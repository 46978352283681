import {ReactComponent as E1} from "../../../../Core/symbols/myBloom/emotes/Emot_01.svg"
import {ReactComponent as E2} from "../../../../Core/symbols/myBloom/emotes/Emot_02.svg"
import {ReactComponent as E3} from "../../../../Core/symbols/myBloom/emotes/Emot_03.svg"
import {ReactComponent as E4} from "../../../../Core/symbols/myBloom/emotes/Emot_04.svg"
import {ReactComponent as E5} from "../../../../Core/symbols/myBloom/emotes/Emot_05.svg"
import {ReactComponent as E6} from "../../../../Core/symbols/myBloom/emotes/Emot_06.svg"
import {ReactComponent as E7} from "../../../../Core/symbols/myBloom/emotes/Emot_07.svg"
import {ReactComponent as E8} from "../../../../Core/symbols/myBloom/emotes/Emot_08.svg"
import {ReactComponent as E9} from "../../../../Core/symbols/myBloom/emotes/Emot_09.svg"
import {ReactComponent as E10} from "../../../../Core/symbols/myBloom/emotes/Emot_10.svg"
import {ReactComponent as E11} from "../../../../Core/symbols/myBloom/emotes/Emot_11.svg"
import {ReactComponent as H1} from "../../../../Core/symbols/myBloom/hats/Hat_01.svg"
import {ReactComponent as H2} from "../../../../Core/symbols/myBloom/hats/Hat_02.svg"
import {ReactComponent as H3} from "../../../../Core/symbols/myBloom/hats/Hat_03.svg"
import {ReactComponent as H4} from "../../../../Core/symbols/myBloom/hats/Hat_04.svg"
import {ReactComponent as H5} from "../../../../Core/symbols/myBloom/hats/Hat_05.svg"
import {ReactComponent as H6} from "../../../../Core/symbols/myBloom/hats/Hat_06.svg"
import {ReactComponent as H7} from "../../../../Core/symbols/myBloom/hats/Hat_07.svg"
import {ReactComponent as H8} from "../../../../Core/symbols/myBloom/hats/Hat_08.svg"
import {ReactComponent as H9} from "../../../../Core/symbols/myBloom/hats/Hat_09.svg"
import {ReactComponent as H10} from "../../../../Core/symbols/myBloom/hats/Hat_10.svg"
import {ReactComponent as H11} from "../../../../Core/symbols/myBloom/hats/Hat_11.svg"
import {ReactComponent as H12} from "../../../../Core/symbols/myBloom/hats/Hat_12.svg"
import {ReactComponent as H13} from "../../../../Core/symbols/myBloom/hats/Hat_13.svg"
import {ReactComponent as H14} from "../../../../Core/symbols/myBloom/hats/Hat_14.svg"
import {ReactComponent as H15} from "../../../../Core/symbols/myBloom/hats/Hat_15.svg"
import {ReactComponent as HatLogo} from "../../../../Core/symbols/myBloom/hats/Hat_Logo.svg";
import {ReactComponent as None} from "../../../../Core/symbols/myBloom/icons/None.svg"
import {ReactComponent as PaletteIcon} from "../../../../Core/symbols/myBloom/icons/Palette.svg"
import {ReactComponent as A1} from "../../../../Core/symbols/myBloom/avatars/Avatar_01.svg";
import {ReactComponent as A2} from "../../../../Core/symbols/myBloom/avatars/Avatar_02.svg";
import {ReactComponent as A3} from "../../../../Core/symbols/myBloom/avatars/Avatar_03.svg";
import {ReactComponent as A4} from "../../../../Core/symbols/myBloom/avatars/Avatar_04.svg";
import {ReactComponent as A5} from "../../../../Core/symbols/myBloom/avatars/Avatar_05.svg";


export const selectForm = (id, color) => {
    switch (id) {
        case "01":
            return <A1 style={{fill: color}}/>
        case "02":
            return <A2 style={{fill: color}}/>
        case "03":
            return <A3 style={{fill: color}}/>
        case "04":
            return <A4 style={{fill: color}}/>
        case "05":
            return <A5 style={{fill: color}}/>
        default:
            break;
    }
}

export const selectHat = (id, color) => {
    if (id !== "15") {
        return HatAssetsList[parseInt(id) - 1]
    } else {
        return <H15 style={{fill: color}}/>
    }

}
export const selectEmote = (id) => {
    return EmoteAssetsList[parseInt(id) - 1]
}
export const listOfPalettes = () => {
    let list = []
    Object.entries(PaletteColors).forEach(color => list.push({
        id: color[0],
        asset: <PaletteIcon
            style={{fill: color[1].avatar}}/>
    }))
    return list
}

export const PaletteColors = {
    "01": {
        avatar: "#07B7E3",
        bg: "#D4F4FB"
    },
    "02": {
        avatar: "#DF82F5",
        bg: "#FBF0FE"
    },
    "03": {
        avatar: "#84CCAB",
        bg: "#DAF0E6"
    },
    "04": {
        avatar: "#FFB053",
        bg: "#FFE5C6"
    },
    "05": {
        avatar: "#E2B08D",
        bg: "#F3DFD1"
    },
    "06": {
        avatar: "#ACA7D7",
        bg: "#E4E2F2"
    },
    "07": {
        avatar: "#D26577",
        bg: "#F7E4ED"
    },
}

export const formAssetsList = (color) => {
    return [
        {
            id: "01",
            category: "form",
            asset: <A1 style={{fill: color}}/>,
        },
        {
            id: "02",
            category: "form",
            asset: <A2 style={{fill: color}}/>,
        },
        {
            id: "03",
            category: "form",
            asset: <A3 style={{fill: color}}/>,
        },
        {
            id: "04",
            category: "form",
            asset: <A4 style={{fill: color}}/>,
        },
        {
            id: "05",
            category: "form",
            asset: <A5 style={{fill: color}}/>,
        },
    ]
}

export const calculateEmote = (percent) => {
    if (percent >-1 && percent !== null) {
        if (percent <= 19) {
            return "01"
        } else if (percent <= 29  ) {
            return "02"
        } else if (percent <= 39) {
            return "03"
        } else if (percent <= 49) {
            return "04"
        } else if (percent <= 59) {
            return "05"
        } else if (percent <= 64) {
            return "06"
        } else if (percent <= 69) {
            return "07"
        } else if (percent <= 74) {
            return "08"
        } else if (percent <= 79) {
            return "09"
        } else if (percent <= 89) {
            return "10"
        } else if (percent >= 90) {
            return "11"
        }
    } else {
        return "06"
    }

}
export const generateRandomAvatar = (emoteValue = null) =>{
    const maxForms = 5
    const maxHats = 16
    const maxColors = 7

    const emote = emoteValue ? emoteValue : "06"

    let randomForm = Math.floor(Math.random() * (maxForms - 1 + 1) + 1)
    let randomColor = Math.floor(Math.random() * (maxColors - 1 + 1) + 1)
    let randomHat = Math.floor(Math.random() * (maxHats - 1 + 1) + 1)

    randomHat = randomHat < 10 ? "0" + randomHat.toString() : randomHat.toString()
    randomForm = "0" + randomForm.toString()
    randomColor = "0" + randomColor.toString()
    return {
        hat: randomHat,
        color: randomColor,
        form: randomForm,
        emote : emote
    }
}
export const avatarCodes = (code) => {
    const decodedArray = code.match(/.{1,2}/g) ?? [];
    return {
        form: decodedArray[0],
        color: decodedArray[1],
        emote: decodedArray[2],
        hat: decodedArray[3],
    }
}
export const innerWheel = (color) => {
    const forms = formAssetsList(PaletteColors[color].avatar)
    const palettes = listOfPalettes()
    return forms.concat(palettes)
}


export const HatWheelList = [
    {
        id: "01",
        category: "hat",
        asset: <H1 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "02",
        category: "hat",
        asset: <H2 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "03",
        category: "hat",
        asset: <H3 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "04",
        category: "hat",
        asset: <H4 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "05",
        category: "hat",
        asset: <H5 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "06",
        category: "hat",
        asset: <H6 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "07",
        category: "hat",
        asset: <H7 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "08",
        category: "hat",
        asset: <H8 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "09",
        category: "hat",
        asset: <H9 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "10",
        category: "hat",
        asset: <H10 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "11",
        category: "hat",
        asset: <H11 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "12",
        category: "hat",
        asset: <H12 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "13",
        category: "hat",
        asset: <H13 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "14",
        category: "hat",
        asset: <H14 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "15",
        category: "hat",
        asset: <H15 style={{filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"}}/>
    },
    {
        id: "16",
        category: "hat",
        asset: <None/>
    }

]


export const HatAssetsList = [
    <H1/>,
    <H2/>,
    <H3/>,
    <H4/>,
    <H5/>,
    <H6/>,
    <H7/>,
    <H8/>,
    <H9/>,
    <H10/>,
    <H11/>,
    <H12/>,
    <H13/>,
    <H14/>,
    <H15/>,
    <None/>
]
export const EmoteAssetsList = [
    <E1/>,
    <E2/>,
    <E3/>,
    <E4/>,
    <E5/>,
    <E6/>,
    <E7/>,
    <E8/>,
    <E9/>,
    <E10/>,
    <E11/>,
]
export {
    E1,
    E2,
    E3,
    E4,
    E5,
    E6,
    E7,
    E8,
    E9,
    E10,
    E11,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    H7,
    H8,
    H9,
    H10,
    H11,
    H12,
    H13,
    H14,
    H15,
    HatLogo,
    None,
    PaletteIcon,
    A1,
    A2,
    A3,
    A4,
    A5
}