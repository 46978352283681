import React, {useRef} from 'react';
import {TwitterPicker} from "react-color";
import PropTypes from "prop-types";
import {useOutsideAlerter} from "../../../../../hooks/outsideAlerterHook";
import {Overlay, Popover} from "react-bootstrap";
import {defaultColors} from "../Assets/CategoriesAssets";

const ColorPicker = (props) => {
    const {color, displayPicker,setDisplayPicker,handleColor} = props

    const ref = useRef(null);

    const handleClose = () => {
      setDisplayPicker(false)
    }

    const handleClick = () => {
        setDisplayPicker(!displayPicker)
    }


    useOutsideAlerter(ref,()=> {handleClose()})
    return (
        <div ref={ref} className={"w-25 h-25 mx-auto"}>
            <div onClick={handleClick} className={"inline-block pointer w-25 h-25"}>
                <div className={"w-full h-full border-1 border-disabled-grey rounded-md "} style={{backgroundColor : color}}/>
            </div>
            <Overlay
                show={displayPicker}
                target={ref.current}
                placement="bottom-start"
                container={ref.current}
                containerPadding={20}
            >
                <Popover className={"w-280"}>
                    <Popover.Body className={"p-5"}>
                        <TwitterPicker
                            className={"border-0 shadow-none w-full"}
                            onChangeComplete={(colorSelected) => { handleColor(colorSelected.hex)}}
                            colors={defaultColors}
                            triangle={"hide"}
                            color={color}
                        />
                    </Popover.Body>
                </Popover>

            </Overlay>
        </div>
    );
};

ColorPicker.propTypes={
    color : PropTypes.string.isRequired,
    displayPicker : PropTypes.bool.isRequired,
    setDisplayPicker : PropTypes.func.isRequired,
    handleColor : PropTypes.func.isRequired,
}

export default ColorPicker;
