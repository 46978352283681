import React from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import Team from "./Team";



const TeamPage = (props) => {
    return(
        <DisplayAdminView {...props} menu={"settings"} pageName={"admin.teams"} selectedIndex={10}>
            {
                () => (
                    <Container>
                        <Team {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default TeamPage