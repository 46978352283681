import {Button, Card, Container} from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import {Colors} from "../../../../Utils/Colors";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import EbloomInfoTooltip from "../Popovers-Tooltips/EbloomInfoTooltip";
import _ from "lodash";


const DashboardCard = (props) => {

    const {title, minHeight, maxHeight, borderColor, paddingLeft, paddingRight, showButton, buttonText, buttonVariant, handleButton, showExtraComponent, extraComponent, showInfo, info,colors} = props;

    return(
        <Container fluid className={"px-0"}>
            <Card style={{minHeight:minHeight, borderColor:colors ? colors.primary : borderColor, paddingLeft:paddingLeft, paddingRight:paddingRight, maxHeight:maxHeight}}>
                <Card.Body>
                    <div className={"grid grid-rows-1 grid-cols-5 items-center mb-25 justify-center"}>
                        {
                            title &&
                            <div style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={"ebloom-normal-title justify-self-center  col-span-3 col-start-2"}>
                                {title}
                            </div>
                        }
                        <div className={"flex justify-between items-center col-start-5 justify-self-end"}>
                            {
                                showButton &&
                                <div>
                                    <Button className={buttonVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text" : buttonVariant === "purple-outline" ? "ebloom-btn-purple-outline ebloom-normal-text" : buttonVariant === "purple-outline-noBorder" ? "ebloom-btn-purple-outline noBorder ebloom-normal-text" : " ebloom-normal-text"} style={{color:buttonVariant === "link" ? colors ? colors.secondary : Colors.EBLOOM_PURPLE : ""}} variant={buttonVariant} size={"sm"} onClick={handleButton}>{buttonText}</Button>
                                </div>
                            }
                            {
                                showExtraComponent &&
                                <div>
                                    {extraComponent}
                                </div>
                            }
                            {
                                showInfo &&
                                <div>
                                    <EbloomInfoTooltip text={info} colors={colors}/>
                                </div>

                            }
                        </div>
                    </div>
                    {props.children}
                </Card.Body>
            </Card>
        </Container>
    )
}

DashboardCard.defaultProps = {
    title:null,
    subtitle:null,
    titlePosition:"center",
    subtitlePosition:"justify",
    minHeight:0,
    maxHeight:10000,
    paddingLeft:0,
    paddingRight:0,
    showButton:false,
    buttonVariant:"purple",
    showExtraComponent:false,
    borderColor:Colors.EBLOOM_CONTRAST_PURPLE,
    showInfo:true,
    colors : null

}

DashboardCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titlePosition: PropTypes.string,
    subtitlePosition: PropTypes.string,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    showButton: PropTypes.bool,
    buttonText: PropTypes.any,
    buttonVariant:PropTypes.string,
    handleButton: PropTypes.func,
    showExtraComponent: PropTypes.bool,
    extraComponent:PropTypes.any,
    borderColor:PropTypes.string,
    showInfo:PropTypes.bool,
    info:PropTypes.string,
    colors : PropTypes.object
}

export default DashboardCard;