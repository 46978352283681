import React from 'react';
import PropTypes from 'prop-types';
import DisplaySuperAdminView from "../../../Layouts/DisplaySuperAdminView";
import {Container} from "react-bootstrap";
import WeBloomDashboard from "../../../../Admin/Pages/WeBloom/Dashboard/WeBloomDashboard";

const WeBloomDashboardGroupPage = (props) => {
    //const {} = props

    return (
        <DisplaySuperAdminView pageName={"WeBloom"} menu={"noMenu"} isAllEntities={false} isDashboardSuperAdmin={false} selectedIndex={9} module={"webloom"} {...props}>
            {
                (checkForCollabSurvey, checkIfCollabAccess, nbActiveSurvey,entitiesToDisplay,isAllEntities) =>(
                    <Container className={"pt-114 h-full"}>
                        <WeBloomDashboard isSuperAdmin={true} isAllEntities={isAllEntities} isTemplate={false} nbActiveSurvey={nbActiveSurvey} {...props} />
                    </Container>
                )
            }
        </DisplaySuperAdminView>
    );
};

WeBloomDashboardGroupPage.propTypes = {

};

export default WeBloomDashboardGroupPage;
