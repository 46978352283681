import React, {useEffect} from "react";
import PropTypes from "prop-types"

const EbloomBooleanSwitch = (props) => {

    //state

    //props
    const {selected, handleChange, color, disabled} = props;

    //hooks

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {

    }, [])

    return (
        <div className={disabled ? "opacity-50" : "opacity-100"}>
            <div className={"h-15 w-45 rounded-3xl flex justify-between items-center p-5 relative " + (disabled ? "cursor-default " : "cursor-pointer ") + (selected ? color === "contrast-blue" ? "bg-contrast-blue/[0.6]" : color === "purple" ? "bg-purple/[0.6]" : color === "contrast-purple" ? "bg-contrast-purple/[0.6]" : "bg-grey/[0.8]" : "bg-grey/[0.8]")} onClick={disabled ? () => {} : handleChange}>
                <div className={`absolute transition-all duration-100 ${
                    selected ? 'translate-x-15 bg-'+color+' border-'+color : 'left-0 border-white bg-bg-white'
                } h-25 w-27 border rounded-3xl shadow shadow-black`}/>
            </div>
        </div>
    )
}

EbloomBooleanSwitch.propTypes = {
    selected:PropTypes.bool.isRequired,
    handleChange:PropTypes.func.isRequired,
    color:PropTypes.string,
    disabled:PropTypes.bool
}

EbloomBooleanSwitch.defaultProps = {
    color:"purple",
    disabled:false
}
export default EbloomBooleanSwitch