import {ReactComponent as DoubleQuotes} from "../../../../Core/symbols/myBloom/icons/double-quotes.svg";
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import quoteList from "./quotes.json";
import {getLanguage} from "react-multi-lang";

const Quotes = (props) => {
    const bp = useBreakpoints()

    const recoverQuoteOfTheDay = () => {
        const date = new Date();
        const dayOfYear = Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 86400000);
        const randomNumber = (dayOfYear % 39) + 1; //from 1 to 39, same number for given day of the year
        return  quoteList[randomNumber][getLanguage()]
    }

    return <div className={"w-full flex flex-col" + (bp.isTabletLandscapeOrLess() ? " mt-20" : "")}>

        <div className={""}>
            <div className={"w-auto relative "}>
                <div className={"absolute top-15 left-35"}>
                    <DoubleQuotes className={"h-26 w-26"}/>
                </div>

                <div className={"rounded-3xl bg-white h-1/2 min-h-155 px-70 py-30"}>
                    <p className={"text-purple font-semibold text-xl ebloom-text"}>{recoverQuoteOfTheDay()}</p>
                </div>
            </div>
        </div>
        {
            !bp.isTabletLandscapeOrLess() &&
            <div className={"w-full flex flex-col justify-center items-end"}>
                <div className={"w-40 h-40 rounded-full bg-white my-10 "}/>
                <div className={"w-20 h-20 rounded-full bg-white "} />
            </div>
        }

    </div>
}
export default Quotes