import React from "react";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Notification from "./Notification";


const NotificationPage = (props) =>{

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <Notification companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
}

export default NotificationPage;