import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as IdeaboxIcon} from "../../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as ValidateIcon} from "../../../../../Core/symbols/webloom/white/validate-white.svg";
import {ReactComponent as ForbiddenIcon} from "../../../../../Core/symbols/webloom/white/forbidden-white.svg";
import {Badge, Button} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import {SurveyStatus, SurveyUsersStatus} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {getLanguage} from "react-multi-lang";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {useUserData} from "../../../../../../hooks/userHook";
import {Colors} from "../../../../../../Utils/Colors";

const IdeaboxCard = (props) => {

    //state
    const {survey,isProspect,setShowModal} = props;

    const isRunning = survey.status && survey.status === SurveyStatus.RUNNING && survey.lastAnswer === null;

    const hasAnswered = survey.status && survey.status === SurveyStatus.RUNNING && survey.lastAnswer !== null;

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {

    }, [])

    return (
        <div className={(isRunning || hasAnswered) ? survey.userSurveyStatus === SurveyUsersStatus.UNREAD ? "bg-contrast-blue/[0.3] rounded-lg px-15 py-11 pointer" : "bg-contrast-blue/[0.1] rounded-lg px-15 py-11 pointer" : "bg-bg-grey/[0.7] rounded-lg px-15 py-11"} onClick={isProspect ? ()=> setShowModal(true) : (isRunning || hasAnswered) ? () => {navigate("/webloom/ideabox/answer/" + survey.id, {state:survey})} : () => {}}>
            <div className={"flex justify-between items-center mb-20"}>
                <div className={"flex justify-start"}>
                    <IdeaboxIcon
                        className={(isRunning || hasAnswered) ? "w-30 h-30 contrast-purple-icon mr-5" : "w-30 h-30 grey-icon mr-5"}/>
                    <div
                        className={(isRunning || hasAnswered) ? "ebloom-title font-semibold text-contrast-purple ebloom-very-large-text" : "ebloom-title font-semibold text-grey ebloom-very-large-text"}>
                        Ideabox
                    </div>
                    <div className={"mx-10"}>
                        {
                            !!user.companyGroup && !!survey.id_company && survey.id_company !== user.companyId &&
                            <Badge style={{backgroundColor: user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill className={"text-white"}>{user.companyGroup?.name}</Badge>
                        }
                    </div>
                </div>
                {
                    (survey.end_date || survey.lastAnswer) &&
                    <div className={"flex items-center"}>
                        {
                            survey.lastAnswer !== null ?
                                <div className={"flex items-center"}>
                                    <ValidateIcon className={"w-20 h-20 green-icon mr-5"}/>
                                    <div className={"text-grey"}>{_.upperFirst(t("webloom.iParticipatedOn",{param:moment(survey.lastAnswer).locale(getLanguage()).format("dddd Do MMMM YYYY")}))}</div>
                                </div>
                                : survey.status === SurveyStatus.DONE ?
                                    <div className={"flex items-center"}>
                                        <ForbiddenIcon className={"w-20 h-20 red-icon mr-5"}/>
                                        <div className={"text-grey"}>{_.upperFirst(t("webloom.iDidNotParticipate"))}</div>
                                    </div>
                                    : survey.end_date &&
                                    <div className={"text-contrast-purple"}>{moment(survey.end_date).endOf("day").locale(getLanguage()).fromNow()}</div>
                        }
                    </div>
                }
            </div>
            {
                ((isRunning || hasAnswered) && survey.question.category) &&
                <div className={"mt-10 mb-10"}>
                    <span>{_.upperFirst(t("core.sb.sbHelper"))}</span>
                </div>
            }

            <div className={(isRunning || hasAnswered) ? "text-purple ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10" : "text-grey ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10"}>
                {survey.question.text}
            </div>
            {
                survey.image_url &&
                <FileDisplay displayImg urlParams={survey.image_url} imgHeight={300}/>
            }
            {
                (isRunning || hasAnswered) &&
                <div className={"flex justify-end items-baseline"}>
                    <Button className={"ebloom-btn-purple ml-5"} size={"sm"} onClick={isProspect ? ()=> setShowModal(true) : () => {navigate("/webloom/ideabox/answer/" + survey.id, {state:survey})}}>{_.toUpper(hasAnswered ? t("webloom.iStillHaveMoreIdeas") : t("webloom.iHaveAnIdea"))}</Button>
                </div>
            }

        </div>
    )
}

IdeaboxCard.propTypes = {
    survey:PropTypes.object.isRequired,
    setShowModal : PropTypes.func.isRequired,
    isProspect : PropTypes.bool.isRequired
}

IdeaboxCard.defaultProps = {}
export default IdeaboxCard;