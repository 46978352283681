import PropTypes from "prop-types";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import parse, {domToReact} from "html-react-parser";
import List from "@mui/material/List";
import React from "react";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
import {Link} from "react-router-dom";
const DetailsQuestion = (props) => {
    const {item,handleCard} = props
    const t = useTranslation()
    const handleGoBack = () => {
      handleCard(null)
    }

    const convertHtmlToText = (html) => {
        const options = {
            replace: ({name,children, attribs}) => {
                if(name === 'ul' || name === 'ol' || name==='li' || name ==='a' ){
                    if (name ==='ol'){
                        return(<List role={"list"}  sx = {{
                            listStyleType: 'decimal',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                            },
                        }}>
                            {domToReact(children,options)}
                        </List>)
                    }
                    if(name ==='ul'){
                        return(<List role={"list"}  sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                            },
                        }}>
                            {domToReact(children,options)}
                        </List>)
                    }
                    if(name === 'a'){
                        if(attribs.href.startsWith('/')){
                            const result = attribs.href.match(/\d+/);
                            return <Link onClick={() => handleCard(result[0])} to={'/admin/faq'} className={'text-purple underline'}>
                                {domToReact(children,options)}
                            </Link>
                        }else{
                            return <a href={attribs.href} target={attribs.target} className={'text-purple underline'}>
                                {domToReact(children,options)}
                            </a>
                        }

                    }
                }
            }
        }
        return parse(html,options)
    }



  return(
      <>
          <CardDefault title={item.question} showButton={false} showLeftButton={true} leftButtonVariant={'secondary'} handleLeftButton={handleGoBack} leftButtonText={_.upperFirst(t('core.back'))}>
            <div>
                {convertHtmlToText(item.answer)}
            </div>
          </CardDefault>
      </>
  )
}

DetailsQuestion.propTypes ={
    item: PropTypes.object.isRequired,
    handleCard: PropTypes.func.isRequired
}

export default DetailsQuestion