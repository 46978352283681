import React from 'react';
import PropTypes from 'prop-types';
import {Colors} from "../../../../Utils/Colors";
import {selectBubbleIcon, selectIcon} from "../../../Admin/Pages/Categories/Assets/CategoriesAssets";

const CategoryBubble = (props) => {
    const {icon,size,color} = props

    return (
        <div className={" h-50 w-50"}>
            {selectBubbleIcon(icon,color)}
        </div>
    );
};

CategoryBubble.propTypes = {
    icon : PropTypes.string.isRequired,
    color : PropTypes.string.isRequired
};

export default CategoryBubble;
