import React from "react";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Button} from "react-bootstrap";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import PropTypes from "prop-types";
import LoadingView from "../../Layouts/LoadingView";

const SideForm = (props) => {

    const {sideFormOpen, toggleDrawer, showButtons, formTitle, children, handleSave, loading, buttonDisabled, tourDataset} = props;

    const t = useTranslation();

    return (
        <SwipeableDrawer
            anchor="right"
            open={sideFormOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >

            <Card className={"overflow-y-auto"}>
                {
                    loading ?
                        <LoadingView/>
                        :
                        <Card.Body data-tour={tourDataset.card}>
                            <div className={"flex justify-between items-center mb-20"}>
                                <div className={"text-contrast-purple ebloom-title ebloom-very-small-title"}>
                                    {_.upperFirst(t(formTitle))}
                                </div>
                                {
                                    !showButtons &&
                                    <Button size={"sm"} variant={"secondary"} onClick={toggleDrawer(false)}>{_.capitalize(t("core.close"))}</Button>
                                }
                            </div>
                            {children}
                            {
                                showButtons &&
                                <div className={"text-right mt-10"}>
                                    <Button className={"mr-5"} variant="secondary" onClick={toggleDrawer(false)}>{_.capitalize(t("core.cancel"))}</Button>
                                    <Button data-tour={tourDataset.btn} className={"ebloom-btn-purple"} onClick={handleSave} disabled={loading || buttonDisabled}>{_.capitalize(t("core.save"))}</Button>
                                </div>
                            }

                        </Card.Body>
                }
            </Card>
        </SwipeableDrawer>
    )
}

SideForm.propTypes = {
    sideFormOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    formTitle:PropTypes.string,
    handleSave:PropTypes.func,
    showButtons:PropTypes.bool,
    loading:PropTypes.bool,
    buttonDisabled:PropTypes.bool,
    tourDataset: PropTypes.object
};


SideForm.defaultProps = {
    showButtons:true,
    formTitle:"core.create",
    handleSave:() => {},
    loading:false,
    buttonDisabled:false,
    tourDataset : {
        card : 'none',
        btn : 'none'
    }
};

export default SideForm;