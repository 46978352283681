import MenuItem from "@mui/material/MenuItem/MenuItem";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row, } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import HttpApi from "../../../../httpApi";
import { FaqType, FullPurpleTextField } from "../../../../Utils/Global";
import { notifyError } from "../../../../Utils/Notification";
import EbloomSearchBar from "../../../Core/Modules/Input/EbloomSearchBar";
import CardFAQ from "./Card/CardFAQ";
import DetailsQuestion from "./DetailsQuestion";
import SupportFAQ from "./SupportFAQ";
const FAQ = (props) => {

    //state
    const [faqList, setFaqList] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [search, setSearch] = useState('');
    const [type, setType] = useState(" ");
    const [searchList, setSearchList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [otherList, setOtherList] = useState([]);
    //props

    //hooks
    const t = useTranslation();

    //getters
    const fetchFaqQuestions = () => {

        HttpApi.get("/faq/getAllByLang").then(response => {
            if (response && response.data) {
                const faq = response.data;
                const prioList = _.orderBy(_.filter(faq,['priority',1]),'checked','desc')
                const checkedList = _.orderBy(_.filter(faq,['priority',0]),'checked','desc')
                const list = prioList.concat(checkedList).slice(0,3)
                setPriorityList(list)
                setFaqList(faq)
                setSearchList(faq)
                const others = _.differenceBy(faq,list,'id')
                setOtherList(others)
            }
        }).catch(error => {
            notifyError(error);
        });
    }

    //posters

    //handlers
    const handleCard = (item) => {
        if (item === null){
            setSelectedCard(null)
        }else{
            const question = faqList.find((card)=> card.id === parseInt(item))
            setSelectedCard(question)
            /*HttpApi.post("/faq/addChecked",question).then(() => {
                
            }).catch(error => {
                notifyError(error);
            });*/
        }
    }

    const handleSearch = (event) => {
        let value = event.target.value
        setSearch(value)

        value = _.deburr(value)

        if(value.trim() !== ''){
            let result = []
            _.words(value).forEach(word => {
                const list = faqList.filter((item) => {
                    const listTag = item.tags.map(tag => _.lowerCase(tag.label))
                    if(listTag.filter(el => _.toLower(t("admin.faq.tags." + el)).includes(_.toLower(word))).length > 0 || (_.deburr(item.question.toLowerCase())).includes(word.toLowerCase()))
                        return item
                    return null;
                })
                result = result.concat(list)
            })
            result = _.uniqBy(result,'id')
            setSearchList(result)
        }else{
            setSearchList(faqList)
        }
    }

    const handleType = (event) => {
        setType(event.target.value)
    }

    //useEffect
    useEffect(() => {
        fetchFaqQuestions()
    }, [selectedCard])

    return(
        selectedCard ?
            <DetailsQuestion handleCard={handleCard} item={selectedCard}/>
            :
        <>
            <Row className={'mb-30'}>
                <Col md={8}>
                    <EbloomSearchBar className={"w-full"} size={"small"} handleChange={handleSearch} searchValue={search}/>
                </Col>
                <Col >
                    <FullPurpleTextField required select open={true} className={'ml-10 w-200'} variant={"outlined"} label={_.upperFirst(t("backoffice.faq.type"))} size={"small"} name={"type-selector"} defaultValue={0} onChange={handleType} value={type} >
                        <MenuItem key={0} value={" "}>{_.upperFirst(t("admin.faq.all"))}</MenuItem>
                        {
                            Object.values(FaqType).map((type,index) => <MenuItem key={index+1} value={type}>{t("admin.faq.types."+ _.camelCase(type))}</MenuItem>)
                        }
                    </FullPurpleTextField>
                </Col>
            </Row>

                {
                    search.trim() !== '' &&

                        <>
                            <h1 className={'ebloom-normal-title font-semibold text-contrast-purple'}>{_.upperFirst(t("admin.faq.searchResult"))}</h1>
                            <Row md={3} gap={3} className={'mb-30'}>
                                {
                                    searchList.length === 0 ?
                                        <h1 className={'ebloom-normal-title text-contrast-purple'}>{_.upperFirst(t("admin.faq.noResult")) }</h1>
                                        :
                                        searchList.map((element,i) =>
                                            <Col className={'mb-30 items-stretch'} key={i}>
                                                <CardFAQ infos={element} handleCard={handleCard}/>
                                            </Col>
                                        )
                                }
                            </Row>
                        </>

                }



            <h1 className={'ebloom-normal-title font-semibold text-contrast-purple'}>{_.upperFirst(t("admin.faq.mostConsulted"))}</h1>
            <Row md={3} gap={3} className={'mb-50 flex'}>
                {
                        priorityList
                            .filter((item) => item.type === type || type === " ")
                            .map((element,i) =>
                            <Col className={'mb-30 items-stretch'} key={i}>
                                <CardFAQ infos={element} handleCard={handleCard}/>
                            </Col>
                        ).slice(0,3)
                }
            </Row>
            <h1 className={'ebloom-normal-title font-semibold text-contrast-purple'}>{_.upperFirst(t("admin.faq.otherQuestion"))}</h1>
            <Row md={3} gap={3}>
                {
                    otherList
                        .filter((item) => item.type === type || type === " ")
                        .map((element,i) =>
                            <Col className={'mb-30 items-stretch'} key={i}>
                                    <CardFAQ infos={element} handleCard={handleCard}/>
                                </Col>
                        )
                }
            </Row>
            <SupportFAQ/>
        </>)
}

export default FAQ;