import React from "react";
import PropTypes from "prop-types"

const EbloomNotificationBadge = (props) => {

    //state
    const {children, number, showNumber, showSonar} = props;
    //hooks

    //getters

    //posters

    //handlers

    //useEffect

    return (
        <div className={"relative inline-block"}>
            {
                number > 0 &&
                <div className={showNumber ? "bg-strong-red rounded-xl absolute ebloom-text ebloom-very-small-text text-white font-semibold -right-10 -top-10" : "bg-strong-red rounded-xl w-12 h-12 absolute -right-5 -top-3"}>
                    <div className={(showSonar ? "sonar w-full h-full bg-transparent border border-strong-red rounded-xl" : "")}>
                        {
                            showNumber &&
                            <span className={"px-6"}>{number>10 ? "10+" : number}</span>
                        }
                    </div>
                </div>
            }
            {children}
        </div>
    )
}

EbloomNotificationBadge.propTypes = {
    showNumber:PropTypes.bool,
    number:PropTypes.number.isRequired,
    showSonar:PropTypes.bool,
}

EbloomNotificationBadge.defaultProps = {
    showNumber:false,
    showSonar:false,
}
export default EbloomNotificationBadge