import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import {Button, Col, Row} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import OpenQuestion from "../../../Polls/Answer/Modules/OpenQuestion";
import LoadingView from "../../../../../../Core/Layouts/LoadingView";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import {useUserData} from "../../../../../../../hooks/userHook";
import {Colors} from "../../../../../../../Utils/Colors";

const AnswerIdeabox = (props) => {

    //state
    const {survey, handleSave, loading} = props;

    //hooks
    const t = useTranslation();
    const user = useUserData();
    //getters

    //posters

    //handlers

    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    //useEffect

    useEffect(() => {

    }, [])

    return (
        <div className={"cursorDefault"}>
            <Row className={"mb-20"}>
                <Col>
                    <div>
                        <span className={"text-purple"}>{displayDate()}</span>
                    </div>
                    {
                        !!user.companyGroup && !!survey.id_company && survey.id_company !== user.companyId &&
                        <div
                            className={"ebloom-normal-text text-purple mb-20"}>{_.upperFirst(t("webloom.from")) + " " }
                            <span style={{ color : user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_LOGO_BLUE
                            }} className={"font-semibold "}>{user.companyGroup.name}</span>
                        </div>

                    }
                    {
                        survey.question.id_question &&
                        <Row className={"items-center mt10 mb10"}>
                            <Col className={"text-center"}>
                                <p className={"text-purple ebloom-text ebloom-large-text"}>
                                    {_.upperFirst(t("core.sb.sbHelper"))}
                                </p>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col className={"text-center"}>
                            <p className={"ebloom-text ebloom-very-large-text font-semibold text-purple"}>{replaceUrlsWithLinks(survey.question.text) || "Your topic"}</p>
                        </Col>
                    </Row>
                    <div className={"w-1/2 m-auto mt-10"}>
                        {
                            survey.image_url &&
                            <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400}/>
                        }
                    </div>
                    <div className={"w-1/2 m-auto flex justify-center mt-10"}>
                        {
                            survey.attachment_url &&
                            <FileDisplay urlParams={survey.attachment_url}/>
                        }
                    </div>

                </Col>
            </Row>
            <hr className={"border-purple"}/>
            <Row className={"mt-20"}>
                <Col md={{offset:3, span:6}}>
                    <OpenQuestion question={survey.question} {...props}/>
                </Col>
            </Row>
            {
                handleSave &&
                <Button className={"ebloom-btn-purple mt-20 float-right"} disabled={loading} onClick={handleSave}>{loading ? <LoadingView size={16}/> : _.upperFirst(t("core.save"))}</Button>

            }
        </div>
    )
}

AnswerIdeabox.propTypes = {
    survey:PropTypes.object.isRequired,
    handleSave:PropTypes.func,
    loading:PropTypes.bool,
}

AnswerIdeabox.defaultProps = {}
export default AnswerIdeabox