import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {DayPicker, InternalModifier, useDayPicker, useDayRender, useNavigation} from "react-day-picker";
import {addDays, subDays, format, startOfISOWeek, startOfWeek, isAfter} from "date-fns";
import IconButton from "@mui/material/IconButton/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {getLanguage} from "react-multi-lang";
import {enGB,nlBE,fr} from "date-fns/locale";
import _ from "lodash";
import moment from "moment";

const CustomCaption = (props) => {
    const {goToMonth,nextMonth,previousMonth} = useNavigation()
    const {locale} = useDayPicker()
    const today = new Date();
    return (
        <div className={"w-full h-40 flex flex-row justify-between items-center"}>
            <IconButton onClick={() => previousMonth && goToMonth(previousMonth)}  disabled={!previousMonth}>
                <KeyboardArrowLeftIcon/>
            </IconButton>
            <h1 className={"ebloom-normal-text"}>{_.upperFirst(format(props.displayMonth,today.getFullYear() === props.displayMonth.getFullYear() ? 'MMMM' : 'MMMM yyyy',{locale : locale}))}</h1>
            <IconButton onClick={() => nextMonth && goToMonth(nextMonth)} disabled={!nextMonth}>
                <KeyboardArrowRightIcon/>
            </IconButton>
        </div>
    )
}

const CustomHead = () => {
    const {
        locale,
        weekStartsOn,
        ISOWeek,
        labels: { labelWeekday },
    } = useDayPicker();

    const getWeekdays = () => {
        const start = ISOWeek
            ? startOfISOWeek(new Date())
            : startOfWeek(new Date(), { locale, weekStartsOn });

        const days = [];
        for (let i = 0; i < 7; i++) {
            const day = addDays(start, i);
            days.push(day);
        }
        return days;
    }

    const weekdays = getWeekdays();

    return(
        <tr className={"w-full border-b-[1px] border-b-disabled-grey"}>
            {weekdays.map((weekday, i) => (
                <th
                    key={i}
                    scope="row"
                    className={"font-normal text-grey"}
                    aria-label={labelWeekday(weekday, { locale })}
                >
                    {_.upperFirst(format(weekday, "EEE",{ locale }))}
                </th>
            ))}
        </tr>
    )
}

const CustomDayContainer = (props) => {
    const {date,displayMonth} = props
    const buttonRef = useRef(null);
    const dayRender = useDayRender(date, displayMonth,buttonRef)

    if(dayRender.isHidden){
        return <div role="gridcell"></div>;
    }
    if (!dayRender.isButton) {
        return <div {...dayRender.divProps} />;
    }

    return (
        <button ref={buttonRef} type={"button"} {...dayRender.buttonProps}/>
    )
}

const CustomDayRender = (props) => {
    const {
        locale,
        formatters: { formatDay },
        modifiers
    } = useDayPicker();
    const {date,activeModifiers} = props;

    const determineHoveredClassNameContainer = () => {
        if(activeModifiers.disabled){
            return "text-disabled-grey"
        }
        let className = "bg-logo-blue text-white "
        if(!!modifiers.hoverDays && !!modifiers.hoverDays.start && !!modifiers.hoverDays.end  ){
            //check if they day is start or end of a range
            const start = moment(modifiers.hoverDays.start)
            const end = moment(modifiers.hoverDays.end)
            const day = moment(date)
            if(start.isSame(date,"days") && start.isSame(end,"days")){
                return className + " rounded-full"
            }else if(start.isSame(date,"days")){
                return className + " rounded-l-full"
            }else if(end.isSame(date,"days")){
                return className + " rounded-r-full"
            }
            if(day.isBetween(start,end,"days")){
                return className
            }
        }
        return ""
    }


    return <div className={"w-full h-full "+ determineHoveredClassNameContainer()}>

        <div className={"w-full h-full flex justify-center items-center " + (!activeModifiers.disabled ? " border-0 border-logo-blue rounded-full transition ease-in duration-75 hover:bg-bg-white hover:text-black hover:border-2  " : "")}>
            <p className={"text-center"}>{formatDay(date, {locale})}</p>
        </div>

    </div>;
}

const EbloomDatePicker = (props) => {
    const {futureDates,startDate,endDate,handleStart,handleEnd,changePreset,preset,month,handleMonthChange,mode,singleDate,fromDate,setShowDialog} = props

    const [selectedDay, setSelectedDay] = useState([]);
    const [selectedDaySingle, setSelectedDaySingle] = useState(null);
    const [hoverDays, setHoverDays] = useState({start:null,end:null});
    const [monthSimple, setMonthSimple] = useState(new Date());

    const lang = getLanguage()
    const locale = lang === "nl"? nlBE : lang === "en"? enGB : fr


    const determineDisabled = (date) => {
        if(futureDates){
            return !isAfter(date,subDays(new Date(),1))
        }else{
            if(selectedDay && selectedDay.from && !selectedDay.to){
                return !isAfter(date,subDays(new Date(selectedDay.from),1))
            }
            return  isAfter(date,new Date())
        }
    }

    const hoverEffectOnDayRange = (date,activeModifiers,e) => {
        e.preventDefault()
        if(!activeModifiers.disabled && !!selectedDay.from && !selectedDay.to){
            if(isAfter(date,subDays(new Date(selectedDay.from),1))){
                setHoverDays({start: selectedDay.from, end: date})
            }
        }
    }


    const handleSelectSingle = (day, selectedDay, activeModifiers, e) => {
        e.preventDefault()
        setSelectedDaySingle(day)
        handleStart(day)
        setShowDialog(false)
    }

    const handleSelectRange = (range, dayClicked, activeModifiers, e) => {
        e.preventDefault()

        setHoverDays({start: null, end: null})
        if(preset !== -1){
            changePreset(-1);
        }
        if(!!selectedDay && !!selectedDay.from && !selectedDay.to){
            setSelectedDay(prevState => {
                return {
                    from : prevState.from,
                    to : dayClicked
                }
            })
            handleStart(new Date(selectedDay.from))
            handleEnd(new Date(dayClicked))
        }else if (!!selectedDay && !!selectedDay.from && !!selectedDay.to){
            setSelectedDay({
                from : dayClicked,
                to : undefined
            })
            handleStart(new Date(dayClicked))
            handleEnd(null)
        }else{
            setSelectedDay({
                from : dayClicked,
                to : undefined
            })
            handleStart(new Date(dayClicked))
            handleEnd(null)
        }
    }

    useEffect(() => {
        if(mode === "range"){
            if(!!startDate && !!endDate){
                setSelectedDay({
                    from : startDate,
                    to : endDate
                })
            }else if (!!startDate && !endDate){
                setSelectedDay({
                    from : startDate,
                    to : undefined
                })
            }else if(!startDate && !endDate){
                setSelectedDay({
                    from : undefined,
                    to : undefined
                })
            }
        }


    }, [startDate,endDate]);

    useEffect(() => {
        setSelectedDaySingle(singleDate)
    }, [singleDate]);

    return (
        <div className={"w-full h-full flex justify-center items-center"}>
            <DayPicker
                mode={mode}
                fromDate={!!fromDate ? new Date(fromDate) :futureDates ? new Date() : null}
                toDate={!futureDates ? new Date() : null}
                selected={preset === 0 ? null : mode === "range" ? selectedDay : selectedDaySingle}
                onSelect={mode === "range" ? handleSelectRange : handleSelectSingle}
                locale={locale}
                weekStartsOn={1}
                showOutsideDays={true}
                disabled={determineDisabled}
                month={month ? month : monthSimple}
                onMonthChange={month ? handleMonthChange : setMonthSimple}
                onDayMouseEnter={mode === "range" ? hoverEffectOnDayRange : () => {}}
                modifiersClassNames={{
                    [InternalModifier.Outside] : "text-[#999]",
                    [InternalModifier.Selected] : mode === "single" ?"bg-logo-blue text-white rounded-full" : "",
                    [InternalModifier.Today] : "font-bold text-contrast-purple",
                    [InternalModifier.RangeStart] : "rounded-l-full bg-logo-blue text-white font-normal ",
                    [InternalModifier.RangeMiddle] : "bg-logo-blue text-white",
                    [InternalModifier.RangeEnd] : "rounded-r-full bg-logo-blue text-white",
                }}
                modifiers={{
                    hoverDays : hoverDays
                }}
                styles={{
                    root : {
                        width : "100%",
                        height : "100%",
                        maxWidth : "none",
                        maxHeight : "none",
                    },
                    months : {
                        width : "100%",
                        height : "100%",
                        maxWidth : "none",
                        maxHeight : "none",
                    },
                    month : {
                        width : "100%",
                        height : "100%",
                        maxWidth : "none",
                        maxHeight : "none",
                    },
                    table : {
                        padding :"auto",
                        width : "100%",
                        height : "85%",
                        maxWidth : "none",
                        maxHeight : "none",
                    },
                    cell : {
                        padding : "0px",
                        margin : "0px",
                        width : "42px",
                        height : "42px",
                    },
                    day: {
                        width : "100%",
                        height : "100%",
                        maxWidth : "42px",
                        maxHeight : "42px",
                    }

                }}
                components={{
                    Caption: CustomCaption,
                    Head: CustomHead,
                    Day : CustomDayContainer,
                    DayContent : CustomDayRender
                }}
            />
        </div>
    );
};

EbloomDatePicker.propTypes = {
    futureDates : PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    handleStart: PropTypes.func,
    handleEnd: PropTypes.func,
    changePreset: PropTypes.func,
    preset: PropTypes.number,
    month: PropTypes.object,
    handleMonthChange: PropTypes.func,
    mode: PropTypes.string.isRequired,
    singleDate : PropTypes.string,
    setShowDialog : PropTypes.func
};

EbloomDatePicker.defaultProps = {
    futureDates : false,
    startDate: new Date(),
    endDate: new Date(),
    handleStart: () => {},
    handleEnd: () => {},
    changePreset: () => {},
    preset: -1,
    month: null,
    setShowDialog : () => {},
    handleMonthChange:() => {},
    singleDate : null,
};

export default EbloomDatePicker;
