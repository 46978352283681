import React from 'react';
import MenuAppBar from "./AppBar";


const HeaderEbloom = (props) => {

    const handleDrawer = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    return (
        <MenuAppBar handleDrawerToggle={handleDrawer()} hasMenu={false} {...props}/>
    );

}

export default HeaderEbloom;
