import {createContext, useEffect, useRef, useState} from "react";
import HttpApi from "../../httpApi";
import {notifyError, notifySuccess} from "../Notification";
import {TourType, TourTypePackages} from "../Global";
import {Colors} from "../Colors";
import {hexToRgb} from "@mui/material";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {useSelector} from "react-redux";

const ProductTourContext = createContext(null)
const ProviderProductTour = (props) => {
    const [stepTour, setStepTour] = useState({})
    const [tourActivated, setTourActivated] = useState(false);
    const [tourStarted, setTourStarted] = useState(false);
    const [tourStartedManageUsers, setTourStartedManageUsers] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const [continuous, setContinuous] = useState(true);

    const [stepCommunication, setStepCommunication] = useState(0);
    const [stepInvite, setStepInvite] = useState(0);
    const [stepManageUsers, setStepManageUsers] = useState(0);
    const [stepManageTeams, setStepManageTeams] = useState(0);
    const [stepCustomLibrary, setStepCustomLibrary] = useState(0);
    const [stepBrowseLibrary, setStepBrowseLibrary] = useState(0);
    const [stepCreatePlaylist, setStepCreatePlaylist] = useState(0);
    const [stepCreateClickAndBloom, setStepCreateClickAndBloom] = useState(0);
    const [stepPlanClickAndBloom, setStepPlanClickAndBloom] = useState(0);
    const [stepLaunchWebloom, setStepLaunchWebloom] = useState(0);
    const [stepDefineRoles, setStepDefineRoles] = useState(0);

    const [handlersTour, setHandlersTour] = useState({
        none : () =>{},
        communication : () =>{},
        invite: () =>{},
        manageUsers: () =>{},
        defineRoles: () =>{},
        manageTeams: () =>{},
        customLibrary: () =>{},
        browseLibrary: () =>{},
        createPlaylist: () =>{},
        createClickAndBloom: () =>{},
        planClickAndBloom: () =>{},
        launchWeBloom: () =>{},
    });


    const userModule = useSelector((state) => state.user.module)

    const t = useTranslation("admin.account")

    const icon = useRef(null);

    const styleEbloom = {
        options: {
            zIndex: 800,
            beaconSize: 36,
            primaryColor: Colors.EBLOOM_PURPLE,
            textColor : Colors.EBLOOM_CONTRAST_PURPLE
        },
        beacon : {
            zIndex: 10000
        },
        beaconInner : {
            backgroundColor : Colors.EBLOOM_LOGO_BLUE
        },
        beaconOuter: {
            backgroundColor : `rgba(${hexToRgb(Colors.EBLOOM_LOGO_BLUE).concat(',')}, 0.2)`,
            border: `2px solid ${Colors.EBLOOM_LOGO_BLUE}`,
        }
    }

    const updateHandler = (type,handler) => {
      setHandlersTour(prevState => {
          let newState = prevState
          newState[type] = handler
          return newState
      })
    }

    const updateStep = (type,step) =>{
        if(tourActivated ){
            if(type === TourType.INVITE){
                setStepInvite(step)
            }
            if(type === TourType.COMMUNICATION){
                setStepCommunication(step)
            }
            if (type === TourType.BROWSE_LIBRARY){
                setStepBrowseLibrary(step)
            }
            if(type === TourType.CUSTOM_LIBRARY){
                setStepCustomLibrary(step)
            }
            if(type === TourType.MANAGE_TEAMS){
                setStepManageTeams(step)
            }
            if (type === TourType.CREATE_PLAYLIST){
                setStepCreatePlaylist(step)
            }
            if(type === TourType.LAUNCH_WEBLOOM){
                setStepLaunchWebloom(step)
            }
            if(type === TourType.PLAN_CLICK_AND_BLOOM){
                setStepPlanClickAndBloom(step)
            }
            if (type === TourType.MANAGE_USERS){
                setStepManageUsers(step)
            }
            if (type === TourType.CREATE_CLICK_AND_BLOOM){
                setStepCreateClickAndBloom(step)
            }
            if (type === TourType.DEFINE_ROLES){
                setStepDefineRoles(step)
            }

            setStepTour(prevState => {
                let state = prevState
                state[type].step = step
                return state
            })
        }
    }

    const checkIsLastOneDone = (tourType) => {
        if(userModule){
            const listTour = TourTypePackages[userModule]
            let remaining = []
            listTour.forEach(type => {
                if(!stepTour[type].outcome) {
                    remaining.push(type)
                }
            })
            return (remaining.length === 1 && remaining[0] === tourType)
        }
        return false
    }

    const validateOutcome = (tourType) => {
        setTourStarted(false)
        if(checkIsLastOneDone(tourType)){
            notifySuccess(_.upperFirst(t("congrats")))
        }
        setStepTour(prevState => {
            let state = prevState
            state[tourType].outcome = true
            state[tourType].step = 0
            return state
        })
        const tour = {
            id : stepTour[tourType].id,
            type: tourType,
            finished : true
        }
        HttpApi.post('/productTour/validateTour',tour).then(()=> {
            fetchData()
        })
    }

    const initializeTours =(tours) => {
        if(tours.length === Object.values(TourType).length){
            setStepCommunication(tours[TourType.COMMUNICATION].step)
            setStepInvite(tours[TourType.INVITE].step)
            setStepManageUsers(tours[TourType.MANAGE_USERS].step)
            setStepManageTeams(tours[TourType.MANAGE_TEAMS].step)
            setStepBrowseLibrary(tours[TourType.BROWSE_LIBRARY].step)
            setStepLaunchWebloom(tours[TourType.LAUNCH_WEBLOOM].step)
            setStepPlanClickAndBloom(tours[TourType.PLAN_CLICK_AND_BLOOM].step)
            setStepCreateClickAndBloom(tours[TourType.CREATE_CLICK_AND_BLOOM].step)
            setStepCustomLibrary(tours[TourType.CUSTOM_LIBRARY].step)
            setStepCreatePlaylist(tours[TourType.CREATE_PLAYLIST].step)
            setStepDefineRoles(tours[TourType.DEFINE_ROLES].step)
        }
    }

    const fetchData = () => {
        HttpApi.get('/productTour/getAllTours').then(result =>{
            if(result.data && result.data[0] !== null){
                const data = result.data
                let tours = {}
                for (let i = 0; i < data.length; i++) {
                    const tour = data[i]
                    const type = tour.type
                    const el = {}
                    el[type] = {
                        id : tour.id,
                        step: tour.current_step,
                        outcome: tour.finished
                    }
                    tours = {...tours,...el}
                }
                setTourActivated(true)
                setStepTour(tours)
                initializeTours(tours)
            }else{
                setTourActivated(false)
            }

        }).catch(error =>{
            notifyError(error)
        })
    }

    useEffect(() => {
        if(icon && icon.current){
            icon.current.classList.remove('invisible','visible')
            if(tourStarted){
                icon.current.classList.add('invisible')
            }
            if(tourStartedManageUsers){
                icon.current.classList.add('invisible')
            }else{
                icon.current.classList.add('visible')
            }
        }
    }, [tourStarted,tourStartedManageUsers]);


    const exposedValue = {
        stepTour, setStepTour,
        initialStep, setInitialStep,
        stepInvite, setStepInvite,
        stepCommunication, setStepCommunication,
        stepBrowseLibrary, setStepBrowseLibrary,
        stepManageUsers, setStepManageUsers,
        stepCreateClickAndBloom, setStepCreateClickAndBloom,
        stepCreatePlaylist, setStepCreatePlaylist,
        stepManageTeams, setStepManageTeams,
        stepCustomLibrary, setStepCustomLibrary,
        stepPlanClickAndBloom, setStepPlanClickAndBloom,
        stepLaunchWebloom, setStepLaunchWebloom,
        stepDefineRoles, setStepDefineRoles,
        updateStep,
        validateOutcome,
        tourStarted, setTourStarted,
        tourStartedManageUsers, setTourStartedManageUsers,
        tourActivated, setTourActivated,
        styleEbloom,
        fetchData,
        continuous, setContinuous,
        icon,
        handlersTour, updateHandler,
    }

    return <ProductTourContext.Provider value={exposedValue}>
        { props.children }
    </ProductTourContext.Provider>
}
export {
    ProductTourContext,
    ProviderProductTour
} ;