import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import Login from "../../../../../login/login";
import _ from "lodash";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import ListView from "../../../../Core/Modules/Views-Tables-Lists/ListView";
import UserModel from "../../../../../models/User";
import {FullContrastBlueTextField, Roles} from "../../../../../Utils/Global";
import {Divider, MenuItem} from "@mui/material";
import {Button} from "react-bootstrap";
import {useUserData} from "../../../../../hooks/userHook";

const WeBloomEntitiesPicker = (props) => {

    //state
    const [entities, setEntities] = useState([]);
    const [users, setUsers] = useState([]);

    const [sideFormOpen, setSideFormOpen] = useState(false);

    const {isDraft, team, targets, handleChange} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData();


    //getters
    const fetchEntities = () => {
        let url = "/companies/findAllEntitiesFromGroupWithUsers/"+userData.companyGroup.id
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setEntities(response.data);
                if(!Login.hasRole("global_admin")){
                    if(response.data.find(el => el.id === 0) !== undefined){
                        setUsers(response.data.find(el => el.id === 0).users);
                    }else{
                        concatTeams(response.data);

                    }
                }
                if(isDraft){
                    if(response.data){
                        checkIfCompleteTeam(users, response.data);
                    }else{
                        handleChange(-2, null, "customized");
                    }
                }
            }else{
                setEntities([]);
            }
        }).catch(error => {
            notifyError(error);
        })


    }
    const selectAllUsers = () => {
        let array = [];
        entities.forEach(e => {
            array = array.concat(e.users);
        })
        handleChange(-3, array, "allCurrentUsers");
    }

    //posters

    //handlers
    const handleChangeMenu = (index) => {
        if(team === -3 && index !== -3){

            handleChange(-3, [], "allCurrentUsers");
        }
        if(index === -2){
            concatTeams();
            setSideFormOpen(true);
        }else if(index >= 0){
            setUsers(entities[index].users);
            setSideFormOpen(true);
        }else if(index === -1){
            handleChange(-1, [], "allCurrentAndFutureUsers");
        }else if(index === -3){
            selectAllUsers();
        }
    }

    const handleAdd = (item) => event => {
        event.preventDefault();
        let array = _.cloneDeep(targets);
        array.push(item);

        handleChange(null, array);

        checkIfCompleteTeam(array);

    }

    const handleRemove = (item) => event => {
        event.preventDefault();
        let array = _.cloneDeep(targets);
        const index = targets.indexOf(item);
        array.splice(index, 1);

        handleChange(null, array);

        checkIfCompleteTeam(array);
    }

    const handleAddAll = (items) => {
        //const toAdd = users.filter(el => !targets.some(e => e.id === el.id))
        const array = targets.concat(items);

        handleChange(null, array);

        checkIfCompleteTeam(array);
    }

    const handleRemoveAll = () => {
        handleChange(-4, [], "select");
    }

    const checkIfCompleteTeam = (array, t=null) => {
        let stop = false;
        const tms = t || entities;
        tms.forEach((team, index) => {
            if(!stop){
                if(team.users.length === array.length && team.users.filter(user => array.filter(el => el.id === user.id).length === 0).length === 0){
                    handleChange(index, null, entities[index].name);
                    stop = true;
                }
            }
        })
        if(!stop){
            if(array.length > 0){
                handleChange(-2, null, "customized");
            }else{
                handleChange(-4, null, "select");
            }
        }
    }

    const concatTeams = (ts = null) => {
        const teamsToConcat = ts || entities;
        let array = [];
        teamsToConcat.forEach(t => {
            if(t.id !== 0){
                array = array.concat(t.users);
            }
        })
        setUsers(array);
    }

    const toggleDrawer = (open) => event => {
        if(event){
            event.preventDefault();
        }
        setSideFormOpen(open);
    }
    //useEffect
    useEffect(() => {
        if(!userData.roles.includes(Roles.BACKOFFICE[0])){
            fetchEntities();
        }
    }, [])

    useEffect(() => {
        if(typeof team === "string" && entities && entities.length > 0){
            const index = entities.findIndex(el => el.name === team);
            handleChange(index, null, team, true);
        }
    }, [team, entities])

    return (
        <>
            <FullContrastBlueTextField size={"small"} select variant={"outlined"} value={team} onChange={() => {}}>
                <MenuItem key={-4} value={-4} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                {
                    (userData.roles.some(el => [Roles.SUPER_ADMIN[0],Roles.ADMIN[0],Roles.BACKOFFICE[0]].includes(el))) &&
                    <MenuItem key={-1} value={-1} onClick={() => {handleChangeMenu(-1)}}>{_.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"))}</MenuItem>

                }
                {
                    (userData.roles.some(el => [Roles.SUPER_ADMIN[0],Roles.ADMIN[0]].includes(el))) &&
                    <MenuItem key={-3} value={-3} onClick={() => {handleChangeMenu(-3)}}>{_.upperFirst(t("core.collaborative.allCurrentUsers"))}</MenuItem>

                }
                {
                    entities.filter(el => el.id !== 0).map((option, index) => {
                        let name = option.name
                        return <MenuItem key={index} value={index} onClick={() => {handleChangeMenu(index)}}>{name}</MenuItem>

                    })
                }
                <MenuItem key={-2} value={-2} onClick={() => {handleChangeMenu(-2)}}>{_.upperFirst(t("core.customized"))}</MenuItem>
            </FullContrastBlueTextField>
            {
                team !== -3 && targets && targets.length > 0 &&
                <Button variant={"link"} className={"ebloom-link-blue"} onClick={() => {handleChangeMenu(team)}}>{_.upperFirst(t("core.edit"))}</Button>
            }

            <SideForm formTitle={"core.edit"} sideFormOpen={sideFormOpen} toggleDrawer={toggleDrawer} showButtons={false}>
                <div className={"flex"}>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={targets} handleAction={handleRemove} showSelectAll handleSelectAll={handleRemoveAll} showLangFilter actionType={"remove"}/>
                    </div>
                    <Divider orientation="vertical" flexItem/>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={users.filter(el => !targets.some(e => e.id === el.id))} handleAction={handleAdd} showSelectAll handleSelectAll={handleAddAll} showLangFilter/>
                    </div>
                </div>
            </SideForm>
        </>
    )
}

WeBloomEntitiesPicker.propTypes = {
    isDraft:PropTypes.bool,
    handleChange:PropTypes.func.isRequired,
}

WeBloomEntitiesPicker.defaultProps = {
    isDraft:false,
}
export default WeBloomEntitiesPicker