import React, {useState} from "react";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import Planner from "./Planner";
import {useUserData} from "../../../../../hooks/userHook";
import EntitiesSwitcher from "../../../../Core/Modules/Button/EntitiesSwitcher";



const PlannerPage = (props) => {
    const user = useUserData()
    const [selectedEntity, setSelectedEntity] = useState(null);
    return(
        <DisplayAdminView {...props} menu={"questions"} pageName={"core.playlist.planner"} selectedIndex={8}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher entity={selectedEntity} handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true}/>
                            </div>
                        }
                        <Planner selectedEntity={selectedEntity} {...props}/>

                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default PlannerPage