import React from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import {SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import {MenuItem} from "@mui/material";
import _ from "lodash";
import Menu from "@mui/material/Menu";
import {ReactComponent as AnswerBubbleIcon} from "../../../../../Core/symbols/webloom/white/answer-white.svg";
import {ReactComponent as PlayIcon} from "../../../../../Core/symbols/webloom/white/publish-white.svg";
import {ReactComponent as DuplicateIcon} from "../../../../../Core/symbols/webloom/white/duplicate-white.svg";
import {ReactComponent as StopIcon} from "../../../../../Core/symbols/webloom/white/stop-white.svg";
import {ReactComponent as ArchiveIcon} from "../../../../../Core/symbols/webloom/white/archive-white.svg";
import {ReactComponent as DeleteIcon} from "../../../../../Core/symbols/webloom/white/delete-white.svg";
import {ReactComponent as BoostIcon} from "../../../../../Core/symbols/webloom/white/boost-white.svg";
import {ReactComponent as EditIcon} from "../../../../../Core/symbols/webloom/white/edit-white.svg";
import {ReactComponent as LinkIcon} from "../../../../../Core/symbols/webloom/white/link-white.svg";
import {Limit} from "../../../../../../Utils/Global";
import {notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import {useUserData} from "../../../../../../hooks/userHook";

const WeBloomDashboardItemMenu = (props) => {

    //state

    const {anchorEl, survey, handleCloseMenu, handleDelete, handlePublish, handleReopen, handleStop, handleBoost, handleArchive, color, handleSeeDetail, handleEdit,isGroup} = props;

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()
    //getters

    //posters

    //handlers

    const handleDuplicate = () => {
        const url = "/admin/webloom/"+survey.type+"/create"+(isGroup ?"/"+user.companyGroup.id : "" )+ "?duplicate=true"
        navigate(url, {state:survey})
    }

    const handleCopyLink = () => {
        const url = survey.type === SurveyTypes.POLL ? "https://www.ebloom-clicknbloom.com/webloom/poll/answer/" + survey.id : "https://www.ebloom-clicknbloom.com/webloom/ideabox/answer/" + survey.id;
        navigator.clipboard.writeText(url).then(() => {
            notifySuccess(_.upperFirst(t("webloom.notification.linkCopied")))
        }).catch(error => {
            notifyError(error);
        })
    }

    return (
        <Menu id={"menu-" + survey.id + "-" + survey.type + "-" + survey.status}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              className={"menu-border-" + color}
        >
            {
                (survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) &&
                <div>
                    <MenuItem key={1} onClick={() => {handleCloseMenu(); handlePublish(survey)}}>
                        <PlayIcon className={"mr10 contrast-blue-icon h-21 w-21"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("webloom.publish"))}</span>
                    </MenuItem>
                    <MenuItem key={2} onClick={() => {handleEdit(survey)}}>
                        <EditIcon className={"mr10 contrast-blue-icon h-21 w-21"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("core.edit"))}</span>
                    </MenuItem>
                    {
                        survey.type !== SurveyTypes.MESSAGE &&
                        <MenuItem key={3} onClick={handleCopyLink}>
                            <LinkIcon className={"mr-10 contrast-blue-icon h-21 w-21"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("webloom.copyLinkParam", {param:(survey.type === SurveyTypes.POLL ? t("webloom.thePoll") : survey.type === SurveyTypes.IDEABOX ? t("webloom.theIdeabox") : t("webloom.theAlert"))}))}</span>
                        </MenuItem>
                    }
                    <MenuItem key={4} onClick={handleDuplicate}>
                        <DuplicateIcon className={"mr-10 contrast-blue-icon h-21 w-21"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("core.duplicate"))}</span>
                    </MenuItem>
                    <MenuItem key={5} onClick={() => {handleCloseMenu(); handleDelete(survey)}}>
                        <DeleteIcon className={"mr10 contrast-blue-icon h-21 w-21"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("core.delete"))}</span>
                    </MenuItem>
                </div>
            }
            {
                survey.status === SurveyStatus.RUNNING &&
                <div>
                    {survey.type !== SurveyTypes.MESSAGE &&
                    <MenuItem key={1} onClick={() => {handleSeeDetail(survey)}} disabled={Boolean(survey.is_anonymous && survey.nbResponses<Limit.ANONYMITY)}>
                        <AnswerBubbleIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("webloom.results"))}</span>
                    </MenuItem>
                    }
                    <MenuItem key={2} onClick={() => {handleEdit(survey)}}>
                        <EditIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("core.edit"))}</span>
                    </MenuItem>
                    {
                        (survey.type !== SurveyTypes.MESSAGE && survey.type !== SurveyTypes.ALERT) &&
                        <MenuItem key={3} onClick={() => {handleCloseMenu(); handleBoost(survey)}}>
                            <BoostIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("webloom.boost"))}</span>
                        </MenuItem>
                    }
                    {
                        survey.type !== SurveyTypes.MESSAGE &&
                        <MenuItem key={4} onClick={handleCopyLink}>
                            <LinkIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("webloom.copyLinkParam", {param:(survey.type === SurveyTypes.POLL ? t("webloom.thePoll") : survey.type === SurveyTypes.IDEABOX ? t("webloom.theIdeabox") : t("webloom.theAlert"))}))}</span>
                        </MenuItem>
                    }
                    <MenuItem key={5} onClick={handleDuplicate}>
                        <DuplicateIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("core.duplicate"))}</span>
                    </MenuItem>
                    <MenuItem key={6} onClick={() => {handleCloseMenu(); handleStop(survey);}}>
                        <StopIcon className={"mr-10 purple-icon h-21 w-21"}/><span className={"text-purple"}>{_.upperFirst(t("webloom.stop"))}</span>
                    </MenuItem>
                </div>
            }
            {
                survey.status === SurveyStatus.DONE &&
                <div>
                    {
                        survey.type !== SurveyTypes.MESSAGE &&
                        <MenuItem key={1} onClick={() => {handleSeeDetail(survey)}} disabled={Boolean(survey.is_anonymous && survey.nbResponses<Limit.ANONYMITY)}>
                            <AnswerBubbleIcon className={"mr10 contrast-purple-icon h-21 w-21"}/><span className={"text-contrast-purple"}>{_.upperFirst(t("webloom.results"))}</span>
                        </MenuItem>
                    }
                    <MenuItem key={2} onClick={() => {handleCloseMenu(); handleReopen(survey)}}>
                        <PlayIcon className={"mr10 contrast-purple-icon h-21 w-21"}/><span className={"text-contrast-purple"}>{_.upperFirst(t("webloom.reopen"))}</span>
                    </MenuItem>
                    <MenuItem key={3} onClick={handleDuplicate}>
                        <DuplicateIcon className={"mr10 contrast-purple-icon h-21 w-21"}/><span className={"text-contrast-purple"}>{_.upperFirst(t("core.duplicate"))}</span>
                    </MenuItem>
                    <MenuItem key={4} onClick={() => {handleCloseMenu(); handleArchive(survey)}}>
                        <ArchiveIcon className={"mr10 contrast-purple-icon h-21 w-21"}/><span className={"text-contrast-purple"}>{_.upperFirst(t("core.collaborative.archive"))}</span>
                    </MenuItem>
                </div>
            }
            {
                survey.status === SurveyStatus.ARCHIVED &&
                <div>
                    {
                        survey.type !== SurveyTypes.MESSAGE &&
                        <MenuItem key={1} onClick={() => {handleSeeDetail(survey)}} disabled={Boolean(survey.nbResponses<5)}>
                            <AnswerBubbleIcon className={"mr10 grey-icon h-21 w-21"}/><span className={"text-grey"}>{_.upperFirst(t("webloom.results"))}</span>
                        </MenuItem>
                    }
                    <MenuItem key={2} onClick={handleDuplicate}>
                        <DuplicateIcon className={"mr10 grey-icon h-21 w-21"}/><span className={"text-grey"}>{_.upperFirst(t("core.duplicate"))}</span>
                    </MenuItem>
                    <MenuItem key={3} onClick={() => {handleCloseMenu(); handleDelete(survey)}}>
                        <DeleteIcon className={"mr10 grey-icon h-21 w-21"}/><span className={"text-grey"}>{_.upperFirst(t("core.delete"))}</span>
                    </MenuItem>
                </div>
            }
        </Menu>
    )
}

WeBloomDashboardItemMenu.propTypes = {
    survey:PropTypes.object.isRequired,
    handleDelete:PropTypes.func.isRequired,
    color:PropTypes.string.isRequired,
    anchorEl:PropTypes.any,
    handleCloseMenu:PropTypes.func.isRequired,
    isGroup:PropTypes.bool
}

WeBloomDashboardItemMenu.defaultProps = {
    isGroup : false
}
export default WeBloomDashboardItemMenu