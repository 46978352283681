import React, {useState, useEffect} from "react";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import HttpApi from "../../../../../httpApi";
import moment from "moment";
import {notifyError} from "../../../../../Utils/Notification";
import {Colors} from "../../../../../Utils/Colors";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PlannerEditor = (props) => {

    const [startDate, setStartDate] = useState(moment().startOf("week").add(1, "day").format("YYYY-MM-DD"));
    const [companiesData, setCompaniesData] = useState([]);

    const t = useTranslation()

    const fetchCompaniesStatistics = () => {
        HttpApi.getV2("/companies/getCompaniesStatForWeekSurvey/" + startDate).then(response => {
            if(response && response.data){
                setCompaniesData(response.data);
            }else{
                setCompaniesData([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleNext = () => {
        setStartDate(moment(startDate).add(1, "week").startOf("week").add(1, "day").format("YYYY-MM-DD"));

    }

    const handlePrevious = () => {
        setStartDate(moment(startDate).subtract(1, "week").startOf("week").add(1, "day").format("YYYY-MM-DD"));
    }

    const handleToday = () => {
        setStartDate(moment().startOf("week").add(1, "day").format("YYYY-MM-DD"));
    }

    useEffect(() => {
        fetchCompaniesStatistics();
    }, [startDate]);


    const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday"];

    return(
        <CardDefault title={_.capitalize(t("backoffice.generalPlanner"))} showButton={false}>
            <Row>
                <div style={{float:"left", marginLeft:20}}>
                    <KeyboardArrowLeftIcon style={{cursor:"pointer", color:Colors.EBLOOM_PURPLE}} onClick={handlePrevious}/>
                    <Button variant={"link"} style={{color:Colors.EBLOOM_PURPLE, textDecoration:"underline", fontFamily:"Lato", fontSize:14}} onClick={handleToday}>{_.capitalize(t("core.day.today"))}</Button>
                    <KeyboardArrowRightIcon style={{cursor:"pointer", color:Colors.EBLOOM_PURPLE}} onClick={handleNext}/>
                </div>
            </Row>
            <Row md={5}>
                {
                    companiesData.map((day, index) => (
                            <Col key={index} style={{padding:2}}>
                                <Card style={{borderColor: Colors.EBLOOM_CONTRAST_PURPLE}}>
                                    <Card.Header style={{
                                        backgroundColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                        color: "white",
                                        textAlign: "center",
                                        fontFamily: "Lato"
                                    }}>
                                        <span>{_.capitalize(t("core.day."+ weekDays[index]))} </span>
                                        <span style={{
                                            opacity: 1,
                                            fontSize: 16
                                        }}>{moment(startDate).add(index, "day").format("DD/MM")}</span>
                                    </Card.Header>
                                    <Card.Body style={{minHeight: 66.67}}>
                                        <ListGroup variant={"flush"} style={{textAlign: "center"}}>
                                            {
                                                day && day.map((company, index) => (
                                                    <ListGroup.Item
                                                        key={index}>{company.name + " (" + company.nbCB + ")"}</ListGroup.Item>
                                                ))
                                            }
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                }

            </Row>
        </CardDefault>
    )
}

export default PlannerEditor;