import React from "react";
import PropTypes from "prop-types"
import {SurveyStatus} from "../../Polls/Modules/Types";
import moment from "moment/moment";
import {useTranslation} from "react-multi-lang/lib";
import {displayDate as formatDate} from "../../../../../../Utils/Global";

const WeBloomDashboardItemProgressBar = (props) => {

    //state

    const {survey} = props;

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers

    const displayProgress = () => {
        return survey.status === SurveyStatus.RUNNING ? null === survey.end_date ? "w-full bg-gradient-to-r from-purple/[0.2] rounded-xl h-20" : "bg-purple/[0.2] rounded-xl h-20" : survey.status === SurveyStatus.DONE ? "bg-contrast-purple text-white rounded-xl h-20" : survey.status === SurveyStatus.ARCHIVED ? "bg-grey text-white rounded-xl h-20" : "bg-inherit rounded-xl h-20";
    }

    const getWidth = () => {
        if(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED){
            return 0;
        }else if(survey.status === SurveyStatus.DONE || survey.status === SurveyStatus.ARCHIVED){
            return "100%";
        }
        const startDate = survey.start_date || survey.created_at;
        const endDate = survey.end_date;

        if(!endDate){
            return "100%";
        }
        const totalDays = moment(endDate).endOf("day").diff(moment(startDate), "minutes");
        const now = moment().diff(moment(startDate), "minutes");
        return Math.round((now/totalDays)*100) + "%";
    }

    const getClassName = () => {
        let className = "border rounded-xl center text-sm w-full relative";
        switch (survey.status){
            case SurveyStatus.DRAFT:
                className += " border-contrast-blue text-black";
                break;
            case SurveyStatus.SCHEDULED:
                className += " border-contrast-blue text-black";
                break;
            case SurveyStatus.RUNNING:
                className += " border-purple text-black";
                break;
            case SurveyStatus.DONE:
                className += " border-contrast-purple text-white";
                break;
            case SurveyStatus.ARCHIVED:
                className += " border-grey text-white";
                break;
            default:
                className += " border-contrast-blue text-black";
                break;
        }
        return className;
    }

    const displayDate = () => {
        return (survey.start_date ? formatDate(survey.start_date, true) : formatDate(survey.created_at, true))+ " " + t("core.to") + " " + (survey.end_date ? formatDate(survey.end_date, true) : "...");

    }

    //useEffect


    return (
        <div className={getClassName()}>
            <div className={displayProgress()} style={{width:getWidth()}}/>
            <div className={"webloom-progress-date"}>
                {displayDate()}
            </div>
        </div>
    )
}

WeBloomDashboardItemProgressBar.propTypes = {
    survey:PropTypes.object.isRequired
}

WeBloomDashboardItemProgressBar.defaultProps = {}
export default WeBloomDashboardItemProgressBar