import {useBreakpoints} from "../../../../hooks/breakpointsHook";
import PropTypes from "prop-types";
import EbloomInfoTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";

const MyBloomContainer = (props) => {

    const {title,tooltip} = props;
    const bp = useBreakpoints()
    return <div
        className={'rounded-md border-1 border-contrast-purple relative bg-bg-white h-full z-0' + (bp.isTabletLandscapeOrLess() && " mt-40 w-full ")}>
        <div className={'bg-contrast-purple rounded-md px-30 p-5 absolute left-0 right-0 mx-auto max-w-fit -top-15'}>
            <h1 className={'font-semibold text-white text-center mx-auto'}>{title}</h1>
        </div>
        {tooltip && <div className={"absolute right-10 top-10"}>
            <EbloomInfoTooltip text={tooltip} placement={"bottom"}/>
        </div>}
        <div className={'mt-40 mx-10 relative '}>
            {props.children}
        </div>
    </div>
}

MyBloomContainer.propTypes={
    title : PropTypes.string.isRequired,
    tooltip : PropTypes.string
}

MyBloomContainer.defaultProps={
    tooltip : null
}

export default MyBloomContainer