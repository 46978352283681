import React, {useState, useEffect} from "react";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {toPng} from "html-to-image";
import {copyImageToClipboard} from "copy-image-clipboard";
import {notifyError, notifySuccess} from "../../../../../../../Utils/Notification";
import download from "downloadjs";
import ResultFrame from "./ResultFrame";
import logo_ebloom from "../../../../../../Core/symbols/logo/ebloom.svg";
import moment from "moment";
import {ReactComponent as PersonIcon} from '../../../../../../Core/symbols/user-solo-white.svg';


const Name = (props) => {

    const [loadingDownload, setLoadingDownload] = useState(false);

    const {question, anonymityRestriction, noData,setShowModalProspect, isProspect} = props;

    const t = useTranslation();

    const handleCopy = () => {
        let node = document.getElementById("result-frame-" + question.id);
        let img = document.getElementById("img-" + question.id);
        img.style.visibility = "visible";
        toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
            img.style.visibility = "hidden";
            copyImageToClipboard(dataUrl).then(() => {
                notifySuccess(_.upperFirst(t("webloom.notification.imageCopied")));
            }).catch(error => {
                notifyError(error);
            })
        })
    }

    const handleDownload = () => {
        if(!loadingDownload){
            setLoadingDownload(true);
            let node = document.getElementById("result-frame-" + question.id);
            let img = document.getElementById("img-" + question.id);
            img.style.visibility = "visible";
            toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
                img.style.visibility = "hidden";
                download(dataUrl, 'webloom-'+ question.title +'.png');
            }).finally(() => {
                setLoadingDownload(false);
            })
        }
    }

    useEffect(() => {

    }, [])

    return (
        <ResultFrame question={question} handleCopy={handleCopy} handleDownload={handleDownload} anonymityRestriction={anonymityRestriction} isIndividualView={props.isIndividualView} setShowModalProspect={setShowModalProspect} isProspect={isProspect}>
            <div className={"text-contrast-purple"}>
                {
                    (anonymityRestriction || noData) ?
                        <div className={"text-contrast-purple text-center"}>{_.upperFirst(t(noData ? "core.collaborative.noAnswer" : "manager.dashboard.anonymousRestriction"))}</div>
                        :
                        <div className={"mt-20 max-h-500 overflow-y-auto px-5"}>
                            {
                                question.answers && question.answers.map((answer, index) => (
                                    <div className={"my-10"} key={index}>
                                        <div className={"text-contrast-purple ebloom-text ebloom-very-small-text text-right"}>{moment(answer.created_at).format("DD/MM/YY HH:mm")}</div>
                                        <div className={"flex w-full p-10 text-contrast-purple rounded-lg bg-contrast-purple/[0.1]"}>
                                            <PersonIcon className={"h-25 w-25 contrast-purple-icon mr-10"}/>
                                            <p className={"text-line-break"}>{answer.text}</p>
                                        </div>
                                    </div>

                                ))
                            }
                        </div>

                }
            </div>
            <img id={"img-" + question.id} alt={"ebloom-logo"} src={logo_ebloom} width={50} style={{visibility:"hidden"}}/>
        </ResultFrame>
    )
}

Name.propTypes = {}

Name.defaultProps = {}
export default Name