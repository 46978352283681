import React, {useEffect, useState} from "react";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import PlaylistList from "./PlaylistList";
import {Container} from "react-bootstrap";
import EntitiesSwitcher from "../../../../Core/Modules/Button/EntitiesSwitcher";
import {useUserData} from "../../../../../hooks/userHook";
import {useParams, useSearchParams} from "react-router-dom";



const PlaylistListPage = (props) => {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const user = useUserData()

    let [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.has("entity") && user.isSuperAdmin && user.companyGroup !== null ){
            setSelectedEntity(parseInt(searchParams.get("entity")))
        }
    }, [searchParams]);
    return(
        <DisplayAdminView {...props} menu={"questions"} pageName={"core.playlist.playlists"} selectedIndex={4}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher entity={selectedEntity} handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true}/>
                            </div>
                        }
                        <PlaylistList selectedEntity={selectedEntity} {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default PlaylistListPage;