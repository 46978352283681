import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {defaultColors, listIcon, randomColor, selectIcon} from "../Assets/CategoriesAssets";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import IconPicker from "../Inputs/IconPicker";
import ColorPicker from "../Inputs/ColorPicker";
import {ReactComponent as DeleteIcon} from "../../../../Core/symbols/webloom/white/delete-white.svg";
import {ReactComponent as EditIcon} from "../../../../Core/symbols/general/Edit.svg";
import {useOutsideAlerter} from "../../../../../hooks/outsideAlerterHook";
import {Colors} from "../../../../../Utils/Colors";
import _ from "lodash";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import {createErrorNotification} from "../../../../../Utils/Notification";
import {useTranslation} from "react-multi-lang";

const Category = (props) => {
    const { handleDeleteCategory, category,setNameCategory,setIcon,setEditActivated,editActivated,handleEdit,color,icon,handleColor,nameCategory,indexEdit,setIndexEdit,handleSave } = props

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayIconPicker, setDisplayIconPicker] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const ref = useRef(null);

    const t = useTranslation()

    const handleHover = (isHover) => {
        setIsHover(isHover)
    }

    const handleChangeName = (event) => {
        setNameCategory(event.target.value)
    }



    const handleChangeIcon = (icon) => {
        if(icon.toString().length < 2){
            const value = '0'+icon
            setIcon(value)
        }else{
            setIcon(String(icon))
        }

    }

    const handleCloseEdit = () => {
        if(editActivated && indexEdit === category.id_category) {
            setEditActivated(false)
            setIndexEdit(-1)
            handleSave(category)
        }

    }

    const handleModal = () => {
      if(category.tags.length >0 ){
          setShowModal(true)
      } else {
          handleDeleteCategory(category.id_category)
      }
    }
    useOutsideAlerter(ref, () => handleCloseEdit())

    return (
        <div
            ref={ref}
            className={"relative p-10 mb-5 w-full rounded-md border-1 border-contrast-purple hover:border-2 hover:border-purple cursor-default"}
            onMouseOver={() => handleHover(true, category.id_category)}
            onMouseOut={() => handleHover(false, category.id_category)}
            style={
                {
                    backgroundColor: (editActivated && indexEdit === category.id_category) ? color : category.color ?  category.color : randomColor()
                }
            }
        >
            {
                ( isHover && !editActivated ) &&
                <div className={"absolute right-10 top-5 flex justify-center items-center flex-row"}>
                    <EbloomTooltip text={_.upperFirst(t("core.edit"))}>
                        <button onClick={() =>handleEdit(category)} className={"w-15 h-15 purple-icon mr-7"}>
                            <div className={"mt-1"}>
                                <EditIcon className={"w-full h-full purple-icon"}/>
                            </div>

                        </button>

                    </EbloomTooltip>
                    <EbloomTooltip text={_.upperFirst(t("core.delete"))}>
                        <button onClick={() => handleModal()} className={"w-13 h-13 purple-icon"}>
                            <div>
                                <DeleteIcon className={"w-full h-full purple-icon"}/>
                            </div>

                        </button>
                    </EbloomTooltip>
                </div>
            }
            {
                (editActivated && indexEdit === category.id_category) &&

                <div className={"absolute right-10 top-5 flex justify-center items-center flex-row"}>
                    <EbloomTooltip text={_.upperFirst(t("core.delete"))}>
                        <button onClick={() => handleModal()} className={"w-13 h-13 purple-icon"}>
                            <div>
                                <DeleteIcon className={"w-full h-full purple-icon"}/>
                            </div>

                        </button>
                    </EbloomTooltip>
                </div>
            }
            {
                (editActivated && indexEdit === category.id_category) ?
                    <div className={"flex flex-col justify-center items-center w-full h-full"}>
                        <IconPicker color={color} displayIconPicker={displayIconPicker} handleIcon={handleChangeIcon} iconId={icon} listIcons={listIcon} setDisplayIconPicker={setDisplayIconPicker}/>
                        <div className={"border-1 bg-white/50 border-disabled-grey rounded-md my-10 w-full "}>
                            <input onChange={handleChangeName} maxLength={23} minLength={1} className={"bg-white/50 px-5 w-full text-center font-semibold"} value={nameCategory} />
                        </div>
                        <div className={"flex flex-row justify-center items-center w-3/4"}>
                            <ColorPicker color={color} displayPicker={displayColorPicker} setDisplayPicker={setDisplayColorPicker} handleColor={handleColor}/>
                            <div className={"border-1 bg-white/50 border-disabled-grey rounded-md my-10 w-80 "}>
                                <input onChange={(event) => {handleColor(event.target.value)}} maxLength={8} minLength={6} className={"bg-white/50 w-full px-5 text-center font-semibold"} value={_.toUpper(color)} />
                            </div>
                        </div>

                    </div>
                    :
                    <div className={"flex flex-col justify-center items-center w-full h-full"}>
                        <div className={"w-25 h-25"}>
                            {selectIcon(category.icon)}
                        </div>

                        <h1 className={"w-full truncate text-center font-semibold mt-3"}>{category.name}</h1>
                    </div>

            }
            <ConfirmModal handleChange={()=> handleDeleteCategory(category.id_category)} handleClose={()=> setShowModal(false)} show={showModal} title={_.upperFirst(t("admin.customCategories.modalDeleteTitle"))} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.delete"))}>
                <div>
                    <p>{_.upperFirst(t("admin.customCategories.modalDeleteContent"))}</p>
                </div>
            </ConfirmModal>
        </div>
    );
};

Category.propTypes = {
    handleSave: PropTypes.func.isRequired,
    setIndexEdit: PropTypes.func.isRequired,
    indexEdit  : PropTypes.number.isRequired,
    setNameCategory : PropTypes.func.isRequired,
    setIcon : PropTypes.func.isRequired,
    setEditActivated : PropTypes.func.isRequired,
    editActivated : PropTypes.bool.isRequired,
    handleEdit : PropTypes.func.isRequired,
    color : PropTypes.string.isRequired,
    icon : PropTypes.string.isRequired,
    handleColor : PropTypes.func.isRequired,
    nameCategory : PropTypes.string.isRequired,
    handleDeleteCategory : PropTypes.func.isRequired,
    category : PropTypes.object.isRequired
};

export default Category;
