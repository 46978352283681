import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as BubbleIcon} from "../../../../../../Core/symbols/webloom/bubble-coma.svg";
import {ReactComponent as BubbleIconRight} from "../../../../../../Core/symbols/webloom/bubble-coma-right.svg";
import {ReactComponent as SendIcon} from "../../../../../../Core/symbols/general/Send.svg";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {ReactComponent as ArrowDown} from "../../../../../../Core/symbols/general/ArrowDown.svg";
import {FullContrastBlueTextField, replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import {IconButton, InputAdornment} from "@mui/material";
import LoadingView from "../../../../../../Core/Layouts/LoadingView";

const BubbleChat = (props) => {

    const [input, setInput] = useState("");

    const {title, text, isRead, imageUrl, attachmentUrl, isMyMessage, handleSave, nbConversations, loading} = props;

    const handleInput = (event) => {
        setInput(event.target.value);
    }

    return(
        <div className={"grid grid-rows-1 " + props.className}>
            <div className={"w-full p-10 grid grid-rows-1 " + (isMyMessage ? "text-contrast-blue" : "text-contrast-purple") + " rounded-lg " + (isRead ? isMyMessage ? "bg-contrast-blue/[0.1]" : "bg-contrast-purple/[0.1]" : isMyMessage ? "bg-contrast-blue/[0.3]" : "bg-contrast-purple/[0.3]")}>
                {
                    imageUrl &&
                    <FileDisplay className={"my-10"} urlParams={imageUrl} displayImg/>
                }
                {
                    handleSave ?
                        <FullContrastBlueTextField variant={"outlined"} size={"small"} value={input} fullWidth onChange={handleInput} multiline InputProps={{
                            endAdornment : (
                                <InputAdornment position="end">
                                    {
                                        loading ?
                                            <LoadingView size={15}/>
                                            :
                                            <IconButton
                                                edge="end"
                                                onClick={() => {handleSave(input); setInput("")}}
                                            >
                                                <SendIcon className={"icon-purple w-25 h-25"}/>
                                            </IconButton>
                                    }

                                </InputAdornment>
                            )
                        }}/>
                        :
                      <div>
                          {
                              title &&
                              <span className={"font-semibold text-contrast-purple ebloom-large-text ebloom-text"}>{title}</span>
                          }
                          <p className={'text-line-break'}>{replaceUrlsWithLinks(text)}</p>
                      </div>
                }
                {
                  attachmentUrl &&
                  <FileDisplay className={"mt-10"} urlParams={attachmentUrl}/>
                }
                {
                    (nbConversations > 0) &&
                    <div className={"flex justify-end items-center"}>
                        <ArrowDown className={"w-15 h-15"}/>
                        <span className={"font-semibold text-purple ml-5"}>{nbConversations}</span>
                    </div>
                }
            </div>
            {
                isMyMessage ?
                    <BubbleIconRight className={"mr-20 contrast-blue-icon low-opacity-icon justify-self-end"}/>
                    :
                    <BubbleIcon className={isRead ? "ml-20 contrast-purple-icon low-opacity-icon" : "ml-20 contrast-purple-icon opacity-icon"}/>
            }
        </div>

    )
}

BubbleChat.propTypes = {
    title:PropTypes.string,
    text:PropTypes.string.isRequired,
    isRead:PropTypes.any,
    imageUrl:PropTypes.string,
    attachmentUrl:PropTypes.string,
    isMyMessage:PropTypes.bool,
    handleSave:PropTypes.func,
    nbConversations:PropTypes.number,
    loading:PropTypes.bool,
}

BubbleChat.defaultProps = {
    isRead:true,
    isMyMessage:false,
    loading:false,
}
export default BubbleChat;