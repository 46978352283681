import React, {useEffect, useState} from "react";
import {Card, Row} from "react-bootstrap";
import EbloomEvolutionChart from "../Modules/Chart/EbloomEvolutionChart";
import HttpApi from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import _ from "lodash";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import {Divider, FormControl} from "@mui/material";
import {Colors} from "../../../Utils/Colors";
import {useTranslation} from "react-multi-lang/lib";


const CategoryEvolutionLayout = (props) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const t = useTranslation();

    const {companyToEdit,isModelCustomEnable,commonSurveyActivated} = props

    const fetchMainCategories = () => {
        HttpApi.getV2("/categories/getMainCategories").then(response => {
            if(response && response.data){
                setCategories(response.data);
                let tempArray = [];
                tempArray.push(response.data[0]);
                setSelectedCategories(tempArray);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleChange = (event) => {
        if(event.target.value.includes(-1)){
            if(selectedCategories.length === categories.length){
                setSelectedCategories([]);
            }else{
                setSelectedCategories(categories);
            }
        }else{
            setSelectedCategories(event.target.value);

        }
    }


    useEffect(() => {fetchMainCategories()}, []);

    return(
        <Card style={{width:750, border:"none"}}>
            <Card.Body>
                <Row>
                    <FormControl variant={"outlined"} fullWidth className={"m-10"}>
                        <InputLabel className={"text-contrast-purple bg-bg-white"} id="idUserAdvancedTeams">{_.capitalize(t("core.category.category"))}</InputLabel>
                        <Select
                            className={"text-purple border border-purple"}
                            labelId="idCategoryEvolvingGraph"
                            id="idCategoryEvolvingGraph"
                            multiple
                            name={"selectedCategories"}
                            value={selectedCategories}
                            onChange={handleChange}
                            inputProps={{'aria-label': _.capitalize(t("admin.advancedUserHelper"))}}
                            renderValue={(selected) => (
                                <div className={"flex flex-wrap"}>
                                    {selected.map((value, index) => {
                                        let name = isModelCustomEnable ? value.name : _.upperFirst(t("core.category."+value.name))
                                        return(
                                            <Chip key={index} label={name} className={"m-2"} style={{backgroundColor:value.color}} />
                                        )
                                    })}
                                </div>
                            )}
                        >
                            <MenuItem key={-1} value={-1}>
                                <Checkbox className={"checkbox-purple"} checked={selectedCategories.length === categories.length} />
                                {_.capitalize(t("core.all"))}
                            </MenuItem>
                            <Divider/>
                            {categories.map((category, index) => {
                                let name = isModelCustomEnable ? category.name : _.upperFirst(t("core.category."+category.name))
                                return(
                                    <MenuItem key={index} value={category}>
                                        <Checkbox className={"checkbox-purple"} checked={selectedCategories.indexOf(category) > -1} />
                                        {name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Row>
                {
                    selectedCategories.length === 1 &&
                        <Row className="justify-content-center">
                            <h6 style={{marginBottom:-20}}>{isModelCustomEnable ? selectedCategories[0].name : _.upperFirst(t("core.category."+selectedCategories[0].name))}</h6>
                        </Row>
                }
                <Row style={{width:750, height:400}}>
                    <EbloomEvolutionChart isModelCustomEnable={isModelCustomEnable} displayCategories={selectedCategories} commonSurveyActivated={commonSurveyActivated} {...props}/>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CategoryEvolutionLayout;