import {Colors} from "./Colors";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import Switch from "@mui/material/Switch";
import moment from "moment";
import {alpha, styled} from "@mui/material/styles"
import HttpApi from "../httpApi";
import {getTranslations} from "../lang/translations";
import {getLanguage} from "react-multi-lang";
import DOMPurify from "dompurify";
import HTMLReactParser from 'html-react-parser';

export const Limit = {
    CB_FREE: 10,
    CB_HAPPY: 5,
    CB_VERY_HAPPY:10,
    CB_SUPER_HAPPY: 1000,
    ANONYMITY:5,
};

export const Roles = {
    USER: ["global_employee"],
    ADVANCED_USER: ["global_manager"],
    USER_ADVANCED_USER : ["global_employee", "global_manager"],
    ADMIN: ["global_admin"],
    SUPER_ADMIN: ["global_super_admin"],
    BACKOFFICE: ["global_backoffice"],
    DEMO_BACKOFFICE:["demo_backoffice"],
    DATATOPIA_BACKOFFICE:["datatopia_backoffice"],
    BACKOFFICE_DEMO_BACKOFFICE:["global_backoffice", "demo_backoffice"],
    BACKOFFICE_GLOBAL_DATATOPIA:["global_backoffice", "datatopia_backoffice"],
    BACKOFFICE_DEMO_DATATOPIA_GLOBAL:["global_backoffice", "demo_backoffice", "datatopia_backoffice"],
    ALL: ["global_employee", "global_manager", "global_admin"],
    ALL_BACKOFFICE: ["global_employee", "global_manager", "global_admin", "global_backoffice"],
    ALL_BACKOFFICE_DEMO:  ["global_employee", "global_manager", "global_admin", "global_backoffice", "demo_backoffice"],
    ALL_BACKOFFICE_DEMO_DATATOPIA:["global_employee", "global_manager", "global_admin", "global_backoffice", "demo_backoffice", "datatopia_backoffice"],
    MORE_THAN_USER: ["global_manager", "global_admin"],
    MORE_THAN_USER_BACKOFFICE: ["global_manager", "global_admin", "global_backoffice"],
    ADMIN_BACKOFFICE: ["global_admin", "global_backoffice"],
}

export const SurveyQuestionRecipients = {
    ALL : "all",
    TEAMS:"teams",
    ENTITIES:"entities"
}


export const Modules = {
    HAPPY:["happy"],
    VERY_HAPPY: ["very_happy"],
    SUPER_HAPPY: ["super_happy"],
    FREE: ["free"],
    PROSPECT : ["prospect"],
    NOT_PROSPECT  :["happy", "very_happy", "super_happy", "free"],
    MORE_THAN_HAPPY: ["very_happy", "super_happy", "free","prospect"],
    MORE_THAN_HAPPY_NOT_PROSPECT: ["very_happy", "super_happy", "free"],
    ALL: ["happy", "very_happy", "super_happy", "free","prospect"],
    MORE_THAN_HAPPY_NOT_FREE: ["very_happy", "super_happy","prospect"],
    NOT_FREE:["happy", "very_happy", "super_happy"]
}

export const SettingTypes = {
    CREDITS:"credits",
    NB_CB: "nbCb",
    MIN_TEAM_MEMBER:"minTeamMember",
    MAX_USER:"maxUser",
    REMINDER:"reminder",
    REMINDER_MORNING:"reminderMorning",
    REMINDER_MIDDLE:"reminderMiddle",
    REMINDER_LAST:"reminderLastDay",
    MAIL_TEXT:"mailText",
    MAX_INVITE:"maxInvite",
    MAIL_SYSTEM:"mailSystem",
    NOTIF_TIME:"notifTime",
    PUBLIC_HOLIDAY: "publicHoliday",
    BLUE_COLLAR: "blueCollar",
    PRODUCT_TOUR : "productTour",
    WINDOW:"window",
    CUSTOM_CATEGORIES:"customCategories",
    TRANSLATION:"translation",
    SHOW_TRANSLATION:"showTranslation",
    SHOW_FREE_TRIAL : "showFreeTrial",
    GOOGLE_REVIEWS:"googleReviews",
    ALERT:"alert",
    COLORS: "colors",
    BLUE_COLLAR_LIBRARY: "blueCollarLibrary",
    BENCHMARK: "benchmark",
}

export const Mail = {
    MAILJET:1,
    MANDRILL:2,
    EC2_SMTP:3,
    MAILERSEND:4,
}

export const CompanyTypes = {
    PROSPECT:"prospect",
    CLIENT:"client",
    DEMO:"demo",
    FREE:"free",
    GROUP : "group",
    DATATOPIA:"datatopia",
    OTHER:"other"
}

export const IdentityTypes = {
    GLOBAL : "global",
    TEAMS : "teams",
}

export const UserStatus = {
    REGISTERED:"registered",
    INVITED:"invited",
    SAVED:"saved"
}

export const GENERIC_URL_PRICE_IT = "https://app.getgivemefive.com/myOffer/ebloom/ecd0b0669/d856d25f-f063-4df5-b0c1-88d2e91e19ab"

export const LIST_FEATURES_FREE = [
    "listFeatureFree-audit","listFeatureFree-result","listFeatureFree-teams","listFeatureFree-webloom","listFeatureFree-users","listFeatureFree-weeks","listFeatureFree-languages","listFeatureFree-noCard"
]


export const FaqType = {
    INFOS: "Infos",
    BEST_PRACTICES: "Best practices",
    HOW_TO : "How to"
}

export const TourType = {
    COMMUNICATION : 'communication' ,
    INVITE : 'invite',
    MANAGE_USERS: 'manageUsers',
    DEFINE_ROLES : 'defineRoles',
    MANAGE_TEAMS: 'manageTeams',
    CUSTOM_LIBRARY : 'customLibrary',
    BROWSE_LIBRARY : 'browseLibrary',
    CREATE_PLAYLIST : 'createPlaylist',
    CREATE_CLICK_AND_BLOOM : 'createClickAndBloom',
    PLAN_CLICK_AND_BLOOM : 'planClickAndBloom',
    LAUNCH_WEBLOOM : 'launchWeBloom',
}

export const TourTypePackages = {
    "happy" : [
        TourType.COMMUNICATION,
        TourType.INVITE,
        TourType.MANAGE_USERS,
        TourType.BROWSE_LIBRARY,
        TourType.CREATE_PLAYLIST,
        TourType.CREATE_CLICK_AND_BLOOM,
        TourType.PLAN_CLICK_AND_BLOOM,
        TourType.LAUNCH_WEBLOOM,
        TourType.CUSTOM_LIBRARY,
        TourType.DEFINE_ROLES
    ],
    "very_happy" :  [
        ...Object.values(TourType)
    ],
    "free": [
        TourType.COMMUNICATION,
        TourType.INVITE,
        TourType.MANAGE_USERS,
        TourType.MANAGE_TEAMS,
        TourType.CUSTOM_LIBRARY,
        TourType.LAUNCH_WEBLOOM,
        TourType.DEFINE_ROLES,
        TourType.PLAN_CLICK_AND_BLOOM
    ],
    "super_happy": [
        ...Object.values(TourType)
    ]
}

export const TourUrl = {
    communication : '/admin/communication' ,
    invite : '/admin/users',
    defineRoles : '/admin/users',
    manageUsers: '/admin/users',
    manageTeams: '/admin/teams',
    customLibrary : '/admin/identity',
    browseLibrary : '/admin/questions/library',
    createPlaylist : '/admin/questions/playlist',
    createClickAndBloom : '/admin/questions/submit',
    planClickAndBloom : '/admin/questions/planner',
    launchWeBloom : '/admin/webloom',
}

export const OnboardingStep = {
    VERIFICATION: 1,
    USER:2,
    COMPANY:3,
}

export const IntegrationType = {
    LDAP:"ldap",
    GOOGLE:"google",
    MICROSOFT:"microsoft",
    SAP:"sap"
}

export const QuestionSectors = {
    GLOBAL:"global",
    BLUE_COLLAR:"blueCollar",
}


export const PurpleSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: Colors.EBLOOM_PURPLE,
        '&:hover': {
            backgroundColor: alpha(Colors.EBLOOM_PURPLE, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: Colors.EBLOOM_PURPLE,
    },
}))

export const FullContrastBlueSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: Colors.EBLOOM_CONTRAST_BLUE,
        '&:hover': {
            backgroundColor: alpha(Colors.EBLOOM_CONTRAST_BLUE, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: Colors.EBLOOM_CONTRAST_BLUE,
    },
}))
export const WhiteBlueSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: Colors.EBLOOM_WHITE_BLUE,
        '&:hover': {
            backgroundColor: alpha(Colors.EBLOOM_WHITE_BLUE, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: Colors.EBLOOM_WHITE_BLUE,
    },
}))



export const PurpleTextField = styled(TextField)(({theme}) => ({

        '& label.Mui-focused': {
            color: Colors.EBLOOM_PURPLE
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "white",
            fontFamily:"Lato",
            fontSize:16,
            fontStyle:"normal",
            '&.Mui-focused fieldset': {
                borderColor: Colors.EBLOOM_PURPLE,
            },
        },
        '& .MuiFormHelperText-root': {
            color: Colors.EBLOOM_CONTRAST_BLUE
        }



}))

export const FullPurpleTextField = styled(TextField)(({theme}) => ({

        '& label.Mui-focused': {
            color: Colors.EBLOOM_PURPLE
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "white",
            '&.Mui-focused fieldset': {
                borderColor: Colors.EBLOOM_PURPLE,
            },
            '&:hover fieldset': {
                borderColor: Colors.EBLOOM_PURPLE,
            },
        },
        '& .MuiFormHelperText-root':{
            color:Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiFormLabel-root":{
            color:Colors.EBLOOM_PURPLE
        },
        "& .MuiOutlinedInput-root fieldset":{
            borderColor:Colors.EBLOOM_PURPLE
        },
        "& .MuiInputBase-input":{
            color:Colors.EBLOOM_PURPLE
        },
        "& .MuiSelect-iconOutlined":{
            color:Colors.EBLOOM_PURPLE
        }


}))

export const FullContrastPurpleTextField =  styled(TextField)(({theme}) => ({

        '& label.Mui-focused': {
            color: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "white",
            '&.Mui-focused fieldset': {
                borderColor: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE,
            },
            '&:hover fieldset': {
                borderColor: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE,
            },

        },
        '& .MuiFormHelperText-root':{
            color:Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiFormLabel-root":{
            color: theme?.primary ? theme?.primary :Colors.EBLOOM_CONTRAST_PURPLE
        },
        "& .MuiOutlinedInput-root fieldset":{
            borderColor: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
        },
        "& .MuiInputBase-input":{
            color: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
        },
        "& .MuiSelect-iconOutlined":{
            color: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE,
        },


}))

export const FullBlueTextField = styled(TextField)(({theme}) => ({

        '& label.Mui-focused': {
            color: Colors.EBLOOM_LOGO_BLUE
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "white",
            '&.Mui-focused fieldset': {
                borderColor: Colors.EBLOOM_LOGO_BLUE,
            },
            '&:hover fieldset': {
                borderColor: Colors.EBLOOM_LOGO_BLUE,
            },
        },
        '& .MuiFormHelperText-root': {
            color: Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiFormLabel-root": {
            color: Colors.EBLOOM_LOGO_BLUE
        },
        "& .MuiOutlinedInput-root fieldset": {
            borderColor: Colors.EBLOOM_LOGO_BLUE
        },
        "& .MuiInputBase-input": {
            color: Colors.EBLOOM_LOGO_BLUE
        },
        "& .MuiSelect-iconOutlined": {
            color: Colors.EBLOOM_LOGO_BLUE
        }


}))

export const FullContrastBlueTextField = styled(TextField)(({theme}) => ({

        '& label.Mui-focused': {
            color: Colors.EBLOOM_CONTRAST_BLUE
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "white",
            fontFamily:"Lato",
            fontSize:16,
            fontStyle:"normal",
            '&.Mui-focused fieldset': {
                borderColor: Colors.EBLOOM_CONTRAST_BLUE,
            },
            '&:hover fieldset': {
                borderColor: Colors.EBLOOM_CONTRAST_BLUE,
            },
        },
        '& .MuiFormHelperText-root': {
            color: Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiFormLabel-root": {
            color: Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiOutlinedInput-root fieldset": {
            borderColor: Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiInputBase-input": {
            color: Colors.EBLOOM_CONTRAST_BLUE
        },
        "& .MuiSelect-iconOutlined": {
            color: Colors.EBLOOM_CONTRAST_BLUE
        }


}))

export const categoryIcon = (categoryName, small=false) => {
    return _.lowerCase(categoryName.split(" ")[0]) + "_icon" + (small ? "_small" : "");
}

export const categoryName = (categoryInitial) => {
    switch (categoryInitial){
        case "work":
            return "Work & Lifestyle";
        case "team":
            return "Team & Interaction";
        case "management":
            return "Management & Leadership";
        case "policy":
            return "Policy & Support";
        case "adhesion":
            return "Adhesion & Culture";
        default :
            return "Anonymity";
    }
}

export const publicHolidays = (year) => {
    let JourAn = moment(new Date(year, 0, 1)).format("YYYY-MM-DD");
    let FeteTravail = moment(new Date(year, 4, 1)).format("YYYY-MM-DD");
    let FeteNationale = moment(new Date(year,6, 21)).format("YYYY-MM-DD");
    let Assomption = moment(new Date(year, 7, 15)).format("YYYY-MM-DD");
    let Toussaint = moment(new Date(year, 10, 1)).format("YYYY-MM-DD");
    let Armistice = moment(new Date(year, 10, 11)).format("YYYY-MM-DD");
    let Noel = moment(new Date(year, 11, 25)).format("YYYY-MM-DD");

    let G = year%19
    let C = Math.floor(year/100)
    let H = (C - Math.floor(C/4) - Math.floor((8*C+13)/25) + 19*G + 15)%30
    let I = H - Math.floor(H/28)*(1 - Math.floor(H/28)*Math.floor(29/(H + 1))*Math.floor((21 - G)/11))
    let J = (year*1 + Math.floor(year/4) + I + 2 - C + Math.floor(C/4))%7
    let L = I - J
    let MoisPaques = 3 + Math.floor((L + 40)/44)
    let JourPaques = L + 28 - 31*Math.floor(MoisPaques/4)
    let Paques = moment(new Date(year, MoisPaques-1, JourPaques)).format("YYYY-MM-DD");
    let LundiPaques = moment(new Date(year, MoisPaques-1, JourPaques+1)).format("YYYY-MM-DD");
    let Ascension = moment(new Date(year, MoisPaques-1, JourPaques+39)).format("YYYY-MM-DD");
    let Pentecote = moment(new Date(year, MoisPaques-1, JourPaques+49)).format("YYYY-MM-DD");
    let LundiPentecote = moment(new Date(year, MoisPaques-1, JourPaques+50)).format("YYYY-MM-DD");

    return [JourAn, Paques, LundiPaques, FeteTravail, Ascension, Pentecote, LundiPentecote, FeteNationale, Assomption, Toussaint, Armistice, Noel];
}

export const isPhone = (value) => {
    const regexPhone = /^\+[0-9]*$/;
    return regexPhone.test(String(value).toLowerCase())
}

export const isEmail = (value) => {
    const regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexMail.test(String(value).toLowerCase());
}

export const validPassword = (password) => {
    return password && password.length >= 8;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;
    return password && passwordRegex.test(password);
}

export const isName = (value) => {
    const name = value.split(" ");
    return name.length > 1;
}

export const replaceUrlsWithLinks = (value) => {
    if(value === null){
        return null
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const regexMail = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,4}/g;
    const regexWithAsterisks = /\*\*([^\*]+)\*\*/g;
    const regexWithUnderscore = /__([^\*]+)__/g;
    const regexWithDollars = /\$\$([^\*]+)\$\$/g;
    let newValue = value.replace(urlRegex, '<a href="$1" target="_blank" class="underline text-purple">$1</a>')
    let newValue2 = newValue.replace(regexMail, (match) => {return '<a href="mailto:'+match+'" target="_blank" class="underline text-purple">'+match+'</a>'})
    let newValue3 = newValue2.replace(regexWithAsterisks, (match) => {return '<span class="font-semibold">'+match.slice(2, match.length-2)+'</span>'})
    let newValue4 = newValue3.replace(regexWithUnderscore, (match) => {return '<span class="underline">'+match.slice(2, match.length-2)+'</span>'})
    let newValue5 = newValue4.replace(regexWithDollars, (match) => {return '<span class="italic">'+match.slice(2, match.length-2)+'</span>'})
    const clean = DOMPurify.sanitize(newValue5);
    return <div>{HTMLReactParser(clean)}</div>
}

export const getNotifHour = (notif) => {
    let time = notif && notif.value ? notif.value.toString() : "1000";
    if(time.length === 3){
        time = "0" + time;
    }
    const hours = time.slice(0, 2);
    const minute = time.slice(2);
    let date = new Date();
    date.setHours(parseInt(hours), parseInt(minute), 0);
    return date.toLocaleTimeString("fr-BE");
}

export const getEndDateAccordingToWindow = (startDate, numWorkingDays) => {
    numWorkingDays--;
    if(typeof startDate === "string"){
        startDate = new Date(startDate);
    }
    const oneDayMs = 24 * 60 * 60 * 1000; // Number of milliseconds in one day

    // Helper function to check if a given date is a weekend (Saturday or Sunday)
    function isWeekend(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    // Copy the start date to avoid modifying the original value
    let currentDate = new Date(startDate.getTime());

    // Loop through the days and add working days
    while (numWorkingDays > 0) {
        currentDate.setTime(currentDate.getTime() + oneDayMs);

        // Check if the current date is a weekend, if so, skip it
        if (!isWeekend(currentDate)) {
            numWorkingDays--;
        }
    }

    return currentDate;
}

export const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
        let fileName = encodeURI(file.name);
        HttpApi.postFile("/file/upload", {file:file, fileName:fileName}).then(response => {
            if(response && response.data){
                resolve(response.data);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

export const processCanvasForPdf = (pdf, canvas, addPage=true) => {
    return new Promise(resolve => {
        if(addPage){
            pdf.addPage("a4", "landscape")
        }
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        const imgData = canvas.toDataURL("image/png");
        pdf.setFillColor(239, 249, 254);
        pdf.rect(0, 0, width, height, "F");
        const widthRatio = width / canvas.width;
        const heightRatio = height / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (width - canvasWidth) / 2;
        const marginY = (height - canvasHeight) / 2;
        pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight,null,"SLOW");
        resolve(pdf);
    })
}

export const processImageForPdf = (pdf, dataUrl, cWidth, cHeight, orientation="portrait") => {
    return new Promise(resolve => {
        pdf.addPage("A4", orientation)
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        const imgData = dataUrl;
        pdf.setFillColor(239, 249, 254);
        pdf.rect(0, 0, width, height, "F");
        const widthRatio = width / cWidth;
        const heightRatio = height / cHeight;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = cWidth * ratio;
        const canvasHeight = cHeight * ratio;

        const marginX = (width - canvasWidth) / 2;
        const marginY = (height - canvasHeight) / 2;
        pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight,null,"SLOW");
        resolve(pdf);
    })
}

export const getColorFromPercentage = (percentage) => {
    if (percentage <= 19) {
        return Colors.EBLOOM_STRONG_RED
    } else if (percentage <= 39) {
        return Colors.EBLOOM_LIGHT_RED
    } else if (percentage <= 59) {
        return Colors.EBLOOM_NEUTRAL
    } else if (percentage <= 79) {
        return Colors.EBLOOM_LIGHT_GREEN
    } else {
        return Colors.EBLOOM_STRONG_GREEN
    }
}

export const getUrl = (url, companyToEdit) => {
    if(companyToEdit && companyToEdit.id !== null){
        let splitedUrl = url.split("/");
        if(companyToEdit.type === CompanyTypes.DATATOPIA || companyToEdit.type === "global-datatopia"){
            splitedUrl.splice(2, 0, "datatopia");
            url = splitedUrl.join("/");
            return url + "/" + companyToEdit.id;
        }
        splitedUrl.splice(2, 0, "backoffice");
        url = splitedUrl.join("/");
        return url + "/" + companyToEdit.id;
    }
    return url;
}

export const getUrlForBackoffice = (url,companyToEdit,selectedEntity,params = []) => {
    let paramsString = params ? params.map(el => String(el)).join('/') : null

    let urlResult = url  + selectedEntity + "/"+ paramsString;
    if(companyToEdit && companyToEdit.id !== null){
        urlResult = url  + companyToEdit.id + ( paramsString ? "/"+ paramsString : "");
    }
    return urlResult;
}

export const isNullOrUndefined = (value) => {
    return value === undefined || value === null;
}

export const displayDate = (date, showTime=false) => {
    if(!date){
        return "";
    }
    if(!showTime || (moment(date).get("hour") >= 0 && moment(date).get("hour") <= 4 && moment(date).get("minute") === 0)){
        return moment(date).format("DD/MM/YY")
    }
    return moment(date).format("DD/MM/YY [" + getTranslations(getLanguage(), "core.at") + "] HH:mm")
}