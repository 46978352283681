import React from "react";
import {ReactComponent as WorkIcon} from "../../symbols/categories/work_lifestyle_bubble_icon.svg";
import {ReactComponent as TeamIcon} from "../../symbols/categories/team_interaction_bubble_icon.svg";
import {ReactComponent as ManagementIcon} from "../../symbols/categories/management_leadership_bubble_icon.svg";
import {ReactComponent as PolicyIcon} from "../../symbols/categories/policy_support_bubble_icon.svg";
import {ReactComponent as AdhesionIcon} from "../../symbols/categories/adhesion_culture_bubble_icon.svg";
import {PropTypes} from "prop-types";

const CategoryIcon = (props) =>{
    const {name} = props;

    return(
        name === "Work & Lifestyle" ?
            <WorkIcon {...props}/>
            :
            name === "Team & Interaction" ?
                <TeamIcon {...props}/>
                :
                name === "Management & Leadership" ?
                    <ManagementIcon {...props}/>
                    :
                    name === "Policy & Support" ?
                        <PolicyIcon {...props}/>
                        :
                        <AdhesionIcon {...props}/>


    )
}

CategoryIcon.propTypes = {
    name:PropTypes.string.isRequired,
}

export default CategoryIcon;