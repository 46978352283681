import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import ColorsModel from "../../../../../models/Colors"
import HttpApi from "../../../../../httpApi";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import ModelForms from "../../../../Core/Modules/Input/ModelForms";
import {createErrorNotification, createSuccessNotification, notifyError} from "../../../../../Utils/Notification";

const ColorsEditor = (props) => {
    //const {} = props
    const [colors, setColors] = useState([]);
    const [color, setColor] = useState({id : null,name:"",primary:"#",secondary:"",tertiary : "",quaternary:"",quinary:"",background:"", zone : "Global"});
    const [sideForm, setSideForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchColors = () => {
        setLoading(true)
        HttpApi.get("/palette/getAll").then(response => {
            if(response && response.data) {
                setColors(response.data)
            }
        }).catch(error => notifyError(error)).finally(() => setLoading(false))
    }

    const handleDelete = () => {
        fetchColors();
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setColor(prevState => {
            let color = {...prevState};
            color[name] = value;
            return color;
        })
    }

    const handleSave = () => {
        let notif1 = "created";
        let notif2 = "creating";
        let url = color.id ? "/palette/update" : "/palette/create";
        HttpApi.post(url,color).then(response => {
            if(response){
                createSuccessNotification("Success !", "Palette successfully " + notif1);
                fetchColors();
                setSideForm(false);
            }else{
                createErrorNotification("Error !", "An error occurred while " + notif2 + " the palette");
            }

        }).catch(error => {
            notifyError(error);
        });
    }

    const toggleDrawer = (open, toEdit) => event => {
        event.preventDefault();

        if(toEdit){
            setColor(toEdit);

        }else{
            setColor({id : null,name:"",primary:"#",secondary:"",tertiary : "",quaternary: "",quinary:"",background:"", zone : "Global"});
        }
        setSideForm(open);
    };

    useEffect(() => {
        fetchColors()
    }, []);

    return (
        <div>
            <CardDefault title={"Colors Editor"} showButton={true} handleRightButton={toggleDrawer(true)}>
                <TableViewV2 loading={loading} model={ColorsModel} items={colors} handleEdit={toggleDrawer} handleDelete={handleDelete} />
            </CardDefault>
            <SideForm  sideFormOpen={sideForm} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <ModelForms model={ColorsModel} handleChange={handleChange} values={color} />
            </SideForm>
        </div>

    );
};

ColorsEditor.propTypes = {

};

export default ColorsEditor;
