
export const communicationSteps = [
    {
        title: "admin.productTour.communication.step1.title",
        content: "admin.productTour.communication.step1.content",
        target: '[data-com="video"]',
        placement: 'left',
        disableBeacon : true,

    },
    {
        title: "admin.productTour.communication.step2.title",
        content: "admin.productTour.communication.step2.content",
        target: '[data-com="copyVideo"]',
        placement: 'top',


    },{
        title: "admin.productTour.communication.step3.title",
        content: "admin.productTour.communication.step3.content",
        target: '[data-com="visualSupport"]',
        placement: 'top',


    },{
        title: "admin.productTour.communication.step4.title",
        content: "admin.productTour.communication.step4.content",
        target: '[data-com="visualSupportBtn"]',
        placement: 'right',


    },{
        title: "admin.productTour.communication.step5.title",
        content: "admin.productTour.communication.step5.content",
        target: '[data-com="introProposition"]',
        placement: 'top',
        spotlightClicks : false

    },{
        title: "admin.productTour.communication.step6.title",
        content: "admin.productTour.communication.step6.content",
        target: '[data-com="comMail"]',
        placement: 'left',
        toolTipWidth : '200px' ,
    },
]