import React from "react";
import {Col, Row} from "react-bootstrap";
import {Colors} from "../../../../Utils/Colors";
import smileyUnhappyIcon from "../../symbols/smileys/smileyUnhappy.svg";
import smileySadIcon from "../../symbols/smileys/smileySad.svg";
import smileyNeutralIcon from "../../symbols/smileys/smileyNeutral.svg";
import smileyGladIcon from "../../symbols/smileys/smileyGlad.svg";
import smileyHappyIcon from "../../symbols/smileys/smileyHappy.svg"

const smileyTab = [
    {smiley: smileyUnhappyIcon, color: Colors.EBLOOM_STRONG_RED, score: 0},
    {smiley: smileySadIcon, color: Colors.EBLOOM_LIGHT_RED, score: 1},
    {smiley: smileyNeutralIcon, color: Colors.EBLOOM_NEUTRAL, score: 2},
    {smiley: smileyGladIcon, color: Colors.EBLOOM_LIGHT_GREEN, score: 3},
    {smiley: smileyHappyIcon, color: Colors.EBLOOM_STRONG_GREEN, score: 4},
]


const EbloomSmileyPercentageV2 = (props) => {

    const {value, isMobile, isLandscapeTablet} = props;

    const percentForScore = (score) => {
        if (value.nbResponse <= 0) {
            return 0;
        }
        return Math.round(((value.tableOfResults[score].nbResponse / value.nbResponse) * 100))
    };

    return (
        <div className={"w-10/12 mx-auto"}>
            <Row md={5} sm={5} xs={5} className={"flex items-center justify-between"}>
                {
                    smileyTab.map((smiley, index) => (
                        <Col key={index} md={2} sm={2} xs={2} className={"text-center p-0"}>
                            <img src={smiley.smiley} className={"m-auto w-40 h-40"}/>
                            <div className={"w-8 h-100 rounded-2xl bg-white-blue m-auto"}>
                                <div style={{
                                    width: 8,
                                    height: percentForScore(smiley.score),
                                    borderRadius: "0 0 3px 3px",
                                    backgroundColor: smiley.color,
                                    position: "relative",
                                    top: 100 - percentForScore(smiley.score)
                                }}/>
                            </div>
                            <div style={{
                                color: "black",
                                fontSize: 14,
                                marginLeft: isMobile ? 5 : isLandscapeTablet ? 5 : "60%",
                                position: "relative",
                                bottom: isMobile ? -10 : isLandscapeTablet ? -10 : (10 + percentForScore(smiley.score)),
                                width: 46,
                                height: 18,
                                border: "1px solid " + smiley.color,
                                boxSizing: "border-box",
                                borderRadius: 9,
                                backgroundColor: "white"
                            }}>
                                <span className={"ebloom-normal-text"} style={{
                                    fontSize: 14,
                                    position: "relative",
                                    bottom: 3,
                                    fontWeight: "bold"
                                }}>{percentForScore(smiley.score)}%</span>
                            </div>

                        </Col>
                    ))
                }
            </Row>
        </div>
    )

}

export default EbloomSmileyPercentageV2;