import {ModelTypes} from "./Types";

const Notification = {
    crud:{
        create:"/notification/create",
        update:"/notification/update",
        delete:"/notification/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    notifications_tr:{
        id:"text",
        type:ModelTypes.RELATIONS,
        key:"text",
        label:"core.text",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"notification-text",
            label:"core.text",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text",
        }
    },

    text_en:{
        id:"text_en",
        type:ModelTypes.TEXT,
        label:"core.notification.textEn",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"notification-text-en",
            label:"core.notification.textEn",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_en",
        }
    },

    text_fr:{
        id:"text_fr",
        type:ModelTypes.TEXT,
        label:"core.notification.textFr",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"notification-text-fr",
            label:"core.notification.textFr",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_fr",
        }
    },

    text_nl:{
        id:"text_nl",
        type:ModelTypes.TEXT,
        label:"core.notification.textNl",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"notification-text-nl",
            label:"core.notification.textNl",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_nl",
        }
    },

    type:{
        id:"type",
        type:ModelTypes.TEXT,
        label:"core.type",
        table:{
            show:true
        },
        list:{

        },

        form:{
            show:true,
            id:"notification-type",
            label:"core.type",
            select:true,
            options:["new", "update", "info"],
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"type",
        }
    },

    isAdmin:{
        id:"isAdmin",
        type:ModelTypes.BOOLEAN,
        label:"core.isAdmin",
        table:{
            show:true
        },
        list:{

        },

        form:{
            show:true,
            id:"notification-isAdmin",
            label:"core.isAdmin",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"isAdmin",
        }
    },

    url:{
        id:"url",
        type:ModelTypes.TEXT,
        label:"url",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"notification-url",
            label:"url",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:false,
            name:"url",
        }
    },


    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Notification;