import React, {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import PollFrame from "../../Polls/Modules/PollFrame";
import {SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import moment from "moment/moment";
import _ from "lodash";
import BubbleChat from "../../Polls/Results/Modules/BubbleChat";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as LikeIcon} from "../../../../../Core/symbols/webloom/white/like-white.svg";
import {ReactComponent as EyeIcon} from "../../../../../Core/symbols/webloom/white/show-white.svg";
import {ListGroup} from "react-bootstrap";
import EbloomPopover from "../../../../../Core/Modules/Popovers-Tooltips/EbloomPopover";
import HttpApi from "../../../../../../httpApi";
import {notifyError} from "../../../../../../Utils/Notification";
import {useUserData} from "../../../../../../hooks/userHook";

const WeBloomResultMessage = () => {

    //state
    const [survey, setSurvey] = useState(null);
    const [teamToDisplay, setTeamToDisplay] = useState(0);
    const [isGroup, setIsGroup] = useState(false);
    const [entity, setEntity] = useState(null);
    //hooks
    const t = useTranslation();
    const location = useLocation();
    const userData = useUserData();
    const params = useParams();

    const isProspect = userData.module === "prospect"
    //getters

    //posters

    //handlers
    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    const handleTeamToDisplay = (event) => {
        setTeamToDisplay(event.target.value)
    }

    //useEffect

    useEffect(() => {
        if(location && location.state){
            setSurvey(location.state);
        }
    }, [location])

    useEffect(() => {
        let idEntity = null
        let isGroupValue = false

        if(params.idGroup){
            idEntity = parseInt(params.idGroup);
            isGroupValue = idEntity === userData.companyGroup.id
            setIsGroup(isGroupValue)
            setEntity(idEntity)
        }
        if(survey && survey.id){
            let url = "/webloom/getSurveyTeamResults/" + survey.id + "/" + teamToDisplay
            if(idEntity !== null){
                if (isGroupValue){
                    url = "/webloom/group/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/"+ teamToDisplay
                }else{
                    url = "/webloom/entities/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/"+ teamToDisplay
                }
            }
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setSurvey(response.data);
                }
            }).catch(error => notifyError(error));
        }
    }, [teamToDisplay]);
    return (
        <>
            {
                survey &&
                <PollFrame status={SurveyStatus.DONE} type={SurveyTypes.MESSAGE} teamToDisplay={teamToDisplay} handleTeamToDisplay={handleTeamToDisplay} entity={entity} isGroup={isGroup}>
                    <div className={"grid grid-cols-3 grid-rows-1 text-contrast-purple justify-between"}>
                        <span>{displayDate()}</span>
                        <div className={"justify-self-center"}>
                            {
                                isProspect ?
                                    <div>
                                        <span style={{fontSize:18}} className={"text-line-break"}>#{survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) :isProspect ?  _.upperFirst(t("core.customized")) : survey.recipients}</span>
                                    </div>
                                    :
                                    <EbloomPopover  isCollab={true} triggerText={survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) : survey.recipients} placement={"bottom"} triggerVariant={"link"}>
                                        {
                                            () => (
                                                <ListGroup variant={"flush"}
                                                           style={{maxHeight: 250, overflowY: "auto", overflowX: "hidden"}}>
                                                    {
                                                        survey.users.map((user, index) => (
                                                            <ListGroup.Item key={index} style={{
                                                                padding: 0,
                                                                border: "none"
                                                            }}>{user.email}</ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            )
                                        }
                                    </EbloomPopover>
                            }
                        </div>
                        <div/>
                    </div>
                    <div className={"w-1/2 m-auto mt-20"}>
                        <BubbleChat title={survey.title} text={survey.question.text} imageUrl={survey.image_url} attachmentUrl={survey.attachment_url} isRead={true} className={"my-5"}/>
                        <div className={"flex items-center justify-between my-10"}>
                            <div className={"flex items-center"}>
                                <LikeIcon  className={"contrast-purple-icon h-26 w-26 mr-5"}/>
                                <span className={"text-contrast-purple font-semibold"}>{survey.question.score || "0"}</span>
                            </div>
                            <div className={"flex items-center"}>
                                <EyeIcon className={"contrast-purple-icon h-26 w-26 mr-5"}/>
                                <span className={"text-contrast-purple font-semibold"}>{(survey.nbViews || "0") + "/" + survey.users.length}</span>
                            </div>
                        </div>
                    </div>
                </PollFrame>
            }
        </>
    )
}

WeBloomResultMessage.propTypes = {}

WeBloomResultMessage.defaultProps = {}
export default WeBloomResultMessage