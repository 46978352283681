import React from 'react';
import PropTypes from 'prop-types';

const CommentIcon = (props) => {
    const {colors,width,height} = props

    return (
        <svg style={{height : height, width: width}} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{fill: colors? colors.secondary : "#B32DDD"}}
                d="M23.5139 1.74755L2.18283 0C0.976761 0 0 1.03952 0 2.32202V18.6502C0 19.9327 0.976761 20.9722 2.18283 20.9722L16.7111 19.2246C16.5309 20.4959 16.8367 22.0696 17.6412 24.0022C18.2849 25.551 18.8626 26.0467 19.075 25.9968C19.0911 25.9923 19.106 25.9841 19.1183 25.9727C19.1306 25.9614 19.14 25.9473 19.1458 25.9316C19.2263 25.7812 19.2359 25.3974 19.0291 24.7714C18.0492 21.8033 18.7073 20.1394 19.565 19.2246L23.6683 18.5874C24.67 18.5874 25.4843 17.7209 25.4843 16.6524L25.3322 3.68256C25.333 2.61408 24.5188 1.74755 23.5139 1.74755Z"
                />
            <path
                d="M22.1533 4.81787H3.68742C3.18352 4.81787 2.77502 5.22636 2.77502 5.73027V6.12049C2.77502 6.62439 3.18352 7.03288 3.68742 7.03288H22.1533C22.6572 7.03288 23.0657 6.62439 23.0657 6.12049V5.73027C23.0657 5.22636 22.6572 4.81787 22.1533 4.81787Z"
                fill="white"/>
            <path
                d="M22.1533 9.39502H3.68742C3.18352 9.39502 2.77502 9.80351 2.77502 10.3074V10.6976C2.77502 11.2015 3.18352 11.61 3.68742 11.61H22.1533C22.6572 11.61 23.0657 11.2015 23.0657 10.6976V10.3074C23.0657 9.80351 22.6572 9.39502 22.1533 9.39502Z"
                fill="white"/>
            <path
                d="M11.347 13.8251H3.68742C3.18352 13.8251 2.77502 14.2336 2.77502 14.7375V15.1277C2.77502 15.6316 3.18352 16.0401 3.68742 16.0401H11.347C11.8509 16.0401 12.2594 15.6316 12.2594 15.1277V14.7375C12.2594 14.2336 11.8509 13.8251 11.347 13.8251Z"
                fill="white"/>
        </svg>

    );
};

CommentIcon.propTypes = {
    colors : PropTypes.object,
    width : PropTypes.number,
    height : PropTypes.number
};

CommentIcon.defaultProps = {
    color: null,
    width : 30,
    height : 30,
}
export default CommentIcon;
