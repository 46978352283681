import React from "react";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import BackOfficeAccount from "./BackOfficeAccount";



const BackofficeAccountPage = (props) =>{

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <BackOfficeAccount companyToEdit={companyToEdit} {...props}/>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
};

export default BackofficeAccountPage;


