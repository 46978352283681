import React, {useState} from 'react';
import DisplaySuperAdminView from "../../../Layouts/DisplaySuperAdminView";
import {Container} from "react-bootstrap";
import SuperAdminDashboard from "../SuperAdminDashboard";
import {Colors} from "../../../../../Utils/Colors";
import {useUserData} from "../../../../../hooks/userHook";

const SuperAdminDashboardGroupPage = (props) => {
    const [bgColor, setBgColor] = useState(Colors.EBLOOM_WHITE_BLUE);
    const user = useUserData()
    const groupDisplay  = {id: user.companyGroup.id, name : user.companyGroup.name}
    return (
        <DisplaySuperAdminView {...props} module={"dashboard"} menu={"noMenu"} isDashboardSuperAdmin={true} isAllEntities={false} showDatePicker={true} bgColor={bgColor}>
            {
                (entitiesToDisplay, startDate, endDate, timeFilterVersion, shouldDownloadReport, handleReportState, showModalReport, handleModalReport, handleReportDisabled,teamsEntityToDisplay,
                 entitiesDashGroup,colors) => (
                    <Container className={"pt-84"} fluid>
                        {
                            <SuperAdminDashboard {...props} isAllEntities={false} teamToDisplay={[groupDisplay]} startDate={startDate} endDate={endDate} timeFilterVersion={timeFilterVersion} shouldDownloadReport={shouldDownloadReport} handleReportState={handleReportState} showModalReport={showModalReport} handleModalReport={handleModalReport} handleReportDisabled={handleReportDisabled} entitiesDashGroup={entitiesDashGroup} colors={colors}/>
                        }

                    </Container>
                )
            }
        </DisplaySuperAdminView>
    );
};

SuperAdminDashboardGroupPage.propTypes = {

};

export default SuperAdminDashboardGroupPage;
