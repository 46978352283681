import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import HttpApi from "../../../../httpApi";
import {Button, Container} from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
    createErrorNotification, createInfoNotification,
    createSuccessfullyCreatedNotification, notifyError
} from "../../../../Utils/Notification";
import _ from "lodash";
import {styled} from "@mui/material/styles";
import Login from "../../../../login/login";
import LockIcon from '@mui/icons-material/Lock';
import {useTranslation} from "react-multi-lang";
import ClickAndBloom from "./ClickAndBloom";
import {Colors} from "../../../../Utils/Colors";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {useUserData} from "../../../../hooks/userHook";
import {CompanyTypes} from "../../../../Utils/Global";
import {useSelector} from "react-redux";
import {GlobalContext} from "../../../../Utils/Context/GlobalContext";

const BackdropStyles = styled(Backdrop)(({theme}) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const DailySurvey =  (props) => {
    const [loading, setLoading] = useState(true);
    const [showThanks, setShowThanks] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const {setShowModalGoogle,setGoogleReviewData} = useContext(GlobalContext)


    const {bp} = props;

    const navigate = useNavigate();
    const t = useTranslation();
    const isMobile = bp.isMobileLandscapeOrLess();
    const isTablet = bp.isTablet();

    const user = useUserData();

    const companyType = useSelector((state) => state.user.company_type);


    const handleClick = () => {
      if(questions.filter(el => el.score !== null && el.score !== undefined).length === questions.length && !loading){
          setLoading(true);

          HttpApi.postV2('/feedbacks/create', {questions:questions})
              .then((response) => {
                  if(response && response.data){
                      const data = {
                          activated : response.data.isValidForModal,
                          cb: response.data.cb,
                          participation : response.data.participation
                      }
                      setGoogleReviewData(data)
                      setShowModalGoogle(data.activated)
                  }
                  createSuccessfullyCreatedNotification("feedback");
                  if(companyType === CompanyTypes.DATATOPIA){
                      user.logout(false);
                      setShowThanks(true);
                  }else{
                      (Login.hasRole("global_admin") || Login.hasRole("global_manager")) ? navigate("/manager/dashboard") : navigate("/employee/dashboard")
                  }
              }).catch((error) => {
              notifyError(error);
          }).finally(() => setLoading(false));
      }else{
          createErrorNotification("Error !", "Please answer all the questions");
      }
    };

    const handleSmileyClick = (score, q) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            let question = questions.find(el => el === q);
            question.score = score;
            if(score === -1){
                question.comment = "";
            }
            return questions;
        })
    };

    const handleComment = (q) => event => {
        event.persist();
        setQuestions(prevState => {
            let questions = [...prevState];
            let question = questions.find(el => el === q);
            question.comment = event.target.value;
            return questions;
        })
    }

    const handleFlag = (q) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            let question = questions.find(el => el===q);
            question.flagged = !question.flagged;
            return questions;
        })
    };

    const handleNext = () => {
        if(activeIndex === questions.length){
            setActiveIndex(questions.length-1);
        }else{
            setActiveIndex(activeIndex+1);
        }
    }

    const handlePrevious = () => {
        if(activeIndex === 0){
            setActiveIndex(0)
        }else{
            setActiveIndex(activeIndex-1);
        }
    }

  const fetchQuestionToday = () => {
        setLoading(true);
        HttpApi.getV2(`/questions/getQuestionOfTheDay`)
        .then((response) => {
            if(response.data && response.data.length > 0){
                setQuestions(response.data);
                setLoading(false);
            }else{
                createInfoNotification("Info", _.upperFirst(t("employee.noQuestionToday")));
                setLoading(false);
                if(companyType === CompanyTypes.DATATOPIA){
                    user.logout(false);
                    setShowThanks(true);
                }else{
                    (Login.hasRole("global_admin") || Login.hasRole("global_manager")) ? navigate("/manager/dashboard") : navigate("/employee/dashboard")
                }
            }
        })
            .catch((error) => {
             notifyError(error);
             setLoading(false);
             setQuestions([]);
            });
  };

  useEffect(() => {
      fetchQuestionToday()
  }, [])

    return(
        <Container>
          <BackdropStyles open={loading}>
              <CircularProgress color="inherit"/>
          </BackdropStyles>

          {
              (!loading && !showThanks) &&
              <div>
                  <div className={"text-center ml-0 text-xl flex flex-col justify-center items-center"}>
                      <LockIcon className={"mb-7"} />
                      <span className={"text-contrast-purple font-semibold"}>{activeIndex+1 + " "} <span style={{fontWeight:"normal"}}>{t("core.of")}</span>{" " + questions.length}</span>
                      <div className={"mt-10"}>
                          {
                              user.companyGroup !== null && questions[activeIndex].id_company !== user.companyId &&
                              <span className={"text-base  text-contrast-purple"}>From <span className={"font-semibold"}
                                  style={{color : user.companyGroup.colors.primary}}>{user.companyGroup.name}</span></span>
                          }
                      </div>

                  </div>
                  <ClickAndBloom question={questions[activeIndex]} handleComment={handleComment} handleFlag={handleFlag}
                                 handleSmileyClick={handleSmileyClick} {...props}/>

                  {
                  activeIndex > 0 &&
                      <KeyboardArrowLeftIcon style={{color:Colors.EBLOOM_PURPLE, marginTop:isMobile ? -5 : isTablet ? -5 : -120, marginLeft:isMobile ? 23 : isTablet ? 23 : 155, fontSize:"3em", cursor:"pointer"}} onClick={handlePrevious}/>
                  }

                  <Button className={"ebloom-btn-purple"} style={{float:"right", marginTop:isMobile ? 0 : isTablet ? 0 : -65, marginRight:isMobile ? 0 : isTablet ? 0 : 80, marginBottom:isMobile ? 20 : 0}} onClick={activeIndex === questions.length-1 ? handleClick : handleNext} disabled={loading || !questions[activeIndex].score}>
                      {
                          activeIndex === questions.length-1 ?
                              _.capitalize(t("core.save"))
                              :
                              <span>{_.capitalize(t("core.next"))} <KeyboardArrowRightIcon/></span>
                      }
                  </Button>

              </div>
          }
            {
                showThanks &&
                <div className={"text-center ml-0 text-xl"}>
                    <span className={"text-contrast-purple font-semibold"}>{_.upperFirst(t("core.thankYou"))}</span>
                </div>

            }

        </Container>

    )

}

export default DailySurvey;
