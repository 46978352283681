import React from 'react';
import PropTypes from "prop-types";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import UserIcon from "@mui/icons-material/SupervisedUserCircle";
import CompanyIcon from "@mui/icons-material/HouseRounded";
import OnboardingIcon from "@mui/icons-material/QuestionAnswerRounded";
import CategoryIcon from "@mui/icons-material/CategoryRounded";
import QuestionIcon from "@mui/icons-material/HelpOutlineOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import MailIcon from "@mui/icons-material/MailOutline";
import TagIcon from "@mui/icons-material/LocalOffer";
import PlaylistIcon from '@mui/icons-material/PlaylistPlay';
import LogsIcon from '@mui/icons-material/Storage';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TeamsIcon from '@mui/icons-material/PeopleOutline';
import StatisticIcon from '@mui/icons-material/Equalizer';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {ListItemButton} from "@mui/material";
import {useNavigationTo} from "../../../hooks/navigationHook";
import {useTranslation} from "react-multi-lang";
import _ from "lodash";
import MapsUgcIcon from '@mui/icons-material/MapsUgcOutlined';
import FAQIcon from '@mui/icons-material/ContactSupport';
import {useUserData} from "../../../hooks/userHook";
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import FrequencyIcon from '@mui/icons-material/TuneOutlined';
import LibraryIcon from "@mui/icons-material/ListOutlined";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import IntegrationIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LinkIcon from '@mui/icons-material/Link';
import {CompanyTypes} from "../../../Utils/Global";
import PaletteIcon from '@mui/icons-material/Palette';

const checkUrl = () => {
    const url = window.location.pathname;
    switch (url) {
        case "/backoffice/user":
            return 1;
        case "/backoffice/company":
            return 2;
        case "/backoffice/category":
            return 3;
        case "/backoffice/question":
            return 4;
        case "/backoffice/onboarding":
            return 5;
        case "/backoffice/daily":
            return 6;
        case "/backoffice/dashboard":
            return 7;
        case "/backoffice/employee":
            return 8;
        case "/backoffice/messages":
            return 9;
        case "/backoffice/tag":
            return 10;
        case "/backoffice/playlist":
            return 11;
        case "/backoffice/notification":
            return 12;
        case "/backoffice/logs":
            return 13;
        case "/backoffice/setting":
            return 14;
        case "/backoffice/teams":
            return 15;
        case "/backoffice/playlists":
            return 16;
        case "/backoffice/globalPlanner":
            return 17;
        case "/backoffice/globalNotification":
            return 18;
        case "/backoffice/module":
            return 19;
        case "/backoffice/report":
            return 20;
        case "/backoffice/account":
            return 21;
        case "/backoffice/globalDashboard":
            return 22;
        case "/backoffice/webloom":
            return 23;
        case "/backoffice/faq":
            return 24;
        case "/backoffice/filters":
            return 25;
        case "/backoffice/templates":
            return 26;
        case "/backoffice/identity":
            return 27;
        case "/backoffice/frequency":
            return 28;
        case "/backoffice/library":
            return 29;
        case "/backoffice/mobile":
            return 30;
        case "/backoffice/integration":
            return 31;
        case "/backoffice/linkCompanies":
            return 32;
        case "/backoffice/colorsEditor":
            return 33;
        default:
            return 1;
    }
};

function NavDrawerBackoffice(props) {
    const { container, bp } = props;
    const navigation = useNavigationTo();
    const selectedIndex= checkUrl();
    const t = useTranslation();
    const user = useUserData();

    const isGlobalBackoffice = user.roles.includes("global_backoffice");
    const isDatatopiaBackoffice = user.roles.includes("datatopia_backoffice");

    const drawer = (
        <div>
            <div className={"mt-50"}/>
            {
                props.companyToEdit &&
                <List subheader={<ListSubheader>{_.capitalize(t("core.company.company")) + " - " + props.companyToEdit.name}</ListSubheader>}>
                    <ListItemButton key="daily" selected={selectedIndex === 6} onClick={() => navigation.to("/backoffice/daily")}>
                        <ListItemIcon><OnboardingIcon/></ListItemIcon>
                        <ListItemText primary={_.capitalize(t("core.playlist.planner"))}/>
                    </ListItemButton>
                    {
                        (isGlobalBackoffice || isDatatopiaBackoffice) &&
                        <ListItemButton key={"frequency"} onClick={() => navigation.to("/backoffice/frequency")} selected={selectedIndex === 28}>
                            <ListItemIcon><FrequencyIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("admin.frequency"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="dashboard" selected={selectedIndex === 7} onClick={() => navigation.to("/backoffice/dashboard")}>
                            <ListItemIcon><DashboardIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("manager.dashboard.dashboard"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="report" selected={selectedIndex === 20} onClick={() => navigation.to("/backoffice/report")}>
                            <ListItemIcon><ListAltIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("Rapport"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="webloom" selected={selectedIndex === 23} onClick={() => navigation.to("/backoffice/webloom")}>
                            <ListItemIcon><MapsUgcIcon/></ListItemIcon>
                            <ListItemText primary={"WeBloom"}/>
                        </ListItemButton>
                    }

                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="employee" selected={selectedIndex === 8} onClick={() => navigation.to("/backoffice/employee")}>
                            <ListItemIcon><UserIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("employee.employees"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="teams" selected={selectedIndex === 15} onClick={() => navigation.to("/backoffice/teams")}>
                            <ListItemIcon><TeamsIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("admin.teams"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="filters" selected={selectedIndex === 25} onClick={() => navigation.to("/backoffice/filters")}>
                            <ListItemIcon><FilterListOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.filters"))}/>
                        </ListItemButton>
                    }
                    {
                        (isDatatopiaBackoffice || isGlobalBackoffice) &&
                        <ListItemButton key="playlists" selected={selectedIndex === 16} onClick={() => navigation.to("/backoffice/playlists")}>
                            <ListItemIcon><PlaylistIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("playlists"))}/>
                        </ListItemButton>
                    }
                    {
                        (isGlobalBackoffice || isDatatopiaBackoffice) &&
                        <ListItemButton key="library" selected={selectedIndex === 29} onClick={() => navigation.to("/backoffice/library")}>
                            <ListItemIcon><LibraryIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.playlist.library"))}/>
                        </ListItemButton>
                    }
                    {
                        ((isGlobalBackoffice) || isDatatopiaBackoffice) &&
                        <ListItemButton key="identity" selected={selectedIndex === 27} onClick={() => navigation.to("/backoffice/identity")}>
                            <ListItemIcon><CreateOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("admin.identity.titleSm"))}/>
                        </ListItemButton>
                    }

                    {
                        ((isGlobalBackoffice) || isDatatopiaBackoffice) &&
                        <ListItemButton key="customCategories" selected={selectedIndex === 28} onClick={() => navigation.to("/backoffice/customCategories")}>
                            <ListItemIcon><CategoryIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("admin.customCategories.title"))}/>
                        </ListItemButton>
                    }

                    {
                        (isGlobalBackoffice) &&
                        <ListItemButton key="notification" selected={selectedIndex === 12} onClick={() => navigation.to("/backoffice/notification")}>
                            <ListItemIcon><NotificationsActiveOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={"Notifications"}/>
                        </ListItemButton>
                    }
                    {
                        (isGlobalBackoffice) &&
                        <ListItemButton key="ldap" selected={selectedIndex === 31} onClick={() => navigation.to("/backoffice/integration")}>
                            <ListItemIcon><IntegrationIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst("integrations")}/>
                        </ListItemButton>
                    }
                    {
                        (isGlobalBackoffice) &&
                        <ListItemButton key="setting" selected={selectedIndex === 14} onClick={() => navigation.to("/backoffice/setting")}>
                            <ListItemIcon><SettingsOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("admin.settings"))}/>
                        </ListItemButton>
                    }
                    {
                        (isGlobalBackoffice || isDatatopiaBackoffice) &&
                        <ListItemButton key="account" selected={selectedIndex === 21} onClick={() => navigation.to("/backoffice/account")}>
                            <ListItemIcon><MenuBookOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("admin.account.account"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice && props.companyToEdit && props.companyToEdit.type === CompanyTypes.GROUP &&
                        <ListItemButton key="linkCompanies" selected={selectedIndex === 32} onClick={() => navigation.to("/backoffice/linkCompanies")}>
                            <ListItemIcon><LinkIcon/></ListItemIcon>
                            <ListItemText primary={"Link Group"}/>
                        </ListItemButton>
                    }
                </List>
            }

                <Divider />
                <List subheader={<ListSubheader>{_.capitalize(t("core.general"))}</ListSubheader>}>
                    {
                        (isGlobalBackoffice || isDatatopiaBackoffice) &&
                        <ListItemButton key="users" selected={selectedIndex === 1} onClick={() => navigation.to("/backoffice/user")}>
                            <ListItemIcon><UserIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.user.users"))}/>
                        </ListItemButton>
                    }
                    <ListItemButton key="companies" selected={selectedIndex === 2} onClick={() => navigation.to("/backoffice/company")}>
                        <ListItemIcon><CompanyIcon/></ListItemIcon>
                        <ListItemText primary={_.capitalize(t("core.company.companies"))}/>
                    </ListItemButton>
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="modules" selected={selectedIndex === 19} onClick={() => navigation.to("/backoffice/module")}>
                            <ListItemIcon><ViewModuleOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={"Modules"}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="categories" selected={selectedIndex === 3} onClick={() => navigation.to("/backoffice/category")}>
                            <ListItemIcon><CategoryIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.category.categories"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="tags" selected={selectedIndex === 10} onClick={() => navigation.to("/backoffice/tag")}>
                            <ListItemIcon><TagIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.tag.tags"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="questions" selected={selectedIndex === 4} onClick={() => navigation.to("/backoffice/question")}>
                            <ListItemIcon><QuestionIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("core.questions"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="webloomTemplates" selected={selectedIndex === 26} onClick={() => navigation.to("/backoffice/templates")}>
                            <ListItemIcon><DocumentScannerOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("webloom.templates"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="messages" selected={selectedIndex === 9} onClick={() => navigation.to("/backoffice/messages")}>
                            <ListItemIcon><MailIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.messages"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="playlist" selected={selectedIndex === 11} onClick={() => navigation.to("/backoffice/playlist")}>
                            <ListItemIcon><PlaylistIcon/></ListItemIcon>
                            <ListItemText primary={"eBloom Selection"}/>
                        </ListItemButton >
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="faq" selected={selectedIndex === 24} onClick={() => navigation.to("/backoffice/faq")}>
                            <ListItemIcon><FAQIcon/></ListItemIcon>
                            <ListItemText primary={_.toUpper(t("backoffice.faq.title"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="colors" selected={selectedIndex === 33} onClick={() => navigation.to("/backoffice/colorsEditor")}>
                            <ListItemIcon><PaletteIcon/></ListItemIcon>
                            <ListItemText primary={"Colors"}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="globalPlanner" selected={selectedIndex === 17} onClick={() => navigation.to("/backoffice/globalPlanner")}>
                            <ListItemIcon><OnboardingIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("backoffice.generalPlanner"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="globalDashboard" selected={selectedIndex === 22} onClick={() => navigation.to("/backoffice/globalDashboard")}>
                            <ListItemIcon><DashboardIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("manager.dashboard.dashboard"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="globalNotification" selected={selectedIndex === 18} onClick={() => navigation.to("/backoffice/globalNotification")}>
                            <ListItemIcon><NotificationsActiveOutlinedIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("core..notification.notification"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="statistic" selected={selectedIndex === 16} onClick={() => navigation.to("/backoffice/statistic")}>
                            <ListItemIcon><StatisticIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("backoffice.statistics"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="mobile" selected={selectedIndex === 30} onClick={() => navigation.to("/backoffice/mobile")}>
                            <ListItemIcon><SmartphoneIcon/></ListItemIcon>
                            <ListItemText primary={_.upperFirst(t("Mobile"))}/>
                        </ListItemButton>
                    }
                    {
                        isGlobalBackoffice &&
                        <ListItemButton key="log" selected={selectedIndex === 13} onClick={() => navigation.to("/backoffice/logs")}>
                            <ListItemIcon><LogsIcon/></ListItemIcon>
                            <ListItemText primary={"Logs"}/>
                        </ListItemButton>
                    }
                </List>
            </div>
    );

    return (
        <div className={"flex navDrawer"}>
            {
                true === bp.isTabletOrLess() ?
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="left"
                        open={props.mobileOpen}
                        onClose={props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                    :
                    <Drawer variant="permanent"
                            anchor="left"
                    >
                        {drawer}
                    </Drawer>
            }
        </div>
    );
}

NavDrawerBackoffice.propTypes = {
    bp:PropTypes.object,
}


export default NavDrawerBackoffice;
