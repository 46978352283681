import React from "react";
import {FullContrastPurpleTextField} from "../../../../../../../Utils/Global";
import PropTypes from "prop-types";
import _ from "lodash";
import {getLanguage} from "react-multi-lang/lib";
import {getTranslations} from "../../../../../../../lang/translations";
import {PollTypes} from "../../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";

const OpenQuestion = (props) => {
    const {question, handleChange, lang} = props;

    const handleChangeText = (event) => {
        let q = _.cloneDeep(question);
        q.answer = event.target.value;
        if(handleChange){ //not defined if preview mode
            handleChange(q);
        }

    }

    return(
        <FullContrastPurpleTextField variant={"outlined"} multiline minRows={question.type === PollTypes.NAME ? 1 : 4} value={question.answer} onChange={handleChangeText} fullWidth placeholder={_.upperFirst(getTranslations(lang || getLanguage(),(question.type === PollTypes.NAME ? "webloom.name" : "webloom.shareYourOpinion")))}/>
    )
}

OpenQuestion.propTypes = {
    question:PropTypes.object.isRequired,
    lang:PropTypes.string
}

export default OpenQuestion;