import React from "react";
import {ReactComponent as HeartIcon} from "../../../../../../Core/symbols/webloom/white/heart-white.svg";
import {ReactComponent as StarIcon} from "../../../../../../Core/symbols/webloom/white/star-white.svg";


const StarRatings = (props) => {
    const {propQuestion} = props;


    const getArrayOfItems = () => {
        let array = [];
        for(let i = 0; i<propQuestion.nb_items;i++){
            array.push(i);
        }
        return array;
    }

    return(
        <div className={"flex"}>
                {
                    getArrayOfItems().map((item, index) => (
                        <div key={index}>
                            {
                                propQuestion.is_heart ?
                                    <HeartIcon className={"ml-5 mr-5 border-contrast-purple-icon h-40 w-40"} />
                                    :
                                    <StarIcon className={"ml-5 mr-5 border-contrast-purple-icon h-40 w-40"} />
                            }
                        </div>
                    ))
                }
        </div>

    )
}

export default StarRatings;