import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    FullBlueTextField,
    FullContrastPurpleTextField,
    FullPurpleTextField,
} from "../../../../Utils/Global";
import {useTranslation} from "react-multi-lang";
import {ReactComponent as LoupeIcon} from "../../symbols/loupe.svg";
import {InputAdornment} from "@mui/material";


const EbloomSearchBar = (props) => {

    const {searchValue, handleChange, size, color, className,name} = props;
    const t = useTranslation();

    return(
        color === "purple-contrast" ?
            <FullContrastPurpleTextField className={className} id={"search-table-questionList"} placeholder={_.capitalize(t("core.search"))} variant={"outlined"} value={searchValue} onChange={handleChange} size={size} name={name} InputProps={{endAdornment:<InputAdornment position={"end"}><LoupeIcon className={"contrast-purple-icon"}/></InputAdornment>}}/>
        : color === "blue" ?
            <FullBlueTextField className={className} id={"search-table-questionList"} placeholder={_.capitalize(t("core.search"))} variant={"outlined"} value={searchValue} onChange={handleChange} size={size} InputProps={{endAdornment:<InputAdornment position={"end"}><LoupeIcon className={"contrast-blue-icon"}/></InputAdornment>}}/>
            : <FullPurpleTextField className={className} id={"search-table-questionList"} placeholder={_.capitalize(t("core.search"))} variant={"outlined"} value={searchValue} onChange={handleChange} size={size} InputProps={{endAdornment:<InputAdornment position={"end"}><LoupeIcon className={"purple-icon"}/></InputAdornment>}}/>
    )

}

EbloomSearchBar.propTypes = {
    searchValue: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    size:PropTypes.string,
    color:PropTypes.string,
    className:PropTypes.string,
    name:PropTypes.string
};

EbloomSearchBar.defaultProps = {
    size:"normal",
    color:"purple-contrast",
    className:"contrast-purple-icon",
    name:""
}

export default EbloomSearchBar;