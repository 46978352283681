import React from "react";
import PropTypes from "prop-types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import _ from "lodash";
import CreateIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/RemoveOutlined';
import {useTranslation} from "react-multi-lang/lib";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton/IconButton";

const CustomButton = (props) => {

    const {type, variant, size, text, icon, handleClick, border} = props;

    const t = useTranslation();

    return(
        <div className={props.className}>
            <OverlayTrigger overlay={
                <Tooltip style={{zIndex:10000}}>
                    {type === "add" ? _.capitalize(t("core.add")) : type === "remove" ? _.capitalize(t("core.remove")) : type === "delete" ? _.capitalize(t("core.delete")) : text}
                </Tooltip>
            }>
                {
                    type === "add" ?
                        <AddCircleOutlineIcon style={{fontSize:parseInt(size) || 24}} className={variant === "blue" ? "ebloom-add-btn-blue" : "ebloom-add-btn-purple"} onClick={handleClick}/>
                        :
                        type === "remove" ?
                        <RemoveCircleOutlineIcon data-tour={'remove'} style={{fontSize:parseInt(size) || 24}} className={variant === "blue" ? "ebloom-add-btn-blue" : "ebloom-add-btn-purple"} onClick={handleClick}/>
                            :
                            type === "delete" ?
                                <IconButton size="small" onClick={handleClick}><DeleteIcon/></IconButton>
                                :
                                <IconButton size={"small"} onClick={handleClick} className={"text-purple"}>
                            {type === "add" ? <CreateIcon/> : type === "remove" ? <RemoveIcon /> : icon}
                        </IconButton>
                }

            </OverlayTrigger>
        </div>
    )

}

CustomButton.propTypes = {
    type:PropTypes.string,
    variant:PropTypes.string,
    size:PropTypes.any,
    text:PropTypes.string,
    icon:PropTypes.object,
    handleClick:PropTypes.func.isRequired,
    border:PropTypes.any,

};

CustomButton.defaultProps = {
    type:"other",
    variant:"secondary",
    size:"sm",
    text:"button",
    icon:<CreateIcon/>,
    border:"none",
};

export default CustomButton;