import React, {useState, useEffect, useContext} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PollFrame from "../../../../../Admin/Pages/WeBloom/Polls/Modules/PollFrame";
import {SurveyStatus, SurveyTypes} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import AnswerIdeabox from "./Modules/AnswerIdeabox";
import HttpApi from "../../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {GlobalContext} from "../../../../../../Utils/Context/GlobalContext";
import WeBloomNotFound from "../../WeBloomNotFound";

const WeBloomAnswerIdeabox = (props) => {
    //context
    const {historyStack,setShowModalGoogle,setGoogleReviewData} = useContext(GlobalContext)

    //state
    const [survey, setSurvey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [linkType, setLinkType] = useState("valid");

    //hooks
    const location = useLocation();
    const navigate = useNavigate();
    const t = useTranslation();
    const {id} = useParams();

    //getters

    //posters
    const handleSave = () => {
        if (!loading) {
            validateSurvey().then(() => {
                setLoading(true);
                HttpApi.post("/collaborative/insertAnswer", {
                    questions: [survey.question],
                    idSurvey: survey.id
                }).then((response) => {
                    if(response && response.data){
                        const data = {
                            activated : response.data.isValidForModal,
                            cb: response.data.cb,
                            participation : response.data.participation
                        }
                        setGoogleReviewData(data)
                        setShowModalGoogle(data.activated)
                    }
                    notifySuccess(_.upperFirst(t("webloom.notification.saveAnswersSuccess")));
                    if(historyStack[historyStack.length-2] === "/employee/dashboard"){
                        navigate("/employee/dashboard");
                    }else{
                        navigate("/webloom/dashboard");
                    }
                }).catch(error => {
                    notifyError(error);
                }).finally(() => {
                    setLoading(false);
                })
            }).catch(error => {
                createErrorNotification(_.upperFirst(t("core.notification.error")), error);
            })

        }

    }

    //handlers
    const validateSurvey = () => {
        return new Promise((resolve, reject) => {
            if (survey.question.answer && survey.question.answer.trim().length > 0) {
                resolve(true);
            }
            reject(_.upperFirst(t("webloom.notification.ideaAnswerError")));
        });
    }
    const handleChange = (newQuestion) => {
        setSurvey(prevState => {
            let survey = {...prevState};
            survey.question = newQuestion;
            return survey;
        })
    }

    //useEffect

    useEffect(() => {
        if (location && location.state) {
            setSurvey(location.state);
        }else if(id){
            HttpApi.get("/collaborative/getForUser/" + id).then(response => {
                if(response && response.data && typeof response.data === "string"){
                    setLinkType(response.data);
                }else if(response && response.data){
                    setSurvey(response.data);
                }else{
                    navigate("/webloom/dashboard");
                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            navigate("/webloom/dashboard");
        }
    }, [])

    return (
        <>
            {
                (linkType === "alreadyAnswered" || linkType === "isExpired" || linkType === "isNotAvailable") ?
                    <WeBloomNotFound type={linkType}/>
                    :
                <PollFrame status={SurveyStatus.RUNNING} type={SurveyTypes.IDEABOX} survey={survey}>
                    {
                        survey &&
                        <AnswerIdeabox survey={survey} handleSave={handleSave} handleChange={handleChange} loading={loading}/>
                    }
                </PollFrame>
            }
        </>
    )
}

WeBloomAnswerIdeabox.propTypes = {}

WeBloomAnswerIdeabox.defaultProps = {}
export default WeBloomAnswerIdeabox