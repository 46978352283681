import React from 'react';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceLine,
    ResponsiveContainer, Label
} from 'recharts';
import {Colors} from "../../../../Utils/Colors";
import {withTranslation} from "react-multi-lang";
import _ from "lodash";
import * as PropTypes from "prop-types";
import {getTranslations} from "../../../../lang/translations";
import Login from "../../../../login/login";
import {ReactComponent as WLIcon} from "../../../Core/symbols/myBloom/categories/bubble/work-lifestyle-bubble.svg";
import {ReactComponent as TIIcon} from "../../../Core/symbols/myBloom/categories/bubble/team-interaction-bubble.svg";
import {
    ReactComponent as MLIcon
} from "../../../Core/symbols/myBloom/categories/bubble/management-leadership-bubble.svg";
import {ReactComponent as PSIcon} from "../../../Core/symbols/myBloom/categories/bubble/policy-support-bubble.svg";
import {ReactComponent as ACIcon} from "../../../Core/symbols/myBloom/categories/bubble/adhesion-culture-bubble.svg";
import {selectBubbleIcon} from "../../../Admin/Pages/Categories/Assets/CategoriesAssets";

const dataDefault = {
    work: [{kickoff: 25, satisfaction: 65}],
    team: [{kickoff: 15, satisfaction: 80}],
    management: [{kickoff: 20, satisfaction: 30}],
    policy: [{kickoff: 10, satisfaction: 75}],
    adhesion: [{kickoff: 30, satisfaction: 80}]
};

const mybloomIcons = {
    "work_lifestyle": <WLIcon/>,
    "team_interaction": <TIIcon/>,
    "management_leadership": <MLIcon/>,
    "adhesion_culture": <ACIcon/>,
    "policy_support": <PSIcon/>
}

const renderShape = (category) => props => {
    const {cx, cy} = props;
        return <foreignObject x={cx - 20} y={cy - 20} width={45} height={45}>
            {selectBubbleIcon(category.icon,category.color)}
        </foreignObject>


}

const renderShapeReport = (category, t, lang) => props => {
    const {cx, cy} = props;
    const color = Colors["EBLOOM_" + _.upperCase(category.split(" ")[0])]
    return (
        <foreignObject x={cx - 20} y={cy - 20} width={60} height={40} fontSize={14} fontFamily={"Lato"}
                       fontWeight={"bold"}>
            <div style={{backgroundColor: color, borderRadius: 10, paddingLeft: 10}}
                 className={"ebloom-text ebloom-small-text font-weight-bold"}>
                {lang ? getTranslations(lang, "core.category.initials." + category) : t("core.category.initials." + category)}
            </div>
        </foreignObject>
    )
}

const renderTick = (props) => {
    const {x, y, payload} = props;
    const icon = payload.value > 80 ? "smiley_happy" : payload.value > 60 ? "smiley_glad" : payload.value > 40 ? "smiley_poker-face" : payload.value > 20 ? "smiley_sad" : "smiley_unhappy";
    return (
        <image x={x - 30} y={y - 15} href={"/img/" + icon + ".png"} width={30} height={30}/>
    )


}

const renderTickRight = (score) => {
    if (score === 50 || score === 75 || score === 100) {
        return score + "%";
    }
    return "";
}

const renderTickTop = (score, t, lang) => {
    if (score === 15) {
        return lang ? _.upperFirst(getTranslations(lang, "manager.dashboard.low")) : _.upperFirst(t("manager.dashboard.low"))
    } else if (score === 20) {
        return lang ? _.upperFirst(getTranslations(lang, "manager.dashboard.medium")) : _.upperFirst(t("manager.dashboard.medium"));
    } else if (score === 25) {
        return lang ? _.upperFirst(getTranslations(lang, "manager.dashboard.high")) : _.upperFirst(t("manager.dashboard.high"));
    } else {
        return "";
    }
}

const renderLabel = (props) => {
    return (
        <text x={325} y={275} fontFamily="Lato" fontSize={18} fill={Colors.EBLOOM_CONTRAST_PURPLE}>
            {props.value}
        </text>

    )
}

const renderLabelY = (props) => {
    return (
        <text x={-20} y={150} transform="rotate(-90,25,150)" fontFamily="Lato" fontSize={18}
              fill={Colors.EBLOOM_CONTRAST_PURPLE}>
            {props.value}
        </text>
    )
}

class SimpleBubbleChart extends React.Component {


    render() {

        const renderTooltip = (value, name, props) => {
            const val = props.dataKey === "satisfaction" ? props.payload.satisfactionTooltip : props.payload.kickoffTooltip ? props.payload.kickoffTooltip.toFixed(1) : "0";
            return [val, name, props];
        };

        const {t, data, isReport, max, min, lang,color} = this.props;
        return (
            <ResponsiveContainer width={"100%"} height={Login.hasModule("happy") ? 400 : 300}
                                 className={"rechart-bubble-chart"}>
                <ScatterChart margin={{right: 20, bottom: 20, left: 20, top: 20}}>
                    <ReferenceLine x={15}/>
                    <ReferenceLine y={60}/>
                    <ReferenceLine y={70}/>
                    <ReferenceLine y={80}/>
                    <ReferenceLine y={90}/>
                    <XAxis type="number" dataKey="kickoff" name="Importance" domain={[min, max]} tick={false}
                           axisLine={{stroke: color ? color : Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}} unit={"%"}>
                        <Label
                            value={lang ? _.upperFirst(getTranslations(lang, "manager.dashboard.bubbleX")) : _.upperFirst(t("manager.dashboard.bubbleX"))}
                            position='bottom' offset={-20} fill={ color ? color : Colors.EBLOOM_CONTRAST_PURPLE} fontSize={18}/>
                    </XAxis>
                    <XAxis type="number" xAxisId="top" dataKey="kickoff" orientation="top" domain={[10, 30]}
                           axisLine={false} tickLine={false} tick={{fill: color ? color :  Colors.EBLOOM_CONTRAST_PURPLE}}
                           tickFormatter={(score) => renderTickTop(score, t, lang)}/>
                    <YAxis type="number" dataKey="satisfaction" name="Satisfaction" tickCount={3} unit="%"
                           domain={[50, 100]} tick={renderTick} padding={{top: 20}} tickLine={false}
                           axisLine={{stroke: color ? color :  Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}}>
                        <Label
                            value={lang ? _.upperFirst(getTranslations(lang, "manager.dashboard.bubbleY")) : _.upperFirst(t("manager.dashboard.bubbleY"))}
                            angle={-90} position='insideLeft' fill={ color ? color : Colors.EBLOOM_CONTRAST_PURPLE} fontSize={18}/>
                    </YAxis>
                    <YAxis yAxisId="right" type="number" dataKey="satisfaction" orientation="right" tickCount={3}
                           domain={[50, 100]} tick={{fill: color ? color :  Colors.EBLOOM_CONTRAST_PURPLE}}
                           tickFormatter={renderTickRight} axisLine={false} tickLine={false}/>
                    <Tooltip cursor={{strokeDasharray: '3 3'}}
                             formatter={(value, name, props) => renderTooltip(value, name, props)}/>

                    {
                        data.map((c,index) => {
                            if(c.kickoff >= 0 && c.satisfaction >= 0) {
                                const catData = {
                                    kickoff : c.kickoff,
                                    satisfaction : c.satisfaction,
                                    kickoffTooltip : c.kickoffTooltip,
                                    satisfactionTooltip : c.satisfactionTooltip
                                }
                                return <Scatter
                                    key={index}
                                    name={c.category.name}
                                    data={[catData]}
                                    shape={
                                        renderShape(c.category)}
                                    fill={c.category.color} />
                            }else{
                                return null
                            }
                        })
                    }
                </ScatterChart>
            </ResponsiveContainer>
        );
    }
}

SimpleBubbleChart.propTypes = {
    data: PropTypes.object.isRequired,
    lang: PropTypes.string,
    color: PropTypes.string,
    mybloom: PropTypes.bool
};

SimpleBubbleChart.defaultProps = {
    data: dataDefault,
    isReport: false,
    max: 30,
    min: 0,
    mybloom: false,
    color : null
};

export default withTranslation(SimpleBubbleChart);
