import React from 'react';
import PropTypes from 'prop-types';
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import CustomCategories from "../../../../Admin/Pages/Categories/CustomCategories";

const BackofficeCustomCategoriesPage = (props) => {
    //const {} = props

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <CustomCategories companyToEdit={companyToEdit} />
                </Container>
            )
            }
        </DisplayBackofficeView>
    );
};

BackofficeCustomCategoriesPage.propTypes = {

};

export default BackofficeCustomCategoriesPage;
