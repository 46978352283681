import React from 'react';
import {
    Radar, RadarChart, PolarGrid, Legend,
    PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip
} from 'recharts';
import {Colors} from "../../../../Utils/Colors";
import PropTypes from "prop-types";
import {ReactComponent as WLIconMyBloom} from "../../symbols/myBloom/categories/bubble/work-lifestyle-bubble.svg"
import {ReactComponent as TIIconMyBloom} from "../../symbols/myBloom/categories/bubble/team-interaction-bubble.svg"
import {ReactComponent as MLIconMyBloom} from "../../symbols/myBloom/categories/bubble/management-leadership-bubble.svg"
import {ReactComponent as PSIconMyBloom} from "../../symbols/myBloom/categories/bubble/policy-support-bubble.svg"
import {ReactComponent as ACIconMyBloom} from "../../symbols/myBloom/categories/bubble/adhesion-culture-bubble.svg"

import {ReactComponent as WLIcon} from "../../symbols/categories/work_lifestyle_bubble_icon.svg"
import {ReactComponent as TIIcon} from "../../symbols/categories/team_interaction_bubble_icon.svg"
import {ReactComponent as MLIcon} from "../../symbols/categories/management_leadership_bubble_icon.svg"
import {ReactComponent as PSIcon} from "../../symbols/categories/policy_support_bubble_icon.svg"
import {ReactComponent as ACIcon} from "../../symbols/categories/adhesion_culture_bubble_icon.svg"
import {selectBubbleIcon} from "../../../Admin/Pages/Categories/Assets/CategoriesAssets";
import _ from "lodash";
import {getColorFromPercentage} from "../../../../Utils/Global";
import {useTranslation} from "react-multi-lang";


const customRadarTick = ({x, y, payload, index, radius}, t, isMobile, isDashboardManager) => {

    //const isMobile = payload.value === "W&L" || payload.value === "T&I" || payload.value === "M&L" || payload.value === "P&S" || payload.value === "A&C";

    const icon = () => {
        if (isDashboardManager) {
            if (isMobile) {
                switch (payload.value) {
                    case "Work & Lifestyle":
                        return <WLIcon className={"w-30 h-30"}/>;
                    case "Team & Interaction":
                        return <TIIcon className={"w-30 h-30"}/>;
                    case "Management & Leadership":
                        return <MLIcon className={"w-30 h-30"}/>;
                    case "Policy & Support":
                        return <PSIcon className={"w-30 h-30"}/>;
                    case "Adhesion & Culture":
                        return <ACIcon className={"w-30 h-30"}/>;
                }
            } else {
                switch (payload.value) {
                    case "Work & Lifestyle":
                        return <WLIcon className={"w-30 h-30"}/>;
                    case "Team & Interaction":
                        return <TIIcon className={"w-30 h-30"}/>;
                    case "Management & Leadership":
                        return <MLIcon className={"w-30 h-30"}/>;
                    case "Policy & Support":
                        return <PSIcon className={"w-30 h-30"}/>;
                    case "Adhesion & Culture":
                        return <ACIcon className={"w-30 h-30"}/>;
                }
            }
        } else {
            if (isMobile) {
                switch (payload.value) {
                    case "Work & Lifestyle":
                        return <WLIconMyBloom className={"w-35 h-35"}/>;
                    case "Team & Interaction":
                        return <TIIconMyBloom className={"w-35 h-35"}/>;
                    case "Management & Leadership":
                        return <MLIconMyBloom className={"w-35 h-35"}/>;
                    case "Policy & Support":
                        return <PSIconMyBloom className={"w-35 h-35"}/>;
                    case "Adhesion & Culture":
                        return <ACIconMyBloom className={"w-35 h-35"}/>;
                }
            } else {
                switch (payload.value) {
                    case "Work & Lifestyle":
                        return <WLIconMyBloom className={"w-35 h-35"}/>;
                    case "Team & Interaction":
                        return <TIIconMyBloom className={"w-35 h-35"}/>;
                    case "Management & Leadership":
                        return <MLIconMyBloom className={"w-35 h-35"}/>;
                    case "Policy & Support":
                        return <PSIconMyBloom className={"w-35 h-35"}/>;
                    case "Adhesion & Culture":
                        return <ACIconMyBloom className={"w-35 h-35"}/>;
                }
            }

        }

    }

    const calculateX = () => {
        switch (index) {
            case 0:
                return x - 15;
            case 1:
                return x;
            case 2:
                return x + 2;
            case 3:
                return x - 30;
            case 4:
                return x - 30;
            default:
                return x;
        }
    }

    const calculateY = () => {
        switch (index) {
            case 0:
                return y - 30;
            case 1:
                return y - 10;
            case 2:
                return y - 15;
            case 3:
                return y - 15;
            case 4:
                return y - 10;
            default:
                return y;
        }
    }

    const calculateTextX = () => {
        switch (index) {
            case 0:
                return x + 20;
            case 1:
                return x + 35;
            case 2:
                return x + 40;
            case 3:
                return x - 150;
            case 4:
                return x - 150;
            default:
                return x;
        }
    }

    const calculateTextY = () => {
        switch (index) {
            case 0:
                return y - 30;
            case 1:
                return y - 20;
            case 2:
                return y - 25;
            case 3:
                return y - 25;
            case 4:
                return y - 20;
            default:
                return y;
        }
    }

    return (
        <g>
            <foreignObject x={calculateX()} y={calculateY()}
                           width={30}
                           height={30}>

                {
                    icon()
                }
            </foreignObject>
            <foreignObject x={calculateTextX()} y={calculateTextY()} width={index === 0 ? 90 : index === 3 ? 110 : 120}
                           height={50}>
                {

                    <p style={{textAlign: index === 4 || index === 3 ? "right" : "left"}}>{isMobile ? t("core.category.initials." + payload.value) : t("core.category." + payload.value)}</p>
                }
            </foreignObject>
        </g>
    );
}

const CustomTooltip = ({ active, payload, label },isModelCustomEnable,isDashboardManager) => {
    const t = useTranslation()

    if (active && payload && payload.length) {
        return (
            <div className="ebloom-normal-text bg-white border-1 border-grey/70 p-10 flex flex-col justify-start items-start">
                <p>{isModelCustomEnable ? label : _.upperFirst(t("core.category."+label))}</p>
                {
                    payload.map(p => (
                        <div>
                            {
                                isDashboardManager &&
                                    <p style={{color: p.color}}>{p.name}: {p.value}</p>
                            }
                        </div>
                    ))
                }
            </div>
        );
    }

    return null;
};

const CustomTick = (props) => {
    const item = props.data[parseInt(props.index)].category
    const xOffset = 22;
    const yOffset = 22;

    const conditionToGrayOut = props.data[parseInt(props.index)].A < 0 && props.data[parseInt(props.index)].B < 0

    return <g z={10} opacity={conditionToGrayOut ? 0.5 : 1 } transform={`translate(${props.x - xOffset },${props.y -yOffset})`}>
        {selectBubbleIcon(item.icon,item.color)}
    </g>
}
class SimpleRadarChart extends React.Component {

    render() {

        const {name1, name2, data, isDashboardManager, t, isMobile,color,colorA, colorB,isModelCustomEnable} = this.props;
        return (
            <ResponsiveContainer width={"100%"} height={375}>
                <RadarChart outerRadius={isMobile ? 100 : 150} data={data}>
                    <PolarGrid/>
                    <PolarAngleAxis dataKey="subject" orient={"inner"} margin={{top: 0, bottom: 0, left: 20, right: 20}}
                                    tick={<CustomTick data={data}/>}

                    />
                    <PolarRadiusAxis angle={30} domain={[0, 100]} tick={false} axisLine={false}/>
                    <Tooltip offset={20}  content={(el) => CustomTooltip(el,isModelCustomEnable,isDashboardManager)} />


                    <Radar name={name1} dataKey={isDashboardManager ? "A":"B"} stroke={colorA ? colorA : isDashboardManager ? Colors.EBLOOM_CONTRAST_PURPLE : color}
                           strokeWidth={3}
                           fill={"transparent"}/>
                    <Radar name={name2} dataKey={isDashboardManager ? "B":"A"}
                           stroke={colorB ? colorB :isDashboardManager ? Colors.EBLOOM_LOGO_BLUE : Colors.EBLOOM_CONTRAST_PURPLE}
                           strokeWidth={3}
                           fill={"transparent"}/>



                </RadarChart>
            </ResponsiveContainer>
        );
    }
}

SimpleRadarChart.propTypes = {
    name1: PropTypes.string.isRequired,
    name2: PropTypes.string.isRequired,
    data: PropTypes.array || PropTypes.bool,
    isDashboardManager: PropTypes.bool,
    isMobile: PropTypes.bool,
    isModelCustomEnable: PropTypes.bool,
    color: PropTypes.string,
    colorA: PropTypes.string,
    colorB: PropTypes.string
};

SimpleRadarChart.defaultProps = {
    isDashboardManager: true,
    isMobile: false,
    isModelCustomEnable: false,
    color: Colors.EBLOOM_LOGO_BLUE,
    colorA: Colors.EBLOOM_CONTRAST_PURPLE,
    colorB: Colors.EBLOOM_LOGO_BLUE
}

export default SimpleRadarChart
