import React, {useContext, useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import _ from "lodash";
import {
    createErrorNotification,
    createInfoNotification, createSuccessfullyUpdatedNotification, notifyError, notifySuccess
} from "../../../../Utils/Notification";
import UserModel from "../../../../models/User";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {Button, Col, Row, Form} from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import TableViewV2 from "../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import {useTranslation} from "react-multi-lang";
import {Colors} from "../../../../Utils/Colors";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import * as fileSaver from "file-saver";
import {useNavigate} from "react-router-dom";
import readXlsxFile from "read-excel-file";
import UserComparison from "./UserComparison";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu/Menu";
import IconButton from "@mui/material/IconButton/IconButton";
import EbloomGuide from "../../../Core/Modules/Views-Tables-Lists/EbloomGuide";
import {useUserData} from "../../../../hooks/userHook";
import DetailView from "../../../Core/Modules/Views-Tables-Lists/DetailView";
import ModelForms from "../../../Core/Modules/Input/ModelForms";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import InviteUsers from "./InviteUsers";
import {ReactComponent as AddUserIcon} from "../../../Core/symbols/add-users.svg";
import OtherChanelCom from "../Communication/OtherChanelCom";
import {CompanyTypes, getUrl, isEmail, Modules, TourType, UserStatus} from "../../../../Utils/Global";
import ManageUsersTour from "../../../../Utils/ProductTour/Tours/ManageUsers/ManageUsersTour";
import {GlobalContext} from "../../../../Utils/Context/GlobalContext";
import DefineRolesTour from "../../../../Utils/ProductTour/Tours/DefineRoles/DefineRolesTour";
import {ModelTypes} from "../../../../models/Types";
import InvitePreview from "./InvitePreview";



const EmployeeEditor = (props) => {
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState({id:null,email:"", name:"", roles:[""], id_roles:[-1], id_team:-1, teams:[], teams_collab:[], filters: []});
    const [userToReinvite, setUserToReinvite] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [tablePage, setTablePage] = useState(0);
    const [usersMatched, setUsersMatched] = useState([]);
    const [isBlueCollar, setIsBlueCollar] = useState(false);
    const [seeOtherChannelCom, setSeeOtherChannelCom] = useState(false);
    const [menuTeamAnchor, setMenuTeamAnchor] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [showReinvite, setShowReinvite] = useState(false);
    const [showModalReinvite, setShowModalReinvite] = useState(false)
    const [showInviteSaved, setShowInvitedSaved] = useState(false);
    const [showInvitePreview, setShowInvitePreview] = useState(false);
    const [integrationType, setIntegrationType] = useState("");

    const {updateStep,setTourStartedManageUsers,tourStartedManageUsers,stepManageUsers,tourStarted,setContinuous,validateOutcome,tourActivated} = useContext(ProductTourContext);
    const {
        sideFormUploadOpen, setSideFormUploadOpen,
        excelData, setExcelData,
        showComparison, setShowComparison,
        sideFormEditOpen, setSideFormEditOpen,
        showMenuAnchor, setShowMenuAnchor,
        seePreview, setSeePreview
    }= useContext(GlobalContext)

    const {companyToEdit} = props;

    const t = useTranslation();

    const navigate = useNavigate();

    const user = useUserData();

    const isMoreThanHappy = user && (user.module === "very_happy" || user.module === "super_happy" || user.module === "free");

    const EmployeeModel = _.cloneDeep(UserModel);

    const isCompanyToEditGroup = companyToEdit && companyToEdit.type === CompanyTypes.GROUP

    const handleSave = () => {
        if(!employee.id || employee.id_roles.includes(0)){
            createErrorNotification("Error", "Invalid payload request for Employee Update");
            return;
        }
        let url = "/users/updateEmployee";
        let data = companyToEdit ? {id:employee.id, email:employee.email, name:employee.name, lang:employee.lang, id_company:employee.id_company, id_roles:employee.id_roles, teams:employee.teams, teamsCollab:employee.teams_collab, id_team:employee.id_team === -1 ? null : employee.id_team, filters:employee.filters} : {id:employee.id, name:employee.name, id_roles: employee.id_roles, teams:(employee.id_roles.includes(2)) ? employee.teams : null, teamsCollab:(employee.id_roles.includes(2)) ? employee.teams_collab : null, id_team:employee.id_team === -1 ? null : employee.id_team, filters:employee.filters};
        let company_filters = UserModel.filters.form.selectOptions.options.map(el => el.company_filter);
        company_filters = [...new Set(company_filters)];
        if(employee.id_roles.includes(2) && isMoreThanHappy && employee.teams.length === 0 && employee.teams_collab.length === 0){
            createErrorNotification("Error!", "You must assign at least one team (Dashboard and/or Collaborative area) to your Advanced User");
        }else if(company_filters.length !== employee.filters.length && Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module)){
            createErrorNotification("Error!", "Please assign a value for each created filters");
        }else{
            HttpApi.put(url,data).then(() => {
                createSuccessfullyUpdatedNotification("employee");
                fetchEmployees();
                if(tourActivated)
                validateOutcome(TourType.DEFINE_ROLES)
                setSideFormEditOpen(false);
            }).catch(error => {
                notifyError(error);
            })
        }

    };

    const handleSaveTeam = (id) => {
        HttpApi.postV2("/teams/insertUser", {id_user:employee.id, id_team:id}).then(() => {
            fetchEmployees();
            resetUserEditState();
            setMenuTeamAnchor(null);
            notifySuccess("User added to the team");
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if(name === "teams" || name === "teams_collab"){
            if(value.includes(-1)){//if all is selected
                if(value.length > EmployeeModel.teams.form.selectOptions.options.length){
                    value = [];
                }else{
                    value = EmployeeModel.teams.form.selectOptions.options.map(el => el.id);
                }
            }
        }
        setEmployee(prevState => {
            let employee = {...prevState};
            if(name === "id_roles"){
                employee[name] = [value];
            }else{
                employee[name] = value;
            }

            return employee;
        })
    };
    const handleClose = (event) => {
        setShowModal(false);
    };

    const handleOpen = (event) => {
        event.preventDefault();
        if(!tourStartedManageUsers){
            setShowMenuAnchor(false);

        }
        setShowModal(true);
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleRowClick = (item) => event => {
        event.preventDefault();
        item.id_roles = item.id_roles.filter(el => el !== 6);
        setEmployee(item);
        setShowDetail(true);
    }

    const handleCloseDetail = () => {
        setShowDetail(false);
        resetUserEditState();
    }

    const handleShowComparison = () => {
        if(showComparison){
            fetchEmployees();
        }
        if(seePreview){
            setSeePreview(false);
        }
        setShowComparison(!showComparison);

    }

    const handleSeePreview = () => {
        setSeePreview(!seePreview);
    }

    const handleSeePreviewAndReload = () => {
        updateStep(TourType.INVITE,0)
        setSeePreview(false);
        setShowComparison(false);
        setShowInvitePreview(false);
        setShowInvitedSaved(false);
        UserModel.initializeOptions();
        EmployeeModel.initializeOptions();
        fetchEmployees();
    }

    const fetchIntegrationUserList = (type) => {
        HttpApi.getV2("/users/"+type+"/sync").then(response => {
            if(response && response.data){
                if(response.data !== true){
                    setUsersMatched(response.data);
                    setShowComparison(true);
                }
            }else if(response && response.data === false){
                window.open(HttpApi.getBaseUrl() + "/auth/"+type+"?id=" + user.companyId, "_self");
            }
        })
    }

    const handleExcel = (event) => {
        readXlsxFile(event.target.files[0]).then((rows) => {
            if(rows && rows.length > 1){
                setExcelData(rows);
            }else if(!rows || rows.length <= 1) {
                createErrorNotification("Error!", "Empty file, please provide a filled excel file");
                document.getElementById("input-users").value = null;
                setExcelData([]);
            }else{
                createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
                document.getElementById("input-users").value = null;
                setExcelData([]);
            }
        }).catch(() => {
            createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
            document.getElementById("input-users").value = null;
            setExcelData([]);
        })
    }

    const handleSaveExcel = (notif=true) => {
        if(!loadingExcel && isMoreThanHappy){
            if(excelData && excelData.length > 1){
                setLoadingExcel(true);
                HttpApi.postV2("/users/checkMatching", {users:excelData}).then(response => {
                    if(response && response.data){
                        if(notif){
                            notifySuccess("Excel successfully uploaded");
                        }
                        if(tourStartedManageUsers){
                            setTourStartedManageUsers(false)
                            setContinuous(true)
                            updateStep(TourType.MANAGE_USERS,12)
                            setTimeout(() => {
                                setTourStartedManageUsers(true)
                            },400)
                        }
                        setSideFormUploadOpen(false);
                        setShowComparison(true);
                        setUsersMatched(response.data);
                    }
                }).catch(error => {
                    notifyError(error);
                }).finally(() => setLoadingExcel(false));
            }else{
                createErrorNotification("Error!", "Please provide a filled excel file.");
            }
        }
    }

    const handleOpenMenu = (event) => {
        setMenuAnchor(event.target);
        setShowMenuAnchor(true);
        if(tourStartedManageUsers){
            setTimeout(() => {
                if(stepManageUsers>1){
                    updateStep(TourType.MANAGE_USERS,8)
                }else{
                    updateStep(TourType.MANAGE_USERS,1)
                }

            },300)
        }
    }

    const handleCloseMenu = () => {
        setShowMenuAnchor(false);
    }

    const handleOpenMenuTeams = (open, toEdit) => event => {
        setMenuTeamAnchor(event.target);
        setEmployee(toEdit);
    }

    const handleCloseMenuTeams = () => {
        resetUserEditState();
        setMenuTeamAnchor(null);
    }

    const handleShowReinvite = () => {
        setTablePage(0);
        setShowReinvite(true);
        if(!tourStartedManageUsers){
            setShowMenuAnchor(false);
        }
    }

    const handleHideReinvite = () => {
        setTablePage(0);
        setShowReinvite(false);
        setUserToReinvite([]);
    }

    const handleOpenModalReinvite = () => {
        setShowModalReinvite(true);
    }

    const handleCloseModalReinvite = () => {
        setShowModalReinvite(false);
    }

    const handleSendInviteReminder = () => {
        HttpApi.postV2("/users/sendInviteReminder", {users:userToReinvite}).then(() => {
            notifySuccess(_.upperFirst(t("admin.reminderSent", {param:userToReinvite.length.toString()})));
            handleHideReinvite();
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleCheck = (user) => {
        let u = userToReinvite.find(el => el.id === user.id);
        if(u !== undefined){
            setUserToReinvite(prevState => {
                let userToReinvite = [...prevState];
                userToReinvite.splice(userToReinvite.indexOf(u), 1)
                return userToReinvite;
            })
        }else{
            setUserToReinvite(prevState => {
                let userToReinvite = [...prevState];
                userToReinvite.push(user);
                return userToReinvite;
            })
        }
    }

    const handleSelectAll = () => {
        if(userToReinvite.length === employees.filter(el => el.status === UserStatus.INVITED).length){
            setUserToReinvite([]);
        }else{
            setUserToReinvite(employees.filter(el => el.status === UserStatus.INVITED));
        }
    }

    const fetchEmployees = () => {
        setLoadingEmployees(true);
        let url = "/users/getEmployees";

        if(isCompanyToEditGroup){
            url = "/users/backoffice/getAllFromGroup/" + companyToEdit.id;
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setEmployees(response.data);
                setLoadingEmployees(false);
            }else{
                createInfoNotification("Info!", "No employees found");
                setLoadingEmployees(false);
            }
        }).catch(error => {
            setLoadingEmployees(false);
            notifyError(error);
        });
    };

    const resetUserEditState = () => {
        setEmployee({id:null,email:"", name:"", roles:[""], id_roles:[-1], id_team:-1, teams:[], teams_collab:[], filters: []});
    };

    const redirectToTeams = () => event => {
        navigate("/admin/teams");
    }

    const getLogo = (value) => {
        const logo = require("../../../Core/symbols/integrations/"+value+".svg")
        return <img src={logo} alt={"Logo " + value} width={35} height={35}/>
    }

    useEffect(() => {
        fetchEmployees();
        let url = new URL(window.location.href);
        let invite = url.searchParams.get("invite");
        let reinvite = url.searchParams.get("reinvite");
        let productTour = url.searchParams.get("productTour");
        let strategyUrl = url.searchParams.get("type");
        if(invite){
            setSeePreview(true);
        }else if(reinvite){
            setShowModalReinvite(true);
        }else if(productTour){
            if(productTour === "invite"){
               setSeePreview(true);
            }
        }else if(strategyUrl){
            fetchIntegrationUserList(strategyUrl);
        }
        url = "/settings/getForCompany/blueCollar";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setIsBlueCollar(response.data.value === 1);
            }else{
                setIsBlueCollar(false);
            }
        }).catch(error => {
            notifyError(error);
        })
        if(!companyToEdit){
            HttpApi.getV2("/integrations/sso/fetch").then(response => {
                if(response && response.data){
                    setIntegrationType(response.data);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }, [companyToEdit]);

    const toggleDrawerUpload = (open) => event => {
        event.preventDefault();
        setShowMenuAnchor(false);
        if(open){
            setExcelData([]);
            if(tourStartedManageUsers){
                setTimeout(() => {
                    updateStep(TourType.MANAGE_USERS,9)
                },300)
            }
        }else{
            document.getElementById("input-users").value = null;
        }
        if(tourStartedManageUsers && !open){
            setTourStartedManageUsers(false)
        }
        setSideFormUploadOpen(open);

    }

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(toEdit){
            let item = _.cloneDeep(toEdit);
            item.id_team = item.id_team || -1;
            item.teams_collab = item.teams_collab ? item.teams_collab.map(el => el.id) : item.teams_collab;
            item.teams = item.teams ? item.teams.map(el => el.id) : item.teams;
            item.filters = item.filters ? item.filters.map(el => el.id) : item.filters;
            item.id_roles = item.id_roles.filter(el => el !== 6);
            setEmployee(item);
        }else{
            resetUserEditState();
        }

        if (open){
            setSideFormEditOpen(open);
            if(tourStarted){
                setTimeout(()=>{
                    updateStep(TourType.DEFINE_ROLES,2)
                },360)
            }
            if(tourStartedManageUsers){
                setTimeout(()=>{
                    updateStep(TourType.MANAGE_USERS,5)
                },360)
            }
        }else{
            setSideFormEditOpen(open);
        }

    };

    const exportUsers = () => {
        HttpApi.getWithResponseTypeV2("/users/export").then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'ebloom-users.xlsx');
            notifySuccess("Users exported to excel file");
        }).catch(error => {
            notifyError(error);
        });
    }

    const downloadTemplate = () => {
        HttpApi.getWithResponseTypeV2("/companies/template/true").then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'ebloom-users-template.xlsx');
            notifySuccess("Template downloaded");
        })
    }
    useEffect(() => {
        return () => {
            setShowComparison(false)
            setShowMenuAnchor(false)
            setSeePreview(false)
            setExcelData([])
        };
    }, []);

    EmployeeModel.lang.table.show = false;
    EmployeeModel.lang.form.show = false;
    EmployeeModel.company_name.table.show = false;
    EmployeeModel.id_company.form.show = false;
    EmployeeModel.password.form.show = false;
    EmployeeModel.confirmPassword.form.show = false;
    EmployeeModel.oldPassword.form.show = false;
    EmployeeModel.email.form.readOnly = true;
    EmployeeModel.phone.form.show = false;
    EmployeeModel.team.table.show = isMoreThanHappy;
    EmployeeModel.team.table.alternative = companyToEdit ? "/" :  <Button size="sm" variant="link" style={{color:Colors.EBLOOM_PURPLE}}>{_.capitalize(t("admin.addToATeam"))}</Button>
    EmployeeModel.team.table.alternativeAction = companyToEdit ? () => {} : EmployeeModel.id_team.form.selectOptions.options.length > 0 ? handleOpenMenuTeams : redirectToTeams
    EmployeeModel.crud.delete = "/users/deleteEmployee";
    EmployeeModel.created_at.type = ModelTypes.DATE


        return(
           <>
               <ManageUsersTour setSideEdit={setSideFormEditOpen} setModal={setShowModalReinvite} setMenuOpen={setShowMenuAnchor} seePreview={seePreview}/>
               {
                   (!companyToEdit && !showComparison && !seePreview && !seeOtherChannelCom && !showInvitePreview) &&
                   <div className={"w-full flex justify-center mb-30 items-center"}>
                       {
                           showInviteSaved ?
                               <div className={"border border-contrast-purple rounded-md bg-bg-white grid grid-cols-2 grid-rows-1 items-center justify-around p-15 pointer"}>
                                   <div className={"m-5 p-5 text-center hover:bg-bg-grey rounded-md"}  onClick={() => {setSeePreview(true)}}>
                                       <div className={"bg-purple text-white rounded-md w-fit m-auto p-5 mb-10"}>
                                           {_.upperCase(t("core.new"))}
                                       </div>
                                       <div className={"text-contrast-purple"} dangerouslySetInnerHTML={{__html:_.upperFirst(t("admin.inviteNew"))}}/>
                                   </div>
                                   <div className={"m-5 p-5 text-center pointer hover:bg-bg-grey rounded-md"} onClick={() => {setShowInvitePreview(true)}}>
                                       <div className={"bg-purple text-white rounded-md w-fit m-auto p-5 mb-10"}>
                                           {_.toUpper(t("core.saved"))}
                                       </div>
                                       <div className={"text-contrast-purple"} dangerouslySetInnerHTML={{__html:_.upperFirst(t("admin.inviteSaved", {param:employees.filter(el => isEmail(el.email) && el.status === UserStatus.SAVED).length.toString()}))}}/>
                                   </div>
                               </div>
                               :
                               <div>
                                   {
                                       isBlueCollar ?
                                           <div className={"border border-contrast-purple rounded-md bg-bg-white grid grid-cols-2 grid-rows-1 items-center justify-around p-15 pointer"}>
                                               <div className={"m-5 p-5 text-center hover:bg-bg-grey rounded-md"}  onClick={employees.find(el => isEmail(el.email) && el.status === UserStatus.SAVED) !== undefined ? () => {setShowInvitedSaved(true)} : () => {setSeePreview(true)}}>
                                                   <div className={"logo_mail small_smile m-auto"}/>
                                                   <div className={"text-contrast-purple"}>{_.upperFirst(t("admin.inviteByMail"))}</div>
                                               </div>
                                               <div className={"m-5 p-5 text-center pointer hover:bg-bg-grey rounded-md"} onClick={() => {setSeeOtherChannelCom(true)}}>
                                                   <div className={"dialog_icon_blue small_smile m-auto"}/>
                                                   <div className={"text-contrast-purple"}>{_.upperFirst(t("admin.inviteByOther"))}</div>
                                               </div>
                                           </div>
                                           :
                                           <div className={"flex items-center"}>
                                               {
                                                   integrationType &&
                                                   <Button className={"bg-bg-white text-black border border-black flex items-center mr-5"} size="lg" variant={"secondary"} onClick={() => fetchIntegrationUserList(integrationType)}>
                                                       {getLogo(integrationType)}
                                                       <span className={"ebloom-text ebloom-normal-text text-black ml-5 w-full"}>{_.upperFirst(t("admin.synchronizeUsers"))}</span>
                                                   </Button>
                                               }
                                               <Button data-invite={'invite'} size="lg" className={"bg-purple noBorder hover:bg-purple/[0.7] text-white flex items-center"} onClick={employees.find(el => isEmail(el.email) && el.status === UserStatus.SAVED) !== undefined ? () => {setShowInvitedSaved(true)} : () => {
                                                   setSeePreview(true)
                                               }}>
                                                   <AddUserIcon className={"w-30 h-30 mr-5"}/>
                                                   <div>{_.capitalize(t("core.inviteUsers"))}</div>
                                               </Button>
                                           </div>

                                   }
                               </div>
                       }
                   </div>
                       }

               {
                   (!showComparison && !seePreview && !seeOtherChannelCom && !showInvitePreview) &&
                   <>
                       <DefineRolesTour setEmployee={setEmployee} setShowModal={setShowModal} setSeeOtherChannelCom={setSeeOtherChannelCom}/>
                       <CardDefault title={_.capitalize(t("core.user.users"))} showButton={!showReinvite } rightButtonVariant={"link"} buttonText={_.upperFirst(t("admin.reinviteUsers"))} handleRightButton={handleShowReinvite} showExtraRightButton extraRightButtonText={<IconButton data-users={'dots'} size="small" color="inherit"><MoreHorizIcon/></IconButton>} extraRightButtonVariant={"link"} handleExtraRightButton={handleOpenMenu} tourTooltip={true} tourType={TourType.MANAGE_USERS}>
                           <div data-roles={'list'}>
                               {
                                   showReinvite &&
                                   <div className={"flex items-center justify-end mb-5"}>
                                       <div className={"ebloom-link-purple"} onClick={handleSelectAll}>{(userToReinvite.length === employees.filter(el => el.status === UserStatus.INVITED).length ? _.upperFirst(t("core.deselectAll")) : _.upperFirst(t("core.selectAll")))}</div>
                                       <div className={"text-contrast-purple px-5 mx-5 border-x border-contrast-purple"}>{userToReinvite.length + " " + t("core.selectedParam", {param:t("core.user.userOrUsers")})}</div>
                                       <Button className={"bg-grey border-grey mx-5"} size={"sm"} onClick={handleHideReinvite}>{_.upperFirst(t("core.cancel"))}</Button>
                                       <Button className={"ebloom-btn-purple mx-5"} disabled={userToReinvite.length === 0} size="sm" onClick={handleOpenModalReinvite}>{_.upperFirst(t("core.reinvite"))}</Button>
                                   </div>
                               }
                               <TableViewV2 loading={loadingEmployees}
                                            items={(showReinvite ? employees.filter(el => el.status === UserStatus.INVITED) : employees)}
                                            model={EmployeeModel} showActions={companyToEdit === null} showEdit={!showReinvite} showDelete={!showReinvite} showCounter={true} handleEdit={toggleDrawer} handleDelete={fetchEmployees} tablePage={tablePage} handleTablePage={handleTablePage} defaultOrder={"desc"} defaultOrderBy={"created_at"} showDetail={!showReinvite} handleView={handleRowClick} handleObject textDetail={"Details"} showCheckbox={showReinvite} handleCheckbox={handleCheck} checkedItems={userToReinvite}/>
                           </div>
                       </CardDefault>
                   </>
               }
               {
                   seePreview &&
                        <InviteUsers createdEmailsAndPhones={employees.map(el => el.email)} handleBack={handleSeePreview} handleSeePreviewAndReload={handleSeePreviewAndReload}/>
               }
               {
                   showComparison &&
                       <UserComparison createdEmailsAndPhones={employees.map(el => el.email)} usersMatched={usersMatched} handleShowComparison={handleShowComparison} handleSeePreviewAndReloadBis={handleSeePreviewAndReload} {...props}/>
               }
               {
                   seeOtherChannelCom &&
                   <OtherChanelCom handleBack={() => {setSeeOtherChannelCom(false)}}/>
               }
               {
                   showInvitePreview &&
                   <InvitePreview toInviteConfirm={employees.filter(el => isEmail(el.email) && el.status === UserStatus.SAVED)} handleSeePreview={() => {setShowInvitePreview(false)}} handleSeePreviewAndReload={handleSeePreviewAndReload}/>
               }

               <SideForm tourDataset={{card:'',btn:'saveBtnSideForm'}} sideFormOpen={sideFormUploadOpen} formTitle={_.capitalize(t("core.uploadParam", {param:t("core.user.users")}))} toggleDrawer={toggleDrawerUpload} handleSave={handleSaveExcel} loading={loadingExcel} buttonDisabled={excelData.length === 0}>
                   <p style={{width:350, textAlign:"justify"}}>{_.upperFirst(t("admin.uploadMatchingHelper"))}</p>
                   <p style={{color:Colors.EBLOOM_STRONG_RED}} className={"center"}>{_.upperFirst(t("admin.uploadHelper2"))}</p>
                   <Row>
                       <span data-users={'template'} className={"ebloom-link-purple ebloom-text ebloom-very-large-text text-center"} onClick={downloadTemplate}>{_.upperFirst(t("core.downloadParam", {param:"template"}))}</span>
                   </Row>
                   <Row className={"mt20 mb20"}>
                       <Col>
                           <Form.Control data-users={'chooseFile'} type="file" id="input-users" accept=".xlsx, .xlsm, .xls" onChange={handleExcel} />
                       </Col>
                   </Row>
               </SideForm>
               <SideForm tourDataset={{card:'cardSideForm',btn:'editUserBtn'}} sideFormOpen={sideFormEditOpen} formTitle={_.capitalize(t("core.editParam", {param:t("core.user.user")}))} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                   <ModelForms model={EmployeeModel} handleChange={handleChange} values={employee}/>
               </SideForm>
               <DetailView model={EmployeeModel} item={employee} show={showDetail} handleClose={handleCloseDetail}/>
               <Menu className={'z-40'} id={"employee-editor-menu"} anchorEl={menuAnchor}  keepMounted open={showMenuAnchor} onClose={handleCloseMenu}>
                   <List>
                       <MenuItem key={1} onClick={handleOpen}>
                           <ListItemText data-users={'roles'} primary={_.capitalize(t("admin.roleGuide.roleGuide"))}/>
                       </MenuItem>
                       {
                           employees.find(el => isEmail(el.email) && el.status === UserStatus.SAVED) !== undefined &&
                           <MenuItem key={4} onClick={() => {setMenuAnchor(null); setShowMenuAnchor(false); setShowInvitePreview(true)}}><span dangerouslySetInnerHTML={{__html:_.upperFirst(t("admin.inviteSaved", {param:employees.filter(el => isEmail(el.email) && el.status === UserStatus.SAVED).length.toString()}))}}/></MenuItem>
                       }
                       {
                           employees.filter(el => el.status === "invited").length > 0 &&
                           <MenuItem key={3} onClick={handleShowReinvite}>
                               <ListItemText data-users={'reinviteUsers'} primary={_.upperFirst(t("admin.reinviteUsers"))}/>
                           </MenuItem>
                       }
                       {
                           isMoreThanHappy &&
                           <MenuItem key={2} onClick={toggleDrawerUpload(true)}>
                               <ListItemText data-users={'updateUsers'} primary={_.upperFirst(t("admin.updateUsers"))}/>
                           </MenuItem>
                       }
                   </List>
               </Menu>
               <Menu anchorEl={menuTeamAnchor} open={Boolean(menuTeamAnchor)} onClose={handleCloseMenuTeams}>
                   <MenuItem key={-1} value={-1} disabled>{_.upperFirst(t(EmployeeModel.id_team.form.label))}</MenuItem>
                   {
                       EmployeeModel.id_team.form.selectOptions.options.map((team, index) => (
                           <MenuItem key={index} value={team.id} onClick={() => {handleSaveTeam(team.id)}}>{team.name}</MenuItem>
                       ))
                   }
               </Menu>
               <ConfirmModal handleClose={handleCloseModalReinvite} show={showModalReinvite} title={_.upperFirst(t("admin.reinviteModalTitle", {param:userToReinvite.length.toString()}))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={handleSendInviteReminder}>
                   <p>{userToReinvite.length === 1 ? _.upperFirst(t("admin.reinviteOneModalText")) : _.upperFirst(t("admin.reinviteModalText", {param:userToReinvite.length.toString()}))}</p>
               </ConfirmModal>
               <ConfirmModal handleClose={handleClose} show={showModal} showButtons={false} title={_.upperFirst(t("admin.roleGuide.roleGuide"))} size="lg">
                   <EbloomGuide />
               </ConfirmModal>
           </>
        )

}

EmployeeEditor.defaultProps = {
    companyToEdit: JSON.parse(localStorage.getItem("ebloom-companyToEdit")),
};

export default EmployeeEditor;