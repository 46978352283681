import React, {useEffect, useState} from "react";
import {ReactComponent as IdeaBoxIcon} from "../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as PollsIcon} from "../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as MessageIcon} from "../../../../Core/symbols/webloom/white/message-white.svg";
import {ReactComponent as SmileyIcon} from "../../../../Core/symbols/myBloom/icons/smileyHappyWhite.svg";
import {useNavigationTo} from "../../../../../hooks/navigationHook";
import Login from "../../../../../login/login";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import EbloomNotificationBadge from "../../../../Core/Modules/Notifications/EbloomNotificationBadge";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {SurveyTypes, SurveyUsersStatus} from "../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {useUserData} from "../../../../../hooks/userHook";

const ActivityMenu = (props) => {

    const {activeSurveys} = props
    const userData = useUserData();

    const isProspect = userData.module === 'prospect';
    const navigation = useNavigationTo()
    const [hasNewCB, setHasNewCB] = useState(false);

    const t = useTranslation()


    const numberNotif = (type) => {
        if(!activeSurveys){
            return 0;
        }
        switch (type) {
            case 1:
                return (activeSurveys.filter(value => value.type === SurveyTypes.POLL)).length
            case 2:
                return (activeSurveys?.filter(value => value.type === SurveyTypes.IDEABOX && value.userSurveyStatus !== SurveyUsersStatus.ANSWERED)).length
            case 3:
                return (activeSurveys?.filter(value => value.type === SurveyTypes.MESSAGE && value.userSurveyStatus === SurveyUsersStatus.UNREAD)).length
            default:
                break;
        }
    }
    const checkIfCB = () => {
        if (Login.hasOneOfRole(["global_employee", "global_manager", "global_admin"]) && !isProspect) {
            HttpApi.get(`/survey/today/getQuestionOfTheDay`)
                .then((response) => {
                    if (response && response.data && response.data.length > 0) {
                        HttpApi.getV2("/feedbacks/getForUser/" + response.data[0].id_survey_question).then((response) => {
                            if (response && response.data === true) {
                                setHasNewCB(false);
                            } else {
                                setHasNewCB(true);
                            }
                        }).catch((error) => {
                            notifyError(error);
                            setHasNewCB(false)
                        })
                    } else {
                        setHasNewCB(false);
                    }
                })
                .catch((error) => {
                    notifyError(error);
                    setHasNewCB(false);
                });
        }
    }

    useEffect(() => {
        checkIfCB()
    }, []);


    return <div
        className={"w-full h-50 bg-contrast-purple rounded-md flex-row flex justify-around items-center divide-x-2 divide-white/30 "}>

        <EbloomTooltip text={"Click&Bloom"}>
            <button onClick={ hasNewCB ? () => navigation.to("/employee/daily") : () => {
            }} className={`w-1/4 h-full flex justify-center items-center ${!hasNewCB && "cursor-default" }` }>
                <div className={"w-1/2 h-full flex justify-center items-center "}>
                    {
                        hasNewCB ?
                            <EbloomNotificationBadge number={1} showNumber>
                                <SmileyIcon className={"w-25 h-25"}/>
                            </EbloomNotificationBadge>
                            :
                            <SmileyIcon className={"w-25 h-25"}/>
                    }

                </div>
            </button>
        </EbloomTooltip>

        <EbloomTooltip text={_.upperFirst(t("webloom.poll"))}>
            {
                numberNotif(1) > 0 ?
                    <button onClick={() => {navigation.to("/webloom/dashboard?orderBy=poll");}} className={"w-1/4 h-full flex justify-center items-center"}>
                        <div className={"mt-10"}>
                            <EbloomNotificationBadge number={numberNotif(1)} showNumber>
                                <PollsIcon className={"w-30 h-30"}/>
                            </EbloomNotificationBadge>
                        </div>

                    </button>
                    :
                    <div className={"w-1/4 h-full flex justify-center items-center"} >
                        <PollsIcon className={"w-30 h-30"}/>

                    </div>
            }
        </EbloomTooltip>

        <EbloomTooltip text={_.upperFirst(t("webloom.ideabox"))}>
            {
                numberNotif(2) > 0 ?
                    <button onClick={() => {navigation.to("/webloom/dashboard?orderBy=ideabox");}} className={"w-1/4 h-full flex justify-center items-center"}>
                        <div className={"mt-10"}>
                            <EbloomNotificationBadge number={numberNotif(2)} showNumber>
                                <IdeaBoxIcon className={"w-30 h-30"}/>
                            </EbloomNotificationBadge>
                        </div>

                    </button>
                    :
                    <div className={"w-1/4 h-full flex justify-center items-center"} >
                        <IdeaBoxIcon className={"w-30 h-30"}/>
                    </div>
            }
        </EbloomTooltip>
        <EbloomTooltip text={_.upperFirst(t("webloom.message"))}>
            {
                numberNotif(3) > 0 ?
                    <button onClick={() => {navigation.to("/webloom/dashboard?orderBy=message");}} className={"w-1/4 h-full flex justify-center items-center"}>
                        <div className={"mt-10"}>
                            <EbloomNotificationBadge number={numberNotif(3)} showNumber>
                                <MessageIcon className={"w-30 h-30"}/>
                            </EbloomNotificationBadge>
                        </div>

                    </button>
                    :
                    <div className={"w-1/4 h-full flex justify-center items-center"} >
                        <MessageIcon className={"w-30 h-30"}/>
                    </div>
            }
        </EbloomTooltip>

    </div>
}
export default ActivityMenu