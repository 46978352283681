import React from "react"
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import IntegrationEditor from "./IntegrationEditor";


const IntegrationEditorPage = (props) => {

    return (
        <DisplayBackofficeView {...props}>
            {
                (companyToEdit) => (
                    <IntegrationEditor companyToEdit={companyToEdit} {...props}></IntegrationEditor>
                )
            }
        </DisplayBackofficeView>
    )

}

export default IntegrationEditorPage;