import {ModelTypes} from "./Types";

const Question = {
    crud:{
        create:"/questions/create",
        update:"/questions/update",
        delete:"/questions/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    questions_tr:{
        id:"text",
        type:ModelTypes.RELATIONS,
        key:"text",
        label:"core.question",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"text-question",
            label:"core.question",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text"
        }
    },


    text_fr:{
        id:"text_fr",
        type:ModelTypes.TEXT,
        label:"core.question",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"text-question-fr",
            label:"core.questionFr",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_fr"
        }
    },

    text_nl:{
        id:"text_nl",
        type:ModelTypes.TEXT,
        label:"core.question",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"text-question-nl",
            label:"core.questionNl",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_nl"
        }
    },

    text_en:{
        id:"text_en",
        type:ModelTypes.TEXT,
        label:"core.questionEn",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"text-question-en",
            label:"core.questionEn",
            select:false,
            multiline:true,
            password:false,
            rows:5,
            required:true,
            name:"text_en"
        }
    },

    id_category:{
        id:"id_category",
        type:ModelTypes.NUMBER,
        label:"id_category",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"category-question",
            label:"core.category.category",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_category"
        }
    },

    category:{
        id:"category",
        type:ModelTypes.RELATION,
        key:"name",
        label:"core.category.category",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    id_tag:{
        id:"id_tag",
        type:ModelTypes.NUMBER,
        label:"id_tag",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"tag-question",
            label:"core.tag",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_tag"
        }
    },

    tag:{
        id:"tag",
        type:ModelTypes.RELATION,
        key:"name",
        label:"core.tag",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    company:{
        id:"type",
        type:ModelTypes.RELATION,
        key:"name",
        label:"core.type",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"type-question",
            label:"core.type",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"type"
        }
    },

    sector:{
        id:"sector",
        type:ModelTypes.TEXT,
        label:'core.sector',
        table:{
            show: true
        },
        list:{
            show: true
        },
        form:{
            show:true,
            id:"sector-question",
            label:"core.sector",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"sector"
        }
    },

    lastUsed:{
        id:"last_used",
        type:ModelTypes.DATE,
        label:"core.lastUsed",
        table:{
            show:false,
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },
};


export default Question;