import {createSlice} from "@reduxjs/toolkit";

export const user = createSlice({
    name: 'user',
    initialState: {
        id: null,
        email: null,
        name:null,
        id_company:null,
        company:null,
        roles:null,
        is_super_admin: false,
        module:null,
        company_type:null,
        id_company_group : null,
    },
    reducers: {
        updateId: (state, action) => {
            state.id = action.payload
        },
        updateEmail: (state, action) => {
            state.email = action.payload
        },
        updateName: (state, action) => {
            state.name = action.payload
        },
        updateIdCompany: (state, action) => {
            state.id_company = action.payload
        },
        updateCompany: (state, action) => {
            state.company = action.payload
        },
        updateRoles: (state, action) => {
            state.roles = action.payload
        },
        updateIsSuperAdmin: (state, action) => {
            state.is_super_admin = action.payload
        },
        updateModule: (state, action) => {
            state.module = action.payload
        },
        updateCompanyType:(state, action) => {
            state.company_type = action.payload
        },
        updateCompanyGroup: (state, action) => {
            state.company_group = action.payload
        }
    },
})

export const {updateId, updateEmail, updateName, updateIdCompany, updateCompany, updateRoles,updateIsSuperAdmin, updateModule, updateCompanyType,updateCompanyGroup} = user.actions;

export default user.reducer;