import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {customLibrarySteps} from "./customLibrarySteps";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import PropTypes from "prop-types";

const CustomLibraryTour = (props) => {

    const {handleStep,stepCustomization} = props
    const {
        updateStep,
        stepTour,
        stepCustomLibrary, setStepCustomLibrary,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        continuous, setContinuous,
        icon,
        updateHandler
    } = useContext(ProductTourContext)

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);

            if(ACTIONS.NEXT === action){
                if(index === 2){
                    setContinuous(false)
                    updateStep(TourType.CUSTOM_LIBRARY,next)
                }else {
                    setContinuous(true)
                    updateStep(TourType.CUSTOM_LIBRARY,next)
                }
            }else if (ACTIONS.PREV === action){
                if(index === 1){
                    updateStep(TourType.CUSTOM_LIBRARY,1)
                }else if(index === 3 ){
                    handleStep(1)
                    setContinuous(true)
                    updateStep(TourType.CUSTOM_LIBRARY,1)
                }else if (index === 4){
                    setContinuous(false)
                    handleStep(1)
                    updateStep(TourType.CUSTOM_LIBRARY,3)
                }else{
                    setContinuous(true)
                    updateStep(TourType.CUSTOM_LIBRARY,next)
                }
            }else if (ACTIONS.CLOSE === action){
                if(index=== 3){
                    if(stepCustomization === 8){
                        setContinuous(true)
                        updateStep(TourType.CUSTOM_LIBRARY,next)
                    }
                }
            }
        }
    }

    const handleTour = () => {
        if(stepCustomization===8){
            handleStep(1)
            setContinuous(true)
            updateStep(TourType.CUSTOM_LIBRARY,1)
        }
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)

        const tour = {
            id : stepTour[TourType.CUSTOM_LIBRARY].id,
            current_step : stepTour[TourType.CUSTOM_LIBRARY].step >= customLibrarySteps.length - 1 ? 0 : stepTour[TourType.CUSTOM_LIBRARY].step,
            type: TourType.CUSTOM_LIBRARY,
            finished : stepTour[TourType.CUSTOM_LIBRARY].outcome
        }
        if(stepCustomLibrary >= customLibrarySteps.length - 1){
            setStepCustomLibrary(1)
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})
        }
        updateHandler(TourType.CUSTOM_LIBRARY,handleTour)

    }

    const iconRinging = () => {
        if(icon.current && stepTour[TourType.CUSTOM_LIBRARY]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.CUSTOM_LIBRARY].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepCustomLibrary]);


    useEffect(() => {
        fetchData();
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        updateHandler(TourType.CUSTOM_LIBRARY,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);

    useEffect(() => {
       if(stepCustomization===8){
           setContinuous(true)
           updateStep(TourType.CUSTOM_LIBRARY,4)
       }else if(stepCustomization === 0){
           setContinuous(true)
           updateStep(TourType.CUSTOM_LIBRARY,0)
       }
        updateHandler(TourType.CUSTOM_LIBRARY,handleTour)

    }, [stepCustomization]);


    return(
        <>
            <ReactJoyride
                steps={customLibrarySteps}
                continuous={continuous}
                run={tourStarted}
                stepIndex={stepCustomLibrary}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />

        </>
    )
}

CustomLibraryTour.propTypes = {
    handleStep : PropTypes.func.isRequired,
    stepCustomization : PropTypes.number.isRequired
}

export default CustomLibraryTour