import React from "react"
import {Container} from "react-bootstrap";
import WeBloomCreateAlert from "./WeBloomCreateAlert";
import DisplayAdminView from "../../../../Layouts/DisplayAdminView";

const WeBloomCreateAlertPage = (props) => {

    return (
        <DisplayAdminView pageName={"WeBloom"} menu={"noMenu"} selectedIndex={9} {...props}>
        {
            () => (
                <Container>
                    <WeBloomCreateAlert {...props}/>
                </Container>
            )
        }
        </DisplayAdminView>
    )

}

export default WeBloomCreateAlertPage;