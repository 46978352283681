import {ModelTypes} from "./Types";

const Colors = {
    crud:{
        create:"/palette/create",
        update:"/palette/update",
        delete:"/palette/delete"
    },
    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },
    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"color-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
        }
    },
    primary:{
        id:"primary",
        type:ModelTypes.TEXT,
        label:"primary",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show: true
        },
        list: {},
        form: {
            show:true,
            id:"color-primary",
            label:"primary",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"primary",
        }
    },
    secondary: {
        id:"secondary",
        type:ModelTypes.TEXT,
        label:"secondary",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show:false
        },
        list:{

        },
        form:{
            show:false,
            id:"color-secondary",
            label:"secondary",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"secondary",
        }
    },
    tertiary:{
        id:"tertiary",
        type:ModelTypes.TEXT,
        label:"tertiary",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show:false
        },
        list:{

        },
        form:{
            show:false,
            id:"color-tertiary",
            label:"tertiary",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"tertiary",
        }
    },
    quaternary:{
        id:"quaternary",
        type:ModelTypes.TEXT,
        label:"quaternary",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show:false
        },
        list:{

        },
        form:{
            show:false,
            id:"color-quaternary",
            label:"quaternary",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"quaternary",
        }
    },
    quinary:{
        id:"quinary",
        type:ModelTypes.TEXT,
        label:"quinary",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show:false
        },
        list:{

        },
        form:{
            show:false,
            id:"color-quinary",
            label:"quinary",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"quinary",
        }
    },
    background:{
        id:"background",
        type:ModelTypes.TEXT,
        label:"background",
        table:{
            formatValue : (value) => {
                return <div className={`w-full flex justify-center items-center`}>
                    <div style={{backgroundColor: value}} className={`w-15 h-15 flex `}/>
                </div>
            },
            show:false,
        },
        list:{

        },
        form:{
            show:false,
            id:"color-background",
            label:"background",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"background",
        }
    },
    zone:{
        id:"zone",
        type:ModelTypes.TEXT,
        label:"zone",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },
    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },

}

export default Colors