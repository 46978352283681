import {ModelTypes} from "./Types";

const Module = {
    crud:{
        create:"/modules/create",
        update:"/modules/update",
        delete:"/modules/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"module-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
            helperText:""
        }
    },

    code:{
        id:"code",
        type:ModelTypes.TEXT,
        label:"core.code",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"company-code",
            label:"core.code",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"code",
            helperText:"core.login.companyCodeHelper"
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Module;