import React, {useState} from "react";
import {Colors} from "../../Utils/Colors";
import Container from "react-bootstrap/Container";
import {Card, Col, Row} from "react-bootstrap";
import TextFieldCustom from "./Modules/Input/TextFieldCustom";
import CompanyModel from "../../models/Company";
import * as microsoftTeams from "@microsoft/teams-js";
import HttpApi from "../../httpApi";

const TeamsConfig = (props) => {

    const [codeAdmin, setCodeAdmin] = useState(null);

    const handleChange = (event) => {
        const value = event.target.value;
        setCodeAdmin(value);
        if(value.length > 0){
            microsoftTeams.settings.setValidityState(true);
        }else{
            microsoftTeams.settings.setValidityState(false);
        }
    }


    microsoftTeams.initialize();

    microsoftTeams.settings.registerOnSaveHandler(function (saveEvent) {

        microsoftTeams.settings.setSettings({
            entityId: "notification",
            contentUrl: "https://www.ebloom-testnbloom.be/configTeams",
            removeUrl: "https://www.ebloom-testnbloom.be/removeTeams",
            configName: "notification"
        });

        microsoftTeams.settings.getSettings(function (settings) {

            HttpApi.postV2("/integrations/saveTeamsCredentials", {webhookUrl:settings.webhookUrl}).then(() => {
                console.log("success");
                saveEvent.notifySuccess();
            }).catch(error => {
                saveEvent.notifyFailure(error);
            })
            // We get the Webhook URL in settings.webhookUrl which needs to be saved.
            // This can be used later to send notification.
        });

    });


    return(
        <div style={{backgroundColor:Colors.EBLOOM_BG_BLUE, minHeight:"100vh"}}>
            <div style={{padding: 60}} >
                <div className="logo-ebloom" style={{height: 90}}/>
            </div>
            <Container>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card style={{backgroundColor:"transparent", borderColor:"transparent"}}>
                            <Card.Body>
                                <div style={{textAlign:"center"}}>
                                    <Card.Title style={{color:Colors.EBLOOM_PURPLE, fontSize:"2em"}}>Teams Configuration</Card.Title>
                                </div>
                                <Row>
                                    <TextFieldCustom
                                        id="access_key_config_teams"
                                        model={CompanyModel.code_admin}
                                                     value={codeAdmin}
                                                     handleChange={handleChange}/>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TeamsConfig;