
export const inviteSteps = [
    {
        title: "admin.productTour.invite.step1.title",
        content: "admin.productTour.invite.step1.content",
        target: '[data-tour="excelBtn"]',
        placement: 'center',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
    },
    {
        title: "admin.productTour.invite.step2.title",
        content: "admin.productTour.invite.step2.content",
        target: '[data-tour="excelBtn"]',
        placement: 'left',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
    },
    {
        title: "admin.productTour.invite.step3.title",
        content: "admin.productTour.invite.step3.content",
        target: '[data-invite="template"]',
        placement: 'left',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        showProgress:true,
        spotlightPadding: 0,
        styles: {
            options : {
                zIndex: 1500
            }
        }
    },
    {
        title: "admin.productTour.invite.step4.title",
        content: "admin.productTour.invite.step4.content",
        target: '[data-invite="chooseFile"]',
        placement: 'left',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        showProgress:true,
        spotlightPadding: 4,
        styles: {
            options : {
                zIndex: 1500
            }
        }
    },
    {
        title: "admin.productTour.invite.step5.title",
        content: "admin.productTour.invite.step5.content",
        target: '[data-tour="btnInviteSave"]',
        placement: 'left',
        disableBeacon : false,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        showProgress:true,
        spotlightPadding: 3,
        disableNext : false,
        placementBeacon: 'top',
        disableSkip : true,
        nextText : "admin.productTour.invite.step5.nextText",
        styles: {
            options : {
                zIndex : 1500
            }
        }
    },
    {
        title: "admin.productTour.invite.step6.title",
        content: "admin.productTour.invite.step6.content",
        target: '[data-invite="resultExcel"]',
        placement: 'top',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,
        toolTipWidth : 'auto' ,
        styles: {
            options : {
                zIndex : 1500
            }
        }
    },
    {
        title: "admin.productTour.invite.step7.title",
        content: "admin.productTour.invite.step7.content",
        target: '[data-invite="saveUsers"]',
        placement: 'top',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,
        toolTipWidth : 'auto' ,
        styles: {
            options : {
                zIndex : 1500
            }
        }
    },
    {
        title: "admin.productTour.invite.step8.title",
        content: "admin.productTour.invite.step8.content",
        target: '[data-invite="seePreview"]',
        placement: 'left',
        placementBeacon: 'top',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        spotlightPadding: 3,
        disableNext : false,
        disableSkip: true,
        nextText : "admin.productTour.invite.step8.nextText",
    },
    {
        title: "admin.productTour.invite.step8.title",
        content: "admin.productTour.invite.step8.content",
        target: '[data-invite="seePreview"]',
        placement: 'left',
        placementBeacon: 'top',
        disableBeacon : false,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        spotlightPadding: 3,
        disableNext : false,
        disableSkip: true,
        nextText : "admin.productTour.invite.step8.nextText",
    },
    {
        title: "admin.productTour.invite.step9.title",
        content: "admin.productTour.invite.step9.content",
        target: '[data-invite="editText"]',
        placement: 'left',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,
    },
    {
        title: "admin.productTour.invite.step10.title",
        content: "admin.productTour.invite.step10.content",
        target: '[data-invite="editLang"]',
        placement: 'right',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,

    },
    {
        title: "admin.productTour.invite.step11.title",
        content: "admin.productTour.invite.step11.content",
        target: '[data-invite="finalInvite"]',
        placement: 'top',
        disableBeacon : false,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        disableNext : true,
        nextEmpty : true,
        enableLastSkip : true
    },
]