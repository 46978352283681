import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PollTypes} from "../../Modules/Types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../../../../lang/translations";
import {replaceUrlsWithLinks} from "../../../../../../../Utils/Global";

const ProgressBar = (props) => {

    const {option, total, type, lang, anonymityRestriction, noData} = props;

    const [progressWidth, setProgressWidth] = useState(0);

    const t = useTranslation();

    const displayPercent = noData ? _.upperFirst(t("core.collaborative.noAnswer")) : anonymityRestriction ? _.upperFirst(t("manager.dashboard.anonymityRestrictionSmall")) : !total ? _.upperFirst(t("webloom.NA")) : Math.round((option.selected/total)*100) + "%"

    useEffect(() => {
        let idTimeout = null;
        let final = !total ? 0 : Math.round((option.selected/total)*100);
        setProgressWidth(0);
        idTimeout = setTimeout(() => {
            setProgressWidth(final);
        }, 100);

        return () => {
            if(idTimeout !== null){
                clearTimeout(idTimeout);
            }
        }

    }, [option, total])

    return(
        <div className={"mt-20 mb-20"}>
            <div className={"w-10/12 inline-block"}>
                <div className={"flex justify-between text-contrast-purple"}>
                    <span className={"ebloom-text ebloom-large-text"}>{option.text === "Other" ? _.upperFirst(getTranslations(lang,"webloom.other")) : option.text === "N/A" ? _.upperFirst(getTranslations(lang,"webloom.NA")) : replaceUrlsWithLinks(option.text)}</span>
                    {
                        noData &&
                        <span className={"w-fit whitespace-nowrap text-contrast-purple"}>{displayPercent}</span>
                    }
                    {
                        (!anonymityRestriction) &&
                        <span className={"ebloom-text ebloom-small-text"}><span className={"ebloom-large-text font-semibold mr-5"}>{option.selected}</span>{t("core.answers")}</span>
                    }
                </div>
                <div className={"h-20 bg-grey w-full"}><div className={"h-20 bg-contrast-purple ease-linear duration-500 max-w-full"} style={{width:progressWidth + "%"}}></div></div>
            </div>
            {
                !noData &&
                    <div className={"w-1/12 inline-block ml-10 text-contrast-purple font-semibold ebloom-text ebloom-very-large-text relative top-23"}>
                        {displayPercent}
                    </div>
            }

        </div>
    )
}

ProgressBar.propTypes = {
    option:PropTypes.object.isRequired,
    total:PropTypes.number.isRequired,
    type:PropTypes.string.isRequired
}

export default ProgressBar;