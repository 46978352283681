import React, {useContext, useEffect, useState} from "react";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {Badge, Button, Col, Card, Row} from "react-bootstrap";
import {getLanguages} from "../../../../lang/translations";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import {Colors} from "../../../../Utils/Colors";
import {
    IdentityTypes,
    FullContrastPurpleTextField,
    getUrl,
    isNullOrUndefined,
    PurpleTextField,
    TourType
} from "../../../../Utils/Global";
import HttpApi from "../../../../httpApi";
import {MenuItem} from "@mui/material";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {notifyError, notifySuccess} from "../../../../Utils/Notification";
import LoadingView from "../../../Core/Layouts/LoadingView";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useSavePage} from "../../../../hooks/savePageHook";
import {useUserData} from "../../../../hooks/userHook";
import CustomLibraryTour from "../../../../Utils/ProductTour/Tours/CustomLibrary/CustomLibraryTour";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";


const IdentityStarter = (props) => {
    const [loading, setLoading] = useState(false);
    const [langs, setLangs] = useState([]);
    const [step, setStep] = useState(0);
    const [listIdentity, setListIdentity] = useState(null);
    const [identities, setIdentities] = useState([]);
    const [selectedLang, setSelectedLang] = useState("fr");
    const [show, setShow] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [showModalLang, setShowModalLang] = useState(false);
    const [showModalSkip, setShowModalSkip] = useState(false);
    const {companyToEdit,selectedEntity} = props;

    const {updateStep, setContinuous ,validateOutcome,tourActivated, setTourStarted} = useContext(ProductTourContext);

    const user = useUserData();

    const isSuperAdmin = user.roles.includes("global_super_admin");


    const page = useSavePage(true);


    const steps = [
        {name:"company",
            fr:{keySentence:"Pour le moment, j'aime l'image de {l'entreprise}", keyValue:"l'entreprise", examples:["{L'entreprise} communique une vision qui me motive","En ce moment, je me sens soutenu(e) par {l'entreprise} et ai accès aux aides nécessaires à mon bien-être au travail", "Pour le moment, je me sens connecté(e) à {l'entreprise}, ses valeurs, sa culture", "Actuellement, je trouve que {l'entreprise} est bien organisée pour le télétravail"]},
            en:{keySentence:"At the moment I like {the company}'s image", keyValue:"the company", examples:["{The company} communicates a vision that motivates me", "I'm currently feeling supported by {the company} and have access to the help I need for my well-being at work", "I'm currently feeling connected to {the company}, its values, its culture", "I currently find that {the company} is well organized for home-working"]},
            nl:{keySentence:"Op dit moment hou ik van het imago van {het bedrijf}", keyValue:"het bedrijf", examples:["{Het bedrijf} communiceert een visie die mij motiveert","Op dit moment voel ik me gesteund door {het bedrijf} en heb toegang tot de hulp die ik nodig heb om me goed te voelen op het werk", "Voor het moment voel ik me verbonden met {het bedrijf}, haar waarden, haar cultuur", "Momenteel vind ik dat {het bedrijf} goed georganiseerd is voor het telewerken"]}},
        {name:"manager",
            fr:{keySentence:"Pour le moment, je me sens valorisé(e) par {mon/ma manager}", keyValue:"mon/ma manager", examples:["En ce moment, je collabore bien avec {mon/ma manager}", "En ce moment, je sens que {mon/ma manager} m'apprécie", "J'apprécie {mon/ma manager} actuel(le)"]},
            en:{keySentence:"For now I'm feeling valued by {my manager}", keyValue:"my manager", examples:["I'm currently working well with {my manager}", "I'm currently feeling that {my manager} appreciates me", "I like {my current manager}"]},
            nl:{keySentence:"Voor het moment voel ik me gewaardeerd door {mijn manager}", keyValue:"mijn manager", examples:["Op dit moment werk ik goed samen met {mijn manager}", "Tegenwoordig heb ik het gevoel dat {mijn manager} me waardeert", "Ik waardeer {mijn actuele manager}"]}},
        {name:"team",
            fr:{keySentence:"Pour le moment, je suis satisfait(e) de la manière dont les conflits sont gérés dans {mon équipe}", keyValue:"mon équipe", examples:["En ce moment, je trouve que la collaboration est bonne au sein de {mon équipe}", "Pour le moment, j'aime la dynamique qu'il y a dans {mon équipe}"]},
            en:{keySentence:"I'm currently satisfied with how conflicts are managed in {my team}", keyValue:"my team", examples:["I'm currently feeling that I'm part of {a team} in which everyone gives their best", "I currently like {my team}'s dynamic"]},
            nl:{keySentence:"Voor het moment ben ik tevreden met de manier waarop conflicten in {mijn team} worden beheerd",keyValue:"mijn team", examples:["Op dit moment voel ik dat ik deel uitmaak van {een team} waarin iedereen het beste van zichzelf geeft", "Voor het moment hou ik van de dynamiek in {mijn team}"]}},
        {name:"managementTeam",
            fr:{keySentence:"Pour le moment, je sens que {l'équipe dirigeante} donne le meilleur d'elle même pour l'entreprise", keyValue:"l'équipe dirigeante", examples:["En ce moment, je trouve que {l'équipe dirigeante} de l'entreprise est inspirante", "Ces temps-ci, je trouve que {l'équipe dirigeante} représente bien les valeurs de l'entreprise"]},
            en:{keySentence:"I'm currently feeling {the management team} is doing their absolute best for the company", keyValue:"the management team", examples:["I'm currently feeling that the company's {management team} is inspiring", "These days I've been feeling that {the management team} represents the company's values well"]},
            nl:{keySentence:"Momenteel heb ik het gevoel dat {het managementteam} zijn beste beentje voorzet ten dienste van het bedrijf", keyValue:"het managementteam", examples:["Op dit ogenblik vind ik {het managementteam} van het bedrijf inspirerend", "Tegenwoordig vind ik dat {het managementteam} de waarden van het bedrijf goed vertegenwoordigt"]}},
        {name:"hrTeam",
            fr:{keySentence:"Ces derniers temps, {l'équipe RH} est accessible et disponible", keyValue:"l'équipe RH", examples:[]},
            en:{keySentence:"{The HR team} has been accessible and available lately", keyValue:"the HR team", examples:[]},
            nl:{keySentence:"De laatste tijd is {het HR-team} toegankelijk en beschikbaar", keyValue:"het HR-team", examples:[]}},
        {name:"colleagues",
            fr:{keySentence:"Actuellement, je me sens respecté(e) par {mes collègues}", keyValue:"mes collègues", examples:["Pour le moment, {mes collègues} sont réactifs à mes communications", "Ces derniers temps, j'ai de bonnes relations avec {mes collègues}"]},
            en:{keySentence:"I'm currently feeling respected by {my colleagues}", keyValue:"my colleagues", examples:["{my colleagues} are currently reactive to my communications", "I currently have good relationships with {my colleagues}"]},
            nl:{keySentence:"Momenteel voel ik me gerespecteerd door {mijn collega's}", keyValue:"mijn collega's", examples:["De laatste tijd zijn {mijn collega's} reactief op mijn communicaties", "De laatste tijd heb ik goede relaties met {mijn collega's}"]}},
        {name:"values",
            fr:{keySentence:"{}", keyValue:"valeurs", examples:["Je partage les valeurs de l'entreprise", "Ces temps-ci, je trouve que l'équipe dirigeante représente bien les valeurs de l'entreprise"]},
            en:{keySentence:"{}", keyValue:"values", examples:["I share the company's values", "These days I've been feeling that the management team represents the company's values well"]},
            nl:{keySentence:"{}", keyValue:"waarden", examples:["Ik deel de waarden van het bedrijf", "Tegenwoordig vind ik dat het managementteam de waarden van het bedrijf goed vertegenwoordigt"]}},
        {name:"validation"}
    ];

    const companyPrefix = {name:"Prefix", keySentenceStart:"En ce moment, je trouve que l'ambiance ", keySentenceEnd:" est bonne", keyPlaceHolder:"chez", keyValue:"dans l'entreprise", keyValuePlaceHolder:"chez" , keyAfterValueName:" l'entreprise", examples:["Je retrouve les valeurs qui sont importantes pour moi {dans l'entreprise}"]}

    const colleguesPrefix = {name:"Prefix", keySentenceStart:"La dernière fois que j'ai rencontré une difficulté, j'ai pu compter sur le soutien ", keySentenceEnd:"", keyValue:"de mes collègues", examples:["En ce moment, je me sens libre de m'exprimer ouvertement auprès {de mes collègues}", "Ces derniers temps, je reçois des commentaires positifs sur mon travail de la part {de mes collègues}"]}

    const t = useTranslation();


    const handleLang = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;
        setLangs(prevState => {
            let langs = [...prevState];
            if(checked){
                langs.push(value);
            }else{
                langs.splice(langs.indexOf(value), 1);
            }
            return langs;
        })
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleUpdateLang = () => {
        if(langs.length === 0){
            setStep(8);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleStep = (number) => {
        if(step === 0){
            setSelectedLang([langs[0]]);
            updateStep(TourType.CUSTOM_LIBRARY,1)
        }else{
            setContinuous(false)
            updateStep(TourType.CUSTOM_LIBRARY,3)
        }
        setStep(number);
    }

    const handleClickStep = (index) => {
        if(index<step){
            setStep(index);
        }else{
            if(index !==8){
                if(emptyInputs() && !checkAllEmpty()){
                    setShowModalSkip(true);
                }else{
                    setStep(index);
                }
            }else{
                setStep(index);
            }
        }
        const keyInTheCompany = identities.filter(el => el.key_value === "dans l'entreprise")
        const toModify = keyInTheCompany[0]
        const keyTheCompany = identities.filter(el => el.key_value === "l'entreprise")
        if(keyInTheCompany.length>0 && keyTheCompany.length>0 && keyTheCompany[0].text.trim() !== ''){
            const company = keyTheCompany[0].text
            if(keyInTheCompany[0].text.replace(company,'').trim() === ''){
                setIdentities(prevState => {
                    const index = prevState.findIndex(el => el.key_value === "dans l'entreprise");
                    if(index !== -1){
                        toModify.text = "chez "+keyTheCompany[0].text;
                        prevState[index] = toModify
                    }else{
                        prevState.push({id:null, text:"chez "+keyTheCompany[0].text, lang:'fr', key_value:"dans l'entreprise",type : IdentityTypes.GLOBAL})
                    }

                    return prevState
                })
            }
        }else if(keyInTheCompany.length ===0 && keyTheCompany.length>0 && keyTheCompany[0].text.trim() !== ''){
            setIdentities(prevState => {
                prevState.push({id:null, text:"chez "+keyTheCompany[0].text, lang:'fr', key_value:"dans l'entreprise",type : IdentityTypes.GLOBAL})
                return prevState
            })
        }else if (keyTheCompany.length ===0 ){
            setIdentities(prevState => {
                const index = prevState.findIndex(el => el.key_value === "dans l'entreprise");
                if(index !== -1){
                    toModify.text = ''
                    prevState[index] = toModify
                }
                return prevState
            })
        }

    }

    const handleChange = (hasValue, lang, keyValue) => event => {
        const value = event.target.value;
        setIdentities(prevState => {
            let identities = [...prevState];
            if(hasValue){

                let i = identities.filter(el => el.key_value === keyValue)[0];
                let j = null;
                if(keyValue === "dans l'entreprise"){
                    const valueCompany = identities.filter(el => el.key_value === "l'entreprise")[0].text;
                    if(valueCompany !== undefined && valueCompany !== null && valueCompany.trim() !== ""){
                        i.text = value+" "+valueCompany
                    }
                }else if(keyValue === "l'entreprise"){
                    j = identities.filter(el => el.key_value === "dans l'entreprise")[0];
                    if(j !== null && j !== undefined){
                        const newValue = j
                        const prefix = j.text.split(" ")[0]
                        newValue.text = prefix +" "+ value
                        if(identities.indexOf(j) !== -1){
                            identities.splice(identities.indexOf(j), 1);
                        }
                        identities.push(newValue)
                    }else{
                        const newValue = {id:null, text: "chez "+ value, lang:lang, key_value:"dans l'entreprise",type : IdentityTypes.GLOBAL}
                        if(identities.indexOf(j) !== -1){
                            identities.splice(identities.indexOf(j), 1);
                        }
                        identities.push(newValue)
                    }
                }else if(keyValue === "mes collègues"){
                    j = identities.filter(el => el.key_value === "de mes collègues")[0];
                }
                if((value === undefined || value === null || value.trim() === "") && !i.id){
                    identities.splice(identities.indexOf(i), 1);
                    if(j && !j.id){
                        identities.splice(identities.indexOf(j), 1);
                    }
                }else{
                    if(keyValue === "dans l'entreprise"){
                        const valueCompany = identities.filter(el => el.key_value === "l'entreprise")[0].text;
                        if(valueCompany !== undefined && valueCompany !== null && valueCompany.trim() !== ""){
                            i.text = value+" "+valueCompany
                        }
                    }else{
                        i.text = value;
                    }

                    if((value === undefined || value === null || value.trim() === "") && j !== null && j !== undefined){
                        j.text = value;
                    }
                }
            }else{
                if(keyValue === "dans l'entreprise"){
                    const valueCompany = identities.filter(el => el.key_value === "l'entreprise")[0].text;
                    if(valueCompany !== undefined && valueCompany !== null && valueCompany.trim() !== ""){
                        identities.push({id:null, text:value+" "+valueCompany, lang:lang, key_value:keyValue,type : IdentityTypes.GLOBAL})
                    }
                }else{
                    identities.push({id:null, text:value, lang:lang, key_value:keyValue,type : IdentityTypes.GLOBAL})
                }

            }
            return identities;
        })

        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeLang = (event) => {
        setSelectedLang(event.target.value);
    }

    const handleOpenModal = () => {
        setShow(true);
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleOpenModalLang = () => {
        setAnchor(null);
        setShowModalLang(true);
    }

    const handleCloseModalLang = () => {
        setShowModalLang(false);
    }

    const handleOpenModalSkip = () => {
        if(checkAllEmpty()){
            setStep(step+1);
        }else{
            setShowModalSkip(true);
        }

    }

    const handleCloseModalSkip = () => {
        setShowModalSkip(false);
    }

    const handleSkip = () => {
        setIdentities(prevState => {
            let identities = [...prevState];
            const s = steps[step-1];
            Object.values(s).forEach((entry, index) => {
                if(index>0){
                    if(identities.filter(el => el.key_value === entry.keyValue).length > 0){
                        let i = identities.filter(el => el.key_value === entry.keyValue)[0];
                        if(!i.id){
                            identities.splice(identities.indexOf(i), 1);
                        }else{
                            i.text = "";
                        }
                    }
                }
            })
            return identities;
        })
        setStep(step+1);
    }

    const checkAllEmpty = (index=null) => {
        index = index !== null ? index  : step-1;
        let empty=true;
        langs.forEach(l => {
            if(identities.filter(el => el.key_value === steps[index][l].keyValue && el.text).length > 0) {
                empty = false;
            }
        })
        return empty;
    }

    const emptyInputs = (index=null) => {
        index = index !== null ? index : step-1;
        let empty=false;
        langs.forEach(l => {
            if(identities.filter(el => el.key_value === steps[index][l].keyValue && el.text).length <= 0){
                empty=true;
            }
        })
        return empty;
    }

    const handleSave = () => {
        if(!loading){
            setLoading(true);
            let listIdentityCopy = listIdentity;
            const identitiesCopy = [...identities]
            identitiesCopy.filter(el => !langs.includes(el.lang)).forEach(i => {
                i.text="";
            })
            let url = "/identities/synchronize"
            if(selectedEntity){
                url = "/identities/synchronize/"+selectedEntity
            }
            listIdentityCopy.identities = identitiesCopy;
            HttpApi.postV2(url, listIdentityCopy).then(() => {
                fetchIdentities()
                page.save();
                notifySuccess("Identity saved - Library synchronized");
                if(tourActivated){
                    updateStep(TourType.CUSTOM_LIBRARY,7)
                    if(identities.length > 0){
                        validateOutcome(TourType.CUSTOM_LIBRARY)
                    }


                }
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const fetchIdentities = () => {
        setLoading(true)
        let url = ""
        if (isSuperAdmin && !isNullOrUndefined(selectedEntity)) {
            url = "/identities/getForCompany/" + selectedEntity
        } else if (!isSuperAdmin) {
            url = "/identities/getForCompany";
        }
        if ((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin) {
            HttpApi.getV2(url).then(response => {
                if (response && response.data && response.data.length > 0) {
                    const list = response.data
                    const globalList = list.filter(el => el.type === IdentityTypes.GLOBAL)[0];
                    if (!!globalList) {
                        setListIdentity(globalList)
                        const global = globalList.identities
                        setIdentities(global)
                        if (global.filter(el => el.lang === getLanguage()).length > 0) {
                            setSelectedLang(getLanguage());
                        } else {
                            setSelectedLang(global[0].lang);
                        }
                        setLangs(prevState => {
                            let langs = [...prevState];
                            global.forEach(i => {
                                if (!langs.includes(i.lang)) {
                                    langs.push(i.lang);
                                }
                            })
                            return langs;
                        })
                        setStep(steps.length);
                    }else{
                        setListIdentity({id : null,title:"General Customization", type: IdentityTypes.GLOBAL, identities: [],identity_teams:[]})
                        setIdentities([])
                        setLangs([])
                        setStep(0)
                        setSelectedLang(getLanguage())
                    }
                } else {
                    setListIdentity({id : null,title:"General Customization", type: IdentityTypes.GLOBAL, identities: [],identity_teams:[]})
                    setIdentities([])
                    setLangs([])
                    setStep(0)
                    setSelectedLang(getLanguage())
                }
            }).finally(() => {setLoading(false)})
        }

    }

    const calculateBackground = (index) => {
        let classes = "identity_step ";
        if(index === 0){
            classes += "identity_step_first ";
        }else if(index === 7){
            classes += "identity_step_last ";
        }
        if(index === step-1){
            return classes + "identity_step_selected";
        }
        if(index<7){
            if(checkAllEmpty(index)){
                return classes + "identity_step_empty";
            }else if(emptyInputs(index)){
                return classes + "identity_step_error";
            }else{
                return classes + "identity_step_valid";
            }
        }
        return classes + "identity_step_empty";

    }

    const checkValidity = () => {
        let valid=true;
        for(let i=0;i<7;i++){
            if(!checkAllEmpty(i)){
                if(emptyInputs(i)){
                    valid=false;
                }
            }
        }
        return valid;
    }

    const processTextIdentity = (text,lang) => {
        let newText = text
        let presentKeyword = []
        const listKeyword = identities.filter(el => el.lang === lang && !["values","valeurs","waarden"].includes(el.key_value)).map(el => el.key_value);
        for(let i=0;i<listKeyword.length;i++){
            if(text.includes(listKeyword[i])){
                presentKeyword.push(listKeyword[i])
            }
        }
        if(presentKeyword.length > 0 ){
            for(let i=0;i<presentKeyword.length;i++){
                const valueReplacement = identities.filter(el => el.key_value === presentKeyword[i])[0]?.text;
                if(valueReplacement){
                    newText = newText.replace(presentKeyword[i], valueReplacement)
                }

            }
        }
        return newText
    }

    const displayLang = () => {
        let string = "";
        langs.forEach((l, index) => {
            string += _.upperCase(l);
            if(index !== langs.length-1){
                string += ", ";
            }
        })
        return string;
    }

    useEffect(() => {
        fetchIdentities();
    }, [companyToEdit,selectedEntity])

    useEffect(() => {
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        if(productTour && productTour === "identity"){
            setTourStarted(true);
        }
    }, [identities]);



    return(
        <>
            <CustomLibraryTour handleStep={handleStep} stepCustomization={step}/>
            <CardDefault showButton={false} title={_.upperFirst(t("admin.identity.title"))} subtitle={_.upperFirst(t("admin.identity.description"))} tourTooltip={true} tourType={TourType.CUSTOM_LIBRARY}>
                {
                    step === 0 &&
                    <Card>
                        <Card.Body data-customLib={'selectLang'}>
                            <span className={"ebloom-normal-title contrast-purple"}>{_.upperFirst(t("admin.identity.subtitle"))}</span>
                            <p className={"ebloom-normal-text mt10"}>{_.upperFirst(t("admin.identity.description"))}</p>
                            <p className={"ebloom-text ebloom-large-text purple"}>{_.upperFirst(t("admin.identity.langHelper"))}</p>
                            {
                                getLanguages().map((lang, index) => (
                                    <div key={index}><Checkbox className={"checkbox-purple"} checked={langs.includes(lang.value)} value={lang.value} onChange={handleLang}/>{lang.name}</div>
                                ))
                            }
                            <div className={"text-center"}>
                                <Button className={"ebloom-btn-purple mt20"} disabled={langs.length === 0} onClick={() => {handleStep(1)}}>{_.upperCase(t("core.customize"))}</Button>
                            </div>
                        </Card.Body>
                    </Card>
                }
                {step > 0  &&
                    <div className={"w-full h-full"}>
                        <div style={{textAlign: "right", position: "relative", bottom: 65, right: 40}}>
                            <IconButton onClick={(event) => {
                                setAnchor(event.target)
                            }}>
                                <MoreHorizIcon className={"purple"}/>
                            </IconButton>
                            <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={() => {
                                setAnchor(null)
                            }}>
                                <MenuItem
                                    onClick={handleOpenModalLang}>{_.upperFirst(t("admin.identity.addDeleteLang"))}</MenuItem>
                            </Menu>
                        </div>
                        <div data-customLib={'steps'} className={"w-full px-5 flex justify-center"}>
                            {
                                steps.map((s, index) => (
                                    <div key={index} data-customLib={'step' + index}
                                         className={"center inline_icon pointer ebloom-text ebloom-very-small-text " + calculateBackground(index)}
                                         onClick={langs.length === 0 ? () => {
                                         } : () => {
                                             handleClickStep(index + 1)
                                         }}>{(index + 1) + ". " + _.upperFirst(t("admin.identity." + s.name))}</div>
                                ))
                            }
                        </div>
                        <div
                            className={"ebloom-text ebloom-large-text font-weight-bold contrast-purple mt20 " + (step === 7 ? "mb20" : "mb5")}>{step === 7 ? _.upperFirst(t("admin.identity.valuesHelper")) : step === steps.length ? _.upperFirst(t("admin.identity.validationHelper")) : _.upperFirst(t("admin.identity.stepHelper"))}</div>

                        {
                            step < steps.length && langs.map((lang, index) => {
                                const keySentenceStart = steps[step - 1][lang].keySentence.split("{")[0];
                                const keySentenceEnd = steps[step - 1][lang].keySentence.split("}")[1];
                                const hasValue = identities.filter(el => el.key_value === steps[step - 1][lang].keyValue).length > 0;
                                const value = hasValue ? identities.filter(el => el.key_value === steps[step - 1][lang].keyValue)[0].text : steps[step - 1][lang].keyValue;
                                return (
                                    <div className={"mt-10 mb-10"} key={index}>
                                        <Badge
                                            bg={""}
                                            className={"badge-purple inline_icon ebloom-text ebloom-small-text font-weight-normal mr-20 align-top h-30 w-50 rounded-xl pt-7"}
                                        >{_.upperCase(lang)}</Badge>
                                        {
                                            step === 7 ?
                                                <div className={"inline_icon b5"}>
                                                    <div className={"mb20"} style={{width: 400}}>
                                                        <FullContrastPurpleTextField fullWidth size="small"
                                                                                     variant="outlined"
                                                                                     placeholder={_.upperFirst(steps[step - 1][lang].keyValue)}
                                                                                     className={"very_small_textfield"}
                                                                                     value={hasValue ? value : null}
                                                                                     onChange={handleChange(hasValue, lang, steps[step - 1][lang].keyValue)}/>
                                                    </div>
                                                    {
                                                        steps[step - 1][lang].examples.map((example, index) => {
                                                            return (
                                                                <div key={index}
                                                                     className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(example, lang) + (hasValue ? " : " : "")}<span
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        cursor: "default"
                                                                    }}>{hasValue ? value : ""}</span></div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div className={"inline_icon"}>
                                                    <p className={"ebloom-text ebloom-normal-text"}>
                                                        <span>{processTextIdentity(keySentenceStart, lang)}</span>
                                                        <PurpleTextField size="small"
                                                                         placeholder={!keySentenceStart ? _.upperFirst(steps[step - 1][lang].keyValue) : steps[step - 1][lang].keyValue}
                                                                         variant="outlined"
                                                                         className={"very_small_textfield"}
                                                                         value={hasValue ? value : ""}
                                                                         onChange={handleChange(hasValue, lang, steps[step - 1][lang].keyValue)}/>
                                                        <span>{processTextIdentity(keySentenceEnd, lang)}</span>
                                                    </p>
                                                    {
                                                        steps[step - 1][lang].examples.map((example, index) => {
                                                            const start = example.split("{")[0];
                                                            const end = example.split("}")[1]
                                                            const defaultWord = example.split("{")[1].split("}")[0];
                                                            return (
                                                                <div key={index}
                                                                     className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(start, lang)}<span
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        cursor: "default"
                                                                    }}>{!start ? _.upperFirst(hasValue ? value : defaultWord) : (hasValue ? value : defaultWord)}</span>{processTextIdentity(end, lang)}
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                    {
                                                        (step === 1 || step === 6) && lang === "fr" && identities.filter(el => el.key_value === steps[step - 1][lang].keyValue).length > 0 && identities.filter(el => el.key_value === steps[step - 1][lang].keyValue)[0].text &&
                                                        <div>
                                                            <p className={"ebloom-text ebloom-normal-text mt20"}>
                                                                <span>{step === 1 ? companyPrefix.keySentenceStart : colleguesPrefix.keySentenceStart}</span>
                                                                <PurpleTextField size="small"
                                                                                 placeholder={step === 1 ? companyPrefix.keyPlaceHolder : colleguesPrefix.keyValue.replace("mes collègues", value)}
                                                                                 variant="outlined"
                                                                                 className={"very_small_textfield " + (step === 1 && "w-70")}
                                                                                 value={identities.filter(el => el.key_value === (step === 1 ? companyPrefix.keyValue : colleguesPrefix.keyValue)).length > 0 ? step === 1 ? identities.filter(el => el.key_value === companyPrefix.keyValue)[0].text.split(" ")[0] : identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text : null}
                                                                                 onChange={handleChange(identities.filter(el => el.key_value === (step === 1 ? companyPrefix.keyValue : colleguesPrefix.keyValue)).length > 0, "fr", step === 1 ? companyPrefix.keyValue : colleguesPrefix.keyValue)}/>
                                                                <span>{step === 1 ? companyPrefix.keyAfterValueName.replace("l'entreprise", value) + companyPrefix.keySentenceEnd : colleguesPrefix.keySentenceEnd}</span>
                                                            </p>
                                                            {
                                                                step === 6 && colleguesPrefix.examples.map((example, index) => {
                                                                    const start = example.split("{")[0];
                                                                    const end = example.split("}")[1]
                                                                    return (
                                                                        <div key={index}
                                                                             className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(start, lang)}<span
                                                                            style={{
                                                                                textDecoration: "underline",
                                                                                cursor: "default"
                                                                            }}>{identities.filter(el => el.key_value === colleguesPrefix.keyValue).length > 0 && identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text ? identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text : colleguesPrefix.keyValue.replace("mes collègues", value)}</span>{processTextIdentity(end, lang)}
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                            {
                                                                step === 1 && companyPrefix.examples.map((example, index) => {
                                                                    const start = example.split("{")[0];
                                                                    const end = example.split("}")[1]
                                                                    return (
                                                                        <div key={index}
                                                                             className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(start, lang)}<span
                                                                            style={{
                                                                                textDecoration: "underline",
                                                                                cursor: "default"
                                                                            }}>{identities.filter(el => el.key_value === companyPrefix.keyValue).length === 0 ? companyPrefix.keyPlaceHolder + " " + value : identities.filter(el => el.key_value === companyPrefix.keyValue).length > 0 && identities.filter(el => el.key_value === companyPrefix.keyValue)[0].text ? identities.filter(el => el.key_value === companyPrefix.keyValue)[0].text : companyPrefix.keyValue.replace("dans l'entreprise", value)}</span>{processTextIdentity(end, lang)}
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                        }

                                    </div>
                                )
                            })
                        }
                        {
                            step === steps.length &&
                            <div data-customLib={'validation'}>
                                <div className={"ml5 mt20 mb20"}>
                                    <FullContrastPurpleTextField value={selectedLang} select variant="outlined"
                                                                 size="small" className={"very_small_textfield"}
                                                                 onChange={handleChangeLang}>
                                        {
                                            langs.map((l, index) => (
                                                <MenuItem key={index}
                                                          value={l}>{_.upperCase(t("core.lang." + l))}</MenuItem>
                                            ))
                                        }
                                    </FullContrastPurpleTextField>
                                </div>
                                <CardDefault showHeader={false}>
                                    <Row>
                                        <Col md={3}>
                                            <div
                                                className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.originalTerms"))}</div>
                                        </Col>
                                        <Col md={3}>
                                            <div
                                                className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.customizedTerms"))}</div>
                                        </Col>
                                        <Col>
                                            <div
                                                className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.adaptedCBExamples"))}</div>
                                        </Col>
                                    </Row>
                                    {
                                        steps.slice(0, steps.length - 1).map((s, index) => {
                                            const hasValue = identities.filter(el => el.key_value === s[selectedLang].keyValue).length > 0;
                                            const value = hasValue && langs.length > 0 ? identities.filter(el => el.key_value === s[selectedLang].keyValue)[0].text : "";
                                            const exampleStart = index === 6 ? s[selectedLang].examples[0] + (hasValue ? " : " : "") : s[selectedLang].keySentence.split("{")[0];
                                            const exampleEnd = index === 6 ? "" : s[selectedLang].keySentence.split("}")[1];
                                            return (
                                                <Row key={index}>
                                                    <Col md={3}>
                                                        <div
                                                            className={"ebloom-normal-text contrast-purple center mt10 mb10 pointer"}
                                                            onClick={() => {
                                                                handleStep(index + 1)
                                                            }}>{s[selectedLang].keyValue}</div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div
                                                            className={"ebloom-normal-text purple center mt10 mb10"}>{value}</div>
                                                    </Col>
                                                    <Col>
                                                        <div
                                                            className={"ebloom-normal-text contrast-purple center mt10 mb10"}>{exampleStart}<span
                                                            style={{
                                                                textDecoration: "underline",
                                                                cursor: "default"
                                                            }}>{!exampleStart ? _.upperFirst((hasValue && value !== "") || index === 6 ? value : s[selectedLang].keyValue) : (hasValue && value !== "") || index === 6 ? value : s[selectedLang].keyValue}</span>{exampleEnd}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </CardDefault>
                            </div>
                        }
                        {
                            (step > 1 && langs.length > 0) &&
                            <KeyboardArrowLeftIcon
                                style={{color: Colors.EBLOOM_PURPLE, fontSize: "2em", cursor: "pointer"}}
                                onClick={() => {
                                    handleStep(step - 1)
                                }}/>
                        }
                        {
                            step === steps.length ?
                                <div className={"right"}>
                                    {
                                        checkValidity() ?
                                            <Button data-customLib={'saveBtn'} className={"ebloom-btn-purple"}
                                                    size={"sm"} onClick={handleOpenModal} disabled={loading}>{loading ?
                                                <LoadingView size={16}/> : _.upperFirst(t("core.save"))}</Button>
                                            :
                                            <Button className={"ebloom-btn-purple"} size={"sm"}
                                                    onClick={handleOpenModal} disabled>
                                                <EbloomTooltip text={_.upperFirst(t("admin.identity.errorText"))}
                                                               placement={"left"}>
                                                    <div>{_.upperFirst(t("core.save"))}</div>
                                                </EbloomTooltip>
                                            </Button>

                                    }
                                </div>
                                :
                                <div className={"right"}>
                                    <Button size="sm" className={"ebloom-btn-purple ml5"} onClick={() => {
                                        handleStep(step + 1)
                                    }}>{_.upperCase(t("core.next"))}</Button>
                                </div>
                        }
                    </div>
                }


            </CardDefault>
            {
                step === 8 ?
                    <ConfirmModal handleClose={handleCloseModal} show={show}
                                  title={_.upperFirst(t("admin.identity.confirmModalTitle"))}
                                  buttonLeftText={_.capitalize(t("core.cancel"))}
                                  buttonRightText={_.capitalize(t("core.confirm"))} handleChange={handleSave}
                                  buttonDisabled={loading}>
                        <p>{_.upperFirst(t("admin.identity.confirmModalText", {langs: displayLang()}))}</p>
                    </ConfirmModal>
                    :
                    <ConfirmModal handleClose={handleCloseModalSkip} show={showModalSkip}
                                  title={_.upperFirst(t("admin.identity.skipModalTitle", {step: _.upperFirst(t("admin.identity." + (step > 0 ? steps[step - 1].name : "")))}))}
                                  buttonLeftText={_.upperFirst(t("core.cancel"))}
                                  buttonRightText={_.upperFirst(t("core.confirm"))} handleChange={handleSkip}>
                        <p>{_.upperFirst(t("admin.identity.skipModalText", {step:_.upperFirst(t("admin.identity." + (step > 0 ? steps[step-1].name : ""))), key_value:(step > 0 ? steps[step-1][getLanguage()].keyValue : "")}))}</p>
                    </ConfirmModal>
            }

            <ConfirmModal handleClose={handleCloseModalLang} show={showModalLang} title={_.upperFirst(t("admin.identity.addDeleteModalTitle"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={handleUpdateLang}>
                <p className={"ebloom-text ebloom-large-text purple"}>{_.upperFirst(t("admin.identity.addDeleteModalText"))}</p>
                {
                    getLanguages().map((lang, index) => (
                        <div><Checkbox className={"checkbox-purple"} checked={langs.includes(lang.value)} value={lang.value} onChange={handleLang}/>{lang.name}</div>
                    ))
                }
            </ConfirmModal>
        </>
    )
};

IdentityStarter.propTypes = {

};

export default IdentityStarter;
