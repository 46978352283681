import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types"
import {ReactComponent as Speedometer} from "../../symbols/general/speedometer.svg";
import {ReactComponent as ArrowGreen} from "../../symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../symbols/general/ArrowNeutral.svg";

import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {Row} from "react-bootstrap";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import moment from "moment";
import {Colors} from "../../../../Utils/Colors";

const EbloomSpeedometerV2 = (props) => {

    const {score, showScore, showLegend, width, type, showDifference, difference,colors} = props;
    const ref = useRef();

    const t = useTranslation();

    const rotateX = (score) => {
        let degre = 0;
        if(score && score>=50){
            let temp = ((score-50)/50)*180
            degre = 8 + temp
        }
        ref.current.getElementsByClassName("speedometer-needle")[0].setAttribute("transform", "rotate("+degre+", 137, 135)");
    }

    const displayScore = (score) => {
        if(score !== null && score >= 0){
            return Math.round(score);
        }else if(score === -1){
            return _.upperFirst(t("manager.dashboard.anonymityRestrictionSmall"));
        }else{
            return _.upperFirst(t("webloom.NA"));
        }
    }

    const displayDifference = (difference,score) =>{
        if(difference !== null && difference.difference !== null ){
            const diff = difference.difference
            if(diff === score ){
                return null
            }else if(Math.round(diff) > 0){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-start items-center"}>
                        <div className={"scale-75"}>
                            <ArrowGreen/>
                        </div>
                        <p className={"text-strong-green text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>
            }else if (Math.round(diff) < 0 ){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-start items-center"}>
                        <div className={"scale-75"}>
                            <ArrowRed/>
                        </div>
                        <p className={"text-strong-red text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>
            }else if (Math.round(diff) === 0){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-start items-center"}>
                        <div className={"scale-75"}>
                            <ArrowNeutral/>
                        </div>
                        <p className={"text-grey text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>
            }
            return null
        }

        return null
    }

    const generateStyle = (width) => {
        if(!width){
            ref.current.setAttribute("width", "280");
            ref.current.setAttribute("height", "150");
        }else{
            const height = width*0.53;
            ref.current.setAttribute("width", width);
            ref.current.setAttribute("height", height);
        }
    }

    const getClassNameAccordingToScore = (score) => {
        if(type === "yesNo"){
            return "yes-no hide-legend";
        }
        if(score>=50 && score<55){
            return "score50";
        }else if(score>=55 && score<60){
            return "score55";
        }else if(score>=60 && score<65){
            return "score60";
        }else if(score>=65 && score<70){
            return "score65";
        }else if(score>=70 && score<75){
            return "score70";
        }else if(score>=75 && score<80){
            return "score75";
        }else if(score>=80 && score<85){
            return "score80";
        }else if(score>=85 && score<90){
            return "score85";
        }else if(score>=90 && score<95){
            return "score90";
        }else if(score>=95){
            return "score95";
        }else if(score === null || score === undefined || score < 0){
            return "scoreNeg";
        }else{
            return "score45";
        }
    }

    useEffect(() => {
        rotateX(score)
        generateStyle(width);
    }, [score, width])

    return(
        <div style={{width:width}} className={"m-auto"}>
            <Speedometer ref={ref} style={{width:width , fill:colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={getClassNameAccordingToScore(score) + (showLegend ? "" : " hide-legend")} />
            <Row className={"grid grid-cols-6 text-3xl ebloom-text font-bold text-contrast-purple text-center relative "}>
                {
                    (showScore && type !== "yesNo") &&
                    <div style={{color : colors? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={"col-start-3 col-span-2 px-0 "}>
                        <p className={""}>{displayScore(score)}</p>
                    </div>
                }
                {
                    (showDifference && type !== "yesNo" && !(score === null || score === undefined || score < 0)) &&
                    <div className={"col-start-5 col-span-4 flex justify-start items-center px-0"}>
                        {displayDifference(difference,score)}
                    </div>
                }
            </Row>
        </div>
    )
}

EbloomSpeedometerV2.propTypes = {
    score:PropTypes.any.isRequired,
    showScore:PropTypes.bool,
    showLegend:PropTypes.bool,
    width:PropTypes.number,
    type:PropTypes.string,
    showDifference: PropTypes.bool,
    difference : PropTypes.object,
    colors : PropTypes.object
}

EbloomSpeedometerV2.defaultProps = {
    showScore:true,
    showLegend:true,
    width:280,
    type:"dashboard",
    showDifference : false,
    difference: null,
    colors : null

}
export default EbloomSpeedometerV2