import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import {manageTeamsSteps} from "./manageTeamsSteps";
import PropTypes from "prop-types";

const ManageTeamsTour = (props) => {

    const {
        updateStep,
        stepTour,
        stepManageTeams, setStepManageTeams,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        validateOutcome,
        continuous, setContinuous,
        icon,
        updateHandler
    } = useContext(ProductTourContext)

    const{setSideForm} = props

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if(ACTIONS.NEXT === action){
                if(index === 2) {
                    updateStep(TourType.MANAGE_TEAMS, next)
                }else if (index === 3) {
                    setContinuous(false)
                    updateStep(TourType.MANAGE_TEAMS, 3)

                }else if (index === 3) {
                    setContinuous(false)
                    updateStep(TourType.MANAGE_TEAMS, 3)
                }else{
                    updateStep(TourType.MANAGE_TEAMS,next)
                }
            }else if (ACTIONS.PREV === action){
                if(index===3){
                    setContinuous(true)
                    setSideForm(false)
                    updateStep(TourType.MANAGE_TEAMS,next)
                }else if (index === 4){
                    setContinuous(true)
                    updateStep(TourType.MANAGE_TEAMS,2)
                }else{
                    updateStep(TourType.MANAGE_TEAMS,next)
                }
            }else if (ACTIONS.CLOSE === action){
                if(index === 3){
                    setContinuous(false)
                }

            }
        }
    }

    const handleTour = () => {
        setContinuous(true)

        if(stepManageTeams === 3 || (stepManageTeams >= 4 && document.querySelectorAll("[data-tour=row]").length<1)){
            updateStep(TourType.MANAGE_TEAMS,2)
        }
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)
        const tour = {
            id : stepTour[TourType.MANAGE_TEAMS].id,
            current_step : stepTour[TourType.MANAGE_TEAMS].step >= manageTeamsSteps.length - 1 ? 0 : stepTour[TourType.MANAGE_TEAMS].step,
            type: TourType.MANAGE_TEAMS,
            finished : stepTour[TourType.MANAGE_TEAMS].outcome
        }
        if(stepManageTeams >= manageTeamsSteps.length - 1){
            validateOutcome(TourType.MANAGE_TEAMS)
            setStepManageTeams(0)
            icon.current.classList.remove('ebloom-ring')
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{
            })
        }
        iconRinging()
    }

    const iconRinging = () => {
        if(icon.current && stepTour[TourType.MANAGE_TEAMS]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.MANAGE_TEAMS].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepManageTeams,stepTour,tourActivated]);


    useEffect(() => {
        fetchData();
        if(tourActivated && icon && icon.current){
            iconRinging()
        }
        updateHandler(TourType.MANAGE_TEAMS,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);
    return(
        <>
            <ReactJoyride
                steps={manageTeamsSteps}
                continuous={continuous}
                run={tourStarted}
                stepIndex={stepManageTeams}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />
        </>
    )
}

ManageTeamsTour.propTypes = {
    setSideForm : PropTypes.func.isRequired
}

export default ManageTeamsTour