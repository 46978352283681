
export const weBloomSteps = [
    {
        title: "admin.productTour.launchWeBloom.step1.title",
        content: "admin.productTour.launchWeBloom.step1.content",
        target: '[data-webloom="create"]',
        placement: 'right',
        disableBeacon : true

    },
    {
        title: "admin.productTour.launchWeBloom.step2.title",
        content: "admin.productTour.launchWeBloom.step2.content",
        target: '[data-webloom="notif"]',
        placement: 'left',
        disableBeacon : true

    },
    {
        title: "admin.productTour.launchWeBloom.step3.title",
        content: "admin.productTour.launchWeBloom.step3.content",
        target: '[data-webloom="seeWeBloom"]',
        placement: 'left',
        disableBeacon : true

    },
]