import React from "react";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import CompanyEditor from "./CompanyEditor";



const CompanyEditorPage = (props) =>{

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <CompanyEditor companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
}

export default CompanyEditorPage;