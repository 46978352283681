import React from "react";
import DisplayAdminView from "../../../../../Admin/Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import WeBloomAnswerPoll from "./WeBloomAnswerPoll";

const WeBloomAnswerPollPage = (props) => {

    return(
        <DisplayAdminView pageName={"core.collaborative.poll"} menu={"noMenu"} selectedIndex={10} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomAnswerPoll {...props}/>
                    </Container>

                )
            }
        </DisplayAdminView>
    )
}

export default WeBloomAnswerPollPage;