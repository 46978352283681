import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useUserData} from "../../../../hooks/userHook";
import {FormControl} from "@mui/material";
import {Colors} from "../../../../Utils/Colors";
import Select from "@mui/material/Select";
import {alpha, createTheme} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {FullContrastPurpleTextField} from "../../../../Utils/Global";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";

const EntitiesSwitcher = (props) => {
    const {handleSwitch,alternative,allOption,entity,isWeBloom,constrastPurple, isDashboardGroup,colors} = props

    const user = useUserData()
    const t = useTranslation()

    const [entities, setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);

    const handleChangeUnique = (event) => {
        const value = event.target.value
        handleSwitch(value)
    }

    const handleChangeMultiple = (event) => {
        const idGroup = isDashboardGroup ? 0 : user.companyGroup.id
        const value = event.target.value
        const companySelected = value.filter(el => el.id === idGroup).length > 0 && selectedEntities.filter(el => el.id === idGroup).length < 1
        if(value.length > 0){
            const indexCompanyRemove = value.findIndex(el => el.id === idGroup)
            if(companySelected){
                setSelectedEntities([entities[0]])
                setShowMenu(false)
                handleSwitch([entities[0]])
            }else if(indexCompanyRemove !== -1){
                value.splice(indexCompanyRemove,1)
                setSelectedEntities(value)
                handleSwitch(value)
            }else{
                setSelectedEntities(value)
                handleSwitch(value)
            }
        }else if((value.length === 1 && value[0].id === idGroup) || (value.length === 0 && selectedEntities.length === 1 && selectedEntities[0].id === idGroup)){
            setShowMenu(false)
        }
    }

    const fetchEntities = () => {
        let url = "/companies/findAllEntitiesFromGroup/"+ user.companyGroup.id;

        HttpApi.getV2(url).then(response => {
            if(response && response.data) {
                let listTeam = response.data
                if(!allOption){
                    const company = listTeam.filter(el => el.id === user.companyId)[0]
                    setEntities(listTeam);
                    handleSwitch(company.id);
                }else if(alternative){
                    const company = listTeam.filter(el => el.id === user.companyId)[0]
                    let list = []
                    if(user.roles.includes("global_admin")){
                        list = listTeam.filter(el => el.id === user.companyId)
                    }
                    list.splice(0,0, {id : user.companyGroup.id, name : user.companyGroup.name});
                    setEntities(list);
                    if(entity){
                        handleSwitch(entity)
                    }else{
                        if(user.roles.includes("global_admin")){
                            handleSwitch(company.id);
                        }else{
                            handleSwitch(list[0].id);
                        }
                    }


                }else{
                    listTeam.splice(0,0, {id: isDashboardGroup ? 0 : user.companyGroup.id, name: _.upperFirst(t("core.allEntities"))});
                    setEntities(listTeam);
                    handleSwitch([listTeam[0]]);
                    setSelectedEntities([listTeam[0]])
                }
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const renderValue= (selected) => {
        if(!isMultiple){
            return <div>
                <p>
                    {entities.filter(el => el.id === selected)[0]?.name}
                </p>
            </div>

        }else{
            if(selected.length === 1){
                return <div><p
                    className={"text-ellipsis overflow-hidden"}>{selected[0].name}</p>
                </div>
            }else{
                return <div>
                    <p className={"text-ellipsis overflow-hidden"}>{`${selected.length}  entities`}</p>
                </div>
            }
        }
    }

    const filterEntities = (entities)  => {
        if(alternative){
            return entities.filter(el => el.id === user.companyGroup.id || (user.roles.includes("global_admin") && el.id === user.companyId))
        }else{
            return entities
        }
    }

    useEffect(() => {
        fetchEntities()
        setIsMultiple((allOption && !alternative && !isWeBloom))
    }, [user]);





    return(
        constrastPurple ?
            <div className={!user.roles.includes("global_admin") && alternative ? "hidden" : ""}>
                <FullContrastPurpleTextField
                    select={true}
                    size={"small"}
                    fullWidth={true}
                    className={"text-ellipsis overflow-hidden flex-nowrap"}
                    SelectProps = {{
                        multiple : isMultiple,
                        value : (alternative || !allOption) ? entity : selectedEntities,
                        name : "selectTeams",
                        onChange : (alternative || !allOption) ? handleChangeUnique : handleChangeMultiple ,
                    }}
                >
                    {
                        filterEntities(entities).map((option, index) => (
                            <MenuItem key={index} value={!isMultiple ? option.id : option}>{option.name}</MenuItem>

                        ))
                    }
                </FullContrastPurpleTextField>
            </div>

            :
            <div className={!user.roles.includes("global_admin") && alternative ? "hidden" : "w-auto max-w-170"}>
                <FormControl sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    "&& .MuiSelect-icon": {
                        color: colors ? colors.secondary : Colors.EBLOOM_PURPLE
                    }
                }} fullWidth={true} className={"text-ellipsis w-auto max-w-170 min-w-140 overflow-hidden flex-nowrap"}
                             size={"small"}>
                    <Select
                        sx={{
                            color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
                            borderColor : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
                        }}
                        className={" h-35 mr-5 bg-white border  text-ellipsis overflow-hidden flex-nowrap"}
                        multiple={isMultiple}
                        value={alternative || !allOption ? entity : selectedEntities}
                        name={"selectTeams"}
                        onChange={(alternative || !allOption) ? handleChangeUnique : handleChangeMultiple}
                        onClose={() => setShowMenu(false)}
                        onOpen={() => setShowMenu(true)}
                        open={showMenu}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: alpha(colors ? colors.secondary : Colors.EBLOOM_PURPLE, 0.35)
                                },
                            }
                        }}
                        renderValue={renderValue}
                    >
                        {
                            filterEntities(entities).map((option, index) => (
                                <MenuItem key={index} value={!isMultiple ? option.id : option}>{option.name}</MenuItem>

                            ))
                        }
                    </Select>
                </FormControl>
            </div>
    )
};

EntitiesSwitcher.propTypes = {
    alternative: PropTypes.bool,
    allOption: PropTypes.bool,
    isWeBloom: PropTypes.bool,
    constrastPurple: PropTypes.bool,
    isDashboardGroup: PropTypes.bool,
    entity: PropTypes.number,
    handleSwitch : PropTypes.func.isRequired,
    colors : PropTypes.object
};

EntitiesSwitcher.defaultProps = {
    alternative: false,
    constrastPurple: false,
    isWeBloom: false,
    allOption: true,
    entity: null,
    isDashboardGroup : false,
    colors : null
}
export default EntitiesSwitcher;
