import React, {useState} from "react";
import PropTypes from "prop-types";
import ResultFrame from "../Modules/ResultFrame";
import {toPng} from "html-to-image";
import {copyImageToClipboard} from "copy-image-clipboard";
import {notifyError, notifySuccess} from "../../../../../../../Utils/Notification";
import download from "downloadjs";
import BubbleChatList from "./BubbleChatList";
import _ from "lodash";
import logo_ebloom from "../../../../../../Core/symbols/logo/ebloom.svg";
import {useTranslation} from "react-multi-lang/lib";

const OpenQuestion = (props) => {

    const [loadingDownload, setLoadingDownload] = useState(false);

    const {question, handleMarkAsRead, anonymityRestriction, noData,setShowModalProspect, isProspect} = props;

    const t = useTranslation();

    const filter = (node) => {
        const exclusionClasses = ['report-hide'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }

    const handleCopy = () => {
        let node = document.getElementById("result-frame-" + question.id);
        let img = document.getElementById("img-" + question.id);
        img.style.visibility = "visible";
        toPng(node, {style:{backgroundColor:"white"}, filter:filter}).then((dataUrl) => {
            img.style.visibility = "hidden";
            copyImageToClipboard(dataUrl).then(() => {
                notifySuccess(_.upperFirst(t("webloom.notification.imageCopied")));
            }).catch(error => {
                notifyError(error);
            })
        })
    }

    const handleDownload = () => {
        if(!loadingDownload){
            setLoadingDownload(true);
            let node = document.getElementById("result-frame-" + question.id);
            let img = document.getElementById("img-" + question.id);
            img.style.visibility = "visible";
            toPng(node, {style:{backgroundColor:"white"}, filter:filter}).then((dataUrl) => {
                img.style.visibility = "hidden";
                download(dataUrl, 'webloom-'+ question.title +'.png');
            }).finally(() => {
                setLoadingDownload(false);
            })
        }
    }

    const handleMarkRead = (id_answer) => {
        let q = _.cloneDeep(question);
        let answer = q.answers.find(el => el.id === id_answer);
        answer.isRead = 1;
        handleMarkAsRead(q);
    }

    return(
        <ResultFrame question={question} handleCopy={handleCopy} handleDownload={handleDownload} anonymityRestriction={anonymityRestriction} isIndividualView={props.isIndividualView} setShowModalProspect={setShowModalProspect} isProspect={isProspect}>
            <div className={"text-contrast-purple"}>
                {
                    anonymityRestriction || noData ?
                        <div className={"text-contrast-purple text-center"}>{_.upperFirst(t(noData ? "core.collaborative.noAnswer": "manager.dashboard.anonymousRestriction"))}</div>
                        :
                        <BubbleChatList answers={question.answers} handleMarkAsRead={handleMarkRead}/>

                }
            </div>
            <img id={"img-" + question.id} alt={"ebloom-logo"} src={logo_ebloom} width={50} style={{visibility:"hidden"}}/>
        </ResultFrame>
    )
}

OpenQuestion.propTypes = {
    question:PropTypes.object.isRequired
}

export default OpenQuestion;