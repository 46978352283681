import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Playlist from "../../../../Admin/Pages/Questions/Playlist/Playlist";



const PlaylistView = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <Playlist companyToEdit={companyToEdit} {...props}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default PlaylistView;


