import {ModelTypes} from "./Types";

const Faq = {
    crud:{
        create:"/faq/create",
        update:"/faq/update",
        delete:"/faq/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"ID Faq",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    question:{
        id:"question",
        type:ModelTypes.TEXT,
        label:"backoffice.faq.question",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    answer:{
        id:"answer",
        type:ModelTypes.TEXT,
        label:"backoffice.faq.answer",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    lang:{
        id:"lang",
        type:ModelTypes.TEXT,
        label:"backoffice.faq.lang",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    id_question:{
        id:"id_question",
        type:ModelTypes.NUMBER,
        label:"id_question",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    type:{
        id:"type",
        type:ModelTypes.TEXT,
        label:"core.type",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    checked:{
        id:'checked',
        type:ModelTypes.NUMBER,
        label: 'Checked',
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    priority:{
        id:'priority',
        type:ModelTypes.BOOLEAN,
        label: 'Priority',
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    tags :{
        id:'tags',
        type:ModelTypes.ARRAY,
        label: 'Tags',
        table:{
            show: false
        },
        list:{

        },
        form:{

        }
    },


    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"uptated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Faq;