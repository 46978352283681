import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {useNavigate, useParams} from "react-router-dom";
import HttpApi from "../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../Utils/Notification";
import LoadingView from "../../../Core/Layouts/LoadingView";
import TableViewV2 from "../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import UserModel from "../../../../models/User";
import ListView from "../../../Core/Modules/Views-Tables-Lists/ListView";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import EbloomJumbotron from "../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";

const Filter = (props) => {
    //const {} = props
    const t = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [filter, setFilter] = useState(null);
    const [tablePage, setTablePage] = useState(0);
    const [userNotInFilter, setUserNotInFilter] = useState([]);
    const [sideForm, setSideForm] = useState(false);
    const [users, setUsers] = useState([]);
    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleAdd = (item) => event => {
        event.preventDefault();
        const data = {
            id_filter:filter.id,
            id_user:item.id
        }
        HttpApi.postV2("/filters/addUser", data).then(() => {
            setUserNotInFilter(userNotInFilter.filter(el => el.id !== item.id));
            setUsers(prevState => {
                let users = [...prevState];
                users.push(item);
                return users;
            })
            fetchFilter(params.id)
            notifySuccess("User successfully added",1000);
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleRemoveUser = (user)=> event => {
        event.preventDefault();
        const data = {
            id_filter:filter.id,
            id_user:user.id
        }
        HttpApi.postV2("/filters/removeUser", data).then(() => {
            setUserNotInFilter(prevState => {
                let list = prevState
                list.push(user);
                return list
            })
            setUsers(users.filter(el => el.id!== user.id))
            setFilter(prevState => {
                let filter = {...prevState};
                filter.users = filter.users.filter(el => el.id!== user.id);
                return filter;
            })
            fetchFilter(params.id)

            notifySuccess("User successfully removed",1000);
        }).catch(error => {
            notifyError(error);
        })
    }

    const toggleDrawer = (open, toEdit) => event => {
        event.preventDefault();
        setSideForm(open);
    };

    const fetchUserNotInFilter = () => {
        HttpApi.getV2("/filters/getEmployeesNotInFilter/" + filter.id_company_filter).then(response => {
            if(response && response.data){
                setUserNotInFilter(response.data)
            }
        }).catch(error => {
            notifyError(error)
        })
    }

    const fetchFilter = (id) => {
      HttpApi.getV2("/filters/getFilterInfosWithUsers/" + id).then(response => {
          if(response && response.data){
            setFilter(response.data);
            setUsers(response.data.users);
          }
      }).catch(error => {
          notifyError(error)
      })
    }

    useEffect(() => {
        if(filter){
            fetchUserNotInFilter()
        }
    }, [filter]);

    useEffect(() => {
        if(params.id){
            const id = params.id;
            fetchFilter(id)

        }
    }, [params]);



    const EmployeeModel = _.cloneDeep(UserModel);
    EmployeeModel.lang.table.show = false;
    EmployeeModel.company_name.table.show = false;
    EmployeeModel.created_at.table.show = false;
    EmployeeModel.roles.table.show = false;

    return (
        !filter ?
            <LoadingView/>
            :
        <div>
            <CardDefault showLeftButton={true}  leftButtonVariant={'secondary'} leftButtonText={_.upperFirst(t('core.back'))} handleLeftButton={() => navigate("/admin/filters")} title={filter.name} titlePosition={"center"} buttonText={"core.add"} handleRightButton={toggleDrawer(true)}>
                {
                    filter.users.length === 0 ?
                    <EbloomJumbotron title={_.upperFirst(t("core.filters"))}
                                     text={"Add your first users into this filter!"}
                                     buttonText={_.upperFirst(t("core.add"))} handleAction={toggleDrawer(true)}/>
                    :
                    <TableViewV2 items={users} model={EmployeeModel} showActions={!props.companyToEdit} showEdit={false}
                                 showDelete={false} showDetail={true} handleView={handleRemoveUser} handleObject
                                 typeDetail={"remove"} tablePage={tablePage} handleTablePage={handleTablePage}/>
                }
            </CardDefault>
            <SideForm formTitle={_.capitalize(t("core.add"))} sideFormOpen={sideForm} toggleDrawer={toggleDrawer} showButtons={false}>
                <ListView model={UserModel} items={userNotInFilter} handleAction={handleAdd}/>
            </SideForm>
        </div>
    );
};

Filter.propTypes = {

};

export default Filter;
