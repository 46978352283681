import React, {useContext, useEffect, useMemo, useState} from "react";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import _ from "lodash";
import TextFieldCustom from "../../../Core/Modules/Input/TextFieldCustom";
import UserModel from "../../../../models/User";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {Button, Col, Row} from "react-bootstrap";
import LoadingView from "../../../Core/Layouts/LoadingView";
import HttpApi from "../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../Utils/Notification";
import {useTranslation} from "react-multi-lang/lib";
import {getLanguage} from "react-multi-lang";
import {getLanguages, getTranslations} from "../../../../lang/translations";
import {FullContrastPurpleTextField, isEmail, TourType} from "../../../../Utils/Global";
import FreeTrialModal from "../../../Core/Modules/Modal/FreeTrialModal";
import {useUserData} from "../../../../hooks/userHook";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";

const InvitePreview = (props) => {

    const lang = getLanguage();

    const [mailLang, setMailLang] = useState(lang);
    const [showModal, setShowModal] = useState(false);

    const user = useUserData();

    const mailtext2 = (lang) => {
        return getTranslations(lang, "admin.mailInviteDefault", {company:user.company});
    }


    const [loadingInvite, setLoadingInvite] = useState(false);

    const [editText, setEditText] = useState(false);
    const [mailText, setMailText] = useState([{id:null, lang:"fr", text:mailtext2("fr")}, {id:null, lang:"en", text:mailtext2("en")}, {id:null, lang:"nl", text:mailtext2("nl")}]);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const {updateStep,setTourStarted,validateOutcome,tourActivated} = useContext(ProductTourContext);

    const {toInviteConfirm, handleSeePreviewAndReload, handleSeePreview} = props;

    const name = user.name || _.upperFirst(user.email.split("@")[0]) + " (" + user.email + ")";

    const mailtext1 = getTranslations(mailLang, "admin.mailPreview", {name:name});


    const t = useTranslation();

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    }

    const handleOpenConfirmModal = () => {
        setShowModal(true);
    }

    const handleCloseConfirmModal = () => {
        setShowModal(false);
    }

    const handleChangeMailLang = (event) => {
        setMailLang(event.target.value);
    }

    const handleEditText = () => {
        setEditText(!editText);
    }

    const handleChangeText = (event) => {
        const value = event.target.value;
        const index = mailText.indexOf(mailText.filter(el => el.lang === mailLang)[0]);
        setMailText(prevState => {
            let mailText = [...prevState];
            mailText[index].text = value;
            return mailText;
        })
    }

    const setDefaultText = () => {
        const index = mailText.indexOf(mailText.filter(el => el.lang === mailLang)[0]);
        setMailText(prevState => {
            let mailText = [...prevState];
            mailText[index].text = mailtext2(mailLang);
            return mailText;
        })
    }

    const cancelText = () => {
        getOnboardingText();
        setEditText(false);
    }

    const handleSendInvite = () => {
        if(!loadingInvite){
            setLoadingInvite(true);
            const teams = toInviteConfirm.filter(el => el.team )
            if(tourActivated){
                setTourStarted(false)
                updateStep(TourType.INVITE,0)
                if(teams.length > 0){
                    validateOutcome(TourType.MANAGE_TEAMS)
                    updateStep(TourType.MANAGE_TEAMS,0)
                }
            }
            HttpApi.postV2("/users/createInvite", {users:toInviteConfirm.filter(el => isEmail(el.email))}).then((response) => {
                UserModel.initializeOptions();
                if(response && response.data === false){//for free trial
                    setShowAlertModal(true);
                }else{
                    if(tourActivated){
                        validateOutcome(TourType.INVITE)
                    }
                    handleSeePreviewAndReload();
                    notifySuccess(_.upperFirst(t("admin.invite.success.userInvited")));

                }
            }).catch(error => {
                notifyError(error);
            }).finally(() => setLoadingInvite(false));
        }
    }

    const getOnboardingText = () => {
        HttpApi.getV2("/companies/getOnboarding").then(response => {
            if(response && response.data){
                if(response.data.length < getLanguages().length){
                    getLanguages().forEach(lang => {
                        if(!response.data.some(el => el.lang === lang.value)){
                            response.data.push({id:null, lang:lang.value, text:mailtext2(lang.value)});
                        }
                    })
                    setMailText(response.data);
                }else{
                    setMailText(response.data);
                }

            }else{
                setMailText([{id:null, lang:"fr", text:mailtext2("fr")}, {id:null, lang:"en", text:mailtext2("en")}, {id:null, lang:"nl", text:mailtext2("nl")}]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const saveMailText = () => {
        let data = mailText.filter(el => el.lang === mailLang)[0];
        const url = data.id ? "/companies/updateOnboarding" : "/companies/createOnboarding";
        const HttpApiCall = data.id ? HttpApi.put : HttpApi.postV2;
        HttpApiCall(url, data).then(() => {
            setEditText(false);
            getOnboardingText();
        }).catch(error => {
            notifyError(error);
        })
    }

    const checkIfCustomText = (lang) => {
        const txt = mailText.filter(el => el.lang === lang.value)[0] ? mailText.filter(el => el.lang === lang.value)[0].text : "";
        return mailtext2(lang.value) !== txt;
    }

    const getLangValue = (lang) => {
        const txt2 = checkIfCustomText(lang) ? _.upperFirst(t("core.customizedText")) : _.upperFirst(t("core.defaultText"));
        return lang.name + " (" + txt2 + ")"
    }

    const checkIfAllSendingLangCustomOrDefault = useMemo(
        () => {
            let count = 0;
            let nbLangs = 0;
            let langs = getLanguages();
            for(let i=0; i<langs.length;i++){
                if(toInviteConfirm.filter(el => el.lang === langs[i].value).length > 0){
                    nbLangs++;
                    if(checkIfCustomText(langs[i])){
                        count++;
                    }
                }
            }
            return (nbLangs-count === 0 || count === 0);
        },[toInviteConfirm, mailText]
    )

    useEffect(() => {
        getOnboardingText();
    }, [])

    return(
        <div>
            <CardDefault title={_.capitalize(t("core.inviteUsers"))} showButton={false} showLeftButton leftButtonVariant="secondary" leftButtonText={_.upperFirst(t("core.back"))} handleLeftButton={handleSeePreview}>
                <p>{_.upperFirst(t("admin.inviteUserPreview"))}</p>
                <p className={"text-line-break"}>{_.upperFirst(t("admin.inviteUserPreview2"))}</p>
                <CardDefault showHeader={false}>
                    <Row>
                        <Col md={3}>
                            <div data-invite={'editLang'}>
                                <TextFieldCustom  model={UserModel.lang} value={mailLang} handleChange={handleChangeMailLang} size={"small"}>
                                    {
                                        getLanguages().map(lang => (
                                            <MenuItem key={lang.value} value={lang.value}>{getLangValue(lang)}</MenuItem>
                                        ))
                                    }
                                </TextFieldCustom>
                            </div>

                        </Col>
                        <Col className={"mt-10 max-h-80 overflow-y-auto"}>
                            {
                                toInviteConfirm && toInviteConfirm.filter(el => isEmail(el.email)).map((user, index) => (
                                    <span key={index} className={"text-contrast-purple"}>{(user.email || user) + "; "}</span>
                                ))
                            }
                        </Col>
                        <Col md={2} className={"right"}>
                            {
                                editText &&
                                <Button className={"ebloom-link-purple"} variant={"link"} size="sm" onClick={setDefaultText}>{_.upperFirst(t("core.defaultText"))}</Button>

                            }
                            {
                                editText &&
                                <Button className={"ebloom-link-purple"} variant={"link"} size="sm" onClick={cancelText}>{_.upperFirst(t("core.cancel"))}</Button>

                            }
                            <Button data-invite={'editText'} className={"ebloom-link-purple"} variant={"link"} size="sm" onClick={editText ? saveMailText : handleEditText}>{editText ? _.upperFirst(t("core.saveParam", {param:t("core.text")})) :_.upperFirst(t("core.editParam", {param:t("core.text")}))}</Button>
                        </Col>
                    </Row>

                    <p>{mailtext1}</p>
                    {
                        editText ?
                            <div className={"mb10"}>
                                <FullContrastPurpleTextField value={mailText.filter(el => el.lang === mailLang)[0].text} variant={"outlined"} multiline fullWidth rows={10} onChange={handleChangeText} />
                            </div>
                            :
                            <p className={"text-line-break"} style={{fontStyle:"italic"}}>{mailText.filter(el => el.lang === mailLang)[0] ? mailText.filter(el => el.lang === mailLang)[0].text : ""}</p>
                    }

                </CardDefault>
                <p>{_.upperFirst(t("admin.inviteUserPreview3"))}</p>
                <div className={"center"}><Button data-invite={'finalInvite'} onClick={handleOpenConfirmModal} className={"ebloom-btn-purple"} disabled={loadingInvite || editText}>{loadingInvite ? <LoadingView size={16}/> :  _.upperFirst(t("admin.inviteNow"))}</Button></div>
            </CardDefault>
            <FreeTrialModal title={_.upperFirst(t("free.invitationLimitTitle"))} show={showAlertModal} handleClose={handleCloseAlertModal} text={_.upperFirst(t("free.invitationLimitText"))}/>
            <ConfirmModal handleClose={handleCloseConfirmModal} show={showModal} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("core.inviteUsers"))} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.invite"))} handleChange={handleSendInvite}>
                <p className={"mb-5"}>{_.upperFirst(t("admin.invite.modalPreviewText"))}</p>
                {
                    getLanguages().map((lang, index) => (
                        toInviteConfirm && toInviteConfirm.filter(el => el.lang === lang.value && isEmail(el.email)).length > 0 &&
                            <li key={index}>{_.upperFirst(t("admin.invite.modalPreviewInvitationIn", {number:toInviteConfirm.filter(el => el.lang === lang.value && isEmail(el.email)).length, lang:_.lowerFirst(getLangValue(lang))}))}</li>
                    ))
                }
                {
                    !checkIfAllSendingLangCustomOrDefault &&
                    <p className={"text-strong-red mt-5"}>{_.upperFirst(t("admin.invite.modalPreviewAlert"))}</p>
                }
            </ConfirmModal>

        </div>
    )
}

export default InvitePreview;