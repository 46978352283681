import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import TagEditor from "./TagEditor";


const TagEditorPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <TagEditor companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default TagEditorPage;