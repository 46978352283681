import {Button, Card} from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
import React from "react";

const SupportCard = (props) => {
    const {type} = props
    const t = useTranslation()

    const handleCard = () => {
      if(type==='tech'){
          window.$crisp.push(['do', 'chat:open']);
      }else{
          return window.location ='mailto:margot@ebloom.be?subject='+ _.upperFirst(t("admin.faq.clientSupport"))
      }
    }


  return (
      <>
          <Card onClick={() => handleCard()} className={'purple-border cursor-pointer'}>
              <Card.Body className={'h-100 relative'}>
                  <Card.Title className={'flex justify-between'}>
                          <div>
                              <h1 className={"w-auto ebloom-very-large-text font-semibold mb-10 text-contrast-purple text-left"}>
                                  {type === 'tech' ? _.upperFirst(t("admin.faq.tech")) : _.upperFirst(t("admin.faq.customer"))}
                              </h1>
                          </div>
                          <div className={'absolute purple-border '+ (type === 'tech' ? 'marco-profile' : 'margot-profile') } style={{top:-20, right: -10}} />
                  </Card.Title>
                  <div className={'w-3/4'}>
                      <p>
                          {type === 'tech' ? _.upperFirst(t('admin.faq.devText')) : _.upperFirst(t('admin.faq.customerText'))}
                      </p>
                  </div>
              </Card.Body>
              <div className={"text-right"}>
                  {
                      type==='tech' ?
                          <Button size={"sm"} className={"m-10 ebloom-btn-purple"} >{_.upperFirst(t('admin.faq.chatHim'))}</Button>
                          :
                          <Button size={"sm"}  className={"m-10 ebloom-btn-purple"} >{_.upperFirst(t('admin.faq.chatHer'))}</Button>
                  }
              </div>
          </Card>
      </>
  )
}


SupportCard.propTypes ={
    type : PropTypes.string.isRequired,
}
export default SupportCard