import React, {useState, useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessfullyCreatedNotification, createSuccessfullyUpdatedNotification, notifyError, notifySuccess,
} from "../../../../../Utils/Notification";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import _ from "lodash";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import QuestionModel from "../../../../../models/Question";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import QuestionList from "../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import {useTranslation} from "react-multi-lang";
import * as fileSaver from "file-saver";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import readXlsxFile from "read-excel-file";
import TagList from "../../../../Core/Modules/Input/TagList";
import { CompanyTypes as CompanyType, QuestionSectors } from '../../../../../Utils/Global';
import EbloomSearchBar from '../../../../Core/Modules/Input/EbloomSearchBar';

const QuestionEditor = (props) => {

    const [types, setTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState({id:null, text_en:"", text_fr:"", text_nl:"", id_tag:"", tag:"", type:0, sector:QuestionSectors.GLOBAL});
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [sideFormUploadOpen, setSideFormUploadOpen] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [tablePage, setTablePage] = useState(0);


    const t = useTranslation();

    const handleSave = () => {
        //TODO CHECK VALIDATION
        const updating = question.id !== null;
        const data = question;

        const url = updating ? QuestionModel.crud.update : QuestionModel.crud.create;
        if(!updating){
            delete data.id;
        }

        HttpApi.post(url, data).then(response => {
            if(response){
                if(updating){
                    createSuccessfullyUpdatedNotification("question");
                }else{
                    createSuccessfullyCreatedNotification("question");
                }
                setSideFormOpen(false);
                resetQuestionEditState();
                fetchAllQuestions();
            }
        }).catch(error => {
            notifyError(error);

        })
    };

    const handleExcel = (event) => {
        let file = event.target.files[0]
        readXlsxFile(file, {sheet:2}).then((rows1) => {
            let rows = [];
            readXlsxFile(file, {sheet:3}).then((rows2) => {
                rows2.splice(0, 1);
                rows = rows1.concat(rows2);
                readXlsxFile(file, {sheet:4}).then((rows3) => {
                    rows3.splice(0, 1);
                    rows = rows.concat(rows3);
                    readXlsxFile(file, {sheet:5}).then((rows4) => {
                        rows4.splice(0, 1);
                        rows = rows.concat(rows4);
                        readXlsxFile(file, {sheet:6}).then((rows5) => {
                            rows5.splice(0, 1);
                            rows = rows.concat(rows5);
                            setExcelData(rows);
                        }).catch(() => {
                            createErrorNotification("Error!", "Invalid file format. Please use Excel type");
                            document.getElementById("input-cb").value = null;
                        })
                    }).catch(() => {
                        createErrorNotification("Error!", "Invalid file format. Please use Excel type");
                        document.getElementById("input-cb").value = null;
                    })
                }).catch(() => {
                    createErrorNotification("Error!", "Invalid file format. Please use Excel type");
                    document.getElementById("input-cb").value = null;
                })
            }).catch(() => {
                createErrorNotification("Error!", "Invalid file format. Please use Excel type");
                document.getElementById("input-cb").value = null;
            })
        }).catch(() => {
            createErrorNotification("Error!", "Invalid file format. Please use Excel type");
            document.getElementById("input-cb").value = null;
        })
    }

    const saveExcel = () => {
        if(!loadingUpload){
            if(excelData && excelData.length > 0 && !loadingUpload){
                setLoadingUpload(true);
                setSideFormUploadOpen(false);
                HttpApi.post("/questions/uploadQuestion", {questions:excelData}).then(() => {
                    fetchAllQuestions();
                    notifySuccess("Click&Bloom uploaded");
                }).catch(error => {
                    notifyError(error);
                }).finally(() => setLoadingUpload(false));
            }else{
                createErrorNotification("Error ! ", "Please provide an excel file");
            }
        }
    }

    const exportQuestion = () => {
        if(!loadingExport){
            setLoadingExport(true);
            HttpApi.getWithResponseType("/questions/exportQuestion").then((response) => {
                let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, 'ebloom-clicknbloom.xlsx');
                notifySuccess("Click&Bloom successfully exported");
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoadingExport(false);
            });
        }

    }


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setQuestion(prevState => {
            let question = {...prevState};
            question[name] = value;
            return question;
        });
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const fetchCompanies = () => {
        HttpApi.getV2("/companies/findAll").then(response => {
            if(response && response.data){
                setTypes(response.data.filter(el => el.type === CompanyType.CLIENT || el.type === CompanyType.OTHER || el.type === CompanyType.GROUP || el.type === CompanyType.FREE));
            }else{
                setTypes([{name:"Global", id:0}]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchAllQuestions = () => {
        HttpApi.get("/questions/getAllDaily").then(response => {
            if(response && response.data){
                setQuestions(response.data);
            }else{
                setQuestions([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const fetchTags = () => {
        HttpApi.getV2("/tags/getAll/0").then(results => {
            if(results && results.data){
                setTags(results.data);
            }else{
                setTags([]);
                createInfoNotification("Info", "No tags");
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const resetQuestionEditState = () => {
        setQuestion({id:null, text_en:"", text_fr: "", text_nl: "", id_category: "", category: "", id_tag:"", tag:"", type:0, sector:QuestionSectors.GLOBAL});
    };

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(toEdit){
            setQuestion({id:toEdit.id, text_en:toEdit.text_en, text_fr:toEdit.text_fr, text_nl:toEdit.text_nl, id_category:toEdit.id_category, category:toEdit.name, id_tag:toEdit.id_tag, tag:toEdit.tag.name, type:toEdit.company.id, sector: toEdit.sector});
        }else{
            resetQuestionEditState();

        }
        setSideFormOpen(open);

    };

    const toggleDrawerUpload = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(!open){
            document.getElementById("input-cb").value = null;
        }

        setSideFormUploadOpen(open);

    };

    let formTitle = question.id === null ? _.capitalize(t("core.create")) + " " + _.capitalize(t("core.question")) : _.capitalize(t("core.update")) + " " + _.capitalize(t("core.question"));

    useEffect(() => {
        fetchAllQuestions();
        fetchTags();
        fetchCompanies();
    }, []);


    return (
        <>
            <CardDefault
                title={"Click&Bloom"}
                showButton
                handleRightButton={toggleDrawer(true)}
                showExtraRightButton
                extraRightButtonText={loadingExport ? <LoadingView size={16}/> : _.upperFirst(t("core.download"))}
                extraRightButtonVariant={"link"}
                handleExtraRightButton={exportQuestion}
                showSecondExtraRightButton
                secondExtraRightButtonText={loadingUpload ? <LoadingView size={16}/> : _.upperFirst(t("core.upload"))}
                secondExtraRightButtonVariant={"link"}
                handleSecondExtraRightButton={toggleDrawerUpload(true)}>
                <QuestionList questions={questions} isBackoffice={true} showAction={false} showActionButtons={true} handleDelete={fetchAllQuestions} handleEdit={toggleDrawer} showPagination tablePage={tablePage} handleTablePage={handleTablePage}/>
            </CardDefault>

            <SideForm sideFormOpen={sideFormUploadOpen} formTitle={_.upperFirst(t("core.upload"))} toggleDrawer={toggleDrawerUpload} showButtons handleSave={saveExcel}>
                <Row className={"mt20 mb20"}>
                    <Col>
                        <Form.Control type="file" id="input-cb" accept=".xlsx, .xlsm, .xls" onChange={handleExcel} />
                    </Col>
                </Row>
            </SideForm>

            <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <Row>
                    <TextFieldCustom helper={false} model={QuestionModel.text_en} value={question.text_en} handleChange={handleChange}/>
                </Row>

                <Row>
                    <TextFieldCustom helper={false} model={QuestionModel.text_fr} value={question.text_fr} handleChange={handleChange}/>
                </Row>

                <Row>
                    <TextFieldCustom helper={false} model={QuestionModel.text_nl} value={question.text_nl} handleChange={handleChange}/>
                </Row>
                <Row>
                    <Col className={"pb10 pt10 pl10 pr10"}>
                        <TagList handleChange={handleChange} value={question.id_tag} tags={tags} size={"medium"} label={"Tag"} name={"id_tag"}/>
                    </Col>
                </Row>
                <Row>
                    <TextFieldCustom helper={false} model={QuestionModel.company} value={question.type} handleChange={handleChange}>
                        {
                            types.map((type, index) => (
                                <MenuItem key={index} value={type.id}>{type.name}</MenuItem>
                            ))
                        }
                    </TextFieldCustom>
                </Row>
                <Row>
                    <TextFieldCustom helper={false} model={QuestionModel.sector} value={question.sector} handleChange={handleChange}>
                        {
                            Object.values(QuestionSectors).map((sector, index) => (
                              <MenuItem key={index} value={sector}>{sector}</MenuItem>
                            ))
                        }
                    </TextFieldCustom>
                </Row>
            </SideForm>
        </>
    );
}

export default QuestionEditor;