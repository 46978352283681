import React from "react";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Library from "../../../../Admin/Pages/Questions/Library/Library";


const BackofficeLibraryPage = (props) => {

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <Library companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
}

export default BackofficeLibraryPage;