import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateBlockedRoute, updateModal, updateNavigation} from "../store/navigation";
import ConfirmModal from "../Components/Core/Modules/Modal/ConfirmModal";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
export function useNavigationTo(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.navigation.value);
    const updateBlockState = (state) => {
        dispatch(updateNavigation(state));
        setNavigation(prevState => {
            let navigation = {...prevState};
            navigation.to = state === true ? () => {} : navigate;
            return navigation;
        })
    }

    const blockRouter = () => {
        updateBlockState(true);
    }

    const unblockRouter = () => {
        updateBlockState(false);
    }

    const [navigation, setNavigation] = useState({to:navigate, forceTo:navigate, updateBlockState:updateBlockState, blockRouter:blockRouter, unblockRouter:unblockRouter});

    useEffect(() => {
        setNavigation(prevState => {
            let navigation = {...prevState};
            navigation.to = selector === true ? (url, options={}) => {dispatch(updateModal(true)); dispatch(updateBlockedRoute({url:url, options:options}))} : navigate;
            return navigation;
        })
    }, [selector]);

    return navigation;
}

export const NavigationModal = (props) => {

    const selector = useSelector((state) => state.navigation.modal);
    const blockedRoute = useSelector((state) => state.navigation.blockedRoute);
    const dispatch = useDispatch();
    const navigation = useNavigationTo();
    const navigate = useNavigate();
    const t = useTranslation();

    const [show, setShow] = useState(false);

    const handleChange = () => {
        navigation.unblockRouter();
        navigate(blockedRoute.url, blockedRoute.options);
        dispatch(updateBlockedRoute({url:"", options:{}}))
    }

    const handleClose = () => {
        dispatch(updateModal(false));
    }

    useEffect(() => {
        setShow(selector);
    },[selector])

    return(
        <ConfirmModal handleClose={handleClose} show={show} title={_.upperFirst(t("core.unsavedChangesPromptTitle"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={handleChange}>
            <p>{_.upperFirst(t("core.unsavedChangesPromptBody"))}</p>
        </ConfirmModal>
    )
}