import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    updateId,
    updateEmail,
    updateName,
    updateCompany,
    updateIdCompany,
    updateRoles,
    updateModule,
    updateCompanyType,
    updateCompanyGroup, updateIsSuperAdmin
} from "../store/user";
import HttpApi from "../httpApi";
import {notifyError} from "../Utils/Notification";
import {useNavigate} from "react-router-dom";
import {getFromStorage, getStorage} from "../Utils/Storage";
import {CompanyTypes} from "../Utils/Global";

export function useUserData(){
    const  [user, setUser] = useState({
        id:useSelector((state) => state.user.id) || getFromStorage("id"),
        email:useSelector((state) => state.user.email) || getFromStorage("email"),
        name:useSelector((state) => state.user.name) || getFromStorage("name"),
        company:useSelector((state) => state.user.company) || getFromStorage("company"),
        roles:useSelector((state) => state.user.roles) || getFromStorage("roles"),
        module:useSelector((state) => state.user.module) || getFromStorage("module"),
        companyType:useSelector((state) => state.user.company_type) || getFromStorage("company_type"),
        companyId:useSelector((state) => state.user.id_company) || getFromStorage("id_company"),
        companyGroup:useSelector((state) => state.user.company_group) || getFromStorage("company_group"),
        isSuperAdmin:useSelector((state) => state.user.is_super_admin) || getFromStorage("is_super_admin"),

        setId:() => {},
        setEmail:() => {},
        setName:() => {},
        setCompany:() => {},
        setIdCompany:() => {},
        setRoles:() => {},
        setModule:() => {},
        setCompanyType:() => {},
        setCompanyGroup :() => {},
        setIsSuperAdmin:() => {},
        logout:() => {}
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = (redirect=true) => {
        HttpApi.post("/auth/logout").then().catch(error => {
            notifyError(error);
        }).finally(() => {
            sessionStorage.removeItem("ebloom-credentials");
            localStorage.removeItem("ebloom-credentials");
            localStorage.removeItem("ebloom-companyToEdit");
            localStorage.removeItem("ebloom-showSBox");
            if(redirect === true){
                navigate("/login");
            }
        })

    }

    const setId = (id) => {
        setUser(prevState => {
            let user = {...prevState};
            user.id = id;
            return user;
        })
        dispatch(updateId(id));
    }

    const setEmail = (email) => {
        setUser(prevState => {
            let user = {...prevState};
            user.email = email;
            return user;
        })
        dispatch(updateEmail(email));
    }

    const setName = (name) => {
        setUser(prevState => {
            let user = {...prevState};
            user.name = name;
            return user;
        })
        let store = getStorage("ebloom-credentials") || {};
        store.name = name;
        if(store.company_type === CompanyTypes.DEMO){
            sessionStorage.setItem("ebloom-credentials", JSON.stringify(store));
        }else{
            localStorage.setItem("ebloom-credentials", JSON.stringify(store));
        }
        dispatch(updateName(name));
    }

    const setCompany = (company) => {
        setUser(prevState => {
            let user = {...prevState};
            user.company = company;
            return user;
        })
        dispatch(updateCompany(company));
    }

    const setIdCompany = (id_company) => {
        setUser(prevState => {
            let user = {...prevState};
            user.id_company = id_company;
            return user;
        })
        dispatch(updateIdCompany(id_company));
    }

    const setRoles = (roles) => {
        setUser(prevState => {
            let user = {...prevState};
            user.roles = roles;
            return user;
        })
        dispatch(updateRoles(roles));
    }

    const setModule = (module) => {
        setUser(prevState => {
            let user = {...prevState};
            user.module = module;
            return user;
        })
        dispatch(updateModule(module));
    }

    const setCompanyType = (companyType) => {
        setUser(prevState => {
            let user = {...prevState};
            user.companyType = companyType
            return user;
        })
        dispatch(updateCompanyType(companyType));
    }

    const setCompanyGroup = (companyGroup) => {
        setUser(prevState => {
            let user = {...prevState};
            user.companyGroup = companyGroup
            return user;
        })
        dispatch(updateCompanyGroup(companyGroup));
    }

    const setIsSuperAdmin = (isSuperAdmin) => {
        setUser(prevState => {
            let user = {...prevState};
            user.isSuperAdmin = isSuperAdmin
            return user;
        })
        dispatch(updateIsSuperAdmin(isSuperAdmin));
    }

    useEffect(() => {
        setUser(prevState => {
            let user = {...prevState};
            user.setId = setId;
            user.setEmail = setEmail;
            user.setName = setName;
            user.setCompany = setCompany;
            user.setIdCompany = setIdCompany;
            user.setRoles = setRoles;
            user.setModule = setModule;
            user.setCompanyType = setCompanyType;
            user.setCompanyGroup = setCompanyGroup;
            user.setIsSuperAdmin = setIsSuperAdmin;
            user.logout = logout;
            return user;
        });
    }, []);

    return user;
}