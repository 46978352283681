import React from "react";
import {Col, Row} from "react-bootstrap";
import SupportCard from "./Card/SupportCard";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
const SupportFAQ = (props) => {

    const t = useTranslation()


  return (
      <>
          <Row className={'mb-30'}>
              <h1 className={'ebloom-normal-title font-semibold  text-contrast-purple'}>{_.upperFirst(t("admin.faq.contact"))}</h1>
          </Row>
          <Row md={2}>
              <Col>
                  <SupportCard type={'tech'}/>
              </Col>
              <Col>
                  <SupportCard type={'customer'}/>
              </Col>
          </Row>
      </>
  )
}

export default SupportFAQ