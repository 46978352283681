import React, {useEffect, useState} from "react";
import {Badge, Card, Col, Row} from "react-bootstrap";
import _ from "lodash";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from "@mui/material/IconButton/IconButton";
import {ReactComponent as PollIcon} from "../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as IdeaboxIcon} from "../../../../Core/symbols/webloom/white/ideabox-white.svg"
import {ReactComponent as HandsIcon} from "../../../../Core/symbols/webloom/white/participation-white.svg";
import {ReactComponent as UsersGroupIcon} from "../../../../Core/symbols/webloom/white/people-white.svg";
import {ReactComponent as AnswerBubbleIcon} from "../../../../Core/symbols/webloom/white/answer-white.svg";
import {ReactComponent as BellIcon} from "../../../../Core/symbols/webloom/white/alert-white.svg";
import {ReactComponent as InboxIcon} from "../../../../Core/symbols/webloom/white/inbox-white.svg";
import {ReactComponent as MessageIcon} from "../../../../Core/symbols/webloom/white/message-white.svg";
import {ReactComponent as BoostIcon} from "../../../../Core/symbols/webloom/white/boost-white.svg";
import {ReactComponent as AlertIcon} from "../../../../Core/symbols/webloom/white/finguer-up-white.svg";
import {SurveyStatus, SurveyTypes} from "../Polls/Modules/Types";
import {useTranslation} from "react-multi-lang/lib";
import WeBloomDashboardItemMenu from "./Modules/WeBloomDashboardItemMenu";
import WeBloomDashboardItemProgressBar from "./Modules/WeBloomDashboardItemProgressBar";
import moment from "moment";
import {getLanguage} from "react-multi-lang";
import EbloomNotificationBadge from "../../../../Core/Modules/Notifications/EbloomNotificationBadge";
import {ReactComponent as LikeIcon} from "../../../../Core/symbols/webloom/white/like-white.svg";
import {ReactComponent as EyeIcon} from "../../../../Core/symbols/webloom/white/show-white.svg";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import FileDisplay from "../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {Limit} from "../../../../../Utils/Global";
import {selectBubbleIcon} from "../../Categories/Assets/CategoriesAssets";
import PropTypes from "prop-types";

const WeBloomDashboardCard = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const {survey, handleSeeDetail, handleEdit,isProspect,isAllEntities,isGroup} = props;

    //hooks
    const t = useTranslation();

    //handlers
    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleOpenMenu = (event) => {
        setAnchorEl(event.target);
    }

    const displayOverflowScroll= () => {
        switch (survey.status) {
            case SurveyStatus.DONE :
                return ''
            case SurveyStatus.DRAFT :
                return 'overflow-y-auto-blue'
            case SurveyStatus.RUNNING :
                return 'overflow-y-auto-purple'
            case SurveyStatus.ARCHIVED :
                return 'overflow-y-auto-grey'
            default :
                return ''
        }
    }

    const displayColor = () => {
        return survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED ? "contrast-blue" : survey.status === SurveyStatus.RUNNING ? "purple" : survey.status === SurveyStatus.DONE ? "contrast-purple" : "grey";
    }

    const displayStatus = () => {
        return survey.status === SurveyStatus.DRAFT ? t("webloom.draft") : survey.status === SurveyStatus.SCHEDULED ? t("webloom.scheduled") : survey.status === SurveyStatus.RUNNING ? t("webloom.running") : survey.status === SurveyStatus.DONE ? t("webloom.done") : t("webloom.archived")
    }

    const displaySurveyRecipient = () => {
        switch (survey.recipients){
            case "allCurrentAndFutureUsers":
                return _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"));
            case "allCurrentUsers":
                return _.upperFirst(t("core.collaborative.allCurrentUsers"));
            case "customized":
                return _.upperFirst(t("core.customized"));
            default:
                if(isProspect){
                    return _.upperFirst(t("core.customized"));
                }else{
                    return survey.recipients
                }

        }
    }

    return(
        <Card className={"cursorDefault border-1 border-" + displayColor() + (survey.status === SurveyStatus.ARCHIVED ? " opacity-60" : " opacity-100")}>
            <Card.Header className={"flex justify-between items-center bg-" + displayColor()}>
                <div>
                    {
                        survey.type === SurveyTypes.POLL ? <PollIcon className={"inline w-34 h-34"}/> : survey.type === SurveyTypes.IDEABOX ? <IdeaboxIcon className={"inline w-34 h-34"}/> : survey.type === SurveyTypes.MESSAGE ? <MessageIcon className={"inline w-34 h-34"}/> : <AlertIcon className={"inline w-30 h-30"}/>
                    }
                    <span className={"ebloom-very-large-text text-white font-semibold ml5 inline align-middle"}>{_.upperFirst(t("webloom."+ survey.type))}</span>
                </div>
                <div className={"min-w-fit flex items-center justify-between"}>
                    {
                        (survey.last_boost && survey.status === SurveyStatus.RUNNING && !isProspect) &&
                        <div className={"mr-5"}>
                            <EbloomTooltip text={_.upperFirst(t("webloom.lastBoost")) + " " + moment(survey.last_boost).format("DD/MM/YY")}>
                                <BoostIcon className={"w-25 h-25"} />
                            </EbloomTooltip>
                        </div>
                    }
                    <div className={"h-25 text-white rounded-3xl bg-white/[0.25] px-5 cursorDefault"}>{_.toUpper(displayStatus())}</div>
                    {
                        (!isProspect && !isAllEntities) &&
                        <div><IconButton size={"small"} onClick={handleOpenMenu}><MoreHorizIcon className={"text-white"}/></IconButton></div>
                    }

                    {
                        (!isProspect && !isAllEntities) &&
                        <WeBloomDashboardItemMenu survey={survey} color={displayColor()} anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} {...props} isGroup={isGroup}/>
                    }

                </div>
            </Card.Header>
            <Card.Body className={"pointer"} style={{height:421}} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}}>
                <div
                    className={"text-" + displayColor() + " h-169 mb-15 overflow-y-auto " + displayOverflowScroll() + " text-center ebloom-title font-semibold relative"}>
                    {
                        survey.type === SurveyTypes.ALERT ?
                            <span>{_.upperFirst(t("webloom.alertText"))}</span>
                            :
                        survey.title ?
                          <div><p>{survey.title}</p>{(survey.id_company === 0 && survey.logo_url) && <div className={"float-right"}><FileDisplay urlParams={survey.logo_url} displayImg imgHeight={50}/></div>}</div>
                            : survey.question && survey.question.category ?
                                <div className={"relative"}>
                                    <div className={"w-30 h-30 left-0 absolute"}>
                                        {selectBubbleIcon(survey.question.icon, survey.question.color)}
                                    </div>
                                    <div
                                        className={"text-" + displayColor() + " text-center ebloom-title font-semibold ml-35"}>{survey.question.text}</div>
                                </div>
                                :
                                <span>
                                    {survey.question.text}
                                </span>
                    }
                    {
                        (survey.attachment_url || survey.image_url) &&
                        <div className={"mt-10"}>
                            {
                                survey.image_url &&
                                <div className={"h-100"}>
                                    <FileDisplay urlParams={survey.image_url} displayImg
                                                 imgHeight={100}/>
                                </div>
                            }

                            {
                                survey.attachment_url &&
                                <div className={"h-40 my-10 flex justify-end items-center"}>
                                    <FileDisplay urlParams={survey.attachment_url}/>
                                </div>
                            }
                        </div>

                    }
                </div>

            <div className={"flex flex-col h-1/2"}>
                <WeBloomDashboardItemProgressBar survey={survey}/>
                <div
                    className={"text-" + displayColor() + " font-semibold center mt-10 "}>{"# " + displaySurveyRecipient()}</div>
                {
                    survey.type === SurveyTypes.POLL ?
                        <div
                            className={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED ? "opacity-60 h-full flex flex-col justify-end" : "opacity-100 h-full flex flex-col justify-end ")}>
                            <Row className={""}>
                                <Col md={2}><HandsIcon className={displayColor() + "-icon h-26 w-26"}/></Col>
                                <Col
                                    className={"text-left ebloom-text ebloom-small-text"}>{_.upperFirst(t("manager.dashboard.participationRate"))} (%)</Col>
                                {
                                    (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                    <Col md={2} className={"text-right"}><Badge bg={""}
                                                                                className={"text-white bg-" + displayColor()}>{Math.round((survey.nbResponses / survey.users.length) * 100) || "0"}</Badge></Col>
                                }
                            </Row>
                                <Row className={"my-15"}>
                                    <Col md={2}><UsersGroupIcon className={displayColor() + "-icon h-26 w-26"}/></Col>
                                    <Col className={"text-left ebloom-text ebloom-small-text"}>{_.upperFirst(t("webloom.numberParticipants")) + " (max "+ (survey.users ? survey.users.length : 0)+")"}</Col>
                                    {
                                        (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                        <Col md={2} className={"text-right"}><Badge bg={""} className={"text-white bg-" + displayColor()}>{survey.nbResponses || "0"}</Badge></Col>
                                    }
                                </Row>
                                <Row className={""}>
                                    <Col md={2}>
                                        <EbloomNotificationBadge number={survey.nbResponses-survey.lastNbAnswers} showSonar>
                                            <AnswerBubbleIcon className={displayColor() + "-icon h-26 w-26"}/>
                                        </EbloomNotificationBadge>
                                    </Col>
                                    <Col className={"text-left ebloom-text ebloom-small-text"}>
                                        <span>{_.upperFirst(t("webloom.lastAnswer"))}</span>
                                    </Col>
                                    {
                                        (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                        <Col md={5} className={"text-right"}><Badge bg={""} className={"text-white bg-" + displayColor()}>{survey.lastAnswer ? _.upperFirst(moment(survey.lastAnswer).locale(getLanguage()).fromNow()) : "-"}</Badge></Col>
                                    }
                                </Row>
                            </div>
                            : (survey.type === SurveyTypes.IDEABOX || survey.type === SurveyTypes.ALERT) ?
                                <div className={"relative h-full w-full"}>
                                    <div
                                        className={((survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? "opacity-60 h-full flex flex-col justify-end  w-full " : "opacity-100 h-full flex flex-col justify-end  w-full")}>
                                        <Row className={""}>
                                            <Col md={2}>
                                                <InboxIcon className={displayColor() + "-icon h-26 w-26"}/>
                                            </Col>
                                            <Col
                                                className={"text-left ebloom-text ebloom-small-text"}>{_.upperFirst(t(survey.type === SurveyTypes.IDEABOX ? "webloom.nbIdea" : "webloom.nbAlert"))}</Col>
                                            {
                                                (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                                <Col md={2} className={"text-right"}>
                                                    <Badge bg={""} className={"text-white bg-" + displayColor()}>
                                                        {survey.nbIdeas}
                                                    </Badge>
                                                </Col>
                                            }
                                        </Row>
                                        <Row className={"my-15"}>
                                            <Col md={2}>
                                                <EbloomNotificationBadge number={(survey.type === SurveyTypes.ALERT && survey.questions[0] && survey.questions[0].answers ? survey.questions[0].answers.filter(el => !el.isRead).length : 0)} showSonar>
                                                    <BellIcon className={displayColor() + "-icon h-26 w-26"}/>
                                                </EbloomNotificationBadge>
                                            </Col>
                                            <Col
                                                className={"text-left ebloom-text ebloom-small-text"}>{_.upperFirst(t(survey.type === SurveyTypes.ALERT ? "webloom.unanswered" : "webloom.unread"))}</Col>
                                            {
                                                (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                                <Col md={2} className={"text-right"}><Badge bg={""}
                                                                                            className={"text-white bg-" + displayColor()}>{survey.questions[0] && survey.questions[0].answers ? survey.questions[0].answers.filter(el => !el.isRead).length : 0}</Badge></Col>
                                            }
                                        </Row>
                                        <Row className={""}>
                                            <Col md={2}>
                                                <EbloomNotificationBadge
                                                    number={survey.nbResponses >= Limit.ANONYMITY ? survey.nbIdeas - survey.lastNbAnswers : 0} showSonar>
                                                    {
                                                        survey.type === SurveyTypes.IDEABOX ?
                                                                <IdeaboxIcon className={displayColor() + "-icon h-26 w-26"}/>
                                                            :
                                                            <AlertIcon className={displayColor() + "-icon h-26 w-26"}/>
                                                    }

                                                </EbloomNotificationBadge>
                                            </Col>
                                            <Col
                                                className={"text-left ebloom-text ebloom-small-text"}>{_.upperFirst(t(survey.type === SurveyTypes.IDEABOX ? "webloom.lastIdea" : "webloom.lastAlert"))}</Col>
                                            {
                                                (survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED) &&
                                                <Col md={5} className={"text-right"}>
                                                    <Badge bg={""} className={"text-white bg-" + displayColor()}>
                                                        {survey.lastAnswer ? _.upperFirst(moment(survey.lastAnswer).locale(getLanguage()).fromNow()) : "-"}
                                                    </Badge>
                                                </Col>
                                            }
                                        </Row>
                                    </div>

                                </div>
                                :
                                <div
                                     className={((survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? "opacity-60 flex justify-between items-end w-full h-full" : "opacity-100 flex items-end justify-between w-full h-full")}>
                                    <div className={"flex items-center"}>
                                        <LikeIcon className={displayColor() + "-icon h-26 w-26 mr-5"}/>
                                        {
                                            survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED &&
                                            <span
                                                className={"text-" + displayColor() + " font-semibold"}>{survey.question.score || "0"}</span>
                                        }
                                    </div>
                                    <div className={"flex items-center"}>
                                        <EyeIcon className={displayColor() + "-icon h-26 w-26 mr-5"}/>
                                        {
                                            survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED &&
                                            <span
                                                className={"text-" + displayColor() + " font-semibold"}>{(survey.nbViews || "0") + "/" + survey.users.length}</span>
                                        }
                                    </div>
                                </div>
                    }
                </div>
                            </Card.Body>
        </Card>
    )
}

WeBloomDashboardCard.propTypes = {
    isAllEntities : PropTypes.bool,
    isGroup : PropTypes.bool
}
WeBloomDashboardCard.defaultProps = {
    isAllEntities : false,
    isGroup : false,
}


export default WeBloomDashboardCard;