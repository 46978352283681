import React, {useState} from 'react';
import DisplaySuperAdminView from "../../../Layouts/DisplaySuperAdminView";
import {Container} from "react-bootstrap";
import SuperAdminDashboard from "../SuperAdminDashboard";
import {Colors} from "../../../../../Utils/Colors";

const SuperAdminDashboardEntitiesPage = (props) => {
    const [bgColor, setBgColor] = useState(Colors.EBLOOM_WHITE_BLUE);

    return (
        <DisplaySuperAdminView {...props} menu={"noMenu"} module={"dashboard"} isDashboardSuperAdmin={true} isAllEntities={true} showDatePicker={true} bgColor={bgColor} showFilterCommonSurveys={true} >
            {
                (entitiesToDisplay, startDate, endDate, timeFilterVersion, shouldDownloadReport, handleReportState, showModalReport, handleModalReport, handleReportDisabled,teamsEntityToDisplay,entitiesDashGroup,colors,commonSurveyActivated) => (
                    <Container className={"pt-84"} fluid>
                        {
                            <SuperAdminDashboard {...props} isAllEntities={true} teamToDisplay={entitiesToDisplay} startDate={startDate} endDate={endDate} timeFilterVersion={timeFilterVersion} shouldDownloadReport={shouldDownloadReport} handleReportState={handleReportState} showModalReport={showModalReport} handleModalReport={handleModalReport} handleReportDisabled={handleReportDisabled} teamsEntityToDisplay={teamsEntityToDisplay} commonSurveyActivated={commonSurveyActivated}/>
                        }

                    </Container>
                )
            }
        </DisplaySuperAdminView>
    );
};

SuperAdminDashboardEntitiesPage.propTypes = {

};

export default SuperAdminDashboardEntitiesPage;
