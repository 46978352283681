import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useDrag} from "react-dnd";
import _ from "lodash";

const DraggableTag = props => {
    const {itemType,children} = props
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: "tag",
        item: itemType,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return (
        <div ref={ref} style={{ opacity }}>
            {children}
        </div>
    );
};

DraggableTag.propTypes = {

};

export default DraggableTag;
