import React, {useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../httpApi";
import {Row, Col, ListGroup, ListGroupItem, Button} from "react-bootstrap";
import Switch from '@mui/material/Switch';
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import TextField from "@mui/material/TextField/TextField";
import {
    createErrorNotification,
    createSuccessNotification, notifyCustomError, notifyError, notifySuccess
} from "../../../../../Utils/Notification";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {Checkbox, MenuItem} from "@mui/material";
import UserModel from "../../../../../models/User";
import {FullContrastPurpleTextField, Mail} from "../../../../../Utils/Global";
import {getLanguages} from "../../../../../lang/translations";
import ListView from "../../../../Core/Modules/Views-Tables-Lists/ListView";
import NotificationToken from "../../../models/NotificationToken";


const Message = (props) => {

    //State
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [sideFormMobileOpen, setSideFormMobileOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [message, setMessage] = useState({subject:"", body:""});
    const [companiesTeams, setCompaniesTeams] = useState([]);
    const companyTeams = 0;
    const [push, setPush] = useState({user:{email:"", token:""}, title:"", body:""});
    const [mobileTokens, setMobileTokens] = useState([]);
    const [mail, setMail] = useState({to:"",
        text:"",
        type:"daily",
        lang:"user",
        subject:"",
        isPolite:false,
        button:false,
        buttonText:"",
        buttonLink:"",
        mailSystem:Mail.MAILJET});

    const mailSystems = [{value:Mail.MAILJET, name:"Mailjet"}, {value:Mail.MANDRILL, name: "Mandrill"}, {value:Mail.EC2_SMTP, name:"EC2 - SMTP"}, {value:Mail.MAILERSEND, name:"MailerSend"}];

    const types = [{value:"daily", name:"Daily"}, {value:"reminder", name:"Reminder"}, {value:"morningReminder", name:"Morning reminder"},{value:"middleReminder", name:"Middle reminder"}, {value:"lastReminder",name:"Last reminder"}, {value:"invitation", name:"Invitation"},{value:"collab", name:"Poll"},{value:"keytradeCollab", name:"Keytrade Poll"},{value:"sbox",name:"Ideabox"},{value:"keytradeSbox",name:"Keytrade Ideabox"},{value:"alert",name:"Alert"},{value:"answerAlert",name:"Answer alert"},{value:"resetPassword",name:"PasswordRecovery"}, {value:"submitCB", name:"Submit Custom CB"}, {value:"reporting", name:"Reporting"}, {value:"custom", name:"Custom"}];

    //props

    //hooks
    const t = useTranslation();

    //getters
    const fetchActiveSlackCompanies = () => {
        HttpApi.get("/integration/slack/getActiveCompanies").then(async response => {
            if(response && response.data){
                await response.data.forEach(company => {
                    company.checked = false;
                });
                setCompanies(response.data);
            }
        }).catch(error => {
            notifyError(error);
        });
    };

    const fetchActiveTeamsCompanies = () => {
        HttpApi.get("/integration/teams/getActiveCompanies").then(response => {
            if(response && response.data){
                setCompaniesTeams(response.data);
            }else{
                setCompaniesTeams([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    //posters
    const handleSend = () => {
        if(!message.subject || !message.body){
            createErrorNotification("Error !", "Invalid payload request to send messages : Subject and Messages required !");
        }else{
            let id_companies = [];
            companies.forEach((company) => {
                if(company.checked){
                    id_companies = _.concat(id_companies, company.id_company);
                }
            });
            HttpApi.post("/integration/slack/postUniqueMessage", {subject:message.subject, message:message.body, companies:id_companies}).then(() => {
                createSuccessNotification("Success !", id_companies.length + " message(s) sent");
                setMessage({subject: "", body:""});
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const handleSendPush = () => {
        if(push.title && push.user.email && push.user.token && push.body){
            HttpApi.post("/integration/pushCustomNotification", {title:push.title, text:push.body, email:push.user.email, token:push.user.token}).then((response) => {
                if(response && response.data){
                    notifySuccess("Notification successfully sent");
                }else{
                    notifyCustomError("Invalid token, deleted from database - Check logs for more information");
                }
                setPush(prevState => {
                    let push = {...prevState};
                    push.user.email = "";
                    push.user.token = "";
                    return push;
                })
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const handleSendMail = () => {
        HttpApi.postV2("/integrations/sendEmailTestNotification", {
            email: mail.to,
            type: mail.type,
            mail_system: mail.mailSystem,
            lang: mail.lang,
            is_polite: mail.isPolite
        }).then(() => {
            notifySuccess("Email sent to " + mail.to);
            setMail(prevState => {
                let mail = {...prevState};
                mail.to = "";
                return mail;
            })
        }).catch(error => {
            notifyError(error);
        })
    };

    const handleSendTeamsNotification = () => {
        HttpApi.post("/integration/teams/sendNotification", {id_company:companyTeams}).then(() => {
            notifySuccess("Notification successfully sent");
        }).catch(error => {
            notifyError(error);
        })
    }

    //handlers

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setMessage(prevState => {
            let message = {...prevState};
            message[name] = value;
            return message;
        })
    };

    const handleInputPush = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setPush(prevState => {
            let push = {...prevState};
            push[name] = value;
            return push;
        })
    }

    const handleMail = (event) => {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        setMail(prevState => {
            let mail = {...prevState};
            mail[name] = value;
            return mail;
        })
    };

    const handleChange = (event) => {
         const checked = event.target.checked;
         const name = event.target.name;
         setCompanies(prevState => {
             let companies = [...prevState];
             companies[name].checked = checked;
             return companies;
         })
    };

    const handleChangeTeamsCompany = (event) => {
        setCompaniesTeams(event.target.value);
    }

    const handleListAction = (item) => event => {
        event.preventDefault();
        setPush(prevState => {
            let push = {...prevState};
            push.user.email = item.user.email;
            push.user.token = item.token;
            return push;
        })
        setSideFormMobileOpen(false);

    }

    const resetMailForm = () => {
        setMail({to:"",
            text:"",
            isNotification:mail.isNotification,
            subject:"",
            button:mail.button,
            buttonText:"",
            buttonLink:""})
    };

    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setSideFormOpen(open);
    };

    const toggleMobileDrawer = (open) => event => {
        event.preventDefault();
        setSideFormMobileOpen(open);
    }

    //useEffect

    useEffect(() => {
        fetchActiveTeamsCompanies();
        fetchActiveSlackCompanies();
        HttpApi.get("/integration/notification/token/getAll").then(response => {
            if(response && response.data){
                setMobileTokens(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }, []);

    return(
        <Row>
            <Col md={6} sm={12}>
                <CardDefault title={_.capitalize(t("core.message")) + " Slack"} handleRightButton={toggleDrawer(true)}>
                    <Row className={"mb-10"}>
                        <Col md={12} sm={12}>
                            <TextField required fullWidth  id="subject-message" label="Subject" variant="outlined" name={"subject"} value={message.subject} onChange={handleInput} />
                        </Col>
                    </Row>
                    <Row className={"mt-10 mb-10"}>
                        <Col md={12} sm={12}>
                            <TextField required fullWidth id="body-message" label="Message" variant="outlined" multiline={true} rows={10} name={"body"} value={message.body} onChange={handleInput}/>
                        </Col>
                    </Row>
                    <Row className={"m-10 float-right"}>
                        <Button size="sm" className="ebloom-btn-purple" onClick={handleSend}>{_.capitalize(t("core.send"))}</Button>
                    </Row>
                </CardDefault>

                <SideForm sideFormOpen={sideFormOpen} formTitle={"Select Companies"} toggleDrawer={toggleDrawer} showButtons={false}>
                   <ListGroup variant="flush">
                       {
                           companies?.map((company, index) => (
                               <ListGroupItem>{company.company || company.name} <Checkbox className={"checkbox-purple"} name={index} value={company.checked} checked={company.checked} onChange={handleChange} /></ListGroupItem>
                           ))
                       }
                   </ListGroup>
                </SideForm>
                <SideForm toggleDrawer={toggleMobileDrawer} sideFormOpen={sideFormMobileOpen} formTitle={"Select user"} showButtons={false}>
                     <ListView items={mobileTokens} model={NotificationToken} handleAction={handleListAction}/>
                </SideForm>
            </Col>
            <Col md={6} sm={12}>
                <CardDefault title={_.capitalize(t("core.message")) + " Mail"} showButton={false}>
                    <Row>
                        <Col md={12} sm={12}>
                            <TextField required fullWidth id="to-email" label={"To"} variant={"outlined"} name={"to"} value={mail.to} onChange={handleMail}/>
                        </Col>
                    </Row>
                    <Row className={"mt10"}>
                        <Col>
                            <TextField select required fullWidth id="mailSystem" label={"Mail System"} variant={"outlined"} name={"mailSystem"} value={mail.mailSystem} onChange={handleMail}>
                                {
                                    mailSystems.map((system, index) => (
                                        <MenuItem key={index} value={system.value}>{system.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Col>
                    </Row>
                    <Row className={"mt-10"}>
                        <Col>
                            <TextField select required fullWidth id={"type"} label={"Type"} variant={"outlined"} name={"type"} value={mail.type} onChange={handleMail}>
                                {
                                    types.map((type, index) => (
                                        <MenuItem key={index} value={type.value}>{type.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Col>
                    </Row>
                    <Row className={"mt-10"}>
                        <Col>
                            <TextField select required fullWidth id={"lang"} label={"lang"} variant={"outlined"} name={"lang"} value={mail.lang} onChange={handleMail}>
                                <MenuItem key={-1} value={"user"}>User lang</MenuItem>
                                {
                                    getLanguages().map((lang, index) => (
                                        <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Col>
                    </Row>
                    <Row className={"mt-10 mb-10"}>
                        <Col>
                            <Checkbox className={"checkbox-purple"} value={mail.isPolite} onChange={handleMail} name={"isPolite"}/> Polite
                        </Col>
                    </Row>
                    {
                        mail.type === "custom" &&
                            <div>
                                <Row className={"mb-10"}>
                                    <Col>
                                        <TextField required fullWidth id="subject-email" label={"Subject"} variant={"outlined"} name={"subject"} value={mail.subject} onChange={handleMail}/>
                                    </Col>
                                </Row>
                                <Row className={"mb-10 mt-10"}>
                                    <Col>
                                        <TextField required fullWidth id="message-email" multiline={true} rows={10} label={"Message"} variant={"outlined"} name={"text"} value={mail.text} onChange={handleMail}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span>Use Button</span><Switch value={mail.button} checked={mail.button} onChange={handleMail} name={"button"}/>
                                    </Col>
                                </Row>
                                {
                                    mail.button &&
                                        <Row>
                                            <Col>
                                                <TextField required fullWidth id="buttonText-email" label={"Button Text"} variant={"outlined"} name={"buttonText"} value={mail.buttonText} onChange={handleMail}/>

                                            </Col>
                                            <Col>
                                                <TextField required fullWidth id="buttonLink-email" label={"Button Link"} variant={"outlined"} name={"buttonLink"} value={mail.buttonLink} onChange={handleMail}/>

                                            </Col>
                                        </Row>
                                }
                            </div>
                    }
                    <Row className={"m-10 float-right"}>
                        <Button size="sm" style={{marginRight:10}} variant={"secondary"} onClick={resetMailForm}>{_.capitalize("reset")}</Button>
                        <Button size="sm" className="ebloom-btn-purple" onClick={handleSendMail}>{_.capitalize(t("core.send"))}</Button>
                    </Row>
                </CardDefault>
            </Col>
            <Col md={6}>
                <CardDefault title={"Messages Teams"} showButton={false}>
                    <Row>
                        <Col>
                            <TextFieldCustom model={UserModel.id_company} value={companyTeams} handleChange={handleChangeTeamsCompany}>
                                {
                                    companiesTeams.map(company => (
                                        <MenuItem key={company.id_company} value={company.id_company}>{company.company}</MenuItem>
                                    ))
                                }
                            </TextFieldCustom>
                        </Col>
                    </Row>
                    <Button size="sm" className={"ebloom-btn-purple right"} onClick={handleSendTeamsNotification}>Send Notification</Button>
                </CardDefault>
            </Col>
            <Col md={6}>
                <CardDefault title={"Push notifications"} showButton={true} handleRightButton={toggleMobileDrawer(true)} buttonText={_.upperFirst(t("core.collaborative.recipients"))}>
                    <Row className={"mb-10"}>
                        <Col>
                            <span>{_.upperFirst(t("core.to")) + ": " + push.user.email}</span>
                        </Col>
                    </Row>
                    <Row className={"mb-10"}>
                        <Col>
                            <FullContrastPurpleTextField required fullWidth id={"title-push"} label="Title" variant="outlined" name={"title"} value={push.title} onChange={handleInputPush}/>
                        </Col>
                    </Row>
                    <Row className={"mt-10 mb-10"}>
                        <Col>
                            <FullContrastPurpleTextField required fullWidth id="body-push" label="Message" variant="outlined" multiline={true} rows={3} name={"body"} value={push.body} onChange={handleInputPush}/>
                        </Col>
                    </Row>
                    <Button className={"ebloom-btn-purple mt-20 right"} size={"sm"} onClick={handleSendPush}>{_.upperFirst(t("core.send"))}</Button>
                </CardDefault>
            </Col>
        </Row>

    )

}

export default Message;