import React from "react";
import PropTypes from "prop-types";
import {Colors} from "../../../../Utils/Colors";
import {Badge, Row} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import _ from "lodash";

const EbloomSmileyProgressBar = (props) => {

    const {nbResponse, total, staticSmiley, customSmiley, customColor, showRepartition, barWidth, barHeight, space, avg, showSmiley, legendFontSize, showLegendPercent, showMinimumView,isHeatMap} = props;

    const t = useTranslation();

    const score = (avg !== null && avg !== undefined) ? parseInt(avg) : total > 0 ? Math.round((nbResponse/total)*100) : -2;
    const smiley = staticSmiley ? customSmiley : score >= 80 ? "smileyHappy" : score >= 60 ? "smileyGlad" : score >= 40 ? "smileyPokerface" : score >= 20 ? "smileySad" : score >= 0 ? "smileyUnhappy" : "smileyAnonymous";
    const color = staticSmiley ? customColor : score >= 80 ? Colors.EBLOOM_STRONG_GREEN : score >= 60 ? Colors.EBLOOM_LIGHT_GREEN : score >= 40 ? Colors.EBLOOM_NEUTRAL : score >= 20 ? Colors.EBLOOM_LIGHT_RED : Colors.EBLOOM_STRONG_RED;

    const clampedScore = Math.min(Math.max(score, 0), 100);
    return(
        showMinimumView
        ?
            <div className={"flex items-center ml-10"} style={{marginTop:space/2, marginBottom:space/2}}>
                <div className={smiley + " very_small_smile"} style={{marginRight:0, marginLeft:0, backgroundSize:smiley === "smileyAnonymous" ? 29 : 25}}/>
                {
                    score >= 0 ?
                        <span className={"ebloom-text"} style={{marginLeft:10, fontSize:isHeatMap ? 16 :legendFontSize}}>{score + (showLegendPercent ? "%" : "")}</span>
                        :
                        score === -1 ?
                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.anonymousRestriction"))}>
                                <Badge pill bg={""} className={"bg-grey text-white h-18 ml-10"}>{t("manager.dashboard.anonymityRestrictionSmall")}</Badge>
                            </EbloomTooltip>
                            :
                            score === -2 ?
                                <span className={"ebloom-text ml-10"}>N/A</span>
                                :
                                <span/>
                }
                {
                    showRepartition &&
                    <span className={"ebloom-text"} style={{marginLeft:5, fontSize:legendFontSize, marginTop:5, float:"right", textAlign:"right"}}>{"(" + nbResponse + "/" + total + ")"}</span>

                }
            </div>
        :
        <div className={"flex items-center"} style={{marginTop:space/2, marginBottom:space/2}}>
            {
                showSmiley &&
                <div className={smiley + " very_small_smile"} style={{marginRight:5, marginLeft:0, backgroundSize:smiley === "smileyAnonymous" ? 29 : 25}}/>
            }
            <div style={{width:barWidth, height:barHeight, backgroundColor:"#C4C4C4", borderRadius:4}}>
                <div
                    style={{
                        width:`${clampedScore}%`,
                        height:barHeight,
                        borderRadius:4,
                        backgroundColor:color,
                        transition: 'width 0.3s ease'
                }}/>
            </div>
            <div>
                {
                    score >= 0 ?
                        <span className={"ebloom-text"} style={{marginLeft:10, fontSize: legendFontSize}}>{score + (showLegendPercent ? "%" : "")}</span>
                        :
                        score === -1 ?
                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.anonymousRestriction"))}>
                                <Badge pill bg={""} className={"bg-grey text-white h-18 mt5 ml20"}>{t("manager.dashboard.anonymityRestrictionSmall")}</Badge>
                            </EbloomTooltip>
                            :
                            score === -2 ?
                                <span className={"ebloom-text ml-10"}>N/A</span>
                                :
                                <span/>
                }
                {
                    showRepartition &&
                    <span className={"ebloom-text"} style={{marginLeft:5, fontSize:legendFontSize, marginTop:5, float:"right", textAlign:"right"}}>{"(" + nbResponse + "/" + total + ")"}</span>

                }
            </div>
        </div>
    )
}

EbloomSmileyProgressBar.propTypes = {
    nbResponse:PropTypes.number,
    total:PropTypes.number,
    staticSmiley:PropTypes.bool,
    customSmiley:PropTypes.string,
    customColor:PropTypes.string,
    showRepartition:PropTypes.bool,
    barWidth:PropTypes.number,
    barHeight:PropTypes.number,
    space:PropTypes.number,
    avg:PropTypes.number,
    showSmiley:PropTypes.bool,
    legendFontFamily:PropTypes.string,
    legendFontSize:PropTypes.number,
    showLegendPercent:PropTypes.bool,
    showMinimumView:PropTypes.bool,
    isHeatMap:PropTypes.bool,
}

EbloomSmileyProgressBar.defaultProps = {
    staticSmiley:true,
    showRepartition:false,
    barWidth:75,
    barHeight:8,
    space:0,
    showSmiley:true,
    legendFontFamily:"Lato",
    legendFontSize:12,
    showLegendPercent:true,
    showMinimumView:false,
    isHeatMap:false,
}

export default EbloomSmileyProgressBar;