
export const manageUserSteps = [
    {
        title: "admin.productTour.manageUsers.step1.title",
        content: "admin.productTour.manageUsers.step1.content",
        target: '[data-users="dots"]',
        placement: 'left',
        disableBeacon : true,
        disableNext : true,
        nextText : "admin.productTour.manageUsers.step1.nextText",
    },
    {
        title: "admin.productTour.manageUsers.step2.title",
        content: "admin.productTour.manageUsers.step2.content",
        target: '[data-users="roles"]',
        placement: 'left',
        disableBeacon : true,
        spotlightClicks : false,
        spotlightPadding : 7 ,
    },
    {
        title: "admin.productTour.manageUsers.step3.title",
        content: "admin.productTour.manageUsers.step3.content",
        target: '[data-users="reinviteUsers"]',
        placement: 'left',
        disableBeacon : true,
        spotlightClicks : false,

    },
    {
        title: "admin.productTour.manageUsers.step4.title",
        content: "admin.productTour.manageUsers.step4.content",
        target: '[data-tour="buttonUsersView-0"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 1,
        disableScrolling : true
    },
    {
        title: "admin.productTour.manageUsers.step5.title",
        content: "admin.productTour.manageUsers.step5.content",
        target: '[data-tour="buttonUsersEdit-0"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 1,
        disableScrolling : true,
        styles: {
            options : {
                zIndex : 1500
            }
        },
        disableNext : true,
        nextText : "admin.productTour.manageUsers.step5.nextText",

    },
    {
        title: "admin.productTour.manageUsers.step6.title",
        content: "admin.productTour.manageUsers.step6.content",
        target: '#roles-user',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.manageUsers.step7.title",
        content: "admin.productTour.manageUsers.step7.content",
        target: '[data-users="exportExcel"]',
        placement: 'left',
        disableBeacon : true

    },
    {
        title: "admin.productTour.manageUsers.step8.title",
        content: "admin.productTour.manageUsers.step8.content",
        target: '[data-users="dots"]',
        placement: 'left',
        disableBeacon : true,
        disableNext : true,
        nextText : "admin.productTour.manageUsers.step8.nextText",

    },
    {
        title: "admin.productTour.manageUsers.step9.title",
        content: "admin.productTour.manageUsers.step9.content",
        target: '[data-users="updateUsers"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding : 7 ,

    },
    {
        title: "admin.productTour.manageUsers.step10.title",
        content: "admin.productTour.manageUsers.step10.content",
        target: '[data-users="template"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 0,
        styles: {
            options : {
                zIndex: 1500
            }
        }
    },
    {
        title: "admin.productTour.manageUsers.step11.title",
        content: "admin.productTour.manageUsers.step11.content",
        target: '[data-users="chooseFile"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 4,
        styles: {
            options : {
                zIndex: 1500
            }
        }

    },{
        title: "admin.productTour.manageUsers.step12.title",
        content: "admin.productTour.manageUsers.step12.content",
        target: '[data-tour="saveBtnSideForm"]',
        placement: 'left',
        placementBeacon: "top",
        disableBeacon : false,
        spotlightPadding: 3,
        styles: {
            options : {
                zIndex : 1500
            }
        },
        disableNext : false,
        disableSkip : true,
        nextText : "admin.productTour.manageUsers.step12.nextText",

    },
    {
        title: "admin.productTour.manageUsers.step13.title",
        content: "admin.productTour.manageUsers.step13.content",
        target: '#stepUpdateUser0',
        placement: 'top',
        disableBeacon : false,

    },
    {
        title: "admin.productTour.manageUsers.step14.title",
        content: "admin.productTour.manageUsers.step14.content",
        target: '#stepUpdateUser1',
        placement: 'top',
        disableBeacon : false,

    },
    {
        title: "admin.productTour.manageUsers.step15.title",
        content: "admin.productTour.manageUsers.step15.content",
        target: '#stepUpdateUser2',
        placement: 'top',
        disableBeacon : false,

    },

]