import React, {useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import HttpApi from "../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import LoadingView from "../../../../Core/Layouts/LoadingView";


const Statistic = (props) => {
    const [loadingAirtable, setLoadingAirtable] = useState(false);


    const reloadAirtable = () => {
        if(!loadingAirtable){
            setLoadingAirtable(true);
            HttpApi.get("/statistic/reloadAirtable").then(() => {
                notifySuccess("Airtable updated");
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoadingAirtable(false);
            })
        }
    }

    const reloadWeeklyAirtable = () => {
        HttpApi.get("/statistic/reloadWeeklyAirtable").then(() => {
            notifySuccess("Weekly airtable updated");
        }).catch(error => {
            notifyError(error);
        })
    }

    return(
        <CardDefault title={"Statistics"} showExtraRightButton extraRightButtonText={"Reload weekly airtable"} handleExtraRightButton={reloadWeeklyAirtable} extraRightButtonVariant={"purple"} handleRightButton={reloadAirtable} buttonText={loadingAirtable ? <LoadingView size={16}/> : "Reload monthly airtable"}>
            <LoadingView />
        </CardDefault>
    )
}

export default Statistic;