import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import UsersIcon from '@mui/icons-material/RecentActorsOutlined';
import IntegrationIcon from "@mui/icons-material/CameraOutlined";
import FrequencyIcon from '@mui/icons-material/TuneOutlined';
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import PlaylistIcon from '@mui/icons-material/SubscriptionsOutlined';
import LibraryIcon from '@mui/icons-material/ListOutlined';
import PlannerIcon from '@mui/icons-material/DateRangeOutlined';
import Login from "../../../login/login";
import FAQIcon from '@mui/icons-material/ContactSupport';
import ProfileIcon from "@mui/icons-material/PersonOutline";
import MyBloomIcon from '@mui/icons-material/BarChartOutlined';
import LogoutIcon from "@mui/icons-material/ExitToApp";
import TeamsIcon from '@mui/icons-material/PeopleOutline';
import SubmitCustomIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FreeTrialModal from "../../Core/Modules/Modal/FreeTrialModal";
import {Collapse, ListItemButton} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {useNavigationTo} from "../../../hooks/navigationHook";
import {useUserData} from "../../../hooks/userHook";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import MiniProductTourMap from "../ProductTour/MiniProductTourMap";
import {ProductTourContext} from "../../../Utils/Context/ProductTourContext";
import HttpApi from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";
import CategoryIcon from "@mui/icons-material/CategoryRounded";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";


function NavDrawerAdmin(props) {
    const { container } = props;
    const navigation = useNavigationTo();
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [allTourDone, setAllTourDone] = useState(false);
    const [customCategoriesActivated, setCustomCategoriesActivated] = useState(false);

    const {selectedIndex, isFree, bp} = props;

    const {tourActivated} = useContext(ProductTourContext)

    const t = useTranslation();

    const user = useUserData();

    const email = user.name || user.email;

    useEffect(() => {
        HttpApi.getV2("/settings/getForCompany/customCategories").then(response => {
            if(response && response.data && response.data.value > 0){
                setCustomCategoriesActivated(true)
            }else{
                setCustomCategoriesActivated(false)
            }
        }).catch(error =>{
            notifyError(error)
        })
    }, []);


    useEffect(() => {
            if(selectedIndex === 17 || selectedIndex === 12 || selectedIndex === 2 || selectedIndex === 26 ){
                setOpen(true);
            }
        }, [props.menu, selectedIndex]
    );

    const handleOpenModal = () => {
        setShow(true);
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleListItemClick = (url) => event => {
        navigation.to(url);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const titleMenu = () => {
        switch (props.menu) {
            case "settings":
                return _.upperFirst(t("admin.settings"));
            case "questions":
                return _.upperFirst(t("core.questions"));
            case "account":
                return email;
            case "collaborative":
                return _.upperFirst(t("core.collaborative.poll"));
            default:
                return "Menu";
        }
    };

    const drawer = (
        <div>
            <div className={"mt-50"}/>
            {
                (tourActivated && !allTourDone) &&
                <div>
                    <MiniProductTourMap setAllTourDone={setAllTourDone}/>
                </div>
            }

            <List subheader={<ListSubheader>{titleMenu()}</ListSubheader>}>
                {
                    (props.menu === "settings") &&
                        <div>
                            <ListItemButton key="account" selected={selectedIndex === 25} onClick={handleListItemClick("/admin/account")}>
                                <ListItemIcon><MenuBookOutlinedIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("admin.account.account"))}/>
                            </ListItemButton>
                            <ListItemButton key={"frequency"} onClick={isFree ? handleOpenModal : handleListItemClick("/admin/frequency")} selected={selectedIndex === 9}>
                                <ListItemIcon><FrequencyIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("admin.frequency"))}/>
                                {
                                    isFree &&
                                    <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                }
                            </ListItemButton>
                            {
                                Login.hasRole("global_admin") &&
                                <ListItemButton key="employee" selected={selectedIndex === 1} onClick={handleListItemClick("/admin/users")}>
                                    <ListItemIcon><UsersIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.user.users"))}/>
                                </ListItemButton>
                            }

                            {
                                (Login.hasModule("very_happy") || Login.hasModule("super_happy") || Login.hasModule("free")) && Login.hasRole("global_admin")  &&
                                <ListItemButton key={"teams"} onClick={handleListItemClick("/admin/teams")} selected={selectedIndex === 10}>
                                    <ListItemIcon><TeamsIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("admin.teams"))}/>
                                </ListItemButton>
                            }
                            {
                                (Login.hasModule("very_happy") || Login.hasModule("super_happy") || Login.hasModule("free")) && Login.hasRole("global_admin")  &&
                                <ListItemButton key="filters" selected={selectedIndex === 31} onClick={isFree ? handleOpenModal :handleListItemClick("/admin/filters")}>
                                    <ListItemIcon><FilterListOutlinedIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.filters"))}/>
                                    {
                                        isFree &&
                                        <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                    }
                                </ListItemButton>
                            }
                            {
                                Login.hasRole("global_admin") &&
                                <ListItemButton onClick={handleClick}>
                                    <ListItemIcon>
                                        <BuildOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.general"))} />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                            }
                            {
                                Login.hasRole("global_admin") &&
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton key="communication" selected={selectedIndex === 12} onClick={handleListItemClick("/admin/communication")} className={"pl-32"}>
                                            <ListItemIcon><SmsIcon/></ListItemIcon>
                                            <ListItemText primary={_.capitalize(t("admin.communication"))}/>
                                        </ListItemButton>
                                        <ListItemButton key="integration" selected={selectedIndex === 2} onClick={handleListItemClick("/admin/integration")} className={"pl-32"}>
                                            <ListItemIcon><IntegrationIcon/></ListItemIcon>
                                            <ListItemText primary={_.capitalize(t("core.integration"))}/>
                                        </ListItemButton>
                                        <ListItemButton key="identity" selected={selectedIndex === 17} className={"pl-32"} onClick={handleListItemClick("/admin/identity")}>
                                            <ListItemIcon>
                                                <CreateOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={_.upperFirst(t("admin.identity.titleSm"))} />
                                        </ListItemButton>
                                        {
                                            customCategoriesActivated &&
                                            <ListItemButton key="customCategories" selected={selectedIndex === 26} className={"pl-32"} onClick={handleListItemClick("/admin/customCategories")}>
                                                <ListItemIcon><CategoryIcon/></ListItemIcon>
                                                <ListItemText primary={_.upperFirst(t("admin.customCategories.title"))} />
                                            </ListItemButton>
                                        }

                                    </List>
                                </Collapse>
                            }
                            {
                                Login.hasRole("global_admin") &&
                                <ListItemButton key="faq" selected={selectedIndex === 21} onClick={handleListItemClick("/admin/faq")}>
                                    <ListItemIcon><FAQIcon/></ListItemIcon>
                                    <ListItemText primary={_.toUpper(t("admin.faq.title"))}/>
                                </ListItemButton>
                            }
                            {
                                Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") &&
                                <ListItemButton key="identity" selected={selectedIndex === 17} onClick={handleListItemClick("/admin/identity")}>
                                    <ListItemIcon>
                                        <CreateOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={_.upperFirst(t("admin.identity.titleSm"))} />
                                </ListItemButton>
                            }
                            {
                                Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") &&
                                <ListItemButton key="customCategories" selected={selectedIndex === 26} className={"pl-32"} onClick={handleListItemClick("/admin/customCategories")}>
                                    <ListItemIcon><CategoryIcon/></ListItemIcon>
                                    <ListItemText primary={_.upperFirst(t("admin.customCategories.title"))} />
                                </ListItemButton>
                            }
                        </div>
                }
                {
                    (props.menu === "questions") &&
                        <div>
                            <ListItemButton key="library" selected={selectedIndex === 3} onClick={isFree ? handleOpenModal :handleListItemClick("/admin/questions/library")}>
                                <ListItemIcon><LibraryIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("core.playlist.library"))}/>
                                {
                                    isFree &&
                                    <ListItemIcon style={{marginRight:-10}}><LockOutlinedIcon/></ListItemIcon>
                                }
                            </ListItemButton>
                            <ListItemButton key="playlist" selected={selectedIndex === 4} onClick={handleListItemClick("/admin/questions/playlist")}>
                                <ListItemIcon><PlaylistIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("core.playlist.playlists"))}/>
                            </ListItemButton>
                            <ListItemButton key="planner" selected={selectedIndex === 8} onClick={handleListItemClick("/admin/questions/planner")}>
                                <ListItemIcon><PlannerIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("core.playlist.planner"))}/>
                            </ListItemButton>
                            <ListItemButton key="submitCustom" selected={selectedIndex === 16} onClick={isFree ? handleOpenModal : handleListItemClick("/admin/questions/submit")}>
                                <ListItemIcon><SubmitCustomIcon/></ListItemIcon>
                                <ListItemText primary={_.upperFirst(t("admin.submitCustomTitle"))}/>
                                {
                                    isFree &&
                                    <ListItemIcon style={{marginRight:-10}}><LockOutlinedIcon/></ListItemIcon>
                                }
                            </ListItemButton>
                        </div>
                }
                {
                    (props.menu === "account") &&
                    <div>
                        <ListItemButton key={5} onClick={handleListItemClick("/profile")} selected={selectedIndex === 5}>
                            <ListItemIcon><ProfileIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.profile.profile"))}/>
                        </ListItemButton>
                        <ListItemButton key={6} onClick={handleListItemClick("/employee/dashboard")} selected={selectedIndex === 6}>
                            <ListItemIcon><MyBloomIcon/></ListItemIcon>
                            <ListItemText primary={"MyBloom"}/>
                        </ListItemButton>
                        <ListItemButton key={8} onClick={Login.logout}>
                            <ListItemIcon><LogoutIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize(t("core.login.logout"))}/>
                        </ListItemButton>
                    </div>
                }

            </List>

        </div>
    );

    return (
        <div className={"flex navDrawer"}>
            {
                bp.pageSize <= bp.breakpoints.tablet ?
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="left"
                        open={props.mobileOpen}
                        onClose={props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                    :

                    <Drawer className={"w-240 shrink-0"}
                            variant="permanent"
                            anchor="left">
                        {drawer}
                    </Drawer>
            }



            <FreeTrialModal show={show} handleClose={handleCloseModal}/>
        </div>
    );
}

NavDrawerAdmin.defaultProps = {
    menu: "settings",
    isMobile:true,
};

NavDrawerAdmin.propsType = {
    menu: PropTypes.string,
    isMobile:PropTypes.bool
};

export default NavDrawerAdmin;
