import React, {useState} from "react";
import PropTypes from "prop-types"
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import BubbleChat from "../../../../../../Admin/Pages/WeBloom/Polls/Results/Modules/BubbleChat";
import {ReactComponent as LikeIcon} from "../../../../../../Core/symbols/webloom/white/like-white-nofill.svg";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const AnswerMessage = (props) => {

    //state
    const [isLiked, setIsLiked] = useState(false);
    const {survey} = props;

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers
    const handleLike = () => {
        setIsLiked(!isLiked);
    }

    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    //useEffect

    return (
        <div className={"cursorDefault"}>
            <Row className={"mb-20"}>
                <Col>
                    <div>
                        <span className={"text-purple"}>{displayDate()}</span>
                    </div>
                </Col>
            </Row>
            <div className={"w-1/2 m-auto mt-20"}>
                <BubbleChat title={survey.title} text={survey.question.title} attachmentUrl={survey.attachment_url} imageUrl={survey.image_url} isRead={true} className={"my-5"}/>
                <LikeIcon className={isLiked ? "purple-icon-fill-stroke h-25 w-25 pointer" : "purple-icon-stroke h-25 w-25 pointer"} onClick={handleLike}/>
            </div>
        </div>
    )
}

AnswerMessage.propTypes = {
    survey:PropTypes.object.isRequired,
}

AnswerMessage.defaultProps = {}
export default AnswerMessage