import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import PollFrame from "../../../../../Admin/Pages/WeBloom/Polls/Modules/PollFrame";
import {PollTypes, SurveyStatus} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import AnswerPoll from "./Modules/AnswerPoll";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import HttpApi from "../../../../../../httpApi";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {GlobalContext} from "../../../../../../Utils/Context/GlobalContext";
import WeBloomNotFound from "../../WeBloomNotFound";

const WeBloomAnswerPoll = (props) => {
    //context
    const {historyStack,setShowModalGoogle,setGoogleReviewData} = useContext(GlobalContext)

    //state
    const [survey, setSurvey] = useState();
    const [loading, setLoading] = useState(false);
    const [linkType, setLinkType] = useState("valid");

    //hooks
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const t = useTranslation();

    //getters

    //posters
    const handleSave = () => {
        if (!loading) {
            validateSurvey().then(() => {
                setLoading(true);
                HttpApi.post("/collaborative/insertAnswer", {
                    questions: survey.questions,
                    idSurvey: survey.id
                }).then((response) => {
                    if(response && response.data){
                        const data = {
                            activated : response.data.isValidForModal,
                            cb: response.data.cb,
                            participation : response.data.participation
                        }
                        setGoogleReviewData(data)
                        setShowModalGoogle(data.activated)
                    }
                    notifySuccess(_.upperFirst(t("webloom.notification.saveAnswersSuccess")));
                    if(historyStack[historyStack.length-2] === "/employee/dashboard"){
                        navigate("/employee/dashboard");
                    }else{
                        navigate("/webloom/dashboard");
                    }

                }).catch(error => {
                    notifyError(error);
                }).finally(() => {
                    setLoading(false);
                })
            }).catch(error => {
                createErrorNotification(_.upperFirst(t("core.notification.error")), error);
            })

        }

    }

    //handlers
    const handleChange = (newQuestion) => {
        setSurvey(prevState => {
            let survey = {...prevState};
            const index = survey.questions.findIndex(el => el.id === newQuestion.id);
            survey.questions[index] = newQuestion;
            return survey;
        })
    }

    const validateSurvey = () => {
        return new Promise((resolve, reject) => {
            survey.questions.forEach(question => {
                if (question.type === PollTypes.YES_NO || question.type === PollTypes.UNIQUE_CHOICE) {
                    const uniqueChoiceValidation = validateUniqueChoice(question);
                    if (!uniqueChoiceValidation.passing) {
                        reject(uniqueChoiceValidation.message);
                    }
                } else if (question.type === PollTypes.MULTIPLE_CHOICES) {
                    const multipleChoiceValidation = validateMultipleChoice(question);
                    if (!multipleChoiceValidation.passing) {
                        reject(multipleChoiceValidation.message);
                    }
                } else if (question.type === PollTypes.STAR_RATING) {
                    const startRatingsValidation = validateStarRatings(question);
                    if (!startRatingsValidation.passing) {
                        reject(startRatingsValidation.message);
                    }
                } else {
                    const openQuestionValidation = validateOpenQuestion(question);
                    if (!openQuestionValidation.passing) {
                        reject(openQuestionValidation.message);
                    }
                }
            })
            resolve(true);
        })
    }

    const validateUniqueChoice = (question) => {
        if (question.is_required) {
            if (question.options.filter(el => el.selected > 0).length !== 1) {
                return {
                    passing: false,
                    message: _.upperFirst(t("webloom.notification.requiredError", {question: question.title}))
                };
            } else if (question.show_other && question.define_other && question.options.filter(el => el.id === 0 && el.selected === 1).length > 0) { //if define other is selected
                return {
                    passing: question.options.filter(el => el.id === 0 && el.text).length > 0,
                    message: _.upperFirst(t("webloom.defineOtherError", {question: question.title}))
                }; //true if value entered in define other, otherwise false
            }
            return {passing: true, message: ""};
        } else if (question.show_other && question.define_other && question.options.filter(el => el.id === 0 && el.selected === 1).length > 0) { //if define other is selected
            return {
                passing: question.options.filter(el => el.id === 0 && el.text).length > 0,
                message: _.upperFirst(t("webloom.defineOtherError", {question: question.title}))
            }; //true if value entered in define other, otherwise false
        }
        return {
            passing: question.options.filter(el => el.selected > 0).length <= 1,
            message: _.upperFirst(t("webloom.notification.uniqueChoiceError", {question: question.title}))
        };
    }

    const validateMultipleChoice = (question) => {
        if (question.is_required) {
            if (question.options.filter(el => el.selected > 0).length === 0 || question.options.filter(el => el.selected).length > question.nb_items) {
                return {
                    passing: false,
                    message: _.upperFirst(t("webloom.notification.requiredAndMaxAnswersError", {
                        question: question.title,
                        max: question.nb_items
                    }))
                };
            } else if (question.show_other && question.define_other && question.options.filter(el => el.id === 0 && el.selected === 1).length > 0) { //if define other is selected
                return {
                    passing: question.options.filter(el => el.id === 0 && el.text).length > 0,
                    message: _.upperFirst(t("webloom.defineOtherError", {question: question.title}))
                }; //true if value entered in define other, otherwise false
            }
            return {passing: true, message: ""};
        } else if (question.show_other && question.define_other && question.options.filter(el => el.id === 0 && el.selected === 1).length > 0) { //if define other is selected
            return {
                passing: question.options.filter(el => el.id === 0 && el.text).length > 0,
                message: _.upperFirst(t("webloom.defineOtherError", {question: question.title}))
            }; //true if value entered in define other, otherwise false
        }
        return {
            passing: question.options.filter(el => el.selected).length <= question.nb_items,
            message: _.upperFirst(t("webloom.mawAnswersError", {question: question.title, max: question.nb_items}))
        };
    }

    const validateStarRatings = (question) => {
        if (question.is_required) {
            return {
                passing: question.score && question.score <= question.nb_items,
                message: _.upperFirst(t("webloom.notification.requiredError", {question: question.title}))
            };
        }
        return {
            passing: !question.score || question.score <= question.nb_items,
            message: _.upperFirst(t("webloom.notification.incorrectValueError", {question: question.title}))
        };

    }

    const validateOpenQuestion = (question) => {
        if (question.is_required) {
            return {
                passing: question.answer && question.answer.trim().length > 0,
                message: _.upperFirst(t("webloom.notification.requiredError", {question: question.title}))
            };
        }
        return {passing: true, message: ""};
    }

    //useEffect
    useEffect(() => {
        if (location && location.state) {
            setSurvey(location.state);
        }else if(id){
            HttpApi.get("/collaborative/getForUser/" + id).then(response => {
                if(response && response.data && typeof response.data === "string"){
                    setLinkType(response.data);
                }else if(response && response.data){
                    setSurvey(response.data);
                }else{
                    navigate("/webloom/dashboard");
                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            navigate("/webloom/dashboard");
        }
    }, [])

    return (
        <>
            {
                (linkType === "alreadyAnswered" || linkType === "isExpired" || linkType === "isNotAvailable") ?
                    <WeBloomNotFound type={linkType}/>
                    :
                    <PollFrame status={SurveyStatus.RUNNING} survey={survey}>
                        {
                            (survey) &&
                            <AnswerPoll survey={survey} loading={loading} handleChange={handleChange} handleSave={handleSave}/>
                        }
                    </PollFrame>
            }
        </>

    )


}

WeBloomAnswerPoll.propTypes = {
    survey: PropTypes.object
}

export default WeBloomAnswerPoll;