import React from "react";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import FilterEditor from "./FilterEditor";



const FilterEditorPage = (props) =>{

    return (
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <FilterEditor companyToEdit={companyToEdit} {...props}/>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
};

export default FilterEditorPage;


