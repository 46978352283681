import React from "react";
import PropTypes from "prop-types";
import {getTranslations} from "../../../../lang/translations";
import _ from "lodash";
import EbloomSpeedometerV2 from "./EbloomSpeedometerV2";
import {useTranslation} from "react-multi-lang";
const EbloomYesNoSpeedometer = (props) =>  {

    const {data, total, width, lang} = props;

    const t = useTranslation();

    const getPercentLeft = () => {
        return !total ? 50 : Math.round((data[1].selected/total)*100);
    }

    const getPercentRight = () => {
        return !total ? 50 : 100-getPercentLeft()-getPercentNA();
    }

    const getPercentNA = () => {
        if(data[2] && data[2].selected){
            return Math.round((data[2].selected/total)*100)
        }
        return 0;
    }

    const getMaxValue = () => {
        return getPercentLeft() + getPercentRight();
    }

    const getValue = () => {
        const middleValue = getMaxValue()/2;
        if(getPercentLeft() > middleValue){
            return 50+(50*((middleValue-(getPercentLeft()-middleValue))/getMaxValue()));
        }else if(getPercentRight() > middleValue){
            return 50+(50*((middleValue+(getPercentRight()-middleValue))/getMaxValue()));
        }
        return 50+(50*(middleValue/getMaxValue()));
    }

    return(
        <div>
            <div className={"flex justify-center items-center"}>
                <div className={"text-contrast-purple font-semibold mr-50"}>
                    <div className={"ebloom-xtra-large-text text-center"}>{data[1].text}</div>
                    <div className={"ebloom-very-large-text text-center"}>{!total ? _.upperFirst(t("webloom.NA")) : (getPercentLeft() + "%")}</div>
                </div>
                <EbloomSpeedometerV2 score={getValue()} width={width} type={"yesNo"}/>
                <div className={"text-contrast-purple ml-50 font-semibold"}>
                    <div className={"ebloom-xtra-large-text text-center"}>{data[0].text}</div>
                    <div className={"ebloom-very-large-text text-center"}>{!total ? _.upperFirst(t("webloom.NA")) : getPercentRight() + "%"}</div>
                </div>
            </div>
            {
                data[2] &&
                <div className={"text-center text-contrast-purple ebloom-small-text -mt-50"}>
                    {_.upperFirst(getTranslations(lang, "webloom.NA")) + " : " + getPercentNA() + "%"}
                </div>
            }

        </div>

    )
}

EbloomYesNoSpeedometer.propTypes = {
    fluidWidth: PropTypes.bool,
    width:PropTypes.number,
    height: PropTypes.number,
    valueTextFontSize:PropTypes.number,
    ringWidth:PropTypes.number,
    needleHeightRatio:PropTypes.number,
    paddingVertical:PropTypes.number,
}

EbloomYesNoSpeedometer.defaultProps = {
    fluidWidth:false,
    width:250,
    height:200,
    ringWidth:50,
    needleHeightRatio:0.7,
}

export default EbloomYesNoSpeedometer;