import React, {useEffect, useState} from 'react'
import {getLanguage, useTranslation} from "react-multi-lang";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import _ from "lodash";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import FaqModel from "../../../../../models/Faq";
import EditFaq from "./EditFaq";


const FAQEditor = (props) => {

    //state
    const [faqList, setFaqList] = useState([]);
    const [faqFocused, setFaqFocused] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [onEdit, setOnEdit] = useState(false);
    const [onCreate, setOnCreate] = useState(false);

    //props

    //hooks
    const t = useTranslation();

    //getters
    const fetchFaqQuestions = () => {

        HttpApi.get("/faq/getAll").then(response => {
            if(response && response.data){
                setFaqList(response.data)
            }
        }).catch(error => {
            notifyError(error);
        });
    }

    //posters

    //handlers
    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleEdit = (foo,toEdit) => event =>{
        const list = faqList.filter(value => value.id === toEdit.id)
        setFaqFocused(list)
        handleClose()
        setTimeout(() =>{
            setOnEdit(true)
        },60)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleDelete = () => {
        fetchFaqQuestions()
    }

    const getLangFaqList = () => {
        const langUser = getLanguage()
        return faqList.filter(item => item.lang === langUser)
    }


    const handleCreate= () => {
        handleClose()
        setTimeout(() =>{
            setOnCreate(true)
        },60)
    }

    const handleClose= () => {
        setOnEdit(false)
        setOnCreate(false)
        fetchFaqQuestions()

    }

    //useEffect

    useEffect(() => {
        fetchFaqQuestions()
    }, [])

    return(
        <>
            <CardDefault title={_.toUpper(t("backoffice.faq.title"))} showButton={!onCreate} handleRightButton={handleCreate}>
                {onCreate ? <EditFaq handleClose={handleClose} onCreate={true}/> : onEdit ? <EditFaq handleClose={handleClose} listEdit={faqFocused} onCreate={false}/> : null}
                <CardDefault title={_.capitalize(t("backoffice.faq.listFaq"))} showButton={false}>
                    <TableViewV2 model={FaqModel}  items={getLangFaqList()} handleEdit={handleEdit} handleDelete={handleDelete} tablePage={tablePage}  handleTablePage={handleTablePage}/>
                </CardDefault>
            </CardDefault>
        </>
    )
}

export default FAQEditor