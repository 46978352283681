import React, {useRef} from "react";
import {Overlay, Popover} from "react-bootstrap";
import * as PropTypes from "prop-types";
import {useOutsideAlerter} from "../../../../hooks/outsideAlerterHook";


const EbloomPopoverV2 = (props) => {
    const ref = useRef(null);


    const {show, target, handleClose, trigger, title, children, placement, showHeader, width}= props;


    const resetAndClose = () => {
        handleClose();
    }

    useOutsideAlerter(ref, () => {resetAndClose()});

    return(
        <div ref={ref}>
            {trigger}
            <Overlay
                show={show}
                target={target}
                placement={placement}
                container={ref.current}
                containerPadding={20}

            >
                <Popover id="popover-ebloom" style={{width:width, maxWidth:width,zIndex:20}} >
                    {
                        showHeader &&
                        <Popover.Header as="h3">{title}</Popover.Header>
                    }

                    <Popover.Body style={{padding:0}}>
                        {children}
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>

    )
}

EbloomPopoverV2.propTypes = {
    title: PropTypes.string,
    showHeader:PropTypes.bool,
    trigger:PropTypes.any.isRequired,
    show:PropTypes.bool.isRequired,
    handleClose:PropTypes.func.isRequired,
    target:PropTypes.any,
    placement:PropTypes.string.isRequired,
    width:PropTypes.number,
    overrideClose: PropTypes.bool,
};

EbloomPopoverV2.defaultProps = {
    showHeader:false,
    width:300,
    overrideClose:false,
}

export default EbloomPopoverV2;