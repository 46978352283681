import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Statistic from "./Statistic";

const StatisticPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <Statistic companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
}

export default StatisticPage;