import React, {useState} from "react";
import HeaderBackoffice from './HeaderBackoffice'
import NavDrawerBackoffice from "./NavDrawerBackoffice";
import {Row} from "react-bootstrap";
import {Colors} from "../../../Utils/Colors";
import moment from "moment";

const DisplayBackofficeDashboardView = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [companyToEdit, setCompanyToEdit] = useState(localStorage.getItem("ebloom-companyToEdit") ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")) : null);
    const [unlockedBackoffice, setUnlockedBackoffice] = useState(localStorage.getItem("ebloom-unlockedBackoffice") === "true" || false);
    //const [teamToDisplay, setTeamToDisplay] = useState(null);
    const [startDate, setStartDate] = useState(new Date(moment().subtract(3, "month").format()));
    const [endDate, setEndDate] = useState(new Date(moment().format()));
    const [formattedStartDate, setFormattedStartDate] = useState(moment().subtract(3, "month").format("YYYY-MM-DD"));
    const [formattedEndDate, setFormattedEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [timeFilterVersion, setTimeFilterVersion] = useState(0);

    const handleUnlockedBackoffice = (value) => {
        setUnlockedBackoffice(value);
    }

    const switchCompany = (company) => {
        setCompanyToEdit(company)
    }

    const handleSwitchTeam = (team) => {
        //setTeamToDisplay(team);
    };

    const handleSwitchStartDate = (date) => {
        setStartDate(date);
        setFormattedStartDate(date ? moment(date).format("YYYY-MM-DD") : null);
    }

    const handleSwitchEndDate = (date, reload = false) => {
        setEndDate(date);
        setFormattedEndDate(date ? moment(date).format("YYYY-MM-DD") : null);
        if(date || reload){
            setTimeFilterVersion(timeFilterVersion + 1);
        }
    }

    const {bp, menu} = props;

    const drawMargin = menu !== "noMenu" && bp.pageSize > bp.breakpoints.tablet ? 240 : 0;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return(
        <div style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE, minHeight:"100vh"}}>
            <Row className={"mb-50 mr-0 ml-240"}>
                {
                    menu !== "noMenu" &&
                    <NavDrawerBackoffice style={{width:`calc(100% - ${drawMargin}px)`,marginLeft:drawMargin}} companyToEdit={companyToEdit} mobileOpen={mobileOpen} unlockedBackoffice={unlockedBackoffice} handleDrawerToggle={handleDrawerToggle} {...props}/>

                }
                <HeaderBackoffice unlockedBackoffice={unlockedBackoffice} handleUnlockedBackoffice={handleUnlockedBackoffice} switchCompany={switchCompany} handleDrawerToggle={handleDrawerToggle} handleSwitch={handleSwitchTeam} startDate={startDate} endDate={endDate} handleSwitchStartDate={handleSwitchStartDate} handleSwitchEndDate={handleSwitchEndDate} {...props}/>
            </Row>
            <Row style={{width:`calc(100% - ${drawMargin}px)`,marginLeft:drawMargin}}>
                {props.children(companyToEdit, unlockedBackoffice, formattedStartDate, formattedEndDate, timeFilterVersion)}
            </Row>
        </div>
    )
}

DisplayBackofficeDashboardView.defaultProps = {
    menu:"backoffice"
}

export default DisplayBackofficeDashboardView;
