import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import FilterModel from "../../../../models/Filter";
import ModelForms from "../../../Core/Modules/Input/ModelForms";
import _ from "lodash";
import HttpApi from "../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../Utils/Notification";
import TableViewV2 from "../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import EbloomJumbotron from "../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";
import {FullContrastPurpleTextField, getUrl} from "../../../../Utils/Global";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ListView from "../../../Core/Modules/Views-Tables-Lists/ListView";
import UserModel from "../../../../models/User";
import {Divider} from "@mui/material";
import {useNavigate} from "react-router-dom";

const FiltersList = (props) => {

    //state
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [tablePage, setTablePage] = useState(0);
    const [sideFormUsersOpen, setSideFormUsersOpen] = useState(false);
    const [userNotInFilter, setUserNotInFilter] = useState([]);
    const [filters, setFilters] = useState([]);
    const [sideFormEdit, setSideFormEdit] = useState(false);

    const [filter, setFilter] = useState({id:null, name:"", id_company_filter:-1, company_filter:"", users:[], created_at:null});

    const {companyToEdit} = props;

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();

    //getters
    const fetchFilters = () => {
        HttpApi.getV2(FilterModel.crud.getAll).then(response => {
            if(response && response.data){
                const filtersList = response.data;
                setFilters(filtersList);
                if(!!filter?.id){
                    setFilter(filtersList.find(el => el.id === filter.id))
                }
                FilterModel.initializeOptions(true);

            }else{
                setFilters([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchUserNotInFilter = (id) => {
        HttpApi.getV2("/filters/getEmployeesNotInFilter/" + id).then(response => {
            if(response && response.data){
                setUserNotInFilter(response.data)
            }
        }).catch(error => {
            notifyError(error)
        })
    }

    //posters
    const saveFilter = () => {
        const url = filter.id ? FilterModel.crud.update : FilterModel.crud.create;
        const HttpApiCall = filter.id ? HttpApi.put : HttpApi.postV2;
        let data = filter;
        let isValid = true;
        if(data.id_company_filter !== 0){
            const filtered = filters.filter(el => el.id_company_filter === data.id_company_filter)
            if(filtered.length > 0){
                for (let i = 0; i < filtered.length; i++) {
                    if(filtered[i].name.trim() === data.name.trim()){
                        isValid = false;
                        break;
                    }
                }
            }
        }

        if (!isValid){
            createErrorNotification(_.upperFirst(t("core.notification.error")) ,"Name already taken")
        }else{
            if(props.companyToEdit){
                data.id_company = props.companyToEdit.id;
            }
            if(data.id_company_filter === 0){
                data.id_company_filter = null;
            }
            HttpApiCall(url, data).then(() => {
                setSideFormOpen(false);
                setSideFormEdit(false)
                resetFilter();
                fetchFilters();
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    //handlers

    const handleDelete = () => {
        fetchFilters()
    }

    const handleAddUser = (user) => event => {
        event.preventDefault();
        const data = {
            id_filter:filter.id,
            id_user:user.id
        }
        if(filter.users.filter(el => el.id === user.id).length === 0){
            HttpApi.postV2("/filters/addUser", data).then(() => {
                setFilter(prevState => {
                    let filter = {...prevState};
                    filter.users.push(user);
                    return filter;
                })
                setUserNotInFilter(prevState => {
                    let list = prevState
                    list = list.filter(el => el.id!== user.id);
                    return list
                })
                fetchFilters()
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification(_.upperFirst(t("core.notification.error")) ,_.upperFirst(t("core.userAlreadyInFilter")));
        }

    }

    const handleRemoveUser = (user)=> event => {
        event.preventDefault();
        const data = {
            id_filter:filter.id,
            id_user:user.id
        }
        if(filter.users.filter(el => el.id === user.id).length > 0){
            HttpApi.postV2("/filters/removeUser", data).then(() => {
                setFilter(prevState => {
                    let filter = {...prevState};
                    filter.users = filter.users.filter(el => el.id!== user.id);
                    return filter;
                })
                setUserNotInFilter(prevState => {
                    let list = prevState
                    list.push(user);
                    return list
                })
                fetchFilters()
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification(_.upperFirst(t("core.notification.error")) ,_.upperFirst(t("core.userAlreadyRemovedFilter")));
        }
    }



    const handleChange = (event,newValue,isCategory = false) => {
        event.preventDefault();
        setFilter(prevState => {
            let filter = {...prevState};
            if(isCategory){
                //Autocomplete in the ModelForm
                if(newValue !== null){
                    if(newValue.id){
                        //Existing Category
                        filter.company_filter = newValue.name;
                        filter.id_company_filter = newValue.id;
                    }else{
                        //New Category
                        filter.company_filter = newValue.inputValue || ""
                        filter.id_company_filter = null;
                    }

                }else{
                    //New Category
                    filter.company_filter = "";
                    filter.id_company_filter = null;
                }
            }else if(!isCategory){
                //Filter name
                filter.name = event.target.value;
            }
            return filter;
        })

    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const toggleDrawerUsers = (toEdit) => event => {
        event.preventDefault();
        if(toEdit && toEdit.id && !sideFormUsersOpen){
            let filterToEdit = _.cloneDeep(toEdit);
            filterToEdit.company_filter = "";
            fetchUserNotInFilter(filterToEdit.id_company_filter)
            setFilter(filterToEdit);

        }else if(filter && sideFormUsersOpen){
            resetFilter();
        }
        setSideFormUsersOpen(!sideFormUsersOpen);
    }

    const resetFilter = () => {
        setFilter({id:null, name:"", id_company_filter:-1, company_filter:"", users:[], created_at:null});
    }

    const toggleDrawer = (open, toEdit=null) => event => {
        event.preventDefault();
        if(toEdit && toEdit.id && open){
            let filterToEdit = _.cloneDeep(toEdit);
            filterToEdit.company_filter = "";
            setFilter(filterToEdit);
        }else if(filter && !open){
            resetFilter();
        }
        setSideFormOpen(open);
    }

    const toggleDrawerEdit = (open, toEdit=null) => event => {
        event.preventDefault();
        if(toEdit && toEdit.id && open){
            let filterToEdit = _.cloneDeep(toEdit);
            setFilter(filterToEdit);
        }else if(filter && !open){
            resetFilter();
        }
        setSideFormEdit(open);
    }

    const handleRowClick = (item) => event =>  {
      event.preventDefault();
      navigate("/admin/filters/"+item.id)
    }

    //useEffect

    useEffect(() => {
        fetchFilters();
    }, [])




    FilterModel.crud.delete = "/filters/deleteSingleFilter"


    return (
        <>
            <CardDefault title={_.upperFirst(t("core.filters"))} handleRightButton={toggleDrawer(true)}>
                {
                    !filters || filters.length <= 0 ?
                        <EbloomJumbotron title={_.upperFirst(t("core.filters"))} text={_.upperFirst(t("core.createFilter"))} buttonText={_.upperFirst(t("core.create"))} handleAction={toggleDrawer(true)} />
                        :
                        <TableViewV2 model={FilterModel} items={filters} rowCursor={"pointer"} handleRowClick={handleRowClick} tablePage={tablePage} handleTablePage={handleTablePage} handleDelete={handleDelete} handleEdit={toggleDrawerEdit}  typeDetail={"custom"} iconDetail={<PersonAddAltOutlinedIcon className={"text-purple"} />} showDetail handleView={toggleDrawerUsers} handleObject={true} textDetail={_.upperFirst(t("core.addUsers"))}/>

                }
            </CardDefault>
            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} formTitle={(!filter || !filter.id ? _.upperFirst(t("core.createParam", {param:_.lowerFirst(t("core.filter"))})) : _.upperFirst(t("core.editParam", {param:_.lowerFirst(t("core.filter"))})))} handleSave={saveFilter} buttonDisabled={filter.name.trim() === "" || filter.company_filter.trim() === ""}>
                <div className={"w-470"}>
                    <ModelForms model={FilterModel} handleChange={handleChange} values={filter} companyToEdit={props.companyToEdit}/>
                </div>
            </SideForm>
            <SideForm toggleDrawer={toggleDrawerEdit} sideFormOpen={sideFormEdit} formTitle={(!filter || !filter.id ? _.upperFirst(t("core.createParam", {param:_.lowerFirst(t("core.filter"))})) : _.upperFirst(t("core.editParam", {param:_.lowerFirst(t("core.filter"))})))} handleSave={saveFilter}>
                <FullContrastPurpleTextField  value={filter.name} name={"name"}  onChange={handleChange} />
            </SideForm>
            <SideForm formTitle={"core.edit"} sideFormOpen={sideFormUsersOpen} toggleDrawer={toggleDrawerUsers} showButtons={false}>
                <div className={"flex"}>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={filter.users} handleAction={handleRemoveUser} actionType={"remove"} listTitle={_.upperFirst(t("core.usersWithThisParam", {param :_.upperFirst(filter.name)}))}/>
                    </div>
                    <Divider orientation="vertical" flexItem/>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={userNotInFilter} handleAction={handleAddUser} listTitle={_.upperFirst(t("core.usersAvailableAdd"))}/>
                    </div>
                </div>
            </SideForm>
        </>
    )
}

FiltersList.propTypes = {
    companyToEdit:PropTypes.object
}

FiltersList.defaultProps = {
    companyToEdit:null,
}
export default FiltersList