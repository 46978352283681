import {Badge, Button, Card,} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
import {FaqType} from "../../../../../Utils/Global";

const CardFAQ = (props) => {
    const {infos,handleCard} = props
    const t = useTranslation();
    const [mapBadges, setMapBadges] = useState(new Map());

    useEffect(() => {
        initColorBadge()
    }, []);

    const initColorBadge = () => {
        const map = new Map()
        const types = Object.values(FaqType)
        const colorizedBadges = ["bg-light-red","bg-best-practices","bg-logo-blue"]
        for (let i = 0; i < types.length; i++) {
            map.set(types[i],colorizedBadges[i])
        }
        setMapBadges(map)
    }

    const getColorBadges = (type) => {
      return mapBadges.get(type)
    }

    const handleClick = (id) => {
        handleCard(id)
    }
    
    const answerParser = (answer) => {
        let tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = answer;
        let text = tempDivElement.textContent || tempDivElement.innerText || ""
        return text + '...'

    }

    return (<>
            <Card key={infos.id_question} onClick={() => handleClick(infos.id)}  className={'cursor-pointer purple-border h-full'}>
                <Card.Title className={'max-h-125'}>
                    <div className={'mt-16 mx-16 mb-0'}>
                        <div className={'float-right ml-5'}>
                            <Badge pill className={'text-white '+ getColorBadges(infos.type)} >{_.upperFirst(t('admin.faq.types.'+ _.camelCase(infos.type)))}</Badge>
                        </div>
                        <div className={'clear-left'}>
                            <h1 className={"w-auto ebloom-normal-text font-semibold text-contrast-purple text-left"}>{infos.question}</h1>
                        </div>
                    </div>
                </Card.Title>
                <Card.Body style={{paddingTop : 0, paddingBottom:0}} className={'py-0'}>
                        <p className={'text-truncate-vertical'}>
                            {answerParser(infos.answer)}
                        </p>
                </Card.Body>
                <div className={"text-right"}>
                    <Button size={"sm"}  className={"m-10 ebloom-btn-purple"}>{_.upperFirst(t("admin.faq.seeAnswer"))}</Button>
                </div>
        </Card>

    </>
        )
}

CardFAQ.propTypes ={
    infos: PropTypes.object.isRequired,
    handleCard: PropTypes.func.isRequired
}

export default CardFAQ