import {useContext} from "react";
import {ProductTourContext} from "../Utils/Context/ProductTourContext";
import {GlobalContext} from "../Utils/Context/GlobalContext";
import {TourType} from "../Utils/Global";
import {useNavigationTo} from "./navigationHook";

export function useNavigationProductTour (){
    const navigate = useNavigationTo();
    
    const {handlersTour} = useContext(ProductTourContext);
    const {setSeePreview} = useContext(GlobalContext)
    
    const navigateTo = (urlToGo,typeTour) => {
        navigate.forceTo(urlToGo);
        if(typeTour === TourType.INVITE){
            setSeePreview(true)
        }
        setTimeout(()=>{
            handlersTour[typeTour]()
        },400)
    }

    return {to:navigateTo};
}