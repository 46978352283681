import {configureStore} from '@reduxjs/toolkit'
import userReducer from "./user";
import navigationReducer from "./navigation";
import settingsReducer from "./setting";

export default configureStore({
    reducer: {
        user:userReducer,
        navigation:navigationReducer,
        settings:settingsReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
    }
})