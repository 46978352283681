import React, {useEffect, useState} from "react"
import PropTypes from "prop-types";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {FullBlueTextField, FullContrastPurpleTextField, FullPurpleTextField} from "../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";


const CategoryList = (props) => {
    const [categories, setCategories] = useState([]);

    const {handleChange, value, color, name, size, label,id_action,isModelCustomEnable} = props;

    const t = useTranslation();

    const fetchCategories = () => {
        HttpApi.getV2("/categories/getMainCategories").then(response => {
            if(response && response.data){
                setCategories(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {
        if(props.categories){
           setCategories(props.categories);
        }else{
            fetchCategories();
        }
    }, [props.categories])

    return(
        color === "purple-contrast" ?
            <FullContrastPurpleTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                {
                    categories && categories.map((category, index) => (
                        <MenuItem key={index} value={category.id}>
                            <span>{category.id === -1 ? category.name : isModelCustomEnable ? category.name : _.upperFirst(t("core.category."+category.name))}</span>
                        </MenuItem>
                    ))

                }
            </FullContrastPurpleTextField>
            :
            color === "blue" ?
                <FullBlueTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        categories && categories.map((category, index) => (
                            <MenuItem key={index} value={category.id}>
                                <span>{category.id === -1 ? category.name : isModelCustomEnable ? category.name : _.upperFirst(t("core.category."+category.name))}</span>
                            </MenuItem>
                        ))

                    }
                </FullBlueTextField>
                :
                <FullPurpleTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        categories && categories.map((category, index) => (
                            <MenuItem key={index} value={category.id}>
                                <span>{category.id === -1 ? category.name : isModelCustomEnable ? category.name : _.upperFirst(t("core.category."+category.name))}</span>
                            </MenuItem>
                        ))

                    }
                </FullPurpleTextField>
    )
}

CategoryList.propTypes ={
    categories:PropTypes.array,
    id_category:PropTypes.number,
    handleChange:PropTypes.func.isRequired,
    value:PropTypes.number.isRequired,
    color:PropTypes.string,
    name:PropTypes.string,
    size:PropTypes.string,
    label:PropTypes.string.isRequired,
    id_action: PropTypes.number,
    isModelCustomEnable: PropTypes.bool,
}

CategoryList.defaultProps = {
    color:"purple-contrast",
    name:"category-filter",
    size:"small",
    isModelCustomEnable : false
}

export default CategoryList;