export const manageTeamsSteps  = [
    {
        title: "admin.productTour.manageTeams.step1.title",
        content: "admin.productTour.manageTeams.step1.content",
        target: '[data-tour="teamsTitle"]',
        disableBeacon : true,
        spotlightPadding: 0,
        spotlightClicks : false
    },
    {
        title: "admin.productTour.manageTeams.step2.title",
        content: "admin.productTour.manageTeams.step2.content",
        target: '[data-tour="anonymityRulesTeams"]',
        placement: 'right'
    },
    {
        title: "admin.productTour.manageTeams.step3.title",
        content: "admin.productTour.manageTeams.step3.content",
        target: '[data-tour="teamsCreate"]',
        placement: 'left',
        spotlightPadding: 3,
        disableNext : true,
        disableBeacon: true,
        nextText : "admin.productTour.manageTeams.step3.nextText",

    },
    {
        title: "admin.productTour.manageTeams.step4.title",
        content: "admin.productTour.manageTeams.step4.content",
        target: '[data-tour="saveTeam"]',
        placement: 'left',
        spotlightPadding: 2,
        disableBeacon: false,
        spotlightClicks: false,
        placementBeacon : 'left',
        styles : {
            options : {
                zIndex : 1500
            }
        },
        nextText : "admin.productTour.manageTeams.step4.nextText"

    },
    {
        title: "admin.productTour.manageTeams.step5.title",
        content: "admin.productTour.manageTeams.step5.content",
        target: '[data-tour="badge-isValid"]',
        spotlightClicks: false,
        disableBeacon: true,
        placement : "top"
    },
    {
        title: "admin.productTour.manageTeams.step6.title",
        content: "admin.productTour.manageTeams.step6.content",
        target: '[data-tour="row"]',
        placement : "top",
        disableBeacon: true,
        spotlightClicks: false,
    }

]