import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-multi-lang/lib";
import {useUserData} from "../../../../../hooks/userHook";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {ReactComponent as ValidateIcon} from "../../../../Core/symbols/productTour/step-done.svg";
import {ReactComponent as NotStartedIcon} from "../../../../Core/symbols/productTour/step-todo.svg";
import {Col, ListGroup, Row} from "react-bootstrap";
import SettingModel from '../../../../../models/Setting'
import _ from 'lodash'
import {getNotifHour, getUrl, PurpleSwitch, SettingTypes} from "../../../../../Utils/Global";
import EbloomInfoTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";
import EbloomTimePicker from "../../../../Core/Modules/Input/EbloomTimePicker";
import EbloomWindowPicker from "../../../../Core/Modules/Input/EbloomWindowPicker";
const BackOfficeAccount = (props) => {

    const [settings, setSettings] = useState([]);
    const [tours, setTours] = useState([]);

    const [switchReminder, setSwitchReminder] = useState(false);
    const [switchHolidays, setSwitchHolidays] = useState(false);
    const [switchReminderMorning, setSwitchReminderMorning] = useState(false);
    const [switchReminderMiddle, setSwitchReminderMiddle] = useState(false);
    const [switchReminderLastDay, setSwitchReminderLastDay] = useState(false);
    const [switchTranslation, setSwitchTranslation] = useState(false);
    const [timePicker, setTimePicker] = useState("");
    const [window, setWindow] = useState(3);

    const {companyToEdit, bp} = props;

    const t = useTranslation();

    const user = useUserData();

    const isMinimumScreenSize = bp.pageSize >= 1280

    const companyName = companyToEdit ? companyToEdit.name : user.company;

    const order = {maxUser:1,credits:2,nbCb:3,minTeamMember:4, publicHoliday:5, notifTime:6, window:7,reminder:8,reminderMorning:9, reminderMiddle:10, reminderLastDay:11,mailSystem:12,productTour:13,translation:14}

    const ptOrder = {communication: 1,invite: 2,manageUsers: 3,defineRoles:4,manageTeams: 5,customLibrary: 6,browseLibrary:7,createPlaylist: 8,createClickAndBloom: 9,planClickAndBloom: 10,launchWeBloom: 11}

    const handleSwitch = (el) => (event) =>  {
        const value = event.target.checked;
        const time = event.target.value
        const element = {
            id : el.id,
            type : el.type,
            value : value
        }
        if(el.type === SettingTypes.REMINDER){
            HttpApi.post(getUrl('/setting/updateReminder', companyToEdit),element).then(()=>{
                setSwitchReminder(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.REMINDER_MORNING){
            HttpApi.post(getUrl('/setting/updateReminderMorning', companyToEdit),element).then(()=>{
                setSwitchReminderMorning(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.PUBLIC_HOLIDAY){
            HttpApi.post(getUrl('/setting/updatePublicHoliday', companyToEdit),element).then(()=>{
                setSwitchHolidays(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.NOTIF_TIME){
            element.value= time.replace(':', '');
            HttpApi.post(getUrl('/setting/updateNotifTime', companyToEdit),element).then(()=>{
                setTimePicker(time)
            }).catch(error => {
                notifyError(error)
            })
        }else if(el.type === SettingTypes.WINDOW){
            element.value = time;
            HttpApi.post(getUrl("/setting/updateWindow", companyToEdit), element).then(() => {
                if(time === 3){
                    setSwitchReminder(true);
                    setSwitchReminderMorning(true);
                    setSwitchReminderMiddle(false);
                    setSwitchReminderLastDay(false);
                }else if(time === 5){
                    setSwitchReminder(true);
                    setSwitchReminderMorning(true);
                    setSwitchReminderMiddle(false);
                    setSwitchReminderLastDay(true);
                }else if(time === 10){
                    setSwitchReminder(true);
                    setSwitchReminderMorning(true);
                    setSwitchReminderMiddle(true);
                    setSwitchReminderLastDay(true);
                }
                setWindow(time);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.REMINDER_MIDDLE){
            HttpApi.post(getUrl("/setting/updateReminderMiddle", companyToEdit), element).then(() => {
                setSwitchReminderMiddle(value);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.REMINDER_LAST){
            HttpApi.post(getUrl("/setting/updateReminderLast", companyToEdit), element).then(() => {
                setSwitchReminderLastDay(value);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.TRANSLATION){
            HttpApi.post(getUrl("/setting/updateTranslation", companyToEdit), element).then(() => {
                setSwitchTranslation(value);
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const fetchSettings = () => {
        const url = getUrl("/setting/getAllForCompany", companyToEdit);
        HttpApi.get(url).then(response => {
            if(response && response.data){
                let listSettings = response.data.filter(el => SettingModel[el.type] && (SettingModel[el.type].packages).includes(user.module))
                const showTranslation = listSettings.filter(el => el.type === SettingTypes.SHOW_TRANSLATION)[0]
                if (showTranslation){
                    if(Boolean(showTranslation.value)){
                        if(listSettings.filter(el => el.type === SettingTypes.TRANSLATION)[0] === undefined){
                            listSettings.push({type: SettingTypes.TRANSLATION, value:0})
                        }
                    }else{
                        listSettings = listSettings.filter(el => el.type !== SettingTypes.TRANSLATION)
                    }
                }

                setSettings(listSettings);
                const filteredEdit = listSettings.filter(el => [SettingTypes.REMINDER,SettingTypes.REMINDER_MORNING,SettingTypes.PUBLIC_HOLIDAY,SettingTypes.NOTIF_TIME, SettingTypes.REMINDER_MIDDLE, SettingTypes.REMINDER_LAST, SettingTypes.WINDOW,SettingTypes.TRANSLATION].includes(el.type))
                filteredEdit.forEach(el => {
                    if(el.type === SettingTypes.NOTIF_TIME){
                        setTimePicker(getNotifHour(el).substring(0,5))
                    }
                    else if(el.type === SettingTypes.REMINDER_MORNING){
                        setSwitchReminderMorning(Boolean(el.value))
                    }else if(el.type === SettingTypes.PUBLIC_HOLIDAY){
                        setSwitchHolidays(Boolean(el.value))
                    }else if(el.type === SettingTypes.REMINDER){
                        setSwitchReminder(Boolean(el.value))
                    }else if(el.type === SettingTypes.WINDOW){
                        setWindow(el.value);
                    }else if(el.type === SettingTypes.REMINDER_MIDDLE){
                        setSwitchReminderMiddle(Boolean(el.value))
                    }else if(el.type === SettingTypes.REMINDER_LAST){
                        setSwitchReminderLastDay(Boolean(el.value))
                    }else if(el.type === SettingTypes.TRANSLATION){
                        setSwitchTranslation(Boolean(el.value))
                    }
                })

            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const excludedSettings = useMemo(() => {
        let list = [SettingTypes.PRODUCT_TOUR,SettingTypes.MAX_INVITE,SettingTypes.SHOW_TRANSLATION,SettingTypes.GOOGLE_REVIEWS,SettingTypes.COLORS]

        if(window === 5){
            list.push(SettingTypes.REMINDER_MIDDLE)
            return list
        }else if(window === 10){
            return list
        }
        list.push(...[SettingTypes.REMINDER_MIDDLE, SettingTypes.REMINDER_LAST])
        return list
    }, [window]);

    useEffect(() => {
        fetchSettings();
        if(companyToEdit && companyToEdit.id){
            HttpApi.get("/productTour/getAllCompanyTours/" + companyToEdit.id).then(response => {
                if(response && response.data){
                    setTours(response.data);
                }else{
                    setTours([]);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }, [companyToEdit]);

    return(
        <CardDefault title={companyName} showButton={false}>
            {
                tours && tours.length > 0 &&
                <div className={'flex flex-row justify-between px-30 w-full mb-50 ' + (isMinimumScreenSize ? 'mt-100' : 'mt-50') }>
                    {
                        tours.sort((a,b) => {return ptOrder[a.type] - ptOrder[b.type]}).map((tour,index) => (

                                index === 0 ?
                                <div key={index} className={'flex justify-center items-center relative'}>
                                    <div className={'absolute z-10 -top-10 -right-15 bottom-0 flex flex-col items-center'}>
                                        <div className={'absolute text-center -top-70 ebloom-small-text tour-text '}>
                                            {
                                                t("admin.productTour." + tour.type + ".title")
                                            }
                                        </div>
                                        <div key={index} >{tour.finished ? <ValidateIcon className={'tour-svg'}/> : <NotStartedIcon className={'tour-svg'} />} </div>
                                    </div>
                                </div>
                            :
                                <div key={index} className={'flex justify-center w-full items-center relative'}>
                                    <div className={"tour-between-step tour-not-started"}/>
                                    <div className={'wrapper-step'}>
                                        <div className={'absolute z-10 -top-10 -right-15 bottom-0 flex flex-col items-center'}>
                                            <div className={'absolute text-center ebloom-small-text tour-text ' + (isMinimumScreenSize ? '-top-70' : '-top-30')}>
                                                {
                                                    t("admin.productTour." + tour.type +'.title')
                                                }
                                            </div>
                                            <div key={index}>{tour.finished ? <ValidateIcon className={'tour-svg'}/> : <NotStartedIcon className={"tour-svg"}/>}</div>
                                        </div>
                                    </div>
                                </div>

                        ))
                    }
                </div>
            }
            <Row>
                {
                    [1,2].map(index => (
                        <Col key={index}>
                            <ListGroup variant="flush">
                                {
                                    settings?.filter(el => !excludedSettings.includes(el.type)).sort((a,b) => {return order[a.type]-order[b.type]}).slice(index === 1 ? 0 : Math.ceil(settings.filter(el => !excludedSettings.includes(el.type)).length/2), index===1 ? Math.ceil(settings.filter(el => !excludedSettings.includes(el.type)).length/2) : settings.filter(el => !excludedSettings.includes(el.type)).length).sort((a,b) => order[a.type]-order[b.type]).map((setting, index) => (
                                        <ListGroup.Item className={"noBorder h-60"} key={index}>
                                            <Row className={"ebloom-normal-text contrast-purple h-40 items-center"}>
                                                <Col md={7}>
                                                    <div className={"flex items-center"}>
                                                        <div className={"mr-5"}>{_.upperFirst(t(SettingModel[setting.type].labelType))}</div>
                                                        {
                                                            SettingModel[setting.type].tooltip &&
                                                            <EbloomInfoTooltip text={_.upperFirst(t(SettingModel[setting.type].tooltip))} size={"sm"}/>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className={"font-weight-bold text-right"}>
                                                    {
                                                        (SettingModel[setting.type].editable) ?
                                                            <div>
                                                                {
                                                                    setting.type === SettingTypes.NOTIF_TIME ?
                                                                        <EbloomTimePicker color={'purple'} selectedHour={timePicker} handleChange={handleSwitch(setting)}/>
                                                                        :
                                                                        setting.type === SettingTypes.WINDOW ?
                                                                            <EbloomWindowPicker value={window} handleChange={handleSwitch(setting)} color={"purple"}/>
                                                                            :
                                                                            <PurpleSwitch checked={setting.type === SettingTypes.REMINDER ? switchReminder : setting.type === SettingTypes.REMINDER_MORNING ? switchReminderMorning : setting.type === SettingTypes.REMINDER_MIDDLE ? switchReminderMiddle : setting.type === SettingTypes.REMINDER_LAST ? switchReminderLastDay :setting.type === SettingTypes.TRANSLATION ? switchTranslation :  switchHolidays} onClick={handleSwitch(setting)} />
                                                                }
                                                            </div>
                                                            :
                                                            typeof SettingModel[setting.type].labelValue(setting.value) === "string" ? _.upperFirst(t(SettingModel[setting.type].labelValue(setting.value))) : SettingModel[setting.type].labelValue(setting.value)
                                                    }
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        </Col>
                    ))
                }
            </Row>
        </CardDefault>

    )
}

export default BackOfficeAccount;