import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import React, {useContext, useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import {inviteSteps} from "./inviteSteps";
import {ProductTourContext} from "../../../Context/ProductTourContext"
import {GlobalContext} from "../../../Context/GlobalContext";
import {Modules, TourType} from "../../../Global";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import {useUserData} from "../../../../hooks/userHook";
import {invitePackageStarterSteps} from "./invitePackageStarterSteps";

const InviteTour = (props) => {
    const [continuous, setContinuous] = useState(true);
    const [updated, setUpdated] = useState(false);
    const {
        updateStep,
        stepTour,
        setStepInvite,
        stepInvite,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        initialStep,
        setInitialStep,
        icon,
        updateHandler
    } = useContext(ProductTourContext)

    const {setInvitePreview,setSideFormOpen,invitePreview} = useContext(GlobalContext);

    const user = useUserData();
    const isPackageStarter = Modules.HAPPY.includes(user.module)
    const openSide = () => {
        setSideFormOpen(true)
    }

    const closeSide = () => {
        setSideFormOpen(false)
    }
    const closePreview = () => {
        setInvitePreview(false)
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || [ACTIONS.SKIP].includes(action)) {
            setTourStarted(false)
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if (ACTIONS.NEXT === action) {
                if (index === 1) {
                    //ADD MANUALLY STEP + OPEN SIDEFORM
                    openSide()
                    setTimeout(() => {
                        updateStep(TourType.INVITE, next)
                    }, 200)
                }else if (index === 2) {
                    //TEMPLATE
                    setContinuous(false)
                    updateStep(TourType.INVITE, next)
                }else if (index === 3) {
                    //CHOOSE FILE
                    setContinuous(false)
                    updateStep(TourType.INVITE, next)
                }else if (index === 4) {
                    //CLOSE EXCEL AND DISPLAY DATA
                    setContinuous(false)
                    updateStep(TourType.INVITE, next)
                }else if (index === 8) {
                    // BUTTON SEE PREVIEW WITH BEACON
                    updateStep(TourType.INVITE,next)
                    setContinuous(false)
                }else{
                    updateStep(TourType.INVITE,next)
                }
            } else if (ACTIONS.PREV === action) {
                if (index === 2){
                    closeSide();
                    setTimeout(() => {
                        updateStep(TourType.INVITE,next)
                    }, 200);
                } else if (index === 5){
                    openSide()
                    setContinuous(true)
                    setTimeout(() => {
                        updateStep(TourType.INVITE,3)
                    }, 300);
                }else if (index === 8) {
                    setContinuous(false)
                    setTimeout(()=>{
                        updateStep(TourType.INVITE,next)
                    },100)

                }else if (index === 9) {
                    closePreview()
                    setContinuous(false)
                    setTimeout(()=>{
                        updateStep(TourType.INVITE,next)
                    },100)
                }else if (index === 10) {
                    setContinuous(true)
                    updateStep(TourType.INVITE,next)

                }else if (index === 11) {
                    updateStep(TourType.INVITE,next)
                    setContinuous(false)

                }else{
                    updateStep(TourType.INVITE,next)
                }
            } else if(ACTIONS.CLOSE === action){
                if(index === 4){
                    updateStep(TourType.INVITE, 4)
                    setContinuous(false)

                }else if (index=== 5) {
                    updateStep(TourType.INVITE,next)
                    setContinuous(false)

                }else if (index=== 8) {
                    updateStep(TourType.INVITE,8)
                    setContinuous(false)

                }else {
                    updateStep(TourType.INVITE,next)
                }

            }
        }
    };


    const handleJoyrideCallbackStarterPackage = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setTourStarted(false)
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if (ACTIONS.NEXT === action) {
                if(index===0){
                    if(document.querySelectorAll('[data-invite=rowUser]').length > 0){
                        setContinuous(false)
                        updateStep(TourType.INVITE,2)
                    }else{
                        updateStep(TourType.INVITE,next)
                    }
                }else if(index=== 2){
                    setContinuous(false)
                    updateStep(TourType.INVITE,next)
                }else if (index === 5){
                    setContinuous(false)
                    updateStep(TourType.INVITE,next)
                }else{
                    updateStep(TourType.INVITE,next)
                }
            }else if (ACTIONS.PREV === action) {
                if(index===2){
                    setContinuous(true)
                    updateStep(TourType.INVITE,next)
                }else if (index === 4){
                    closePreview()
                    setTimeout(()=>{
                        updateStep(TourType.INVITE,2)
                    },100)
                    setContinuous(false)
                }else if(index === 8){
                    setContinuous(true)
                    updateStep(TourType.INVITE,next)
                }else{
                    updateStep(TourType.INVITE,next)
                }

            }else if (ACTIONS.CLOSE === action) {
                if(index === 3){
                    setContinuous(false)
                    updateStep(TourType.INVITE,3)
                }else{
                    updateStep(TourType.INVITE,next)
                    setContinuous(false)
                }

            }
        }
    }

    const handleTour = () => {
        setContinuous(false)
        if(updated){
           if(stepTour[TourType.INVITE]){
               const step = stepTour[TourType.INVITE].step
               if([2,3,4].includes(step)){
                   setContinuous(true)
                   updateStep(TourType.INVITE,1)
               }else if (step === 5) {
                   setContinuous(false)
                   updateStep(TourType.INVITE,5)
               }else if (step === 8) {
                   setContinuous(false)
                   updateStep(TourType.INVITE,8)
               }else if (step > 8) {
                   updateStep(TourType.INVITE,5)
               }else{
                   setContinuous(true)
               }
           }else{
               setContinuous(true)
               updateStep(TourType.INVITE,0)
           }
        }else{
            setContinuous(true)
            updateStep(TourType.INVITE,0)
        }
        setTimeout(() => {
            setTourStarted(true)
        },100)
    }

    const handleTourPackageStarter = () => {
        if(updated){
            if(stepTour[TourType.INVITE]) {
                const step = stepTour[TourType.INVITE].step
                if(document.querySelectorAll('[data-invite=rowUser]').length > 0){
                    if(step === 1){
                        setContinuous(false)
                        updateStep(TourType.INVITE, 2)
                    } else if(step === 3){
                        setContinuous(false)
                        updateStep(TourType.INVITE, 3)
                    } else if (step >3) {
                        setContinuous(false)
                        updateStep(TourType.INVITE, 2)
                    }
                }else{
                    setContinuous(false)
                    updateStep(TourType.INVITE, 1)
                }
            }else{
                setContinuous(true)
                updateStep(TourType.INVITE,0)
            }
        }else{
            setContinuous(true)
            updateStep(TourType.INVITE,0)
        }
        setTimeout(() => {
            setTourStarted(true)
        },100)
    }

    const updateTourStatusStarterPackage = () => {
        closeSide()
        setContinuous(true)
        const tour = {
            id : stepTour[TourType.INVITE].id,
            current_step : stepTour[TourType.INVITE].step >= invitePackageStarterSteps.length - 1 ? 0 : stepTour[TourType.INVITE].step,
            type: TourType.INVITE,
            finished : stepTour[TourType.INVITE].outcome
        }
        HttpApi.post('/productTour/updateTour',tour).then(()=>{
            if(stepInvite >= 8){
                closePreview()
                closeSide()
                setStepInvite(0)
                icon.current.classList.remove('ebloom-ring')
            }
        })
        setUpdated(true)
        updateHandler(TourType.INVITE,handleTourPackageStarter)
        iconRinging()
    }

    const updateTourStatus = () => {
        closeSide()
        setContinuous(true)
        const tour = {
            id : stepTour[TourType.INVITE].id,
            current_step : stepTour[TourType.INVITE].step >= inviteSteps.length - 1 ? 0 : stepTour[TourType.INVITE].step,
            type: TourType.INVITE,
            finished : stepTour[TourType.INVITE].outcome
        }
        HttpApi.post('/productTour/updateTour',tour).then(()=>{
            if(stepInvite >= 12){
                closePreview()
                closeSide()
                setStepInvite(0)
                icon.current.classList.remove('ebloom-ring')
            }
        })
        setInitialStep(tour.current_step)
        setUpdated(true)
        updateHandler(TourType.INVITE,handleTour)
        iconRinging()
    }

    const iconRinging = () => {

        if(icon && icon.current && stepTour[TourType.INVITE]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.INVITE].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    //Need this useEffect because of the save Step when you close the side form
    // it turns off the tour and not update the tour
    useEffect(() => {
        if(!tourStarted && tourActivated){
            if(isPackageStarter){
                updateTourStatusStarterPackage()
            }else{
                updateTourStatus()
            }
        }
    }, [tourStarted]);

    useEffect(() => {
        iconRinging()
    }, [stepInvite,stepTour,initialStep,invitePreview]);


    useEffect(() => {
        fetchData();
        setInitialStep(stepInvite)
        if(tourActivated && icon && icon.current){
            iconRinging()
        }
        if(isPackageStarter){

            updateHandler(TourType.INVITE,handleTourPackageStarter)
        }else{
            updateHandler(TourType.INVITE,handleTour)
        }
        return () => {
            setTourStarted(false)
        }
    }, []);

    return(
        <>
            <ReactJoyride
                steps={isPackageStarter ? invitePackageStarterSteps : inviteSteps}
                continuous={continuous}
                run={tourStarted}
                callback={isPackageStarter ? handleJoyrideCallbackStarterPackage : handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                stepIndex={stepInvite}
                disableCloseOnEsc={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                tooltipComponent={EbloomTourTip}
                debug={false}
                styles={styleEbloom}
            />
        </>
    )
}
export default InviteTour