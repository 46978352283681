import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import {createSuccessfullyCreatedNotification, notifyError} from "../../../../../../Utils/Notification";
import HttpApi from "../../../../../../httpApi";
import AutoPlanner from "./AutoPlanner";
import SideForm from "../../../../../Core/Modules/Views-Tables-Lists/SideForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {FullContrastPurpleTextField, Modules} from "../../../../../../Utils/Global";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {Divider, FormControl} from "@mui/material";
import {Colors} from "../../../../../../Utils/Colors";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useTranslation} from "react-multi-lang/lib";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import EbloomTooltip from "../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const AutoPlannersList = (props) => {
    const {selectedEntity = null,playlists,teams,handleToday, fetchNumberFutureQuestions,isStarter,isFree, handleOpenModalFree,autoPlanners,frequencies,usedPlaylists,loading,fetchAutoPlanners} = props

    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([-1]);
    const [selectedFrequency, setSelectedFrequency] = useState(-1);
    const [selectedPlaylist, setSelectedPlaylist] = useState(-1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [openModalOnCreation, setOpenModalOnCreation] = useState(false);
    const [loadingPlanner, setLoadingPlanner] = useState(false);

    const t = useTranslation();

    const itemsPerSlide = 3;
    const week = ["monday","tuesday","wednesday","thursday","friday"];

    const fakeAutoPlanner = {
        id: -1,
        id_playlist: 0,
        id_frequency: -1,
        type: "global",
        active: false,

        frequency: {
            id: -1,
            title: "Frequency #1",
            id_company: 0,
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            recurrency: 1,
        },
        playlist: {
            id: 0,
            name: "Bloomothèque",
            id_company: 0,
        },
        auto_planner_teams: [],
        auto_planner_surveys: []
    }


    const usedPlaylistActive = useMemo(() => {
        return autoPlanners.filter(el => el.active).map(el => el.id_playlist);
    }, [autoPlanners]);

    const emptyFrequencies = useMemo(() => {
        return frequencies.filter(el => week.reduce((previousValue, currentValue) => previousValue + el[currentValue],0) === 0).map(el => el.id);
    }, [frequencies]);
    const nextSlide = () => {
        if (currentIndex < autoPlanners.length+1 - itemsPerSlide) {
            setCurrentIndex(currentIndex + itemsPerSlide);
        }
    };

    const getColOffset = () => {
        if(autoPlanners.length === 1) return 'col-start-2 col-end-2';
        return ''
    }

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsPerSlide);
        }
    };



    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setSelectedTeams([-1])
            setSelectedFrequency(-1)
            setSelectedPlaylist(-1)
        }
        setSideFormOpen(open);
    }


    const handleSaveAddAutoPlanner = () => {
        const frequency = frequencies.find(el => el.id === selectedFrequency)
        const totalCbFrequency = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
        if(totalCbFrequency === 0){
            handleAddAutoPlanner("cancel")
        }else{
            setSideFormOpen(false)
            setOpenModalOnCreation(true)
        }

    }

    const handleAddAutoPlanner = (type = "cancel") => {
        setLoadingPlanner(true)
        const status = ["now","next"].includes(type) ? type : null
        const data = {
            id_playlist : selectedPlaylist,
            id_frequency : selectedFrequency,
            active : !!type,
            auto_planner_teams : selectedTeams.length === 1 && selectedTeams[0] === -1 ? [] : selectedTeams,
            timing : status
        }
        let url = "/frequencies/autoPlanner/create"
        if(!!selectedEntity){
            url = "/frequencies/autoPlanner/create/"+selectedEntity
        }
        HttpApi.postV2(url,data).then((response) => {
            if(response && response.data){
                createSuccessfullyCreatedNotification("auto planner");
                setSideFormOpen(false)
                setSelectedTeams([-1])
                setSelectedFrequency(-1)
                setSelectedPlaylist(-1)
                if(currentIndex < autoPlanners.length + 1 - itemsPerSlide){
                    setCurrentIndex(prevState => prevState +itemsPerSlide)
                }
                fetchNumberFutureQuestions()
                fetchAutoPlanners();
            }
        }).catch(error => {
            notifyError(error)
        }).finally(() => {
            setLoadingPlanner(false)
            setOpenModalOnCreation(false)
        })
    }

    const handleChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        if (name === "teams") {
            if (value.length > 0) {
                if (value.includes(-1) && selectedTeams.includes(-1)) {
                    setSelectedTeams(value.filter(el => el !== -1))
                } else if (value.includes(-1) && !selectedTeams.includes(-1)) {
                    setSelectedTeams([-1])
                } else {
                    setSelectedTeams(value.filter(el => el !== -1))
                }
            }
        } else if (name === "id_playlist") {
            const playlist = playlists.find(el => el.id === value)
            if(value === 0 && !usedPlaylistActive.includes(value)) {
                setSelectedPlaylist(value)
            }else{
                const frequency = selectedFrequency !== -1 ?  frequencies.find(el => el.id === selectedFrequency) : null
                if(frequency && playlist && !(usedPlaylistActive.includes(playlist.id) || playlist.nbCB < week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0))){
                    setSelectedPlaylist(value)
                }
            }

        } else if (name === "id_frequency") {
            const frequency = frequencies.find(el => el.id === value)
            if(frequency) {
                if(selectedPlaylist !== -1){
                    const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                    const playlist = playlists.find(el => el.id === selectedPlaylist)
                    if(playlist && playlist.nbCB < nbCB ){
                        setSelectedPlaylist(-1)
                    }
                }
                setSelectedFrequency(value)
            }
        }
    }

    const handleIndexReturnPreviousPage = () => {
        if(currentIndex > 0 && autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).length === 0){
            setCurrentIndex(prevState => prevState - itemsPerSlide)
        }
    }



    useEffect(() => {
        handleIndexReturnPreviousPage()
    }, [autoPlanners]);


    useEffect(() => {
        fetchAutoPlanners()
    }, [selectedEntity]);

    return (
        loading  ? <LoadingView/> :
            <div className={"w-full relative mb-40"}>
                {
                    autoPlanners.length === 1 || isFree ?
                        <div className={"w-full flex justify-center items-center mb-20"}>
                            <div className={"w-1/2"}>
                                <AutoPlanner autoPlannerData={isFree ? fakeAutoPlanner :autoPlanners[0]} key={0}
                                             playlists={playlists}
                                             usedPlaylists={isFree ? [] :usedPlaylists.filter(el => el !== autoPlanners[0].id_playlist)}
                                             frequencies={isFree ? [{...fakeAutoPlanner.frequency}] :frequencies}
                                             teams={isFree ? [] :teams}
                                             isSingle={true}
                                             isStarter={isStarter}
                                             otherAutoPlanners={autoPlanners.filter(el => el.id !== autoPlanners[0].id)}
                                             shouldShowDelete={autoPlanners.length > 1}
                                             fetchAutoPlanners={fetchAutoPlanners}
                                             handleToday={handleToday}
                                             selectedEntity={selectedEntity}
                                             handleOpenModalFree={handleOpenModalFree}
                                             isFree={isFree}
                                             fetchNumberFutureQuestions={fetchNumberFutureQuestions}
                                />
                            </div>
                            {
                                !isFree &&
                                <div
                                className={"absolute right-100 size-30 flex justify-center items-center "}>
                                <div className={"w-full h-full flex justify-center items-center "}>
                                    <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.addAutoPlanner"))}>
                                        <div className={"w-full h-full flex justify-center items-center "}>
                                            <AddCircleOutlineIcon
                                                className={"ebloom-add-btn-purple size-25"}
                                                onClick={toggleDrawer(true)}/>
                                        </div>


                                    </EbloomTooltip>
                                </div>
                            </div>}
                        </div>

                        :
                        <div>
                            <Row className={"mb-20"}>
                                <Col md={1} className={"p-0 flex justify-center items-center"}>
                                    {
                                        currentIndex > 0 &&
                                        <div className={"size-30 flex justify-center items-center"} onClick={prevSlide}>
                                            <KeyboardArrowLeftIcon className={"pointer text-purple size-25"}/>
                                        </div>
                                    }

                                </Col>
                                <Col md={10} className={"p-0 relative overflow-hidden"}>
                                    <div
                                        className={`grid grid-cols-3 gap-x-10 transition-transform duration-300`}>
                                        {
                                            autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).map((autoPlanner, index) => (
                                                <div
                                                    className={`w-full ${getColOffset()}  flex flex-grow justify-center items-center`}
                                                    key={index}>
                                                    <AutoPlanner autoPlannerData={autoPlanner} key={index}
                                                                 playlists={playlists}
                                                                 usedPlaylists={usedPlaylists.filter(el => el !== autoPlanner.id_playlist)}
                                                                 frequencies={frequencies}
                                                                 teams={teams}
                                                                 isSingle={false}
                                                                 isStarter={isStarter}
                                                                 otherAutoPlanners={autoPlanners.filter(el => el.id !== autoPlanner.id)}
                                                                 shouldShowDelete={autoPlanners.length > 1}
                                                                 fetchAutoPlanners={fetchAutoPlanners}
                                                                 handleToday={handleToday}
                                                                 selectedEntity={selectedEntity}
                                                                 fetchNumberFutureQuestions={fetchNumberFutureQuestions}
                                                    />

                                                </div>
                                            ))
                                        }
                                        {
                                            autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).length <= 2 &&
                                            <div className={"w-full h-full flex justify-start items-center"}>
                                                <div
                                                    className={" size-30 flex justify-center items-center ml-20"}>
                                                    <div className={"w-full h-full flex justify-center items-center "}>
                                                        <EbloomTooltip
                                                            text={_.upperFirst(t("admin.autoPlanner.addAutoPlanner"))}>
                                                            <div
                                                                className={"w-full h-full flex justify-center items-center "}>
                                                                <AddCircleOutlineIcon
                                                                    className={"ebloom-add-btn-purple size-25"}
                                                                    onClick={toggleDrawer(true)}/>
                                                            </div>


                                                        </EbloomTooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </Col>
                                <Col md={1} className="p-0 flex justify-center items-center flex-row">
                                    {
                                        autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).length > 2 &&
                                        <div
                                            className={" size-30 flex justify-center items-center "}>
                                            <div className={"w-full h-full flex justify-center items-center "}>
                                                <EbloomTooltip
                                                    text={_.upperFirst(t("admin.autoPlanner.addAutoPlanner"))}>
                                                    <div className={"w-full h-full flex justify-center items-center "}>
                                                        <AddCircleOutlineIcon
                                                            className={"ebloom-add-btn-purple size-25"}
                                                            onClick={toggleDrawer(true)}/>
                                                    </div>


                                                </EbloomTooltip>
                                            </div>
                                        </div>
                                    }

                                    {
                                        currentIndex < autoPlanners.length - itemsPerSlide &&
                                        <div onClick={nextSlide} className={"size-30 flex justify-center items-center"}>
                                            <KeyboardArrowRightIcon className="pointer text-purple size-25"/>
                                        </div>
                                    }

                                </Col>
                            </Row>
                        </div>
                }


                <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen}
                          buttonDisabled={selectedPlaylist === -1 || selectedFrequency === -1 || selectedTeams.length === 0}
                          showButtons={true} handleSave={() => {
                    handleSaveAddAutoPlanner()
                }} formTitle={"core.add"}>
                    <div className={" w-300  flex justify-center items-center flex-col gap-15"}>
                        <FullContrastPurpleTextField name={"id_frequency"} onChange={handleChange}
                                                     value={selectedFrequency} fullWidth={true} size={"small"}
                                                     select={true} label={_.upperFirst(t("admin.frequency"))}>
                            <MenuItem key={-1} value={-1} disabled
                                      className={"ebloom-normal-text"}>{_.upperFirst(t("admin.autoPlanner.selectFrequency"))}</MenuItem>
                            {
                                frequencies.map((item, index) => {
                                    if(emptyFrequencies.includes(item.id)){
                                        return(
                                            <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.emptyFrequencyTitle"))} placement={"left"}>
                                                    <p>{item.title}</p>
                                                </EbloomTooltip>
                                            </MenuItem>
                                        )

                                    }
                                    return <MenuItem key={index} value={item.id} >{item.title}</MenuItem>

                                })
                            }

                        </FullContrastPurpleTextField>
                        <FullContrastPurpleTextField disabled={selectedFrequency === -1} onChange={handleChange}
                                                     name={"id_playlist"}
                                                     value={selectedPlaylist} fullWidth={true} size={"small"}
                                                     select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                            <MenuItem key={-2} value={-1} disabled
                                      className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                            {
                                usedPlaylistActive.includes(0)  ?
                                    <MenuItem key={-1} value={0} disabled={true} className={"pointer-events-auto"}>
                                        <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                            <p>{_.upperFirst(t("core.playlist.library"))}</p>
                                        </EbloomTooltip>
                                    </MenuItem>
                                    :
                                    <MenuItem key={-1} value={0}
                                              className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>

                            }
                            {
                                playlists.map((item, index) => {

                                    const frequency = selectedFrequency !== -1 ?  frequencies.find(el => el.id === selectedFrequency) : null
                                    if (frequency){
                                        if(usedPlaylistActive.includes(item.id) ){
                                            return (
                                                <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                    <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                                        <p>{item.name}</p>
                                                    </EbloomTooltip>
                                                </MenuItem>
                                            )
                                        }else if( item.nbCB < week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue], 0)){
                                            return (
                                                <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                    <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledShort"))} placement={"left"}>
                                                        <p>{item.name}</p>
                                                    </EbloomTooltip>
                                                </MenuItem>
                                            )
                                        }
                                        return (
                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                        )
                                    }else return null

                                })
                            }
                        </FullContrastPurpleTextField>

                        {
                            (!isStarter && teams.length > 0) &&
                            <FormControl variant={"outlined"} fullWidth style={{
                                '& label.Mui-focused': {
                                    color: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: "white",
                                    '&.Mui-focused fieldset': {
                                        borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                    },
                                },
                                '& .MuiFormHelperText-root': {
                                    color: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                "& .MuiFormLabel-root": {
                                    color: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                "& .MuiOutlinedInput-root fieldset": {
                                    borderColor: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                "& .MuiInputBase-input": {
                                    color: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                "& .MuiSelect-iconOutlined": {
                                    color: Colors.EBLOOM_CONTRAST_PURPLE
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                },
                            }}>
                                <InputLabel className={"text-contrast-purple bg-bg-white"}
                                            id={"3"}>{_.upperFirst(t("admin.teams"))}</InputLabel>
                                <Select
                                    className={"text-contrast-purple border border-contrast-purple"}
                                    size="small"
                                    id={"3"}
                                    multiple
                                    name={"teams"}
                                    value={selectedTeams}
                                    onChange={(event) => handleChange(event)}
                                    renderValue={(selected) => (
                                        <div className={""}>
                                            {
                                                selected.length === 1 ?
                                                    selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                        : teams.find(el => el.id === selected[0]).name
                                                    : selected.length + " " + _.lowerFirst(t("admin.teams"))

                                            }
                                        </div>
                                    )}
                                >
                                    <MenuItem key={-1} value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                    <Divider key={-3}/>

                                    {teams.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            <div>{item.name}</div>
                                        </MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </div>
                </SideForm>
                <Modal show={openModalOnCreation} size={"lg"} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                    <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                        <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlannerTitle"))}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                        <p>{_.upperFirst(t("admin.autoPlannerHelper"))}</p>
                        {
                            isFree &&
                            <div className={"text-line-break"}>
                                <p>{_.upperFirst(t("free.autoPlannerText"))}</p>
                            </div>
                        }

                    </Modal.Body>
                    <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                        <Button variant={"secondary"} className={"ebloom-normal-text"} disabled={loadingPlanner} onClick={() => handleAddAutoPlanner("cancel")} >{_.capitalize(t("admin.autoPlanner.notNow"))}</Button>
                        <Button className={"ebloom-btn-purple ebloom-normal-text"} disabled={loadingPlanner} onClick={() =>handleAddAutoPlanner("now")} >{loadingPlanner ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                        <Button className={"ebloom-btn-purple ebloom-normal-text"} disabled={loadingPlanner} onClick={() =>handleAddAutoPlanner("next")} >{loadingPlanner ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                    </Modal.Footer>
                </Modal>
            </div>

    );
};

AutoPlannersList.propTypes = {
    isStarter: PropTypes.bool.isRequired,
    isFree: PropTypes.bool.isRequired,
    selectedEntity: PropTypes.number,
    teams: PropTypes.array.isRequired,
    playlists: PropTypes.array.isRequired,
    handleToday: PropTypes.func.isRequired,
    handleOpenModalFree: PropTypes.func.isRequired,
    fetchNumberFutureQuestions: PropTypes.func.isRequired
};

export default AutoPlannersList;
