import React from 'react';
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../lang/translations";
import {replaceUrlsWithLinks} from "../../../../Utils/Global";


const EbloomBarChart = (props) => {

    const {data, total, lang} = props;

    const t = useTranslation();

    const COLORS = ["#5D00A2", "#07B7E3", "#B92CEC", "#A1E5F6", "#CCCCCC", "#F0F0F0"];

    const getBarHeight = (item) => {
        const percentAnswer = item.selected/total;
        return 250*percentAnswer;
    }

    return (
        <div className={"flex w-full items-center"}>
            <div className={"flex justify-between w-2/3"}>
                {
                    data.map((item, index) => (
                        <div className={"h-250 relative w-full ml-5 mr-5"} key={index}>
                            <div className={"w-full absolute"} style={{height:getBarHeight(item), backgroundColor:COLORS[index%COLORS.length], bottom:0}}>
                                <div className={"text-center -mt-25 text-contrast-purple font-semibold ebloom-text ebloom-large-text"}>{item.selected}<span className={"font-normal"}>{" (" + Math.round((item.selected/total)*100) + "%)"}</span></div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={"w-1/3 ml-50"}>
                {
                    data.map((item, index) => (
                        <div className={"flex items-center"} key={index}>
                            <div className={"rounded-xl h-15 w-15 mr-5"} style={{backgroundColor:COLORS[index%COLORS.length]}}/>
                            <div style={{color:COLORS[index%COLORS.length]}}>{item.text === "Other" ? _.upperFirst(getTranslations(lang,"webloom.other")) :<div className={" max-w-100 truncate"}>{replaceUrlsWithLinks(item.text)}</div>}</div>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default EbloomBarChart;
