import MyBloomContainer from "./MyBloomContainer";
import React, {useContext, useEffect, useState} from "react";
import EditAvatar from "./Avatar/EditAvatar";
import HttpApi from "../../../../httpApi";
import {avatarCodes, calculateEmote, PaletteColors} from "./Avatar/AvatarAssets";
import MyBloomDoubleContainer from "./MyBloomDoubleContainer";
import Avatar from "./Avatar/Avatar";
import MyScores from "./Scores/MyScores";
import edit from "../../../Core/symbols/myBloom/icons/Edit_avatar.svg";
import MyParticipation from "./Participation/MyParticipation";
import ActivityMenu from "./Participation/ActivityMenu";
import Quotes from "./Quotes/Quotes";
import MyVotes from "./Votes/MyVotes";
import {notifyError} from "../../../../Utils/Notification";
import {useBreakpoints} from "../../../../hooks/breakpointsHook";
import MyOrganisation from "./Organisation/MyOrganisation";
import LoadingView from "../../../Core/Layouts/LoadingView";
import {getColorFromPercentage, SettingTypes} from "../../../../Utils/Global";
import {Colors} from "../../../../Utils/Colors";
import MyPriorities from "./Priority/MyPriorities";
import DetailsScores from "./Scores/DetailsScores";
import PropTypes from "prop-types";
import moment from "moment/moment";
import MyActivity from "./Activity/MyActivity";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import WebloomContainer from "./WeBloomContainer/WebloomContainer";
import {SurveyStatus, SurveyTypes, SurveyUsersStatus} from "../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {ReactComponent as ArrowGreen} from "../../../Core/symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../../Core/symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../../Core/symbols/general/ArrowNeutral.svg";
import {useUserData} from "../../../../hooks/userHook";
import {GlobalContext} from "../../../../Utils/Context/GlobalContext";
import ProspectModal from "../../../Core/Modules/Modal/ProspectModal";

const MyBloom = (props) => {

    const {startDate, endDate, handleBgColor, timeFilterVersion} = props

    const bp = useBreakpoints();
    const t = useTranslation()

    const [loadingRadar, setLoadingRadar] = useState(true);
    const [loadingScores, setLoadingScores] = useState(true);
    const [loadingFocusMatrix, setLoadingFocusMatrix] = useState(true);
    const [loadingActivity, setLoadingActivity] = useState(true);
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loadingWeBloom, setLoadingWeBloom] = useState(true);

    const [statFromStart, setStatFromStart] = useState({tableOfResults: [], nbResponse: 0});

    const [categoriesScores, setCategoriesScore] = useState([]);

    const [statOfBubble, setStatOfBubble] = useState([]);

    const [statOfAvg, setStatOfAvg] = useState({total : 0 ,avg: -1, limit: true, previous : null});

    const [participationRate, setParticipationRate] = useState({actual: null, previous: 0});

    const [radarData, setRadarData] = useState([]);

    const [showEdit, setShowEdit] = useState(false);

    const [history, setHistory] = useState([]);

    const [surveys, setSurveys] = useState([]);

    const [previousDate, setPreviousDate] = useState({start: moment(), end : moment()});

    const [isModelCustomEnable, setIsModelCustomEnable] = useState(false);

    const [isEbloomModel, setIsEbloomModel] = useState(false);

    const [cbUser, setCbUser] = useState({total : 0, answered: 0});
    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState([]);
    const [avatar, setAvatar] = useState({
        form: '01',
        hat: '16',
        emote: '06',
        color: '01'
    });

    const [showModal, setShowModal] = useState(false);

    const userData = useUserData();

    const isProspect = userData.module === 'prospect';


    const displayPreviousExerciceAvg = () => {
        const score = statOfAvg.avg
        const difference = statOfAvg.previous
        if(difference !== null){
            if(score === difference ){
                return null
            }else if(Math.round(difference) > 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowGreen className={""}/>
                            </div>
                            <p className={"text-strong-green text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>

            }else if (Math.round(difference) < 0 ){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowRed/>
                            </div>
                            <p className={"text-strong-red text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }else if (Math.round(difference) === 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center ml-5"}>
                            <div className={"scale-75"}>
                                <ArrowNeutral/>
                            </div>
                            <p className={"text-grey text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }
            return null
        }
        return null
    }

    const checkEquality = (a1, a2) => {
        if(a1.length !== a2.length){
            return false
        }
        for (const el of a1) {
            const result = a2.filter(c => c.name === el.name && parseInt(c.icon) === parseInt(el.icon) && c.color === el.color)
            if(result.length > 0){
                const tagsSame = checkTags(el.tags,result[0].tags)
                if(!tagsSame){
                    return false
                }
            }else{
                return false
            }
        }
        return true
    }

    const checkTags = (t1,t2) => {
        for (const el of t1) {
            const result = t2.filter(t => t.id_tag === el.id_tag)
            if(result.length <= 0){
                return false
            }
        }
        return true
    }

    const checkSurveys = () => {
        HttpApi.get("/collaborative/getAllForUsers").then(responseSurveys => {
            HttpApi.post("/collaborative/updateUserSurveyUnreadStatus", {}).then( () => {
                if(responseSurveys && responseSurveys.data){
                    const listWebloom = responseSurveys.data.filter(el => el.status !== SurveyStatus.DONE && ((el.lastAnswer === null && el.userSurveyStatus !== SurveyUsersStatus.ARCHIVED) || el.type === SurveyTypes.IDEABOX || el.type === SurveyTypes.ALERT))
                    setSurveys(listWebloom);
                }else{
                    setSurveys([]);
                }
                setLoadingWeBloom(false)
            }).catch(error => {
                notifyError(error);
            })

        }).catch((error) => {
            notifyError(error);
        }).finally(() =>{
            setLoadingWeBloom(false)
        });
    }

    const fetchModelCustomization = () => {
        HttpApi.getV2("/categories/getAllCustomCategories").then(response => {
            if(response && response.data){
                const equalToEBloom = checkEquality(response.data.companyModelConfirmed, response.data.ebloomModel)
                setIsEbloomModel(response.data.companyModelConfirmed.length === 0 ? true : equalToEBloom)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchImportanceAndSatisfaction = () => {
        setLoadingFocusMatrix(true);
        const url = "/feedbacks/user/getImportanceAndSatisfaction/" + startDate + "/" + endDate;
        HttpApi.getV2(url).then(response => {
            if (response && response.data) {
                setStatOfBubble(response.data);
            } else {
                setStatOfBubble([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingFocusMatrix(false));
    };

    const fetchScores = () => {
        HttpApi.getV2("/feedbacks/getScoresUser/" + startDate + "/" + endDate).then(response => {
            if (response && response.data) {
                const data = response.data
                setPreviousDate({
                    start: data.prevDate.start,
                    end: data.prevDate.end
                })
                setStatOfAvg({total: data.cbAnswered, avg: data.avg, limit : data.limit, previous: data.avgDifference})
                setParticipationRate({actual : data.participationRate, previous: data.participationDifference});
                setCategoriesScore(data.categories)
                setStatFromStart(data.smileyScore)
                setCbUser({total: data.totalCB,answered: data.cbAnswered })
            } else {
                setParticipationRate(null);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {setLoadingScores(false)})
    };
    const fetchRadarAvg = () => {
        setLoadingRadar(true);
        HttpApi.getV2("/feedbacks/user/getRadarData/" + startDate + "/" + endDate).then(response => {
            if (response && response.data) {
                let radarData = response.data
                const filteredTableOfAvg = radarData.tableOfAvg.filter(el => el.A !== null && el.B !== null && el.A >= 0 && el.B >= 0)
                let filteredRadar = {
                    tableOfAvg : filteredTableOfAvg.length >= 3 ? filteredTableOfAvg.sort((a,b) => a.category.position - b.category.position) : radarData.tableOfAvg.sort((a,b) => a.category.position - b.category.position),
                    enoughValue : filteredTableOfAvg.length >= 3
                }
                if(!filteredRadar.enoughValue){
                    filteredRadar.tableOfAvg.forEach(el => {
                        el.A = null
                        el.B = null
                    })
                }
                setRadarData(filteredRadar);

            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingRadar(false);
        })
    };
    const fetchAvatar = () => {
        HttpApi.get("/mybloom/getAvatar").then(result => {
            const data = avatarCodes(result.data)
            setAvatar(data)
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingAvatar(false);
        })
    }

    const fetchHistory = () => {
        setLoadingActivity(true);
        let url = "/questions/user/getHistory/" + startDate + "/" + endDate;

        HttpApi.getV2(url).then(response => {
            if(response && response.data.length>0){
                setHistory(response.data);
            }else{
                setHistory([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingActivity(false));
    };

    const fetchModelCustomSetting = () => {
        HttpApi.getV2("/settings/getForCompany/"+ SettingTypes.CUSTOM_CATEGORIES).then(response => {
            if(response && response.data){
                setIsModelCustomEnable(Boolean(response.data.value))
            }else{
                setIsModelCustomEnable(false)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {
        handleBgColor(PaletteColors[avatar.color].bg)
    }, [avatar]);


    useEffect(() => {
        if (timeFilterVersion !== null) {
            fetchAvatar()
            fetchScores()
            fetchHistory()
            fetchRadarAvg()
            fetchImportanceAndSatisfaction()
            checkSurveys()
            fetchModelCustomSetting()
            fetchModelCustomization()
        }
    }, [timeFilterVersion]);


    useEffect(() => {
        const newEmote = calculateEmote(statOfAvg.avg)
        setAvatar(prevState => {
            return {
                form: prevState.form,
                hat: prevState.hat,
                emote: newEmote,
                color: prevState.color
            }
        })
    }, [statOfAvg]);


    return <div>
        {
            bp.isTabletLandscapeOrLess() ?
                <div className={"w-full h-full"}>
                    <div className={"inset-0 flex flex-col justify-center items-center"}>
                        {showEdit &&
                            <EditAvatar showEdit={showEdit} setShowEdit={setShowEdit} avatar={avatar} setAvatar={setAvatar}/>}
                        {showDetails && <DetailsScores isEbloomModel={isEbloomModel} isModelCustomEnable={isModelCustomEnable} setShowDetails={setShowDetails} details={details}/>}

                        <ActivityMenu activeSurveys={surveys}/>
                        <MyBloomDoubleContainer title1={_.upperFirst(t("employee.dashboard.iFeel"))} title2={_.upperFirst(t("employee.dashboard.myScores"))}>
                            <div>
                                {loadingAvatar ? <LoadingView/> : <Avatar avatar={avatar}/> }
                                <div className={'flex justify-between items-end absolute bottom-0 w-full'}>
                                    <div>
                                        {
                                            loadingScores ?
                                                <LoadingView/>
                                                :
                                                <div className={"flex flex-row items-end"}>
                                                    <p className={'text-contrast-purple font-semibold text-lg cursor-default'}>
                                                        <EbloomTooltip text={_.upperFirst(t("employee.dashboard.bloomIndexHelper"))}>
                                                            <span className={'text-4xl font-sans'}
                                                                  style={{color: (statOfAvg.total > 0 && statOfAvg.avg !== null && statOfAvg.avg > -1 ? getColorFromPercentage(statOfAvg.avg) : Colors.EBLOOM_CONTRAST_PURPLE)}}>
                                                                {(statOfAvg.total > 0 && statOfAvg.avg !== null && statOfAvg.avg > -1 ? Math.floor(statOfAvg.avg) : _.toUpper(t("webloom.NA")))}
                                                            </span>
                                                        </EbloomTooltip>
                                                        /100
                                                    </p>

                                                    <p className={"text-center"}>{displayPreviousExerciceAvg()}</p>
                                                </div>

                                        }

                                    </div>
                                    <div>
                                        <button onClick={() => {
                                            setShowEdit(true)
                                        }}>
                                            <img className={'w-24'} src={edit} alt={'edit'}/>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <MyScores isModelCustomEnable={isModelCustomEnable} categoriesScores={categoriesScores} loadingCategories={loadingScores}
                                      startDate={startDate} endDate={endDate} setShowDetails={setShowDetails}
                                      details={details} setDetails={setDetails}/>
                        </MyBloomDoubleContainer>
                        <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myParticipation"))} tooltip={_.upperFirst(t("employee.dashboard.myParticipationHelper"))}>
                            <MyParticipation totalCB={cbUser.total} answered={cbUser.answered}
                                             participation={participationRate} history={history} previousDate={previousDate}/>
                        </MyBloomContainer>
                        <WebloomContainer isProspect={isProspect} setShowModal={setShowModal} title={"WeBloom"} surveys={surveys} fetchSurveys={checkSurveys} avatar={avatar} loadingWeBloom={loadingWeBloom} />
                        <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myVotes"))} tooltip={_.upperFirst(t("employee.dashboard.smileyHelper"))}>
                            <MyVotes statFromStart={statFromStart} loadingFromStart={loadingScores}
                                     startDate={startDate}
                                     endDate={endDate}/>
                        </MyBloomContainer>
                        <Quotes/>
                        <MyBloomContainer title={_.upperFirst(t("employee.dashboard.organisationAndMe"))} tooltip={_.upperFirst(t("employee.dashboard.organisationAndMeHelper"))}>
                            <MyOrganisation isModelCustomEnable={isModelCustomEnable} avatar={avatar} radarData={radarData} loadingRadar={loadingRadar}/>
                        </MyBloomContainer>
                        <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myPriorities"))} tooltip={_.upperFirst(t("employee.dashboard.myPrioritiesHelper"))}>
                            <MyPriorities isModelCustomEnable={isModelCustomEnable} statOfBubble={statOfBubble} loadingFocusMatrix={loadingFocusMatrix}/>
                        </MyBloomContainer>
                        <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myActivity"))}>
                            <MyActivity isModelCustomEnable={isModelCustomEnable} history={history} loading={loadingActivity}/>
                        </MyBloomContainer>
                    </div>

                </div>


                : <div className={"w-full h-full"}>
                    <div className={"inset-0 flex flex-col justify-center items-center"}>
                        {showEdit &&
                            <EditAvatar showEdit={showEdit} setShowEdit={setShowEdit} avatar={avatar} setAvatar={setAvatar}/>}
                        {showDetails && <DetailsScores isEbloomModel={isEbloomModel} isModelCustomEnable={isModelCustomEnable} setShowDetails={setShowDetails} details={details}/>}

                        <div className={'inset-0 px-20 pt-30'}>
                            <div className={' grid grid-cols-12 justify-center gap-y-50 gap-x-35'}>
                                <div className={'col-start-1 col-end-5 flex justify-between flex-col '}>
                                    <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myParticipation"))} tooltip={_.upperFirst(t("employee.dashboard.myParticipationHelper"))}>
                                        <MyParticipation totalCB={cbUser.total} answered={cbUser.answered}
                                                         participation={participationRate} history={history} previousDate={previousDate} />
                                    </MyBloomContainer>
                                    <div className={"mt-40"}>
                                        <ActivityMenu activeSurveys={surveys}/>
                                    </div>
                                </div>

                                <div className={'col-start-5 col-end-13'}>
                                    <MyBloomDoubleContainer colorDivider={PaletteColors[avatar.color].bg} title1={_.upperFirst(t("employee.dashboard.iFeel"))} title2={_.upperFirst(t("employee.dashboard.myScores"))}>
                                        <div>
                                            {loadingAvatar ? <LoadingView/> : <div className={"pointer m-auto max-w-[250px]"} onClick={() => {setShowEdit(true)}}><Avatar avatar={avatar} /></div>  }


                                            <div className={'px-20 flex justify-between items-end absolute bottom-0 w-full'}>
                                                <div>
                                                    {
                                                        loadingScores ?
                                                            <LoadingView/>
                                                            :
                                                            <div className={"flex flex-row items-end"}>
                                                                <p className={'text-contrast-purple font-semibold text-lg cursor-default'}>
                                                                    <EbloomTooltip text={_.upperFirst(t("employee.dashboard.bloomIndexHelper"))}>
                                                            <span className={'text-4xl font-sans'}
                                                                  style={{color: (statOfAvg.total > 0 && statOfAvg.avg !== null && statOfAvg.avg > -1 ? getColorFromPercentage(statOfAvg.avg) : Colors.EBLOOM_CONTRAST_PURPLE)}}>
                                                                {(statOfAvg.total > 0 && statOfAvg.avg !== null && statOfAvg.avg > -1 ? Math.floor(statOfAvg.avg) : _.toUpper(t("webloom.NA")))}
                                                            </span>
                                                                    </EbloomTooltip>
                                                                    /100
                                                                </p>

                                                                <p className={"text-center"}>{displayPreviousExerciceAvg()}</p>
                                                            </div>

                                                    }

                                                </div>
                                                <div>
                                                    <button onClick={() => {
                                                        setShowEdit(true)
                                                    }}>
                                                        <img className={'w-24'} src={edit} alt={'edit'}/>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <MyScores isModelCustomEnable={isModelCustomEnable} categoriesScores={categoriesScores} loadingCategories={loadingScores}
                                                  startDate={startDate} endDate={endDate} setShowDetails={setShowDetails}
                                                  details={details} setDetails={setDetails}/>
                                    </MyBloomDoubleContainer>

                                </div>
                                <div className={"col-span-12 grid grid-cols-4 gap-x-30 h-500"}>
                                    <div className={"col-span-2"}>
                                        <WebloomContainer isProspect={isProspect} setShowModal={setShowModal} title={"WeBloom"} surveys={surveys} fetchSurveys={checkSurveys} avatar={avatar} loadingWeBloom={loadingWeBloom} />
                                    </div>
                                    <div className={"col-span-2 flex flex-col justify-start"}>
                                        <div className={' w-full mb-70'}>
                                            <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myVotes"))} tooltip={_.upperFirst(t("employee.dashboard.myVotesHelper"))}>
                                                <MyVotes statFromStart={statFromStart} loadingFromStart={loadingScores}
                                                         startDate={startDate}
                                                         endDate={endDate}/>
                                            </MyBloomContainer>
                                        </div>
                                        <div className={"mt-30"}>
                                            <Quotes/>
                                        </div>
                                    </div>
                                </div>

                                <div className={'col-start-1 col-end-7'}>
                                    <MyBloomContainer title={_.upperFirst(t("employee.dashboard.organisationAndMe"))} tooltip={_.upperFirst(t("employee.dashboard.organisationAndMeHelper"))}>
                                        <MyOrganisation isModelCustomEnable={isModelCustomEnable} avatar={avatar} radarData={radarData} loadingRadar={loadingRadar}/>
                                    </MyBloomContainer>
                                </div>

                                <div className={'col-start-7 col-end-13'}>
                                    <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myPriorities"))} tooltip={_.upperFirst(t("employee.dashboard.myPrioritiesHelper"))}>
                                        <MyPriorities isModelCustomEnable={isModelCustomEnable} statOfBubble={statOfBubble} loadingFocusMatrix={loadingFocusMatrix}/>
                                    </MyBloomContainer>
                                </div>

                                <div className={'col-span-full mb-70'}>
                                    <MyBloomContainer title={_.upperFirst(t("employee.dashboard.myActivity"))}>
                                        <MyActivity isModelCustomEnable={isModelCustomEnable} history={history} loading={loadingActivity}/>
                                    </MyBloomContainer>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
        }
        <ProspectModal show={showModal} handleClose={() => setShowModal(false)} />
    </div>

}
MyBloom.propTypes = {
    startDate:PropTypes.string,
    endDate:PropTypes.string,
    handleBgColor : PropTypes.func.isRequired,
    timeFilterVersion : PropTypes.number.isRequired
}

MyBloom.defaultProps = {
    startDate:"1995-12-25",
    endDate:moment().format("YYYY-MM-DD")
}
export default MyBloom