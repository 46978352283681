import React from "react";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const EbloomGuide = (props) => {

    const t = useTranslation()

    const lines = [{name:"Click&Bloom", user:true, advancedUser:true,admin:true},
        {name:"admin.roleGuide.individualDashboard", user:true, advancedUser:true,admin:true},
        {name:"admin.roleGuide.collabParticipation", user:true, advancedUser:true,admin:true},
        {name:"admin.roleGuide.resultsDashboard", user:false, advancedUser:true,admin:true},
        {name:"admin.roleGuide.collabManagement", user:false, advancedUser:true,admin:true},
        {name:"admin.roleGuide.planCB", user:false, advancedUser:false,admin:true},
        {name:"admin.roleGuide.appSettings", user:false, advancedUser:false,admin:true}
    ];

    const renderIcon = (value) => {
        return value ? <div className={"m-auto check_purple_icon micro_icon"}/> : "";
    }

    return(
        <div className={"pl10 pr10 pt10 pb10"}>
            <Row>
                <Col md={6} className={"purple-border"}></Col>
                <Col md={2} className={"center purple-border contrast-purple ebloom-text ebloom-small-text cursorDefault"}><span>User</span></Col>
                <Col md={2} className={"center purple-border contrast-purple ebloom-text ebloom-small-text cursorDefault"}>Advanced User</Col>
                <Col md={2} className={"center purple-border contrast-purple ebloom-text ebloom-small-text cursorDefault"}>Admin</Col>
            </Row>
            {
                lines.map((line, index) => (
                    <Row key={index}>
                        <Col md={6} className={"center purple-border contrast-purple ebloom-normal-text cursorDefault"}>{_.upperFirst(t(line.name))}</Col>
                        <Col md={2} className={"center purple-border contrast-purple"}>{renderIcon(line.user)}</Col>
                        <Col md={2} className={"center purple-border contrast-purple"}>{renderIcon(line.advancedUser)}</Col>
                        <Col md={2} className={"center purple-border contrast-purple"}>{renderIcon(line.admin)}</Col>
                    </Row>
                ))
            }
        </div>
    )
}

export default EbloomGuide;