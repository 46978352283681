import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import ProgressBar from "./ProgressBar"
import ResultFrame from "./ResultFrame";
import EbloomPieChartV2 from "../../../../../../Core/Modules/Chart/EbloomPieChartV2";
import {PollTypes} from "../../Modules/Types";
import EbloomBarChart from "../../../../../../Core/Modules/Chart/EbloomBarChart";
import EbloomYesNoSpeedometer from "../../../../../../Core/Modules/Chart/EbloomYesNoSpeedometer";
import {toPng} from "html-to-image";
import download from "downloadjs";
import _ from "lodash";
import { copyImageToClipboard } from 'copy-image-clipboard'
import {notifyError, notifySuccess} from "../../../../../../../Utils/Notification";
import {PurpleSwitch, replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import logo_ebloom from "../../../../../../Core/symbols/logo/ebloom.svg";
import {useTranslation} from "react-multi-lang/lib";

const Choice = (props) => {

    const [total, setTotal] = useState(1);
    const [showGraph, setShowGraph] = useState(false);
    const [isSorted, setIsSorted] = useState(false);
    const [showOthers, setShowOthers] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const {question, showAllGraph, lang, anonymityRestriction, noData,setShowModalProspect, isProspect} = props;

    const t = useTranslation();

    const handleShowGraph = () => {
        setShowGraph(!showGraph);
    }

    const handleSort = () => {
        setIsSorted(!isSorted);
    }

    const handleCopy = () => {
        let node = document.getElementById("result-frame-" + question.id);
        let img = document.getElementById("img-" + question.id);
        let switchNode;
        img.style.visibility = "visible";
        if(!showOthers && Boolean(question.show_other) === true && Boolean(question.define_other) === true){
            switchNode = document.getElementById("switch-" + question.id);
            switchNode.style.visibility = "hidden";
        }
        toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
            img.style.visibility = "hidden";
            if(!showOthers && Boolean(question.show_other) === true && Boolean(question.define_other) === true){
                switchNode.style.visibility = "visible";
            }
            copyImageToClipboard(dataUrl).then(() => {
                notifySuccess(_.upperFirst(t("webloom.notification.imageCopied")));
            }).catch(error => {
                notifyError(error);
            })
        })
    }

    const handleDownload = () => {
        if(!loadingDownload){
            setLoadingDownload(true);
            let node = document.getElementById("result-frame-" + question.id);
            let img = document.getElementById("img-" + question.id);
            let switchNode;
            img.style.visibility = "visible";
            if(!showOthers && Boolean(question.show_other) === true && Boolean(question.define_other) === true){
                switchNode = document.getElementById("switch-" + question.id);
                switchNode.style.visibility = "hidden";
            }
            toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
                img.style.visibility = "hidden";
                if(!showOthers && Boolean(question.show_other) === true && Boolean(question.define_other) === true){
                    switchNode.style.visibility = "visible";
                }
                download(dataUrl, "webloom-" + question.title + '.png');
            }).finally(() => {
                setLoadingDownload(false);
            })
        }
    }

    const sortedArray = () => {
        let clone = _.cloneDeep(question.options);
        if(isSorted){
            return clone.sort((a,b) => {return b.selected-a.selected});
        }
        return clone;
    }

    useEffect(() => {
        let count = 0;
        question.options?.forEach(option => {
            count+=option.selected;
        })

        if(question.type === PollTypes.MULTIPLE_CHOICES && question.nbUsers !== null){
            setTotal(question.nbUsers);
        }else{
            setTotal(count);
        }
    }, [question]);

    useEffect(() => {
        setShowGraph(showAllGraph);
    }, [showAllGraph])

    return(
        <ResultFrame question={question} handleShowGraph={handleShowGraph} showGraph={showGraph} handleSort={handleSort} handleCopy={handleCopy} handleDownload={handleDownload} anonymityRestriction={anonymityRestriction} lang={lang} setShowModalProspect={setShowModalProspect} isProspect={isProspect}>
            {
                (!showGraph || !total) ? sortedArray()?.map((option, index) => (
                        <ProgressBar option={option} total={total} type={question.type} key={index} lang={lang} anonymityRestriction={anonymityRestriction} noData={noData}/>
                    ))
                    :
                    question.type === PollTypes.UNIQUE_CHOICE ?
                        <EbloomPieChartV2 data={question.options} lang={lang} anonymityRestriction={anonymityRestriction}/>
                        :
                        question.type === PollTypes.YES_NO ?
                            <EbloomYesNoSpeedometer data={question.options} total={total} lang={lang} anonymityRestriction={anonymityRestriction}/>
                            :
                            <EbloomBarChart data={question.options} total={total} lang={lang} anonymityRestriction={anonymityRestriction}/>

            }
            {
                (Boolean(question.show_other) === true && Boolean(question.define_other) === true && !anonymityRestriction) &&
                <div id={"switch-" + question.id} className={"report-hide"}>
                    <PurpleSwitch checked={showOthers} onChange={() => {setShowOthers(!showOthers)}}/><span className={"text-contrast-purple"}>{_.upperFirst(t("core.showParam", {param:t("webloom.other")}))}</span>
                    <div className={"w-full max-h-100 overflow-y-auto mt-10"}>
                    {
                        showOthers && question.others.map((other, index) => (
                            <li key={index} className={"text-contrast-purple"}>{other.text}</li>
                        ))
                    }
                    </div>
                </div>
            }
            <img id={"img-" + question.id} alt={"logo-ebloom"} src={logo_ebloom} width={50} style={{visibility:"hidden"}}/>
        </ResultFrame>

    )
}

Choice.propTypes = {
    question:PropTypes.object.isRequired,
    lang:PropTypes.string.isRequired,
    showAllGraph:PropTypes.bool,
    anonymityRestriction:PropTypes.bool,
}

Choice.defaultProps = {
    anonymityRestriction:true
}

export default Choice;