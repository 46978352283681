import React from 'react';
import PropTypes from 'prop-types';

const NbCbIcon = (props) => {
    const {colors,width,height} = props

    return (
        <svg style={{height : height, width: width}} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{fill: colors? colors.primary : "#5D00A2"}}
                d="M23.5139 1.74755L2.18283 0C0.976761 0 0 1.03952 0 2.32202V18.6502C0 19.9327 0.976761 20.9722 2.18283 20.9722L16.7111 19.2246C16.5309 20.4959 16.8367 22.0696 17.6412 24.0022C18.2849 25.551 18.8626 26.0467 19.075 25.9968C19.0911 25.9923 19.106 25.9841 19.1183 25.9727C19.1306 25.9614 19.14 25.9473 19.1458 25.9316C19.2263 25.7812 19.2359 25.3974 19.0291 24.7714C18.0492 21.8033 18.7073 20.1394 19.565 19.2246L23.6683 18.5874C24.67 18.5874 25.4843 17.7209 25.4843 16.6524L25.3322 3.68256C25.333 2.61408 24.5188 1.74755 23.5139 1.74755Z"
                />
            <path
                d="M20 8.25V6.5H17.498L17.998 3H15.998L15.498 6.5H11.499L11.999 3H9.999L9.499 6.5H6V8.25H9.249L8.75 11.75H6V13.5H8.5L8 17H10L10.5 13.5H14.5L13.999 17H16L16.5 13.5H20V11.75H16.75L17.248 8.25H20ZM14.75 11.75H10.75L11.249 8.25H15.248L14.75 11.75Z"
                fill="white"/>
        </svg>
    );
};

NbCbIcon.propTypes = {
    colors : PropTypes.object,
    width : PropTypes.number,
    height : PropTypes.number
};

NbCbIcon.defaultProps = {
    color: null,
    width : 30,
    height : 30,
}

export default NbCbIcon;
