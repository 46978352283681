import React, {useEffect, useState} from "react";
import {ReactComponent as StarIcon} from "../../../../../../Core/symbols/webloom/white/star-white.svg";
import {ReactComponent as HeartIcon} from "../../../../../../Core/symbols/webloom/white/heart-3-parts.svg";
import ResultFrame from "./ResultFrame";
import EbloomStarRatingBarChart from "../../../../../../Core/Modules/Chart/EbloomStarRatingBarChart";
import {toPng} from "html-to-image";
import {copyImageToClipboard} from "copy-image-clipboard";
import {notifyError, notifySuccess} from "../../../../../../../Utils/Notification";
import download from "downloadjs";
import logo_ebloom from "../../../../../../Core/symbols/logo/ebloom.svg";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const StarRating = (props) => {

    const [showGraph, setShowGraph] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const {question, showAllGraph, anonymityRestriction, noData,setShowModalProspect, isProspect} = props;

    const t = useTranslation();

    const handleShowGraph = () => {
        setShowGraph(!showGraph);
    }

    const handleCopy = () => {
        let node = document.getElementById("result-frame-" + question.id);
        let img = document.getElementById("img-" + question.id);
        img.style.visibility = "visible";
        toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
            img.style.visibility = "hidden";
            copyImageToClipboard(dataUrl).then(() => {
                notifySuccess(_.upperFirst(t("webloom.notification.imageCopied")));
            }).catch(error => {
                notifyError(error);
            })
        })
    }

    const handleDownload = () => {
        if(!loadingDownload){
            setLoadingDownload(true);
            let node = document.getElementById("result-frame-" + question.id);
            let img = document.getElementById("img-" + question.id);
            img.style.visibility = "visible";
            toPng(node, {style:{backgroundColor:"white"}}).then((dataUrl) => {
                img.style.visibility = "hidden";
                download(dataUrl, 'webloom-'+question.title+'.png');
            }).finally(() => {
                setLoadingDownload(false);
            })
        }
    }

    const getArrayOfItems = () => {
        let array = [];
        for(let i = 0; i<question.nb_items;i++){
            array.push(i);
        }
        return array;
    }

    const getClassName = (index) => {
        if(anonymityRestriction){
            return "border-contrast-purple-icon h-40 w-40 ml-5 mr-5"
        }
        if(index > getAvgAnswers()){
            if(index-getAvgAnswers() >= 1){
                return "border-contrast-purple-icon h-40 w-40 ml-5 mr-5"
            }else{
                return "half-contrast-purple-icon border-contrast-purple-icon h-40 w-40 ml-5 mr-5"
            }
        }
        return "contrast-purple-icon h-40 w-40 ml-5 mr-5"
    }

    const getAvgAnswers = () => {
        if(question.answers === null || question.answers.length === 0){
            return 0;
        }
        return Number((question.answers.reduce((p, c) => p + c.score, 0) / question.answers.length).toFixed(1));
    }

    useEffect(() => {
        setShowGraph(showAllGraph);
    }, [showAllGraph])

    return(
        <ResultFrame question={question} handleShowGraph={handleShowGraph} showGraph={showGraph} handleCopy={handleCopy} handleDownload={handleDownload} anonymityRestriction={anonymityRestriction} setShowModalProspect={setShowModalProspect} isProspect={isProspect}>
            {
                !showGraph ?
                    <div className={"flex items-center mt-20"}>
                        {
                            getArrayOfItems().map((item, index) => (
                                question.is_heart ?
                                    <HeartIcon className={getClassName(index+1)} key={index}/>
                                    :
                                    <StarIcon className={getClassName(index+1)} key={index}/>
                            ))
                        }
                        {
                            noData ?
                                <span className={"text-contrast-purple"}>{_.upperFirst(t("core.collaborative.noAnswer"))}</span>
                                :
                        <div className={"ebloom-text ebloom-xtra-large-text font-semibold text-contrast-purple ml-10"}>{anonymityRestriction ? _.upperFirst(t("manager.dashboard.anonymityRestrictionSmall")) : (getAvgAnswers() + "/" + question.nb_items)}</div>
                        }
                    </div>
                    :
                    <div className={"mt-20"}>
                        <EbloomStarRatingBarChart array={getArrayOfItems()} answers={question.answers || []} avgAnswers={getAvgAnswers()} isHeart={question.is_heart}/>
                    </div>
            }
            <img id={"img-" + question.id} alt={"ebloom-logo"} src={logo_ebloom} width={50} style={{visibility:"hidden"}}/>
        </ResultFrame>

    )
}

export default StarRating;