import React, {useState} from 'react';
import PropTypes from "prop-types";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const CommonSurveyButton = (props) => {
    const {handleSwitch,isActive,locked} = props

    const t = useTranslation("manager.dashboard.")
    const handleClick = () => {
        if(locked) return;
        handleSwitch(!isActive)
    };

    return (
        <div className="flex items-center justify-center ">
            <EbloomTooltip text={isActive ? _.upperFirst(t("filterCommonSurveysDisabled")): _.upperFirst(t("filterCommonSurveysEnabled"))}>
                <button
                    onClick={handleClick}
                    className={`w-60 h-34 rounded-md flex items-center justify-center bg-white  relative `}
                >
                    <div className="relative flex items-center justify-center ">
                    <span
                        className={`w-22 h-22  rounded-full absolute ${
                            isActive ? '-left-5 bg-white border-purple border-2' : 'left-1 bg-purple border-purple border-2'
                        } transition-all duration-300`}></span>
                        <span
                            className={`w-22 h-22 rounded-full absolute ${
                                isActive ? '-right-5 bg-white border-purple border-2 ' : 'right-1 bg-purple border-purple border-2'
                            } transition-all duration-300`}></span>
                        <span
                            className={`w-11 h-16 bg-purple rounded-full absolute border-2  border-purple ${
                                isActive ? '-left-2.5 right-2.5 opacity-100 ' : 'opacity-0'
                            } transition-all duration-300`}></span>
                    </div>
                </button>
            </EbloomTooltip>

        </div>
    );
};

CommonSurveyButton.propTypes = {
    handleSwitch: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    locked: PropTypes.bool.isRequired,
};

export default CommonSurveyButton;
