import {ModelTypes} from "../../../models/Types";

const NotificationToken = {
    crud:{
        create:"/integration/notification/token/save",
        getAll:"/integration/notification/token/getAll"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{
            show:false,
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },

    user:{
        id:"email",
        type:ModelTypes.PRISMA_RELATION,
        relationLink:(value) => {return value.email},
        label:"core.login.email",
        table:{
            show:true
        },
        list:{
            show:true
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },

    company:{
        id:"company",
        type:ModelTypes.PRISMA_RELATION,
        relationLink:(value) => {return value.name},
        label:"core.company.company",
        table:{
            show:true
        },
        list:{
            show:true
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },
    
    token:{
        id:"token",
        type:ModelTypes.TEXT,
        label:"backoffice.token",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },
    
    created_at:{
        id:"created_at",
        type:ModelTypes.DATE,
        label:"core.created_at",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATE,
        label:"core.updated_at",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATE,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },
};


export default NotificationToken;