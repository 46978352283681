import React from "react";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const PollTypeCard = (props) => {
    const {type} = props;
    const t = useTranslation();

    return(
        <div className={"flex mt10 mb10"}>
            <div className={"w-61 h-61 bg-contrast-blue flex justify-center items-center rounded-l-lg"}><type.icon className={"w-28 h-28 border-contrast-blue-icon"}/></div>
            <div className={"w-201 h-61 bg-contrast-blue/[0.3] flex justify-center items-center rounded-r-lg ebloom-text ebloom-large-text"}>{_.upperFirst(t("webloom."+type.type))}</div>
        </div>
    )
}

export default PollTypeCard;