import React from "react";
import PropTypes from "prop-types";
import {PollTypes} from "../../Modules/Types";
import {useTranslation} from "react-multi-lang";
import _ from "lodash";


export const OpenQuestion = (props) => {

    const {propQuestion} = props;

    const t = useTranslation();

    return(
        <div className={"w-450 min-h-45 bg-contrast-blue/[0.1] rounded-md mt-20 p-10"}>
            {
                propQuestion.type === PollTypes.IDENTIFICATION &&
                <span className={"text-contrast-purple italic"}>{_.upperFirst(t("webloom.identificationHelper"))}</span>
            }
        </div>
    )
}

OpenQuestion.propTypes = {
    propQuestion:PropTypes.object
}