import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import React, {useContext, useEffect, useState} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import {Modules, TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {manageUserSteps} from "./manageUserSteps";
import PropTypes from "prop-types";
import {GlobalContext} from "../../../Context/GlobalContext";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import {manageUserPackageStarterSteps} from "./manageUserPackageStarterSteps";
import {useUserData} from "../../../../hooks/userHook";

const ManageUsersTour = (props) => {
    const {setMenuOpen,setSideEdit,seePreview} = props

    const {
        setSideFormUploadOpen,
        setExcelData,
        showComparison, setShowComparison
    }= useContext(GlobalContext)

    const {
        updateStep,
        stepTour,
        stepManageUsers, setStepManageUsers,
        tourStartedManageUsers, setTourStartedManageUsers,
        tourActivated,
        styleEbloom,
        fetchData,
        validateOutcome,
        initialStep, setInitialStep,
        icon,
        updateHandler,
        continuous,setContinuous
    } = useContext(ProductTourContext)

    const [updated, setUpdated] = useState(false);
    const user = useUserData();
    const isPackageStarter = Modules.HAPPY.includes(user.module)

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setTourStartedManageUsers(false)
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if (ACTIONS.NEXT === action) {
                if(index === 0) {
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 100)
                }else if (index===2) {
                    setMenuOpen(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if (index === 5) {
                    setSideEdit(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if(index === 7) {
                    setMenuOpen(true)
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 200)
                }else if(index === 8) {
                    setSideFormUploadOpen(true)
                    setExcelData([])
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 400)
                }else if(index === 10) {
                    setContinuous(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if (index === 11|| index === 12 || index === 13) {
                    updateStep(TourType.MANAGE_USERS, next)
                }else{
                    updateStep(TourType.MANAGE_USERS,next)
                }

            }else if (ACTIONS.PREV === action) {
                if(index === 1) {
                    setMenuOpen(false)
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 100)
                }else if (index === 3) {
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, 0)
                    }, 200)
                }else if (index === 5) {
                    setSideEdit(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if (index === 6) {
                    updateStep(TourType.MANAGE_USERS, 4)
                }else if (index === 8) {
                    setMenuOpen(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if(index === 9) {
                    document.getElementById("input-users").value = null;
                    setSideFormUploadOpen(false)
                    setMenuOpen(true)
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 200)
                }else if (index === 11) {
                    setContinuous(true)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if(index === 12) {
                    setShowComparison(false)
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, 7)
                    }, 200)
                }else{
                    updateStep(TourType.MANAGE_USERS,next)
                }
            }else if(ACTIONS.CLOSE === action){
                if(index === 11){
                    updateStep(TourType.MANAGE_USERS,11)
                }
            }
        }
    }

    const handleJoyrideCallbackPackageStarter = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatusStarterPackage()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if (ACTIONS.NEXT === action) {
                if(index === 0) {
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 100)
                }else if (index===2) {
                    setMenuOpen(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if (index === 5) {
                    setSideEdit(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else{
                    updateStep(TourType.MANAGE_USERS,next)
                }

            }else if (ACTIONS.PREV === action) {
                if(index === 1) {
                    setMenuOpen(false)
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, next)
                    }, 100)
                }else if (index === 3) {
                    setTimeout(() => {
                        updateStep(TourType.MANAGE_USERS, 0)
                    }, 200)
                }else if (index === 5) {
                    setSideEdit(false)
                    updateStep(TourType.MANAGE_USERS, next)
                }else if (index === 6) {
                    updateStep(TourType.MANAGE_USERS, 4)
                }
            }
        }
    }



    const handleTour = () => {
        setContinuous(true)
        if(stepTour[TourType.MANAGE_USERS]) {
            const step = stepTour[TourType.MANAGE_USERS].step
            if (step === 1 || step === 2) {
                updateStep(TourType.MANAGE_USERS, 0)
            } else if (step === 5) {
                updateStep(TourType.MANAGE_USERS, 4)
            } else if (step >= 7) {
                updateStep(TourType.MANAGE_USERS, 7)
            }
        }else{
            updateStep(TourType.MANAGE_USERS, 0)
        }
        setTimeout(() => {
            setTourStartedManageUsers(true)
        }, 350)
    }
        const handleTourStarterPackage = () => {
        setContinuous(true)
        if(initialStep === stepTour[TourType.MANAGE_USERS].step && !updated){
            //Resume product tour from another page
            if(initialStep === 5){
                updateStep(TourType.MANAGE_USERS,4)
            }else if(initialStep === 2 || initialStep === 1 ){
                updateStep(TourType.MANAGE_USERS,0)
            }
            setTimeout(() => {
                setTourStartedManageUsers(true)
            },350)
        }else {
            //Resume product tour from current page
            if(stepManageUsers === 1 || stepManageUsers === 2){
                updateStep(TourType.MANAGE_USERS,0)
            }else if (stepManageUsers === 5){
                updateStep(TourType.MANAGE_USERS,4)
            }
            setTimeout(() => {
                setTourStartedManageUsers(true)
            },350)
        }
    }
    const updateTourStatus = () => {
        setMenuOpen(false)
        setSideEdit(false)
        setSideFormUploadOpen(false)
        setTourStartedManageUsers(false)
        const tour = {
            id : stepTour[TourType.MANAGE_USERS].id,
            current_step : stepTour[TourType.MANAGE_USERS].step >= manageUserSteps.length - 1 ? 0 : stepTour[TourType.MANAGE_USERS].step,
            type: TourType.MANAGE_USERS,
            finished : stepTour[TourType.MANAGE_USERS].outcome
        }
        if(stepManageUsers >= manageUserSteps.length - 1){
            validateOutcome(TourType.MANAGE_USERS)
            setStepManageUsers(0)
            if(icon && icon.current){
                icon.current.classList.remove('ebloom-ring')
            }
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{
                setInitialStep(tour.current_step)
            })
        }
        setUpdated(true)
        updateHandler(TourType.MANAGE_USERS,handleTour)

        iconRinging()

    }
    const updateTourStatusStarterPackage = () => {
            setMenuOpen(false)
            setSideEdit(false)
            setSideFormUploadOpen(false)
            setTourStartedManageUsers(false)
            const tour = {
                id: stepTour[TourType.MANAGE_USERS].id,
                current_step: stepTour[TourType.MANAGE_USERS].step >= manageUserPackageStarterSteps.length - 1 ? 0 : stepTour[TourType.MANAGE_USERS].step,
                type: TourType.MANAGE_USERS,
                finished: stepTour[TourType.MANAGE_USERS].outcome
            }
            if (stepManageUsers >= manageUserPackageStarterSteps.length - 1) {
                validateOutcome(TourType.MANAGE_USERS)
                setStepManageUsers(0)
                if (icon && icon.current) {
                    icon.current.classList.remove('ebloom-ring')
                }
            } else {
                HttpApi.post('/productTour/updateTour', tour).then(() => {
                    setInitialStep(tour.current_step)
                })
            }
            setUpdated(true)
            updateHandler(TourType.MANAGE_USERS, handleTourStarterPackage)
            iconRinging()
    }

    const iconRinging = () => {
        if(icon && icon.current && stepTour[TourType.MANAGE_USERS]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.MANAGE_USERS].outcome){
                if((stepManageUsers >=0 && stepManageUsers <= 12) && (initialStep >= 0 && initialStep <= 12) && !showComparison){
                    icon.current.classList.add('ebloom-ring')
                }else if((stepManageUsers >=13 && stepManageUsers <= 15) && (initialStep >=13 && initialStep <= 15) && showComparison){
                    icon.current.classList.add('ebloom-ring')
                }else if (stepManageUsers> initialStep && showComparison) {
                    icon.current.classList.add('ebloom-ring')
                }
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepManageUsers,stepTour,tourActivated,showComparison]);


    //Need this useEffect because of the save Step when you close the side form
    // it turns off the tour and not update the tour
    useEffect(() => {
       if(!tourStartedManageUsers && tourActivated){
           if(isPackageStarter){
               updateTourStatusStarterPackage()
           }else{
               updateTourStatus()
           }
       }
    }, [tourStartedManageUsers]);


    useEffect(() => {
        fetchData();
        setInitialStep(stepManageUsers)
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        if(isPackageStarter){
            updateHandler(TourType.MANAGE_USERS,handleTourStarterPackage)
        }else{
            updateHandler(TourType.MANAGE_USERS,handleTour)
        }

        return () => {
            setTourStartedManageUsers(false)
        }
    }, []);

    useEffect(() => {
        if (!seePreview && !showComparison){
            iconRinging()
        }
    }, [seePreview,showComparison]);



    return(
        <>
            <ReactJoyride
                steps={isPackageStarter ? manageUserPackageStarterSteps :manageUserSteps}
                continuous={continuous}
                run={tourStartedManageUsers}
                stepIndex={stepManageUsers}
                callback={isPackageStarter ? handleJoyrideCallbackPackageStarter :handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                scrollToFirstStep={false}
                hideCloseButton={true}
                debug={false}
                styles={styleEbloom}
                tooltipComponent={EbloomTourTip}
            />

        </>
    )
}

ManageUsersTour.prototype = {
    setMenuOpen : PropTypes.func.isRequired,
    setSideEdit : PropTypes.func.isRequired,
    seePreview : PropTypes.bool.isRequired,
}

export default ManageUsersTour