import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types"
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import {MenuItem} from "@mui/material";
import EbloomSearchBar from "../../../../../../Core/Modules/Input/EbloomSearchBar";
import {ReactComponent as SortIcon} from "../../../../../../Core/symbols/webloom/white/sort-white.svg"
import _ from "lodash";
import moment from "moment";
import BubbleChat from "./BubbleChat";
import HttpApi from "../../../../../../../httpApi";
import {notifyError} from "../../../../../../../Utils/Notification";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as AnswerArrow} from "../../../../../../Core/symbols/webloom/white/answer-arrow-white.svg";
import {useUserData} from "../../../../../../../hooks/userHook";
import {displayDate} from "../../../../../../../Utils/Global";
import EbloomTooltip from "../../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const BubbleChatList = (props) => {

    //state
    const [searchValue, setSearchValue] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortBy, setSortBy] = useState("mostRecent");
    const [openAnswer, setOpenAnswer] = useState(0);

    const {answers, handleMarkAsRead,consultOnly, showAnswerArrow, handleSaveConversation} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData();

    //getters

    //posters
    const markAsRead = (id) => {
        if(!consultOnly){
            HttpApi.post("/collaborative/updateAnswerReadState", {idAnswer:id}).then(() => {
                handleMarkAsRead(id);
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    //handlers
    const handleOpenAnswer = (id) => {
        if(openAnswer === id){
            setOpenAnswer(0);
        }else{
            setOpenAnswer(id);
        }
    }
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    }

    const handleSortBy = (value) => {
        setSortBy(value);
        setAnchorEl(null);
    }
    const getSort = (a, b) => {
        if(sortBy === "mostRecent"){
            if(a.created_at<b.created_at){
                return 1;
            }
            return -1;
        }
        if(sortBy === "lessRecent"){
            if(a.created_at<b.created_at){
                return -1;
            }
            return 1;
        }
        if(sortBy === "unread"){
            if(a.isRead > b.isRead){
                return 1;
            }
            return -1
        }
        if(sortBy === "read"){
            if(a.isRead > b.isRead){
                return -1;
            }
            return 1
        }
        return 0;
    }

    //useEffect
    useEffect(() => {
        if(true === showAnswerArrow){
            setSortBy("lessRecent");
        }
    }, [showAnswerArrow]);

    return (
        <div className={"px-10"}>
            <div className={"flex justify-between items-center mt-10 report-hide"}>
                <div>
                    <IconButton size={"small"} onClick={(event) => {setAnchorEl(event.target)}}>
                        <SortIcon className={"w-20 h-20 purple-icon"}/>
                    </IconButton>
                    <span className={"text-contrast-purple"}>{_.upperFirst(t("webloom." + sortBy))}</span>
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => {setAnchorEl(null)}}>
                        <MenuItem onClick={() => {handleSortBy("mostRecent")}}>{_.upperFirst(t("webloom.mostRecent"))}</MenuItem>
                        <MenuItem onClick={() => {handleSortBy("lessRecent")}}>{_.upperFirst(t("webloom.lessRecent"))}</MenuItem>
                        <MenuItem onClick={() => {handleSortBy("unread")}}>{_.upperFirst(t("webloom.unread"))}</MenuItem>
                        <MenuItem onClick={() => {handleSortBy("read")}}>{_.upperFirst(t("webloom.read"))}</MenuItem>
                    </Menu>
                </div>
                <EbloomSearchBar handleChange={handleSearch} searchValue={searchValue} size={"small"}/>
            </div>
            <div className={"mt-20 max-h-500 overflow-y-auto px-5"}>
                {
                    answers && answers.length > 0 && answers.filter(el => _.lowerCase(el.text).includes(_.lowerCase(searchValue)) || moment(el.created_at).format("DD/MM/YY HH:mm").includes(searchValue)).sort((a,b) => {return getSort(a, b)}).map((answer, index) => (
                        <div className={"flex items-center"} key={index}>
                            <div className={"my-5 pointer w-full p-5 " + (openAnswer === answer.id && "border border-dashed border-contrast-blue rounded-md")}>
                                <div className={"text-contrast-purple ebloom-text ebloom-very-small-text text-right"}>{moment(answer.created_at).format("DD/MM/YY HH:mm")}</div>
                                <div className={"my-5"} onClick={consultOnly ? () => {if(answer.conversations){handleOpenAnswer(answer.id)}} : () => {if(!answer.isRead && !showAnswerArrow){ markAsRead(answer.id)} if(answer.conversations){handleOpenAnswer(answer.id)}}}>
                                    <BubbleChat text={answer.text} isRead={answer.isRead} nbConversations={answer.conversations?.length}/>
                                </div>
                                {
                                    openAnswer === answer.id &&
                                    <div>
                                        {
                                            answer.conversations?.map((conversation, index) => (
                                                    <div className={"my-5 px-20"} key={index}>
                                                        <div className={"text-contrast-purple ebloom-text ebloom-very-small-text text-right"}>{displayDate(conversation.created_at, true)}</div>
                                                        <BubbleChat text={conversation.text}
                                                                    isRead={conversation.id_user === userData.id ? true : conversation.isRead}
                                                                    isMyMessage={answer.id_user === userData.id ? conversation.id_user === userData.id : conversation.id_user !== answer.id_user}/>
                                                    </div>
                                                ))
                                        }
                                        {
                                            handleSaveConversation &&
                                            <div className={"my-5 px-10"}>
                                                <BubbleChat text={""} isRead={true} isMyMessage handleSave={(text) => {handleSaveConversation(text, answer.id); markAsRead(answer.id)}}/>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {
                                showAnswerArrow && !consultOnly &&
                                <div className={"w-fit ml-5"}>
                                    <EbloomTooltip text={_.upperFirst(t("core.toAnswer"))}>
                                        <IconButton size="small" onClick={() => handleOpenAnswer(answer.id)}>
                                            <AnswerArrow className={"w-25 h-25 purple-icon"}/>
                                        </IconButton>
                                    </EbloomTooltip>
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
            <div className={"mt-20 flex items-center justify-between text-contrast-purple report-hide"}>
                <div>{_.upperFirst(t("webloom.unread"))}<span className={"font-semibold ml-5"}>{answers ? answers.filter(el => !el.isRead).length : 0}</span></div>
                <div>{_.upperFirst(t("webloom.total"))}<span className={"font-semibold ml-5"}>{answers ? answers.length : 0}</span></div>
            </div>
        </div>
    )
}

BubbleChatList.propTypes = {
    answers:PropTypes.array.isRequired,
    showAnswerArrow:PropTypes.bool,
    handleMarkAsRead:PropTypes.func.isRequired,
    handleSaveConversation:PropTypes.func,
    consultOnly : PropTypes.bool
}

BubbleChatList.defaultProps = {
    consultOnly : false,
    showAnswerArrow:false,
}
export default BubbleChatList