import React from "react";
import PropTypes from "prop-types";
import {PollQuestion} from "./PollQuestion";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import moment from "moment";
import LoadingView from "../../../../../../Core/Layouts/LoadingView";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {displayDate, replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import {useUserData} from "../../../../../../../hooks/userHook";
import {Colors} from "../../../../../../../Utils/Colors";

const AnswerPoll = (props) => {
    const {survey, loading, handleSave} = props;

    const user = useUserData()
    const t = useTranslation();

    return(
        <div className={"w-3/5 m-auto cursorDefault"}>
            <div className={"ebloom-normal-text text-contrast-purple mb-20"}>{displayDate(survey.start_date || survey.created_at, true) + " - " + (survey.end_date ? displayDate(survey.end_date, true) : _.upperFirst(t("webloom.noEndDefined")))}</div>
            {
                !!user.companyGroup && !!survey.id_company && survey.id_company !== user.companyId &&
                <div
                    className={"ebloom-normal-text text-contrast-purple mb-20"}>{_.upperFirst(t("webloom.from")) + " " }
                    <span style={{
                        color: user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_LOGO_BLUE
                    }} className={"font-semibold "}>{user.companyGroup.name}</span>
                </div>

            }
            <div
                className={"ebloom-title ebloom-small-title text-contrast-purple font-semibold"}>{replaceUrlsWithLinks(survey.title)}</div>
            <p className={"ebloom-normal-text text-contrast-purple mb-10 text-line-break"}>{replaceUrlsWithLinks(survey.description)}</p>
            {
                Boolean(survey.is_anonymous) === false &&
                <p className={"text-strong-red"}>{_.upperFirst(t("webloom.nonAnonymousHelper"))}</p>
            }
            <div className={"mb-10"}>
            {
                survey.image_url &&
                <FileDisplay className={"w-fit"} urlParams={survey.image_url} displayImg imgHeight={400}/>
            }
            </div>
            <div className={"flex justify-start " + ((survey.attachment_url || survey.image) ? "mb-50" : "mb-10")}>
            {
                survey.attachment_url &&

                    <FileDisplay urlParams={survey.attachment_url}/>
            }
            </div>
            {
                survey.questions.map((question, index) => (
                    <PollQuestion question={question} key={index} lang={survey.lang} {...props}/>
                ))
            }
            {
                handleSave &&
                <Button className={"ebloom-btn-purple mt-20 float-right"} disabled={loading} onClick={handleSave}>{loading ? <LoadingView size={16}/> : _.upperFirst(t("core.save"))}</Button>

            }
        </div>
    )
}

AnswerPoll.propTypes = {
    survey:PropTypes.object.isRequired,
    handleChange:PropTypes.func,
    loading:PropTypes.bool
}

AnswerPoll.defaultProps = {
    loading:false
}

export default AnswerPoll;