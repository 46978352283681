import {useEffect, useState} from 'react';
import HeaderEbloom from "../../Core/Layouts/HeaderEbloom";
import {Colors} from "../../../Utils/Colors";
import Login from "../../../login/login";
import HttpApi from "../../../httpApi";
import moment from "moment";
import {notifyError} from "../../../Utils/Notification";
import PropTypes from "prop-types";
import {useUserData} from "../../../hooks/userHook";
import {CompanyTypes} from "../../../Utils/Global";
import {useNavigationTo} from "../../../hooks/navigationHook";


const DisplayManagerView = ({children, bgColor, ...rest}) => {

    const user = useUserData();

    const [teamToDisplay, setTeamToDisplay] = useState([]);
    const [startDate, setStartDate] = useState(new Date(moment().subtract((user.companyType === CompanyTypes.DEMO ? 6 : 3), "month").format()));
    const [endDate, setEndDate] = useState(new Date(moment().format()));
    const [formattedStartDate, setFormattedStartDate] = useState(moment().subtract((user.companyType === CompanyTypes.DEMO ? 6 : 3), "month").format("YYYY-MM-DD"));
    const [formattedEndDate, setFormattedEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [timeFilterVersion, setTimeFilterVersion] = useState(0);
    const [nbActiveSurvey, setNbActiveSurvey] = useState(0);
    const [collabAccess, setCollabAccess] = useState(false);
    const [nbAnswersSurvey, setNbAnswersSurvey] = useState(0);
    const [nbSurvey, setNbSurvey] = useState(0);
    const [shouldDownloadReport, setShouldDownloadReport] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);
    const [reportDisabled, setReportDisabled] = useState(true);
    const [commonSurveyActivated, setCommonSurveyActivated] = useState(false);
    const [lockCommonSurvey, setLockCommonSurvey] = useState(false);
    const [validTeams, setValidTeams] = useState([]);
    const [dashboardAccess, setDashboardAccess] = useState(false);
    const [enoughTeamsForFilterSurvey, setEnoughTeamsForFilterSurvey] = useState(false);

    const isAdmin = Login.hasRole("global_admin");
    const isFree = Login.hasModule("free");
    const isProspect = user.module === "prospect"
    const isStarter = user.module === "happy"

    const navigation = useNavigationTo();

    const handleSwitchTeam = (teams) => {
        if(enoughTeamsForFilterSurvey){
            let lockFilter = teams.length === 1
            if(lockFilter){
                if(teams[0].id === 0) {
                    setCommonSurveyActivated(false)
                    lockFilter = false
                }else{
                    setCommonSurveyActivated(false)
                }
            }else{
                setCommonSurveyActivated(false)
            }
            setLockCommonSurvey(lockFilter)
        }


        setTeamToDisplay(teams);
    };

    const handleSwitchStartDate = (date) => {
        setStartDate(date);
        setFormattedStartDate(date ? moment(date).format("YYYY-MM-DD") : null);
    }

    const handleSwitchEndDate = (date, reload = false) => {
        setEndDate(date);
        setFormattedEndDate(date ? moment(date).format("YYYY-MM-DD") : null);
        if (date || reload) {
            setTimeFilterVersion(timeFilterVersion + 1);
        }

    }

    const checkIfCollabAccess = () => {
        if (Login.hasRole("global_manager")) {
            HttpApi.getV2("/teams/getUserAdvancedCollabTeamsWithUsers").then(result => {
                if (result && result.data && result.data.length > 0) {
                    setCollabAccess(true);
                    checkForCollabSurveyAnswers();
                } else {
                    setCollabAccess(false);
                }
            }).catch(error => {
                notifyError(error);
                setCollabAccess(false);
            })
        } else if (isAdmin) {
            checkForCollabSurveyAnswers();
        }
    }

    const checkIfSurveysTeamsExist = () => {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = !!endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        HttpApi.getV2("/questions/checkCompanyCustomAudiences/"+start+"/"+end).then(response => {
            if (response && response.data){
                const value = response.data.results
                setLockCommonSurvey(!value)
                setEnoughTeamsForFilterSurvey(value)
                if(!value){
                    setCommonSurveyActivated(false)
                }
            }else{
                setCommonSurveyActivated(false)
                setLockCommonSurvey(true)
                setEnoughTeamsForFilterSurvey(false)
            }
        }).catch(error => {
            notifyError(error);
        })
    }


    const fetchValidTeams = () => {
        if(Login.hasRole("global_manager") || isAdmin) {
            let url = isAdmin ? "/teams/getValid" : "/teams/getUserAdvancedCBTeamsWithUsers";
            HttpApi.getV2(url).then(response => {
                if(response && response.data) {
                    if(isAdmin){
                        setDashboardAccess(true);
                    }
                    setValidTeams(response.data);
                    const validTeams = response.data
                    if(validTeams.length >= 2) {
                        checkForCollabSurveyAnswers()
                    }
                    if(!isAdmin){
                        if(validTeams.length > 0 ) {
                            setDashboardAccess(true);
                        }else{
                            setDashboardAccess(false)
                            navigation.to("/employee/dashboard");
                        }
                    }
                }

            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const checkForCollabSurveyAnswers = () => {
        const url = "/collaborative/checkForNewCollabAnswers";
        if(isProspect){
            setNbAnswersSurvey(0)
        }else{
            HttpApi.get(url).then(response => {
                if (response && response.data) {
                    const nb = response.data;
                    if (window.location.pathname === "/admin/collaborative/active") {
                        HttpApi.post("/collaborative/updateLastNotif", {nbNotif: nb}).then(() => {
                            setNbAnswersSurvey(0);
                        }).catch(error => notifyError(error));
                    } else {
                        setNbAnswersSurvey(nb);
                    }
                } else {
                    setNbAnswersSurvey(0);
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const checkForCollabSurvey = () => {
        if(isProspect){
            setNbActiveSurvey(0);
        }else{
            const url = "/collaborative/checkForActiveSurveyUser";
            HttpApi.get(url).then(response => {
                if (response && response.data) {
                    const surveys = response.data;
                    setNbActiveSurvey(surveys && surveys.activeSurveys ? surveys.activeSurveys.length : 0);
                    setNbSurvey(surveys.nbSurveys);
                } else {
                    setNbActiveSurvey(0);
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const handleReportState = (value) => {
        setShouldDownloadReport(value);
    }

    const handleModalReport = (value) => {
        setShowModalReport(value);
    }

    const handleReportDisabled = (value) => {
        setReportDisabled(value);
    }

    useEffect(() => {
        fetchValidTeams()
    }, []);

    useEffect(() => {
        if(!isProspect){
            checkIfSurveysTeamsExist()
        }
    }, [startDate,endDate,dashboardAccess,validTeams]);

    return (
        <div id="managers" style={{backgroundColor: bgColor}}>
            <HeaderEbloom {...rest} handleSwitch={handleSwitchTeam} startDate={startDate} endDate={endDate}
                          handleSwitchStartDate={handleSwitchStartDate} handleSwitchEndDate={handleSwitchEndDate}
                          nbActiveSurvey={nbActiveSurvey} checkForCollabSurvey={checkForCollabSurvey}
                          checkIfCollabAccess={checkIfCollabAccess} collabAccess={collabAccess}
                          nbAnswersSurvey={nbAnswersSurvey} nbSurvey={nbSurvey} isFree={isFree}
                          handleModalReport={handleModalReport} shouldDownloadReport={shouldDownloadReport}
                          reportDisabled={reportDisabled} commonSurveyActivated={commonSurveyActivated} setCommonSurveyActivated={setCommonSurveyActivated} lockCommonSurvey={lockCommonSurvey} dashboardAccess={dashboardAccess}/>
            <div className={"pt-84"}>
                {children(teamToDisplay, formattedStartDate, formattedEndDate, timeFilterVersion, shouldDownloadReport, handleReportState, showModalReport, handleModalReport, handleReportDisabled,commonSurveyActivated,validTeams,dashboardAccess)}
            </div>
        </div>
    );
};

DisplayManagerView.propTypes = {
    bgColor: PropTypes.string,
}

DisplayManagerView.defaultProps = {
    bgColor: Colors.EBLOOM_WHITE_BLUE
}

export default DisplayManagerView;
