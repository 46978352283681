import React from 'react'
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import FAQEditor from "./FAQEditor";

const FAQEditorPage = (props) => {
    return (
        <DisplayAdminView {...props}>
            {() => (
                <Container>
                    <FAQEditor {...props}/>
                </Container>
            )
            }
        </DisplayAdminView>
    )
}

export default FAQEditorPage