import {useEffect, useState} from "react";

/**
 * Hook that handle screen size on breakpoints
 */
export function useBreakpoints() {
    const [pageSize, setCurrentPageSize] = useState(1200);

    const breakpoints = {
        mobile: 320,
        mobileLandscape: 480,
        tablet: 768,
        tabletLandscape: 1024,
        desktop: 1200,
        desktopLarge: 1500,
        desktopWide: 1920,
    };

    const isMobile = () => {
        return pageSize<=breakpoints.mobile;
    }

    const isMobileLandscape = () => {
        return pageSize<=breakpoints.mobileLandscape && pageSize > breakpoints.mobile;
    }

    const isTablet = () => {
        return pageSize<=breakpoints.tablet && pageSize > breakpoints.mobileLandscape;
    }

    const isTabletLandscape = () => {
        return pageSize<=breakpoints.tabletLandscape && pageSize > breakpoints.tablet;
    }

    const isDesktop = () => {
        return pageSize<=breakpoints.desktop && pageSize > breakpoints.tabletLandscape;
    }

    const isDesktopLarge = () => {
        return pageSize<=breakpoints.desktopLarge && pageSize > breakpoints.desktop;
    }

    const isDesktopWide = () => {
        return pageSize > breakpoints.desktopLarge;
    }

    const isMobileOrLess = () => {
        return pageSize <= breakpoints.mobile;
    }

    const isMobileLandscapeOrLess = () => {
        return pageSize <= breakpoints.mobileLandscape;
    }

    const isTabletOrLess = () => {
        return pageSize <= breakpoints.tablet;
    }

    const isTabletLandscapeOrLess = () => {
        return pageSize <= breakpoints.tabletLandscape;
    }

    const isDesktopOrLess = () => {
        return pageSize <= breakpoints.desktop;
    }

    const isDesktopLargeOrLess = () => {
        return pageSize <= breakpoints.desktopLarge;
    }

    const isDesktopWideOreLess = () => {
        return pageSize <= breakpoints.desktopWide;
    }



    useEffect(() => {
        /**
         * init page size on mount
         */
        setCurrentPageSize(window.innerWidth);
        function handleScreen(event) {
            setCurrentPageSize(event.target.innerWidth);
        }
        // Bind the event listener
        window.addEventListener("resize", handleScreen);
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener("resize", handleScreen);
        };
    }, []);

    return {pageSize:pageSize, breakpoints:breakpoints, isMobile:isMobile, isMobileLandscape:isMobileLandscape, isTablet:isTablet, isTabletLandscape:isTabletLandscape, isDesktop:isDesktop, isDesktopLarge:isDesktopLarge, isDesktopWide:isDesktopWide, isMobileOrLess:isMobileOrLess, isMobileLandscapeOrLess:isMobileLandscapeOrLess, isTabletOrLess:isTabletOrLess, isTabletLandscapeOrLess:isTabletLandscapeOrLess, isDesktopOrLess:isDesktopOrLess, isDesktopLargeOrLess:isDesktopLargeOrLess, isDesktopWideOreLess:isDesktopWideOreLess};
}