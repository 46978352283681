

export const createClickAndBloomStep = [
    {
        title: "admin.productTour.createClickAndBloom.step1.title",
        content: "admin.productTour.createClickAndBloom.step1.content",
        target: '[data-tour="tipsBtn"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 4
    },
    {
        title: "admin.productTour.createClickAndBloom.step2.title",
        content: "admin.productTour.createClickAndBloom.step2.content",
        target: '[data-createCB="input"]',
        placement: 'top',
        disableBeacon : true,
        spotlightPadding: 2
    },
    {
        title: "admin.productTour.createClickAndBloom.step3.title",
        content: "admin.productTour.createClickAndBloom.step3.content",
        target: '[data-createCB="select"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2

    },
    {
        title: "admin.productTour.createClickAndBloom.step4.title",
        content: "admin.productTour.createClickAndBloom.step4.content",
        target: '[data-createCB="plus"]',
        placement: 'top',
        disableBeacon : true,
        spotlightClicks : false
    },{
        title: "admin.productTour.createClickAndBloom.step5.title",
        content: "admin.productTour.createClickAndBloom.step5.content",
        target: '[data-createCB="confirm"]',
        placement: 'left',
        disableBeacon : true,
    },
]