import React from 'react';
import PropTypes from 'prop-types';

const FlagIcon = (props) => {
    const {colors,width,height} = props

    return (
        <svg style={{height : height, width: width}} viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g style={{fill: colors ? colors.primary : "#5D00A2"}}>
                <path
                      d="M3.0932 33.0958L0.38134 32.7568V3.50339C0.38134 2.35424 1.66863 1.47712 3.0932 1.65509V33.0958Z"
                      />
                <path
                      d="M3.0932 33.4746C3.07739 33.4754 3.06155 33.4754 3.04574 33.4746L0.333877 33.1356C0.241531 33.1242 0.156561 33.0794 0.0950355 33.0096C0.0335098 32.9398 -0.000307287 32.8498 -2.10863e-05 32.7568V3.50339C-2.10863e-05 2.92797 0.260148 2.38221 0.732182 1.96526C1.34744 1.42204 2.24744 1.16526 3.14066 1.27627C3.23293 1.28785 3.31779 1.33274 3.37929 1.4025C3.44078 1.47226 3.47466 1.56209 3.47456 1.65509V33.0958C3.47456 33.1969 3.43438 33.2939 3.36286 33.3654C3.29134 33.4369 3.19434 33.4771 3.0932 33.4771V33.4746ZM0.762691 32.4195L2.71184 32.6636V2.01356C2.17348 2.00601 1.65026 2.19184 1.23727 2.53729C1.09355 2.65491 0.976798 2.80205 0.894918 2.96873C0.813037 3.13542 0.767943 3.31776 0.762691 3.50339V32.4195Z"
                      />
                <path
                      d="M28.7288 23.0509C28.7288 23.0509 26.9492 25.5086 20.4237 25.0001C15.6271 24.6264 16.2712 21.356 9.91526 21.695C6.88475 21.856 4.34068 22.5467 2.37289 23.0509V2.54246C4.1695 2.09246 6.54238 1.30602 9.40678 1.27127C16.2712 1.18653 16.6254 3.4789 21.7797 3.98314C23.8864 4.18907 28.7288 2.54246 28.7288 2.54246V23.0509Z"
                      />
                <path
                      d="M22.1339 26.3424C21.5695 26.3424 20.9678 26.3195 20.3246 26.2695C17.8669 26.078 16.5957 25.1992 15.4661 24.4246C14.1991 23.5526 13.1059 22.8009 9.98303 22.967C7.24998 23.1119 4.9093 23.7136 3.0288 24.1975L2.68981 24.2822C2.50198 24.3306 2.30557 24.3353 2.11563 24.2961C1.92568 24.2568 1.74723 24.1746 1.59395 24.0558C1.44067 23.9369 1.31662 23.7846 1.23129 23.6104C1.14597 23.4362 1.10163 23.2448 1.10168 23.0509V2.54239C1.10227 2.25939 1.19729 1.98468 1.37168 1.76178C1.54606 1.53889 1.78984 1.38056 2.06439 1.31188C2.36185 1.23731 2.67964 1.15171 3.01693 1.05765C4.72795 0.60341 6.85761 0.0339181 9.39066 1.97731e-05C13.5847 -0.0516751 15.5263 0.740698 17.4051 1.5068C18.6822 2.02799 19.889 2.52375 21.9034 2.71782C23.2364 2.84832 26.5466 1.9407 28.3195 1.339C28.5108 1.27394 28.7148 1.25541 28.9147 1.28497C29.1146 1.31453 29.3046 1.39131 29.4689 1.50896C29.6332 1.62661 29.7671 1.78172 29.8594 1.96145C29.9518 2.14117 30 2.34032 30 2.54239V23.0509C29.9999 23.3184 29.9153 23.5791 29.7585 23.7958C29.5542 24.0805 27.739 26.3424 22.1339 26.3424ZM10.7966 20.4017C14.1017 20.4017 15.5873 21.4187 16.9076 22.3297C17.9364 23.0373 18.7483 23.5966 20.5229 23.7348C23.461 23.9644 25.2771 23.5424 26.2856 23.1492C26.7048 22.993 27.1001 22.7791 27.4601 22.5136V4.27375C25.7576 4.76782 23.194 5.40087 21.6585 5.25087C19.2746 5.01697 17.8373 4.43053 16.4474 3.86358C14.7271 3.16188 13.1034 2.49833 9.42541 2.54494C7.21015 2.57205 5.25083 3.09663 3.6771 3.51782L3.64405 3.52544V21.4187C5.3932 20.989 7.45761 20.5551 9.84744 20.428C10.1796 20.4105 10.496 20.4017 10.7966 20.4017Z"
                      />

            </g>

        </svg>
    );
};

FlagIcon.propTypes = {
    colors: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
};

FlagIcon.defaultProps = {
    color: null,
    width: 30,
    height: 30,
}
export default FlagIcon;
