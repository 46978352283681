import React from "react";
import WeBloomDashboard from "./WeBloomDashboard";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";

const WeBloomDashboardPage = (props) => {

    return(
        <DisplayAdminView pageName={"WeBloom"} menu={"noMenu"} selectedIndex={9} {...props}>
            {
                (checkForCollabSurvey, checkIfCollabAccess, nbActiveSurvey) => (
                    <Container>
                        <WeBloomDashboard nbActiveSurvey={nbActiveSurvey} {...props}/>
                    </Container>

                )
            }
        </DisplayAdminView>
    )
}

export default WeBloomDashboardPage;