import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React from "react";
import IconButton from "@mui/material/IconButton/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Login from "../../../../login/login";
import {useTranslation} from "react-multi-lang/lib";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import MyBloomIcon from "@mui/icons-material/AssessmentOutlined";
import ProfileIcon from "@mui/icons-material/PersonOutline";
import SettingIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import LockIcon from "@mui/icons-material/LockOutlined";
import LibraryIcon from '@mui/icons-material/ListOutlined';
import PlannerIcon from '@mui/icons-material/DateRangeOutlined';
import {ReactComponent as SmileyIcon} from "../../../Core/symbols/myBloom/icons/smileyHappyWhite.svg";
import _ from "lodash";
import Badge from "@mui/material/Badge";
import EbloomNotification from "../Notifications/EbloomNotification";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useNavigationTo} from "../../../../hooks/navigationHook";
import EbloomNotificationBadge from "../Notifications/EbloomNotificationBadge";

const checkUrl = () => {
    const url = window.location.pathname;
    switch (url) {
        case "/admin/webloom":
            return 13;
        case "/webloom/dashboard":
            return 13;
        case "/suggestion":
            return 12;
        case "/admin/tuto":
            return 11;
        case "/admin/questions/planner":
            return 10;
        case "/admin/questions/playlist":
            return 8;
        case "/admin/questions/library":
            return 8;
        case "/employee/dashboard":
            return 7;
        case "/backoffice/*":
            return 5;
        case "/admin/users":
            return 4;
        case "/manager/dashboard":
            return 3;
        case "/profile":
            return 2;
        case "/employee/daily":
            return 1;
        default:
            return 1;
    }
};

export default function MenuSettings(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(checkUrl);
    const {hasNewCB, nbActiveSurvey, isFree} = props;
    let navigation = useNavigationTo()
    const t = useTranslation();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToProfile = () => {
        setSelectedIndex(2);
        navigation.to('/profile');
    };

    const redirectToMyDashboard = () => {
        setSelectedIndex(7);
        navigation.to("/employee/dashboard");
    };

    const redirectToDaily = () => {
        navigation.to("/employee/daily");
    }

    const redirectToDashboard = () => {
        setSelectedIndex(3);
        navigation.to("/manager/dashboard");
    };

    const redirectToSettings = () => {
        setSelectedIndex(4);
        navigation.to("/admin/users");
    };

    const redirectToBackoffice = () => {
        setSelectedIndex(5);
        navigation.to("/backoffice/company");
    };

    const redirectToClickAndBloom = () => {
        setSelectedIndex(8);
        navigation.to("/admin/questions/library");
    };

    const redirectToPlanner = () => {
        setSelectedIndex(10);
        navigation.to("/admin/questions/planner");
    };

    const redirectToCollab = () => {
        setSelectedIndex(13);
        Login.hasRole("global_admin") ? navigation.to("/admin/webloom") : navigation.to("/webloom/dashboard");
    }

    return (
        <div style={{position: "relative", right: -15}}>
            {
                (!Login.hasRole("global_backoffice") && !Login.hasRole("demo_backoffice") && !Login.hasRole("datatopia_backoffice")) &&
                <IconButton style={{marginRight: -10, marginBottom:5}} color="inherit" className={"icon_button"} >
                    <EbloomNotification/>
                </IconButton>
            }
            <IconButton color="inherit" aria-controls='settings-menu' aria-haspopup="true" size={"small"}
                        onClick={handleClick}>
                <EbloomNotificationBadge number={nbActiveSurvey + ((hasNewCB) ? 1 : 0)} showNumber>
                    <MenuIcon/>
                </EbloomNotificationBadge>
            </IconButton>
            <Menu id="settings_menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
            >
                {
                    (Login.hasRole("global_admin") && true === hasNewCB) &&
                    <div>
                        <List>
                            <MenuItem key={12} onClick={redirectToDaily} selected={selectedIndex === 12}>
                                <ListItemIcon><Badge badgeContent={1}
                                                     color={"secondary"}><SmileyIcon
                                    className={"w-24 h-24"}/></Badge></ListItemIcon>
                                <ListItemText primary={_.upperFirst(t("core.cbOfTheDay"))}/>
                            </MenuItem>
                        </List>
                        <Divider/>
                    </div>
                }
                {
                    (Login.hasRole("global_admin") || Login.hasRole("global_manager")) &&
                    <div>
                        <List>
                            <MenuItem key={3} onClick={redirectToDashboard} selected={selectedIndex === 3}>
                                <ListItemIcon><DashboardIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("manager.dashboard.dashboard"))}/>
                            </MenuItem>
                        </List>
                        <Divider/>
                    </div>
                }
                <List>
                    {
                        (Login.hasRole("global_admin") || nbActiveSurvey > 0) &&
                        <MenuItem key={13} onClick={redirectToCollab} selected={selectedIndex === 13}>
                            <ListItemIcon>
                                <Badge badgeContent={nbActiveSurvey} max={10} color={"secondary"}>
                                    <MyBloomIcon/>
                                </Badge>
                            </ListItemIcon>
                            <ListItemText>{"WeBloom"}</ListItemText>
                            {
                                isFree &&
                                <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                            }
                        </MenuItem>
                    }
                </List>
                <Divider/>


                {Login.hasRole("global_admin") &&
                    <div>
                        <List>
                            <MenuItem key={10} onClick={redirectToPlanner} selected={selectedIndex === 10}>
                                <ListItemIcon><PlannerIcon/></ListItemIcon>
                                <ListItemText primary={_.upperFirst(t("core.playlist.planner"))}/>
                            </MenuItem>
                            <MenuItem key={8} onClick={redirectToClickAndBloom} selected={selectedIndex === 8}>
                                <ListItemIcon><LibraryIcon/></ListItemIcon>
                                <ListItemText primary={_.upperFirst(t("core.question"))}/>
                                {
                                    isFree &&
                                    <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                }
                            </MenuItem>
                            <MenuItem key={4} onClick={redirectToSettings} selected={selectedIndex === 4}>
                                <ListItemIcon><SettingIcon/></ListItemIcon>
                                <ListItemText primary={_.capitalize(t("admin.settings"))}/>
                            </MenuItem>
                        </List>
                        <Divider/>
                    </div>
                }
                <List>
                    <MenuItem key={2} onClick={redirectToProfile} selected={selectedIndex === 2}>
                        <ListItemIcon><ProfileIcon/></ListItemIcon>
                        <ListItemText primary={_.capitalize(t("core.profile.profile"))}/>
                    </MenuItem>

                    {
                        (Login.hasRole("global_employee") || Login.hasRole("global_manager") || Login.hasRole("global_admin")) &&

                        <MenuItem key={7} onClick={redirectToMyDashboard} selected={selectedIndex === 7}>
                            <ListItemIcon><MyBloomIcon/></ListItemIcon>
                            <ListItemText primary={"MyBloom"}/>
                        </MenuItem>
                    }
                    {/*{
                        (!Login.hasRole("global_backoffice") && !Login.hasRole("demo_backoffice") && !Login.hasRole("datatopia_backoffice")) &&
                        <MenuItem key={9} onClick={redirectToKickoff} selected={selectedIndex === 9}>
                            <ListItemIcon><KickOffIcon/></ListItemIcon>
                            <ListItemText primary={"Kickoff"}/>
                        </MenuItem>
                    }*/}

                </List>

                {(Login.hasRole("global_backoffice") || Login.hasRole("demo_backoffice") || Login.hasRole("datatopia_backoffice")) &&
                <div>
                    <Divider/>
                    <List>
                        <MenuItem key={5} onClick={redirectToBackoffice} selected={selectedIndex === 5}>
                            <ListItemIcon><LockIcon/></ListItemIcon>
                            <ListItemText primary={_.capitalize("backoffice")}/>
                        </MenuItem>
                    </List>
                 </div>
                 }
                <Divider/>
                <List>
                    <MenuItem key={6} onClick={Login.logout}>
                        <ListItemIcon><LogoutIcon/></ListItemIcon>
                        <ListItemText primary={_.capitalize(t("core.login.logout"))}/>
                    </MenuItem>
                </List>
            </Menu>
        </div>
    );
}