import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {Modules, TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {planClickAndBloomSteps} from "./planClickAndBloomSteps";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import PropTypes from "prop-types";
import {useUserData} from "../../../../hooks/userHook";
import {planClickAndBloomFreeSteps} from "./planClickAndBloomFreeSteps";

const PlanClickAndBloomTour = (props) => {
    const {
        updateStep,
        stepTour,
        stepPlanClickAndBloom, setStepPlanClickAndBloom,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        icon,
        updateHandler

    } = useContext(ProductTourContext)

    const {handleNextWeek,handleToday} = props

    const user = useUserData();
    const isPackageFree = Modules.FREE.includes(user.module)



    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
                if(action === ACTIONS.PREV && index === 1){
                    handleToday()
                    setTimeout(()=>{
                        const plusIcon = document.querySelector('[data-planCB=plus]')
                        if(!plusIcon) {
                            handleNextWeek(true)
                        }
                        updateStep(TourType.PLAN_CLICK_AND_BLOOM,next)
                    },200)
                }else{
                    updateStep(TourType.PLAN_CLICK_AND_BLOOM,next)
                }
            }
        }
    }



    const handleTour = () => {
        handleToday()
        setTimeout(()=>{
            const plusIcon = document.querySelector('[data-planCB=plus]')
            if(!plusIcon){
                handleNextWeek(true)
                if(!document.querySelector('[data-planCB=plus]') && isPackageFree){
                    handleToday()
                    updateStep(TourType.PLAN_CLICK_AND_BLOOM,1)
                }
            }
            setTourStarted(true)
        },200)

    }
    const updateTourStatus = () => {
        setTourStarted(false)
        if(isPackageFree){
            const tour = {
                id : stepTour[TourType.PLAN_CLICK_AND_BLOOM].id,
                current_step : stepTour[TourType.PLAN_CLICK_AND_BLOOM].step >= planClickAndBloomFreeSteps.length - 1 ? 0 : stepTour[TourType.PLAN_CLICK_AND_BLOOM].step,
                type: TourType.PLAN_CLICK_AND_BLOOM,
                finished : stepTour[TourType.PLAN_CLICK_AND_BLOOM].outcome
            }
            if(stepPlanClickAndBloom >= planClickAndBloomFreeSteps.length - 1){
                setStepPlanClickAndBloom(0)
            }
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})
        }else{
            const tour = {
                id : stepTour[TourType.PLAN_CLICK_AND_BLOOM].id,
                current_step : stepTour[TourType.PLAN_CLICK_AND_BLOOM].step >= planClickAndBloomSteps.length - 1 ? 0 : stepTour[TourType.PLAN_CLICK_AND_BLOOM].step,
                type: TourType.PLAN_CLICK_AND_BLOOM,
                finished : stepTour[TourType.PLAN_CLICK_AND_BLOOM].outcome
            }
            if(stepPlanClickAndBloom >= planClickAndBloomSteps.length - 1){
                setStepPlanClickAndBloom(0)
            }
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})

        }

    }

    const iconRinging = () => {
        if((icon && icon.current) && stepTour[TourType.PLAN_CLICK_AND_BLOOM]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.PLAN_CLICK_AND_BLOOM].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepPlanClickAndBloom]);


    useEffect(() => {
        fetchData();
        handleToday()
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        updateHandler(TourType.PLAN_CLICK_AND_BLOOM,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={ isPackageFree ? planClickAndBloomFreeSteps : planClickAndBloomSteps}
                continuous
                run={tourStarted}
                stepIndex={stepPlanClickAndBloom}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                styles={styleEbloom}
                tooltipComponent={EbloomTourTip}

            />
        </>
    )
}
PlanClickAndBloomTour.propTypes = {
    handleNextWeek : PropTypes.func.isRequired
}
export default PlanClickAndBloomTour