import {useContext, useEffect, useState} from "react";
import {ProductTourContext} from "../../../Utils/Context/ProductTourContext";
import {ReactComponent as ValidateIcon} from "../../Core/symbols/productTour/step-done.svg";
import {ReactComponent as StartedIcon} from "../../Core/symbols/productTour/step-doing.svg";
import {ReactComponent as NotStartedIcon} from "../../Core/symbols/productTour/step-todo.svg";
import {TourType, TourTypePackages, TourUrl} from "../../../Utils/Global";
import PropTypes from "prop-types";
import EbloomTooltip from "../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useNavigationProductTour} from "../../../hooks/navigationProductTourHook";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {useUserData} from "../../../hooks/userHook";

const ProductTourMap = (props) => {
    const {fetchData,stepTour} = useContext(ProductTourContext);
    const {setCount,bp} = props

    const [tourList, setTourList] = useState([]);
    const [tourResumeIndex, setTourResumeIndex] = useState(-1);

    const navigate = useNavigationProductTour();
    const user = useUserData()
    const t = useTranslation("admin.productTour")

    const isMinimumScreenSize = bp.pageSize >= 1280

    const order = {communication: 1,invite: 2,manageUsers: 3,defineRoles:4,manageTeams: 5,customLibrary: 6,browseLibrary:7,createPlaylist: 8,createClickAndBloom: 9,planClickAndBloom: 10,launchWeBloom: 11}

    const orderTour = () => {
        const final = Object.entries(stepTour).filter(tour => TourTypePackages[user.module].includes(tour[0])).sort((a,b) => {
            const indexA = order[a[0]]
            const indexB = order[b[0]]

            a[1].title = t(a[0]+'.title')
            b[1].title = t(b[0]+'.title')

            if(indexA<indexB){
                return-1;
            }else if(indexA>indexB){
                return 1;
            }
            return 0;
        })
        setTourList(final)
        let count = 0
        let checkFirstNotFinished = false
        final.forEach((tour,index) => {
            if(tour[1].outcome){
                count ++
            }else if (tour[1].step >=0 && !checkFirstNotFinished){
                setTourResumeIndex(index)
                checkFirstNotFinished = true
            }
        })
        setCount(count)
    }

    const buttonText = (data,index,type) => {
        if(isMinimumScreenSize){
            if(index === tourResumeIndex){
                return (
                    <EbloomTooltip text={_.upperFirst(t("admin.productTour."+type+".text"))} placement={'top'}>
                        <div onClick={() =>{navigate.to(TourUrl[type], type);}} className={'flex flex-col items-center '}>
                            <p className={'cursor-pointer purple'}>
                                {data.title}
                            </p>
                        </div>
                    </EbloomTooltip>
                        )
            }else if(data.outcome){
                return (
                    <EbloomTooltip text={_.upperFirst(t("admin.productTour.restart"))} placement={'top'}>
                        <div onClick={() =>{navigate.to(TourUrl[type], type);}} className={'flex flex-col items-center cursor-pointer '}>
                            <p className={'tour-text-finished'}>
                                {data.title}
                            </p>
                        </div>
                    </EbloomTooltip>
                )
            }else{
                if(!stepTour[TourType.INVITE].outcome && [TourType.MANAGE_USERS,TourType.MANAGE_TEAMS,TourType.DEFINE_ROLES].includes(type)){
                    return (
                        <EbloomTooltip text={_.upperFirst(t("admin.productTour."+type+".text") + "\n\n"+ t("admin.productTour.finishInvite"))} placement={'top'}>
                            <div className={'flex flex-col items-center cursor-default'}>
                                <p className={'tour-text-not-started'}>
                                    {data.title}
                                </p>
                            </div>
                        </EbloomTooltip>

                    )
                }
                return (
                    <EbloomTooltip text={_.upperFirst(t("admin.productTour."+type+".text"))} placement={'top'}>
                        <div  onClick={() =>{navigate.to(TourUrl[type], type);}} className={'flex flex-col items-center cursor-default'}>
                            <p className={'cursor-pointer purple'}>
                                {data.title}
                            </p>
                        </div>
                    </EbloomTooltip>

                )
            }
        }
    };

    const defineLogoOrText = (data,text=false,index,type) => {
      if(text){
              if (tourList.slice(0,index).find(el => el[1].step >= 0 && el[1].outcome === 0)){
                  return 'tour-between-step tour-not-started'
              }else{
                  return 'tour-between-step ' + (data.outcome === 1 ? 'tour-finished' : index=== tourResumeIndex ? 'tour-not-finished' : 'tour-not-started')
              }
      }else{
          if(isMinimumScreenSize){
              if (data.outcome === 1){
                  return <ValidateIcon className={'tour-svg'} />
              }else {
                  return index === tourResumeIndex  ?
                      <StartedIcon onClick={() =>{navigate.to(TourUrl[type], type);}} className={'tour-svg ebloom-ring cursor-pointer'}/>
                      :
                      <NotStartedIcon className={'tour-svg'}/>
              }
          }else{
              if(data.outcome === 1){
                  return (
                      <EbloomTooltip text={'Recommencer ' + data.title} placement={'top'}>
                          <div onClick={() =>{navigate.to(TourUrl[type], type);}} className={'flex flex-col items-center cursor-pointer '}>
                              <ValidateIcon className={'tour-svg'} />
                          </div>
                      </EbloomTooltip>
                  )
              }else{
                  if(index===tourResumeIndex){
                      return (
                          <EbloomTooltip text={'Continuer ' + data.title} placement={'top'}>
                              <div onClick={() =>{navigate.to(TourUrl[type], type);}} className={'flex flex-col items-center cursor-pointer '}>
                                  <StartedIcon className={'tour-svg ebloom-ring'}/>
                              </div>
                          </EbloomTooltip>
                      )
                  }else{
                      return (
                          <EbloomTooltip text={data.title} placement={'top'}>
                              <NotStartedIcon className={'tour-svg'}/>
                          </EbloomTooltip>
                      )
                  }
              }
          }
      }
    }
    useEffect(() => {
        orderTour()
    }, [stepTour]);

    useEffect(() => {
        fetchData()
        orderTour()
    }, []);

    return (
        <div className={'flex flex-row justify-between px-30 w-full ' + (isMinimumScreenSize ? 'mt-100' : 'mt-50') }>
            {
                tourList.map((tour,index) =>{
                    const data = tour[1]
                    const type = tour[0]
                    if(index === 0){
                        return (
                            <div key={index} className={'flex justify-center items-center relative'}>
                                <div className={'absolute z-10 -top-10 -right-15 bottom-0 flex flex-col items-center'}>
                                    <div className={'absolute text-center -top-70 ebloom-small-text tour-text '}>
                                        {
                                            buttonText(data,index,type)
                                        }
                                    </div>
                                    <div key={index} > {defineLogoOrText(data,false,index,type)} </div>
                                </div>
                            </div>
                        )
                    } else{
                        return (
                            <div key={index} className={'flex justify-center w-full items-center relative'}>
                                <div className={defineLogoOrText(data,true,index)}></div>
                                <div className={'wrapper-step'}>
                                    <div className={'absolute z-10 -top-10 -right-15 bottom-0 flex flex-col items-center'}>
                                        <div className={'absolute text-center ebloom-small-text tour-text ' + (isMinimumScreenSize ? '-top-70' : '-top-30')}>
                                            {
                                                buttonText(data,index,type)
                                            }
                                        </div>
                                        <div key={index} > {defineLogoOrText(data,false,index,type)} </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

ProductTourMap.propTypes = {
    setCount : PropTypes.func.isRequired
}
export default ProductTourMap