import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {useTranslation} from "react-multi-lang";
import {Accordion, Button, Row} from "react-bootstrap";
import FrequencyItem from "./FrequencyItem";
import {useUserData} from "../../../../hooks/userHook";
import HttpApi from "../../../../httpApi";
import {
    createSuccessfullyDeletedNotification,
    notifyError, notifySuccess
} from "../../../../Utils/Notification";
import {SettingTypes} from "../../../../Utils/Global";
import {useParams} from "react-router-dom";

const FrequenciesList = (props) => {
    const {companyToEdit,selectedEntity} = props;
    const t = useTranslation();
    const user = useUserData();
    const params = useParams();

    const isSuperAdmin = user.roles.includes("global_super_admin");

    const [expandedKey, setExpandedKey] = useState("0");
    const [listFrequencies, setListFrequencies] = useState([]);
    const [maxCB, setMaxCB] = useState(5);
    const [frequenciesUnsaved, setFrequenciesUnsaved] = useState([]);

    const handleAccordion = (key) => {
        if(frequenciesUnsaved.length > 0){
            setExpandedKey(String(frequenciesUnsaved[0]));
        }else if (expandedKey === key) {
            setExpandedKey("-1");
        } else {
            setExpandedKey(key);
        }
    }

    const checkName = () => {
        const frequencyNames = listFrequencies.map(el => el.title.trim());
        const frequencyNumbers = frequencyNames
            .map(name => parseInt(name.replace("Frequency #", ""), 10))
            .filter(number => !isNaN(number));
        const maxNumber = Math.max(0, ...frequencyNumbers);
        return `Frequency #${maxNumber + 1}`;
    }

    const handleAddFrequency = (event) => {
        event.preventDefault();
        const elementToAdd = {id:-10,title:checkName(),recurrency:1,monday:0,tuesday:0,wednesday:0,thursday:0,friday:0,auto_planners : []};

        setListFrequencies([...listFrequencies, elementToAdd]);
        setFrequenciesUnsaved([...frequenciesUnsaved, elementToAdd.id]);
        setExpandedKey(String(elementToAdd.id))

        /*
        let url = '/frequencies/create';

        if(!!selectedEntity){
            url += '/' + selectedEntity;
        }
        HttpApi.postV2(url, elementToAdd).then(response => {
            if(response && response.data){
                let msg = "Frequency created successfully";
                notifySuccess(msg)
                setExpandedKey(String(response.data.id))
            }
            fetchFrequencies();
        }).catch(error => {
            notifyError(error);
        })*/


    }




    const handleRemoveFrequency = (id) => {
        let newList = [...listFrequencies];
        const index = newList.findIndex(el => el.id === id);
        const elementToDelete = index > -1 ? newList[index] : null;
        if(elementToDelete !== null && elementToDelete.id === -10){
            newList = newList.filter(el => el.id !== -10);
            setListFrequencies(newList);
            setFrequenciesUnsaved(previous => {
                return previous.filter(el => el !== -10);
            });
        }else if(elementToDelete !== null && elementToDelete.id !== null){
            const elementToDelete = newList[index];
            HttpApi.postV2("/frequencies/delete",{id : elementToDelete.id}).then(response => {
                createSuccessfullyDeletedNotification("frequency");
                newList = newList.filter(el => el.id !== elementToDelete.id);
                setFrequenciesUnsaved(previous => {
                    return previous.filter(el => el !== elementToDelete.id);
                });
                setListFrequencies(newList);
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const fetchFrequencies = () => {
        let url = "/frequencies/getAll"
        if (isSuperAdmin && !!selectedEntity){
            url = "/frequencies/getAll/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                const list = response.data
                if(list.length > 0){
                    if(params.id){
                        setExpandedKey(params.id)
                    }else{
                        setExpandedKey(String(list[0].id))
                    }
                    setListFrequencies(list);
                }else{
                    setListFrequencies([{id:null,title:"Frequency #1",recurrency:1,monday:0,tuesday:0,wednesday:0,thursday:0,friday:0,auto_planners : []}])
                }
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchNbCB = () => {
        let url = "/settings/getForCompany/"+SettingTypes.NB_CB
        if(isSuperAdmin && !!selectedEntity){
            url = "/settings/group/getForCompany/"+SettingTypes.NB_CB+"/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setMaxCB(response.data.value);
            }else{
                setMaxCB(10);
            }
        }).catch(error => {
            notifyError(error)
        })
    }

    useEffect(() => {
        fetchFrequencies();
        fetchNbCB()
        if(params.id){
            setExpandedKey(params.id)
        }
    }, [selectedEntity]);

    return (
        <div>
            <CardDefault showButton={false} subtitle={_.upperFirst(t("admin.frequencyHelper"))} title={_.upperFirst(t("admin.frequency"))}>
                <Accordion className={"ebloom-accordion"} activeKey={expandedKey} defaultActiveKey={expandedKey}>
                    {
                        listFrequencies.map((frequency, index) => (
                            <FrequencyItem
                                key={index}
                                frequency={frequency}
                                selectedEntity={selectedEntity}
                                handleAccordion={handleAccordion}
                                keyAccordion={frequency.id.toString()}
                                expandedKey={expandedKey}
                                handleRemove={() => handleRemoveFrequency(frequency.id)}
                                showRemove={frequency.id < 0 || ( frequenciesUnsaved.length === 0 &&  listFrequencies.filter(el => el.id !== null).length > 0 && listFrequencies.length > 1)}
                                refreshFrequencies={fetchFrequencies}
                                maxCB={maxCB}
                                setFrequenciesUnsaved={setFrequenciesUnsaved}
                                frequenciesUnsaved={frequenciesUnsaved}
                                listFrequencies={listFrequencies}
                            />
                        ))
                    }
                </Accordion>
                <Row className={"w-full my-10 p-0 mx-auto"}>
                    <div className={"w-full flex justify-end items-center"}>
                        {
                            frequenciesUnsaved.length === 0 &&
                            <Button className={"ebloom-btn-purple w-auto"} size={"sm"} onClick={handleAddFrequency}>
                                <div className={"flex justify-center items-center flex-col w-full"}>
                                    <div>{_.upperFirst(t("admin.frequencies.add"))}</div>
                                </div>
                            </Button>
                        }
                    </div>

                </Row>
            </CardDefault>
        </div>
    );
};

FrequenciesList.propTypes = {
    companyToEdit: PropTypes.object,
    selectedEntity: PropTypes.number
};

FrequenciesList.defaultProps = {
    companyToEdit: null,
    selectedEntity: null
}


export default FrequenciesList;
