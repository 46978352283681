import React from "react";
import {useDrop} from "react-dnd";
import PropTypes from "prop-types";
import {ReactComponent as MultipleArrows} from "../../../../../../Core/symbols/webloom/multiple-arrows.svg";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const DropZone = (props) => {
    const {position, first} = props;

    const t = useTranslation();

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept:"newQuestion",
        drop: () => ({position:position.toString()}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }), [position])

    return (
        <div>
            {
                first ?
                    <div ref={drop} className={"h-160 w-full bg-contrast-blue/[0.06] border-1 border-dashed border-contrast-blue rounded-lg cursor-default"}>
                        <div className={"flex items-center justify-center h-full"}><MultipleArrows className={"h-36 w-36 mr-10"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("webloom.dndInfo"))}</span></div>
                    </div>
                    :
                    <div ref={drop} className={canDrop ? isOver ? "h-80 border-1 border-dashed border-contrast-blue rounded-lg" : "h-10 border-1 border-dashed border-contrast-blue w-full rounded-lg" : "h-10"}/>

            }
        </div>
    )
}

DropZone.propTypes = {
    position:PropTypes.number.isRequired,
    first:PropTypes.bool,
}

DropZone.defaultProps = {
    first:false,
}

export default DropZone;