import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as HeartIcon} from "../../../../../../Core/symbols/webloom/white/heart-3-parts.svg";
import {ReactComponent as StarIcon} from "../../../../../../Core/symbols/webloom/white/star-white.svg";
import _ from "lodash";

const StarRatings = (props) => {
    const [isHover, setIsHover] = useState(null);
    const [score, setScore] = useState(0);

    const {question, handleChange} = props;


    const handleChangeStar = (score) => {
        if(handleChange){ //real answer
            let q = _.cloneDeep(question);
            q.score = score;
            handleChange(q);
        }else{ //preview mode
            setScore(score);
        }

    }


    const getArrayOfItems = () => {
        let array = [];
        for(let i = 0; i<question.nb_items;i++){
            array.push(i);
        }
        return array;
    }

    return(
        <div className={"flex"}>
            {
                getArrayOfItems().map((item, index) => (
                    <div className={"pointer"} key={index} onClick={() => {handleChangeStar(index+1)}} onMouseEnter={() => {setIsHover(index)}} onMouseLeave={() => {setIsHover(null)}}>
                        {
                            question.is_heart ?
                                <HeartIcon className={"ml-5 mr-5 w-40 h-40 border-contrast-purple-icon " + ((null !== isHover && isHover >= index) || (null !== question.score && question.score >= index+1) || score >= index+1 ? "contrast-purple-icon" : "")} />
                                :
                                <StarIcon className={"ml-5 mr-5 w-40 h-40 border-contrast-purple-icon " + ((null !== isHover && isHover >= index) || (null !== question.score && question.score >= index+1) || score >= index+1 ? "contrast-purple-icon" : "")} />
                        }
                    </div>
                ))
            }
        </div>

    )
}

StarRatings.propTypes = {
    question:PropTypes.object.isRequired,
    handleChange:PropTypes.func
}

export default StarRatings;