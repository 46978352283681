import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";

const EbloomJumbotron = (props) => {


    const {title, text, handleAction, buttonText, showButton} = props;

    const t = useTranslation();

    return(
        <div className={"p-5 mb-4 bg-bg-grey rounded-lg"}>
            <div className={"container-fluid py-5"}>
                <h1 className={"ebloom-normal-title font-semibold text-contrast-purple mb-20"}>{title}</h1>
                {
                    text ?
                        <p className={"cold-md-8 fs-4 ebloom-normal-text my-10"}>{text}</p>
                        :
                        <br />
                }
                {
                    showButton &&
                    <p>
                        <Button className={"ebloom-btn-purple m-auto my-10"} onClick={handleAction}>{_.capitalize(t(buttonText))}</Button>
                    </p>
                }
            </div>
        </div>
    )



}

EbloomJumbotron.propTypes = {
    title:PropTypes.string.isRequired,
    text: PropTypes.string,
    handleAction:PropTypes.func,
    buttonText:PropTypes.string,
    showButton:PropTypes.bool
};

EbloomJumbotron.defaultProps = {
    buttonText:"core.add",
    showButton:true,
};

export default EbloomJumbotron;