import {createContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


const GlobalContext = createContext(null)
const ProviderGlobal = (props) => {

    const location = useLocation()

    const [invitePreview, setInvitePreview] = useState(false); //
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [sideFormUploadOpen, setSideFormUploadOpen] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [sideFormEditOpen, setSideFormEditOpen] = useState(false);
    const [showMenuAnchor, setShowMenuAnchor] = useState(false);
    const [seePreview, setSeePreview] = useState(false);
    const [showComparison, setShowComparison] = useState(false);
    const [historyStack, setHistoryStack] = useState([]);
    const [showModalGoogle, setShowModalGoogle] = useState(false)
    const [googleReviewData, setGoogleReviewData] = useState({activated : false, cb: 0, participation : "nulos"})

    const exposedValue = {
        invitePreview, setInvitePreview,
        sideFormOpen, setSideFormOpen,
        sideFormUploadOpen, setSideFormUploadOpen,
        excelData, setExcelData,
        showComparison, setShowComparison,
        sideFormEditOpen, setSideFormEditOpen,
        showMenuAnchor, setShowMenuAnchor,
        seePreview, setSeePreview,
        historyStack,
        showModalGoogle, setShowModalGoogle,
        googleReviewData, setGoogleReviewData
    }

    useEffect(() => {
        setHistoryStack(prevState => [...prevState,location.pathname])
    }, [location]);


    return <GlobalContext.Provider value={exposedValue}>
        { props.children }
    </GlobalContext.Provider>
}

export {
    GlobalContext,
    ProviderGlobal
}
