import {getColorFromPercentage} from "../../../../../Utils/Global";
import {ReactComponent as ArrowRight} from "../../../../Core/symbols/myBloom/icons/ArrowRight.svg";
import React from "react";
import {ReactComponent as WLIcon} from "../../../../Core/symbols/myBloom/categories/bubble/work-lifestyle-bubble.svg";
import {ReactComponent as TIIcon} from "../../../../Core/symbols/myBloom/categories/bubble/team-interaction-bubble.svg";
import {
    ReactComponent as MLIcon
} from "../../../../Core/symbols/myBloom/categories/bubble/management-leadership-bubble.svg";
import {ReactComponent as PSIcon} from "../../../../Core/symbols/myBloom/categories/bubble/policy-support-bubble.svg";
import {ReactComponent as ACIcon} from "../../../../Core/symbols/myBloom/categories/bubble/adhesion-culture-bubble.svg";
import {ReactComponent as ArrowGreen} from "../../../../Core/symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../../../Core/symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../../../Core/symbols/general/ArrowNeutral.svg";
import moment from "moment";
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import CategoryBubble from "../../../../Core/Modules/CategoryDisplay/CategoryBubble";

const ScoreType = (props) => {

    const {category, showArrow , showPreviousDetails,isModelCustomEnable} = props
    const t = useTranslation()
    const categoriesIcon = {
        5: <WLIcon/>,
        6: <TIIcon/>,
        7: <MLIcon/>,
        8: <PSIcon/>,
        9: <ACIcon/>,

    }

    const calculateWidth = (cat) => {
        if(cat.total <= 0){
            return 0
        }else{
            if(cat.score === 0){
                return "3%"
            }else{
                return cat.score + "%"
            }
        }
    }

    const displayPreviousExercice = (cat) => {
        const score = cat.score
        const difference = cat.difference
        if(difference !== null){
            if(showPreviousDetails){
                if(score === difference ){
                    return null
                }else if(Math.round(difference) > 0){
                    return <div>
                        <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(cat.prevDate.start).format("DD/MM/YY"), end: moment(cat.prevDate.end).format("DD/MM/YY") }))} >
                            <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                                <div className={"scale-75"}>
                                    <ArrowGreen className={""}/>
                                </div>
                                <p className={"text-strong-green text-sm"}>
                                    {Math.abs(Math.round(difference))}
                                    <span className={"font-normal"}>%</span>
                                </p>
                            </div>
                        </EbloomTooltip>
                    </div>

                }else if (Math.round(difference) < 0 ){
                    return <div>
                        <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(cat.prevDate.start).format("DD/MM/YY"), end: moment(cat.prevDate.end).format("DD/MM/YY") }))} >
                            <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                                <div className={"scale-75"}>
                                    <ArrowRed/>
                                </div>
                                <p className={"text-strong-red text-sm"}>
                                    {Math.abs(Math.round(difference))}
                                    <span className={"font-normal"}>%</span>
                                </p>
                            </div>
                        </EbloomTooltip>
                    </div>
                }else if (Math.round(difference) === 0){
                    return <div>
                        <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(cat.prevDate.start).format("DD/MM/YY"), end: moment(cat.prevDate.end).format("DD/MM/YY") }))} >
                            <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center ml-5"}>
                                <div className={"scale-75"}>
                                    <ArrowNeutral/>
                                </div>
                                <p className={"text-grey text-sm"}>
                                    {Math.abs(Math.round(difference))}
                                    <span className={"font-normal"}>%</span>
                                </p>
                            </div>
                        </EbloomTooltip>
                    </div>
                }
                return null
            }else{
                if(score === difference ){
                    return null
                }else if(Math.round(difference) > 0){
                    return <div>
                        <div className={"flex cursor-default justify-start items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowGreen className={""}/>
                            </div>
                        </div>
                    </div>

                }else if (Math.round(difference) < 0 ){
                    return <div>
                        <div className={"flex cursor-default justify-start items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowRed/>
                            </div>
                        </div>
                    </div>
                }else if (Math.round(difference) === 0){
                    return <div>
                        <div className={"flex cursor-default justify-start items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowNeutral/>
                            </div>
                        </div>
                    </div>
                }
                return null
            }
        }
        return null
    }

    return <div className={"grid grid-cols-12 gap-y-15 h-48"}>
        <div className={"col-start-1 col-end-3"}>
            <div className={"flex justify-center items-center relative"}>
                <div>
                    <CategoryBubble icon={category.category.icon} color={category.category.color}/>
                </div>

            </div>
        </div>
        <div className={"col-start-3 col-end-10 "}>
            <div className={" flex flex-col justify-start items-start"}>
                <h1 className={"text-left font-sans text-contrast-purple"}>{isModelCustomEnable ? category.category.name : _.upperFirst(t("core.category."+category.category.name))}</h1>
                <div className={"w-3/4 bg-[#D9D9D9] h-10 rounded-full relative"}>
                    <div className={" h-full rounded-full float-left "}
                         style={{
                             width: calculateWidth(category),
                             backgroundColor: getColorFromPercentage(category.score)
                         }}></div>
                </div>
            </div>
        </div>
        <div className={"col-start-10 col-end-12 flex justify-start items-center"}>
            <h1 className={" font-semibold text-center text-2xl text-contrast-purple"}>{category.total ? category.score + "%" : _.toUpper(t("webloom.NA"))}</h1>
            <span>{displayPreviousExercice(category)}</span>
        </div>
        {
            showArrow && <div className={"col-start-12 col-end-13 flex justify-center items-center"}>
                <ArrowRight className={"w-15 h-15 "}/>
            </div>
        }

    </div>
}

ScoreType.propTypes={
    category : PropTypes.object.isRequired,
    showArrow : PropTypes.bool,
    showPreviousDetails : PropTypes.bool,
    isModelCustomEnable : PropTypes.bool
}

ScoreType.defaultProps = {
    showArrow: true,
    showPreviousDetails : false,
    isModelCustomEnable : false,
}

export default ScoreType