import React from "react";
import PropTypes from "prop-types"
import EbloomTooltip from "./EbloomTooltip";
import {ReactComponent as InfoIcon} from "../../symbols/info.svg";
import {Colors} from "../../../../Utils/Colors";

const EbloomInfoTooltip = (props) => {

    //state

    //props
    const {text, placement, size,colors} = props;

    //hooks

    //getters

    //posters

    //handlers

    //useEffect


    return (
        <EbloomTooltip text={text} placement={placement}>
            <InfoIcon style={{fill : colors ? colors.secondary : Colors.EBLOOM_PURPLE}} className={size === "sm" ? "w-20 h-20" : size === "lg" ? "w-45 h-45" : "w-30 h-30"}/>
        </EbloomTooltip>
    )
}

EbloomInfoTooltip.propTypes = {
    text:PropTypes.string.isRequired,
    placement:PropTypes.string,
    size:PropTypes.string,
    colors : PropTypes.object
}

EbloomInfoTooltip.defaultProps = {
    placement:"bottom",
    size:"md",
    colors : null
}
export default EbloomInfoTooltip