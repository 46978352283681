import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import SubmitCustom from "./SubmitCustom";



const SubmitCustomPage = (props) => {
    return(
        <DisplayAdminView {...props} menu={"questions"} pageName={"admin.submitCustomSmallTitle"} selectedIndex={16}>
            {
                () => (
                    <Container>
                        <SubmitCustom {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default SubmitCustomPage