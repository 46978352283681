import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import EbloomPopoverV2 from "../Popovers-Tooltips/EbloomPopoverV2";
import EbloomDatePicker from "./EbloomDatePicker";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import moment from "moment";

const WebloomDatePicker = (props) => {
    const {date,handleDate,fromDate, placeholder} = props
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);

    const t = useTranslation()

    const handleClick = (event) => {
        setTarget(event.target);
        setShow(true);
    };

    return (
        <div>
            <EbloomPopoverV2 width={320} target={target} handleClose={() => setShow(false)} show={show}
                             trigger={
                                 <div onClick={handleClick}
                                      className={"border-1 border-contrast-blue text-contrast-blue rounded-md pointer h-25 w-full  flex  justify-center items-center px-5"}>
                                     <span>{!!date ? moment(date).format("DD/MM/YYYY") : _.upperFirst(t(placeholder))}</span>
                                 </div>
                             } placement={"bottom"} showHeader={false}>
                <div className={"w-full h-350 flex justify-center items-center"}>
                    <EbloomDatePicker mode={"single"} futureDates={true} handleStart={handleDate} singleDate={date} fromDate={fromDate} setShowDialog={setShow}/>
                </div>
            </EbloomPopoverV2>
        </div>
    );
};

WebloomDatePicker.propTypes = {
    date : PropTypes.string.isRequired,
    handleDate : PropTypes.func.isRequired,
    fromDate : PropTypes.string,
    placeholder : PropTypes.string,
};

WebloomDatePicker.defaultProps = {
    placeholder : "core.now"
};

export default WebloomDatePicker;
