import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import HttpApi from "../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {FullContrastPurpleTextField} from "../../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import EbloomSearchBar from "../../../../Core/Modules/Input/EbloomSearchBar";
import _ from "lodash";
import IconButton from "@mui/material/IconButton/IconButton";
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';

const LinkCompanies = (props) => {
    const {companyToEdit} = props
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [linkedCompanies, setLinkedCompanies] = useState([]);
    const [unlinkedCompanies, setUnlinkedCompanies] = useState([]);
    const [searchValueLinked, setSearchValueLinked] = useState("");
    const [searchValueUnLinked, setSearchValueUnLinked] = useState("");


    const handleLinkedCompanies = () => {
        const company = groups.filter(el => el.name === selectedGroup)[0]

    }

    const handleSearch = (event) => {
        if(event.target.name === "searchUnlinked") {
            setSearchValueUnLinked(event.target.value)
        }else{
            setSearchValueLinked(event.target.value)
        }
    }

    const handleAdd = (idCompany) => {
        const data = {idCompanyEntity : idCompany, idCompanyGroup : selectedGroup.id}
        HttpApi.put("/companies/linkCompanyToGroup",data).then((response) => {
            if(response && response.data){
                notifySuccess("Company added to the group")
                fetchAll()
            }
        }).catch(error => {
            notifyError(error)
        })

    }

    const handleRemove = (idCompany) => {
        const data = {idCompanyEntity : idCompany}
        HttpApi.put("/companies/unlinkCompanyToGroup",data).then((response) => {
            if(response && response.data){
                notifySuccess("Company removed from the group")
                fetchAll()
            }
        }).catch(error => {
            notifyError(error)
        })
    }

    const filterSearch = (array,linked = false) => {
      return array.filter(el => _.lowerCase(el.name).includes(_.lowerCase(linked ? searchValueLinked : searchValueUnLinked)))
    }

    const fetchAllEntitiesFromGroup = () => {
        HttpApi.getV2("/companies/findAllEntitiesFromGroup/"+selectedGroup.id).then((response) => {
            if(response && response.data){
                setLinkedCompanies(response.data)
            }
        }).catch(error => {
            notifyError(error)
        })
    }

    const fetchDataUnlinkedClient = () => {
        HttpApi.getV2("/companies/findAllUnlinked").then((response) => {
            if(response && response.data){
                setUnlinkedCompanies(response.data)
            }
        }).catch(error => {
            notifyError(error)
        })
    }
    const fetchAll = () => {
        setSearchValueUnLinked("")
        setSearchValueLinked("")
        fetchDataUnlinkedClient()
        if(selectedGroup !== null){
            fetchAllEntitiesFromGroup()
        }
    }
    useEffect(() => {
        fetchAll()
        if(companyToEdit !== null) {
            setSelectedGroup(companyToEdit)
        }
    }, [companyToEdit]);

    useEffect(() => {
        if(selectedGroup !== null){
            handleLinkedCompanies()
            fetchAllEntitiesFromGroup()
        }
    }, [selectedGroup]);



    return (
        <div className={"m-auto w-full"}>
            <CardDefault title={"Link companies"} showButton={false}>
                {
                    selectedGroup &&
                    <div className={"w-full h-full grid grid-cols-2 mt-30"}>
                        <div className={"flex flex-col items-center justify-start border-contrast-purple border-r-2"}>
                            <div className={"mb-20"}>
                                <p className={"ebloom-small-title text-contrast-purple"}>Linked Companies</p>
                            </div>
                            <div className={"w-full px-15"}>
                                <EbloomSearchBar size={"small"} name={"searchLinked"} className={"w-full"}
                                                 handleChange={handleSearch} searchValue={searchValueLinked}/>
                            </div>
                            <div className={"mt-30 px-15 divide-y w-full divide-contrast-purple"}>
                                {
                                    filterSearch(linkedCompanies,true).map((el, index) => (
                                        <div className={"w-full  h-50 my-10 relative flex justify-center items-center"}>
                                            <p className={"ebloom-text text-center"}>{el.name}</p>
                                            <div
                                                className={"w-30 h-30 absolute flex justify-center items-center right-20"}>
                                                <IconButton className={"text-purple"} onClick={() => handleRemove(el.id)}>
                                                    <LinkOffIcon className={"w-24 h-24"}/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div className={"flex flex-col items-center justify-start"}>
                            <div className={"mb-20"}>
                                <p className={"ebloom-small-title text-contrast-purple"}>Unlinked Companies</p>
                            </div>
                            <div className={"w-full px-15"}>
                                <EbloomSearchBar size={"small"} name={"searchUnlinked"} className={"w-full"}
                                                 handleChange={handleSearch} searchValue={searchValueUnLinked}/>
                            </div>
                            <div className={"mt-30 px-15 divide-y w-full divide-contrast-purple"}>
                                {
                                    filterSearch(unlinkedCompanies).map((el, index) => (
                                        <div className={"w-full  h-50 my-10 relative flex justify-center items-center"}>
                                        <p className={"ebloom-text text-center"}>{el.name}</p>
                                            <div className={"w-30 h-30 absolute flex justify-center items-center right-20"}>
                                                <IconButton className={"text-purple"} onClick={() => handleAdd(el.id)}>
                                                    <AddLinkIcon className={"w-24 h-24"}/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
            </CardDefault>
        </div>

    );
};

LinkCompanies.propTypes = {};

export default LinkCompanies;


