import React from "react";
import {useDrag} from "react-dnd";
import {PropTypes} from "prop-types";
import _ from "lodash";

const DraggableItem = (props) => {
    const {itemToDrag, handleItem, children, isNew} = props;
    const [{isDragging}, drag] = useDrag(
        () => ({
            type:"newQuestion",
            item:itemToDrag,
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult()
                if(item && dropResult !== null){
                    if(isNew){
                        item.id = _.uniqueId(Math.floor(Math.random()*100).toString());
                        item.new = true;
                    }
                    handleItem(item, dropResult.position);
                }
            },
            collect:(monitor) => ({
                isDragging: monitor.isDragging(),
            })
        }), [handleItem])

    return(
        <div ref={drag} style={{opacity: isDragging ? 0.5 : 1}} className={"cursor-grab"}>
            {children}
        </div>
    )
}

DraggableItem.propTypes = {
    itemToDrag:PropTypes.object.isRequired,
    handleItem:PropTypes.func.isRequired,
    type:PropTypes.string.isRequired

}

DraggableItem.defaultProps = {
    type:"newQuestion",
    isNew:false,
}

export default DraggableItem;