
export const browseLibrarySteps = [
    {
        title: "admin.productTour.browseLibrary.step1.title",
        content: "admin.productTour.browseLibrary.step1.content",
        target: '[data-browseLibrary="categories"]',
        placement: 'bottom',
        disableBeacon : true

    },
    {
        title: "admin.productTour.browseLibrary.step2.title",
        content: "admin.productTour.browseLibrary.step2.content",
        target: '[data-browseLibrary="tags"]',
        placement: 'bottom',
        disableBeacon : true

    },
    {
        title: "admin.productTour.browseLibrary.step3.title",
        content: "admin.productTour.browseLibrary.step3.content",
        target: '[data-browseLibrary="search"]',
        placement: 'bottom',
        disableBeacon : true

    },
    {
        title: "admin.productTour.browseLibrary.step4.title",
        content: "admin.productTour.browseLibrary.step4.content",
        target: '[data-browseLibrary="order"]',
        placement: 'right',
        disableBeacon : true

    },

]