
export const ModelTypes = {
    NUMBER:"NUMBER",
    TEXT:"TEXT",
    ARRAY:"ARRAY",
    DATE:"DATE",
    DATETIME:"DATETIME",
    OBJECT:"OBJECT",
    BOOLEAN:"BOOLEAN",
    RELATIONS:"RELATIONS",
    RELATION:"RELATION",
    PRISMA_RELATION:"PRISMA_RELATION",
    PRISMA_RELATIONS:"PRISMA_RELATIONS"
}