import React, {useEffect} from 'react';
import {ReactComponent as CloseIcon} from "../../../../Core/symbols/myBloom/icons/Close.svg";
import {ReactComponent as Dice} from "../../../../Core/symbols/myBloom/icons/DiceIcon.svg";

import PropTypes from 'prop-types';
import {
    A1, formAssetsList,
    HatLogo, HatWheelList,
    listOfPalettes,
    PaletteColors,
    PaletteIcon,
    selectEmote,
    selectForm,
    selectHat
} from "./AvatarAssets";
import {Colors} from "../../../../../Utils/Colors";

const EditAvatarMobile = props => {
    const {selectedForm,selectedHat,selectedColor,avatar,handleClose,handleSave,handleRandom,handleReset,handleChange} = props

    const handleMenu = (menu) => {
        document.querySelector(".selectedMenu").classList.remove("selectedMenu")
        document.querySelector("#avatar-menu-"+menu).classList.add("selectedMenu")
        handleMenuContent(menu)
    }

    const handleMenuContent = (menu) => {
        switch (menu) {
            case 1:
                menuColors()
                break
            case 2:
                menuForms()
                break
            case 3:
                menuHats()
                break
            default:
                break;
        }

    }

    const menuHats = () => {
        document.querySelector("#menu-hats").classList.remove("menu-hidden")
        document.querySelector("#menu-colors").classList.add("menu-hidden")
        document.querySelector("#menu-forms").classList.add("menu-hidden")

    }

    const menuColors = () => {
        document.querySelector("#menu-hats").classList.add("menu-hidden")
        document.querySelector("#menu-colors").classList.remove("menu-hidden")
        document.querySelector("#menu-forms").classList.add("menu-hidden")
    }
    const menuForms = () => {
        document.querySelector("#menu-hats").classList.add("menu-hidden")
        document.querySelector("#menu-colors").classList.add("menu-hidden")
        document.querySelector("#menu-forms").classList.remove("menu-hidden")
    }

    useEffect(() => {
        window.$crisp.push(["do", "chat:hide"])
        menuColors()
        return () => {
            window.$crisp.push(["do", "chat:show"])
            window.scrollTo(0, 0);

        };
    }, []);


    return (
        <div>
            <div
                className={`fixed inset-0 flex flex-col items-stretch justify-around bg-white z-50`}
            >
                <div className={"flex flex-row justify-between items-center mx-10 pt-20 font-semibold text-lg text-purple"}>
                    <button className={"w-35 h-35 bg-white rounded-full"}
                            onClick={handleClose}>
                        <CloseIcon/>
                    </button>
                    <button onClick={handleSave}>Save</button>
                </div>
                <div className={" w-full"}>
                    <div>
                        <div className="w-250 h-2/5 relative mx-auto">
                            <div className="w-full">
                                {selectForm(selectedForm, PaletteColors[selectedColor].avatar)}
                            </div>
                            <div className="absolute w-full top-0">
                                {selectedHat !== "16" ? selectHat(selectedHat, PaletteColors[selectedColor].avatar) : null}
                            </div>
                            <div className="absolute w-full top-0">
                                {selectEmote(avatar.emote)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"w-full h-full"}>
                    <div className={"flex flex-row justify-between items-center mx-10 mb-10 font-semibold text-lg text-purple"}>
                        <button onClick={handleRandom} className={"ml-5"}>
                            <Dice/>
                        </button>
                        <button onClick={handleReset}>Reset</button>
                    </div>
                    <div className={'w-full h-50 bg-bg-light-purple rounded-t-xl flex flex-row'}>
                        <div onClick={() =>handleMenu(1)} id={"avatar-menu-1"} className={"w-1/3 rounded-t-xl flex justify-center items-center selectedMenu"}>
                            <div className={"w-30"}>
                                <PaletteIcon className={"logoMenu"} fill={Colors.EBLOOM_PURPLE}/>
                            </div>
                        </div>
                        <div onClick={() =>handleMenu(2)} id={"avatar-menu-2"} className={"w-1/3 rounded-t-xl flex justify-center items-center relative"}>
                            <div className={"w-60 absolute bottom-5"}>
                                <A1 className={"logoMenu"} fill={Colors.EBLOOM_PURPLE}/>
                            </div>
                        </div>
                        <div onClick={() =>handleMenu(3)} id={"avatar-menu-3"} className={"w-1/3 rounded-t-xl flex justify-center items-center"}>
                            <div className={"w-40 mt-5"}>
                                <HatLogo className={"logoMenu"} fill={Colors.EBLOOM_PURPLE}/>
                            </div>
                        </div>
                    </div>
                    <div className={"w-full h-full bg-light-purple pt-10 relative"}>
                        <div id={"menu-forms"} className={'grid grid-cols-4 auto-rows-min gap-10 place-items-center absolute w-full'}>
                            {formAssetsList(PaletteColors[selectedColor].avatar).map((item,index) => (
                                <div key={index} id={item.category + item.id}  className={"w-60 h-60 bg-bg-white rounded-full p-5 " + (item.id === selectedForm ? "selectedAsset" :'') }>
                                    <button className={"w-full h-full"} onClick={() => handleChange(item.id, item.category)}>
                                        {item.asset}
                                    </button>
                                </div>))}
                        </div>
                        <div id={"menu-colors"} className={'grid grid-cols-4 auto-rows-min gap-10 place-items-center absolute w-full'}>
                            {listOfPalettes().map((item,index) => (
                                <div key={index} id={"color" + item.id} className={"w-60 h-60 bg-bg-white rounded-full p-10 " + (item.id === selectedColor ? "selectedAsset" :'')}>
                                    <button className={"w-full h-full"} onClick={() => handleChange(item.id, item.category)}>
                                        {item.asset}
                                    </button>
                                </div>))}
                        </div>
                        <div id={"menu-hats"} className={'grid grid-cols-4 auto-rows-min gap-10 place-items-center absolute w-full'}>
                            {HatWheelList.map((item,index) => (
                                <div key={index} id={item.category + item.id} className={"w-60 h-60 bg-bg-white rounded-full " + (item.id === selectedHat ? "selectedAsset" :'')}>
                                    <button className={"w-full h-full"} onClick={() => handleChange(item.id, item.category)}>
                                        {item.asset}
                                    </button>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditAvatarMobile.propTypes = {
    selectedForm : PropTypes.string.isRequired,
    selectedHat : PropTypes.string.isRequired,
    selectedColor: PropTypes.string.isRequired,
    avatar : PropTypes.object.isRequired,
    handleClose : PropTypes.func.isRequired,
    handleSave : PropTypes.func.isRequired,
    handleRandom : PropTypes.func.isRequired,
    handleReset : PropTypes.func.isRequired,
    handleChange : PropTypes.func.isRequired,
};

export default EditAvatarMobile;
