import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const ConfirmModalAvatar = (props) => {

    const {showModal, handleConfirm, handleCancel} = props
    const t = useTranslation()

    return <div className={`fixed inset-0 flex items-start justify-center bg-[#000]/60  z-[220] ${
        showModal ? "visible" : "hidden"
    }`}>
        <div className={"w-500 min-h-[200px] bg-transparent-blue mt-30 rounded-md transition"}>
            <div className={"divide-y divide-[#dee2e6]"}>
                <div>
                    <div className={"w-full p-16 bg-contrast-blue rounded-t-md"}>
                        <h1 className={"ebloom-xtra-large-text ebloom-text modal-title text-white text-center"}>{_.upperFirst(t("employee.dashboard.editAvatarModalTitle"))}</h1>
                    </div>
                    <div className={"p-16"}>
                        <p className={"ebloom-normal-text "}>{_.upperFirst(t("employee.dashboard.editAvatarModalText"))}</p>
                    </div>
                </div>
                <div className={"flex justify-end items-center p-16"}>
                    <button onClick={handleCancel} className={"btn btn-secondary m-4"}>{_.upperFirst(t("core.cancel"))}</button>
                    <button onClick={handleConfirm} className={"btn ebloom-btn-purple m-4"}>{_.upperFirst(t("core.confirm"))}</button>
                </div>
            </div>

        </div>
    </div>
}

ConfirmModalAvatar.propTypes = {
    showModal : PropTypes.bool.isRequired,
    handleConfirm : PropTypes.func.isRequired,
    handleCancel :  PropTypes.func.isRequired,
}

export default ConfirmModalAvatar