import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Identity from "../../../../Admin/Pages/Identity/Identity";



const BackofficeIdentityPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <Identity companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default BackofficeIdentityPage


