import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import {defineRolesSteps} from "./defineRolesSteps";
import PropTypes from "prop-types";
import {GlobalContext} from "../../../Context/GlobalContext";

const DefineRolesTour = (props) => {
    const {
        updateStep,
        stepTour,
        stepDefineRoles, setStepDefineRoles,
        tourStarted,
        setTourStarted,
        styleEbloom,
        fetchData,
        updateHandler,
    } = useContext(ProductTourContext)

    const {setSideFormEditOpen,setShowComparison,setSeePreview} = useContext(GlobalContext)
    
    const {setEmployee, setShowModal,setSeeOtherChannelCom} = props

    const admin = 1
    const advancedUser = 2
    const user = 3

    const role = 'id_roles'

    const changeRole = (value) => {
        setEmployee(prevState => {
            let employee = {...prevState};
            employee[role] = [value];
            return employee;
        })
    }

    const closeDrawer = () => {
        setSideFormEditOpen(false)
        setEmployee({id:null,email:"", name:"", roles:[""], id_roles:[-1], id_team:-1, teams:[], teams_collab:[], filters: []});
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type) ) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if (ACTIONS.NEXT===action) {
                if(index === 0){
                    updateStep(TourType.DEFINE_ROLES,next)
                    setShowModal(false)
                }else if (index === 2) {
                    changeRole(user)
                    updateStep(TourType.DEFINE_ROLES,next)
                }else if (index === 3) {
                    changeRole(admin)
                    updateStep(TourType.DEFINE_ROLES,next)
                }else if (index === 4) {
                    changeRole(advancedUser)
                    updateStep(TourType.DEFINE_ROLES,next)
                }else{
                    updateStep(TourType.DEFINE_ROLES,next)
                }
            }else if (ACTIONS.PREV===action) {
               if(index === 1){
                   setShowModal(true)
                   updateStep(TourType.DEFINE_ROLES,next)
               }else if (index === 2) {
                   updateStep(TourType.DEFINE_ROLES,next)
                   closeDrawer()
               }else if (index === 3) {
                   updateStep(TourType.DEFINE_ROLES,next)
               } else if (index === 4) {
                   changeRole(user)
                   updateStep(TourType.DEFINE_ROLES,next)
               } else if (index === 5) {
                   changeRole(admin)
                   updateStep(TourType.DEFINE_ROLES,next)
               } else{
                   updateStep(TourType.DEFINE_ROLES,next)
               }
            }
        }
    }

    const handleTour = () => {
        setSeeOtherChannelCom(false)
        setShowComparison(false)
        setSeePreview(false)
        updateStep(TourType.DEFINE_ROLES,0)
        setShowModal(true)
        setTimeout(()=>{
            setTourStarted(true)
        },200)

    }
    const updateTourStatus = () => {
        setTourStarted(false)

        const tour = {
            id : stepTour[TourType.DEFINE_ROLES].id,
            current_step : stepTour[TourType.DEFINE_ROLES].step >= defineRolesSteps.length - 1 ? 0 : stepTour[TourType.DEFINE_ROLES].step,
            type: TourType.DEFINE_ROLES,
            finished : stepTour[TourType.DEFINE_ROLES].outcome
        }
        if(stepDefineRoles >= defineRolesSteps.length - 1){
            setStepDefineRoles(0)
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})
        }
    }

    useEffect(() => {
        fetchData();
        updateHandler(TourType.DEFINE_ROLES,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);

    return(
        <>
            <ReactJoyride
                steps={defineRolesSteps}
                continuous
                run={tourStarted}
                stepIndex={stepDefineRoles}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />

        </>
    )
}

DefineRolesTour.prototype = {
    setEmployee: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setSeeOtherChannelCom: PropTypes.func.isRequired,
}
export default DefineRolesTour