import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import WeBloomDashboard from "../../../../Admin/Pages/WeBloom/Dashboard/WeBloomDashboard";


const WeBloomTemplatePage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <WeBloomDashboard isTemplate={true} companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
}

export default WeBloomTemplatePage;