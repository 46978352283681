import React, {useEffect, useState} from 'react';
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import HttpApi from "../../../../httpApi";
import {
    createErrorNotification,
    createErrorNotificationWithContent,
    notifyError,
    notifySuccess
} from "../../../../Utils/Notification";

import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import DropZoneCategory from "./DragAndDrop/DropZoneCategory";
import DraggableTag from "./DragAndDrop/DraggableTag";
import _ from "lodash";
import {defaultColors, selectIcon} from "./Assets/CategoriesAssets";
import Category from "./Components/Category";
import {Button} from "react-bootstrap";
import {useSavePage} from "../../../../hooks/savePageHook";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {useTranslation} from "react-multi-lang";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useUserData} from "../../../../hooks/userHook";


const CustomCategories = (props) => {

    const {companyToEdit,selectedEntity} = props

    const [categories, setCategories] = useState([]);
    const [tagsToDistribute, setTagsToDistribute] = useState([]);
    const [editActivated, setEditActivated] = useState(false);
    const [nameCategory, setNameCategory] = useState("");
    const [color, setColor] = useState("#C4E4FF");
    const [icon, setIcon] = useState("01");
    const [indexEdit, setIndexEdit] = useState(-1);
    const [initialCategories, setInitialCategories] = useState([]);
    const [modalReset, setModalReset] = useState(false);
    const [companyModelConfirmed, setCompanyModelConfirmed] = useState([]);
    const [ebloomModel, setEbloomModel] = useState([]);

    const [statusMode, setStatusMode] = useState(null);

    const t = useTranslation()
    const page = useSavePage(true)
    const user = useUserData()

    const isSuperAdmin = user.roles.includes("global_super_admin");


    const status = {
        CONFIRMED : 1,
        EBLOOM : 2,
        DRAFT : 3,
        MODIFIED : 4
    }

    const handleSaveEdit = (category) => {
        setEditActivated(false)
        setIndexEdit(-1)
        setCategories(prevState => {
            const indexCat = prevState.findIndex(el => el.id_category === category.id_category)
            prevState[indexCat].icon = icon
            prevState[indexCat].name = nameCategory
            prevState[indexCat].color = color
            return [...prevState]
        })
        updateOrder()
    }

    const handleEdit = (category) => {
        setEditActivated(true)
        setNameCategory(category.name)
        setIcon(category.icon)
        setIndexEdit(category.id_category)
        handleColor(category.color)
    }

    const handleColor = (color) => {
        if(color.length >= 1){
            setColor(color)
        }
    }

    const handleDeleteCategory = (idCategory) => {
        setCategories((prevState) => {
            const index = prevState.findIndex(el => el.id_category === idCategory)
            const categoryDeleted = prevState.splice(index,1)
            setTagsToDistribute(prevStateTags =>  [...prevStateTags, ...categoryDeleted[0].tags])
            return [...prevState]
        })
        setIndexEdit(idCategory)
        setEditActivated(false)
        updateOrder()
    }

    const handleAddCategory = () => {
        const id = parseInt(_.uniqueId(Math.floor(Math.random()*100).toString()));
        const category = {
            name : '',
            color : defaultColors[Math.floor(Math.random()*defaultColors.length)],
            icon : selectIcon(null),
            id_category : id,
            position : categories.length+1,
            tags : [],

        }
        handleEdit(category)
        setCategories(prevState => [...prevState,category])
        setIndexEdit(category.id_category)
    }

    const changeTagCategory = (tag,category) => {
        setCategories((prevState) => {
            if(tag.fromCategory !== null){
                //Remove
                const indexRemove = categories.findIndex(el => el.id_category === tag.fromCategory)
                const indexTagRemove = prevState[indexRemove].tags.findIndex(el => el.id_tag === tag.id)
                const removedTag = prevState[indexRemove].tags.splice(indexTagRemove,1)
                //Add
                const indexAddCategory = categories.findIndex(el => el.id_category === category.id_category)
                prevState[indexAddCategory].tags.push(...removedTag)
                return [...prevState]
            }else{
                let removedTag = []
                setTagsToDistribute(prevStateTags => {
                    const indexRemove = prevStateTags.findIndex(el => el.id_tag === tag.id)
                    removedTag = prevStateTags.splice(indexRemove,1)
                    return prevStateTags
                })
                const indexAddCategory = categories.findIndex(el => el.id_category === category.id_category)
                prevState[indexAddCategory].tags.push(...removedTag)
                return [...prevState]
            }

        })
    }

    const checkEquality = (a1, a2) => {
        if(a1.length !== a2.length){
            return false
        }
        for (const el of a1) {
            const result = a2.filter(c => c.id_category === el.id_category && c.name === el.name && parseInt(c.icon) === parseInt(el.icon) && c.color === el.color)
            if(result.length > 0){
                const tagsSame = checkTags(el.tags,result[0].tags)
                if(!tagsSame){
                    return false
                }
            }else{
                return false
            }
        }
        return true
    }

    const checkTags = (t1,t2) => {
        for (const el of t1) {
            const result = t2.filter(t => t.id_tag === el.id_tag)
            if(result.length <= 0){
                return false
            }
        }
        return true
    }

    const getStatusMode = () => {
        switch (statusMode) {
            case null :
                return ''
            case 1 :
                return _.upperCase(t("admin.customCategories.online"))
            case 2 :
                return "EBLOOM"
            case 3 :
                return _.upperCase(t("webloom.draft"))
            case 4 :
                return _.upperCase(t("webloom.editing"))
            default :
                return ''
        }
    }

    const changeStatusMode = () => {
        if(checkEquality(ebloomModel, categories)){
            //Ebloom model
            return setStatusMode(status.EBLOOM)
        }else if (checkEquality(companyModelConfirmed, categories)){
            // Company confirmed model
            return setStatusMode(status.CONFIRMED)
        }else {
            // Draft
            return setStatusMode(status.DRAFT)
        }
    }

    const saveDraft = () => {
        let url = "/categories/saveDraft"
        if(isSuperAdmin && selectedEntity !==null){
            url = "/categories/saveDraft/"+selectedEntity
        }
        const data = {
            categories : categories,
            tagsUnlinked : tagsToDistribute
        }
        HttpApi.postV2(url,data).then(response => {
            fetchCategories()
            notifySuccess(_.upperFirst(t("admin.customCategories.savedAsDraft")))
        }).catch(error => {
            notifyError(error)
        })
    }

    const goBackToEbloomModel = () => {
        let url = "/categories/resetToEbloomModel"
        if(isSuperAdmin && selectedEntity !==null){
            url = "/categories/resetToEbloomModel/"+selectedEntity
        }
        HttpApi.postV2(url,[]).then(response => {
            fetchCategories()
            notifySuccess(_.upperFirst(t("admin.customCategories.resetEbloom")))
        }).catch(error => {
            notifyError(error)
        })
    }

    const saveModel = () => {
        let msg = []
        let isInvalid = false
        if(categories.length < 3){
            msg.push(_.upperFirst(t("admin.customCategories.errorMinimumCategory")))
            isInvalid = true
        }
        for (const c of categories) {
            if(c.name.length < 1 || c.tags.length < 1) {
                msg.push(_.upperFirst(t("admin.customCategories.errorTags")))
                isInvalid = true
                break
            }
        }
        if(tagsToDistribute.length > 0 ) {
            msg.push(_.upperFirst(t("admin.customCategories.errorTags")))
            isInvalid = true
        }

        if(isInvalid){
            const content = <div>
                <ul>
                    {msg.map(el => (
                        <li>{el}</li>
                    ))}
                </ul>
            </div>
            createErrorNotification(_.upperFirst(t("admin.customCategories.errorTitle")), content)
        }else{
            let url = "/categories/saveModel"
            if(isSuperAdmin && selectedEntity !==null){
                url = "/categories/saveModel/"+selectedEntity
            }
            HttpApi.postV2(url, {categories: categories}).then(response => {
                notifySuccess(_.upperFirst(t("admin.customCategories.modelSaved")))
                fetchCategories()
            }).catch(error => {
                notifyError(error)
            })
        }
    }

    const updateOrder = () => {
        setCategories(prevState => {
            return prevState.map((el, index) => {
                el.position = index + 1
                return el
            })
        })
    }

    const fetchCategories = () => {
        let url = "/categories/getAllCustomCategories"
        if(isSuperAdmin && selectedEntity !==null){
            url = "/categories/getAllCustomCategories/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if (response && response.data){
                const data = response.data
                setEbloomModel(_.cloneDeep(data.ebloomModel))
                setCompanyModelConfirmed(_.cloneDeep(data.companyModelConfirmed))
                if(data.companyModelDraft.length === 0){
                    if(data.companyModelConfirmed.length === 0){
                        setInitialCategories(_.cloneDeep(data.ebloomModel))
                        setCategories(data.ebloomModel)
                        setStatusMode(status.EBLOOM)
                    }else{
                        setInitialCategories(_.cloneDeep(data.companyModelConfirmed))
                        setCategories(data.companyModelConfirmed)
                        setStatusMode(status.CONFIRMED)
                    }
                }else{
                    setInitialCategories(_.cloneDeep(data.companyModelDraft))
                    setCategories(data.companyModelDraft)
                    setStatusMode(status.DRAFT)
                }
                setTagsToDistribute(data.tagsToDispatch)
            }
        }).catch(error => {
            notifyError(error)
        })

    }

    useEffect(() => {
        const value = checkEquality(categories,initialCategories)
        if(!value){
            setStatusMode(status.MODIFIED)
            page.unSave()
        }else{
            changeStatusMode()
            page.save()
        }
    }, [categories]);

    useEffect(() => {
        fetchCategories()
    }, [selectedEntity,companyToEdit]);

    return (
        <CardDefault title={_.upperFirst(t("admin.customCategories.title"))} showButton={false}>
            <div className={"w-full flex justify-end items-end px-20"}>
                <div className={"rounded-xl bg-bg-blue px-10 py-3"}>
                    <p className={"text-contrast-blue"}>{getStatusMode()}</p>

                </div>
            </div>
            <div className={"px-20"}>
                <DndProvider backend={HTML5Backend}>
                    {
                        tagsToDistribute.length > 0 &&
                        <div className={"w-full flex flex-wrap justify-start items-center"}>
                            <p className={"font-semibold mr-10"}>{_.upperFirst(t("admin.customCategories.toDispatch"))}</p>
                            {
                                tagsToDistribute.map(tagDispatch => {
                                    return (
                                        <DraggableTag key={tagDispatch.id_tag} itemType={{type : "tag", id : tagDispatch.id_tag , fromCategory: null, name : tagDispatch.name }}>
                                            <div className={"border-1 border-purple rounded-md flex justify-center items-center w-236 h-30 my-5 mx-5 px-10 py-5 cursor-pointer"}>
                                                <EbloomTooltip text={t("core.tag."+tagDispatch.name)}>
                                                    <h2 className={"w-full h-min truncate ebloom-normal-text text-purple text-center"}>{t("core.tag."+tagDispatch.name)}</h2>
                                                </EbloomTooltip>
                                            </div>
                                        </DraggableTag>
                                    )
                                })
                            }
                        </div>
                    }
                    <div className={"w-full h-full grid grid-cols-5 gap-x-10 gap-y-10 "+ (tagsToDistribute.length > 0 ? "my-20" : "my-60")}>
                        {
                            categories.sort((a,b) => a.position - b.position).map((category,index) => {
                                return(
                                    <div key={category.id_category} className={"w-full h-full flex flex-col"}>
                                        <Category
                                            handleSave={handleSaveEdit}
                                            handleColor={handleColor}
                                            handleEdit={handleEdit}
                                            nameCategory={nameCategory}
                                            color={color}
                                            icon={icon}
                                            editActivated={editActivated}
                                            indexEdit={indexEdit}
                                            setEditActivated={setEditActivated}
                                            setIcon={setIcon}
                                            setNameCategory={setNameCategory}
                                            category={category}
                                            handleDeleteCategory={handleDeleteCategory}
                                            setIndexEdit={setIndexEdit}
                                        />
                                        <div className={"flex flex-col w-full h-full"}>
                                            {
                                                category.tags.map((tag) => {
                                                    return (
                                                        <DraggableTag key={tag.id_tag} itemType={{type : "tag", id : tag.id_tag , fromCategory: category.id_category, name : tag.name }}>
                                                            <div className={"border-1 border-contrast-purple rounded-md flex justify-center items-center w-full h-30 my-5 px-10 py-5 cursor-pointer"}>
                                                                <EbloomTooltip text={t("core.tag."+tag.name)}>
                                                                    <h2 className={"w-full h-min  truncate ebloom-normal-text text-center"}>{t("core.tag."+tag.name)}</h2>
                                                                </EbloomTooltip>

                                                            </div>
                                                        </DraggableTag>
                                                    )
                                                })
                                            }
                                            <DropZoneCategory key={index} category={category} changeTagCategory={changeTagCategory}/>

                                        </div>
                                    </div>

                                )
                            })
                        }
                        {
                            (categories.length < 10 && !editActivated )&&
                            <button onClick={() => handleAddCategory()} className={"ebloom-btn-purple h-75 w-full flex flex-col justify-center items-center rounded-md"}>
                                <p className={"text-2xl font-bold h-min text-center"}>+</p>
                                <h1>{_.upperFirst(t("admin.customCategories.newCategory"))}</h1>
                            </button>
                        }
                    </div>
                </DndProvider>
            </div>


                <div className={"w-full flex justify-between items-center"}>

                    <div>
                        {
                            !checkEquality(ebloomModel, categories) &&
                            <button onClick={()=> setModalReset(true)} className={"underline text-purple mx-15 h-full flex justify-center items-end"}>
                                <p className={""}>{_.upperFirst(t("webloom.reset"))}</p>
                            </button>
                        }

                    </div>

                    <div className={"w-full flex justify-end items-center"}>
                        {
                            !checkEquality(initialCategories, categories) &&
                            <button onClick={() => saveDraft()} className={"underline text-purple mx-15 h-full flex justify-center items-end"}>
                                <p className={""}>{_.upperFirst(t("admin.customCategories.saveDraft"))}</p>
                            </button>
                        }
                        {
                            !checkEquality(companyModelConfirmed, categories) &&
                            <Button onClick={() => saveModel()} className={"ebloom-btn-purple"}>
                                {_.upperFirst(t("core.save"))}
                            </Button>
                        }

                    </div>
                </div>

            <ConfirmModal handleClose={()=> setModalReset(false)} show={modalReset} title={_.upperFirst(t("admin.customCategories.modalResetTitle"))} handleChange={() => goBackToEbloomModel()} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>
                    {_.upperFirst(t("admin.customCategories.modalResetContent"))}
                </p>
            </ConfirmModal>
        </CardDefault>
    );
};

export default CustomCategories;
