import {ModelTypes} from "./Types";

const Team = {
    crud:{
        create:"/teams/create",
        update:"/teams/update",
        delete:"/teams/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"team-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
        }
    },

    id_company:{
        id:"id_company",
        type:ModelTypes.NUMBER,
        label:"id_company",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
            id:"company-team",
            label:"core.company.company",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_company"
        }
    },

    company:{
        id:"company",
        type:ModelTypes.TEXT,
        label:"core.company.company",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    nbUser:{
        id:"nbUser",
        type:ModelTypes.NUMBER,
        label:"admin.size",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

    isValid:{
        id:"isValid",
        type:ModelTypes.TEXT,
        label:"admin.validity",
        table:{
            show:true
        },

        list:{
            show:false
        },

        form:{
            show:false
        }
    },


    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Team