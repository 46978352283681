import React from 'react';
import {Container} from "react-bootstrap";
import ManagerDashboard from "../../../../Manager/Pages/ManagerDashboard";
import DisplayBackofficeDashboardView from "../../../Layouts/DisplayBackofficeDashboardView";
import SuperAdminDashboard from "../../../../SuperAdmin/Pages/Dashboard/SuperAdminDashboard";


const AdminDashboardPage = (props) => {
    return (
        <DisplayBackofficeDashboardView showDatePicker {...props}>
            {(companyToEdit, unlockedBackoffice, formattedStartDate, formattedEndDate, timeFilterVersion) => (
                <Container>
                    <React.Fragment>
                        {
                            companyToEdit && companyToEdit.type === "group" ?
                                <SuperAdminDashboard isAllEntities={false} companyToEdit={companyToEdit} timeFilterVersion={timeFilterVersion} startDate={formattedStartDate} endDate={formattedEndDate} teamToDisplay={[companyToEdit]} />
                                :
                                <ManagerDashboard companyToEdit={companyToEdit} startDate={formattedStartDate} endDate={formattedEndDate} timeFilterVersion={timeFilterVersion}/>

                        }
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayBackofficeDashboardView>
    )
};

export default AdminDashboardPage;
