import React, {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../../httpApi";
import {notifyError} from "../../../../../../Utils/Notification";
import {SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import PollFrame from "../../Polls/Modules/PollFrame";
import {Col, ListGroup, Row} from "react-bootstrap";
import _ from "lodash";
import moment from "moment/moment";
import BubbleChatList from "../../Polls/Results/Modules/BubbleChatList";
import EbloomPopover from "../../../../../Core/Modules/Popovers-Tooltips/EbloomPopover";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {useUserData} from "../../../../../../hooks/userHook";

const WeBloomResultAlert = () => {

    //state
    const [survey, setSurvey] = useState(null)
    const [entity, setEntity] = useState(null);
    const [isGroup, setIsGroup] = useState(false);

    //hooks
    const t = useTranslation();
    const location = useLocation();
    const userData = useUserData();
    const {id,idGroup} = useParams();

    const isProspect = userData.module === "prospect"
    //getters

    //posters

    //handlers

    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    const handleMarkAsRead = (id_answer) => {
        setSurvey(prevState => {
            let survey = {...prevState};
            let answer  = survey.question.answers.find(el => el.id === id_answer);
            answer.isRead = true;
            return survey;
        })
    }

    const handleSaveConversation = (text, id_collab_user_question) => {
        HttpApi.post("/collaborative/insertConversation", {id_collab_user_question:id_collab_user_question, text:text, id_survey:survey.id}).then(() => {
            setSurvey(prevState => {
                let survey = {...prevState};
                let answer  = survey.question.answers.find(el => el.id === id_collab_user_question);
                answer.conversations.push({id:null, text:text, id_collab_user_question:id_collab_user_question, id_user:userData.id, isRead:false, created_at:new Date()});
                return survey;
            })
        }).catch(error => {
            notifyError(error);
        })
    }

    const noData = !survey || !survey.questions || !survey.questions[0] || !survey.questions[0].answers || survey.questions[0].answers.length<1;


    //useEffect

    useEffect(() => {

        let idEntity = null
        let isGroupValue = false

        if(idGroup){
            idEntity = parseInt(idGroup);
            isGroupValue = idEntity === userData.companyGroup.id
            setIsGroup(isGroupValue)
            setEntity(idEntity)
        }
        if(location && location.state){
            setSurvey(location.state);
        }else if(id){
            let url = "/webloom/getSurveyTeamResults/" + id + "/0"
            if(idEntity !== null){
                if (isGroupValue){
                    url = "/webloom/group/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/0"
                }else{
                    url = "/webloom/entities/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/0"
                }
            }
            HttpApi.getV2(url).then(response => {
                if (response && response.data) {
                    setSurvey(response.data);
                }
            }).catch(error => notifyError(error));
        }
    }, [id])

    return (
        <>
            <PollFrame status={SurveyStatus.DONE} noData={noData} type={SurveyTypes.ALERT}>
                {
                    survey &&
                    <div className={"cursorDefault"}>
                        <Row className={"mb-20"}>
                            <Col>
                                <div className={"grid grid-rows-1 grid-cols-3 items-center text-contrast-purple mb-20"}>
                                    <span>{displayDate()}</span>
                                    <div className={"justify-self-center"}>
                                        {
                                            isProspect ?
                                                <div>
                                                    <span style={{fontSize:18}} className={"text-line-break"}>#{survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) :isProspect ?  _.upperFirst(t("core.customized")) : survey.recipients}</span>
                                                </div>
                                                :
                                                <EbloomPopover  isCollab={true} triggerText={survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) : survey.recipients} placement={"bottom"} triggerVariant={"link"}>
                                                    {
                                                        () => (
                                                            <ListGroup variant={"flush"}
                                                                       style={{maxHeight: 250, overflowY: "auto", overflowX: "hidden"}}>
                                                                {
                                                                    survey.users.map((user, index) => (
                                                                        <ListGroup.Item key={index} style={{
                                                                            padding: 0,
                                                                            border: "none"
                                                                        }}>{user.email}</ListGroup.Item>
                                                                    ))
                                                                }
                                                            </ListGroup>
                                                        )
                                                    }
                                                </EbloomPopover>
                                        }
                                    </div>
                                    <div className={"justify-self-end"}><span>{survey.question.answers?.length || 0}</span> {_.upperFirst(t("webloom.alerts"))}</div>
                                </div>
                                <Row>
                                    <Col className={"text-center"}>
                                        <p className={"ebloom-text ebloom-very-large-text font-semibold text-purple"}>{_.upperFirst(t("webloom.alertText"))}</p>
                                    </Col>
                                </Row>
                                {
                                    survey.image_url &&
                                    <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400}/>
                                }
                                {
                                    survey.attachment_url &&
                                    <FileDisplay className={"w-fit m-auto my-10"} urlParams={survey.attachment_url}/>
                                }
                            </Col>
                        </Row>
                        <hr className={"border-contrast-purple"}/>
                        <div className={"w-1/2 m-auto"}>
                            {
                                (noData) ?
                                    <p className={"text-contrast-purple text-center my-10 font-semibold"}>{_.upperFirst(t(noData ? "core.collaborative.noAnswer" : "manager.dashboard.anonymousRestriction"))}</p>
                                    :
                                    <BubbleChatList consultOnly={!!entity || isGroup} answers={survey.question.answers} handleMarkAsRead={isProspect ? ()=> {} :handleMarkAsRead} showAnswerArrow handleSaveConversation={!!entity || isGroup ? null :handleSaveConversation}/>
                            }
                        </div>
                    </div>
                }
            </PollFrame>
        </>
    )
}

WeBloomResultAlert.propTypes = {}

WeBloomResultAlert.defaultProps = {}

export default WeBloomResultAlert