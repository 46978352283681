import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import {Col, Row} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {displayDate as displayFormatDate, replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import BubbleChat from "../../../../../../Admin/Pages/WeBloom/Polls/Results/Modules/BubbleChat";

const AnswerAlert = (props) => {

    //state
    const {survey, handleSave, loading} = props;

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers

    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    const sortAnswers = (a,b) => {
        if(a.created_at<b.created_at){
            return -1;
        }else if(a.created_at>b.created_at){
            return 1;
        }
        return 0.
    }

    //useEffect

    useEffect(() => {

    }, [])

    return (
        <div className={"cursorDefault"}>
            <Row className={"mb-20"}>
                <Col>
                    <div>
                        <span className={"text-purple"}>{displayDate()}</span>
                    </div>
                    <Row>
                        <Col className={"text-center"}>
                            <p className={"ebloom-text ebloom-very-large-text font-semibold text-purple"}>{_.upperFirst(t("webloom.alertText"))}</p>
                        </Col>
                    </Row>
                    <div className={"w-1/2 m-auto mt-10"}>
                        {
                            survey.image_url &&
                            <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400}/>
                        }
                    </div>
                    <div className={"w-1/2 m-auto flex justify-center mt-10"}>
                        {
                            survey.attachment_url &&
                            <FileDisplay urlParams={survey.attachment_url}/>
                        }
                    </div>

                </Col>
            </Row>
            <hr className={"border-purple"}/>
            <Row className={"mt-20"}>
                <Col md={{offset: 3, span: 6}}>
                    {
                        survey.question?.answers && survey.question.answers.length > 0 &&
                        <div className={"my-10"}>
                            {
                                survey.question.answers.sort((a,b) => sortAnswers(a,b)).map((answer, index) => (
                                    <div key={index}>
                                        <div className={"text-contrast-blue ebloom-text font-semibold my-5"}>{_.upperFirst(t("webloom.alertLaunchedOn", {param: displayFormatDate(answer.created_at, true)}))}</div>
                                        <BubbleChat text={answer.text} isMyMessage/>
                                        {
                                            answer.conversations?.map((conversation, index) => (
                                                <div className={"px-20 my-5"} key={index}>
                                                    <div className={"text-contrast-purple ebloom-text ebloom-very-small-text text-right"}>{displayFormatDate(conversation.created_at, true)}</div>
                                                    <BubbleChat text={conversation.text}
                                                                isRead={conversation.isRead}/>
                                                </div>
                                            ))
                                        }
                                        <div className={"w-full my-20 h-2 border-b border-contrast-purple border-dashed"}/>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className={"text-contrast-blue ebloom-text font-semibold my-5"}>{_.upperFirst(t("webloom.newAlert"))}</div>
                    <BubbleChat text={""} isMyMessage handleSave={handleSave} loading={loading}/>
                </Col>
            </Row>
        </div>
    )
}

AnswerAlert.propTypes = {
    survey: PropTypes.object.isRequired,
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
}

AnswerAlert.defaultProps = {}
export default AnswerAlert