/**
 * Recover the current storage (session if exists for demo account, local otherwise)
 * @param {string} key
 * @returns {object|null}
 */
export const getStorage = (key="ebloom-credentials") => {
    if(sessionStorage.getItem(key) !== null){
        return JSON.parse(sessionStorage.getItem(key));
    }
    return localStorage.getItem(key) !== null ? JSON.parse(localStorage.getItem(key)) : null;
}


/**
 * Recovers value from ebloom-credentials localStorage (sessionStorage if exists for demo account) according to the requested key
 * Available keys are : id, email, name, company, role, module, company_type, id_company, token
 * @param {string} key
 * @returns {string|int|null}
 */
export const getFromStorage = (key) => {
    let storage = localStorage.getItem("ebloom-credentials");
    if(sessionStorage.getItem("ebloom-credentials") !== null){
        storage = sessionStorage.getItem("ebloom-credentials");
    }
    if(storage !== null){
        storage = JSON.parse(storage);
        if(key === "id" && !storage[key]){
            key="userId";
        }
        if(storage[key] !== null && (key === "id" || key === "id_company")){
            return Number(storage[key]);
        }
        return storage[key];
    }
    return null;
}