import React from 'react';

import MenuAppBar from "../../Core/Layouts/AppBar";


const HeaderBackoffice = (props) => {

    const handleDrawer = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        props.handleDrawerToggle();
    };


    return (
      <MenuAppBar switchCompany={props.switchCompany} handleDrawerToggle={handleDrawer()} {...props}/>

     );

}

export default HeaderBackoffice;
