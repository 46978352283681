import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {Button, Col, Form, Row} from "react-bootstrap";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import HttpApi from "../../../../httpApi";
import fileSaver from "file-saver/dist/FileSaver";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../Utils/Notification";
import readXlsxFile from "read-excel-file";
import InvitePreview from "./InvitePreview";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import InviteEditor from "./InviteEditor";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import {GlobalContext} from "../../../../Utils/Context/GlobalContext";
import {Modules, TourType} from "../../../../Utils/Global";
import InviteTour from "../../../../Utils/ProductTour/Tours/InviteUser/InviteTour";
import {useUserData} from "../../../../hooks/userHook";

const InviteUsers = (props) => {

    //state
    const [excelData, setExcelData] = useState([]);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [users, setUsers] = useState([]);
    const [integrationType, setIntegrationType] = useState("")

    const {updateStep,setTourStarted,tourActivated,setContinuous} = useContext(ProductTourContext);
    const {sideFormOpen, setSideFormOpen, invitePreview, setInvitePreview} = useContext(GlobalContext);
    //props
    const {createdEmailsAndPhones, handleBack, handleSeePreviewAndReload} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData();

    //getters
    const downloadTemplate = () => {
        HttpApi.getWithResponseTypeV2("/companies/template/false").then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'ebloom-users-template.xlsx');
            notifySuccess("Template downloaded");
        })
    }

    const fetchIntegrationUserList = (type) => {
        HttpApi.getV2("/users/"+type+"/sync").then(response => {
            if(response && response.data){
                if(response.data !== true){
                    setUsers(response.data);
                }
            }else if(response && response.data === false){
                window.open(HttpApi.getBaseUrl() + "/auth/"+type+"?id=" + userData.companyId, "_self");
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    //posters
    const handleSaveExcel = () => {
        if(!loadingExcel && userData.module !== "happy"){
            if(excelData && excelData.length > 1){
                setLoadingExcel(true);
                HttpApi.postV2("/users/checkMatching", {users:excelData}).then(response => {
                    if(response && response.data){
                        setExcelData([]);
                        setSideFormOpen(false);
                        setUsers(response.data.tableToInvite);
                        setContinuous(true)
                        updateStep(TourType.INVITE,5)
                    }
                }).finally(() => setLoadingExcel(false));
            }else{
                createErrorNotification("Error!", "Please provide a filled excel file.");
            }
        }
    }

    //handlers
    const handleGoogle = () => {
        window.open(HttpApi.getBaseUrl() + "/auth/google", "_self");
    }
    const toggleDrawer = (open, toEdit=null) => event =>{
        event.preventDefault();
        setSideFormOpen(open);
        if(open){
            setTimeout(() => {
                updateStep(TourType.INVITE,2)
            },300)

        }else{
            setTourStarted(false)
        }
    }

    const handleExcel = (event) => {
        readXlsxFile(event.target.files[0]).then((rows) => {
            if(rows && rows.length > 1){
                setExcelData(rows);
            }else if(!rows || rows.length <= 1) {
                createErrorNotification("Error!", "Empty file, please provide a filled excel file");
                document.getElementById("input-invite-users").value = null;
                setExcelData([]);
            }else{
                createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
                document.getElementById("input-invite-users").value = null;
                setExcelData([]);
            }
        }).catch(() => {
            createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
            document.getElementById("input-invite-users").value = null;
            setExcelData([]);
        })
    }

    const handleBackToUsers = () => {
        handleBack();
        setTourStarted(false)
    }

    const handleChangeUsers = (newUsers) => {
        setUsers(newUsers);
    }

    const handleButton = () => {
        HttpApi.postV2("/users/saveUsers", {users:users}).then(() => {
            notifySuccess(_.upperFirst(t("admin.invite.success.userSaved")));
            setInvitePreview(true);
            if(tourActivated)
                if(Modules.HAPPY.includes(userData.module)){
                    updateStep(TourType.INVITE,4);
                }else{
                    updateStep(TourType.INVITE,9)
                }
        })
    }

    const handleSeePreview = () => {
        if (invitePreview){
            setTourStarted(false)
            setInvitePreview(false);
            setContinuous(true)
            if(Modules.HAPPY.includes(userData.module)){
                updateStep(TourType.INVITE,4);
            }else{
                updateStep(TourType.INVITE,5);
            }

        }else{
            setInvitePreview(true);
            setContinuous(false)
            if(Modules.HAPPY.includes(userData.module)){
                updateStep(TourType.INVITE,4);
            }else{
                updateStep(TourType.INVITE,7);
            }

        }
        handleSeePreviewAndReload();
    }

    const getLogo = (value) => {
        const logo = require("../../../Core/symbols/integrations/"+value+".svg")
        return <img src={logo} alt={"Logo " + value} width={35} height={35}/>
    }

    //useEffect

    useEffect(() => {
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        let strategyUrl = url.searchParams.get("type");
        if(strategyUrl){
            fetchIntegrationUserList(strategyUrl);
        }
        if(productTour && productTour === "invite"){
            setTourStarted(true);
        }
        HttpApi.getV2("/integrations/sso/fetch").then(response => {
            if(response && response.data){
                setIntegrationType(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })

        return () => {
            setInvitePreview(false)
            setSideFormOpen(false)
        }
    }, [])

    return (
        <>
            <InviteTour/>
            {
            invitePreview ?
            <InvitePreview toInviteConfirm={users} handleSeePreview={handleSeePreview} handleSeePreviewAndReload={props.handleSeePreviewAndReload}/>
            :
            <div>
                <CardDefault title={_.upperFirst(t("core.inviteUsers"))} showButton={users && users.length >0 && userData.module !== "happy"} rightButtonVariant={"link"} handleRightButton={toggleDrawer(true)} buttonText={_.upperFirst(t("admin.invite.inviteViaExcel"))} showLeftButton leftButtonVariant={"secondary"} leftButtonText={_.upperFirst(t("core.back"))} handleLeftButton={handleBackToUsers} tourDataset={{card : '',leftBtn:'', btn : 'excelBtn', title : '',}} tourTooltip={true} tourType={TourType.INVITE}>
                    <InviteEditor createdEmailsAndPhones={createdEmailsAndPhones} usersTable={users} handleChangeUsers={handleChangeUsers} buttonText={_.upperFirst(t("core.saveAndInviteUsers")) + " (" + _.upperFirst(t("core.seePreview")) + ")"} handleButton={handleButton} handleSeePreviewAndReload={handleSeePreviewAndReload} isInvite/>
                    {
                        ((!users || users.length === 0) && userData.module !== "happy") &&
                        <div className={"flex justify-end items-center mt-30"}>
                            {
                                integrationType &&
                                <Button className={"bg-bg-white text-black border border-black flex items-center mr-5"} variant={"secondary"} onClick={() => fetchIntegrationUserList(integrationType)}>
                                    {getLogo(integrationType)}
                                    <span className={"ebloom-text ebloom-normal-text text-black ml-5 w-full"}>{_.upperFirst(t("admin.synchronizeUsers"))}</span>
                                </Button>
                            }
                            <span data-tour={"excelBtn"} className={"ebloom-link-purple"} onClick={toggleDrawer(true)}>{_.upperFirst(t("admin.invite.inviteManyExcel"))}</span>
                        </div>
                    }
                </CardDefault>
                <SideForm tourDataset={{card : 'inviteSideCard', btn : 'btnInviteSave'}} sideFormOpen={sideFormOpen} formTitle={_.capitalize(t("core.inviteUsers"))} toggleDrawer={toggleDrawer} handleSave={handleSaveExcel} loading={loadingExcel} buttonDisabled={excelData.length === 0}>
                    <p className={"w-350 text-justify"}>{ _.upperFirst(t("admin.uploadInviteHelper"))}</p>
                    <p className={"text-strong-red text-center"}>{_.upperFirst(t("admin.uploadHelper2"))}</p>
                    <Row>
                        <span data-invite={'template'} className={"ebloom-link-purple ebloom-text ebloom-very-large-text text-center"} onClick={downloadTemplate}>{_.upperFirst(t("core.downloadParam", {param:"template"}))}</span>
                    </Row>
                    <Row className={"mt20 mb20"}>
                        <Col>
                            <Form.Control data-invite={'chooseFile'} type="file" id="input-invite-users" accept=".xlsx, .xlsm, .xls" onChange={handleExcel} />
                        </Col>
                    </Row>
                </SideForm>
            </div>
            }
        </>
    )
}

InviteUsers.propTypes = {
    createdEmailsAndPhones:PropTypes.array.isRequired,
    handleBack:PropTypes.func.isRequired
}

InviteUsers.defaultProps = {}
export default InviteUsers