import React, {useEffect, useState} from "react";
import {Modal, Button, ListGroup, Row, Col} from "react-bootstrap";
import {createSuccessNotification, notifyError} from "../../../../Utils/Notification";
import HttpApi from "../../../../httpApi";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {CompanyTypes} from "../../../../Utils/Global";
import {useUserData} from "../../../../hooks/userHook";
import EbloomSearchBar from "../Input/EbloomSearchBar";

const CompanySwitcher = (props) => {
    const user = useUserData();

    const [show, setShow] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [companyToEdit, setCompanyToEdit] = useState(localStorage.getItem("ebloom-companyToEdit") ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")) : null);
    const [searchWord, setSearchWord] = useState("");
    const [category, setCategory] = useState(user.roles.includes("global_backoffice") ? "client" : user.roles.includes("demo_backoffice") ? "demo" : user.roles.includes("datatopia_backoffice") ? "datatopia" : "other");

    const t = useTranslation();

    const fetchCompanies = () => {
        const url = "/companies/findAll";
        HttpApi.getV2(url).then(results => {
            if(results && results.data){
                if(user.roles.includes("global_backoffice")){
                    setCompanies(results.data);
                }else if(user.roles.includes("demo_backoffice")){
                    setCompanies(results.data.filter(el => el.type === CompanyTypes.DEMO));
                }

            }else{
                setCompanies([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    };


    const handleClose = () => {
        setShow(false);
    };

    const handleOpen = () => {
        setShow(true);
    };

    const handleChange = (company) => {
        const data = {
            id:company.id,
            name:company.name,
            type:company.type,
            module : company.module.code,
            company_group : company.company_group?.id ? {
                id: company.company_group.id ,
                name : company.company_group.name
            }: null,
            companies_entities : company.companies_entities
        }
        HttpApi.getV2("/auth/companyToEdit/"+company.id).then(results => {
            if(results && results.data){
                const backOfficeToken = results.data;
                HttpApi.setBackofficeToken(backOfficeToken)
            }
            localStorage.setItem("ebloom-companyToEdit", JSON.stringify(data));
            setCompanyToEdit(data);
            props.switchCompany(company);
            createSuccessNotification("Success !", "Company to edit successfully switched to " + company.name);
            setShow(false);
        })

    };

    const handleSearch = (event) => {
        setSearchWord(event.target.value);
    }

    const handleCategory = (value) => {
        setCategory(value);
    }


    useEffect(() => {
        fetchCompanies();
    }, []);

    return(
        <>
            <Button variant="light" size={"sm"} onClick={handleOpen}>
                {
                    companyToEdit ?
                        companyToEdit.name
                        : "Select company"
                }
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={"bg-contrast-blue text-white ebloom-text ebloom-xtra-large-text"}>
                    <Modal.Title>Company Switcher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EbloomSearchBar handleChange={handleSearch} searchValue={searchWord} size="small" className={"w-full mb-10"}/>
                    {
                        user.roles.includes("global_backoffice") &&
                        <div className={"flex justify-between mt-10 mb-10"}>
                            <div className={"border rounded-md p-5 pointer " + (category === "client" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple")} onClick={() => handleCategory("client")}>Clients</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "demo" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("demo")}>Démo</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "free" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("free")}>Free</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "group" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("group")}>Group</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "prospect" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("prospect")}>Prospect</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "other" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("other")}>Other</div>
                            <div className={"border rounded-md p-5 pointer " + (category === "datatopia" ? "border-contrast-purple bg-contrast-purple text-white" : "border-purple text-purple")} onClick={() => handleCategory("datatopia")}>Datatopia</div>
                        </div>
                    }

                    <ListGroup variant="flush">
                        {
                            companies?.filter(el => el.type === category).filter(el => _.lowerCase(el.name).includes(_.lowerCase(searchWord))).map(company => (
                                <ListGroup.Item key={company.id}>
                                    <Row>
                                        <Col>
                                            {company.name}
                                        </Col>
                                        <Col>
                                            <div className={"text-right"}>
                                                <Button className={"ebloom-btn-purple"} size="sm" onClick={() => {handleChange(company)}}>{_.upperFirst(t("core.select"))}</Button>
                                            </div>
                                        </Col>

                                    </Row>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>

                </Modal.Body>
            </Modal>
        </>
    )

}

export default CompanySwitcher;