import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import FilterModel from "../../../../../models/Filter";
import ModelForms from "../../../../Core/Modules/Input/ModelForms";
import _ from "lodash";
import HttpApi from "../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import EbloomJumbotron from "../../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";
import DetailView from "../../../../Core/Modules/Views-Tables-Lists/DetailView";
import {getUrl} from "../../../../../Utils/Global";

const FilterEditor = (props) => {

    //state
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [tablePage, setTablePage] = useState(0);
    const [showDetail, setShowDetail] = useState(false);

    const [filters, setFilters] = useState([]);

    const [filter, setFilter] = useState({id:null, name:"", id_company_filter:-1, company_filter:"", users:[], created_at:null});

    const {companyToEdit} = props;

    //hooks
    const t = useTranslation();

    //getters
    const fetchFilters = () => {
        HttpApi.get(getUrl(FilterModel.crud.getAll, companyToEdit)).then(response => {
            if(response && response.data){
                setFilters(response.data);
            }else{
                setFilters([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    //posters
    const saveFilter = () => {
        const url = filter.id ? FilterModel.crud.update : FilterModel.crud.create;
        let data = filter;
        if(props.companyToEdit){
            data.id_company = props.companyToEdit.id;
        }
        if(data.id_company_filter === 0){
            data.id_company_filter = null;
        }
        HttpApi.post(url, data).then(() => {
            notifySuccess("Filter successfully created");
            setSideFormOpen(false);
            if(data.id_company_filter === null){
                FilterModel.initializeOptions(true);
            }
            resetFilter();
            fetchFilters();
        }).catch(error => {
            notifyError(error);
        })
    }

    //handlers
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFilter(prevState => {
            let filter = {...prevState};
            filter[name] = value;
            return filter;
        })
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleRowClick = (item) => event => {
        event.preventDefault();
        setFilter(item);
        setShowDetail(true);
    }

    const handleCloseDetail = () => {
        setShowDetail(false);
        resetFilter();
    }

    const resetFilter = () => {
        setFilter({id:null, name:"", id_company_filter:-1, company_filter:"", users:[], created_at:null});
    }

    const toggleDrawer = (open, toEdit=null) => event => {
        event.preventDefault();
        if(toEdit && toEdit.id && open){
            let filterToEdit = _.cloneDeep(toEdit);
            filterToEdit.company_filter = "";
            setFilter(filterToEdit);
        }else if(filter && !open){
            resetFilter();
        }
        setSideFormOpen(open);
    }

    //useEffect

    useEffect(() => {
        fetchFilters();
    }, [])

    return (
        <>
            <CardDefault title={"Filters"} handleRightButton={toggleDrawer(true)}>
                {
                    !filters || filters.length <= 0 ?
                        <EbloomJumbotron title={"Filters"} text={"Create your first filter to get event more information!"} buttonText={_.upperFirst(t("core.create"))} handleAction={toggleDrawer(true)} />
                        :
                        <TableViewV2 model={FilterModel} items={filters} tablePage={tablePage} handleTablePage={handleTablePage} handleEdit={toggleDrawer} showDetail handleView={handleRowClick} handleObject textDetail={"Details"}/>

                }
            </CardDefault>
            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} formTitle={(!filter || !filter.id ? _.upperFirst(t("core.createParam", {param:"filter"})) : _.upperFirst(t("core.editParam", {param:"filter"})))} handleSave={saveFilter}>
                <ModelForms model={FilterModel} handleChange={handleChange} values={filter} companyToEdit={props.companyToEdit}/>
            </SideForm>
            <DetailView model={FilterModel} item={filter} show={showDetail} handleClose={handleCloseDetail}/>
        </>
    )
}

FilterEditor.propTypes = {
    companyToEdit:PropTypes.object
}

FilterEditor.defaultProps = {
    companyToEdit:null,
}
export default FilterEditor