import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card} from "react-bootstrap";
import {ReactComponent as PollIcon} from "../../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as IdeaboxIcon} from "../../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as MessageIcon} from "../../../../../Core/symbols/webloom/white/message-white.svg";
import {ReactComponent as HideIcon} from "../../../../../Core/symbols/webloom/white/hide-white.svg";
import {ReactComponent as ShowIcon} from "../../../../../Core/symbols/webloom/white/show-white.svg";
import {ReactComponent as PdfIcon} from "../../../../../Core/symbols/webloom/white/pdf-white.svg";
import {ReactComponent as XlsIcon} from "../../../../../Core/symbols/webloom/white/xls-white.svg";
import {ReactComponent as PieIcon} from "../../../../../Core/symbols/webloom/pie.svg";
import {ReactComponent as AlertIcon} from "../../../../../Core/symbols/webloom/white/finguer-up-white.svg";
import IconButton from "@mui/material/IconButton/IconButton";
import {ReactComponent as CloseIcon} from "../../../../../Core/symbols/webloom/white/close-white.svg";
import {SurveyStatus, SurveyTypes} from "./Types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {useNavigationTo} from "../../../../../../hooks/navigationHook";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import EbloomSwitch from "../../../../../Core/Modules/Button/EbloomSwitch";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import EbloomSelectV2 from "../../../../../Core/Modules/Input/EbloomSelectV2";
import HttpApi, {Sources} from "../../../../../../httpApi";
import {useUserData} from "../../../../../../hooks/userHook";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {Divider} from "@mui/material";
import {GlobalContext} from "../../../../../../Utils/Context/GlobalContext";
import ProspectModal from "../../../../../Core/Modules/Modal/ProspectModal";
import {useParams} from "react-router-dom";
import {notifyError} from "../../../../../../Utils/Notification";

const PollFrame = (props) => {

    //state
    const [showModalPdf, setShowModalPdf] = useState(false);
    const [teams, setTeams] = useState([]);
    const [showModal, setShowModal] = useState(false);

    //props
    const {children,survey, status, type, isPreview, handlePreview, handleReset, isSaved, showGraph, handleShowGraph, handleExportExcel, handleExportPdf, loadingExcel, loadingPdf, anonymityRestriction, handleResultView, isIndividualView, loadingIndividualResults, noData, hideReset, handleTeamToDisplay, teamToDisplay,entity,isGroup} = props;

    //hooks
    const navigation = useNavigationTo();
    const t = useTranslation();
    const user = useUserData();
    const {id} = useParams();

    const isProspect = user.module === "prospect"

    //handler
    const handleBack = () => {
        if(id && window.location.pathname.includes("/admin/webloom/alert/result/" + id.toString())){
            navigation.to("/admin/webloom")
        }else if(id && window.location.pathname === "/webloom/alert/answer/" + id.toString()){
            navigation.to("/webloom/dashboard");
        }else{
            navigation.to(-1);
        }

    }




    const fetchTeamsOrEntities = () => {
        if(status === SurveyStatus.DONE && ![SurveyTypes.MESSAGE,SurveyTypes.ALERT].includes(type) ){
            let url
            if(entity !== -1 || isGroup){
                if(isGroup){
                    url = "/companies/findAllEntitiesFromGroup/"+entity
                }else{
                    url = "/teams/getValid/"+ entity
                }
            }else{
               url = user.roles.includes("global_backoffice") || user.roles.includes("global_admin") ? "/teams/getValid" : "/teams/getUserAdvancedTeams";
            }
            if(url){
                HttpApi.getV2(url,Sources.ENTITIES_WEBLOOM).then(response => {
                    if(response && response.data){
                        let listTeam = response.data
                        if(isProspect){
                            listTeam = listTeam.map((el,index) => {
                                if(el.id !== 0 ){
                                    el.name = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index + 'A'.charCodeAt(0))
                                    return el
                                }
                                return el
                            })
                        }
                        setTeams(listTeam);
                    }else{
                        setTeams([])
                    }
                }).catch(error => {
                    notifyError(error)
                })
            }
        }
    }

    useEffect(() => {
        if(status !== null && entity !== null && isGroup !== null){
            fetchTeamsOrEntities()
        }
    }, [status,entity,isGroup]);

    return(
        <div>
            <Card className={status === SurveyStatus.DRAFT ? "border-1 border-contrast-blue mb-20" : status === SurveyStatus.RUNNING ? "border-1 border-purple mb-20" : status === SurveyStatus.DONE ? "border-1 border-contrast-purple mb-20" : "border-1 border-contrast-blue mb-20"}>
                <Card.Header className={status === SurveyStatus.DRAFT ? "bg-contrast-blue text-white grid grid-cols-3 grid-rows-1 items-center" : status === SurveyStatus.RUNNING ? "bg-purple text-white grid grid-cols-2 grid-rows-1 items-center" : status === SurveyStatus.DONE ? "bg-contrast-purple text-white grid grid-cols-3 grid-rows-1 items-center" : "bg-contrast-blue text-white grid-cols-3 items-center"}>
                    <div className={"flex"}>{type === SurveyTypes.POLL ? <PollIcon className={"w-51 h-51 mr-10"}/> : type === SurveyTypes.IDEABOX ? <IdeaboxIcon className={"w-51 h-51 mr-10"}/> : type === SurveyTypes.MESSAGE ? <MessageIcon className={"w-51 h-51 mr-10"}/> : <AlertIcon className={"w-51 h-51 mr-10"}/>}<span className={"ebloom-normal-title font-semibold"}>{_.upperFirst(t("webloom."+type))}</span></div>
                    {
                        status === SurveyStatus.DRAFT ?
                            <div className={"flex justify-between bg-white/[0.15] p-15 rounded-lg w-auto"}>
                                <div className={"font-semibold mr-15"}>{isPreview ? _.toUpper(t("webloom.preview")) : (isSaved || isProspect) ? _.toUpper(t("webloom.saved")) : _.toUpper(t("webloom.editing")) + "..."}</div>
                                {
                                    false === hideReset &&
                                    <div className={"underline ml-10 mr-10 pointer"} onClick={isProspect? () => setShowModal(true) : handleReset}>{_.upperFirst(t("webloom.reset"))}</div>

                                }
                                <div className={"flex ml-10 mr-10 pointer"}>{isPreview ? <HideIcon className={"w-26 h-26 mr-5"}/> : <ShowIcon className={"w-26 h-26 mr-5"}/>}<span className={"underline"} onClick={handlePreview}>{isPreview ? _.upperFirst(t("core.edit")) : _.upperFirst(t("webloom.preview"))}</span></div>
                            </div>
                            :
                            (status === SurveyStatus.DONE && !anonymityRestriction) ?
                                <div className={"justify-self-center flex justify-between bg-white/[0.15] p-15 rounded-lg w-auto items-center"}>
                                    <div className={"font-semibold cursor-default " + ( ![SurveyTypes.MESSAGE,SurveyTypes.ALERT].includes(type) ? "mr-15" : "" ) }>{_.toUpper(t("webloom.results"))}</div>
                                    {
                                        (type !== SurveyTypes.MESSAGE && type !== SurveyTypes.ALERT) &&
                                        <div className={"font-semibold mr-5 cursor-default w-fit"}>{isGroup ? _.upperFirst(t("core.entity")) :_.upperFirst(t("admin.team"))}</div>

                                    }
                                    {
                                        (type !== SurveyTypes.MESSAGE && type !== SurveyTypes.ALERT) &&
                                        <EbloomSelectV2 onChange={handleTeamToDisplay} value={teamToDisplay} name={"team"}>
                                            <MenuItem key={-1} value={0}>{_.upperFirst(t("core.all"))}</MenuItem>
                                            {
                                                teams?.map((team, index) => (
                                                    <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                                                ))
                                            }
                                        </EbloomSelectV2>
                                    }
                                    {
                                        handleShowGraph &&
                                        <IconButton size={"small"} className={"mr-10 ml-10 bg-bg-white"} onClick={handleShowGraph}>
                                            <PieIcon className={"w-25 h-25" + (showGraph ? " purple-icon" : " border-purple-icon")}/>
                                        </IconButton>
                                    }
                                    {
                                        type === SurveyTypes.POLL &&
                                        <div className={"mr-10 ml-10"}>
                                            {
                                                loadingIndividualResults ?
                                                    <LoadingView size={16} color={"white"}/>
                                                    :
                                                    <EbloomSwitch handleChange={handleResultView} selected={isIndividualView}/>
                                            }
                                        </div>
                                    }
                                    {
                                        (type !== SurveyTypes.MESSAGE && type !== SurveyTypes.ALERT) &&
                                        <Divider orientation="vertical" className={"bg-bg-white ml-10 mr-10 h-40"}/>
                                    }
                                    {
                                        (type !== SurveyTypes.MESSAGE && type !== SurveyTypes.ALERT) &&
                                        <div className={"flex underline ml-10 mr-10 pointer " + (isProspect && "text-disabled-grey")} onClick={isProspect ? () => setShowModal(true) : handleExportExcel}>{loadingExcel ? <LoadingView color={"white"} size={16}/> : <div className={"flex"}><XlsIcon className={"mr-10 " + (isProspect && "grey-icon")}/><span>{"Xls"}</span></div>}</div>
                                    }
                                    {
                                        type === SurveyTypes.POLL &&
                                        <div className={"flex underline ml-10 mr-10 pointer "+ (isProspect && "text-disabled-grey")} onClick={isProspect ? () => setShowModal(true) : () => setShowModalPdf(true)}>{loadingPdf ? <LoadingView color={"white"} size={16}/> : <div className={"flex"}><PdfIcon className={"mr-10 "+(isProspect && "grey-icon")}/><span>{"Pdf"}</span></div>}</div>
                                    }
                                </div>
                                : (status === SurveyStatus.DONE && (anonymityRestriction || noData)) &&
                                <div className={"justify-self-center flex justify-between bg-white/[0.15] p-15 rounded-lg w-auto items-center"}>
                                    <div className={"font-semibold mr-15 cursor-default"}>{_.toUpper(t("webloom.results"))}</div>
                                    <div className={"font-semibold mr-5 cursor-default w-fit"}>{isGroup ? _.upperFirst(t("core.entity")) :_.upperFirst(t("admin.team"))}</div>
                                    <EbloomSelectV2 onChange={handleTeamToDisplay} value={teamToDisplay} name={"team"}>
                                        <MenuItem key={-1} value={0}>{_.upperFirst(t("core.all"))}</MenuItem>
                                        {
                                            teams?.map((team, index) => (
                                                <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                                            ))
                                        }
                                    </EbloomSelectV2>
                                    <div className={"ml-5"}>{_.toUpper(t(noData ? "webloom.noAnswer" : "manager.dashboard.anonymousRestrictionMedium"))}</div>

                                </div>
                    }
                    <IconButton className={"justify-self-end"} size={"small"} onClick={handleBack}><CloseIcon className={"w-30 h-30 pointer"}/></IconButton>
                </Card.Header>
                <Card.Body>
                    {children}
                </Card.Body>
            </Card>
            <ConfirmModal handleClose={() => setShowModalPdf(false)} show={showModalPdf} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t(isIndividualView ? "webloom.modalIndividualPdfTitle" : "webloom.modalGlobalPdfTitle"))} handleChange={() => {setShowModalPdf(false); handleExportPdf()}} handleLeftButton={() => setShowModalPdf(false)} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>{_.upperFirst(t(isIndividualView ? "webloom.modalIndividualPdfBody" : "webloom.modalGlobalPdfBody"))}</p>
            </ConfirmModal>
            <ProspectModal show={showModal} handleClose={() => setShowModal(false)}/>
        </div>

    )
}

PollFrame.propTypes = {
    survey: PropTypes.object,
    status: PropTypes.number,
    type: PropTypes.string,
    isSaved:PropTypes.bool,
    isPreview: PropTypes.bool,
    handlePreview: PropTypes.func,
    handleReset: PropTypes.func,
    handleShowGraph: PropTypes.func,
    anonymityRestriction:PropTypes.bool,
    noData:PropTypes.bool,
    handleResultView:PropTypes.func,
    loadingPdf:PropTypes.bool,
    loadingExcel:PropTypes.bool,
    handleExportExcel:PropTypes.func,
    handleExportPdf:PropTypes.func,
    hideReset:PropTypes.bool,
    isGroup:PropTypes.bool,
    handleTeamToDisplay:PropTypes.func,
    teamToDisplay:PropTypes.number,
    entity:PropTypes.number
}

PollFrame.defaultProps = {
    survey : null,
    type:SurveyTypes.POLL,
    hideReset:false,
    teamToDisplay:0,
    isGroup : false,
    handleTeamToDisplay:() => {}
}

export default PollFrame;