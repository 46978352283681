import React, {useState, useEffect} from "react";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import {PurpleSwitch} from "../../../../../Utils/Global";
import {SurveyStatus, SurveyTypes, SurveyUsersStatus} from "../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import PollCard from "./Modules/PollCard";
import IdeaboxCard from "./Modules/IdeaboxCard";
import MessageCard from "./Modules/MessageCard";
import _ from "lodash";
import {MenuItem} from "@mui/material";
import EbloomSelectV2 from "../../../../Core/Modules/Input/EbloomSelectV2";
import ProspectModal from "../../../../Core/Modules/Modal/ProspectModal";
import {useUserData} from "../../../../../hooks/userHook";
import AlertCard from "./Modules/AlertCard";

const WeBloomAnswerDashboard = (props) => {

    //state
    const [surveys, setSurveys] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [typeFilter, setTypeFilter] = useState("All types");
    const [orderBy, setOrderBy] = useState("start_date");
    const [showModal, setShowModal] = useState(false);

    const {isMobile} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData()

    const isProspect = userData.module === "prospect"

    //context

    //getters
    const fetchSurveys = () => {
        HttpApi.get("/collaborative/getAllForUsers").then(response => {
            if(response && response.data){
                setSurveys(response.data);
                HttpApi.post("/collaborative/updateUserSurveyUnreadStatus", {}).then().catch(error => notifyError(error));

            }else{
                setSurveys([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    //posters

    //handlers
    const handleTypeFilter = (event) => {
        setTypeFilter(event.target.value);
    }

    const sortWeBloom = (a,b) => {
        if(!orderBy || !["poll", "ideabox", "message", "alert"].includes(orderBy)){
            if(a.start_date<b.start_date){
                return 1;
            }else if(a.start_date>b.start_date){
                return -1;
            }
            return 0;
        }else{
            if(a.type === orderBy && b.type !== orderBy){
                return -1;
            }else if(b.type === orderBy && a.type !== orderBy){
                return 1;
            }
            return 0;
        }
    }

    //useEffect

    useEffect(() => {
        fetchSurveys()
    }, [])

    useEffect(() => {
        if(surveys && surveys.filter(el => (el.lastAnswer !== null && el.type !== SurveyTypes.IDEABOX && el.type !== SurveyTypes.ALERT) || el.status === SurveyStatus.DONE).length === surveys.length){
            setShowArchived(true);
        }else{
            setShowArchived(false);
        }
        const url = new URL(window.location.href);
        const order = url.searchParams.get("orderBy");
        if(order && ["poll", "ideabox", "message"].includes(order)){
            setOrderBy(order);
        }

    }, [surveys])

    return (
        <>
            <div className={(isMobile ? "w-full px-25" : "w-2/3 px-51") + " m-auto bg-bg-white border border-contrast-blue rounded-lg py-15"}>
                <div className={"flex justify-between"}>
                    {
                        (surveys && surveys.length > 0) &&
                        <EbloomSelectV2 value={typeFilter} onChange={handleTypeFilter}>
                            <MenuItem key={1} value={"All types"}>{_.upperFirst(t("core.allBisParam", {param:t("webloom.types")}))}</MenuItem>
                            <MenuItem key={2} value={SurveyTypes.POLL}>{_.upperFirst(t("webloom.poll"))}</MenuItem>
                            <MenuItem key={3} value={SurveyTypes.IDEABOX}>{_.upperFirst(t("webloom.ideabox"))}</MenuItem>
                            <MenuItem key={4} value={SurveyTypes.MESSAGE}>{_.upperFirst(t("webloom.message"))}</MenuItem>
                            {
                                surveys?.find(el => el.type === SurveyTypes.ALERT) !== undefined &&
                                <MenuItem key={5} value={SurveyTypes.ALERT}>{_.upperFirst(t("webloom.alert"))}</MenuItem>
                            }
                        </EbloomSelectV2>
                    }
                    {
                        (surveys && surveys.filter(el => (el.status === SurveyStatus.RUNNING && el.lastAnswer === null && el.type !== SurveyTypes.MESSAGE) || ( el.type === SurveyTypes.MESSAGE && el.status === SurveyStatus.RUNNING && el.userSurveyStatus !== SurveyUsersStatus.ARCHIVED) ).length !== surveys.length) &&
                        <div className={"flex justify-end items-center mb-20"}>
                            <PurpleSwitch checked={showArchived} onChange={() => {setShowArchived(!showArchived)}}/><span className={"text-contrast-purple"}>{_.upperFirst(t("core.showParam", {param:t("webloom.archived")}))}</span>
                        </div>
                    }
                </div>
                {
                    surveys?.filter(el =>el.status !== (showArchived ? null : SurveyStatus.DONE) && (showArchived ? true : ((el.lastAnswer === null && el.userSurveyStatus !== SurveyUsersStatus.ARCHIVED) || el.type === SurveyTypes.IDEABOX || el.type === SurveyTypes.ALERT))).filter(el => el.type === typeFilter || typeFilter === "All types").sort(sortWeBloom).map((survey, index) => (
                        <div className={"mt-10 mb-10"} key={index}>
                            {
                                survey.type === SurveyTypes.POLL ?
                                    <PollCard isProspect={isProspect} setShowModal={setShowModal} survey={survey}/>
                                    :
                                    survey.type === SurveyTypes.IDEABOX ?
                                        <IdeaboxCard isProspect={isProspect} setShowModal={setShowModal}  survey={survey}/>
                                        :
                                        survey.type === SurveyTypes.ALERT ?
                                            <AlertCard isProspect={isProspect} setShowModal={setShowModal} survey={survey}/>
                                            :
                                        <MessageCard isProspect={isProspect} setShowModal={setShowModal}  survey={survey} fetchSurveys={fetchSurveys}/>
                            }

                        </div>
                    ))
                }
            </div>
            <ProspectModal show={showModal} handleClose={() => setShowModal(false)}/>
        </>
    )
}

WeBloomAnswerDashboard.propTypes = {}

WeBloomAnswerDashboard.defaultProps = {}
export default WeBloomAnswerDashboard