import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import {getTranslations} from "../../../../lang/translations";
import {selectIcon} from "../../../Admin/Pages/Categories/Assets/CategoriesAssets";
import _ from "lodash";

const CategoryTag = (props) => {

    const {category, width, inline, showIcon, lang,modelCustomEnable} = props;

    const t = useTranslation();

    const name = () => {
        if(modelCustomEnable){
            const words = category.name.split(' ');
            const availableSize = width - (showIcon ? 33 : 8); //remove size of the icon + some margin

            let nbPossibleLettersWords = Math.floor(availableSize/7);
            let lettersInWords = category.name.length;
            if(lettersInWords<= nbPossibleLettersWords){
                return category.name;
            }else{
                let nbPossibleLettersPerWord =  nbPossibleLettersWords/words.length;
                let smallerWords = words.filter(el => el.length<nbPossibleLettersPerWord)
                let largerWords = words.filter(el => el.length>nbPossibleLettersPerWord).map(el => el = {name:el, extraLetters:el.length-nbPossibleLettersPerWord});
                let goodWords = words.filter(el => el.length === nbPossibleLettersPerWord);
                let nbExtraLetters = smallerWords.map(el => (nbPossibleLettersPerWord-el.length)).reduce((partialSum,a) => partialSum + a, 0)
                while(nbExtraLetters > 0 && largerWords.find(el => el.extraLetters > 0) !== undefined){
                    for(let i=0;i<largerWords.length;i++){
                        if(largerWords[i].extraLetters > 0 && nbExtraLetters>0){
                            largerWords[i].extraLetters--;
                            nbExtraLetters--;
                        }
                    }
                }

                let newWord = "";
                for(let i =0;i<words.length;i++){
                    let word = words[i];
                    if(smallerWords.includes(word) || goodWords.includes(word)){
                        newWord += word;
                    }else if(largerWords.find(el => el.name === word) !== undefined){
                        const index = word.length - largerWords.find(el => el.name === word).extraLetters - 1;
                        newWord += word.slice(0, index);
                        if(index>0){
                            newWord += ".";
                        }
                    }
                    if(i<words.length-1){
                        newWord += " ";
                    }
                }
                return newWord;
            }
        }else{
            if((width < 200 && category.name === "Management & Leadership") || width <= 150){
                if(width <= 100){
                    return lang ? getTranslations(lang, "core.category.initials." + category.name) : t("core.category.initials." + category.name)
                }
                return lang ? getTranslations(lang, "core.category.small." + category.name) : t("core.category.small." + category.name)
            }
            return lang ? getTranslations(lang, "core.category." + category.name) : t("core.category." + category.name);
        }

    }

    return(
        <div className={"flex rounded-md items-center"} style={{width:width, backgroundColor : category.color}}>
            {
                showIcon &&
                <div className={"pr-0 pl-5 pt-5 pb-5"}>
                    <div className={"h-16 w-16"}>
                        {selectIcon(category.icon)}
                    </div>
                </div>
            }

            <div className={"ebloom-text ebloom-small-text pl-0 pr-0 m-auto max-h-25 whitespace-nowrap"}>
                {name()}
            </div>
        </div>
    )
}

CategoryTag.propTypes = {
    category:PropTypes.object.isRequired,
    width:PropTypes.number,
    showIcon:PropTypes.bool,
    modelCustomEnable:PropTypes.bool,
    lang:PropTypes.string,
}

CategoryTag.defaultProps = {
    width:240,
    inline:false,
    showIcon:true,
    modelCustomEnable : false
}

export default CategoryTag;