import React, {useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import SettingModel from "../../../../../models/Setting";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {notifyError} from "../../../../../Utils/Notification";
import HttpApi from "../../../../../httpApi";
import {SettingTypes, PurpleSwitch} from "../../../../../Utils/Global";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";

const Setting = (props) => {

    const [settings, setSettings] = useState({});

    const {companyToEdit} = props;

    const t = useTranslation();

    const cloneSettings = _.cloneDeep(SettingModel);
    cloneSettings.initializeOptions()
    delete cloneSettings.initializeOptions;
    const handleSave = (type) => event => {
        event.preventDefault();
        let url = settings[type] && settings[type].id ? '/settings/update/' + type : '/settings/create';
        const value = cloneSettings[type].value.form.switch ? event.target.checked === true ? 1 : 0 : event.target.value;
        const data = settings[type] && settings[type].id ? {type:type, value:Number(value), id: settings[type].id} : {type:type, value:Number(value), id_company:props.companyToEdit.id};
        const HttpCall = settings[type] && settings[type].id ? HttpApi.put : HttpApi.postV2
        HttpCall(url, data).then(response => {
            if(response && response.data){
                fetchSetting(type);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchSetting = (type) => {

        HttpApi.getV2("/settings/getForCompany/" + type).then(response => {
            if(response && response.data){
                setSettings(prevState => {
                    let settings = {...prevState};
                    settings[type] = response.data;
                    return settings;
                })
            }else{
                setSettings(prevState => {
                    let settings = {...prevState};
                    settings[type] = {id:null, type:type, value:0};
                    return settings;
                })
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {

        Object.values(SettingTypes).forEach(type => {
            fetchSetting(type);
        })
    }, [companyToEdit]);

    return(
        <CardDefault title={_.upperFirst(t("admin.settings"))} showButton={false}>
            <div className={"grid grid-cols-3"}>
            {
                Object.values(cloneSettings).map((setting, index) => (
                    <div key={index} className={"flex justify-between items-center my-5"}>
                        <div className={"text-xl text-contrast-purple ebloom-text"}>
                            {_.upperFirst(t("backoffice." + setting.type.label))}:
                        </div>
                        <div className={"w-fit mr-20"}>
                            {
                                setting.value.form.select ?
                                    <TextFieldCustom fullWidth={false} size={"small"} model={setting.value} value={settings[setting.type.label] ? settings[setting.type.label].value : 0} handleChange={handleSave(setting.type.label)}>
                                        {
                                            setting.value.form.selectOptions.options.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </TextFieldCustom>
                                    :
                                    setting.value.form.switch ?
                                        <PurpleSwitch checked={settings[setting.type.label]?.value} onChange={handleSave(setting.type.label)}/>
                                        :
                                    <TextFieldCustom fullWidth={false} size={"small"} model={setting.value} value={settings[setting.type.label] ? settings[setting.type.label].value : 0} handleChange={handleSave(setting.type.label)}/>
                            }
                        </div>
                    </div>
                ))
            }
            </div>
        </CardDefault>
    )
}

export default Setting;