import React, { useState} from "react"
import PropTypes from "prop-types";
import {FullBlueTextField, FullContrastPurpleTextField, FullPurpleTextField} from "../../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";


const ActionList = (props) => {
    const t = useTranslation();

    const [actions] = useState([
        {id:-1, name:_.capitalize(t("core.all"))},
        {
            id : 1,
            name : "Click&Bloom"
        },
        {
            id : 2,
            name : "Poll"
        },
        {
            id : 3,
            name : "Ideabox"
        }
    ]);

    const {handleChange, value, color, name, size, label,id_cat, id_tag} = props;



    return(
        color === "purple-contrast" ?
            <FullContrastPurpleTextField select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                {
                    actions && actions.map((action, index) => (
                        <MenuItem key={index} value={action.id}>
                            <span>{action.name}</span>
                        </MenuItem>
                    ))
                }
            </FullContrastPurpleTextField>
            :
            color === "blue" ?
                <FullBlueTextField select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        actions && actions.map((action, index) => (
                            <MenuItem key={index} value={action.id}>
                                <span>{action.name}</span>
                            </MenuItem>
                        ))

                    }
                </FullBlueTextField>
                :
                <FullPurpleTextField select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        actions && actions.map((action, index) => (
                            <MenuItem disabled={action.id === 2 && (id_tag >=0 || id_cat >=0)} key={index} value={action.id}>
                                <span>{action.name}</span>
                            </MenuItem>
                        ))

                    }
                </FullPurpleTextField>
    )
}

ActionList.propTypes ={
    categories:PropTypes.array,
    id_action:PropTypes.number,
    handleChange:PropTypes.func.isRequired,
    value:PropTypes.number.isRequired,
    color:PropTypes.string,
    name:PropTypes.string,
    size:PropTypes.string,
    label:PropTypes.string.isRequired,
    id_tag:PropTypes.number,
    id_cat:PropTypes.number,
}

ActionList.defaultProps = {
    color:"purple-contrast",
    name:"action-filter",
    size:"small",
}

export default ActionList;