import {Store} from 'react-notifications-component';
import _ from "lodash";
import Login from "../login/login";
import {getLanguage} from "react-multi-lang";
import {getTranslations} from "../lang/translations";


export const notifyError = (error) => {
    const lang = getLanguage();
    if(error && error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)){
        Login.logout();
    } else if(error && error.response && error.response.data && (error.response.data.msg || error.response.data.error?.msg)){
        console.log(error.response.data);
        const msg =error.response.data.error?.msg ||  error.response.data.msg;
        createErrorNotification(_.upperFirst(getTranslations(lang, "core.notification.error")), msg);
    }else if(error && error.message && error.message === "axios request canceled"){
        //console.log(error.message);
    }else{
        console.log(error);
        createErrorNotification(_.upperFirst(getTranslations(lang,"core.notification.error")), _.upperFirst(getTranslations(lang,"core.notification.errorGeneric")));
    }
};

export const notifyCustomError = (msg) => {
    const lang = getLanguage();
    createErrorNotification(_.upperFirst(getTranslations(lang, "core.notification.error")), msg );
}

export const notifySuccess = (msg, duration=4000) => {
    const lang = getLanguage()
    //const title = lang === "fr" ? "Succès !" : lang === "nl" ? "Succes!" : "Success!"
    const title = _.upperFirst(getTranslations(lang,"core.notification.success"));
    createSuccessNotification(title, msg, duration);
};

export const notifyInfo = (msg, infinite=false) => {
    if(infinite){
        createInfiniteInfoNotification("Info", msg);
    }else{
        createInfoNotification("Info", msg);
    }
};

export const createSuccessfullyCreatedNotification = (item, duration=4000) => {
    createSuccessNotification("Success!", _.capitalize(item) + " successfully created", duration);
};

export const createSuccessfullyUpdatedNotification = (item, duration=4000) => {
    createSuccessNotification("Success!", _.capitalize(item) + " successfully updated", duration);
};

export const createSuccessfullyDeletedNotification = (item, duration=4000) => {
    createSuccessNotification("Success!", _.capitalize(item) + " successfully deleted", duration);
};

export const createErrorCreateNotification = (item, duration=4000) => {
    createErrorNotification("Error!", "An error occurred while creating the " + _.capitalize(item), duration);
};

export const createErrorUpdateNotification = (item, duration=4000) => {
    createErrorNotification("Error!", "An error occurred while updating the " + _.capitalize(item), duration);
};

export const createErrorDeleteNotification = (item, duration=4000) => {
    createErrorNotification("Error!", "An error occurred while deleting the " + _.capitalize(item), duration);
};

export const createErrorRecoveringNotification = (item, duration=4000) => {
    createErrorNotification("Error!", "An error occured while recovering the " + _.capitalize(item), duration);
};

export const createSuccessNotification = (title, message, duration=4000) => {
    Store.addNotification({
        title: title,
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: false,
            showIcon: (duration === 0)
        }
    });
};

export const createErrorNotification = (title, message, duration=4000) => {
    Store.addNotification({
        title: title,
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: false
        }
    });
};

export const createWarningNotification = (title, message, duration=4000) => {
    Store.addNotification({
        title: title,
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: false
        }
    });
};

export const createInfoNotification = (title, message, duration=4000) => {
    Store.addNotification({
        title: title,
        message: message,
        type: "info",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: false,
            showIcon:false,
        }
    });
};

export const createInfiniteInfoNotification = (title, message, duration=0) => {
    Store.addNotification({
        title: title,
        message: message,
        type: "info",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ['animated', "fadeOut"],
        dismiss:{
            duration:duration,
            onScreen:false,
            showIcon:true,
        }

    });
};

