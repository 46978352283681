import React, {useState, useEffect} from "react";
import HttpApi from "../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import ModuleModel from "../../../../../models/Module";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {Col, Row} from "react-bootstrap";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";

const Module = (props) => {
    const [modules, setModules] = useState([]);
    const [module, setModule] = useState({id:null, code:"", name:""});
    const [page, setPage] = useState(0);
    const [sideFormOpen, setSideFormOpen] = useState(false);

    const t = useTranslation();

    const fetchModules = () => {
        HttpApi.getV2("/modules/getAll").then(response => {
            if(response && response.data){
                setModules(response.data);
            }else{
                setModules([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handlePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setModule(prevState => {
            let module = {...prevState};
            module[name] = value;
            return module;
        })
    }

    const handleDelete = () => {
        fetchModules();
    }

    const handleSave = () => {
        const url = module.id ? "/modules/update" : "/modules/create";
        const HttpCall = module.id ? HttpApi.put : HttpApi.postV2;
        HttpCall(url, module).then(() => {
            notifySuccess("Module successfully saved");
            fetchModules();
            setSideFormOpen(false);
            setModule({id:null, name:"", code:""});
        }).catch(error => {
            notifyError(error);
        })
    }

    const toggleDrawer = (open, toEdit) => event => {
        if(toEdit){
            setModule(toEdit);
        }else{
            setModule({id:null, name:"", code:""})
        }

        setSideFormOpen(open);
    }

    useEffect(() => {
        fetchModules();
    }, []);

    return(
        <>
            <CardDefault title={"Modules"} handleRightButton={toggleDrawer(true)}>
                <TableViewV2 items={modules} model={ModuleModel} tablePage={page} handleTablePage={handlePage} handleEdit={toggleDrawer} handleDelete={handleDelete}/>
            </CardDefault>
            <SideForm sideFormOpen={sideFormOpen} formTitle={(module && module.id) ? _.upperFirst(t("core.editParam", {param:"module"})) : _.upperFirst(t("core.createParam", {param:"module"}))} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <Row>
                    <Col>
                        <TextFieldCustom value={module.name} model={ModuleModel.name} handleChange={handleChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFieldCustom value={module.code} model={ModuleModel.code} handleChange={handleChange} readOnly={module.id !== null}/>
                    </Col>
                </Row>
            </SideForm>
        </>
    )
}

export default Module;
