import _ from "lodash";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import {Row} from "react-bootstrap";
import SimpleRadarChart from "../../../../Core/Modules/Chart/SimpleRadarChart";
import React from "react";
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import {useTranslation} from "react-multi-lang";
import {useUserData} from "../../../../../hooks/userHook";
import Avatar from "../Avatar/Avatar";
import {ReactComponent as OrgaIcon} from "../../../../Core/symbols/myBloom/icons/Myorganisation.svg";
import {PaletteColors} from "../Avatar/AvatarAssets";
import PropTypes from "prop-types";

const MyOrganisation = (props) => {
    const {loadingRadar, radarData, avatar,isModelCustomEnable} = props
    const bp = useBreakpoints();
    const t = useTranslation();
    const userData = useUserData()
    const companyStateName = userData.company

    return <div className={"w-full divide-contrast-purple divide-y px-10"}>
        <div className={"w-full flex  items-center mb-20" + (bp.isTabletLandscapeOrLess() ? " flex-col justify-center" : " justify-around flex-row px-30")}>
            <div className={"flex justify-center items-center"}>
                <div className={"grid grid-cols-2 place-items-center"}>
                    <div className={"mb-20 w-60 "}>
                        <Avatar avatar={avatar}/>
                    </div>
                    <div>
                        <h1 className={"font-bold"} style={{color: PaletteColors[avatar.color].avatar}}>{_.upperFirst(t("core.myself"))}</h1>
                    </div>
                </div>
            </div>
            <div className={"flex justify-center items-center"}>
                <div className={"grid grid-cols-2 place-items-center"}>
                    <div className={(bp.isTabletLandscapeOrLess() ? "mr-5" : "mr-15")}>
                        <OrgaIcon className={"w-35 h-35"}/>
                    </div>
                    <div>
                        <p className={"text-contrast-purple font-bold"}>
                            {companyStateName}
                        </p>
                    </div>
                </div>

            </div>

        </div>
        <div className={"w-full"}>
            {
                (false === loadingRadar && false === radarData.enoughValue) &&
                <div
                    className={"ebloom-text ebloom-xtra-large-text w-full center absolute bottom-170"}>{_.upperFirst(t("manager.dashboard.notEnoughData"))}</div>
            }
            {
                loadingRadar ?
                    <LoadingView/>
                    :
                    <Row className={"w-full"} style={{
                        opacity: radarData.enoughValue ? 1 : 0.4,
                        paddingTop: bp.isTabletLandscapeOrLess() ? 0 : 20
                    }}>
                        <SimpleRadarChart isModelCustomEnable={isModelCustomEnable} name1={_.capitalize(t("core.myself"))} name2={companyStateName}
                                          data={radarData.tableOfAvg} isDashboardManager={false}
                                          isMobile={bp.isTabletLandscapeOrLess()} t={t}
                                          colorB={PaletteColors[avatar.color].avatar}/>
                    </Row>
            }
        </div>

    </div>
}

MyOrganisation.propTypes = {
    loadingRadar : PropTypes.bool.isRequired,
    isModelCustomEnable : PropTypes.bool,
    radarData : PropTypes.any.isRequired,
    avatar : PropTypes.object.isRequired
}

MyOrganisation.defaultProps = {
    isModelCustomEnable : false
}

export default MyOrganisation