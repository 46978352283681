import React from "react"
import DisplayAdminView from "../../../../Layouts/DisplayAdminView";
import WeBloomCreateIdeabox from "./WeBloomCreateIdeabox";
import {Container} from "react-bootstrap";

const WeBloomCreateIdeaboxPage = (props) => {

    return (
        <DisplayAdminView pageName={"WeBloom"} menu={"noMenu"} selectedIndex={9} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomCreateIdeabox {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )

}

export default WeBloomCreateIdeaboxPage;