import {ModelTypes} from "./Types";

const Playlist = {
    crud:{
        create:"/playlists/create",
        update:"/playlists/update",
        delete:"/playlists/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"playlist-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
        }
    },

    id_company:{
        id:"id_company",
        type:ModelTypes.NUMBER,
        label:"core.company.company",
        table:{
            show:false,
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    company:{
        id:"company",
        type:ModelTypes.TEXT,
        label:"core.company.company",
        table:{
            show:false,
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Playlist;