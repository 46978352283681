import React, {useState} from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import Identity from "./Identity";
import {Container} from "react-bootstrap";
import {useUserData} from "../../../../hooks/userHook";
import EntitiesSwitcher from "../../../Core/Modules/Button/EntitiesSwitcher";
import {Modules} from "../../../../Utils/Global";
import IdentityStarter from "./IdentityStarter";

const IdentityPage = (props) => {
    const user = useUserData()
    const [selectedEntity, setSelectedEntity] = useState(null);
    const isStarter = user.module === Modules.HAPPY[0];
    return(
        <DisplayAdminView menu={"settings"} pageName={"core.general"} selectedIndex={17} {...props}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher entity={selectedEntity} handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true}/>
                            </div>
                        }
                        {
                            isStarter ?
                                <IdentityStarter selectedEntity={selectedEntity} {...props} />:
                                <Identity selectedEntity={selectedEntity} {...props}/>
                        }
                    </Container>
                )
            }
        </DisplayAdminView>
    )
}

export default IdentityPage;