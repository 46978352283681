import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Colors} from "../../../../Utils/Colors";
import {Badge, Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";

const IdentityQuestionModal = (props) => {
    const {show,handleClose,size,colors,light,bgColor,id_survey,listTeams} = props
    const t = useTranslation()

    const [questionsVersion, setQuestionsVersion] = useState({isForAll : true,global: "" , custom: []});

    const fetchQuestionsVersion = () => {
        let url ;
        if(listTeams.length > 0){
            url = `/questions/getQuestionIdentities/${id_survey}/${listTeams.join("-")}`
        }else{
            url = `/questions/getQuestionIdentities/${id_survey}`
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setQuestionsVersion(response.data);
            }else{
                setQuestionsVersion({isForAll : true,global: "", custom: []});
            }
        }).catch(e => {
            notifyError(e)
        })
    }


    useEffect(() => {
        if(id_survey !== null){
            fetchQuestionsVersion()
        }
    },[id_survey])

    return (
        <Modal show={show} onHide={handleClose} size={size} style={{borderColor:colors ? colors.tertiary :light ? Colors.EBLOOM_WHITE_BLUE : Colors.EBLOOM_CONTRAST_BLUE}}>
            <div data-tour={'modal'}>
                <Modal.Header closeButton style={{backgroundColor:colors ? colors.tertiary :light ? Colors.EBLOOM_WHITE_BLUE : bgColor, color:"white"}}>
                    <Modal.Title className={light ? "ebloom-normal-title" : "ebloom-xtra-large-text ebloom-text"} style={{textAlign:"center", color:light ? Colors.EBLOOM_CONTRAST_PURPLE : "white"}}>
                        {_.upperFirst(t("core.identityViewTitle"))}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"ebloom-normal-text"} style={{backgroundColor:colors ? colors.background :light ? "white" : Colors.EBLOOM_WHITE_BLUE}}>
                    <div className={"flex flex-col justify-center items-start divide-y divide-contrast-blue w-full"}>
                        {
                            questionsVersion.custom.map((question, index) => {
                                return (
                                    <div key={index} className={"mb-10 pt-10 flex justify-center items-start flex-col w-full"}>
                                        <div className={"w-full"}>
                                            {
                                                question.teams.map(team => (
                                                    <Badge pill className={"bg-contrast-blue text-white mr-5"}>
                                                        {team}
                                                    </Badge>
                                                ))
                                            }
                                        </div>
                                        <h1>{question.question}</h1>
                                    </div>
                                )
                            })
                        }
                        {
                            questionsVersion.isForAll &&
                            <div className={"mb-10 pt-10 flex justify-center items-start flex-col w-full"}>
                                <div>
                                    <Badge pill className={"bg-contrast-blue text-white"}>
                                        {_.upperFirst(t("core.otherCollaborators"))}
                                    </Badge>
                                </div>
                                <h1>{questionsVersion.global}</h1>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor: colors ? colors.background : Colors.EBLOOM_WHITE_BLUE}}>
                    <Button style={{backgroundColor: colors ? colors.secondary : null}} variant={"secondary"}
                            onClick={handleClose}>{_.upperFirst(t("core.close"))}</Button>
                </Modal.Footer>

            </div>

        </Modal>
    );
};

IdentityQuestionModal.propTypes = {
    handleClose:PropTypes.func.isRequired,
    show:PropTypes.bool.isRequired,
    id_survey:PropTypes.number.isRequired,
    size:PropTypes.string,
    light:PropTypes.bool,
    bgColor:PropTypes.string,
    colors: PropTypes.object,
    listTeams: PropTypes.array
};

IdentityQuestionModal.defaultProps = {
    size:"md",
    light:false,
    bgColor:Colors.EBLOOM_CONTRAST_BLUE,
    colors : null,
    listTeams:[]
}

export default IdentityQuestionModal;
