import React, { useEffect, useState} from 'react';
import {getLanguage, useTranslation} from "react-multi-lang";
import {FullContrastPurpleTextField, IdentityTypes, PurpleTextField} from "../../../../../Utils/Global";
import HttpApi from "../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../Utils/Notification";
import _ from "lodash";
import {Accordion, Badge, Button, Col, Row} from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {FormControl, MenuItem} from "@mui/material";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Colors} from "../../../../../Utils/Colors";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DeleteModal from "../../../../Core/Modules/Modal/DeleteModal";
import {ReactComponent as AlertIcon} from "../../../../Core/symbols/webloom/white/danger-white.svg";

const IdentityCustom = (props) => {
    const {companyToEdit,selectedEntity,langs,keyAccordion,handleAccordion,expandedKey,listIdentitiesItem,setSelectedLang,selectedLang,handleFetch,teams,handleDelete,placeholders} = props;


    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [identities, setIdentities] = useState([]);
    const [show, setShow] = useState(false);
    const [showModalSkip, setShowModalSkip] = useState(false);
    const [listIdentity, setListIdentity] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [warning, setWarning] = useState(false);

    const steps = [
        {name:"manager",
            fr:{keySentence:"Pour le moment, je me sens valorisé(e) par {mon/ma manager}", keyValue:"mon/ma manager", examples:["En ce moment, je collabore bien avec {mon/ma manager}", "En ce moment, je sens que {mon/ma manager} m'apprécie", "J'apprécie {mon/ma manager} actuel(le)"]},
            en:{keySentence:"For now I'm feeling valued by {my manager}", keyValue:"my manager", examples:["I'm currently working well with {my manager}", "I'm currently feeling that {my manager} appreciates me", "I like {my current manager}"]},
            nl:{keySentence:"Voor het moment voel ik me gewaardeerd door {mijn manager}", keyValue:"mijn manager", examples:["Op dit moment werk ik goed samen met {mijn manager}", "Tegenwoordig heb ik het gevoel dat {mijn manager} me waardeert", "Ik waardeer {mijn actuele manager}"]}},
        {name:"team",
            fr:{keySentence:"Pour le moment, je suis satisfait(e) de la manière dont les conflits sont gérés dans {mon équipe}", keyValue:"mon équipe", examples:["En ce moment, je trouve que la collaboration est bonne au sein de {mon équipe}", "Pour le moment, j'aime la dynamique qu'il y a dans {mon équipe}"]},
            en:{keySentence:"I'm currently satisfied with how conflicts are managed in {my team}", keyValue:"my team", examples:["I'm currently feeling that I'm part of {a team} in which everyone gives their best", "I currently like {my team}'s dynamic"]},
            nl:{keySentence:"Voor het moment ben ik tevreden met de manier waarop conflicten in {mijn team} worden beheerd",keyValue:"mijn team", examples:["Op dit moment voel ik dat ik deel uitmaak van {een team} waarin iedereen het beste van zichzelf geeft", "Voor het moment hou ik van de dynamiek in {mijn team}"]}},
        {name:"colleagues",
            fr:{keySentence:"Actuellement, je me sens respecté(e) par {mes collègues}", keyValue:"mes collègues", examples:["Pour le moment, {mes collègues} sont réactifs à mes communications", "Ces derniers temps, j'ai de bonnes relations avec {mes collègues}"]},
            en:{keySentence:"I'm currently feeling respected by {my colleagues}", keyValue:"my colleagues", examples:["{my colleagues} are currently reactive to my communications", "I currently have good relationships with {my colleagues}"]},
            nl:{keySentence:"Momenteel voel ik me gerespecteerd door {mijn collega's}", keyValue:"mijn collega's", examples:["De laatste tijd zijn {mijn collega's} reactief op mijn communicaties", "De laatste tijd heb ik goede relaties met {mijn collega's}"]}},
        {name:"validation"}
    ];


    const colleguesPrefix = {name:"Prefix", keySentenceStart:"La dernière fois que j'ai rencontré une difficulté, j'ai pu compter sur le soutien ", keySentenceEnd:"", keyValue:"de mes collègues", examples:["En ce moment, je me sens libre de m'exprimer ouvertement auprès {de mes collègues}", "Ces derniers temps, je reçois des commentaires positifs sur mon travail de la part {de mes collègues}"]}
    const t = useTranslation();


    const handleStep = (number) => {
        setStep(number);
    }

    const handleClickStep = (index) => {
        if(index<step){
            setStep(index);
        }else{
            if(index !==steps.length-1){
                if(emptyInputs() && !checkAllEmpty()){
                    setShowModalSkip(true);
                }else{
                    setStep(index);
                }
            }else{
                setStep(index);
            }
        }
        const keyInTheCompany = identities.filter(el => el.key_value === "dans l'entreprise")
        const toModify = keyInTheCompany[0]
        const keyTheCompany = identities.filter(el => el.key_value === "l'entreprise")
        if(keyInTheCompany.length>0 && keyTheCompany.length>0 && keyTheCompany[0].text.trim() !== ''){
            const company = keyTheCompany[0].text
            if(keyInTheCompany[0].text.replace(company,'').trim() === ''){
                setIdentities(prevState => {
                    const index = prevState.findIndex(el => el.key_value === "dans l'entreprise");
                    if(index !== -1){
                        toModify.text = "chez "+keyTheCompany[0].text;
                        prevState[index] = toModify
                    }else{
                        prevState.push({id:null, text:"chez "+keyTheCompany[0].text, lang:'fr', key_value:"dans l'entreprise",type : IdentityTypes.TEAMS})
                    }

                    return prevState
                })
            }
        }else if(keyInTheCompany.length ===0 && keyTheCompany.length>0 && keyTheCompany[0].text.trim() !== ''){
            setIdentities(prevState => {
                prevState.push({id:null, text:"chez "+keyTheCompany[0].text, lang:'fr', key_value:"dans l'entreprise",type : IdentityTypes.TEAMS})
                return prevState
            })
        }else if (keyTheCompany.length ===0 ){
            setIdentities(prevState => {
                const index = prevState.findIndex(el => el.key_value === "dans l'entreprise");
                if(index !== -1){
                    toModify.text = ''
                    prevState[index] = toModify
                }
                return prevState
            })
        }

    }

    const handleChangeListIdentity = (event) => {
        setListIdentity(prevState => {
            const el = {...prevState}
            el.title = event.target.value;
            return el
        })
    }

    const handleChange = (hasValue, lang, keyValue) => event => {
        const value = event.target.value;
        setIdentities(prevState => {
            let identities = [...prevState];
            if(hasValue){

                let i = identities.filter(el => el.key_value === keyValue)[0];
                let j = null;
                if(keyValue === "mes collègues"){
                    j = identities.filter(el => el.key_value === "de mes collègues")[0];
                }
                if((value === undefined || value === null || value.trim() === "") && !i.id){
                    identities.splice(identities.indexOf(i), 1);
                    if(j && !j.id){
                        identities.splice(identities.indexOf(j), 1);
                    }
                }else{
                    i.text = value;

                    if((value === undefined || value === null || value.trim() === "") && !!j){
                        j.text = value;
                    }
                }
            }else{
                identities.push({id:null, text:value, lang:lang, key_value:keyValue,type : IdentityTypes.TEAMS})

            }
            return identities;
        })
    }

    const handleChangeLang = (event) => {
        setSelectedLang(event.target.value);
    }

    const handleOpenModal = () => {
        if(checkListIdentity()){
            setShow(true);
        }else{
            createErrorNotification(_.upperFirst(t("admin.identity.errorSynchronizing")),_.upperFirst(t("admin.identity.errorValidationCustom")));
        }

    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleCloseModalSkip = () => {
        setShowModalSkip(false);
    }

    const handleSkip = () => {
        setIdentities(prevState => {
            let identities = [...prevState];
            const s = steps[step];
            Object.values(s).forEach((entry, index) => {
                if(index>0){
                    if(identities.filter(el => el.key_value === entry.keyValue).length > 0){
                        let i = identities.filter(el => el.key_value === entry.keyValue)[0];
                        if(!i.id){
                            identities.splice(identities.indexOf(i), 1);
                        }else{
                            i.text = "";
                        }
                    }
                }
            })
            return identities;
        })
        setStep(step+1);
    }

    const checkAllEmpty = (index=null) => {
        index = index !== null ? index  : step;
        let empty=true;
        langs.forEach(l => {
            if(identities.filter(el => el.key_value === steps[index][l].keyValue && el.text).length > 0) {
                empty = false;
            }
        })
        return empty;
    }

    const emptyInputs = (index=null) => {
        index = index !== null ? index : step;
        let empty=false;
        langs.forEach(l => {
            if(identities.filter(el => el.key_value === steps[index][l].keyValue && el.text).length <= 0){
                empty=true;
            }
        })
        return empty;
    }

    const handleSave = () => {
        if(!loading && !!listIdentity){
            if(checkListIdentity()){
                setLoading(true);
                const listIdentityItem = {...listIdentity}
                if(_.lowerCase(listIdentityItem.title).includes(_.lowerCase(t("admin.identity.customTitle")))){
                    const arrayTitle = listIdentityItem.title.split("#");

                    listIdentityItem.title = "admin.identity.customTitle#"+arrayTitle[1]
                }
                identities.filter(el => !langs.includes(el.lang)).forEach(i => {
                    i.text="";
                })
                let url = "/identities/synchronize"
                if(selectedEntity){
                    url = "/identities/synchronize/"+selectedEntity
                }
                listIdentityItem.identities = identities;
                listIdentityItem.identity_teams = selectedTeams
                HttpApi.postV2(url, listIdentityItem).then(() => {
                    handleFetch();
                    notifySuccess("Identity saved - Library synchronized");
                }).catch(error => {
                    notifyError(error);
                }).finally(() => {
                    setLoading(false);
                })
            }else{
                createErrorNotification(_.upperFirst(t("admin.identity.errorSynchronizing")),_.upperFirst(t("admin.identity.errorValidationCustom")));
            }

        }
    }

    const processIdentities = (identitiesData) => {
        const list = {...listIdentitiesItem}
        if(list.title.includes("admin.identity.customTitle")){
            const arrayTitle = list.title.split("#")
            list.title = _.upperFirst(t("admin.identity.customTitle")) + " #" + arrayTitle[1]
        }
        setListIdentity(list)
        setSelectedTeams(listIdentitiesItem.identity_teams.map(el => (el.team.id)))
        setIdentities(identitiesData)
        if(identitiesData.length > 0){
            if(identitiesData.filter(el => el.lang === getLanguage()).length > 0){
                setSelectedLang(getLanguage());
            }else{
                setSelectedLang(identitiesData[0].lang);
            }
            setStep(steps.length-1);
        }else{
            setStep(0);
        }
    }
    const checkWarning = () => {
        let empty = []
        langs.forEach(l => {
            empty.push(!identities.filter(el => el.lang === l).length > 0)
        })
        setWarning(empty.some(el => el === true ));
    }

    const calculateBackground = (index) => {
        let classes = "identity_step ";
        if(index === 0){
            classes += "identity_step_first ";
        }else if(index === steps.length-1){
            classes += "identity_step_last ";
        }
        if(index === step){
            return classes + "identity_step_selected";
        }
        if(index<steps.length-1){
            if(checkAllEmpty(index)){
                return classes + "identity_step_empty";
            }else if(emptyInputs(index)){
                return classes + "identity_step_error";
            }else{
                return classes + "identity_step_valid";
            }
        }
        return classes + "identity_step_empty";

    }

    const handleChangeMultiple = (event) => {
        let value = event.target.value;
        setSelectedTeams(value)
    }

    const checkListIdentity = () => {
        let valid = true;
        const keywordKeys = ["my manager","mon/ma manager","mijn manager","my team","mon équipe","mijn team","my colleagues","mes collègues","mijn collega's",]

        if(selectedTeams.length === 0 || listIdentity.title.trim() === "" ){
            valid = false;
        }
        if(identities.length === 0){
            valid = false;
        }
        if(identities.filter(el => keywordKeys.includes(el.key_value)).length === 0){
            valid = false;
        }
        return valid
    }

    const checkValidity = () => {
        let valid=true;
        for(let i=0;i<steps.length-1;i++){
            if(!checkAllEmpty(i)){
                if(emptyInputs(i)){
                    valid=false;
                }
            }
        }
        return valid;
    }

    const processTextIdentity = (text,lang) => {
        let newText = text
        let presentKeyword = []
        const listKeyword = identities.filter(el => el.lang === lang && !["values","valeurs","waarden"].includes(el.key_value)).map(el => el.key_value);
        for(let i=0;i<listKeyword.length;i++){
            if(text.includes(listKeyword[i])){
                presentKeyword.push(listKeyword[i])
            }
        }
        if(presentKeyword.length > 0 ){
            for(let i=0;i<presentKeyword.length;i++){
                const valueReplacement = identities.filter(el => el.key_value === presentKeyword[i])[0]?.text;
                if(valueReplacement){
                    newText = newText.replace(presentKeyword[i], valueReplacement)
                }

            }
        }
        return newText
    }

    const displayLang = () => {
        let string = "";
        langs.forEach((l, index) => {
            string += _.upperCase(l);
            if(index !== langs.length-1){
                string += ", ";
            }
        })
        return string;
    }

    useEffect(() => {
        checkWarning();
    }, [identities,langs]);

    useEffect(() => {
        processIdentities(listIdentitiesItem.identities)
    }, [companyToEdit,selectedEntity])

    return (
        <>
            {
                !!listIdentity ?
                    <Accordion.Item as={"div"} className={"w-full"} eventKey={keyAccordion}>
                        <Accordion.Button bsPrefix={"accordion"} action="true" as={"div"}  className={"cursor-pointer ebloom-accordion border-[1px] border-contrast-purple rounded-md  h-45 w-full pl-15 "}>
                            <Row className={"h-full w-full m-0"} >
                                <Col className={"flex justify-start items-center w-full"} onClick={() => handleAccordion(keyAccordion)}>
                                    <div className={"w-full flex flex-row items-center"}>
                                        <h1 className={"text-contrast-purple ebloom-text ebloom-large-text mr-5"}>{_.upperFirst(listIdentity.title)}</h1>
                                        {
                                            teams.filter(el => selectedTeams.includes(el.team.id)).map(el => (
                                                <Badge pill className={'text-white bg-purple mr-5'}>
                                                    {el.team.name}
                                                </Badge>
                                            ))

                                        }
                                        <div className={"ml-10"}>
                                            {warning &&
                                                <EbloomTooltip text={_.upperFirst(t("admin.identity.warningText"))}>
                                                    <AlertIcon className={"h-20 w-20 red-icon"}/>
                                                </EbloomTooltip>

                                            }
                                        </div>
                                    </div>
                                </Col>


                                <Col md={{span: "2", order: "last"}} onClick={expandedKey === keyAccordion ? () => {
                                } : () => handleAccordion(keyAccordion)}>
                                <div
                                        className={"w-full h-full flex  justify-end items-center"} >
                                        {
                                            expandedKey === keyAccordion &&
                                            <DeleteModal handleDelete={handleDelete} noRequest={true} />
                                        }

                                        <div
                                            className={"w-30 h-30  transition duration-300 " + (expandedKey === keyAccordion ? "rotate-180" : "rotate-0")} onClick={() => handleAccordion(keyAccordion)}>
                                            <ArrowDropDownIcon className={"w-full h-full text-contrast-purple"}/>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Accordion.Button>
                        <Accordion.Body style={{padding: 0}}>
                            <div
                                className={"border-[1px] border-t-0 rounded-b-md border-contrast-purple w-full h-full py-16 px-10"}>
                                <div>
                                    <div data-customLib={'steps'} className={"px-5 flex justify-center"}>
                                        {
                                            steps.map((s, index) => (
                                                <div key={index} data-customLib={'step' + index}
                                                     className={"center inline_icon pointer ebloom-text ebloom-very-small-text " + calculateBackground(index)}
                                                     onClick={langs.length === 0 ? () => {
                                                     } : () => {
                                                         handleClickStep(index)
                                                     }}>{(index + 1) + ". " + _.upperFirst(t("admin.identity." + s.name))}</div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className={"ebloom-text ebloom-large-text font-weight-bold contrast-purple mt20 " + ( "mb5")}>{step === steps.length-1 ? _.upperFirst(t("admin.identity.validationHelper")) : _.upperFirst(t("admin.identity.stepHelper"))}</div>

                                    {
                                        step < steps.length-1 && langs.length > 0 && langs.map((lang, index) => {
                                            const keySentenceStart = steps[step][lang].keySentence.split("{")[0];
                                            const keySentenceEnd = steps[step][lang].keySentence.split("}")[1];
                                            const hasValue = identities.filter(el => el.key_value === steps[step][lang].keyValue).length > 0;
                                            const value = hasValue ? identities.filter(el => el.key_value === steps[step][lang].keyValue)[0].text : steps[step][lang].keyValue;
                                            return (
                                                <div className={"mt-10 mb-10"} key={index}>
                                                    <Badge
                                                        bg={""}
                                                        className={"badge-purple inline_icon ebloom-text ebloom-small-text font-weight-normal mr-20 align-top h-30 w-50 rounded-xl pt-7"}
                                                    >{_.upperCase(lang)}</Badge>
                                                    {
                                                        <div className={"inline_icon"}>
                                                            <p className={"ebloom-text ebloom-normal-text"}>
                                                                <span>{processTextIdentity(keySentenceStart, lang)}</span>
                                                                <PurpleTextField size="small"
                                                                                 placeholder={placeholders.length > 0 && placeholders.filter(el => el.key_value === steps[step][lang].keyValue).length > 0 ? placeholders.filter(el => el.key_value === steps[step][lang].keyValue)[0].text : !keySentenceStart ? _.upperFirst(steps[step][lang].keyValue) : steps[step][lang].keyValue}
                                                                                 variant="outlined"
                                                                                 className={"very_small_textfield"}
                                                                                 value={hasValue ? value : ""}
                                                                                 onChange={handleChange(hasValue, lang, steps[step][lang].keyValue)}/>
                                                                <span>{processTextIdentity(keySentenceEnd, lang)}</span>
                                                            </p>
                                                            {
                                                                steps[step][lang].examples.map((example, index) => {
                                                                    const start = example.split("{")[0];
                                                                    const end = example.split("}")[1]
                                                                    const defaultWord =placeholders.length > 0 && placeholders.filter(el => el.key_value === steps[step][lang].keyValue).length > 0 ? placeholders.filter(el => el.key_value === steps[step][lang].keyValue)[0].text : example.split("{")[1].split("}")[0];
                                                                    return (
                                                                        <div key={index}
                                                                             className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(start, lang)}<span
                                                                            style={{
                                                                                textDecoration: "underline",
                                                                                cursor: "default"
                                                                            }}>{!start ? _.upperFirst(hasValue ? value : defaultWord) : (hasValue ? value : defaultWord)}</span>{processTextIdentity(end, lang)}
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                            {
                                                                step === 2 && lang === "fr" && identities.filter(el => el.key_value === steps[step][lang].keyValue).length > 0 && identities.filter(el => el.key_value === steps[step][lang].keyValue)[0].text &&
                                                                <div>
                                                                    <p className={"ebloom-text ebloom-normal-text mt20"}>
                                                                        <span>{colleguesPrefix.keySentenceStart}</span>
                                                                        <PurpleTextField size="small"
                                                                                         placeholder={colleguesPrefix.keyValue.replace("mes collègues", value)}
                                                                                         variant="outlined"
                                                                                         className={"very_small_textfield "}
                                                                                         value={identities.filter(el => el.key_value === colleguesPrefix.keyValue).length > 0 ? identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text : null}
                                                                                         onChange={handleChange(identities.filter(el => el.key_value === colleguesPrefix.keyValue).length > 0, "fr", colleguesPrefix.keyValue)}/>
                                                                        <span>{colleguesPrefix.keySentenceEnd}</span>
                                                                    </p>
                                                                    {
                                                                        step === 2 && colleguesPrefix.examples.map((example, index) => {
                                                                            const start = example.split("{")[0];
                                                                            const end = example.split("}")[1]
                                                                            return (
                                                                                <div key={index}
                                                                                     className={"contrast-purple ebloom-text ebloom-small-text italic mb5"}>{processTextIdentity(start, lang)}<span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        cursor: "default"
                                                                                    }}>{identities.filter(el => el.key_value === colleguesPrefix.keyValue).length > 0 && identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text ? identities.filter(el => el.key_value === colleguesPrefix.keyValue)[0].text : colleguesPrefix.keyValue.replace("mes collègues", value)}</span>{processTextIdentity(end, lang)}
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        step === steps.length - 1 &&
                                        <div>
                                            <div className={"flex w-full"} >
                                                <div>
                                                    <FullContrastPurpleTextField variant={"outlined"} size="small" value={listIdentity.title} name={"title"} onChange={handleChangeListIdentity}  />
                                                </div>
                                                <div className={"ml-20 w-2/3"} >
                                                    <FormControl variant={"outlined"} size={"small"} fullWidth >
                                                        <InputLabel className={"text-contrast-purple bg-bg-white"} >Teams</InputLabel>
                                                        <Select
                                                            className={"text-purple border border-contrast-purple"}
                                                            label={"Teams"}
                                                            size={"small"}
                                                            multiple
                                                            value={selectedTeams}
                                                            onChange={(event) => {handleChangeMultiple(event)}}
                                                            renderValue={(selected) => (
                                                                <div className={"mx-2 flex"}>
                                                                    {selected.map((item, index) => (
                                                                        <div key={index} className={"px-10 mx-5 bg-purple/[0.2] text-contrast-purple ebloom-text rounded-xl"}>
                                                                            {teams.filter(el => el.team.id === item)[0]?.team.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            {
                                                                teams.map(el =>(
                                                                    <MenuItem disabled={el.team.identity_teams?.length > 0 && el.team.identity_teams[0].id_list_identity !== listIdentity.id} selected={selectedTeams.includes(el.team.id)} value={el.team.id}>
                                                                        {el.team.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>

                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className={"ml5 mt20 mb20"}>
                                                <FullContrastPurpleTextField value={selectedLang} select
                                                                             variant="outlined"
                                                                             size="small"
                                                                             className={"very_small_textfield"}
                                                                             onChange={handleChangeLang}>
                                                    {
                                                        langs.map((l, index) => (
                                                            <MenuItem key={index}
                                                                      value={l}>{_.upperCase(t("core.lang." + l))}</MenuItem>
                                                        ))
                                                    }
                                                </FullContrastPurpleTextField>
                                            </div>
                                            <CardDefault showHeader={false}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div
                                                            className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.originalTerms"))}</div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div
                                                            className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.customizedTerms"))}</div>
                                                    </Col>
                                                    <Col>
                                                        <div
                                                            className={"center ebloom-text ebloom-xtra-large-text contrast-purple"}>{_.upperFirst(t("admin.identity.adaptedCBExamples"))}</div>
                                                    </Col>
                                                </Row>
                                                {
                                                    steps.slice(0, steps.length - 1).map((s, index) => {
                                                        const hasValue = identities.filter(el => el.key_value === s[selectedLang].keyValue).length > 0;
                                                        const value = hasValue && langs.length > 0 ? identities.filter(el => el.key_value === s[selectedLang].keyValue)[0].text : "";
                                                        const exampleStart = index === 6 ? s[selectedLang].examples[0] + (hasValue ? " : " : "") : s[selectedLang].keySentence.split("{")[0];
                                                        const exampleEnd = index === 6 ? "" : s[selectedLang].keySentence.split("}")[1];
                                                        return (
                                                            <Row key={index}>
                                                                <Col md={3}>
                                                                    <div
                                                                        className={"ebloom-normal-text contrast-purple center mt10 mb10 pointer"}
                                                                        onClick={() => {
                                                                            handleStep(index + 1)
                                                                        }}>{placeholders.length > 0 && placeholders.filter(el => el.key_value === s[selectedLang].keyValue).length > 0 ? placeholders.filter(el => el.key_value === s[selectedLang].keyValue)[0].text :s[selectedLang].keyValue}</div>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <div
                                                                        className={"ebloom-normal-text purple center mt10 mb10"}>{value}</div>
                                                                </Col>
                                                                <Col>
                                                                    <div
                                                                        className={"ebloom-normal-text contrast-purple center mt10 mb10"}>{exampleStart}<span
                                                                        style={{
                                                                            textDecoration: "underline",
                                                                            cursor: "default"
                                                                        }}>{!exampleStart ? _.upperFirst((hasValue && value !== "") || index === 6 ? value : s[selectedLang].keyValue) : (hasValue && value !== "") || index === 6 ? value : s[selectedLang].keyValue}</span>{exampleEnd}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </CardDefault>
                                        </div>
                                    }
                                    <div className={"w-full h-35"}>
                                        {
                                            (langs.length > 0) &&
                                            <KeyboardArrowLeftIcon
                                                style={{color: Colors.EBLOOM_PURPLE, fontSize: "2em", cursor: "pointer"}}
                                                onClick={() => {
                                                    handleStep(step)
                                                }}/>
                                        }
                                        {
                                            step === steps.length - 1 ?
                                                <div className={"right"}>
                                                    {
                                                        checkValidity() ?
                                                            <Button data-customLib={'saveBtn'}
                                                                    className={"ebloom-btn-purple"}
                                                                    size={"sm"} onClick={handleOpenModal}
                                                                    disabled={loading}>{loading ? <LoadingView
                                                                size={16}/> : _.upperFirst(t("core.save"))}</Button>
                                                            :
                                                            <Button className={"ebloom-btn-purple"} size={"sm"}
                                                                    onClick={handleOpenModal} disabled>
                                                                <EbloomTooltip
                                                                    text={_.upperFirst(t("admin.identity.errorText"))}
                                                                    placement={"left"}>
                                                                    <div>{_.upperFirst(t("core.save"))}</div>
                                                                </EbloomTooltip>
                                                            </Button>

                                                    }
                                                </div>
                                                :
                                                <div className={"right"}>
                                                    <Button size="sm" className={"ebloom-btn-purple ml5"} onClick={() => {
                                                        handleStep(step + 1)
                                                    }}>{_.upperCase(t("core.next"))}</Button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                : <LoadingView/>
            }

            {
                step === steps.length-1 ?
                    <ConfirmModal handleClose={handleCloseModal} show={show}
                                  title={_.upperFirst(t("admin.identity.confirmModalTitle"))}
                                  buttonLeftText={_.capitalize(t("core.cancel"))}
                                  buttonRightText={_.capitalize(t("core.confirm"))} handleChange={handleSave}
                                  buttonDisabled={loading}>
                        <p>{_.upperFirst(t("admin.identity.confirmModalTextCustom", {langs: displayLang(),teams: teams.filter(el => selectedTeams.includes(el.team.id)).map(el => (el.team.name)).join(',')}))}</p>
                    </ConfirmModal>
                    :
                    <ConfirmModal handleClose={handleCloseModalSkip} show={showModalSkip}
                                  title={_.upperFirst(t("admin.identity.skipModalTitle", {step: _.upperFirst(t("admin.identity." + (steps[step].name)))}))}
                                  buttonLeftText={_.upperFirst(t("core.cancel"))}
                                  buttonRightText={_.upperFirst(t("core.confirm"))} handleChange={handleSkip}>
                        <p>{_.upperFirst(t("admin.identity.skipModalText", {
                            step: _.upperFirst(t("admin.identity." + (steps[step].name))),
                            key_value: (steps[step][getLanguage()].keyValue)
                        }))}</p>
                    </ConfirmModal>
            }


        </>
    );
};

IdentityCustom.propTypes = {};

export default IdentityCustom;
