import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import CategoryEditor from "./CategoryEditor";


const CategoryEditorPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
            <Container>
                <React.Fragment>
                    <CategoryEditor companyToEdit={companyToEdit}/>
                </React.Fragment>
            </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default CategoryEditorPage;