import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {alpha, createTheme, styled} from "@mui/material/styles";
import {Colors} from "../../../../Utils/Colors";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import {FormControl} from "@mui/material";
import Select from "@mui/material/Select";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {useUserData} from "../../../../hooks/userHook";
import {CompanyTypes, Roles} from "../../../../Utils/Global";
import InputLabel from "@mui/material/InputLabel";

const FormControlStylesPurple = styled(FormControl)(({theme}) => ({
    '& label.Mui-focused': {
        color:theme?.primary ? theme?.primary :  Colors.EBLOOM_CONTRAST_PURPLE
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white",
        '&.Mui-focused fieldset': {
            borderColor:theme?.primary ? theme?.primary :  Colors.EBLOOM_CONTRAST_PURPLE,
        },
    },
    '& .MuiFormHelperText-root':{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_BLUE
    },
    "& .MuiFormLabel-root":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
    },
    "& .MuiOutlinedInput-root fieldset":{
        borderColor:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
    },
    "& .MuiInputBase-input":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
    },
    "& .MuiSelect-iconOutlined":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_PURPLE
    },
    '& .MuiOutlinedInput-notchedOutline':{
        maxHeight:"100%",
        top:0
    }
}))

const FormControlStylesBlue = styled(FormControl)(({theme}) => ({
    '& label.Mui-focused': {
        color:theme?.primary ? theme?.primary :  Colors.EBLOOM_LOGO_BLUE
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white",
        '&.Mui-focused fieldset': {
            borderColor: theme?.primary ? theme?.primary : Colors.EBLOOM_LOGO_BLUE,
        },
    },
    '& .MuiFormHelperText-root':{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_CONTRAST_BLUE
    },
    "& .MuiFormLabel-root":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_LOGO_BLUE
    },
    "& .MuiOutlinedInput-root fieldset":{
        borderColor:theme?.primary ? theme?.primary : Colors.EBLOOM_LOGO_BLUE
    },
    "& .MuiInputBase-input":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_LOGO_BLUE
    },
    "& .MuiSelect-iconOutlined":{
        color:theme?.primary ? theme?.primary : Colors.EBLOOM_LOGO_BLUE
    },
    '& .MuiOutlinedInput-notchedOutline':{
        maxHeight:"100%",
        top:0
    }
}))


const EbloomMultipleTeamsSelect = (props) => {

    const {value,handleChange,name,className,options,color,label, companyToEdit,isSuperAdmin,isAllEntities, isOneEntitySelected,colors} = props;

    const themeColors = createTheme({
        primary : colors ? colors : color === "blue" ? Colors.EBLOOM_LOGO_BLUE : Colors.EBLOOM_CONTRAST_PURPLE,
    })
    const FormControlStyles = color === "blue" ? FormControlStylesBlue : FormControlStylesPurple;

    const [showMenu, setShowMenu] = useState(false);

    const t = useTranslation()
    const user = useUserData();
    const isProspect = user.companyType === CompanyTypes.PROSPECT
    const isAdvanced = user.roles.includes(Roles.ADVANCED_USER[0])


    const handleChangeMultiple = (event) => {
        const valueEvent = event.target.value
        const name = event.target.name
        const companyID = isSuperAdmin ? isAllEntities && isOneEntitySelected ? 0 : !!companyToEdit ? companyToEdit.id : user.companyGroup.id : 0
        const companySelected = valueEvent.filter(el => el.id === companyID).length > 0 && value.filter(el => el.id === companyID).length < 1
        const benchmarkSelected = valueEvent.filter(el => el.id === -1).length > 0 && value.filter(el => el.id === -1).length < 1
        if(valueEvent.length > 0){
            const indexCompanyRemove = value.findIndex(el => el.id === companyID)
            const indexBenchmarkRemove = value.findIndex(el => el.id === -1)
            if(benchmarkSelected){
                setShowMenu(false)
                handleChange([options[options.length-1]],name)
            }else if(companySelected){
                setShowMenu(false)
                handleChange([options[0]],name)
            }else if(indexBenchmarkRemove !== -1){
                valueEvent.splice(indexBenchmarkRemove,1)
                handleChange(valueEvent,name)
            }else if(indexCompanyRemove !== -1){
                valueEvent.splice(indexCompanyRemove,1)
                handleChange(valueEvent,name)
            }else{
                handleChange(valueEvent,name)
            }
        }else if((valueEvent.length === 1 && value[0].id === companyID) || (valueEvent.length === 0 && value.length === 1 && value[0].id === companyID)){
            setShowMenu(false)
        }
    }

    return(
        <FormControlStyles theme={themeColors} fullWidth={true} variant="outlined" name={name}>
            <InputLabel>{label}</InputLabel>
            <Select
                fullWidth={true}
                size={'small'}
                label={label}
                defaultValue={0}
                className={className}
                value={value}
                multiple={true}
                name={name}
                onChange={handleChangeMultiple}
                onClose={()=> setShowMenu(false)}
                onOpen={()=> setShowMenu(true)}
                open={showMenu}
                MenuProps={{
                    sx : {
                        "&& .Mui-selected": {
                            backgroundColor : alpha(colors ? colors : Colors.EBLOOM_PURPLE,0.35)
                        },
                    }
                }}
                renderValue={(selected) => {
                        if(selected.length === 1){
                            return <div><p
                                className={"text-ellipsis overflow-hidden"}>{selected[0].name}</p>
                            </div>
                        }else{
                            return <div>
                                <p className={"text-ellipsis overflow-hidden"}>{selected.length +" "+ (isSuperAdmin && !isOneEntitySelected? _.lowerFirst(t("core.entities")): _.lowerFirst(t("admin.teams")))}</p>
                            </div>
                        }
                    }
                }
            >
                {
                    options.map((option, index) => {
                        if(option.tooltip) {
                            return (
                                <MenuItem key={index} divider={false}
                                          value={option}>
                                    <EbloomTooltip text={option.tooltip} placement={"right"}>
                                        <div className={"w-full"}>
                                            {option.name}
                                        </div>
                                    </EbloomTooltip>
                                </MenuItem>

                            )
                        } else {
                            const isCompany = option.id === 0
                            let name = isCompany ? !isProspect ? companyToEdit ? companyToEdit.name : user.company : _.upperFirst(t("core.company.company")) : option.name;
                            return (
                                <MenuItem key={index} divider={(isCompany || options.length === index + 2) && !isAdvanced}
                                          value={option}>{name}</MenuItem>
                            )
                        }

                    })
                }
            </Select>
        </FormControlStyles>
    )


}

EbloomMultipleTeamsSelect.propTypes = {
    label:PropTypes.string.isRequired,
    value:PropTypes.any.isRequired,
    name:PropTypes.string.isRequired,
    handleChange:PropTypes.func.isRequired,
    options:PropTypes.array.isRequired,
    isSuperAdmin : PropTypes.bool,
    isAllEntities : PropTypes.bool,
    optionKey:PropTypes.string,
    optionValue:PropTypes.string,
    fullWidth:PropTypes.bool,
    isOneEntitySelected:PropTypes.bool,
    color:PropTypes.string,
    colors:PropTypes.string,
    size:PropTypes.string,


};

EbloomMultipleTeamsSelect.defaultProps = {
    isSuperAdmin : false,
    isAllEntities : false,
    isOneEntitySelected : false,
    optionKey:"name",
    fullWidth:true,
    color:"default",
    colors:null,
    size:"normal"
};

export default EbloomMultipleTeamsSelect;