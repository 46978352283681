import React from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";

import {Container} from "react-bootstrap";
import Integration from "./Integration";



const IntegrationPage = (props) => {
    return(
        <DisplayAdminView {...props} menu={"settings"} pageName={"core.integration"} selectedIndex={2}>
            {
                () => (
                    <Container>
                        <Integration {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )


};

export default IntegrationPage;