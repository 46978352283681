import React from 'react';
import DisplaySuperAdminView from "../../../Layouts/DisplaySuperAdminView";
import {Container} from "react-bootstrap";
import WeBloomDashboard from "../../../../Admin/Pages/WeBloom/Dashboard/WeBloomDashboard";

const WeBloomDashboardEntitiesPage = (props) => {
    //const {} = props

    return (
        <DisplaySuperAdminView
            pageName={"WeBloom"}
            menu={"noMenu"}
            selectedIndex={9}
            showDatePicker={false}
            module={"webloom"}
            {...props}
        >
            {
                (checkForCollabSurvey, checkIfCollabAccess, nbActiveSurvey,isAllEntities) =>(
                    <Container className={"pt-114 h-full"}>
                        <WeBloomDashboard isSuperAdmin={true} displayEntitySwitcher={true} isAllEntities={isAllEntities} isTemplate={false} nbActiveSurvey={nbActiveSurvey} {...props} />
                    </Container>
                )
            }
        </DisplaySuperAdminView>
    );
};

WeBloomDashboardEntitiesPage.propTypes = {

};

export default WeBloomDashboardEntitiesPage;
