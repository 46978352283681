import {memo, useState} from 'react'
import { useDrag, useDrop } from 'react-dnd'


export const DragAndDropItemV2 = memo(function DragAndDropItemV2(props) {
    const {id, moveItem, findItem, children} =  props;
    const originalIndex = findItem(id).index
    const [canDrag, setCanDrag] = useState(true)
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "existingQuestion",
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag:canDrag,
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item
                const didDrop = monitor.didDrop()
                if (!didDrop) {
                    moveItem(droppedId, originalIndex)
                }
            },
        }),
        [id, originalIndex, moveItem, canDrag],
    )
    const [, drop] = useDrop(
        () => ({
            accept: "existingQuestion",
            hover({ id: draggedId }) {
                if (draggedId !== id) {
                    const { index: overIndex } = findItem(id)
                    moveItem(draggedId, overIndex)
                }
            },
        }),
        [findItem, moveItem],
    )

    const handleCanDrag = (value) => {
        setCanDrag(value);
    }

    const opacity = isDragging ? 1 : 1
    return (
        <div ref={(node) => drag(drop(node))} style={{opacity}}>
            {children(handleCanDrag)}
        </div>
    )
})
