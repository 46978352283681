import React from 'react';
import PropTypes from 'prop-types';

const EbloomLogo = (props) => {
    //const {colors} = props

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600.05 323.15" >
            <g id="Calque_1-2" data-name="Calque 1">
                <path style={{isolation: "isolate", mixBlendMode: "multiply"}}
                      d="M126.62,264.51c14.27-9.27,22.45-20.25,25.68-34.51a117.87,117.87,0,0,0,29.58-13.85c26.67-17.32,45.47-45.52,51.58-77.36,5.95-31.07-.45-61.87-18-86.7A118.87,118.87,0,0,0,142.14,4.56C112.22-1.66,81.78,3.46,56.43,19c-.91.56-1.81,1.13-2.7,1.7A116.76,116.76,0,0,0,3.13,93.92a114.33,114.33,0,0,0,15.64,87.56,118.1,118.1,0,0,0,80.78,52.29c13,4.42,20,9.26,20.82,14.4.62,3.92-2.44,8.39-9.09,13.29a61.79,61.79,0,0,0-7.64,6.62c-2.23,2.33-4.12,4.76-2.84,6.71s3.82,2.69,21.93-7.89q2-1.17,3.89-2.39Z"/>
                <path style={{fill: "#b32ddd"}}
                      d="M126.28,264.51c14.27-9.27,22.45-20.25,25.68-34.51a117.94,117.94,0,0,0,29.59-13.85c26.67-17.32,45.47-45.52,51.57-77.36,5.95-31.07-.45-61.87-18-86.7A118.87,118.87,0,0,0,141.8,4.56C111.88-1.66,81.44,3.46,56.09,19c-.91.56-1.8,1.13-2.7,1.7A116.79,116.79,0,0,0,2.79,93.92a114.33,114.33,0,0,0,15.64,87.56,118.12,118.12,0,0,0,80.78,52.29c13,4.42,20,9.26,20.82,14.4.62,3.92-2.44,8.39-9.09,13.29a62.53,62.53,0,0,0-7.64,6.62c-2.23,2.33-4.12,4.76-2.84,6.71s3.82,2.69,21.93-7.89c1.34-.78,2.65-1.58,3.89-2.39Z"/>
                <path style={{fill: "#fff"}}
                      d="M128,65.2a48.76,48.76,0,0,1,16.3,2.62,25.55,25.55,0,0,1,12,8.56q4.56,5.93,4.56,16a23,23,0,0,1-6.16,16.08,46.85,46.85,0,0,1-16,11.06,117.55,117.55,0,0,1-21.22,7q-11.4,2.63-21.89,4,0,13.68,3,22.35t8.33,12.77a20.55,20.55,0,0,0,12.89,4.11,32.11,32.11,0,0,0,12-2.51,39,39,0,0,0,12.2-8.1,53.44,53.44,0,0,0,10.72-15.16l9.35,5q-6.39,12.78-13.91,20.65A46,46,0,0,1,134,180.84a50.54,50.54,0,0,1-18.71,3.42q-21.43,0-32.38-13.12t-11-36.38A92.92,92.92,0,0,1,75.33,110,76.31,76.31,0,0,1,85.59,87.44a51.36,51.36,0,0,1,17.57-16.19Q113.87,65.21,128,65.2Zm-3,10.26A20.35,20.35,0,0,0,109.43,82a42.89,42.89,0,0,0-9.24,16.76,98.16,98.16,0,0,0-4.1,21.21A95.79,95.79,0,0,0,118,114.12a44.44,44.44,0,0,0,15.62-10q5.82-6,5.59-13.57,0-6.84-3.76-11T125.05,75.46Z"/>
                <path style={{fill: "#07b7e3"}}
                      d="M568.11,21.72,222.48,0C203,0,187.12,12.92,187.12,28.86V231.8c0,15.94,15.83,28.86,35.36,28.86l235.41-21.72C455,254.74,460,274.3,473,298.32c10.42,19.23,19.78,25.41,23.23,24.79a1.7,1.7,0,0,0,1.15-.81c1.27-1.87,1.44-6.64-1.9-14.42-15.88-36.89-5.28-57.57,8.68-68.94L570.58,231c16.27,0,29.47-10.77,29.47-24.05l-2.48-161.2C597.57,32.49,584.38,21.72,568.11,21.72Z"/>
                <path style={{fill: "#fff"}}
                      d="M209.6,183.68V66.28h28.94c21,0,34.56,9.89,34.56,30.35,0,11.58-4.29,19.63-10.31,24.32,8.7,5,13.79,14.76,13.79,28,0,23.65-13.79,34.72-35.23,34.72Zm18.62-68.6H242c8.44,0,12.06-5.2,12.06-14.76,0-10.23-6.57-13.58-15.41-13.58H228.22Zm0,48.14h11.52c11.26,0,17.82-3.53,17.82-14.59,0-10.91-5.63-15.43-16.21-15.43H228.22Z"/>
                <path style={{fill: "#fff"}}
                      d="M304,153.74c0,9.06,1.87,13.92,7.23,13.92a14.84,14.84,0,0,0,3.88-.5l1.34,18.28a43.85,43.85,0,0,1-9.37,1.34c-15.41,0-21.57-12.58-21.57-32.7V64.51L304,63.17Z"/>
                <path style={{fill: "#fff"}}
                      d="M356.1,185.69c-20.63,0-33.49-17.94-33.49-44.95,0-26.83,12.86-44.78,33.49-44.78s33.36,18,33.36,44.78C389.46,167.75,376.87,185.69,356.1,185.69Zm1-19.12c9.52,0,14.47-9.89,14.47-25.83,0-15.76-4.95-25.66-14.47-25.66s-14.6,9.9-14.6,25.66C342.5,156.68,347.73,166.57,357.1,166.57Z"/>
                <path style={{fill: "#fff"}}
                      d="M432.09,185.69c-20.63,0-33.49-17.94-33.49-44.95,0-26.83,12.86-44.78,33.49-44.78s33.35,18,33.35,44.78C465.44,167.75,452.85,185.69,432.09,185.69Zm0-19.12c9.51,0,14.46-9.89,14.46-25.83,0-15.76-4.95-25.66-14.46-25.66s-14.61,9.9-14.61,25.66C417.48,156.68,422.71,166.57,432.09,166.57Z"/>
                <path style={{fill: "#fff"}}
                      d="M557.49,183.68V132c0-11.24-3.62-16.94-12-16.94a22.39,22.39,0,0,0-12.46,4,69.7,69.7,0,0,1,1.6,15.76v48.81h-18V132c0-11.24-3.48-16.94-12.05-16.94a21.42,21.42,0,0,0-10.85,3v65.58H475.37V103.34A65,65,0,0,1,505.52,96c7.77,0,14.2,2.35,18.88,6.88C530,99.32,538.07,96,546.24,96c19,0,29.6,12.41,29.6,34.38v53.34Z"/>
            </g>
        </svg>
    );
};

EbloomLogo.propTypes = {
    colors: PropTypes.object,
};

EbloomLogo.defaultProps = {
    colors: null
}
export default EbloomLogo;
