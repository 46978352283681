import {PaletteColors, selectEmote, selectForm, selectHat} from "./AvatarAssets";
import PropTypes from "prop-types";


const Avatar = (props) => {


    const {avatar} = props

    return (
        <div className={`mx-auto w-full relative max-w-[250px]`}>
            <div className={'w-full'}>
                {selectForm(avatar.form, PaletteColors[avatar.color].avatar)}
            </div>
            <div className={'absolute w-full top-0'}>
                {avatar.hat !== "16" ? selectHat(avatar.hat, PaletteColors[avatar.color].avatar) : null}
            </div>

            <div className={'absolute w-full top-0'}>
                {selectEmote(avatar.emote)}
            </div>
        </div>
    )
}

Avatar.propTypes = {
    avatar : PropTypes.object.isRequired
}

export default Avatar