import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";
import {createPlaylistSteps} from "./createPlaylistSteps";

const CreatePlaylistTour = (props) => {

    const {
        updateStep,
        stepTour,
        stepCreatePlaylist, setStepCreatePlaylist,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        validateOutcome,
        icon,
        setContinuous,continuous,
        updateHandler
    } = useContext(ProductTourContext)

    const {setSideForm} = props

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if(ACTIONS.NEXT === action){
                if(index===2){
                    setContinuous(false)
                    updateStep(TourType.CREATE_PLAYLIST,2)
                }else{
                    setContinuous(true)
                    updateStep(TourType.CREATE_PLAYLIST,next)
                }
            }else if (ACTIONS.PREV === action){
                if(index === 2){
                    setSideForm(false)
                    updateStep(TourType.CREATE_PLAYLIST,next)
                }else{
                    setContinuous(true)
                    updateStep(TourType.CREATE_PLAYLIST,next)
                }
            }
        }
    }

    const handleTour = () => {
        if(stepCreatePlaylist === 2){
            updateStep(TourType.MANAGE_TEAMS,1)
        }else{
           setContinuous(true)
        }
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)
        const tour = {
            id : stepTour[TourType.CREATE_PLAYLIST].id,
            current_step : stepTour[TourType.CREATE_PLAYLIST].step >= createPlaylistSteps.length - 1 ? 0 : stepTour[TourType.CREATE_PLAYLIST].step,
            type: TourType.CREATE_PLAYLIST,
            finished : stepTour[TourType.CREATE_PLAYLIST].outcome
        }
        if(stepCreatePlaylist >= createPlaylistSteps.length - 1){
            validateOutcome(TourType.CREATE_PLAYLIST)
            setStepCreatePlaylist(0)
            icon.current.classList.remove('ebloom-ring')
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{
            })
        }
        iconRinging()
    }

    const iconRinging = () => {
        if(icon && icon.current && stepTour[TourType.CREATE_PLAYLIST]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.CREATE_PLAYLIST].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepCreatePlaylist,stepTour,tourActivated]);


    useEffect(() => {
        fetchData();
        if(tourActivated && icon && icon.current){
            iconRinging()
        }
        updateHandler(TourType.CREATE_PLAYLIST,handleTour)

        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={createPlaylistSteps}
                continuous={continuous}
                run={tourStarted}
                stepIndex={stepCreatePlaylist}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />

        </>
    )
}



export default CreatePlaylistTour