import {ModelTypes} from "../../../models/Types";

const LDAP = {
    crud: {
        create: "/integration/ldap/save",
        getAll: "/integration/ldap/getAll"
    },

    id: {
        id: "id",
        type: ModelTypes.NUMBER,
        label: "id",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show: false
        },
        detail: {
            show: false
        }
    },

    url: {
        id: "url",
        type: ModelTypes.TEXT,
        label: "Server url",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"url-ldap",
            label:"Server url",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"url"
        },
        detail: {
            show: false
        }
    },

    bindDN:{
        id: "bindDN",
        type: ModelTypes.TEXT,
        label: "Bind DN",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"bindDN-ldap",
            label:"Bind DN",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"bindDN"
        },
        detail: {
            show: false
        }
    },

    bindCredentials:{
        id: "bindCredentials",
        type: ModelTypes.TEXT,
        label: "Bind Credentials",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"bindCredentials-ldap",
            label:"Bind credentials",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"bindCredentials"
        },
        detail: {
            show: false
        }
    },

    scope:{
        id: "scope",
        type: ModelTypes.TEXT,
        label: "Scope",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"scope-ldap",
            label:"Scope",
            select:true,
            selectOptions:{
                options:[{name:"Base", id:1},{name:"Sub", id:2},{name:"One", id:3}]
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"scope"
        },
        detail: {
            show: false
        }
    },

    filter:{
        id: "filter",
        type: ModelTypes.TEXT,
        label: "filter",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"filter-ldap",
            label:"Filter",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"filter"
        },
        detail: {
            show: false
        }
    },

    attributes:{
        id: "attributes",
        type: ModelTypes.TEXT,
        label: "Attributes",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"attributes-ldap",
            label:"Attributes",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"attributes"
        },
        detail: {
            show: false
        }
    },
}

export default LDAP;
