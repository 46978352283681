import React from "react"
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import MobileEditor from "./MobileEditor";

const MobileEditorPage = (props) => {

    return (
        <DisplayBackofficeView {...props}>
            {
                () => (
                    <MobileEditor {...props}/>
                )
            }
        </DisplayBackofficeView>
    )

}

export default MobileEditorPage;