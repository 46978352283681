import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import Playlist from "./Playlist";
import {Container} from "react-bootstrap";



const PlaylistPage = (props) => {
    return(
        <DisplayAdminView {...props} menu={"questions"} pageName={"core.playlist.playlists"} selectedIndex={4}>
            {
                () => (
                    <Container>
                        <Playlist {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default PlaylistPage;