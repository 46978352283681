import LoadingView from "../../../../Core/Layouts/LoadingView";
import SimpleBubbleChart from "../../../../Core/Modules/Chart/SimpleBubbleChart";
import {Col, Row} from "react-bootstrap";
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import {useTranslation} from "react-multi-lang/lib";
import * as PropTypes from "prop-types";
import _ from "lodash";
import {selectBubbleIcon} from "../../../../Admin/Pages/Categories/Assets/CategoriesAssets";
import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const MyPriorities = (props) => {
    const {statOfBubble, loadingFocusMatrix,isModelCustomEnable} = props

    const [categories, setCategories] = useState([]);

    const bp = useBreakpoints();
    const t = useTranslation();
    //const navigate = useNavigate();

    const fetchCategories = () => {
        HttpApi.getV2("/categories/getMainCategories").then(response => {
            if(response && response.data){
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const cutName = (name) => {
        if(name) {
            let width = 150;
            const w = document.getElementsByClassName("focus-matrix-legend")[0];
            if(w){
                width = w.offsetWidth;
            }
            const words = name.split(" ");
            const availableSize = width - 8; //remove size of the icon + some margin

            let nbPossibleLettersWords = Math.floor(availableSize / 7);
            let lettersInWords = name.length;
            if (lettersInWords <= nbPossibleLettersWords) {
                return name;
            } else {
                let nbPossibleLettersPerWord = nbPossibleLettersWords / words.length;
                let smallerWords = words.filter(el => el.length < nbPossibleLettersPerWord)
                let largerWords = words.filter(el => el.length > nbPossibleLettersPerWord).map(el => el = {
                    name: el,
                    extraLetters: el.length - nbPossibleLettersPerWord
                });
                let goodWords = words.filter(el => el.length === nbPossibleLettersPerWord);
                let nbExtraLetters = smallerWords.map(el => (nbPossibleLettersPerWord - el.length)).reduce((partialSum, a) => partialSum + a, 0)
                while (nbExtraLetters > 0 && largerWords.find(el => el.extraLetters > 0) !== undefined) {
                    for (let i = 0; i < largerWords.length; i++) {
                        if (largerWords[i].extraLetters > 0 && nbExtraLetters > 0) {
                            largerWords[i].extraLetters--;
                            nbExtraLetters--;
                        }
                    }
                }

                let newWord = "";
                for (let i = 0; i < words.length; i++) {
                    let word = words[i];
                    if (smallerWords.includes(word) || goodWords.includes(word)) {
                        newWord += word;
                    } else if (largerWords.find(el => el.name === word) !== undefined) {
                        const index = word.length - largerWords.find(el => el.name === word).extraLetters - 1;
                        newWord += word.slice(0, index);
                        if (index > 0) {
                            newWord += ".";
                        }
                    }
                    if (i < words.length - 1) {
                        newWord += " ";
                    }
                }
                return newWord;
            }
        }

    }

    useEffect(() => {
        fetchCategories()
    }, []);


    return <div className={"mb-30"}>

        {loadingFocusMatrix ?
            <LoadingView/>
            :
            <div>
                <div className={"flex justify-center flex-col text-center mb-30"}>
                    <h1 className={"ebloom-title text-contrast-purple text-center text-xl font-semibold "}>
                        {_.upperFirst(t("employee.dashboard.whatToAct"))}
                    </h1>
                </div>
                <Row>
                    {
                        categories.length > 5 ?
                            <div className={"w-full"}>
                                {[0, 1].map(index => (
                                    <div className={"w-full flex flex-row justify-around items-center"}>
                                        {
                                            categories.sort((a, b) => a.position - b.position).slice(index === 0 ? 0 : Math.floor(categories.length / 2), index === 0 ? Math.floor(categories.length / 2) : categories.length).map((c, index) => (
                                                <div key={index}
                                                     className={"focus-matrix-legend mb-20 flex flex-col justify-center items-center w-1/5 " + (bp.isTabletLandscapeOrLess() && "mb-30")}>
                                                    <EbloomTooltip text={c.name}>
                                                        <div className={"flex flex-col justify-center items-center mb-15 cursor-pointer w-full"}>
                                                            <div className={"w-45 h-45 "}>
                                                                {selectBubbleIcon(c.icon,c.color)}
                                                            </div>
                                                            <p className={"text-center whitespace-nowrap"}>{cutName(c.name)}</p>
                                                        </div>
                                                    </EbloomTooltip>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))}
                            </div>
                            :

                            <div className={"flex flex-wrap justify-around w-full focus-matrix-legend"}>
                                {
                                    categories.sort((a,b) => a.position - b.position).map(c => (
                                        <div className={"flex flex-col justify-center items-center w-1/5 mb-15 cursor-pointer"}>
                                            <div className={"w-45 h-45 "}>
                                                {selectBubbleIcon(c.icon,c.color)}
                                            </div>
                                            <p className={"text-center " + (isModelCustomEnable ? "whitespace-nowrap" : "")}>{isModelCustomEnable ? c.name :_.upperFirst(t("core.category."+c.name))}</p>
                                        </div>
                                    ))
                                }
                            </div>
                    }
                </Row>
                <SimpleBubbleChart data={statOfBubble} {...props}/>
            </div>
        }
    </div>


}

MyPriorities.propTypes={
    statOfBubble :  PropTypes.any.isRequired,
    loadingFocusMatrix : PropTypes.bool.isRequired,
    isModelCustomEnable : PropTypes.bool
}

MyPriorities.defaultProps={
    isModelCustomEnable :false
}
export default MyPriorities