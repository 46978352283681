import {ModelTypes} from "../../../models/Types";

const SSO = {
    crud: {
        create: "/integrations/sso/save",
        getAll: "/integrations/sso/getAll"
    },

    id: {
        id: "id",
        type: ModelTypes.NUMBER,
        label: "id",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show: false
        },
        detail: {
            show: false
        }
    },

    id_company: {
        id: "id_company",
        type: ModelTypes.NUMBER,
        label: "Id Company",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:false,
        },
        detail: {
            show: false
        }
    },

    type:{
        id: "type",
        type: ModelTypes.TEXT,
        label: "Bind DN",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:false,
        },
        detail: {
            show: false
        }
    },

    domain:{
        id: "domain",
        type: ModelTypes.TEXT,
        label: "Domain",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"domain-sso",
            label:"Domain",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"domain"
        },
        detail: {
            show: true
        }
    },

    client_id:{
        id: "client_id",
        type: ModelTypes.TEXT,
        label: "Client ID",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"client_id-sso",
            label:"Client ID",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"client_id"
        },
        detail: {
            show: true
        }
    },

    client_secret:{
        id: "client_secret",
        type: ModelTypes.TEXT,
        label: "Client Secret",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"client_secret-sso",
            label:"Client Secret",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"client_secret"
        },
        detail: {
            show: true
        }
    },

    scope:{
        id: "scope",
        type: ModelTypes.TEXT,
        label: "Scope",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"scope-sso",
            label:"Scope",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"scope"
        },
        detail: {
            show: true
        }
    },

    filter:{
        id: "filter",
        type: ModelTypes.TEXT,
        label: "filter",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"filter-ldap",
            label:"Filter",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"filter"
        },
        detail: {
            show: true
        }
    },

    attribute:{
        id: "attribute",
        type: ModelTypes.TEXT,
        label: "Attributes",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"attribute-sso",
            label:"Attributes",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"attribute"
        },
        detail: {
            show: true
        }
    },

    email_mapper:{
        id: "email_mapper",
        type: ModelTypes.TEXT,
        label: "Email Mapper",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"email_mapper-sso",
            label:"Email Mapper",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"email_mapper"
        },
        detail: {
            show: true
        }
    },

    name_mapper:{
        id: "name_mapper",
        type: ModelTypes.TEXT,
        label: "Name Mapper",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"name_mapper-sso",
            label:"Name Mapper",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name_mapper"
        },
        detail: {
            show: true
        }
    },

    lang_mapper:{
        id: "lang_mapper",
        type: ModelTypes.TEXT,
        label: "Lang Mapper",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"lang_mapper-sso",
            label:"Lang Mapper",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"lang_mapper"
        },
        detail: {
            show: true
        }
    },

    team_mapper:{
        id: "team_mapper",
        type: ModelTypes.TEXT,
        label: "Team Mapper",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"team_mapper-sso",
            label:"Team Mapper",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"team_mapper"
        },
        detail: {
            show: true
        }
    },

    filter_mapper:{
        id: "filter_mapper",
        type: ModelTypes.TEXT,
        label: "Filter Mapper",
        table: {
            show: false
        },
        list: {
            show: false,
        },
        form: {
            show:true,
            id:"filter_mapper-sso",
            label:"Filter Mapper",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"filter_mapper"
        },
        detail: {
            show: true
        }
    },

}

export default SSO;
