import React from "react";
import PropTypes from "prop-types";
import {PollTypes} from "../../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import Choice from "./Choice";
import OpenQuestion from "./OpenQuestion";
import StarRatings from "./StarRatings";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../../../../lang/translations";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import Identification from "./Identification";
import {replaceUrlsWithLinks} from "../../../../../../../Utils/Global";

export const PollQuestion = (props) => {
    const {question, lang} = props;

    return(
        <div className={"w-full text-contrast-purple mb-20 " + (question.type === PollTypes.TITLE ? "mt-40" : "")}>
            <div className={"ebloom-text contrast-purple font-semibold " + (question.type === PollTypes.TITLE ? " ebloom-xtra-large-text" : " ebloom-large-text")}><div className={"inline"}>{question.title ?replaceUrlsWithLinks(question.title) : question.title}</div>{(question.is_required > 0 && <span className={"ebloom-text ebloom-very-large-text"}>*</span>)}</div>
            <div className={"ebloom-text ebloom-small-text mt5 mb20 text-line-break"}>{question.description ? replaceUrlsWithLinks(question.description) : question.description}</div>
            {
                (question.type === PollTypes.MULTIPLE_CHOICES || question.type === PollTypes.UNIQUE_CHOICE || question.type === PollTypes.YES_NO) &&
                <div className={"ebloom-text ebloom-very-small-text text-grey -mt-20 mb-20 italic"}>{_.upperFirst(getTranslations(lang,"webloom.selectMaxParamAnswer", {param:question.nb_items}))}</div>
            }
            {
                question.image_url &&
                <FileDisplay className={"mb-10 w-fit"} urlParams={question.image_url} imgHeight={300} displayImg />
            }
            {
                question.attachment_url &&
                <div className={"flex justify-start mt-10 items-center"}>
                    <FileDisplay urlParams={question.attachment_url}/>
                </div>

            }
            {
                (question.type === PollTypes.UNIQUE_CHOICE || question.type === PollTypes.MULTIPLE_CHOICES || question.type === PollTypes.YES_NO) ?
                <Choice question={question} {...props}/>
                    :
                    (question.type === PollTypes.OPEN_QUESTION || question.type === PollTypes.NAME) ?
                    <OpenQuestion question={question} {...props}/>
                        :
                        question.type === PollTypes.STAR_RATING ?
                        <StarRatings question={question} {...props}/>
                            :
                            question.type === PollTypes.IDENTIFICATION ?
                                <Identification question={question} {...props}/>
                                :
                            <div className={"mb-20"}/>
            }
        </div>

    )
}

PollQuestion.propTypes = {
    question:PropTypes.object.isRequired,
    lang:PropTypes.string.isRequired
}