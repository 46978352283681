import React, {useEffect, useState} from "react"
import PropTypes from "prop-types";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {FullBlueTextField, FullContrastPurpleTextField, FullPurpleTextField} from "../../../../Utils/Global";
import {ListSubheader, MenuItem} from "@mui/material";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";


const TagList = (props) => {
    const [tags, setTags] = useState([]);

    const {handleChange, value, color, name, size, label,id_action, isModelCustomEnable} = props;

    const t = useTranslation();

    const fetchTags = () => {
        HttpApi.getV2("/tags/getAll").then(response => {
            if(response && response.data){
                if(props.id_category && props.id_category > 0){
                    setTags(response.data.filter(el => !el.id_category || el.id_category === props.id_category));
                }else{
                    response.data.sort((a,b) => {
                        return a.id_category - b.id_category;
                    })
                    setTags(response.data);
                }

            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const initializeTag = (tags, id_category) => {
        let t1 = _.cloneDeep(tags);
        let t2 = [];
        t1.sort((a,b) => {
            return a.id_category - b.id_category;
        });
        t1.forEach((tag, index) => {
            if((index === 0 && tag.id_category > 0) || (index > 0 && tag.id_category !== t1[index-1].id_category)){
                t2.push({id:0, name:tag.category, id_category:0})
            }
            t2.push(tag);
        })
        if(id_category && id_category > 0){
            setTags(t2.filter(el => el.id_category === -1 || el.id_category === id_category));
        }else{
            setTags(t2);
        }

    }

    useEffect(() => {
        if(props.tags && props.id_category){
            initializeTag(props.tags, props.id_category);
        }else{
            fetchTags();
        }
    }, [props.tags, props.id_category])

    return(
        color === "purple-contrast" ?
        <FullContrastPurpleTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
            {
                tags && tags.map((tag, index) => (
                    (tag.id_category === 0) ?
                        <ListSubheader key={index}>{isModelCustomEnable ? tag.name :_.upperFirst(t("core.category."+tag.name))}</ListSubheader>
                    :
                        <MenuItem key={index} value={tag.id}>
                            {
                                tag.id_category === -1 ?
                                    <span>{tag.name}</span>
                                    :
                                    <span>{t("core.tag." + tag.name)}</span>
                            }
                        </MenuItem>
                ))

            }
        </FullContrastPurpleTextField>
            :
            color === "blue" ?
                <FullBlueTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        tags && tags.map((tag, index) => (
                            (tag.id_category === 0) ?
                                <ListSubheader key={index}>{isModelCustomEnable ? tag.name :_.upperFirst(t("core.category."+tag.name))}</ListSubheader>
                                :
                                <MenuItem key={index} value={tag.id}>
                                    {
                                        tag.id_category === -1 ?
                                            <span>{tag.name}</span>
                                            :
                                            <span>{t("core.tag." + tag.name)}</span>
                                    }
                                </MenuItem>
                        ))

                    }
                </FullBlueTextField>
                :
                <FullPurpleTextField disabled={id_action && id_action === 2} select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
                    {
                        tags && tags.map((tag, index) => (
                            (tag.id_category === 0) ?
                                <ListSubheader key={index}>{isModelCustomEnable ? tag.name :_.upperFirst(t("core.category."+tag.name))}</ListSubheader>
                                :
                                <MenuItem key={index} value={tag.id}>
                                    {
                                        tag.id_category === -1 ?
                                            <span>{tag.name}</span>
                                            :
                                            <span>{t("core.tag." + tag.name)}</span>
                                    }
                                </MenuItem>
                        ))

                    }
                </FullPurpleTextField>
    )
}

TagList.propTypes ={
    tags:PropTypes.array,
    id_category:PropTypes.number,
    handleChange:PropTypes.func.isRequired,
    value:PropTypes.number.isRequired,
    color:PropTypes.string,
    name:PropTypes.string,
    size:PropTypes.string,
    label:PropTypes.string,
    id_action: PropTypes.number,
    isModelCustomEnable: PropTypes.bool,
}

TagList.defaultProps = {
    color:"purple-contrast",
    name:"tag-filter",
    size:"small",
    label:"Tags",
    isModelCustomEnable:false
}

export default TagList;