import React from 'react';
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import LinkCompanies from "./LinkCompanies";

const LinkCompaniesPage = (props) => {
    return (
        <DisplayBackofficeView {...props}>
            {
                (companyToEdit) => (
                    <LinkCompanies companyToEdit={companyToEdit} {...props} />
                )
            }
        </DisplayBackofficeView>
    )
};

export default LinkCompaniesPage;
