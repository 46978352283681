import React from "react";
import PropTypes from "prop-types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const EbloomTooltip = (props) => {

    const {placement, text, show} = props;

    return(
        <>
        {
            show ?
            <OverlayTrigger key={placement} placement={placement} overlay={
                <Tooltip className={"ebloom-text ebloom-small-text text-line-break"} style={{zIndex:2000}}>{text}</Tooltip>}>
                {props.children}
            </OverlayTrigger>
                :
                props.children
        }
        </>




    )
}

EbloomTooltip.propTypes = {
    placement:PropTypes.string,
    text:PropTypes.string.isRequired,
    show:PropTypes.bool
}

EbloomTooltip.defaultProps = {
    placement:"bottom",
    show:true
}

export default EbloomTooltip;