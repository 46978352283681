import React from "react";
import PropTypes from "prop-types"
import {FullContrastBlueTextField, FullContrastPurpleTextField, FullPurpleTextField} from "../../../../Utils/Global";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {useTranslation} from "react-multi-lang/lib";

const EbloomWindowPicker = (props) => {

    //state

    //props
    const {value, handleChange, color} = props;

    const StyledTextField = color === "purple" ? FullPurpleTextField : color === "blue" ? FullContrastBlueTextField : FullContrastPurpleTextField

    //hooks
    const t = useTranslation();
    //getters

    //posters

    //handlers

    //useEffect

    const windowChoices = [
        {name:"3 " + t("core.day.days"), value:3},
        {name:"5 " + t("core.day.days"), value:5},
        {name:"10 " + t("core.day.days"), value:10}
    ];

    return (
        <StyledTextField variant={"outlined"} select onChange={handleChange} value={value} name={"window"} size={"small"}>
            {
                windowChoices?.map((choice, index) => (
                    <MenuItem key={index} value={choice.value}>{choice.name}</MenuItem>
                ))
            }
        </StyledTextField>
    )
}

EbloomWindowPicker.propTypes = {
    value:PropTypes.number.isRequired,
    handleChange:PropTypes.func.isRequired,
    color:PropTypes.string
}

EbloomWindowPicker.defaultProps = {
    color:"purple",
}
export default EbloomWindowPicker