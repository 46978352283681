import DisplayAdminView from "../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import React, {useState} from "react";
import Account from "./Account";
import {useUserData} from "../../../../hooks/userHook";
import EntitiesSwitcher from "../../../Core/Modules/Button/EntitiesSwitcher";

const AccountPage = (props) => {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const user = useUserData()
    return(
        <DisplayAdminView selectedIndex={25} menu={"settings"} {...props}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher entity={selectedEntity} handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true}/>
                            </div>
                        }
                        <Account {...props} selectedEntity={selectedEntity} />
                    </Container>
                )
            }
        </DisplayAdminView>
    )
}

export default AccountPage;