import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../../Core/symbols/myBloom/icons/Close.svg";
import {ReactComponent as Dice} from "../../../../Core/symbols/myBloom/icons/DiceIcon.svg";
import {A1, H15, HatWheelList, innerWheel, PaletteColors, selectEmote, selectForm, selectHat} from "./AvatarAssets";
import HttpApi from "../../../../../httpApi";
import ConfirmModalAvatar from "./ConfirmModalAvatar";
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import EditAvatarMobile from "./EditAvatarMobile";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const EditAvatar = (props) => {
    const {setShowEdit, avatar, setAvatar} = props

    const [selectedHat, setSelectedHat] = useState(avatar.hat);
    const [selectedForm, setSelectedForm] = useState(avatar.form);
    const [selectedColor, setSelectedColor] = useState(avatar.color);

    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const t = useTranslation()

    const bp = useBreakpoints()



    const handleChange = (id, category) => {
        switch (category) {
            case "hat":
                handleHat(id)
                break
            case "form":
                handleForm(id)
                break
            default :
                handleColor(id)
                break
        }
    }

    const handleForm = (id) => {
        document.querySelector("#form" + selectedForm).classList.remove("selectedAsset")
        document.querySelector("#form" + id).classList.add("selectedAsset")
        setSelectedForm(id)
    }

    const handleColor = (id) => {
        document.querySelector("#color" + selectedColor).classList.remove("selectedAsset")
        document.querySelector("#color" + id).classList.add("selectedAsset")
        setSelectedColor(id)
    }

    const handleHat = (id) => {
        document.querySelector("#hat" + selectedHat).classList.remove("selectedAsset")
        document.querySelector("#hat" + id).classList.add("selectedAsset")
        setSelectedHat(id)
    }

    const handleSave = () => {

        const codeAvatar = selectedForm + selectedColor + avatar.emote + selectedHat
        HttpApi.post("/mybloom/updateAvatar", [codeAvatar]).then(r => {
            setAvatar((prevState) => {
                return {
                    form: selectedForm,
                    hat: selectedHat,
                    emote: prevState.emote,
                    color: selectedColor
                }
            })
            setShowEdit(false)
        })


    }

    const randomAvatar = () => {
        let randomHat = Math.floor(Math.random() * (16 - 1 + 1) + 1)
        let randomForm = Math.floor(Math.random() * (5 - 1 + 1) + 1)
        let randomColor = Math.floor(Math.random() * (7 - 1 + 1) + 1)
        randomHat = randomHat < 10 ? "0" + randomHat.toString() : randomHat.toString()
        randomForm = "0" + randomForm.toString()
        randomColor = "0" + randomColor.toString()
        document.querySelector("#form" + selectedForm).classList.remove("selectedAsset")
        document.querySelector("#form" + randomForm).classList.add("selectedAsset")

        document.querySelector("#color" + selectedColor).classList.remove("selectedAsset")
        document.querySelector("#color" + randomColor).classList.add("selectedAsset")

        document.querySelector("#hat" + selectedHat).classList.remove("selectedAsset")
        document.querySelector("#hat" + randomHat).classList.add("selectedAsset")

        setSelectedForm(randomForm)
        setSelectedColor(randomColor)
        setSelectedHat(randomHat)

    }

    const resetAvatar = () => {
        const formDefault = avatar.form
        const hatDefault = avatar.hat
        const colorDefault = avatar.color

        document.querySelector("#form" + selectedForm).classList.remove("selectedAsset")
        document.querySelector("#form" + formDefault).classList.add("selectedAsset")

        document.querySelector("#color" + selectedColor).classList.remove("selectedAsset")
        document.querySelector("#color" + colorDefault).classList.add("selectedAsset")

        document.querySelector("#hat" + selectedHat).classList.remove("selectedAsset")
        document.querySelector("#hat" + hatDefault).classList.add("selectedAsset")

        setSelectedForm(formDefault)
        setSelectedColor(colorDefault)
        setSelectedHat(hatDefault)
    }

    const handleClose = () => {
        if (selectedColor !== avatar.color || selectedHat !== avatar.hat || selectedForm !== avatar.form) {
            setShowModal(true)
        } else {
            setShowEdit(false)
        }
    }

    const handleConfirmModal = () => {
        setShowEdit(false)
        setShowModal(false)
    }

    const handleCancelModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        document.querySelector("#form" + selectedForm).classList.add("selectedAsset")
        document.querySelector("#hat" + selectedHat).classList.add("selectedAsset")
        document.querySelector("#color" + selectedColor).classList.add("selectedAsset")

        setIsLargeScreen(bp.isTabletLandscapeOrLess())
    }, []);

    useEffect(() => {
        if(!isLargeScreen && !bp.isTabletLandscapeOrLess()){
            document.querySelector("#form" + selectedForm).classList.add("selectedAsset")
            document.querySelector("#hat" + selectedHat).classList.add("selectedAsset")
            document.querySelector("#color" + selectedColor).classList.add("selectedAsset")
            setIsLargeScreen(true)
        }else{
            setIsLargeScreen(false)
        }
    }, [bp.pageSize]);



    return bp.isTabletLandscapeOrLess() ?
        <div>
            <EditAvatarMobile
                avatar={avatar}
                selectedHat={selectedHat}
                selectedForm={selectedForm}
                selectedColor={selectedColor}
                handleClose={handleClose}
                handleChange={handleChange}
                handleReset={resetAvatar}
                handleRandom={randomAvatar}
                handleSave={handleSave}
            />
            <ConfirmModalAvatar showModal={showModal} handleConfirm={handleConfirmModal} handleCancel={handleCancelModal}/>
        </div>

        :<div>
        <div
            className={`fixed inset-0 flex items-center justify-center bg-grey/60 backdrop-blur-[1px] z-[210]`}
        >
            <div>
                <div className={"grid grid-cols-1 place-items-center"}>
                    <div className=" w-[525px] h-[525px] z-70 flex justify-center items-center relative">
                        <button className={"absolute w-40 h-40 right-0 top-0 bg-white/50 rounded-full"}
                                onClick={handleClose}>
                            <CloseIcon/>
                        </button>
                        <div className={"w-full h-full absolute bg-bg-white rounded-full"}>
                            <div
                                className={"w-full h-full absolute bg-[#A929D626] border-[1px] border-contrast-purple rounded-full"}>
                            </div>
                        </div>

                        <div
                            className={'absolute bg-[#A929D626] border-[1px] border-contrast-purple w-370 h-370 rounded-full '}>
                        </div>
                        <div
                            className={"relative w-370 h-370"}>
                            <div
                                className={"absolute w-full h-1/2 bg-[#d59eed] border-[1px] border-contrast-purple rounded-tl-full rounded-tr-full rotate-180 bottom-0"}>
                            </div>
                            <div
                                className={"absolute w-1/3 h-90 bg-[#d59eed] rounded-full top-[42%] rotate-[25deg] -left-1"}>
                            </div>
                            <div
                                className={"absolute w-1/3 h-90 bg-[#d59eed] rounded-full top-[42%] rotate-[-25deg] -right-1"}>
                            </div>
                        </div>
                        <div className={"w-180 h-180 bg-bg-white rounded-full absolute flex justify-center items-center"}>
                            <div className="w-130 relative mx-auto">
                                <div className="w-full">
                                    {selectForm(selectedForm, PaletteColors[selectedColor].avatar)}
                                </div>
                                <div className="absolute w-full top-0">
                                    {selectedHat !== "16" ? selectHat(selectedHat, PaletteColors[selectedColor].avatar) : null}
                                </div>
                                <div className="absolute w-full top-0">
                                    {selectEmote(avatar.emote)}
                                </div>
                            </div>
                        </div>


                        <div className={'absolute'}>
                            {innerWheel(selectedColor).map((item, index) => (
                                <div
                                    key={index}
                                    className="w-50 absolute"
                                    style={{
                                        transformOrigin: 'top left',
                                        transform: `rotate(${-((index * (360 / innerWheel(selectedColor).length)) + 43)}deg) translate(210%)`,
                                    }}
                                >
                                    <div className={"w-60 h-60 bg-bg-white rounded-full "}>
                                        <div id={item.category ? item.category + item.id : "color" + item.id}
                                             className={"w-60 h-60 rounded-full "}>
                                            <button
                                                onClick={() => handleChange(item.id, item.category)}
                                                className={"w-full h-full " + (item.category !== "form" && 'p-10')}
                                                style={{
                                                    transformOrigin: 'center',
                                                    transform: `rotate(${(index * (360 / innerWheel(selectedColor).length)) + 43}deg) ${index < 5 ? ' translateY(-8px)' : ' '}`,
                                                }}
                                            >
                                                {item.asset}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className={'absolute'}>
                            {HatWheelList.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-50 absolute"
                                    style={{
                                        transformOrigin: 'top left',
                                        transform: `rotate(${-((index * (360 / HatWheelList.length)) + 43)}deg) translate(380%)`,
                                    }}
                                >
                                    <div className={"w-60 h-60 bg-bg-white rounded-full"}>
                                        <div id={item.category + item.id}
                                             className={"w-60 h-60 bg-bg-white rounded-full relative " + (index > 4 ? 'p-10' : '')}>

                                            {(item.id !== "16" && item.id !== "15") ?
                                                <button onClick={() => handleChange(item.id, item.category)}
                                                        className={"w-full"}
                                                        style={{
                                                            transformOrigin: 'center',
                                                            transform: `rotate(${(index * (360 / HatWheelList.length)) + 43}deg) scale(1.2) translateY(8px)`,
                                                        }}>
                                                    {item.asset}
                                                </button>
                                                : (item.id === "15") ?
                                                    <button
                                                        onClick={() => handleChange(item.id, item.category)}
                                                        className={"w-full relative"}
                                                        style={{
                                                            transformOrigin: 'center',
                                                            transform: `rotate(${(index * (360 / HatWheelList.length)) + 43}deg) scale(1.2)`,
                                                        }}>
                                                        <div className="w-full">
                                                            {<A1
                                                                style={{
                                                                    fill: (PaletteColors[selectedColor].avatar + "55"),
                                                                }}/>}
                                                        </div>
                                                        <div className={"absolute top-0 left-0 w-full h-full"}>
                                                            {<H15 style={{fill: PaletteColors[selectedColor].avatar}}/>}
                                                        </div>


                                                    </button>
                                                    : <button
                                                        onClick={() => handleChange(item.id, item.category)}
                                                        className={"w-full"}
                                                        style={{
                                                            transformOrigin: 'center',
                                                            transform: `rotate(${(index * (360 / HatWheelList.length)) + 43}deg) `,
                                                        }}>
                                                        {item.asset}
                                                    </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                    <div className={"w-full grid grid-cols-3 gap-10 place-content-center place-items-center mt-20"}>
                        <button onClick={resetAvatar}
                                className={" px-30 py-5 bg-purple text-white font-semibold rounded-lg "}>
                            {_.toUpper(t("employee.dashboard.reset"))}
                        </button>

                        <button
                            onClick={handleSave}
                            className={" px-50 py-10 bg-purple text-white font-semibold rounded-lg "}>
                            {_.toUpper(t("employee.dashboard.save"))}
                        </button>
                        <button onClick={randomAvatar}>
                            <EbloomTooltip placement={"top"} text={_.upperFirst(t("employee.dashboard.randomize"))}>
                                <div className={"bg-bg-light-purple w-55 h-55 flex justify-center items-center rounded-full text-center border-4 border-purple"}>
                                    <Dice/>
                                </div>
                            </EbloomTooltip>
                        </button>
                    </div>
            </div>
        </div>
        <ConfirmModalAvatar showModal={showModal} handleConfirm={handleConfirmModal} handleCancel={handleCancelModal}/>
    </div>;


}

EditAvatar.propTypes= {
    setShowEdit : PropTypes.func.isRequired,
    showEdit : PropTypes.bool.isRequired,
    avatar : PropTypes.object.isRequired,
    setAvatar : PropTypes.func.isRequired
}

export default EditAvatar