import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../lang/translations";
import {replaceUrlsWithLinks} from "../../../../Utils/Global";


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        percent > 0 ?
            <text x={x} y={y} fill={"white"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
            :
            <span/>
    );
};

const COLORS = ["#5D00A2", "#07B7E3", "#B92CEC", "#A1E5F6", "#CCCCCC", "#F0F0F0"];

const EbloomPieChartV2 = (props) => {

    const {data, lang} = props;

    const t = useTranslation();

    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <PieChart width={400} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={130}
                    fill="#8884d8"
                    dataKey="selected"
                    isAnimationActive={false}
                >
                    {data && data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Legend className={"flex justify-center items-center"} layout={"vertical"} align={"right"} verticalAlign={"middle"} iconType={"circle"} formatter={(value, entry, index) => {
                   return entry.payload.payload.payload ? entry.payload.payload.payload.text === "Other" ?
                       _.upperFirst(getTranslations(lang,"webloom.other")) : <div className={" max-w-150 truncate"}>{replaceUrlsWithLinks(entry.payload.payload.payload.text)}</div> :""}
                }/>
            </PieChart>
        </ResponsiveContainer>
    );

}

EbloomPieChartV2.propsType = {
    data:PropTypes.array.isRequired
}

export default EbloomPieChartV2;
