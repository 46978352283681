import React, {useState, useEffect, useContext, useMemo} from "react";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {Col, ListGroup, Row} from "react-bootstrap";
import SettingModel from "../../../../models/Setting";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {useUserData} from "../../../../hooks/userHook";
import ProductTourMap from "../../ProductTour/ProductTourMap";
import {
    getNotifHour,
    getUrl,
    isNullOrUndefined,
    PurpleSwitch,
    SettingTypes,
    TourTypePackages
} from "../../../../Utils/Global";
import EbloomTimePicker from "../../../Core/Modules/Input/EbloomTimePicker";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import EbloomWindowPicker from "../../../Core/Modules/Input/EbloomWindowPicker";
import EbloomInfoTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";

const Account = (props) => {

    const [settings, setSettings] = useState([]);

    const [countTourDone, setCountTourDone] = useState(0);

    const [switchReminder, setSwitchReminder] = useState(false);
    const [switchHolidays, setSwitchHolidays] = useState(false);
    const [switchReminderMorning, setSwitchReminderMorning] = useState(false);
    const [switchReminderMiddle, setSwitchReminderMiddle] = useState(false);
    const [switchReminderLastDay, setSwitchReminderLastDay] = useState(false);
    const [switchTranslation, setSwitchTranslation] = useState(false);
    const [timePicker, setTimePicker] = useState("");
    const [window, setWindow] = useState(3);

    const {companyToEdit,selectedEntity} = props;

    const {tourActivated,fetchData} = useContext(ProductTourContext)

    const t = useTranslation();

    const user = useUserData();

    const isSuperAdmin = user.roles.includes("global_super_admin");

    const companyName = companyToEdit ? companyToEdit.name : isSuperAdmin && selectedEntity === user.companyId ? user.company : user.companyGroup ? user.companyGroup.name : user.company;

    const order = {maxUser:1,credits:2,nbCb:3,minTeamMember:4, publicHoliday:5, notifTime:6, window:7,reminder:8,reminderMorning:9, reminderMiddle:10, reminderLastDay:11,mailSystem:12,productTour:13,translation:14}


    const handleSwitch = (el) => (event) =>  {
        const value = event.target.checked;
        const time = event.target.value

        const element = {
            id : el.id,
            type : el.type,
            value : value ? 1 : 0
        }
        if(el.type === SettingTypes.REMINDER){
            let url = "/settings/update/reminder"
            if(selectedEntity){
                url = "/settings/group/update/reminder/"+selectedEntity
            }
            HttpApi.put(url,element).then(()=>{
                setSwitchReminder(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.REMINDER_MORNING){
            let url = "/settings/update/" + SettingTypes.REMINDER_MORNING
            if(selectedEntity){
                url = "/settings/group/update/reminderMorning/"+selectedEntity
            }
            HttpApi.put(url,element).then(()=>{
                setSwitchReminderMorning(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.PUBLIC_HOLIDAY){
            let url = "/settings/update/publicHoliday"
            if(selectedEntity){
                url = "/settings/group/update/publicHoliday/"+selectedEntity
            }
            HttpApi.put(url,element).then(()=>{
                setSwitchHolidays(value)
            }).catch(error => {
                notifyError(error)
            })
        }else if (el.type === SettingTypes.NOTIF_TIME){
            element.value= parseInt(time.replace(':', ''));
            let url = "/settings/update/notifTime"
            if(selectedEntity){
                url = "/settings/group/update/notifTime/"+selectedEntity
            }
            HttpApi.put(url,element).then(()=>{
                setTimePicker(time)
            }).catch(error => {
                notifyError(error)
            })
        }else if(el.type === SettingTypes.WINDOW){
            element.value = time;
            let url = "/settings/update/window"
            if(selectedEntity){
                url = "/settings/group/update/window/"+selectedEntity
            }
            HttpApi.put(url, element).then(() => {
                if(time === 3){
                   setSwitchReminder(true);
                   setSwitchReminderMorning(true);
                   setSwitchReminderMiddle(false);
                   setSwitchReminderLastDay(false);
                }else if(time === 5){
                    setSwitchReminder(true);
                    setSwitchReminderMorning(true);
                    setSwitchReminderMiddle(false);
                    setSwitchReminderLastDay(true);
                }else if(time === 10){
                    setSwitchReminder(true);
                    setSwitchReminderMorning(true);
                    setSwitchReminderMiddle(true);
                    setSwitchReminderLastDay(true);
                }
                setWindow(time);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.REMINDER_MIDDLE){
            let url = "/settings/update/reminderMiddle"
            if(selectedEntity){
                url = "/settings/group/update/reminderMiddle/"+selectedEntity
            }
            HttpApi.put(url, element).then(() => {
                setSwitchReminderMiddle(value);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.REMINDER_LAST){
            let url = "/settings/update/reminderLastDay"
            if(selectedEntity){
                url = "/settings/group/update/reminderLastDay/"+selectedEntity
            }
            HttpApi.put(url, element).then(() => {
                setSwitchReminderLastDay(value);
            }).catch(error => {
                notifyError(error);
            })
        }else if(el.type === SettingTypes.TRANSLATION){
            let url = "/settings/update/translation"
            if(selectedEntity){
                url = "/settings/group/update/translation/"+selectedEntity
            }
            HttpApi.put(url, element).then(() => {
                setSwitchTranslation(value);
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const fetchSettings = () => {
        let url =""
        if(isSuperAdmin && !isNullOrUndefined(selectedEntity)){
            url = "/settings/group/getAllForCompany/"+selectedEntity
        }else if(!isSuperAdmin){
           url = "/settings/getAllForCompany";
        }
        if((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    let listSettings = response.data.filter(el => SettingModel[el.type] && (SettingModel[el.type].packages).includes(user.module))
                    const showTranslation = listSettings.filter(el => el.type === SettingTypes.SHOW_TRANSLATION)[0]
                    if (showTranslation){
                        if(Boolean(showTranslation.value)){
                            if(listSettings.filter(el => el.type === SettingTypes.TRANSLATION)[0] === undefined){
                                listSettings.push({type: SettingTypes.TRANSLATION, value:0})
                            }
                        }else{
                            listSettings = listSettings.filter(el => el.type !== SettingTypes.TRANSLATION)
                        }
                    }

                    setSettings(listSettings);
                    const filteredEdit = listSettings.filter(el => [SettingTypes.REMINDER,SettingTypes.REMINDER_MORNING,SettingTypes.PUBLIC_HOLIDAY,SettingTypes.NOTIF_TIME, SettingTypes.REMINDER_MIDDLE, SettingTypes.REMINDER_LAST, SettingTypes.WINDOW,SettingTypes.TRANSLATION].includes(el.type))
                    filteredEdit.forEach(el => {
                        if(el.type === SettingTypes.NOTIF_TIME){
                            setTimePicker(getNotifHour(el).substring(0,5))
                        }
                        else if(el.type === SettingTypes.REMINDER_MORNING){
                            setSwitchReminderMorning(Boolean(el.value))
                        }else if(el.type === SettingTypes.PUBLIC_HOLIDAY){
                            setSwitchHolidays(Boolean(el.value))
                        }else if(el.type === SettingTypes.REMINDER){
                            setSwitchReminder(Boolean(el.value))
                        }else if(el.type === SettingTypes.WINDOW){
                            setWindow(el.value);
                        }else if(el.type === SettingTypes.REMINDER_MIDDLE){
                            setSwitchReminderMiddle(Boolean(el.value))
                        }else if(el.type === SettingTypes.REMINDER_LAST){
                            setSwitchReminderLastDay(Boolean(el.value))
                        }else if(el.type === SettingTypes.TRANSLATION){
                            setSwitchTranslation(Boolean(el.value))
                        }
                    })
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const excludedSettings = useMemo(() => {
        let list = [SettingTypes.PRODUCT_TOUR,SettingTypes.MAX_INVITE,SettingTypes.SHOW_TRANSLATION,SettingTypes.GOOGLE_REVIEWS,SettingTypes.COLORS]

        if(window === 5){
            list.push(SettingTypes.REMINDER_MIDDLE)
            return list
        }else if(window === 10){
            return list
        }
        list.push(...[SettingTypes.REMINDER_MIDDLE, SettingTypes.REMINDER_LAST])
        return list
    }, [window]);

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        fetchSettings();
    }, [companyToEdit,selectedEntity]);

    return(
        <>

            <CardDefault title={_.upperFirst(t('admin.account.account'))+" "+ companyName} showButton={false}>
                {
                    tourActivated && (selectedEntity === user.companyId || !isSuperAdmin) &&
                    <div className={'mb-50'}>
                        <span className={"ebloom-large-text ebloom-text font-semibold"}> {_.upperFirst(t("admin.account.configText"))+" " +Math.floor(countTourDone*100/TourTypePackages[user.module].length)}% </span>
                        <ProductTourMap setCount={setCountTourDone} {...props}/>
                    </div>
                }

                <div className={'mt-20'}>
                    <span className={"ebloom-large-text ebloom-text font-semibold"}>{_.upperFirst(t("admin.account.info"))}</span>
                    <Row>
                        {
                            [1,2].map(index => (
                                <Col key={index}>
                                    <ListGroup variant="flush">
                                        {
                                            settings?.filter(el => !excludedSettings.includes(el.type)).slice(index === 1 ? 0 : Math.ceil(settings.filter(el => !excludedSettings.includes(el.type)).length/2), index===1 ? Math.ceil(settings.filter(el => !excludedSettings.includes(el.type)).length/2) : settings.filter(el => !excludedSettings.includes(el.type)).length).sort((a,b) => order[a.type]-order[b.type]).map((setting, index) => (
                                                <ListGroup.Item className={"noBorder h-60"} key={index}>
                                                    <Row className={"ebloom-normal-text contrast-purple h-40 items-center"}>
                                                        <Col md={7}>
                                                            <div className={"flex items-center"}>
                                                                <div className={"mr-5"}>{_.upperFirst(t(SettingModel[setting.type].labelType))}</div>
                                                                {
                                                                    SettingModel[setting.type].tooltip &&
                                                                    <EbloomInfoTooltip text={_.upperFirst(t(SettingModel[setting.type].tooltip))} placement={"top"} size={"sm"}/>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col className={"font-weight-bold text-right"}>
                                                            {
                                                                (SettingModel[setting.type].editable) ?
                                                                    <div>
                                                                        {
                                                                            setting.type === SettingTypes.NOTIF_TIME ?
                                                                                <EbloomTimePicker color={'purple'} selectedHour={timePicker} handleChange={handleSwitch(setting)}/>
                                                                                :
                                                                                setting.type === SettingTypes.WINDOW ?
                                                                                    <EbloomWindowPicker value={window} handleChange={handleSwitch(setting)} color={"purple"}/>
                                                                                    :
                                                                                    <PurpleSwitch checked={setting.type === SettingTypes.REMINDER ? switchReminder : setting.type === SettingTypes.REMINDER_MORNING ? switchReminderMorning : setting.type === SettingTypes.REMINDER_MIDDLE ? switchReminderMiddle : setting.type === SettingTypes.REMINDER_LAST ? switchReminderLastDay :setting.type === SettingTypes.TRANSLATION ? switchTranslation :  switchHolidays} onClick={handleSwitch(setting)} />
                                                                        }
                                                                    </div>
                                                                    :
                                                                    typeof SettingModel[setting.type].labelValue(setting.value) === "string" ? _.upperFirst(t(SettingModel[setting.type].labelValue(setting.value))) : SettingModel[setting.type].labelValue(setting.value)
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))
                                        }
                                    </ListGroup>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </CardDefault>
        </>
    )
}

export default Account;