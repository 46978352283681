

export const Colors = {
    EBLOOM_WHITE_BLUE:"#EFF9FE",
    EBLOOM_TRANSPARENT_BLUE:"#F0F9FF",
    EBLOOM_BG_BLUE:"#BFECFF",
    EBLOOM_CONTRAST_BLUE:"#00A6EA",
    EBLOOM_LOGO_BLUE:"#00C2EA",
    EBLOOM_BG_GREY:"#F2F2F2",
    EBLOOM_CONTRAST_PURPLE:"#5D00A2",

    EBLOOM_DISABLED_GREY:"#C4C4C4",

    EBLOOM_PURPLE: "#B32DDD",

    EBLOOM_STRONG_RED: "#EA1C23",
    EBLOOM_LIGHT_RED: "#E56E1F",
    EBLOOM_NEUTRAL: "#FDDB01",
    EBLOOM_LIGHT_GREEN: "#9CC333",
    EBLOOM_STRONG_GREEN: "#37AC41",

    EBLOOM_WORK:"#C4E4FF",
    EBLOOM_MANAGEMENT: "#F5E1FD",
    EBLOOM_TEAM:"#D4FBF2",
    EBLOOM_POLICY:"#E1E1ED",
    EBLOOM_ADHESION:"#FDF2D1",


};