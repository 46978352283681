import React, {useEffect} from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer, Cell, LabelList,
} from 'recharts';
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {Colors} from "../../../../Utils/Colors";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";


const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 35;
    const smiley = value >= 80 ? "smiley_happy.png" : value >= 60 ? "smiley_glad.png" : value >= 40 ? "smiley_poker-face.png" : value >= 20 ? "smiley_sad.png" : value > 0 ? "smiley_unhappy.png" : "smiley_anonymous.png";
    if(value !== null && value < 100){
        return (
            <image x={x + width / 4} y={y - radius} href={"/img/" + smiley} width={30} height={30} />
        );
    }

};

const CustomTooltip = ({ active, payload, label, overBar, nbCategories, t,isModelCustomEnable}) => {
    if (active && payload && payload.length) {
        return (
            <div style={{backgroundColor:"#FFFFFF", border:"0.2px solid grey", padding:5}}>
                <p>{label}</p>
                {
                    (payload.length > 1 && nbCategories === 1 && !overBar) ?
                    <p>{`${payload[1].name} : ${payload[1].value}`}</p>
                        :
                        (payload.length > 1 && nbCategories === 1 && overBar) ?
                            <div>
                                <p>{`${payload[0].name} : ${payload[0].value}`}</p>
                                <p>{`${_.upperFirst(t("manager.dashboard.nbAnswersSmall"))} : ${overBar}`}</p>
                            </div>
                            :
                            payload.map((p, index) => (
                                <p key={index}>{`${p.name} : ${p.value}`}</p>
                            ))
                }
            </div>
        );
    }

    return null;
};


const EbloomEvolutionChart = (props) => {

    const [evolutionData, setEvolutionData] = React.useState([]);
    const [overBar, setOverBar] = React.useState(null);
    const {displayCategories,companyToEdit,isModelCustomEnable,commonSurveyActivated} = props;

    const fetchData = () => {
        const data = displayCategories.length === 1 ? displayCategories[0].id : 0;
        const url = "/feedbacks/getCategoryEvolution/"+data+"/"+ (!!commonSurveyActivated ? commonSurveyActivated : false)
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setEvolutionData(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const checkDisplayId = (id) => {
        for(let i=0; i<displayCategories.length;i++){
            if(displayCategories[i].id === id){
                return true;
            }
        }
        return false;
    }

    useEffect(() => {fetchData()}, [displayCategories]);

    const t = useTranslation();

    const switchOverBarState = (state) => event =>{
        setOverBar(state);
    }


    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={evolutionData}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name"/>
                <YAxis/>
                <Legend />
                <Tooltip content={<CustomTooltip  overBar={overBar} nbCategories={displayCategories.length} t={t} />} />
                {
                    displayCategories.length === 1 &&
                    <Bar name={_.upperFirst(t("manager.dashboard.weekAvg"))} dataKey="weekAvg" barSize={60} fill="#413ea0">
                        <LabelList dataKey="weekAvg" position="top" content={renderCustomizedLabel} />
                        {
                            evolutionData.map((entry, index) => (
                                <Cell onMouseEnter={switchOverBarState(entry.nbCB)} onMouseLeave={switchOverBarState(null)} key={`cell-${index}`} fill={entry.weekAvg >= 80 ? Colors.EBLOOM_STRONG_GREEN : entry.weekAvg >= 60 ? Colors.EBLOOM_LIGHT_GREEN : entry.weekAvg >=40 ? Colors.EBLOOM_NEUTRAL : entry.weekAvg >= 20 ? Colors.EBLOOM_LIGHT_RED : Colors.EBLOOM_STRONG_RED}/>
                            ))
                        }
                    </Bar>

                }
                {
                    displayCategories.length > 1 &&
                    displayCategories.map(c => (
                        <Line type="monotone" name={displayCategories.length > 1 ? (isModelCustomEnable ? c.name : _.upperFirst(t("core.category."+c.name))) : _.upperFirst(t("manager.dashboard.totalAvg"))} dataKey={c.name} stroke={c.color} strokeWidth={2.5} />
                    ))
                }
            </ComposedChart>
        </ResponsiveContainer>
    );

}

export default EbloomEvolutionChart;
