import HttpApi from "../httpApi";
import {ModelTypes} from "./Types";

const Tag = {
    crud:{
        create:"/tags/create",
        update:"/tags/update",
        delete:"/tags/delete"
    },

    initializeOptions: () => {
        HttpApi.getV2( "/categories/getMainCategories").then(response => {
            if (response && response.data) {
                Tag.id_category.form.selectOptions.options = response.data;
            } else {
                Tag.id_category.form.selectOptions.options = null;
            }
        });
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"tag-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
        }
    },

    id_category:{
        id:"id_category",
        type:ModelTypes.NUMBER,
        label:"id_category",
        table:{
            show:false
        },
        list:{

        },
        form:{
            label:"core.category.category",
            show:true,
            id:"id_category",
            select:true,
            selectOptions:{
                options:null,
                showTextField:false,
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_category",
        }
    },

    category:{
        id:"category",
        type:ModelTypes.TEXT,
        label:"core.category.category",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Tag;