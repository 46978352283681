import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Col, Row} from "react-bootstrap";
import ConfirmModal from "./ConfirmModal";
import {useTranslation} from "react-multi-lang";
import PropTypes from "prop-types";
import {GENERIC_URL_PRICE_IT, LIST_FEATURES_FREE} from "../../../../Utils/Global";
import priceIt from "../../symbols/priceIt.jpg";
import Divider from "@mui/material/Divider";
import {getLanguage} from "react-multi-lang/lib";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem/ListItem";
import { ReactComponent as ValidateIcon} from "../../symbols/general/Validate.svg";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";

const ProspectModal = (props) => {
    const [showFreeTrialForProspect, setShowFreeTrialForProspect] = useState(false);


    const {show, handleClose,isLogin,showFree} = props;
    const t = useTranslation();

    const urlPriceIt = GENERIC_URL_PRICE_IT

    const fetchSettingShowFreeTrial = () => {
        HttpApi.get("/setting/showFreeTrial/getForCompany").then(response => {
            if(response.data === null || response.data.value === 0){
                setShowFreeTrialForProspect(false)
            }else{
                setShowFreeTrialForProspect(true)
            }
        }).catch(e => {
            notifyError(e)
        })
    }
    useEffect(() => {
        if (!isLogin){
            fetchSettingShowFreeTrial()
        }

    }, []);
    return <ConfirmModal size={"lg"} handleClose={handleClose} show={show} title={isLogin ? _.upperFirst(t('prospect.expiredAccount')) : showFreeTrialForProspect ? _.upperFirst(t('free.premiumAvailable')) : _.upperFirst(t('free.premiumAvailable'))} showButtons={false}>

        {
            (showFreeTrialForProspect || showFree) ?
                <div>
                    <div className={"text-center"}>
                        <p className={"font-semibold text-purple"}>{_.upperFirst(t("free.premiumText"))}</p>
                        <div className={"pointer text-center"} >
                            <div onClick={() => {
                                window.open(urlPriceIt, '_blank')
                            }} >
                                <Button className={"ebloom-btn-purple mt-10"}>
                                    {_.upperFirst(t("free.getQuote"))}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Divider className={"my-20"}>{_.upperCase(t("prospect.or"))}</Divider>
                    <div className={"text-center"}>
                        <p className={"font-semibold text-contrast-blue"}>{_.upperFirst(t("prospect.freeTitle"))}</p>
                        <Row className={"text-left mx-auto"}>
                            {
                                [0, 1].map(index => (
                                    <Col key={index}>
                                        <List className={"flex flex-col  h-full"}>
                                            {
                                                LIST_FEATURES_FREE.slice(index === 0 ? 0 : Math.floor(LIST_FEATURES_FREE.length / 2), index === 0 ? Math.floor(LIST_FEATURES_FREE.length / 2) : LIST_FEATURES_FREE.length).map(el => (
                                                    <ListItem className={"flex items-start"}>
                                                        <div className={"flex justify-center items-center"}>
                                                            <ValidateIcon
                                                                className={"contrast-blue-icon w-16 h-16 mt-4"}/>
                                                        </div>

                                                        <p className={"ml-10"}>{_.upperFirst(t('prospect.' + el))}</p>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>

                                    </Col>
                                ))
                            }
                        </Row>
                        <Button
                            onClick={() => window.open("https://www.ebloom-clicknbloom.com/createAccount?lang=" + getLanguage(), '_blank').focus()}
                            className={"ebloom-btn-contrast-blue mt-10"}>{_.upperFirst(t("prospect.freeButton"))}</Button>
                    </div>
                </div>
                :
                <div>
                    <p>{_.upperFirst(t("free.premiumText"))}</p>
                    <div className={"pointer text-center"} onClick={() => {
                        window.open(urlPriceIt, '_blank').focus()
                    }}>
                        <img src={priceIt} alt={"pricing"}/>
                        <Button className={"ebloom-btn-purple mt-10"}>{_.upperFirst(t("free.getQuote"))}</Button>
                    </div>
                </div>

        }

    </ConfirmModal>
}


ProspectModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    showForm:PropTypes.bool,
    showFree:PropTypes.bool,
    isLogin:PropTypes.bool,
    title:PropTypes.string
}

ProspectModal.defaultProps = {
    text:"free.premiumText",
    showForm:true,
    isLogin:false,
    showFree:false,
    title:"free.premiumAvailable"
}

export default ProspectModal