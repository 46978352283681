import {useBreakpoints} from "../../../../hooks/breakpointsHook";
import PropTypes from "prop-types";
import EbloomInfoTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";

const MyBloomDoubleContainer = (props) => {

    const {title1, title2} = props

    const bp = useBreakpoints()
    const t = useTranslation("employee.dashboard")

    return bp.isTabletLandscapeOrLess() ?
        <div className={'rounded-md border-1 border-contrast-purple relative bg-bg-white z-0 w-full mt-40'}>
            <div className={"flex flex-col divide-y-2 divide-contrast-purple/60 mx-20 mt-20"}>
                <div className={"w-full relative mb-20"}>
                    <div
                        className={'bg-contrast-purple rounded-md px-30 p-5 absolute left-0 right-0 mx-auto max-w-fit -top-35'}>
                        <h1 className={'font-semibold text-white text-center mx-auto'}>{title1}</h1>
                    </div>
                    <div className={"mt-15"}>
                        {props.children[0]}
                    </div>
                </div>
                <div className={"w-full relative"}>
                    <div className={"absolute right-10 top-10"}>
                        <EbloomInfoTooltip text={_.upperFirst(t("myScoresHelper"))} placement={"bottom"}/>
                    </div>
                    <div className={'mt-40 mx-auto relative w-full'}>
                        {props.children[1]}
                    </div>

                </div>
            </div>
        </div>
        :
        <div className={""}>
            <div className={'rounded-md border-1 border-contrast-purple relative bg-bg-white z-0 w-full h-360'}>
                <div className={"absolute right-10 top-10"}>
                    <EbloomInfoTooltip text={_.upperFirst(t("myScoresHelper"))} placement={"bottom"}/>
                </div>
                <div className={`flex divide-x-2 divide-contrast-purple/60 my-20 h-320`}>
                    <div className={"w-1/2 relative h-full"}>
                        <div
                            className={'bg-contrast-purple rounded-md px-30 p-5 absolute left-0 right-0 mx-auto max-w-fit -top-35'}>
                            <h1 className={'font-semibold text-white text-center mx-auto'}>{title1}</h1>
                        </div>
                        <div className={"mt-15"}>
                            {props.children[0]}
                        </div>

                    </div>

                    <div className={"w-1/2 relative "}>
                        <div
                            className={'bg-contrast-purple rounded-md px-30 p-5 absolute left-0 right-0 mx-auto max-w-fit -top-35'}>
                            <h1 className={'font-semibold text-white text-center mx-auto'}>{title2}</h1>
                        </div>

                        <div className={'mt-30 mx-auto relative w-full '}>
                            {props.children[1]}
                        </div>

                    </div>
                </div>
            </div>
        </div>
}
MyBloomDoubleContainer.propTypes={
    title1 : PropTypes.string.isRequired,
    title2 : PropTypes.string.isRequired
}
export default MyBloomDoubleContainer