import React from "react";
import DisplayAdminView from "../../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import WeBloomCreatePoll from "./WeBloomCreatePoll";

const WeBloomCreatePollPage = (props) => {

    return(
        <DisplayAdminView pageName={"core.collaborative.poll"} menu={"noMenu"} selectedIndex={9} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomCreatePoll {...props}/>
                    </Container>

                )
            }
        </DisplayAdminView>
    )
}

export default WeBloomCreatePollPage;