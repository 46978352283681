import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = (props) => {
    const {colors,width,height} = props

    return (
        <svg style={{height : height, width: width}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <g>
                <g style={{fill: colors ? colors.secondary : "#B32DDD"}}>
                    <path
                        d="m20,40C8.97,40,0,31.03,0,20S8.97,0,20,0s20,8.97,20,20-8.97,20-20,20Zm0-37.14C10.55,2.86,2.86,10.55,2.86,20s7.69,17.14,17.14,17.14,17.14-7.69,17.14-17.14S29.45,2.86,20,2.86Z"/>
                    <path
                        d="m27.14,28.57c-.37,0-.73-.14-1.01-.42l-14.29-14.29c-.56-.56-.56-1.46,0-2.02.56-.56,1.46-.56,2.02,0l14.29,14.29c.56.56.56,1.46,0,2.02-.28.28-.64.42-1.01.42h0Z"/>
                    <path
                        d="m12.86,28.57c-.37,0-.73-.14-1.01-.42-.56-.56-.56-1.46,0-2.02l14.29-14.29c.56-.56,1.46-.56,2.02,0,.56.56.56,1.46,0,2.02l-14.29,14.29c-.28.28-.64.42-1.01.42h0Z"/>
                </g>
            </g>
        </svg>
    );
};
CloseIcon.propTypes = {
    colors: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
};

CloseIcon.defaultProps = {
    color: null,
    width: 20,
    height: 20,
}
export default CloseIcon;
