import React from "react"
import DisplayAdminView from "../../../../Admin/Layouts/DisplayAdminView";
import WeBloomAnswerDashboard from "./WeBloomAnswerDashboard";
import {ReactComponent as HandIcon} from "../../../../Core/symbols/webloom/white/participation-white.svg";
import {ReactComponent as PollIcon} from "../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as IdeaboxIcon} from "../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as StarIcon} from "../../../../Core/symbols/webloom/white/star-white.svg";
import {ReactComponent as HeartIcon} from "../../../../Core/symbols/webloom/white/heart-white.svg";
import {ReactComponent as AnswerIcon} from "../../../../Core/symbols/webloom/white/answer-white.svg";
import {ReactComponent as MessageIcon} from "../../../../Core/symbols/webloom/white/message-white.svg";
import {Container} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const WeBloomAnswerDashboardPage = (props) => {

    const t = useTranslation();

    const {isMobile} = props;

    return (
        <DisplayAdminView menu={"noMenu"} pageName={"WeBloom"} selectedIndex={0} {...props}>
            {
                () => (
                    <div>
                        <div className={"w-full h-140 bg-logo-blue -mt-50 mb-50 flex justify-between items-center"}>
                            <div className={"w-1/4 h-full"}>
                                <div className={"relative w-full h-full"}>
                                    <AnswerIcon className={isMobile ? "w-25 h-25 opacity-icon absolute" : "w-50 h-50 opacity-icon absolute"} style={{left:"14%", bottom:"50%"}}/>
                                    <MessageIcon className={isMobile ? "w-22 h-22 opacity-icon absolute" : "w-45 h-45 opacity-icon absolute"} style={{left:"28%", bottom:"10%"}}/>
                                    <StarIcon className={isMobile ? "w-12 h-12 opacity-icon absolute" : "w-25 h-25 opacity-icon absolute"} style={{left:"42%", bottom:"40%"}}/>
                                    <IdeaboxIcon className={isMobile ? "w-27 h-27 opacity-icon absolute" : "w-55 h-55 opacity-icon absolute"} style={{left:"56%", bottom:"10%"}}/>
                                    <PollIcon className={isMobile ? "w-25 h-25 opacity-icon absolute" : "w-50 h-50 opacity-icon absolute"} style={{left:"70%", bottom:"50%"}}/>
                                    <HeartIcon className={isMobile ? "w-15 h-15 opacity-icon absolute" : "w-30 h-30 opacity-icon absolute"} style={{left:"84%", bottom:"10%"}}/>
                                    <HandIcon className={isMobile ? "w-22 h-22 opacity-icon absolute" : "w-45 h-45 opacity-icon absolute"} style={{left:"100%", bottom:"33%"}}/>
                                </div>
                            </div>
                            <div className={"w-2/4 text-center"}>
                                <div className={"ebloom-title ebloom-normal-title font-semibold text-white"}>WeBloom</div>
                                <div className={"ebloom-title ebloom-small-title text-white"}>{_.upperFirst(t("webloom.webloomTitle"))}</div>
                            </div>
                            <div className={"w-1/4 h-full"}>
                                <div className={"relative w-full h-full"}>
                                    <AnswerIcon className={isMobile ? "w-25 h-25 opacity-icon absolute" : "w-50 h-50 opacity-icon absolute"} style={{right:"14%", bottom:"50%"}}/>
                                    <MessageIcon className={isMobile ? "w-22 h-22 opacity-icon absolute" : "w-45 h-45 opacity-icon absolute"} style={{right:"28%", bottom:"10%"}}/>
                                    <StarIcon className={isMobile ? "w-12 h-12 opacity-icon absolute" : "w-25 h-25 opacity-icon absolute"} style={{right:"42%", bottom:"40%"}}/>
                                    <IdeaboxIcon className={isMobile ? "w-27 h-27 opacity-icon absolute" : "w-55 h-55 opacity-icon absolute"} style={{right:"56%", bottom:"10%"}}/>
                                    <PollIcon className={isMobile ? "w-25 h-25 opacity-icon absolute" : "w-50 h-50 opacity-icon absolute"} style={{right:"70%", bottom:"50%"}}/>
                                    <HeartIcon className={isMobile ? "w-15 h-15 opacity-icon absolute" : "w-30 h-30 opacity-icon absolute"} style={{right:"84%", bottom:"10%"}}/>
                                    <HandIcon className={isMobile ? "w-22 h-22 opacity-icon absolute" : "w-45 h-45 opacity-icon absolute"} style={{right:"100%", bottom:"33%"}}/>
                                </div>
                            </div>
                        </div>
                        <Container>
                            <WeBloomAnswerDashboard {...props}/>
                        </Container>
                    </div>
                )
            }
        </DisplayAdminView>
    )

}

export default WeBloomAnswerDashboardPage;