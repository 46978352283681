import React, {useState, useEffect, useContext} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import PollFrame from "../../Polls/Modules/PollFrame";
import {PollTypes, SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import {Button, Col, Row} from "react-bootstrap";
import {
    FullContrastBlueTextField,
    uploadFile,
    TourType,
    Roles,
    CompanyTypes,
    displayDate
} from "../../../../../../Utils/Global";
import _ from "lodash";
import EbloomTimePicker from "../../../../../Core/Modules/Input/EbloomTimePicker";
import {ReactComponent as IdeaboxIcon} from "../../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {getLanguage} from "react-multi-lang";
import WeBloomTeamPicker from "../../Modules/WeBloomTeamPicker";
import HttpApi from "../../../../../../httpApi";
import moment from "moment/moment";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import QuestionList from "../../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import SideForm from "../../../../../Core/Modules/Views-Tables-Lists/SideForm";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import AnswerIdeabox from "../../../../../Employee/Pages/WeBloom/Ideabox/Answer/Modules/AnswerIdeabox";
import {useNavigationTo} from "../../../../../../hooks/navigationHook";
import {useSavePage} from "../../../../../../hooks/savePageHook";
import FileInput from "../../../../../Core/Modules/Input/FileInput";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {ProductTourContext} from "../../../../../../Utils/Context/ProductTourContext";
import {useUserData} from "../../../../../../hooks/userHook";
import EbloomSelectLang from "../../../../../Core/Modules/Input/EbloomSelectLang";
import FreeTrialModal from "../../../../../Core/Modules/Modal/FreeTrialModal";
import Checkbox from "@mui/material/Checkbox";
import {selectBubbleIcon} from "../../../Categories/Assets/CategoriesAssets";
import ProspectModal from "../../../../../Core/Modules/Modal/ProspectModal";
import WeBloomEntitiesPicker from "../../Modules/WeBloomEntitiesPicker";
import WebloomDatePicker from "../../../../../Core/Modules/Input/WebloomDatePicker";

const WeBloomCreateIdeabox = (props) => {

    //state
    const [isPreview, setIsPreview] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [showModalSend, setShowModalSend] = useState(false);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);


    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(SurveyStatus.DRAFT);
    const [team, setTeam] = useState(-1);
    const [lang, setLang] = useState(getLanguage());
    const [question, setQuestion] = useState({id:null, id_question:null, text:"", category:null, icon:null,color:null});
    const [targets, setTargets] = useState([]);
    const [recipients, setRecipients] = useState("allCurrentAndFutureUsers");
    const [attachmentUrl, setAttachmentUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [hasEndedFreeTrial, setHasEndedFreeTrial] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [showProspectModal, setShowProspectModal] = useState(false);

    const [hasDeletedQuestion, setHasDeletedQuestion] = useState(false);
    const [hasNewQuestion, setHasNewQuestion] = useState(false);
    const [hasModifiedRecipients, setHasModifiedRecipients] = useState(false);
    const [sendNotification, setSendNotification] = useState(false);

    const [isGroup, setIsGroup] = useState(false);

    //hooks
    const t = useTranslation();
    const location = useLocation();
    const navigation = useNavigationTo();
    const page = useSavePage(true);
    const params = useParams()
    const user = useUserData();

    //context
    const {validateOutcome,tourActivated} = useContext(ProductTourContext)

    const isProspect = user.companyType === CompanyTypes.PROSPECT
    const isDraft =  null !== id;

    //getters
    const fetchQuestions = () => {
        setLoadingQuestions(true);
        let url = "questions/getAllWithLastUsed"
        if(isGroup){
            url = "questions/group/getAllWithLastUsed/"+user.companyGroup.id
        }
        HttpApi.get(url).then(response => {
            if(response && response.data){
                setQuestions(response.data);
            }else{
                setQuestions([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingQuestions(false));
    }

    const reloadSurvey = (id) => {
        let url = isGroup ? "/webloom/getSurvey/"+ id + '/' + user.companyGroup.id : "/webloom/getSurvey/" + id
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setId(response.data.id);
                initiateSurvey(response.data);
            }else{
                navigation.forceTo(isGroup ? "/group/webloom/dashboard" : "/admin/webloom/dashboard");
            }
        });
    }

    const fetchEndDate = () => {
        if(user.module === "free"){
            HttpApi.getV2("/companies/getEndDate").then(response => {
                if(response && response.data){
                    setHasEndedFreeTrial(moment(response.data.end_date).isSameOrBefore(moment()));
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    //posters
    const saveSurvey = (newStatus) => {
        validateSurvey(newStatus === SurveyStatus.DRAFT).then(() => {
            const adaptedQuestion = question.id_question ? {id:question.id, id_question:question.id_question, type:PollTypes.OPEN_QUESTION} : {id:question.id, title:question.text, type:PollTypes.OPEN_QUESTION};
            const data = isDraft ? {
                id: id,
                title:title,
                questions: [adaptedQuestion],
                status: newStatus,
                users: team === -1 ? "all" : targets,
                recipients: recipients,
                start_date: startDate,
                end_date: endDate,
                lang: lang,
                type:SurveyTypes.IDEABOX,
                attachment_url:attachmentUrl,
                image_url:imageUrl,
                logo_url: logoUrl,
                id_company : isGroup ? user.companyGroup.id : user.companyId,
                send_notification:SurveyStatus.RUNNING !== status ? true : sendNotification
            } : {
                questions: [adaptedQuestion],
                title:title,
                status: newStatus,
                users: team === -1 ? "all" : targets,
                recipients: recipients,
                start_date: startDate,
                end_date: endDate,
                lang: lang,
                type:SurveyTypes.IDEABOX,
                attachment_url:attachmentUrl,
                image_url:imageUrl,
                logo_url: logoUrl,
                id_company : isGroup ? user.companyGroup.id : user.companyId,
                send_notification:SurveyStatus.RUNNING !== status ? true : sendNotification
            };
            let url = isDraft ? "/webloom/update" : "/webloom/create";
            const HttpCall = isDraft ? HttpApi.put : HttpApi.postV2;
            if(isGroup){
                url += '/' + data.id_company;
            }
            HttpCall(url, data).then((response) => {
                page.save();
                if (newStatus !== SurveyStatus.DRAFT) {
                    if(tourActivated){
                        validateOutcome(TourType.LAUNCH_WEBLOOM)
                    }
                    notifySuccess(_.upperFirst(t((startDate ? "webloom.notification.surveyPlaned": "webloom.notification.surveySent"), {survey:t("webloom.ideabox")})))
                    navigation.forceTo(isGroup ? "/group/webloom/dashboard" : "/admin/webloom");
                }else{
                    notifySuccess(_.upperFirst(t("webloom.notification.surveySaved",{survey:t("webloom.ideabox")})));
                    const id_survey = id || response.data;
                    reloadSurvey(id_survey)
                }
            })
        }).catch(error => {
            createErrorNotification(_.upperFirst(t("core.notification.error")), error);
        })
    }

    //handlers
    const validateSurvey = (saveDraft) => {
        return new Promise((resolve, reject) => {
            if(!question || (!question.id_question && !question.text)){
                reject(_.upperFirst(t("webloom.notification.topicError")));
            }
            if(status !== SurveyStatus.RUNNING && startDate){
                if(moment(startDate).isSameOrBefore(moment())){
                    reject(_.upperFirst(t("webloom.notification.futurePollError")));
                }else if(moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0){
                    reject(_.upperFirst(t("webloom.notification.startHourError")));
                }
            }
            if(team !== -1 && targets.length < 5 && !saveDraft){
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.ideabox")})))
            }else if(team !== -1 && user.companyId === 177 && targets.length < 20 && !saveDraft){//GSK limite à 20
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.ideabox")})))
            }
            resolve(true);
        })
    }
    const handleOpenResetModal = () => {
        setShowResetModal(true);
    }

    const handleCloseResetModal = () => {
        setShowResetModal(false);
    }

    const handleOpenModalSend = () => {
        validateSurvey(false).then(() => {
            setShowModalSend(true);
        }).catch(error => {
            createErrorNotification(_.upperFirst(t("core.notification.error")), error);
        })

    }

    const handleCloseModalSend = () => {
        setShowModalSend(false);
    }

    const handleReset = () => {
        setTitle(null);
        setStartDate(null);
        setEndDate(null);
        setTeam(-1);
        setLang(getLanguage());
        setQuestion({id:null, id_question:null, text:"", category:null,icon: null,color: null});
        setTargets([]);
        setRecipients("allCurrentAndFutureUsers");
        setImageUrl(null);
        setAttachmentUrl(null);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTeam = (team, targets, recipients, staySaved=false) => {
        if(team !== null){
            setTeam(team);
        }
        if(targets !== null){
            setTargets(targets);
        }
        if(recipients !== null){
            setRecipients(recipients);
        }
        if(page.isSaved && !staySaved){
            page.unSave();
        }

        if(SurveyStatus.RUNNING === status && hasModifiedRecipients === false){
            setHasModifiedRecipients(true);
        }
    }

    const handleAdd = (newQuestion) => event => {
        event.preventDefault();
        setQuestion(prevState => {
            let question = {...prevState};
            question.id_question = newQuestion.id;
            question.text = newQuestion.text;
            question.category = newQuestion.name;
            question.icon = newQuestion.icon
            question.color = newQuestion.color
            return question;
        });
        setSideFormOpen(false);
        if(page.isSaved){
            page.unSave();
        }

        if(SurveyStatus.RUNNING === status && hasNewQuestion === false){
            setHasNewQuestion(true);
        }
    }

    const handleQuestion = (event) => {
        setQuestion(prevState => {
            let question = {...prevState};
            question.id_question = null;
            question.text = event.target.value;
            question.category = null;
            question.icon = null;
            question.color = null;
            return question;
        })
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleRemoveQuestion = () => {
        setQuestion(prevState => {
            let question = {...prevState}
            question.id_question = null;
            question.text = null;
            question.category = null;
            question.icon = null;
            question.color = null;
            return question;
        })
        if(page.isSaved){
            page.unSave();
        }

        if(SurveyStatus.RUNNING === status && hasDeletedQuestion === false){
            setHasDeletedQuestion(true);
        }
    }

    const handleChangeStartDate = (date) => {
        //Change Hour Automatically to 12:00 if it's first time planning a start date
        if(!!date){
            if(!startDate){
                //Set new start date with new hour setting to 12:00
                let sd = moment(date).set("hour", 12).set("minute", 0).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }else{
                //Set new start date to previous hour setting
                const datetime = moment(startDate).format("HH:mm");
                let [hour, minutes] = datetime.split(":");
                let sd = moment(date).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }
        }else{
            setStartDate(date);
        }

        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeEndDate = (date) => {
        let sd = moment(date).endOf("day").format("YYYY-MM-DD HH:mm")
        if(!date){
            setEndDate(null);
        }else{
            setEndDate(new Date(sd));
        }
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTime = (event) => {
        let [hour, minutes] = event.target.value.split(":");
        let sd = moment(startDate).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
        setStartDate(new Date(sd));
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleLogo = (event) => {
        const file = event.target.files[0];
        if(file && !loadingLogo){
            setLoadingLogo(true);
            uploadFile(file).then((url) => {
                setLogoUrl(url)
                setLoadingLogo(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setLogoUrl(null);
                setLoadingLogo(false);
                notifyError(error);
            })
        }else{
            setLogoUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleAttachment = (event) => {
        const file = event.target.files[0];
        if(file && !loadingAttachment){
            setLoadingAttachment(true);
            uploadFile(file).then((url) => {
                setAttachmentUrl(url);
                setLoadingAttachment(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setAttachmentUrl(null);
                notifyError(error);
            })
        }else{
            setAttachmentUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleImage = (event) => {
        const file = event.target.files[0];
        if(file && !loadingImage){
            setLoadingImage(true);
            uploadFile(file).then((url) => {
                setImageUrl(url);
                setLoadingImage(false);
                notifySuccess("Image uploaded");
            }).catch(error => {
                setImageUrl(null);
                notifyError(error);
            })
        }else{
            setImageUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleDeleteImage = () => {
        setImageUrl(null);

        if(page.isSaved){
            page.unSave();
        }
    }

    const handleDeleteAttachment = () => {
        setAttachmentUrl(null);
        if(page.isSaved){
            page.unSave();
        }
    }

    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(true === open){
            fetchQuestions();
        }

        setSideFormOpen(open);
    };

    const handleLang = (event) => {
        setLang(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const displayRecipients = () => {
        switch (team){
            case -1:
                return _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"));
            case -3:
                return _.upperFirst(t("core.collaborative.allCurrentUsers"));
            case -2:
                return _.upperFirst(t("core.customized"));
            case -4:
                return null;
            default:
                return recipients
        }
    }

    const initiateSurvey = (survey) => {
        if(survey.title && Roles.BACKOFFICE.some(el =>user.roles.includes(el))){
            setTitle(survey.title);
        }
        if(survey.status !== SurveyStatus.RUNNING && survey.start_date && moment(survey.start_date).isBefore(moment())){
            setStartDate(null);
        }else{
            setStartDate(survey.start_date ? new Date(survey.start_date) : null);
        }
        if(survey.end_date && moment(survey.end_date).isSameOrBefore(moment())){
            setEndDate(null);
        }else{
            setEndDate(survey.end_date ? new Date(survey.end_date) : null);
        }
        if(survey.recipients !== "allCurrentAndFutureUsers"){
            setTargets(survey.users);
        }
        setTeam(survey.recipients === "allCurrentAndFutureUsers" ? -1 : survey.recipients === "allCurrentUsers" ? -3 : survey.recipients === "customized" ? -2 : survey.recipients);
        setRecipients(survey.recipients);
        const tmpQuestion = {
            id:survey.question.id,
            id_question:survey.question.id_question,
            category:survey.question.category,
            text:survey.question.text,
            icon : survey.question.id_question ? survey.question.icon : null,
            color : survey.question.id_question ? survey.question.color : null,
        }
        setQuestion(tmpQuestion);
        setLang(survey.lang);
        setAttachmentUrl(survey.attachment_url);
        setImageUrl(survey.image_url);
        setStatus(survey.status);
        setLogoUrl(survey.logo_url);
    }

    //useEffect

    useEffect(() => {
        fetchEndDate();
        setIsGroup(!!params.idGroup)
        if(location && location.state){
            let survey = location.state;
                const url = new URL(window.location.href);
                const duplicate = url.searchParams.get("duplicate");
                if(!duplicate){
                    setId(survey.id);
                }else{
                    if(SurveyStatus.RUNNING === survey.status || survey.status === SurveyStatus.DONE){
                        survey.status = SurveyStatus.DRAFT;
                    }
                    page.unSave();
                }
                initiateSurvey(survey);
        }
    }, [params])

    useEffect(() => {
        if(isProspect){
            page.save()
        }
    }, [page]);


    return (
        <>
            <PollFrame hideReset={status === SurveyStatus.RUNNING} type={SurveyTypes.IDEABOX} isPreview={isPreview} handlePreview={() => {setIsPreview(!isPreview)}} handleReset={handleOpenResetModal} status={SurveyStatus.DRAFT} isSaved={page.isSaved} isGroup={isGroup}>
                {
                    isPreview ?
                        <AnswerIdeabox survey={{question:question, start_date:startDate, end_date:endDate, attachment_url:attachmentUrl, image_url:imageUrl}}/>
                        :
                        <div>
                            <Row>
                                <Col md={4} className={"flex items-center justify-center"}>
                                    <IdeaboxIcon className={"h-90 w-90 contrast-blue-icon"}/>
                                </Col>
                                <Col md={8}>
                                    {
                                        Roles.BACKOFFICE.some(el =>user.roles.includes(el)) &&
                                        <Row className={"items-center mt-10 mb-10"}>
                                            <Col md={3}>
                                                <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.title"))}</span>
                                            </Col>
                                            <Col>
                                                <FullContrastBlueTextField variant={"outlined"} placeholder={_.upperFirst(t("core.title"))} onChange={handleChangeTitle} value={title} size={"small"}/>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        Roles.BACKOFFICE.some(el =>user.roles.includes(el)) &&
                                        <Row className={"items-center mt10 mb10"}>
                                            <Col md={3}>
                                                <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.login.lang"))}</span>
                                            </Col>
                                            <Col>
                                                <EbloomSelectLang handleChange={handleLang} value={lang} color="blue"/>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                      (user.roles.includes("global_backoffice")) &&
                                      <Row>
                                          <Col md={3}>
                                              <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("logo partenaire"))}</span>
                                          </Col>
                                          <Col className={"flex items-center"}>
                                              <FileInput className={"mr-5"} handleChange={handleLogo} showText={!logoUrl && !loadingLogo} iconColor={"contrast-blue"}/>
                                              {
                                                  loadingLogo ?
                                                    <LoadingView size={16}/>
                                                    :
                                                    logoUrl &&
                                                    <FileDisplay urlParams={logoUrl} handleDelete={() => {setLogoUrl(null)}} showIcon={false}/>
                                              }
                                          </Col>
                                      </Row>
                                    }
                                    {
                                        question.id_question &&
                                        <Row className={"items-center mt10 mb10"}>
                                            <p className={"text-contrast-blue"}>
                                                {_.upperFirst(t("core.sb.sbHelper"))}
                                            </p>
                                        </Row>
                                    }
                                    <Row className={"items-center mt-20 mb-30"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.topic"))}</span>
                                        </Col>
                                        <Col>
                                            {
                                                null === question || null === question.id_question ?
                                                    <div className={"flex items-center"}>
                                                        <FullContrastBlueTextField variant={"outlined"} placeholder={_.upperFirst(t("webloom.createTopic"))} onChange={handleQuestion} value={question.text} size={"small"} multiline/>
                                                        <span className={isProspect ? "text-disabled-grey underline mx-5 cursor-pointer" : "ebloom-link-blue mx-5"} onClick={isProspect ? () => setShowProspectModal(true) : toggleDrawer(true)}>{_.upperFirst(t("webloom.orSelectCB"))}</span>
                                                    </div>
                                                    :
                                                    <div className={"flex items-center cursorDefault"}>
                                                        <div className={"w-385"}>
                                                            <span className={"text-contrast-blue ebloom-text font-semibold "}>{question.text}</span>
                                                        </div>
                                                        <div className={"w-30 h-30 mx-5"}>
                                                            {selectBubbleIcon(question.icon,question.color)}
                                                        </div>
                                                        <span className={isProspect ? "text-disabled-grey underline mx-5 cursor-pointer" : "ebloom-link-blue mx-5"} onClick={isProspect ? () => setShowProspectModal(true) : toggleDrawer(true)}>{_.upperFirst(t("core.edit"))}</span>
                                                        <span className={isProspect ? "text-disabled-grey underline mx-5 cursor-pointer" : "ebloom-link-blue mx-5"} onClick={isProspect ? () => setShowProspectModal(true) : handleRemoveQuestion}>{_.upperFirst(t("core.remove"))}</span>
                                                    </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"items-start mt10 mb10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.dates"))}</span>
                                        </Col>
                                        <Col className={"flex justify-between items-center "}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "} >
                                                     <span
                                                         className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.start"))}</span>
                                                    <div className={"w-3/4"}>
                                                        {
                                                            status === SurveyStatus.RUNNING ?
                                                                <div
                                                                    className={"text-contrast-blue ebloom-text ebloom-small-text"}>{moment(startDate).format("DD/MM/YY HH:mm")}</div>
                                                                :
                                                                <div>
                                                                    <WebloomDatePicker date={startDate}
                                                                                       handleDate={handleChangeStartDate}/>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    startDate &&
                                                    <div
                                                        className={"w-full ebloom-link-blue text-center"}
                                                        onClick={() => {
                                                            handleChangeStartDate(null)
                                                        }}>{_.upperFirst(t("core.now"))}</div>
                                                }
                                            </Row>

                                        </Col>
                                        <Col className={"flex justify-between items-center flex-row"}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "}>
                                                    <span
                                                        className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.end"))}</span>
                                                    <div className={"w-3/4"}>
                                                        <div>
                                                            <WebloomDatePicker date={endDate}
                                                                               handleDate={handleChangeEndDate} fromDate={startDate} placeholder={_.upperFirst(t("core.never"))}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    endDate &&
                                                    <div className={"w-full text-center ebloom-link-blue"}
                                                         onClick={() => {
                                                             handleChangeEndDate(null)
                                                         }}>{_.upperFirst(t("core.never"))}</div>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        (null !== startDate && status !== SurveyStatus.RUNNING) &&
                                        <Row className={"items-center mt10 mb10"}>
                                            <Col md={3}>
                                                <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.hour"))}</span>
                                            </Col>
                                            <Col>
                                                <EbloomTimePicker handleChange={handleChangeTime} selectedHour={(moment(startDate).isBefore(moment()) || moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0) ? "select" : moment(startDate).format("HH:mm").toString()} isToday={moment(startDate).format("DD/MM/YY") === moment().format("DD/MM/YY")}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.collaborative.recipients"))}
                                                {
                                                    targets && targets.length > 0 &&
                                                    <span className={"ml-5"}>{"("+targets.length+")"}</span>
                                                }
                                            </div>
                                        </Col>
                                        <Col>
                                            {
                                                isGroup ?
                                                    <WeBloomEntitiesPicker handleChange={handleChangeTeam} team={team} targets={targets} />
                                                :
                                                    <WeBloomTeamPicker isProspect={isProspect} isDraft={isDraft} targets={targets} team={team} handleChange={handleChangeTeam}/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.attachment"))}</div>
                                        </Col>
                                        <Col className={"flex items-center"}>
                                            <FileInput className={"mr-5"} handleChange={handleAttachment} showText={!attachmentUrl} iconColor={"contrast-blue"}/>
                                            {
                                                attachmentUrl &&
                                                <FileDisplay urlParams={attachmentUrl} showIcon={false} handleDelete={handleDeleteAttachment}/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.image"))}</div>
                                        </Col>
                                        <Col className={"flex items-center"}>
                                            <FileInput className={"mr-5"} handleChange={handleImage} showText={!imageUrl} type={"image"} iconColor={"contrast-blue"} />
                                            {
                                                imageUrl &&
                                                <FileDisplay urlParams={imageUrl} showIcon={false} handleDelete={handleDeleteImage}/>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>
                }
                {
                    !isProspect &&
                    <div className={"flex items-center justify-end mt-20 mr-15"}>
                        {
                            (!page.isSaved && (null !== question && (null !== question.id_question || question.text)) && SurveyStatus.RUNNING !== status) &&
                            <span className={"ebloom-link-purple"} onClick={() => {saveSurvey(status)}}>{status === SurveyStatus.RUNNING ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.saveParam", {param:(Roles.BACKOFFICE.some( el =>  user.roles.includes(el)) ? "template" : t("core.collaborative.draft"))}))}</span>
                        }
                        {
                            (!Roles.BACKOFFICE.some( el =>  user.roles.includes(el)) ) &&
                            <Button size={'sm'} disabled={null === question || (null === question.id_question && !question.text)} className={"ebloom-btn-purple ml-10"} onClick={hasEndedFreeTrial ? () => setShowFreeTrialModal(true) : handleOpenModalSend}>{(startDate && status !== SurveyStatus.RUNNING) ? _.upperFirst(t("core.collaborative.schedule")) : (startDate && status === SurveyStatus.RUNNING) ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.send"))}</Button>

                        }
                    </div>
                }

            </PollFrame>
            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} showButtons={false} formTitle={"core.add"}>
                {
                    loadingQuestions ?
                        <LoadingView/>
                        :
                        <QuestionList questions={questions}  handleAction={handleAdd} buttonType={"add"} showLastUsed returnObject entityId={isGroup ? parseInt(params.idGroup) : null}/>
                }

            </SideForm>
            <ConfirmModal handleClose={handleCloseResetModal} show={showResetModal} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.resetParam", {param:t("webloom.ideabox")}))} handleChange={handleReset} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>{_.upperFirst(t("webloom.resetPollModal", {survey:t("webloom.theIdeabox")}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalSend} show={showModalSend} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t(SurveyStatus.RUNNING === status ? "core.updateParam" :"core.sendParam", {param:t("webloom.ideabox")}))} handleChange={() => {saveSurvey((startDate && status !== SurveyStatus.RUNNING) ? SurveyStatus.SCHEDULED : SurveyStatus.RUNNING)}} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                {
                    status !== SurveyStatus.RUNNING ?
                        <p><span>{startDate ?
                            _.upperFirst(t("webloom.sendPollModal", {recipients:displayRecipients(), startDate:moment(startDate).format("DD/MM/YY"), startHour:moment(startDate).format("HH:mm"), survey:t("webloom.theIdeabox")}))
                            :
                            _.upperFirst(t("webloom.sendPollModalNoStart", {recipients:displayRecipients(), survey:t("webloom.theIdeabox")}))
                        }</span> <span>
                    {
                        endDate ?
                            _.upperFirst(t("webloom.sendPollModalEndFem", {endDate:moment(endDate).format("DD/MM/YY")}))
                            : _.upperFirst(t("webloom.sendPollModalNoEndFem"))
                    }
                </span>
                        </p>
                        :
                        <div>
                            <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdated", {survey:t("webloom.thePoll")}))}</p>
                            {
                                hasNewQuestion &&
                                <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdatedAddQuestions"))}</p>
                            }
                            {
                                hasDeletedQuestion &&
                                <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdatedRemoveQuestions"))}</p>
                            }
                            <div className={"my-10 flex items-top justify-center"}>
                                <Checkbox className={"checkbox-purple p-0"} checked={sendNotification} onChange={() => {setSendNotification(!sendNotification)}}/>
                                <div className={"ml-5"}>{_.upperFirst(t(hasModifiedRecipients ? "webloom.sendPollModalUpdatedModifiedRecipientsCheckbox" :"webloom.sendPollModalUpdatedCheckbox", {recipients:displayRecipients()}))}</div>
                            </div>
                        </div>
                }

            </ConfirmModal>
            <ProspectModal show={showProspectModal} handleClose={() => setShowProspectModal(false)}/>
            <FreeTrialModal handleClose={() => setShowFreeTrialModal(false)} show={showFreeTrialModal} text={""}/>
        </>
    )
}

WeBloomCreateIdeabox.propTypes = {}

WeBloomCreateIdeabox.defaultProps = {}
export default WeBloomCreateIdeabox;