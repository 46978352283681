
export const defineRolesSteps = [
    {
        title: "admin.productTour.defineRoles.step1.title",
        content: "admin.productTour.defineRoles.step1.content",
        target: '.modal-content',
        placement: 'bottom',
        disableBeacon : true,
        spotlightPadding: 1,
        styles: {
            options : {
                zIndex : 1500
            }
        },
    },
    {
        title: "admin.productTour.defineRoles.step2.title",
        content: "admin.productTour.defineRoles.step2.content",
        target: '[data-roles=list]',
        placement: 'top',
        disableBeacon : true,
        disableNext : true,
        nextEmpty: true
    },
    {
        title: "admin.productTour.defineRoles.step3.title",
        content: "admin.productTour.defineRoles.step3.content",
        target: '[data-tour="cardSideForm"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step4.title",
        content: "admin.productTour.defineRoles.step4.content",
        target: '#roles-user',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step5.title",
        content: "admin.productTour.defineRoles.step5.content",
        target: '#roles-user',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step6.title",
        content: "admin.productTour.defineRoles.step6.content",
        target: '#roles-user',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step7.title",
        content: "admin.productTour.defineRoles.step7.content",
        target: '[data-tour="teams"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step8.title",
        content: "admin.productTour.defineRoles.step8.content",
        target: '[data-tour="teams_collab"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }

    },
    {
        title: "admin.productTour.defineRoles.step9.title",
        content: "admin.productTour.defineRoles.step9.content",
        target: '[data-tour="editUserBtn"]',
        placement: 'top',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }
    }

]