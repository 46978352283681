import React from "react";
import PropTypes from "prop-types"
import {FullContrastBlueTextField,FullPurpleTextField} from "../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import moment from "moment";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";

const EbloomTimePicker = (props) => {

    //state
    const {selectedHour, handleChange, isToday, color} = props;
    const hours = ["08:00", "08:15", "08:30", "08:45",
        "09:00", "09:15", "09:30", "09:45",
        "10:00", "10:15", "10:30", "10:45",
        "11:00", "11:15", "11:30", "11:45",
        "12:00", "12:15", "12:30", "12:45",
        "13:00", "13:15", "13:30", "13:45",
        "14:00", "14:15", "14:30", "14:45",
        "15:00", "15:15", "15:30", "15:45",
        "16:00", "16:15", "16:30", "16:45",
        "17:00", "17:15", "17:30", "17:45",
        "18:00", "18:15", "18:30", "18:45"]

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers
    const filterEl = (el) => {
        if(isToday){
            const hour = el.split(":")[0];
            const minute = el.split(":")[1];
            let d = new Date();
            d.setHours(hour);
            d.setMinutes(minute);
            if(moment(d).isSameOrBefore(moment())){
                return false;
            }
        }
        return true;
    }
    //useEffect


    return (
        color === 'blue' ?
            <FullContrastBlueTextField variant={"outlined"} select onChange={handleChange} value={selectedHour} size={"small"}>
                <MenuItem key={-1} disabled={true} value={"select"}>{_.upperFirst(t("core.select"))}</MenuItem>
                {
                    hours.filter(el => filterEl(el)).map((hour, index) => (
                        <MenuItem key={index} value={hour}>{hour}</MenuItem>
                    ))
                }
            </FullContrastBlueTextField>
            :
            <FullPurpleTextField variant={"outlined"} select onChange={handleChange} value={selectedHour} size={"small"}>
                <MenuItem key={-1} disabled={true} value={"select"}>{_.upperFirst(t("core.select"))}</MenuItem>
                {
                    hours.filter(el => filterEl(el)).map((hour, index) => (
                        <MenuItem key={index} value={hour}>{hour}</MenuItem>
                    ))
                }
            </FullPurpleTextField>
    )
}

EbloomTimePicker.propTypes = {
    handleChange:PropTypes.func.isRequired,
    selectedHour:PropTypes.string,
    isToday: PropTypes.bool,
    color : PropTypes.string
}

EbloomTimePicker.defaultProps = {
    isToday:false,
    color: 'blue'
}
export default EbloomTimePicker