import {Button} from "react-bootstrap";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {Colors} from "../../Colors";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash"
const EbloomTourTip = ({backProps,size, index, isLastStep, primaryProps, skipProps, step, tooltipProps,}) => {

    const t = useTranslation()

    let widthToolTip = '360px'
    let zIndexToolTip = 800

    if(step.toolTipWidth){
        window.innerWidth < 1920 && (widthToolTip = step.toolTipWidth)
    }
    return (
        <div {...tooltipProps} style={{width : widthToolTip, zIndex : zIndexToolTip}} className={"tour-tooltip-box"}>

            {
                ((index < size-1 && !step.disableSkip) || step.enableLastSkip) &&
                <div className={'float float-right'}>
                    <IconButton className={'p-2'} {...skipProps}>
                        <CloseIcon style={{color:Colors.EBLOOM_PURPLE }} />
                    </IconButton>
                </div>
            }

            <div className={'px-10'}>
                {
                    step.title &&
                    <h1 className={'text-center ebloom-title bold'}>{_.upperFirst(t(step.title))}</h1>
                }
                <p >{_.upperFirst(t(step.content))}</p>
            </div>

            <div className={"mt-10"} >
                <div className={"flex flex-row items-center " + ((index!==0 && !step.disableBack) ? 'justify-between' : 'justify-end')}>
                    {(index!==0 && !step.disableBack) &&
                        <IconButton  {...backProps}>
                            <KeyboardArrowLeftIcon style={{color:Colors.EBLOOM_PURPLE }} />
                        </IconButton>
                    }

                    {
                        step.disableNext ?
                            !step.nextEmpty &&
                                <p className={'purple'}> {_.upperFirst(t(step.nextText)) + (' ('+(index+1)+'/'+ size+ ')') }</p>
                            :
                            <Button {...primaryProps } className={"ebloom-btn-purple"}>
                                {isLastStep ? _.upperFirst(t("admin.productTour.buttons.finish")) : step.nextText ? (_.upperFirst(t(step.nextText))+' ('+(index+1)+'/'+ size+ ')') :( _.upperFirst(t("admin.productTour.buttons.next")+' ('+(index+1)+'/'+ size+ ')'))}
                            </Button>
                    }
                </div>
            </div>

        </div>
    )
}

export default EbloomTourTip