import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {Colors} from "../../../Utils/Colors";
import PropTypes from "prop-types";
import {Container} from "react-bootstrap";

const LoadingView = (props) => {
    const {size, color} = props;
    return(
        <Container className={"text-center"}>
            <CircularProgress size={size} style={{color:color}}/>
        </Container>
    )
}

LoadingView.defaultProps = {
    size:40,
    color:Colors.EBLOOM_CONTRAST_PURPLE
}

LoadingView.propTypes = {
    size:PropTypes.number,
    color:PropTypes.string
}

export default LoadingView;