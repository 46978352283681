import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {browseLibrarySteps} from "./browseLibrarySteps";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";

const BrowseLibraryTour = (props) => {
    const {
        updateStep,
        stepTour,
        stepBrowseLibrary, setStepBrowseLibrary,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        validateOutcome,
        icon,
        updateHandler
    } = useContext(ProductTourContext)


    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
                updateStep(TourType.BROWSE_LIBRARY,next)
            }
        }
    }

    const handleTour = () => {
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)
        const tour = {
            id : stepTour[TourType.BROWSE_LIBRARY].id,
            current_step : stepTour[TourType.BROWSE_LIBRARY].step >= browseLibrarySteps.length - 1 ? 0 : stepTour[TourType.BROWSE_LIBRARY].step,
            type: TourType.BROWSE_LIBRARY,
            finished : stepTour[TourType.BROWSE_LIBRARY].outcome
        }
        if(stepBrowseLibrary >= browseLibrarySteps.length - 1){
            validateOutcome(TourType.BROWSE_LIBRARY)
            setStepBrowseLibrary(0)
            icon.current.classList.remove('ebloom-ring')
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{
            })
        }
        iconRinging()
    }

    const iconRinging = () => {
        if(icon && icon.current && stepTour[TourType.BROWSE_LIBRARY]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.BROWSE_LIBRARY].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepBrowseLibrary,stepTour,tourActivated]);


    useEffect(() => {
        fetchData();
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        updateHandler(TourType.BROWSE_LIBRARY,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={browseLibrarySteps}
                continuous
                run={tourStarted}
                stepIndex={stepBrowseLibrary}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />

        </>
    )
}
export default BrowseLibraryTour