import React, {useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import NotificationModel from "../../../../../models/Notification";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import HttpApi from "../../../../../httpApi";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {Row} from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Checkbox from "@mui/material/Checkbox";


const Notification = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState({id:null, text_fr:"", text_en:"", text_nl:"", type:"", isAdmin:false, url:null});
    const [tablePage, setTablePage] = useState(0);
    const [sideFormOpen, setSideFormOpen] = useState(false);

    const t = useTranslation();

    const fetchNotifications = () => {
        HttpApi.get("/notification/getAll").then(response => {
            if(response && response.data){
                setNotifications(response.data);
            }else{
                setNotifications([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleSave = () => {
        const url = notification.id ? NotificationModel.crud.update : NotificationModel.crud.create;
        HttpApi.post(url, notification).then(() => {
            notifySuccess("Notification created");
            fetchNotifications();
            setSideFormOpen(false);
            }
        ).catch(error => notifyError(error));
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = name === "isAdmin" ? event.target.checked : event.target.value;

        setNotification(prevState => {
            let notification = {...prevState};
            notification[name] = value;
            return notification;
        })
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleDelete = () => {
        fetchNotifications();
    }

    const resetNotificationEditState = () => {
        setNotification({id:null, text_fr:"", text_en:"", text_nl:"", type:"", isAdmin:false});
    }

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(toEdit){
            setNotification(toEdit);
        }else{
            resetNotificationEditState();
        }
        setSideFormOpen(open);

    };

    const formTitle = notification.id ? _.capitalize(t("core.editParam", {param:"notification"})) : _.capitalize(t("core.createParam", {param:"notification"}));


    useEffect(() => {
        fetchNotifications();
    }, []);

    return(
        <>
            <CardDefault title={_.capitalize(t("core.notification.notification"))} handleRightButton={toggleDrawer(true)}>
                <TableViewV2 items={notifications} model={NotificationModel} tablePage={tablePage} handleTablePage={handleTablePage} handleEdit={toggleDrawer} handleDelete={handleDelete}/>
            </CardDefault>
            <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <Row>
                    <TextFieldCustom model={NotificationModel.text_en} value={notification.text_en} handleChange={handleChange}/>
                </Row>
                <Row>
                    <TextFieldCustom model={NotificationModel.text_fr} value={notification.text_fr} handleChange={handleChange}/>
                </Row>
                <Row>
                    <TextFieldCustom model={NotificationModel.text_nl} value={notification.text_nl} handleChange={handleChange}/>
                </Row>
                <Row>
                    <TextFieldCustom model={NotificationModel.type} value={notification.type} handleChange={handleChange}>
                        {
                            NotificationModel.type.form.options.map((type, index) => (
                                <MenuItem key={index} value={type}>{_.capitalize(t("core.notification." + type))}</MenuItem>
                            ))
                        }
                    </TextFieldCustom>
                </Row>
                <Row>
                    <TextFieldCustom model={NotificationModel.url} value={notification.url} handleChange={handleChange}/>
                </Row>
                <Row>
                    <Checkbox className={"checkbox-purple"} name={"isAdmin"} checked={notification.isAdmin} onChange={handleChange}/><span>{_.upperFirst(t("core.isAdmin"))}</span>
                </Row>
            </SideForm>
        </>
    )
}

export default Notification;