import React from 'react';
import PropTypes from "prop-types";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton/IconButton";
import MenuIcon from '@mui/icons-material/MenuOutlined';
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import CompanySwitcher from "../Modules/Button/CompanySwitcher";
import Login from "../../../login/login";
import EbloomMenu from "../Modules/Menu/EbloomMenu";
import MenuSettings from "../Modules/Menu/MenuSettings";
import TeamSwitcher from "../Modules/Button/TeamSwitcher";
import EbloomTimeFilter from "../Modules/Input/EbloomTimeFilter";
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import EbloomTooltip from "../Modules/Popovers-Tooltips/EbloomTooltip";
import LoadingView from "./LoadingView";
import {useNavigationTo} from "../../../hooks/navigationHook";
import { styled} from "@mui/material/styles";
import {useUserData} from "../../../hooks/userHook";
import {Roles} from "../../../Utils/Global";
import {Colors} from "../../../Utils/Colors";
import ReviewModal from "../Modules/Modal/ReviewModal";
import EntitiesSwitcher from "../Modules/Button/EntitiesSwitcher";
import TeamSwitcherEntities from "../Modules/Button/TeamSwitcherEntities";
import EbloomLogo from "../EbloomLogo";
import CommonSurveyButton from "../Modules/Button/CommonSurveyButton";

const MenuButton = styled(IconButton)(({theme}) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

function MenuAppBar(props) {

    const t = useTranslation();
    const navigate = useNavigationTo();
    const user = useUserData();
    const isProspect = user.module === "prospect"
    const {bp,isDashboardSuperAdmin,isAllEntities,entitiesToDisplay,handleSwitchEntityTeams,handleSwitchGroup,colors,commonSurveyActivated, setCommonSurveyActivated, lockCommonSurvey,dashboardAccess} = props;

    const handleDrawer = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        props.handleDrawerToggle();
    };

    const redirectHome = () => {
        navigate.to("/employee/daily");
    };

    const checkColor = () => {
        if(colors){
            if(isDashboardSuperAdmin){
                return !isAllEntities;
            }else{
                return  false
            }
        }else{
             return false
        }
    }

    return (
        <div className={"mb-0 px-0 "} style={{zIndex:200}}>
            <AppBar className={"gradient-app-bar " + (props.hasMenu ? "pl-240" : "") } style={{zIndex:200,'--linear-gradient-color' : checkColor()? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE }}>
                <Toolbar>
                    {
                        (props.hasMenu && bp.pageSize < bp.breakpoints.tablet) &&
                        <MenuButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawer()}
                        >
                            <MenuIcon/>
                        </MenuButton>
                    }

                    <div className={"h-50 w-100"} style={{marginRight: 10, cursor: "pointer"}} onClick={redirectHome}>
                        <EbloomLogo colors={checkColor() ? colors : null}/>
                    </div>
                    {
                        (props.pageName && bp.pageSize >= bp.breakpoints.tablet) &&
                        <Typography variant="h6">
                            <span style={{marginRight:10}}>{_.upperFirst(t(props.pageName))}</span>
                        </Typography>
                    }
                    {
                        isDashboardSuperAdmin && user.companyGroup && isAllEntities &&
                        <EntitiesSwitcher {...props} colors={null}/>

                    }
                    {
                        (Login.hasRole("global_backoffice") || Login.hasRole("demo_backoffice") || Login.hasRole("datatopia_backoffice")) &&
                        <CompanySwitcher switchCompany={props.switchCompany} {...props}/>
                    }

                    {
                        (props.showTeamSwitcher && (Login.hasRole("global_manager") || Login.hasRole("global_admin"))) &&  dashboardAccess &&
                        <TeamSwitcher {...props}/>
                    }
                    {
                        Login.hasRole("global_super_admin") && isDashboardSuperAdmin && isAllEntities && entitiesToDisplay.length === 1 && entitiesToDisplay[0].id !== user.companyGroup.id &&
                        <TeamSwitcherEntities handleSwitch={handleSwitchEntityTeams} entity={entitiesToDisplay[0].id}/>
                    }
                    {
                        Login.hasRole("global_super_admin") && isDashboardSuperAdmin && !isAllEntities &&
                        <EntitiesSwitcher alternative={false} allOption={true} isDashboardGroup={true}
                                          handleSwitch={handleSwitchGroup} colors={checkColor() ? colors : null}/>
                    }
                    {
                        (props.showDatePicker) &&
                        <EbloomTimeFilter isProspect={isProspect} size={"sm"}
                                          colorsGroup={(checkColor() ? colors : null)} {...props} />
                    }
                    {
                        Login.hasOneOfRole(["global_admin","global_manager"]) && props.showFilterCommonSurveys && !lockCommonSurvey && !isProspect &&
                        <div className={"ml-10"}>
                            <CommonSurveyButton locked={lockCommonSurvey} handleSwitch={setCommonSurveyActivated} isActive={commonSurveyActivated}/>
                        </div>
                    }
                    {
                        (((props.showTeamSwitcher || (Login.hasRole("global_super_admin") && isDashboardSuperAdmin && isAllEntities && entitiesToDisplay.length === 1 && entitiesToDisplay[0].id !== user.companyGroup.id)) && (Login.hasRole("global_admin") || Login.hasRole("global_manager")) && (Login.hasModule("very_happy") || Login.hasModule("super_happy") || Login.hasModule("free") || Login.hasModule("prospect"))) || (Login.hasRole("global_super_admin") && isDashboardSuperAdmin && !isAllEntities)) &&
                        <EbloomTooltip
                            text={props.reportDisabled ? _.upperFirst(t("manager.dashboard.report.anonymityRestriction")) : _.upperFirst(t("core.downloadParam", {param: t("manager.dashboard.report.report")}))}
                            placement={"bottom"}>
                            <div>
                                <IconButton style={{
                                    marginLeft: 10,
                                    marginRight: 5,
                                    color:checkColor() ? colors.primary : "white",
                                    backgroundColor: props.reportDisabled || props.shouldDownloadReport === true ? "transparent" : checkColor() ? "white" : Colors.EBLOOM_PURPLE,
                                    opacity: props.reportDisabled ? 0.5 : 1,
                                    cursor: props.reportDisabled ? "default" : "pointer",
                                }}
                                            color="inherit"
                                            onClick={props.reportDisabled ? () => {
                                            } : () => {
                                                props.handleModalReport(true)
                                            }}
                                            className={""}
                                            disabled={props.shouldDownloadReport || props.reportDisabled}>
                                    {
                                        props.shouldDownloadReport === false ?
                                            <SystemUpdateAltOutlinedIcon/>
                                            :
                                            <LoadingView color={"white"} size={16}/>
                                    }

                                </IconButton>
                            </div>
                        </EbloomTooltip>
                    }
                    {
                        (Roles.BACKOFFICE_DEMO_DATATOPIA_GLOBAL.some(el => user.roles.includes(el))) ?
                            <div style={{position: "absolute", right: 15}}>
                                <MenuSettings {...props}/>
                            </div>
                            :
                            <div style={{position: "absolute", right: 15}}>
                                <EbloomMenu {...props}/>
                            </div>

                    }


                </Toolbar>
            </AppBar>
            <ReviewModal/>
        </div>
    );
}

MenuAppBar.defaultProps = {
    pageName:"",
    hasMenu: true,
    showTeamSwitcher:false,
    showDatePicker:false,
    isDashboardSuperAdmin : false,
    isAllEntities : false,
    colors : null,
    showFilterCommonSurveys : false
};

MenuAppBar.propTypes = {
    pageName:PropTypes.string,
    hasMenu:PropTypes.bool,
    showTeamSwitcher: PropTypes.bool,
    showDatePicker: PropTypes.bool,
    isDashboardSuperAdmin: PropTypes.bool,
    isAllEntities: PropTypes.bool,
    colors : PropTypes.object,
    showFilterCommonSurveys : PropTypes.bool
};

export default MenuAppBar