import HttpApi from "../httpApi";
import {ModelTypes} from "./Types";

const isBackoffice = localStorage.getItem("ebloom-companyToEdit") !== null;
const id_company = isBackoffice ? Number(JSON.parse(localStorage.getItem("ebloom-companyToEdit")).id) : null;

const Filter = {
    crud:{
        getAll:"/filters/getAllAdmin",
        create:"/filters/create",
        update:"/filters/update",
        delete:"/filters/delete"
    },

    initializeOptions: () => {
        HttpApi.getV2("/filters/getCompanyFilters").then(response => {
            if (response && response.data) {
                Filter.id_company_filter.form.selectOptions.options = response.data;
            } else {
                Filter.id_company_filter.form.selectOptions.options = null;
            }
        });
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"company-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
            helperText:""
        },
        detail:{
            show:true
        }
    },

    id_company_filter:{
        id:"id_company_filter",
        type:ModelTypes.NUMBER,
        label:"core.category.category",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"company-filter",
            label:"core.category.category",
            select:true,
            selectOptions:{
                options:null,
                showComboBox:true,
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_company_filter",
            helperText:""
        },
        detail:{
            show:false
        }
    },

    company_filter:{
        id:"company_filter",
        type:ModelTypes.TEXT,
        label:"core.category.category",
        table:{
            show:true,
            badge:true
        },
        list:{

        },
        form:{
            show:false,
            id:"company-filter",
            label:"core.category.category",
            placeholder:"core.category.category",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"company_filter",
            helperText:""

        },
        detail:{
            show:true
        }
    },

    users:{
        id:"users",
        type:ModelTypes.ARRAY,
        label:"core.user.users",
        table:{
            show:true,
            format:"length"
        },
        list:{

        },
        form:{
            show:false
        },
        detail:{
            show:true,
            key:"email"
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        },
        detail:{
            show:true
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        },
        detail:{
            show:false
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },
};
export default Filter;