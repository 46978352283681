import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import TextFieldCustom from "./Modules/Input/TextFieldCustom";
import UserModel from "../../models/User";
import HttpApi from "../../httpApi";
import {createErrorNotification, createSuccessNotification, notifyError, notifySuccess} from "../../Utils/Notification";
import CardDefault from "./Modules/Cards/CardDefault";
import {getLanguage, setLanguage} from "react-multi-lang/lib";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import DisplayAdminView from "../Admin/Layouts/DisplayAdminView";
import {useTranslation} from "react-multi-lang";
import {getLanguages} from "../../lang/translations";
import ConfirmModal from "./Modules/Modal/ConfirmModal";
import {FullContrastPurpleTextField, Roles} from "../../Utils/Global";
import {useUserData} from "../../hooks/userHook";
import LoadingView from "./Layouts/LoadingView";

const ProfilePage = (props) =>  {
    const [userData, setUserData] = useState({email:"", lang:0, name:null});
    const [userPassword, setUserPassword] = useState({oldPassword:"", password:"", confirmPassword:""});
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [loadingRequest, setLoadingRequest] = useState(null);

    const t = useTranslation();

    const user = useUserData();

    let CloneModel = _.cloneDeep(UserModel);
    CloneModel.password.form.label = "core.profile.newPassword";
    CloneModel.confirmPassword.form.label = "core.profile.confirmNewPassword";

    //HANDLERS

    const handleOpenModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCloseModal2 = () => {
        setShowModal2(false);
    }

    const handleOpenModal2 = () => {
        setShowModal(false);
        setShowModal2(true);
    }

    const handleSendDeletionRequest = () => {
        if(!loadingRequest){
            setLoadingRequest(true);
            HttpApi.postV2("/integrations/sendDeleteAccountRequest", {user:user.email, company:user.company, feedback:feedback}).then(() => {
                notifySuccess(_.upperFirst(t("core.profile.successNotification")), 0);
                user.logout();

            }).catch(error => {
                setLoadingRequest(false);
                notifyError(error);
            })
        }

    }

    const handleChangeFeedback = (event) => {
        setFeedback(event.target.value);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setUserData(prevState => {
            let userData = {...prevState};
            userData[name] = value;
            return userData;
        })
    };

    const handleChangePassword = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setUserPassword(prevState => {
            let userPassword = {...prevState};
            userPassword[name] = value;
            return userPassword;
        })
    };

    //PRIMARY FUNCTIONS

    const fetchUserData = () => {
        HttpApi.getV2('/users/getForId').then((response) => {
            setUserData(prevState => {
                let userData = {...prevState};
                userData.email = response.data.email;
                userData.lang = response.data.lang;
                userData.name = response.data.name;
                return userData;
            })

        }).catch((error) => {
            notifyError(error);
        })
    };

    const updateUserData = () =>{
        if(!userData.lang){
            createErrorNotification("Error !", "Invalid credentials for profile update");
            return;
        }
        HttpApi.put('/users/updateMe', {lang:userData.lang, name:userData.name}).then(() => {
           createSuccessNotification("Success !", "Credentials successfully updated");
           user.setEmail(userData.email);
           user.setName(userData.name);
           if(getLanguage() !== userData.lang){
               setLanguage(userData.lang);
           }

        }).catch((error) => {
            notifyError(error);
            fetchUserData();
        })

    };

    const updatePassword = () => {
        if(!userPassword.password || !userPassword.oldPassword || !userPassword.confirmPassword || UserModel.password.form.error(userPassword.password) || UserModel.confirmPassword.form.error(userPassword.password, userPassword.confirmPassword)){
            createErrorNotification("Error", "Invalid credentials for password update");
            return;
        }

        HttpApi.put('/users/passwordUpdate', {oldPassword:userPassword.oldPassword, newPassword:userPassword.password, confirmNewPassword:userPassword.confirmPassword})
            .then(() => {
                createSuccessNotification("Success", "Password successfully updated");
            }).catch((error) => {
                notifyError(error);
        }).finally(() => {
            resetPasswordFields();
        })
    };


    //FIELDS RESET

    const resetPasswordFields = () => {
        setUserPassword(prevState => {
            let userPassword = {...prevState};
            userPassword.oldPassword = "";
            userPassword.password = "";
            userPassword.confirmPassword = ""
            return userPassword;
        })
    };

    const handlePrivacyPolicy = () => {
        window.open("/documents/Privacy_Policy_eBloom.html");
    }

    useEffect(() => {
        fetchUserData();
    }, [props])


    return (
        <DisplayAdminView {...props} menu={Roles.BACKOFFICE_DEMO_DATATOPIA_GLOBAL.some(el =>user.roles.includes(el)) ? "noMenu" : "account"} pageName={"core.profile.profile"} selectedIndex={5}>
            {
                () => (
                    <Container>
                        <Row>
                            <Col md="6" sm="12">
                                <CardDefault title={_.capitalize(t("core.profile.profile"))} rightButtonPurple={false} rightButtonVariant={"link"} buttonText={_.upperFirst(t("core.login.privacyPolicy"))} handleRightButton={handlePrivacyPolicy} isMobile={props.isMobile} showLeftButton leftButtonVariant={"link"} leftButtonText={_.upperFirst(t("core.delete"))} handleLeftButton={handleOpenModal}>
                                    {
                                        <Row>
                                            <Col>
                                                <TextFieldCustom readOnly model={UserModel.email} value={userData.email} handleChange={handleChange}/>
                                            </Col>
                                            <Col>
                                                <TextFieldCustom error={UserModel.name.form.error(userData.name)} model={UserModel.name} value={userData.name} handleChange={handleChange}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <TextFieldCustom model={UserModel.lang} value={userData.lang} handleChange={handleChange}>
                                                {
                                                    getLanguages().map(lang => (
                                                        <MenuItem key={lang.value} value={lang.value}>{lang.name}</MenuItem>
                                                    ))
                                                }
                                            </TextFieldCustom>
                                        </Col>
                                    </Row>
                                    <div className={"text-right"}>
                                        <Button className={"ebloom-btn-purple"} onClick={updateUserData}>{_.capitalize(t("core.update"))}</Button>
                                    </div>

                                </CardDefault>
                            </Col>
                            <Col>
                                <CardDefault title={_.capitalize(t("core.login.password"))} showButton={false} bigTitle>
                                    <Row>
                                        <Col>
                                            <TextFieldCustom model={UserModel.oldPassword} value={userPassword.oldPassword} handleChange={handleChangePassword}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextFieldCustom model={CloneModel.password} error={UserModel.password.form.error(userPassword.password)} value={userPassword.password} handleChange={handleChangePassword} helper/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextFieldCustom model={CloneModel.confirmPassword} error={UserModel.confirmPassword.form.error(userPassword.password, userPassword.confirmPassword)} value={userPassword.confirmPassword} handleChange={handleChangePassword}/>
                                        </Col>
                                    </Row>
                                    <div className={"text-right"}>
                                        <Button className={"ebloom-btn-purple"} onClick={updatePassword}>{_.capitalize(t("core.profile.changePassword"))}</Button>
                                    </div>
                                </CardDefault>
                            </Col>
                        </Row>
                        <ConfirmModal handleClose={handleCloseModal} show={showModal} title={_.upperFirst(t("core.profile.modalDeleteTitle"))} buttonRightVariant={"link"} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.profile.confirmButton1"))} handleChange={handleOpenModal2}>
                            <p>{_.upperFirst(t("core.profile.modalDeleteText"))}</p>
                        </ConfirmModal>
                        <ConfirmModal handleClose={handleCloseModal2} show={showModal2} title={_.upperFirst(t("core.profile.confirmModalDeleteTitle"))} buttonRightVariant={"link"} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={loadingRequest ? <LoadingView size={16}/> : _.upperFirst(t("core.profile.confirmButton2"))} handleChange={handleSendDeletionRequest} buttonDisabled={!feedback || loadingRequest}>
                            <p>{_.upperFirst(t("core.profile.confirmModalDeleteText"))}</p>
                            <FullContrastPurpleTextField variant={"outlined"} fullWidth minRows={3} multiline placeholder={_.upperFirst(t("core.profile.feedbackPlaceholder"))} value={feedback} onChange={handleChangeFeedback} required/>
                        </ConfirmModal>
                    </Container>
                )
            }

        </DisplayAdminView>
    )

};

export default ProfilePage;