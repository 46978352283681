import React from 'react';
import {Container} from "react-bootstrap";
import DisplayBackofficeDashboardView from "../../../Layouts/DisplayBackofficeDashboardView";
import GlobalDashboard from "./GlobalDashboard";


const GlobalDashboardPage = (props) => {
    return (
        <DisplayBackofficeDashboardView showDatePicker {...props}>
            {(companyToEdit, unlockedBackoffice, formattedStartDate, formattedEndDate, timeFilterVersion) => (
                <Container>
                        <GlobalDashboard companyToEdit={companyToEdit} startDate={formattedStartDate} endDate={formattedEndDate} timeFilterVersion={timeFilterVersion}/>
                </Container>
            )
            }
        </DisplayBackofficeDashboardView>
    )
};

export default GlobalDashboardPage;
