import React, {useRef} from 'react';
import {selectIcon} from "../Assets/CategoriesAssets";
import PropTypes from "prop-types";
import {Col, ListGroup, ListGroupItem, Overlay, Popover, Row} from "react-bootstrap";
import {useOutsideAlerter} from "../../../../../hooks/outsideAlerterHook";
import {Colors} from "../../../../../Utils/Colors";

const IconPicker = (props) => {
    const {iconId,handleIcon,listIcons,displayIconPicker,setDisplayIconPicker,color} = props
    const ref = useRef(null);

    const handleClick = () => {
      setDisplayIconPicker(!displayIconPicker)
    }

    const handleClose = () => {
      setDisplayIconPicker(false)
    }

    useOutsideAlerter(ref,() => handleClose())
    return (
        <div ref={ref}>
            <div onClick={handleClick} className={"border-1 bg-white/50 border-disabled-grey rounded-md p-5 inline-block pointer"}>
                {selectIcon(iconId)}
            </div>
            <Overlay
                show={displayIconPicker}
                target={ref.current}
                placement="bottom-start"
                container={ref.current}
                containerPadding={20}
            >
                <Popover id="popover-ebloom" className={"bg-white border border-solid border-grey/25 shadow-md rounded-md"} >
                    <Popover.Body className={'p-5'}>
                        <div className={"flex flex-wrap justify-start items-center"}>
                            {
                                listIcons.map((icon,index) =>
                                    <div key={index}
                                         onClick={()=>{handleIcon(index+1)}}
                                         className={"w-30 h-30 border-1  border-disabled-grey rounded-md p-5 flex justify-center items-center my-5 mx-5 cursor-pointer"}
                                        style={{
                                            backgroundColor : parseInt(iconId)-1 === index ? color : "#F4EFEF76",
                                        }}
                                    >
                                        {icon}
                                    </div>
                                )
                            }
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
};

IconPicker.propTypes = {
    iconId : PropTypes.string.isRequired,
    handleIcon: PropTypes.func.isRequired,
    listIcons: PropTypes.array.isRequired,
    displayIconPicker: PropTypes.bool.isRequired,
    setDisplayIconPicker: PropTypes.func.isRequired,
    color : PropTypes.string.isRequired
}

export default IconPicker;
