import {createSlice} from "@reduxjs/toolkit";

export const settings = createSlice({
  name: 'settings',
  initialState: {
    settings:null,
  },
  reducers: {
    updateSettings: (state, action) => {
      state.settings = action.payload
    },
  },
})

export const {updateSettings} = settings.actions;

export default settings.reducer;