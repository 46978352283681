import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import HttpApi from "../../../../httpApi";
import {avatarCodes} from "../MyBloom/Avatar/AvatarAssets";
import {notifyError} from "../../../../Utils/Notification";
import Avatar from "../MyBloom/Avatar/Avatar";

const WeBloomNotFound = (props) => {

    //state
    const [avatar, setAvatar] = useState({
        form: '01',
        hat: '16',
        emote: '06',
        color: '01'
    });
    //props
    const {type} = props;
    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {
        HttpApi.get("/mybloom/getAvatar").then(result => {
            const data = avatarCodes(result.data)
            if(type === "alreadyAnswered"){
                data.emote = "09"
            }else{
                data.emote = "05";
            }
            setAvatar(data)
        }).catch(error => {
            notifyError(error);
        })
    }, [])

    return (
        <div className={"grid grid-cols-2 grid-rows-1"}>
            <div className={"mt-155 w-250 align-self-baseline justify-self-end"}>
                <Avatar avatar={avatar}/>
            </div>
            <div className={"justify-self-start align-self-end"}>
                <div className={"w-fit m-auto flex flex-col"}>
                    <div className={"w-auto relative "}>
                        <div className={"rounded-3xl bg-bg-white h-1/2 min-h-155 px-30 py-30"}>
                            <p className={"text-contrast-purple font-semibold text-3xl ebloom-text"}>{type === "alreadyAnswered" ? "Yeaaah" : 'Oops...'}</p>
                            <p className={"text-purple font-semibold text-lg ebloom-text mt-10"}>{_.upperFirst(t(type === "isExpired" ? "webloom.expiredHelper" : type === "alreadyAnswered" ? "webloom.alreadyAnsweredHelper" : "webloom.notAvailableHelper"))}</p>
                        </div>
                    </div>
                    <div className={"w-full flex flex-col justify-center items-start"}>
                        <div className={"w-40 h-40 rounded-full bg-bg-white my-10"}/>
                        <div className={"w-20 h-20 rounded-full bg-bg-white"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

WeBloomNotFound.propTypes = {
    type: PropTypes.string.isRequired
}

WeBloomNotFound.defaultProps = {}
export default WeBloomNotFound