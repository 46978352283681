import React, {useEffect, useState} from 'react';
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import EbloomInfoTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";
import PropTypes from "prop-types";
import {SurveyStatus, SurveyTypes, SurveyUsersStatus} from "../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import PollCard from "../../WeBloom/Dashboard/Modules/PollCard";
import IdeaboxCard from "../../WeBloom/Dashboard/Modules/IdeaboxCard";
import MessageCard from "../../WeBloom/Dashboard/Modules/MessageCard";
import {ListGroup} from "react-bootstrap";
import EbloomSelectV2 from "../../../../Core/Modules/Input/EbloomSelectV2";
import {MenuItem} from "@mui/material";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as BubbleIcon} from "../../../../Core/symbols/webloom/bubble-coma.svg";
import Avatar from "../Avatar/Avatar";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import {useNavigate} from "react-router-dom";
import AlertCard from "../../WeBloom/Dashboard/Modules/AlertCard";


const WebloomContainer = (props) => {
    const {tooltip,title,surveys,fetchSurveys,avatar,loadingWeBloom,isProspect,setShowModal} = props

    const bp = useBreakpoints()
    const t = useTranslation()
    const navigate = useNavigate()

    const [typeFilter, setTypeFilter] = useState("All types");
    const [customAvatar, setCustomAvatar] = useState({
        form: '01',
        hat: '16',
        emote: '10',
        color: '01'
    });

    const handleTypeFilter = (event) => {
        setTypeFilter(event.target.value);
    }

    const sortWeBloom = (a,b) => {
        if(a.start_date<b.start_date){
            return 1;
        }else if(a.start_date>b.start_date){
            return -1;
        }
        return 0;
    }

    useEffect(() => {
        setCustomAvatar((prevState) => {
            return {
                form: avatar.form,
                hat: avatar.hat,
                emote: prevState.emote,
                color: avatar.color
            }
        })
    }, [avatar]);


    return <div className={"w-full h-full"}>
        <div className={"h-500"}>
            <div
                className={'rounded-md border-1 border-contrast-purple relative bg-bg-white h-full py-30 z-0' + (bp.isTabletLandscapeOrLess() && " mt-40 w-full ")}>
                <div className={'bg-contrast-purple rounded-md px-30 p-5 absolute left-0 right-0 mx-auto max-w-fit -top-15'}>
                    <h1 className={'font-semibold text-white text-center mx-auto'}>{title}</h1>
                </div>
                {tooltip && <div className={"absolute right-10 top-10"}>
                    <EbloomInfoTooltip text={tooltip} placement={"bottom"}/>
                </div>}
                <div className={" w-full h-full"}>
                    <div className={'relative px-25 w-full h-full ' + (surveys && surveys.length > 0 && "overflow-y-auto") }>
                        <div className={"flex items-center " + ((surveys && surveys.length > 0) ? "justify-between " : "justify-end ")}>
                            {
                                (surveys && surveys.length > 0) &&
                                <EbloomSelectV2 value={typeFilter} onChange={handleTypeFilter}>
                                    <MenuItem key={1} value={"All types"}>{_.upperFirst(t("core.allBisParam", {param:t("webloom.types")}))}</MenuItem>
                                    <MenuItem key={2} value={SurveyTypes.POLL}>{_.upperFirst(t("webloom.poll"))}</MenuItem>
                                    <MenuItem key={3} value={SurveyTypes.IDEABOX}>{_.upperFirst(t("webloom.ideabox"))}</MenuItem>
                                    <MenuItem key={4} value={SurveyTypes.MESSAGE}>{_.upperFirst(t("webloom.message"))}</MenuItem>
                                    {
                                        (surveys?.find(el => el.type === SurveyTypes.ALERT) !== undefined) &&
                                        <MenuItem key={5} value={SurveyTypes.ALERT}>{_.upperFirst(t("webloom.alert"))}</MenuItem>
                                    }
                                </EbloomSelectV2>
                            }
                            <button className={"text-purple underline"} onClick={() => navigate("/webloom/dashboard")}>
                                {_.upperFirst(t("employee.dashboard.seeAll"))}
                            </button>
                        </div>
                        <div className={"h-full w-full "}>
                            {
                                loadingWeBloom ? <div className={" h-full w-full flex justify-center items-center"}>
                                    <LoadingView/>
                                    </div> :
                                (surveys && surveys.length > 0 ) ?
                                    <ListGroup variant="flush" className={" w-full h-full"} >
                                        {
                                            surveys?.filter(el => el.type === typeFilter || typeFilter === "All types").sort(sortWeBloom).map((survey, index) => (
                                                <div className={"mt-10 mb-10"} key={index}>
                                                    {
                                                        survey.type === SurveyTypes.POLL ?
                                                            <PollCard isProspect={isProspect} setShowModal={setShowModal} survey={survey}/>
                                                            :
                                                            survey.type === SurveyTypes.IDEABOX ?
                                                                <IdeaboxCard isProspect={isProspect} setShowModal={setShowModal} survey={survey}/>
                                                                :
                                                                survey.type === SurveyTypes.ALERT ?
                                                                    <AlertCard survey={survey} setShowModal={setShowModal} isProspect={isProspect}/>
                                                                    :
                                                                <MessageCard isProspect={isProspect} setShowModal={setShowModal} survey={survey} fetchSurveys={fetchSurveys}/>
                                                    }

                                                </div>
                                            ))
                                        }
                                    </ListGroup>

                                    :
                                    <div className={"h-full w-full flex justify-center items-center"}>
                                        <div>
                                            <div className={"w-250 ml-70"}>
                                                <div className={"w-full h-100 bg-contrast-blue/[0.1] rounded-xl flex justify-center items-center"}>
                                                    <p className={"p-15 text-contrast-purple font-semibold"}>{_.upperFirst(t("employee.dashboard.allGood"))}</p>
                                                </div>
                                                <BubbleIcon className={"ml-70 contrast-blue-icon low-opacity-icon"}/>
                                            </div>

                                            <div className={"w-200 h-200"}>
                                                <Avatar avatar={customAvatar}/>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


}

WebloomContainer.proptype = {
    tooltip : PropTypes.string,
    title : PropTypes.string.isRequired,
    surveys : PropTypes.array.isRequired,
    fetchSurveys : PropTypes.func.isRequired,
    avatar: PropTypes.object.isRequired,
    loadingWeBloom : PropTypes.bool.isRequired,
    isProspect : PropTypes.bool.isRequired,
    setShowModal : PropTypes.func.isRequired,
}

WebloomContainer.defaultProps={
    tooltip : null
}

export default WebloomContainer;