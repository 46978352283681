import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";

const EbloomModal = (props) => {
    const {children, size, show, handleClose} = props;


    return(
        <Modal show={show} onHide={handleClose} size={size}>
            <Modal.Body className={"ebloom-normal-text bg-bg-white border rounded-md"}>
                {children}
            </Modal.Body>
        </Modal>
    )
}

EbloomModal.propTypes = {
    handleClose:PropTypes.func.isRequired,
    show:PropTypes.bool.isRequired,
    size:PropTypes.string,
}

EbloomModal.defaultProps = {
    size:"md",
}

export default EbloomModal;