import HttpApi from "../httpApi";
import _ from "lodash";
import {createErrorNotification} from "../Utils/Notification";
import {CompanyTypes} from "../Utils/Global";
import {getFromStorage, getStorage} from "../Utils/Storage";

class Login {

    constructor(){
        this.userRoles = null;
        this.userModule = null;
    }



    connectApp = async (credentials) => {
        await HttpApi.connectApp(credentials.data);
        if(credentials.data.company_type && credentials.data.company_type === CompanyTypes.DEMO){
            sessionStorage.setItem("ebloom-credentials", JSON.stringify(credentials.data));
        }else{
            localStorage.setItem("ebloom-credentials", JSON.stringify(credentials.data));
        }
    };

    reconnectApp =  async (credentials) => {
        sessionStorage.removeItem("ebloom-credentials");
        localStorage.removeItem("ebloom-credentials");
        localStorage.removeItem("ebloom-companyToEdit");
        localStorage.removeItem("ebloom-showSBox");
        await HttpApi.connectApp(credentials.data);
        if(credentials.data.company_type && credentials.data.company_type === CompanyTypes.DEMO){
            sessionStorage.setItem("ebloom-credentials", JSON.stringify(credentials.data));
        }else{
            localStorage.setItem("ebloom-credentials", JSON.stringify(credentials.data));
        }
    }


    logout = () => {
        sessionStorage.removeItem("ebloom-credentials");
        localStorage.removeItem("ebloom-credentials");
        localStorage.removeItem("ebloom-companyToEdit");
        localStorage.removeItem("ebloom-showSBox");
        window.location.href = "/login";
        HttpApi.delete("/auth/logout").catch(error => {
            if(error && error.response && error.response.status && (error.response.status !== 401 && error.response.status !== 403)){
             if(error && error.response && error.response.data && error.response.data.msg){
                    createErrorNotification("Error !", error.response.data.msg);
             }else{
                    createErrorNotification("Error !", "Unable to log you out");
             }
            }
        })
    };


    isLogin = () => {
        return getFromStorage("token") !== null;
    };


    initiateRole = () => {
        if(true === this.isLogin()) {
            this.getMyRole().then(roles => {
                this.userRoles = roles;
                this.getMyModule().then(module => {
                    this.userModule = module;
                    let storage = getStorage("ebloom-credentials") || {};
                    if( !storage.roles.some(el => roles.includes(el)) || storage.module !== module){
                        this.logout();
                    }
                }).catch(error => {
                    this.userModule = null;
                });
            }).catch(error => {
                this.userRoles = null;
            });
        }

    };

    hasOneOfModules = (modules) => {
        if(!_.isArray(modules)){
            return this.hasModule(modules);
        }

        let valid = false;

        modules.forEach(module => {
            if(this.hasModule(module)){
                valid = true;
            }
        });


        return valid;



    };

    hasModule = (module) => {
        if(true === this.isLogin()){
            if(this.userModule){
                return this.userModule === module;
            }
            if(getFromStorage("module")){
                this.userModule = getFromStorage("module");
                return getFromStorage("module") === module;
            }

            let valid = HttpApi.getV2("/modules/getForCompany").then(response => {
                if(response && response.data && response.data.code){
                    return response.data.code === module;
                }
            }).catch(error => {
                console.log(error);
                return false;
            });
            return valid;
        }else{
            return false;
        }
    };

    isMoreThanHappy = () => {
        return this.hasModule("very_happy") || this.hasModule("super_happy") || this.hasModule("free");
    };


    hasOneOfRole = (roles) => {
        if (!(_.isArray(roles))) {
            return this.hasRole(roles);
        }
        let valid = false;
        roles.forEach((role) => {
            if (this.hasRole(role)) {
                valid = true;
            }
        });

        return valid;
    };


    hasRole = (role) => {
        if (this.userRoles) {
            return this.userRoles.includes(role);
        }

        if(getFromStorage("roles")){
            this.userRoles = getFromStorage("roles");
            return getFromStorage("roles").includes(role) ;
        }

        let valid = false;

        HttpApi.getV2("/roles/getMyRole").then(response => {
            if (response && response.data) {
                this.userRoles = response.data.code;
                valid = response.data.code.includes(role) ;
            }
        }).catch(error => {
            console.log(error);
        });
        return valid;
    };

    getMyRole = () => {
        return new Promise((resolve, reject) => {
            let role = ["global_employee"];

            HttpApi.getV2("/roles/getMyRole").then(response => {
                if (response && response.data) {
                    resolve(response.data.code);
                } else {
                    resolve(role);
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        })

    };

    getMyModule = () => {
        return new Promise((resolve, reject) => {
            let module = "happy";

            HttpApi.getV2("/modules/getForCompany").then(response => {
                if(response && response.data){
                    resolve(response.data.code)
                }else{
                    resolve(module);
                }
            }).catch(error => {
                reject(error);
            })
        })
    };

    
    getModule = (id_company) => {
        return new Promise((resolve, reject) => {
            let module = "happy";

            HttpApi.getV2("/modules/getForCompany").then(response => {
                if(response && response.data){
                    resolve(response.data.code)
                }else{
                    resolve(module);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }
}

let instance = new Login();

export default instance;