import React, {useState} from "react";
import PropTypes from "prop-types";
import EbloomPopoverV2 from "../../../../../../Core/Modules/Popovers-Tooltips/EbloomPopoverV2";
import IconButton from "@mui/material/IconButton/IconButton";
import {ReactComponent as SettingIcon} from "../../../../../../Core/symbols/webloom/white/setting-white.svg";
import {Card} from "react-bootstrap";
import {ReactComponent as CloseIcon} from "../../../../../../Core/symbols/webloom/white/close-white.svg";
import {ReactComponent as DuplicateIcon} from "../../../../../../Core/symbols/webloom/white/duplicate-white.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../Core/symbols/webloom/white/delete-white.svg";
import EbloomTooltip from "../../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {PollTypes} from "../../Modules/Types";

const PollCardMenu = (props) => {
    const [targetSettings, setTargetSettings] = useState(false);

    const {question, handleDuplicateQuestion, handleRemoveQuestion, children} = props;

    const t = useTranslation();

    const handleCloseSettings = () => {
        setTargetSettings(null);
    }

    const handleOpenSettings = (event) => {
        setTargetSettings(event.target);
    }

    return (
        <div className={"h-100 flex flex-col justify-between ml10"}>
            {
                (question.type !== PollTypes.TITLE) &&
                <EbloomPopoverV2 handleClose={handleCloseSettings} show={Boolean(targetSettings)} trigger={<EbloomTooltip text={_.upperFirst(t("admin.settings"))} placement={"right"}><IconButton size={"small"} onClick={handleOpenSettings}><SettingIcon className={"h-22 w-22 contrast-blue-icon"}/></IconButton></EbloomTooltip>} placement={"left"} target={targetSettings}>
                    <Card className={"border-1 border-contrast-blue rounded-lg"}>
                        <Card.Header className={"bg-contrast-blue text-white inline"}>
                            <span className={"ebloom-normal-text"}>{_.upperFirst(t("admin.settings"))}</span>
                            <IconButton size={"small"} onClick={handleCloseSettings} className={"float-right"}>
                                <CloseIcon className={"w-25 h-25"}/>
                            </IconButton>
                        </Card.Header>
                        <Card.Body className={"text-contrast-blue"}>
                            {children}
                        </Card.Body>
                    </Card>
                </EbloomPopoverV2>
            }
            {
                (question.type !== PollTypes.NAME) &&
                <EbloomTooltip text={_.upperFirst(t("core.duplicate"))} placement={"right"}>
                    <IconButton size={"small"} onClick={() => {handleDuplicateQuestion(question)}}>
                        <DuplicateIcon className={"h-22 w-22 contrast-blue-icon"}/>
                    </IconButton>
                </EbloomTooltip>
            }
            <EbloomTooltip text={_.upperFirst(t("core.delete"))} placement={"right"}>
                <IconButton size={"small"} onClick={() => {handleRemoveQuestion(question)}}>
                    <DeleteIcon className={"h-22 w-22 contrast-blue-icon"}/>
                </IconButton>
            </EbloomTooltip>
        </div>
    )
}

PollCardMenu.propTypes = {
    question:PropTypes.object.isRequired,
    handleDuplicateQuestion:PropTypes.func.isRequired,
    handleRemoveQuestion:PropTypes.func.isRequired
}

export default PollCardMenu;
