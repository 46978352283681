import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {ReactComponent as PdfIcon} from "../../symbols/webloom/white/pdf-white.svg";
import {ReactComponent as FileIcon} from "../../symbols/webloom/white/file-white.svg";
import {ReactComponent as ImgIcon} from "../../symbols/webloom/white/img-white.svg";
import CustomButton from "../Button/CustomButton";
import _ from "lodash";

const FileDisplay = (props) => {

    //state
    const [type, setType] = useState("pdf");
    const [name, setName] = useState("");

    //props
    const {urlParams, displayImg, handleDelete, showIcon, imgHeight} = props;

    //hooks

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {
        if(urlParams !== null && urlParams !== undefined){
            let splitter = urlParams.split("?")[0];
            let typeSplitter = splitter.split(".");
            setType(typeSplitter[typeSplitter.length-1]);
            let nameWithType = decodeURI(splitter).split("/");
            let nameWithTypeNoCompany = nameWithType[nameWithType.length-1].split("-");
            nameWithTypeNoCompany.splice(0, 1);
            const fileName = nameWithTypeNoCompany.join("-")
            setName(fileName);
        }
    }, [urlParams])

    return (
        <div className={props.className}>
            {
                _.toLower(type) === "pdf" ?
                    <div className={"flex items-center"}>
                        {
                            showIcon &&
                            <PdfIcon className={'w-25 h-25 purple-icon mr-5'}/>
                        }
                        <a href={urlParams} style={{maxWidth:250}} className={"ebloom-link-purple max-w-250 truncate"}>{name}</a>
                        {
                            handleDelete &&
                            <CustomButton handleClick={handleDelete} type={"remove"} className={"ml-5"}/>
                        }
                    </div>
                        :
                        _.toLower(type) === "jpg" || _.toLower(type) === "png" || _.toLower(type) === "jpeg" ?
                            displayImg ?
                                <div className={"relative m-auto w-fit"}>
                                    {
                                        handleDelete &&
                                        <CustomButton handleClick={handleDelete} type={"remove"} className={"absolute right-0 top-0"}/>
                                    }
                                    <img src={urlParams} alt={name} crossOrigin={"anonymous"} className={"rounded-md"} style={{maxHeight:imgHeight !== null ? imgHeight : "auto"}}/>
                                </div>
                                :
                                <div className={"flex items-center"}>
                                    {
                                        showIcon &&
                                        <ImgIcon className={'w-25 h-25 purple-icon mr-5'}/>
                                    }
                                    <a href={urlParams} style={{maxWidth:250}} className={"ebloom-link-purple max-w-250 truncate"}>{name}</a>
                                    {
                                        handleDelete &&
                                        <CustomButton handleClick={handleDelete} type={"remove"} className={"ml-5"}/>
                                    }
                                </div>
                            :
                                <div className={"flex items-center"}>
                                    {
                                        showIcon &&
                                        <FileIcon className={'w-25 h-25 purple-icon mr-5'}/>
                                    }
                                    <a href={urlParams} style={{maxWidth:250}} className={"ebloom-link-purple truncate"}>{name}</a>
                                    {
                                        handleDelete &&
                                        <CustomButton handleClick={handleDelete} type={"remove"} className={"ml-5"}/>
                                    }
                                </div>
            }
        </div>
    )
}

FileDisplay.propTypes = {
    urlParams:PropTypes.string.isRequired,
    displayImg:PropTypes.bool,
    handleDelete:PropTypes.func,
    showIcon:PropTypes.bool,
    imgHeight:PropTypes.number
}

FileDisplay.defaultProps = {
    displayImg:false,
    showIcon:true,
}
export default FileDisplay