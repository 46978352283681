import React from "react";
import {PropTypes} from "prop-types";
import Form from "react-bootstrap/Form";
import {FullContrastPurpleTextField} from "../../../../Utils/Global";

const EbloomSelectV2 = (props) => {
    const {children, color, className, onChange, value, name} = props;


    return(
        <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={value} name={name} select onChange={onChange} className={className}>
            {children}
        </FullContrastPurpleTextField>
    )
}

EbloomSelectV2.defaultProps = {
    color:"contrast-purple"
}

EbloomSelectV2.propTypes = {
    onChange:PropTypes.func.isRequired,
    value:PropTypes.any.isRequired,
    name:PropTypes.any
}

export default EbloomSelectV2;
