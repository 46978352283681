import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import {Col, Row} from "react-bootstrap";
import TextFieldCustom from "./TextFieldCustom";
import {createFilterOptions, Divider, FormControl, MenuItem} from "@mui/material";
import _ from "lodash";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import {FullContrastPurpleTextField} from "../../../../Utils/Global";


const ModelForms = (props) => {

    //state

    //props
    const {model, handleChange, values} = props;

    //hooks
    const t = useTranslation();
    const filter = createFilterOptions()
    //getters

    //posters

    //handlers
    const handleChangeMultiple = (event, item, oldValues) => {
        let value = event.target.value;
        if(item.form.selectOptions.radioGroupBy) {
            if (value.length >= oldValues.length) { //unable to possibility to remove an option without selecting another one
                let newValue = value.filter(el => oldValues.indexOf(el) === -1);
                if (newValue && newValue.length > 0) {
                    const groupBy = item.form.selectOptions.groupBy;
                    const itemAdded = item.form.selectOptions.options.find(el => el.id === newValue[0])
                    const itemToRemove = item.form.selectOptions.options.find(el => el[groupBy] === itemAdded[groupBy] && el.id !== itemAdded.id && oldValues.indexOf(el.id) !== -1);
                    if (itemToRemove) {
                        const indexToRemove = value.indexOf(itemToRemove.id);
                        value.splice(indexToRemove, 1);
                    }
                }
                handleChange(event);
            }
        }else{
            handleChange(event);
        }


    }

    //useEffect

    useEffect(() => {
        if(model && model.initializeOptions){
            model.initializeOptions();
        }
    }, [])

    return (
        <div>
            {
               Object.entries(model).filter(([key, value]) => value.form && value.form.show === true && (!value.form.showCondition || value.form.showCondition(values) === true)).map(([key, value], index) => (
                   <Row key={index}>
                        <Col className={(value.form.type && value.form.type === "secondary") && "pl-50"}>
                            {
                                value.form.select && value.form.selectOptions.options && value.form.multiple ?
                                    <FormControl variant={"outlined"} fullWidth>
                                        <InputLabel className={"text-contrast-purple bg-bg-white"} id={value.id}>{_.upperFirst(t(value.form.label))}</InputLabel>
                                        <Select
                                            className={"text-purple border border-purple"}
                                            data-tour={value.id}
                                            label={_.upperFirst(t(value.form.label))}
                                            labelId={value.id}
                                            size={"medium"}
                                            id={value.id}
                                            multiple
                                            name={value.form.name}
                                            value={values[key] || []}
                                            onChange={(event) => {handleChangeMultiple(event, value, values[key])}}
                                            inputProps={{'aria-label': _.upperFirst(t("admin.advancedUserHelper"))}}
                                            renderValue={(selected) => (
                                                <div className={"m-2 flex"}>
                                                    {selected.map((item, index) => (
                                                        <div key={index} className={"px-10 m-5 bg-purple/[0.2] text-contrast-purple ebloom-text rounded-xl"}>
                                                            {value.form.selectOptions.options.find(el => el.id === item) ? value.form.selectOptions.options.find(el => el.id === item).name  : ""}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            <MenuItem key={-2} value={-2} disabled>{_.upperFirst(t(value.form.label))}</MenuItem>
                                            {
                                                value.form.selectOptions.showAll &&
                                                <MenuItem key={-1} value={-1}>
                                                    {_.capitalize(t("core.all"))}
                                                </MenuItem>
                                            }
                                            {
                                                value.form.selectOptions.showAll &&
                                                <Divider key={-3}/>
                                            }

                                            {value.form.selectOptions.options.map((item, index) => (
                                                    <MenuItem key={index} value={item.id} className={value.form.selectOptions.groupBy && (index === 0 || item[value.form.selectOptions.groupBy] !== value.form.selectOptions.options[index-1][value.form.selectOptions.groupBy]) ? "relative mt-20" : "relative"}>
                                                    {
                                                        value.form.selectOptions.groupBy && (index === 0 || item[value.form.selectOptions.groupBy] !== value.form.selectOptions.options[index-1][value.form.selectOptions.groupBy]) &&
                                                        <div className={"absolute -top-20 ebloom-small-text text-disabled-grey"}>{item[value.form.selectOptions.groupBy]}</div>
                                                    }
                                                        <div>{item.name}</div>
                                                    </MenuItem>

                                            ))}
                                        </Select>
                                        {
                                            value.form.helperText &&
                                            <FormHelperText className={"text-contrast-blue"}>{_.upperFirst(t(value.form.helperText))}</FormHelperText>
                                        }

                                    </FormControl>

                                    :

                                     value.form.select && value.form.selectOptions.showComboBox && value.form.selectOptions.options ?
                                        <Autocomplete
                                            noOptionsText={_.upperFirst(t("core.noOptions"))}
                                            onChange={(event,newValue) => handleChange(event, newValue,true)}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => _.lowerCase(inputValue) === _.lowerCase(option.name));
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        name : `${_.upperFirst(t("core.add"))} "${inputValue}"`
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.name;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                            value={values["company_filter"]}
                                            renderInput={(params) => (
                                                <FullContrastPurpleTextField placeholder={_.upperFirst(t("core.createOrSelectPlaceHolder"))} {...params} label={_.upperFirst(t("core.category.category"))} />
                                            )}
                                            options={value.form.selectOptions.options} />
                                        :
                                        value.form.select && value.form.selectOptions.options ?
                                            <TextFieldCustom model={value} value={values[key]} handleChange={handleChange}>
                                                <MenuItem key={-1} value={-1} disabled>{_.upperFirst(t(value.form.label))}</MenuItem>
                                                {
                                                    value.form.selectOptions.options && value.form.selectOptions.options.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                                                    ))
                                                }
                                            </TextFieldCustom>

                                    :
                                    <TextFieldCustom model={value} value={values[key]} handleChange={handleChange}/>
                            }

                        </Col>
                    </Row>
               ))
            }
        </div>
    )
}

ModelForms.propTypes = {
    model:PropTypes.object.isRequired,
    handleChange:PropTypes.func.isRequired,
    values:PropTypes.object.isRequired,
}

ModelForms.defaultProps = {}
export default ModelForms