import React from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as PollIcon} from "../../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as ValidateIcon} from "../../../../../Core/symbols/webloom/white/validate-white.svg";
import {ReactComponent as ForbiddenIcon} from "../../../../../Core/symbols/webloom/white/forbidden-white.svg";
import {ReactComponent as DangerIcon} from "../../../../../Core/symbols/webloom/white/danger-white.svg";
import {Badge, Button} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import {SurveyStatus, SurveyUsersStatus} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {getLanguage} from "react-multi-lang";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {useUserData} from "../../../../../../hooks/userHook";
import {Colors} from "../../../../../../Utils/Colors";

const PollCard = (props) => {

    //state

    const {survey,isProspect,setShowModal} = props;

    const isRunning = survey.status && survey.status === SurveyStatus.RUNNING && survey.lastAnswer === null;

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()

    //getters

    //posters

    //handlers

    const displayTimeLeft = () => {
        if(survey.end_date){
            let days = moment(survey.end_date).endOf("day").diff(moment(), "days").toString();
            let hours = moment(survey.end_date).endOf("day").diff(moment(), "hours").toString();

            return days > 0 ? _.upperFirst(t("webloom.daysToParticipate", {param:days})) : hours > 0 ? _.upperFirst(t("webloom.hoursToParticipate", {param:hours})) : _.upperFirst(t("webloom.lessThanAnHourToParticipate"))

        }
        return "";
    }

    //useEffect


    return (
        <div className={isRunning ? survey.userSurveyStatus === SurveyUsersStatus.UNREAD ? "bg-contrast-blue/[0.3] rounded-lg px-15 py-11 pointer" : "bg-contrast-blue/[0.1] rounded-lg px-15 py-11 pointer" : "bg-bg-grey/[0.7] rounded-lg px-15 py-11"} onClick={isProspect ? ()=> setShowModal(true) : isRunning ? () => {navigate("/webloom/poll/answer/" + survey.id, {state:survey})} : () => {}}>
            <div className={"flex justify-between items-center mb-20"}>
                <div className={"flex justify-start items-center"}>
                    <PollIcon className={isRunning ? "w-30 h-30 contrast-purple-icon mr-5" : "w-30 h-30 grey-icon mr-5"}/>
                    <div className={isRunning ? "ebloom-title font-semibold text-contrast-purple ebloom-very-large-text" : "ebloom-title font-semibold text-grey ebloom-very-large-text"}>
                        {_.upperFirst(t("webloom.poll"))}
                    </div>
                    <div className={ "mx-10"}>
                        {
                            !!user.companyGroup && !!survey.id_company && survey.id_company !== user.companyId &&
                            <Badge style={{backgroundColor: user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill className={"text-white"}>{user.companyGroup?.name}</Badge>
                        }
                    </div>
                </div>
                {
                    (survey.end_date || survey.lastAnswer) &&
                    <div className={"flex items-center"}>
                        {
                            survey.lastAnswer !== null ?
                                <div className={"flex items-center"}>
                                    <ValidateIcon className={"w-20 h-20 green-icon mr-5"}/>
                                    <div className={"text-grey"}>{_.upperFirst(t("webloom.iParticipatedOn", {param:moment(survey.lastAnswer).locale(getLanguage()).format("dddd Do MMMM YYYY")}))}</div>
                                </div>
                                    : survey.status === SurveyStatus.DONE ?
                                <div className={"flex items-center"}>
                                    <ForbiddenIcon className={"w-20 h-20 red-icon mr-5"}/>
                                    <div className={"text-grey"}>{_.upperFirst(t("webloom.iDidNotParticipate"))}</div>
                                </div>
                                : survey.end_date &&
                                <div className={"flex items-center"}>
                                    {
                                        moment(survey.end_date).diff(moment(), "days") < 3 &&
                                        <DangerIcon className={"red-icon w-20 h-20 mx-5"}/>
                                    }
                                    <div className={"text-contrast-purple"}>{displayTimeLeft()}</div>
                                </div>

                        }
                    </div>
                }
            </div>
            {
                isRunning &&
                    <div className={"mt-10 mb-10"}>
                        <span>{_.upperFirst(t("webloom.invitedToParticipate"))}</span>
                    </div>
            }

            <div className={isRunning ? "text-purple ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10" : "text-grey ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10"}>
                {survey.title}
            </div>
            {
                survey.image_url &&
                <FileDisplay displayImg urlParams={survey.image_url} imgHeight={300}/>
            }
            {
                isRunning &&
                <div className={"flex justify-end items-baseline"}>
                    <span className={"text-contrast-purple mr-5"}>{_.upperFirst(t("webloom.becauseMyVoiceMatter"))}</span>
                    <Button className={"ebloom-btn-purple ml-5"} size={"sm"} onClick={isProspect ? ()=> setShowModal(true) : () => {navigate("/webloom/poll/answer/" + survey.id, {state:survey})}}>{_.toUpper(t("webloom.iParticipate"))}</Button>
                </div>
            }
        </div>
    )
}

PollCard.propTypes = {
    survey:PropTypes.object.isRequired,
    setShowModal : PropTypes.func.isRequired,
    isProspect : PropTypes.bool.isRequired
}

PollCard.defaultProps = {}
export default PollCard