import React, {useState, useEffect} from "react";
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createErrorRecoveringNotification,
    createInfoNotification,
    createSuccessNotification, notifyCustomError, notifyError, notifySuccess,
} from '../../../../../Utils/Notification';
import {Button, Col, Row} from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import UserModel from "../../../../../models/User";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import {getLanguages} from "../../../../../lang/translations";
import Login from "../../../../../login/login";
import {FullContrastPurpleTextField, PurpleSwitch, Roles} from "../../../../../Utils/Global";
import {useUserData} from "../../../../../hooks/userHook";
import BackofficeInviteUsers from "../../../Modules/BackofficeInviteUsers";
import EbloomSwitch from "../../../../Core/Modules/Button/EbloomSwitch";

const UserEditor = (props) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({id:null, password:"", email:"", name:"", id_company:0, lang:"en", id_roles: [3]});
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [sideFormMultipleUsersOpen, setSideFormMultipleUsersOpen] = useState(false);
    const [selectedIdCompany, setSelectedIdCompany] = useState(null);
    const [selectedNumber, setSelectedNumber] = useState(0);
    const [tablePage, setTablePage] = useState(0);
    const [showInviteUsers, setShowInviteUsers] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const langs = getLanguages();

    const t = useTranslation();

    const userRedux = useUserData();

    const handleDelete = () => {
        fetchUsers();
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUser(prevState => {
            let user = {...prevState};
            if(name === "id_roles"){
                user[name] = [value];
            }else{
                user[name] = value;
            }

            return user;
        });
    };

    const handleChangeSelectedIdCompany = (event) => {
        setSelectedIdCompany(event.target.value);
    }

    const handleChangeSelectedNumber = (event) => {
        setSelectedNumber(event.target.value);
    }

    const handleSwitchSuperAdmin = (event) => {
        setIsSuperAdmin(event.target.checked)
    }

    const handleSaveMultipleUsers = () => {
        HttpApi.postV2("/users/createMultipleFake", {id_company: Number(selectedIdCompany), number:Number(selectedNumber)}).then(() => {
            notifySuccess(selectedNumber + " users successfully created !");
            setSideFormMultipleUsersOpen(false);
            setSelectedNumber(0);
            setSelectedIdCompany(null);
            fetchUsers();
        })
    }

    const handleSave = () => {
        let url = "/users/create";
        let HttpCall = HttpApi.postV2;
        const idSuperAdmin = roles.filter(el => el.code === Roles.SUPER_ADMIN[0]).length > 0 ? roles.filter(el => el.code === Roles.SUPER_ADMIN[0])[0].id : null
        let data = {email:user.email, name:user.name, password:user.password, id_company:user.id_company, lang:user.lang, id_roles: isSuperAdmin && idSuperAdmin !== null? [...user.id_roles,idSuperAdmin] : [...user.id_roles]};

        if(user.id){
            url= "/users/update";
            data.id = user.id;
            HttpCall = HttpApi.put;
        }

        HttpCall(url, data).then(response => {
           if(response){
               notifySuccess('User created/updated');
               fetchUsers();
               setSideFormOpen(false);
               resetUserEditState();
           }
        });
    };

    const loginAs = (id) => {
        HttpApi.postV2("/auth/loginAs", {id:id}).then(async (user) => {
            if (user) {
                await Login.reconnectApp(user);
                userRedux.setId(user.data.userId);
                userRedux.setEmail(user.data.email);
                userRedux.setName(user.data.name);
                userRedux.setCompany(user.data.company)
                userRedux.setIdCompany(user.data.id_company);
                userRedux.setIsSuperAdmin(user.data.is_super_admin);
                userRedux.setCompanyGroup(user.data.company_group);

                document.location.reload(true);
            } else {
                createErrorNotification("Error!", "User not found");
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const fetchUsers = () =>{
        setLoading(true);
        const url = "/users/getAll";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setUsers(response.data);
                setLoading(false);
            }else{
                setUsers([]);
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            notifyError(error);
        })
    }

    const fetchCompanies = () => {
        const url = "/companies/findAll";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setCompanies(response.data);
            }else{
                setCompanies([]);
                createInfoNotification("Info", "0 companies found");
            }
        }).catch(error => {
           notifyError(error);
        })
    };

    const fetchRoles = () => {
        if(userRedux.roles.includes("global_backoffice")){
            HttpApi.getV2("/roles/getAll").then(response => {
                if(response && response.data){
                    setRoles(response.data);
                }else{
                    setRoles([]);
                    createErrorRecoveringNotification("roles");
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const resetUserEditState = () => {
        setUser({id:null, email:"", name:"", password:"", id_company:0, lang:"en", id_roles:[3]});
        setIsSuperAdmin(false)
    };

    useEffect(() => {
        fetchUsers();
        fetchCompanies();
        fetchRoles();
    }, []);

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(toEdit){
            toEdit.password = "";
            setIsSuperAdmin(toEdit.is_super_admin)
            toEdit.id_roles = toEdit.id_roles.filter(el => el !== 6);
            setUser(toEdit);
        }else{
            resetUserEditState();
        }
        setSideFormOpen(open);
    };

    const toggleDrawerMultipleUsers = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSideFormMultipleUsersOpen(open);
    };

    const formTitle = user.id ? _.capitalize(t("core.update")) + " " + _.capitalize(t("core.user.user")) : _.capitalize(t("core.create")) + " " + _.capitalize(t("core.user.user"));

    let UserModelClone = _.cloneDeep(UserModel);
    UserModelClone.crud.delete = "/users/delete";

    return(
        <>
            {
                showInviteUsers ?
                    <BackofficeInviteUsers createdEmailsAndPhones={users.map(el => el.email)} handleBack={() => {setShowInviteUsers(false)}} handleSeePreviewAndReload={() => {setShowInviteUsers(false); fetchUsers();}}/>
                    :
                    <>
                        <CardDefault title={_.capitalize(t('core.user.users'))} handleRightButton={toggleDrawer(true)} showExtraRightButton extraRightButtonVariant={"link"} extraRightButtonText={"Create multiple users"} handleExtraRightButton={userRedux.roles.includes("global_backoffice") ? toggleDrawerMultipleUsers(true) : () => {setShowInviteUsers(true)}}>
                            <TableViewV2 loading={loading} items={users} model={UserModelClone} handleEdit={toggleDrawer} handleDelete={handleDelete} tablePage={tablePage} handleTablePage={handleTablePage} defaultOrderBy={"created_at"} defaultOrder={"desc"}/>
                        </CardDefault>
                        <SideForm toggleDrawer={toggleDrawerMultipleUsers} sideFormOpen={sideFormMultipleUsersOpen} handleSave={handleSaveMultipleUsers}>
                            <Row>
                                <TextFieldCustom model={UserModel.id_company} value={selectedIdCompany} handleChange={handleChangeSelectedIdCompany}>
                                    {
                                        companies.map(company => (
                                            <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                        ))
                                    }
                                </TextFieldCustom>
                            </Row>
                            <Row className={"mt10 mb10"}>
                                <Col>
                                    <FullContrastPurpleTextField label={"Number users to create"} value={selectedNumber} onChange={handleChangeSelectedNumber} variant={"outlined"} type={"number"}/>
                                </Col>
                            </Row>
                        </SideForm>
                        <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                            <Row>
                                <TextFieldCustom model={UserModel.email} value={user.email} handleChange={handleChange}/>
                            </Row>
                            <Row>
                                <TextFieldCustom model={UserModel.name} value={user.name} handleChange={handleChange}/>
                            </Row>
                            {
                                userRedux.roles.includes("global_backoffice") &&
                                <Row>
                                    <TextFieldCustom model={UserModel.password} value={user.password} handleChange={handleChange}/>
                                </Row>
                            }
                            <Row>
                                <TextFieldCustom model={UserModel.id_company} value={user.id_company} handleChange={handleChange}>
                                    {
                                        companies.map(company => (
                                            <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                        ))
                                    }
                                </TextFieldCustom>
                            </Row>
                            <Row>
                                <TextFieldCustom model={UserModel.lang} value={user.lang} handleChange={handleChange}>
                                    {
                                        langs.map(lang => (
                                            <MenuItem key={lang.value} value={lang.value}>{_.upperFirst(t("core.lang." + lang.value))}</MenuItem>
                                        ))
                                    }
                                </TextFieldCustom>
                            </Row>
                            {
                                userRedux.roles.includes("global_backoffice") &&
                                <Row>
                                    <TextFieldCustom model={UserModel.id_roles} value={user.id_roles} handleChange={handleChange} >
                                        {
                                            roles.filter(el=> el.code !== Roles.SUPER_ADMIN[0]).map(role => (
                                                <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                            ))
                                        }
                                    </TextFieldCustom>
                                </Row>
                            }
                            {
                                userRedux.roles.includes("global_backoffice") && user.company_group !== null &&
                                <Row>
                                    <Col className={"flex justify-center items-center"}>
                                        <p className={"ebloom-normal-text text-contrast-purple"}>Super-Admin :</p>
                                    </Col>
                                    <Col className={"flex justify-center items-center"}>
                                        <PurpleSwitch checked={isSuperAdmin} onChange={handleSwitchSuperAdmin}/>
                                    </Col>
                                </Row>
                            }
                            {
                              (userRedux.roles.includes("global_backoffice") && user.id) &&
                                <Row>
                                    <Button className={"ebloom-btn-purple ml10 mr10 mb10 mt10"} onClick={() => {loginAs(user.id)}}>Log in</Button>
                                </Row>
                            }
                        </SideForm>
                    </>
            }
        </>
    )

}

export default UserEditor;