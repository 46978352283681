import {ReactComponent as CloseIcon} from "../../../../Core/symbols/myBloom/icons/Close.svg";

import ScoreType from "./ScoreType";
import {getColorFromPercentage} from "../../../../../Utils/Global";
import PropTypes from "prop-types";
import _ from "lodash";
import React from "react";
import {useTranslation} from "react-multi-lang/lib";

const DetailsScores = (props) => {
    const {details, setShowDetails, isModelCustomEnable, isEbloomModel} = props

    const t = useTranslation()

    const calculateWidth = (cat) => {
        if (cat.total <= 0) {
            return 0
        } else {
            if (cat.score === 0) {
                return "5%"
            } else {
                return cat.score + "%"
            }
        }
    }


    return <div
        className={`fixed inset-0 flex items-center justify-center bg-grey/60 backdrop-blur-[1px] `}
        style={{zIndex: 210}}
    >
        <div>
            <div
                className="  max-h-500 w-full md:w-[600px] rounded-lg border-contrast-purple border-2  bg-white z-70 flex flex-col justify-start items-center relative p-30 divide-purple divide-y">
                <div className={"w-full flex justify-between flex-row items-center pb-10"}>
                    <div className={"w-full"}>
                        <ScoreType isModelCustomEnable={isModelCustomEnable} showPreviousDetails={true}
                                   category={details} showArrow={false}/>

                    </div>
                    <button className={" absolute w-30 h-30 right-10 top-10 bg-white rounded-full"}
                            onClick={() => setShowDetails(false)}>
                        <CloseIcon/>
                    </button>
                </div>

                <div className={"w-full flex flex-col justify-center items-center pt-10 overflow-y-auto"}>
                    {details.tags.sort((a, b) => {
                        if (_.lowerCase(a.tag).includes("general")) {
                            return 1;
                        } else if (_.lowerCase(b.tag).includes("general")) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }).map((tag, index) =>
                        <div key={index} className={"w-full grid grid-cols-12 mt-10"}>
                            <div className={"col-start-1 col-end-6"}>
                                {
                                    _.lowerCase(tag.tag).includes("general") && isEbloomModel
                                        ? <h1><span
                                            className={"font-bold mr-10"}>#</span>{_.upperFirst(t("core.general"))}</h1>

                                        :
                                        <h1><span className={"font-bold mr-10"}>#</span>{t("core.tag." + tag.tag)}</h1>

                                }

                            </div>
                            <div className={"col-start-8 col-end-11 flex justify-center items-center"}>
                                <div className={"w-full bg-[#D9D9D9] h-10 rounded-full relative"}>
                                    <div className={" h-full rounded-full float-left "}
                                         style={{
                                             width: calculateWidth(tag),
                                             backgroundColor: getColorFromPercentage(tag.score)
                                         }}>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-start-11 col-end-13 flex justify-center items-center"}>
                                <h1 className={"font-semibold text-center text-xl text-contrast-purple"}>{tag.total ? tag.score + "%" : _.toUpper(t("webloom.NA"))}</h1>
                            </div>

                        </div>
                    )}
                </div>

            </div>
        </div>
    </div>
}

DetailsScores.propTypes = {
    details: PropTypes.object.isRequired,
    setShowDetails: PropTypes.func.isRequired,
    isModelCustomEnable: PropTypes.bool,
    isEbloomModel: PropTypes.bool,
}

DetailsScores.defaultProps = {
    isModelCustomEnable: false,
    isEbloomModel: false
}

export default DetailsScores