import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useUserData} from "../../../hooks/userHook";
import moment from "moment/moment";
import {CompanyTypes} from "../../../Utils/Global";
import Login from "../../../login/login";
import HttpApi from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";
import HeaderEbloom from "../../Core/Layouts/HeaderEbloom";
import {Colors} from "../../../Utils/Colors";
import {SurveyStatus} from "../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {alpha} from "@mui/material/styles";

const DisplaySuperAdminView = ({...rest}) => {
    const {menu,children, bgColor,isAllEntities,bp,module,isDashboardSuperAdmin,showFilterCommonSurveys} = rest;

    const user = useUserData();

    const [entitiesToDisplay, setEntitiesToDisplay] = useState([]);
    const [startDate, setStartDate] = useState(new Date(moment().subtract((user.companyType === CompanyTypes.DEMO ? 6 : 3), "month").format()));
    const [endDate, setEndDate] = useState(new Date(moment().format()));
    const [formattedStartDate, setFormattedStartDate] = useState(moment().subtract((user.companyType === CompanyTypes.DEMO ? 6 : 3), "month").format("YYYY-MM-DD"));
    const [formattedEndDate, setFormattedEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [timeFilterVersion, setTimeFilterVersion] = useState(0);
    const [nbActiveSurvey, setNbActiveSurvey] = useState(0);
    const [collabAccess, setCollabAccess] = useState(false);
    const [nbAnswersSurvey, setNbAnswersSurvey] = useState(0);
    const [nbSurvey, setNbSurvey] = useState(0);
    const [shouldDownloadReport, setShouldDownloadReport] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);
    const [reportDisabled, setReportDisabled] = useState(true);
    const [menuName, setMenuName] = useState("noMenu");
    const [nbNotifGroup, setNbNotifGroup] = useState(0);
    const [teamsEntityToDisplay, setTeamsEntityToDisplay] = useState([]);
    const [entitiesDashGroup, setEntitiesDashGroup] = useState([]);
    const [colors, setColors] = useState(null);
    const [commonSurveyActivated, setCommonSurveyActivated] = useState(false);
    const [lockCommonSurvey, setLockCommonSurvey] = useState(false);

    const isAdmin = Login.hasRole("global_admin");
    const isFree = Login.hasModule("free");
    const idGroup = user.companyGroup.id;
    const drawMargin = (bp.pageSize > bp.breakpoints.tablet && menuName !== "noMenu") ? 240 : 0;

    const handleSwitchEntity = (entity) => {
        setCommonSurveyActivated(false)
        setLockCommonSurvey(true)
        setEntitiesToDisplay(entity);
    };

    const handleSwitchEntitiesForGroup  = (entity) => {
        setEntitiesDashGroup(entity)
    }

    const handleSwitchEntityTeams = (teams) => {
        if (entitiesToDisplay.length === 1 && entitiesToDisplay[0].id !== idGroup){
            let lockFilter = teams.length === 1
            if(lockFilter){
                if(teams[0].id === 0) {
                    setCommonSurveyActivated(false)
                }else{
                    setCommonSurveyActivated(true)
                }
            }else{
                setCommonSurveyActivated(false)
            }
            setLockCommonSurvey(lockFilter)
        }else{
            setLockCommonSurvey(true)
            setCommonSurveyActivated(false)
        }
        setTeamsEntityToDisplay(teams);
    };

    const handleSwitchStartDate = (date) => {
        setStartDate(date);
        setFormattedStartDate(date ? moment(date).format("YYYY-MM-DD") : null);
    }

    const handleSwitchEndDate = (date, reload = false) => {
        setEndDate(date);
        setFormattedEndDate(date ? moment(date).format("YYYY-MM-DD") : null);
        if (date || reload) {
            setTimeFilterVersion(timeFilterVersion + 1);
        }

    }

    const checkIfCollabAccess = () => {
        if (Login.hasRole("global_manager")) {
            HttpApi.getV2("/teams/getUserAdvancedCollabTeamsWithUsers").then(result => {
                if (result && result.data && result.data.length > 0) {
                    setCollabAccess(true);
                    checkForCollabSurveyAnswers();
                } else {
                    setCollabAccess(false);
                    if (menu === "collaborative") {
                        setMenuName("noMenu");
                    }
                }
            }).catch(error => {
                notifyError(error);
                setCollabAccess(false);
            })
        } else if (isAdmin) {
            checkForCollabSurveyAnswers();
        }
    }

    const checkNumberNotifGroup = () => {
        const url = "/collaborative/group/getAll/"+user.companyGroup.id
        HttpApi.get(url).then(response => {
            let list = []
            if(response && response.data){
                list = response.data;
            }
            const numberNotif = list.filter(el => el.nbResponses>el.lastNbAnswers).length;
            setNbNotifGroup(numberNotif)
        }).catch(error => {
            notifyError(error);
        })
    }

    const checkForCollabSurveyAnswers = () => {
        const url = "/collaborative/checkForNewCollabAnswers";
        HttpApi.get(url).then(response => {
            if (response && response.data) {
                const nb = response.data;
                if (window.location.pathname === "/admin/collaborative/active") {
                    HttpApi.post("/collaborative/updateLastNotif", {nbNotif: nb}).then(() => {
                        setNbAnswersSurvey(0);
                    }).catch(error => notifyError(error));
                } else {
                    setNbAnswersSurvey(nb);
                }
            } else {
                setNbAnswersSurvey(0);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const checkForCollabSurvey = () => {
        const url = "/collaborative/checkForActiveSurveyUser";
        HttpApi.get(url).then(response => {
            if (response && response.data) {
                const surveys = response.data;
                setNbActiveSurvey(surveys && surveys.activeSurveys ? surveys.activeSurveys.length : 0);
                setNbSurvey(surveys.nbSurveys);
            } else {
                setNbActiveSurvey(0);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleReportState = (value) => {
        setShouldDownloadReport(value);
    }

    const handleModalReport = (value) => {
        setShowModalReport(value);
    }

    const handleReportDisabled = (value) => {
        setReportDisabled(value);
    }

    const fetchCompanyColor = () => {
        HttpApi.get("/palette/getForCompany/" +(user.companyGroup ? user.companyGroup.id : 0)).then(response => {
            if(response && response.data){
                setColors(response.data)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const determineProps = (children) => {
      switch (module) {
          case "webloom":
              return children(
                  checkForCollabSurvey,
                  checkIfCollabAccess,
                  nbActiveSurvey,entitiesToDisplay,
                  isAllEntities
              );
          case "dashboard":
              return children(
                  entitiesToDisplay,
                  formattedStartDate,
                  formattedEndDate,
                  timeFilterVersion,
                  shouldDownloadReport,
                  handleReportState,
                  showModalReport,
                  handleModalReport,
                  handleReportDisabled,
                  teamsEntityToDisplay,
                  entitiesDashGroup,
                  colors,
                  commonSurveyActivated
              );
          default :
              return children(
                  entitiesToDisplay,
                  isAllEntities,
                  teamsEntityToDisplay,
              );
      }
    }

    useEffect(() => {
        checkNumberNotifGroup()
        fetchCompanyColor()
    }, []);

    useEffect(() => {
        setMenuName(menu);
    }, [menu])
    return (
        <div id="superAdmin" style={{backgroundColor : colors && !isAllEntities && isDashboardSuperAdmin ? alpha(colors.primary,.15) : Colors.EBLOOM_TRANSPARENT_BLUE}} className={"min-h-screen"} >
            <HeaderEbloom {...rest} handleSwitch={handleSwitchEntity} startDate={startDate} endDate={endDate}
                          handleSwitchStartDate={handleSwitchStartDate} handleSwitchEndDate={handleSwitchEndDate} handleSwitchEntityTeams={handleSwitchEntityTeams}
                          nbActiveSurvey={nbActiveSurvey} checkForCollabSurvey={checkForCollabSurvey}
                          checkIfCollabAccess={checkIfCollabAccess} collabAccess={collabAccess}
                          nbAnswersSurvey={nbAnswersSurvey} nbSurvey={nbSurvey} isFree={isFree}
                          handleModalReport={handleModalReport} shouldDownloadReport={shouldDownloadReport}
                          reportDisabled={reportDisabled} isAllEntities={isAllEntities} nbNotifGroup={nbNotifGroup}
                          entitiesToDisplay={entitiesToDisplay} teamsEntityToDisplay={teamsEntityToDisplay} handleSwitchGroup={handleSwitchEntitiesForGroup} entitiesDashGroup={entitiesDashGroup}
                          colors={colors} lockCommonSurvey={lockCommonSurvey} setCommonSurveyActivated={setCommonSurveyActivated} commonSurveyActivated={commonSurveyActivated}
            />
            <div style={{width: `calc(100% - ${drawMargin}px)`, marginLeft: drawMargin}}>
                {determineProps(children)}
            </div>
        </div>
    );
};

DisplaySuperAdminView.propTypes = {
    bgColor: PropTypes.string,
    showFilterCommonSurveys : PropTypes.bool

};

DisplaySuperAdminView.defaultProps = {
    bgColor: Colors.EBLOOM_WHITE_BLUE,
    showFilterCommonSurveys : false
}

export default DisplaySuperAdminView;
