import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Planner from "../../../../Admin/Pages/Questions/Planner/Planner";



const DailyEditorPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {(companyToEdit, unlockedBackoffice) => (
                <Container>
                    <React.Fragment>
                        <Planner companyToEdit={companyToEdit} unlockedBackoffice={unlockedBackoffice}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default DailyEditorPage;


