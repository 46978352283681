import React from "react";
import {Colors} from "../../../Utils/Colors";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {useNavigationTo} from "../../../hooks/navigationHook";

const DisplayPublicView = (props) => {
    const {children} = props;

    const navigate = useNavigationTo();

    const redirectHome = () => {
        navigate.to("/clicknbloom");
    };


    return(
        <div className={"bg-white-blue min-h-screen"}>
            <div className={"mb50 mr-0 ml-0"}>
                <div className={"grow-1 mb-20 px-0"}>
                    <AppBar position="static" className={"bg-contrast-purple"}>
                        <Toolbar>
                            <div className={"ebloom-logo-v2 mr-10 pointer"} onClick={redirectHome}/>
                        </Toolbar>
                    </AppBar>
                </div>
            </div>
            <div>
                {children}
            </div>
            <div className={"h-20"}/>
        </div>
    )
}

DisplayPublicView.defaultProps = {
    backgroundColor:Colors.EBLOOM_WHITE_BLUE,

};

export default DisplayPublicView;
