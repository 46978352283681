import React from "react";
import {Container} from "react-bootstrap";
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import PlannerEditor from "./PlannerEditor";

const PlannerEditorPage = (props) => {
    return(
        <DisplayBackofficeView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <PlannerEditor companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayBackofficeView>
        )
}

export default PlannerEditorPage;