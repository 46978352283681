import React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import EditIcon from "@mui/icons-material/CreateOutlined";
import DeleteModal from "../Modal/DeleteModal";
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import IconButton from "@mui/material/IconButton/IconButton";
import {ContentCopy} from "@mui/icons-material";

const ActionButtons = (props) => {

    const {showEdit, showDelete,showDuplicate,handleDuplicate,handleEdit, handleDelete, url, id,getTextConfirmation} = props;

    const t = useTranslation();

    return(
        <div className={"inline-block"}>
            {
                showEdit &&
                    <OverlayTrigger overlay={
                        <Tooltip>
                            {_.capitalize(t("core.edit"))}
                        </Tooltip>
                    }>
                        <IconButton data-tour={props.dataTour} className={"text-purple"} size="small" onClick={handleEdit}><EditIcon/></IconButton>
                    </OverlayTrigger>
            }
            {
                showDelete &&
                <DeleteModal url={url} handleDelete={handleDelete} id={id} method={props.method} getTextConfirmation={getTextConfirmation}/>
            }
            {
                showDuplicate &&
                <OverlayTrigger overlay={
                    <Tooltip>
                        {_.capitalize(t("core.duplicate"))}
                    </Tooltip>
                }>
                    <IconButton className={"text-purple "} size="small" onClick={handleDuplicate}>
                        <ContentCopy className={"w-20"}/>
                    </IconButton>
                </OverlayTrigger>
            }

        </div>
    )
}

ActionButtons.defaultProps = {
    showEdit:true,
    showDelete:true,
    showDuplicate:false,
    handleDuplicate: () => {},
    handleDelete: () => {},
    handleEdit: () => {},
    id:0,
    url:"",
    dataTour: '',
    method: 'POST',
    getTextConfirmation: () => {return null}
};

ActionButtons.propTypes = {
    url:PropTypes.string,
    handleDelete:PropTypes.func,
    id:PropTypes.number,
    handleEdit:PropTypes.func,
    handleDuplicate:PropTypes.func,
    showEdit:PropTypes.bool,
    showDelete:PropTypes.bool,
    showDuplicate:PropTypes.bool,
    dataTour : PropTypes.string,
    method: PropTypes.string,
    getTextConfirmation: PropTypes.func,
};

export default ActionButtons;
