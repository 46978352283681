import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Log from "./Log";



const LogPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <Log companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
}

export default LogPage;