import React, {useContext, useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import HttpApi from "../../../../../httpApi";
import {
    createErrorCreateNotification,
    createSuccessfullyCreatedNotification,
    notifyError,
    notifySuccess
} from "../../../../../Utils/Notification";
import PlaylistModel from "../../../../../models/Playlist";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import {getUrl, getUrlForBackoffice, PurpleTextField, TourType} from "../../../../../Utils/Global";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FreeTrialModal from "../../../../Core/Modules/Modal/FreeTrialModal";
import Login from "../../../../../login/login";
import CreatePlaylistTour from "../../../../../Utils/ProductTour/Tours/CreatePlaylist/CreatePlaylistTour";
import {ProductTourContext} from "../../../../../Utils/Context/ProductTourContext";
import {useUserData} from "../../../../../hooks/userHook";

const PlaylistList = (props) => {
    const [playlists, setPlaylists] = useState([]);
    const [playlist, setPlaylist] = useState({id:null, name:""});
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [anchorPlaylist, setAnchorPlaylist] = useState(null);
    const [tablePage, setTablePage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [duplicateName, setDuplicateName] = useState("");
    const [showModalFreeTrial, setShowModalFreeTrial] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(null);

    const navigate = useNavigate();
    const t = useTranslation()
    const user = useUserData()
    const {companyToEdit,selectedEntity} = props;

    const isFree = Login.hasModule("free");
    const isSuperAdmin = user.roles.includes("global_super_admin");
    const {updateStep,setContinuous,tourStarted,tourActivated,validateOutcome} = useContext(ProductTourContext)

    const handleOpenModalFreeTrial = () => {
        setShowModalFreeTrial(true);
    }

    const handleCloseModalFreeTrial = () => {
        setShowModalFreeTrial(false);
    }

    const fetchPlaylist = () => {
        let url = "/playlists/getForCompany/"
        if(isSuperAdmin && selectedEntity){
            url += "/"+selectedEntity
        }
        if(url){
            HttpApi.getV2(url).then(results => {
                if (results && results.data) {
                    setPlaylists(results.data);
                }else{
                    setPlaylists([]);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const handleSave = () => {

        let url = playlist.id ? "/playlists/update" : "/playlists/create";
        if(selectedEntity && selectedEntity === companyInfo?.id){
            if(selectedEntity){
                url += "/"+selectedEntity
            }
        }
        HttpApi.postV2(url, playlist).then(results => {
            if(results){
                createSuccessfullyCreatedNotification("playlist");
                fetchPlaylist();
                if(tourActivated){
                    if(tourStarted){
                        setContinuous(true)
                        setTimeout(()=>{
                            updateStep(TourType.CREATE_PLAYLIST,3)
                        },200)
                    }else{
                        validateOutcome(TourType.CREATE_PLAYLIST)
                        updateStep(TourType.CREATE_PLAYLIST,0)
                    }
                }

                setSideFormOpen(false);
            }else{
                createErrorCreateNotification("playlist");
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const generateUniqueName = (baseName, existingNames) => {
        const basePattern = new RegExp(`${baseName} - Copy \\((\\d+)\\)$`);

        const matchingNames = existingNames.filter(name => basePattern.test(name));

        if (matchingNames.length === 0) {
            return `${baseName} - Copy (1)`;
        } else {
            const highestNumber = matchingNames.reduce((max, name) => {
                const match = name.match(/\((\d+)\)$/);
                const currentNumber = match ? parseInt(match[1]) : 0;
                return currentNumber > max ? currentNumber : max;
            }, 0);
            return `${baseName} - Copy (${highestNumber + 1})`;
        }
    }

    const handleDuplicate = (item = null) => {
        let data = {
            id_playlist:playlist.id,
            name:duplicateName
        }
        if(item){
            const names = playlists.map(el => el.name)
            data.name = generateUniqueName("eBloom Selection", names)
            data.id_playlist = item.id
        }
        let url = "/playlists/duplicate"
        if(selectedEntity){
            url += "/"+selectedEntity
        }
        HttpApi.postV2(url, data).then(() => {
            fetchPlaylist();
            notifySuccess("Playlist successfully duplicated");
        }).catch(error => {
            notifyError(error);
        }).finally(() => setShowModal(false));
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setPlaylist(prevState => {
            let playlist = {...prevState};
            playlist[name] = value;
            return playlist;
        })
    };

    const handleChangeDuplicateName = (event) => {
        setDuplicateName(event.target.value);
    }

    const handleDelete = () => {
        fetchPlaylist();
    };

    const handleView = (item) => event => {
        let url = companyToEdit ? "/backoffice/questions/playlist/" + item.id : "/admin/questions/playlist/" + item.id;
        if(user.roles.includes("global_super_admin")) {
           url += "/" + selectedEntity
        }
        navigate(url);
    };

    const handleClose = () => {
        setAnchorPlaylist(null);
    };

    const handleOpenModal = () => {
        setShowModal(true);
        setAnchorPlaylist(null);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleOpenMenu = (open, toEdit) => event => {
        setAnchorPlaylist(event.currentTarget);
        setPlaylist(toEdit);
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }


    const resetPlaylistEditState = () => {
        setPlaylist({id:null, name:""});
    };

    const checkCompanyToEdit = () => {
        if(companyToEdit){
            if(companyToEdit.company_group_id){
                const data = {
                    id : companyToEdit.company_group_id,
                    name : companyToEdit.company_group_name
                }
                setCompanyInfo(data)
            }else if(companyToEdit.companies_entities.length > 0){
                const data = {
                    id : companyToEdit.id,
                    name : companyToEdit.name
                }
                setCompanyInfo(data)
            }
        }else{
            if(user.isSuperAdmin && user.companyGroup !== null && selectedEntity !== null) {
                const data = {
                    id : user.companyGroup.id,
                    name : user.companyGroup.name
                }
                setCompanyInfo(data)
            }
        }
    }


    useEffect(() => {
        checkCompanyToEdit()
        fetchPlaylist();
    }, [])

    useEffect(() => {
        checkCompanyToEdit()
        fetchPlaylist();
    }, [props.companyToEdit,selectedEntity]);



    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (toEdit) {
            setPlaylist(toEdit);
            setAnchorPlaylist(null);
        } else {
            resetPlaylistEditState();
        }
        setSideFormOpen(open);
        if(open) {
            setTimeout(() => {
                updateStep(TourType.CREATE_PLAYLIST, 2)
            }, 360)
        }else{
            setContinuous(true)
            document.querySelectorAll("[data-tour=row]").length >0 ? updateStep(TourType.CREATE_PLAYLIST, 3) : updateStep(TourType.CREATE_PLAYLIST, 1)
        }
    };

    const formTitle = playlist.id ? _.capitalize(t("core.playlist.rename")) : _.capitalize(t("core.playlist.create"));

        return (
            <>
                <CreatePlaylistTour setSideForm={setSideFormOpen}/>
                <CardDefault title={"Playlists"} showButton={!props.companyToEdit} handleRightButton={isFree ? handleOpenModalFreeTrial : toggleDrawer(true)} buttonText={isFree ? <div><span>{_.capitalize(t("core.create"))}</span> <LockOutlinedIcon fontSize={"small"}/></div> : _.capitalize(t("core.create"))} tourDataset={{card:'',leftBtn:'',btn:'createPlaylist'}} tourTooltip={true} tourType={TourType.CREATE_PLAYLIST}>
                    {
                        playlists.length > 0 &&
                        <TableViewV2 items={playlists} model={PlaylistModel} showActions={!props.companyToEdit} handleEdit={handleOpenMenu} handleDelete={handleDelete} handleRowClick={handleView} rowCursor={"pointer"} tablePage={tablePage} handleTablePage={handleTablePage} handleDuplicate={handleDuplicate}/>

                    }
                </CardDefault>
                <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave} tourDataset={{card:'createSideFormPlaylist',btn:''}}>
                    <Row>
                        <TextFieldCustom data-teams={'inputName'} model={PlaylistModel.name} value={playlist.name}  handleChange={handleChange}/>
                    </Row>
                </SideForm>
                <Menu id="playlist_menu"
                      anchorEl={anchorPlaylist}
                      keepMounted
                      open={Boolean(anchorPlaylist)}
                      onClose={handleClose}
                >
                    <List subheader={<ListSubheader>{_.upperFirst(t("core.editParam", {param:_.upperFirst(playlist.name)}))}</ListSubheader>}>
                        <MenuItem key={1} onClick={toggleDrawer(true, playlist)}>
                            <ListItemText primary={_.capitalize(t('core.rename'))}/>
                        </MenuItem>
                       <MenuItem key={2} onClick={handleView(playlist)}>
                           <ListItemText primary={_.capitalize(t("core.edit"))}/>
                       </MenuItem>
                        <MenuItem key={3} onClick={handleOpenModal}>
                            <ListItemText primary={_.capitalize(t("core.duplicate"))}/>
                        </MenuItem>
                    </List>
                </Menu>

                <ConfirmModal handleClose={handleCloseModal} show={showModal} title={_.capitalize(t("core.duplicateParam", {param:playlist.name}))} showButtons buttonRightText={_.capitalize(t("core.save"))} buttonLeftText={_.capitalize(t("core.cancel"))} handleChange={handleDuplicate}>
                    <PurpleTextField fullWidth variant="outlined" label={_.capitalize(t("core.name"))} value={duplicateName} onChange={handleChangeDuplicateName} />
                </ConfirmModal>
                <FreeTrialModal show={showModalFreeTrial} handleClose={handleCloseModalFreeTrial}/>
             </>
        )

}

export default PlaylistList;