import EditIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Divider, FormControl, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../../../Utils/Colors";
import {
    FullContrastBlueTextField, FullContrastPurpleTextField,
    Modules,
    WhiteBlueSwitch
} from "../../../../../../Utils/Global";
import {
    createSuccessfullyDeletedNotification,
    createSuccessNotification, notifyCustomError,
    notifyError, notifySuccess
} from "../../../../../../Utils/Notification";
import { useUserData } from "../../../../../../hooks/userHook";
import HttpApi from "../../../../../../httpApi";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import EbloomTooltip from "../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import SideForm from "../../../../../Core/Modules/Views-Tables-Lists/SideForm";

const AutoPlanner = (props) => {
    const {autoPlannerData,companyToEdit = null,playlists,frequencies,teams,selectedEntity = null,fetchAutoPlanners,shouldShowDelete = false,isSingle=false,handleToday, fetchNumberFutureQuestions, usedPlaylists,isStarter,otherAutoPlanners,handleOpenModalFree} = props

    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [autoplanner, setAutoplanner] = useState({...autoPlannerData});
    const [showModalStartPlanner, setShowModalStartPlanner] = useState(false);
    const [loadingPlannerNow, setLoadingPlannerNow] = useState(false);
    const [loadingPlannerNext, setLoadingPlannerNext] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([-1]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(autoPlannerData.id_playlist);
    const [selectedFrequency, setSelectedFrequency] = useState(autoPlannerData.id_frequency);
    const [showFrequency, setShowFrequency] = useState(false);
    const [frequency, setFrequency] = useState(null);
    const [openStartModal, setOpenStartModal] = useState(false);
    const [autoPlannerAccessDenied, setAutoPlannerAccessDenied] = useState(false);
    const [modalFrequencyEmpty, setModalFrequencyEmpty] = useState(false);
    const [openModifiedActivePlanner, setOpenModifiedActivePlanner] = useState(false);
    const [showModalStartAutoPlanner, setShowModalStartAutoPlanner] = useState(false);
    const [showModalDeleteQuestions, setShowModalDeleteQuestions] = useState(false);
    const [showModalUsedPlaylist, setShowModalUsedPlaylist] = useState(false);
    const [showModalDeleteAutoPlanner, setShowModalDeleteAutoPlanner] = useState(false);
    const [confirmDeleteQuestions, setConfirmDeleteQuestions] = useState(true);


    const t = useTranslation();
    const user = useUserData();
    const navigate = useNavigate();
    const isFree = user.module === Modules.FREE[0];
    const week = ["monday","tuesday","wednesday","thursday","friday"];
    const recurrencies = [{value:1, name:"core.everyWeek"}, {value:2, name:"core.everyTwoWeeks"}, {value:3, name:"core.everyThreeWeeks"}, {value:4, name:"core.everyFourWeeks"}];

    const usedPlaylistActive = useMemo(() => {
        return otherAutoPlanners.filter(el => el.active).map(el => el.id_playlist);
    }, [otherAutoPlanners]);

    const lastDateCB = useMemo(() => {
        if (!autoPlannerData.auto_planner_surveys || autoPlannerData.auto_planner_surveys.length === 0) {
            return null;
        }
        return autoPlannerData.auto_planner_surveys.reduce((latest, survey) => {
            const surveyDate = new Date(survey.survey_question.date);
            return surveyDate > latest ? surveyDate : latest;
        }, new Date(0));
    },[autoPlannerData])

    const emptyFrequencies = useMemo(() => {
        return frequencies.filter(el => week.reduce((previousValue, currentValue) => previousValue + el[currentValue],0) === 0).map(el => el.id);
    }, [frequencies]);

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setSelectedTeams(autoPlannerData.auto_planner_teams.length > 0 ? autoPlannerData.auto_planner_teams.map(el => el.team.id) : [-1])
            setAutoplanner(autoPlannerData)
        }
        setSideFormOpen(open);
    }

    const handleCheckBoxDelete = (event) => {
        const value = event.target.checked;
        setConfirmDeleteQuestions(value)
    }

    const handleOpenFrequency = (id) => {
        setFrequency(frequencies.find(el => el.id === id) || null);
        setShowFrequency(true);
    }

    const handleCloseFrequency = () => {
        setShowFrequency(false);
        setFrequency(null);

    }

    const handleCloseModal = () => {
        setShowModalStartPlanner(false);
    }

    const handleCloseModalStart = () => {
        setOpenStartModal(false);
    }

    const handleCloseModifiedPlannerModal= () => {
        setOpenModifiedActivePlanner(false);
    }

    const handleSaveChanges = (infos = null,launchPlanner = false,timing="now") => {
        setShowModalStartPlanner(false);
        const data = {
            id : autoplanner.id,
            id_playlist : selectedPlaylist,
            id_frequency : selectedFrequency,
            active : false,
            auto_planner_teams : selectedTeams.length === 1 && selectedTeams[0] === -1 ? [] : selectedTeams
        }
        let url = "/frequencies/autoPlanner/update"
        if(!!selectedEntity){
            url = "/frequencies/autoPlanner/update/"+selectedEntity
        }

        HttpApi.put(url,!!infos ? infos : data).then((response) => {
            if(response && response.data){
                if(infos === null) notifySuccess(_.upperFirst(t("admin.autoPlanner.updateAutoPlannerSuccess")))
                setSideFormOpen(false)
                setOpenModifiedActivePlanner(false)
                if(launchPlanner){
                    startAutoPlanner(timing)
                }else{
                    fetchAutoPlanners();
                }
            }
        }).catch(error => {
            notifyError(error);
        })

    }

    const handleDeleteAutoPlanner = (id) => {
        let url = "/frequencies/autoPlanner/delete"
        if (!!selectedEntity) {
            url += "/" + selectedEntity
        }
        const deleteQuestions = confirmDeleteQuestions;
        HttpApi.postV2(url, {id: id,deleteQuestions}).then(response => {
            if(response && response.data){
                createSuccessfullyDeletedNotification("auto planner");
                setSideFormOpen(false)
                fetchAutoPlanners();

            }

        }).catch(error => {
            notifyError(error);
        })
    }
    const startAutoPlanner = (type) => {

        if(!loadingPlannerNow && !loadingPlannerNext){
            if(type === "now"){
                setLoadingPlannerNow(true);
            }else{
                setLoadingPlannerNext(true);
            }
            let url = "/frequencies/autoPlanner/updateActiveState"
            if(!!selectedEntity){
                url = "/frequencies/autoPlanner/updateActiveState/"+selectedEntity
            }
            const data = {id_auto_planner:autoplanner.id,active:true,timing:type}
            HttpApi.put(url, data).then((response) => {
                if(response) {
                    if (response.data) {
                        createSuccessNotification(_.upperFirst(t("admin.autoPlanner.name")), _.upperFirst(t("admin.autoPlanner.notifActivated")));
                        setAutoplanner({...autoplanner, active: true})
                        handleToday();
                        fetchNumberFutureQuestions();
                        fetchAutoPlanners()
                    } else {
                        setAutoplanner({...autoplanner, active: false})
                        setAutoPlannerAccessDenied(true);
                    }
                }else{
                    setAutoplanner({...autoplanner, active: false})
                }
            }).catch((error) => {
                notifyError(error);
            }).finally(() => {
                setOpenStartModal(false)
                setLoadingPlannerNow(false);
                setLoadingPlannerNext(false);
            })
        }
    }


    const switchAutoPlanner =(event) => {
        let value = event.target.checked;
        if(autoplanner.active && !value){ //Disable it
            if(lastDateCB){
                setShowModalDeleteQuestions(true)
            }else{
                handleChangeActive(false,false)
            }
        }else{ // Enable it

            //Check used playlist
            if(usedPlaylistActive.includes(autoplanner.id_playlist)){
                setShowModalUsedPlaylist(true)
            }else{
                //Check Frequency
                const totalCbFrequency = week.reduce((previousValue, currentValue) => previousValue + autoPlannerData.frequency[currentValue],0)
                if(totalCbFrequency === 0){
                    setModalFrequencyEmpty(true)
                }else{
                    setOpenStartModal(true);
                }
            }


        }
    }

    const handleChangeActive = (deleteQuestion=false,value = false) => {
        let url = "/frequencies/autoPlanner/updateActiveState"
        if(!!selectedEntity){
            url = "/frequencies/autoPlanner/updateActiveState/"+selectedEntity
        }
        const data = {id_auto_planner:autoplanner.id,active:false,timing:'now',deleteQuestion}
        HttpApi.put(url,data).then(() => {
            setAutoplanner({...autoplanner, active:value})
            fetchAutoPlanners()
            handleToday();
            fetchNumberFutureQuestions()
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleChange = (event) => {
        event.preventDefault()
        let value = event.target.value
        let name = event.target.name
        if(isSingle){
            let autoplannerInfos = {...autoplanner}
            let teamsSelected = selectedTeams
            if(name === "teams") {
                teamsSelected = []
                if (value.length > 0) {
                    if (value.includes(-1) && selectedTeams.includes(-1)) {
                        setSelectedTeams(value.filter(el => el !== -1))
                        teamsSelected = value.filter(el => el !== -1)
                    } else if (value.includes(-1) && !selectedTeams.includes(-1)) {
                        setSelectedTeams([-1])
                        teamsSelected = []
                    } else {
                        setSelectedTeams(value.filter(el => el !== -1))
                        teamsSelected = value.filter(el => el !== -1)
                    }
                }
                autoplannerInfos.auto_planner_teams = teamsSelected
            }else {
                if(name === "id_frequency"){
                    const frequency = frequencies.find(el => el.id === value)
                    if(frequency && !emptyFrequencies.includes(frequency.id)){
                        const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                        const playlist = playlists.find(el => el.id === selectedPlaylist)
                        if(playlist && playlist.nbCB < nbCB ){
                            setSelectedFrequency(value)
                            setSelectedPlaylist(0)
                        }else {
                           setSelectedFrequency(value)
                        }
                    }
                }else if(name === "id_playlist"){
                    const playlist = playlists.find(el => el.id === value)
                    if(playlist || value === 0){
                        if(value === 0 && !usedPlaylistActive.includes(value)){
                            setSelectedPlaylist(value)
                        }else if(!(usedPlaylistActive.includes(playlist.id) || playlist.nbCB < week.reduce((previousValue, currentValue) => previousValue + autoplanner.frequency[currentValue],0))){
                            setSelectedPlaylist(value)
                        }
                    }
                }
            }
            autoplannerInfos.auto_planner_teams = teamsSelected.length === 1 && teamsSelected[0] === -1 ? [] : teamsSelected
            setAutoplanner({...autoplannerInfos,id_playlist : selectedPlaylist, id_frequency : selectedFrequency})

        }else{
            if(name === "teams"){
                if(value.length > 0){
                    if(value.includes(-1) && selectedTeams.includes(-1)){
                        setSelectedTeams(value.filter(el => el !== -1))
                    }else if(value.includes(-1) && !selectedTeams.includes(-1)){
                        setSelectedTeams([-1])
                    }else{
                        setSelectedTeams(value.filter(el => el !== -1))
                    }
                }

            }else{
                if(name === "id_frequency"){
                    const frequency = frequencies.find(el => el.id === value)
                    if(frequency && !emptyFrequencies.includes(frequency.id)){
                        const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                        const playlist = playlists.find(el => el.id === autoplanner.id_playlist)
                        if(playlist && playlist.nbCB < nbCB ){
                            setSelectedPlaylist(-1)
                            setSelectedFrequency(value)
                            setAutoplanner({...autoplanner, [name]:value, id_playlist:-1})
                        }else {
                            setSelectedFrequency(value)
                            setAutoplanner({...autoplanner, [name]:value})
                        }
                    }

                }else{
                    if (name === "id_playlist") {
                        const playlist = playlists.find(el => el.id === value)
                        if(playlist || value === 0){
                            if(value === 0 && !usedPlaylistActive.includes(value)){
                                setSelectedPlaylist(value)
                                setAutoplanner({...autoplanner, [name]: value})

                            }else if(!(usedPlaylistActive.includes(playlist.id) || playlist.nbCB < week.reduce((previousValue, currentValue) => previousValue + autoplanner.frequency[currentValue],0))){
                                setSelectedPlaylist(value)
                                setAutoplanner({...autoplanner, [name]: value})

                            }

                        }
                    }
                }
            }
        }

    }

    const handleCancelEdit = () => {
        setSelectedFrequency(autoPlannerData.id_frequency)
        setSelectedPlaylist(autoPlannerData.id_playlist)
        if(teams.length > 0){
            setSelectedTeams(autoPlannerData.auto_planner_teams.length > 0 ? autoPlannerData.auto_planner_teams.map(el => el.team.id) : [-1])
        }
    }

    const handleModalModifiedPlanner= (type = "cancel",active = false) => {
        if(type === "cancel" && active){
            if(!isSingle){
                setSideFormOpen(true)
            }
            setOpenModifiedActivePlanner(false)
        }else if (type === "cancel") {
            handleSaveChanges()
            handleCloseModifiedPlannerModal()
        }else{
            handleSaveChanges(null,true,type)
        }

    }

    const checkIfModified = () => {
        if(autoPlannerData.id_playlist !== selectedPlaylist || autoPlannerData.id_frequency !== selectedFrequency ){
            return true
        }
        if (autoPlannerData.auto_planner_teams.length === 0){
            return !(selectedTeams.length === 1 && selectedTeams[0] === -1);
        }
        return !(selectedTeams.length === autoPlannerData.auto_planner_teams.length && selectedTeams.every(teamId => autoPlannerData.auto_planner_teams.map(el => el.id_team).includes(teamId)));
    }

    const sendMsgNeedToSaveBeforeModifyingIt = () => {
        notifyCustomError("You need to save the auto-planner before enable/disable it.")
    }

    useEffect(() => {
        setSelectedTeams(autoPlannerData.auto_planner_teams.length > 0 ? autoPlannerData.auto_planner_teams.map(el => el.team.id) : [-1])
        setAutoplanner(autoPlannerData)
    }, [autoPlannerData]);

    const handleOpenModalStartPlanner = () => {
        const frequency = frequencies.find(el => el.id === selectedFrequency)
        const totalCbFrequency = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
        if(totalCbFrequency === 0){
            handleModalModifiedPlanner("cancel")
            setSideFormOpen(false)
        }else{
            setSideFormOpen(false)
            setOpenModifiedActivePlanner(true)
        }
    }

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: Colors.EBLOOM_WHITE_BLUE,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: Colors.EBLOOM_PURPLE ,
            opacity : 0.7

        }
    }));

    return autoPlannerData &&  (
        <div className={"w-full h-full"}>
            <div className={"w-full h-full flex justify-center items-center"}>
                <Card data-planCB={'autoPlanner'} className={"bg-white-blue border border-contrast-blue h-full  w-full " } onClick={isFree ? handleOpenModalFree : () => {}}>
                    <Card.Header className={"w-full text-center bg-contrast-blue text-white ebloom-very-large-text ebloom-text p-0"}>
                        <div className={"grid grid-cols-12"}>

                            <div className={"col-start-4 col-end-10 flex justify-center items-center"}>
                                <span>Auto-Planner</span>
                                {(isFree) &&
                                    <LockOutlinedIcon className={"ml-5"}/>
                                }
                            </div>

                            <div className={"col-start-10 col-end-13 flex justify-center items-center"}>
                                {
                                    isFree ?
                                        <WhiteBlueSwitch data-planCB={'switchAutoPlanner'} disabled={true} checked={false} name={"autoActive"} />
                                        :
                                        <GreenSwitch data-planCB={'switchAutoPlanner'}
                                                     disabled={isFree}
                                                     checked={autoplanner.active} name={"autoActive"}
                                                     onChange={checkIfModified() && isSingle ? () => sendMsgNeedToSaveBeforeModifyingIt() : switchAutoPlanner}/>
                                }

                            </div>
                        </div>


                    </Card.Header>
                    {
                        isSingle || isFree ?
                            <Card.Body className={"w-full relative h-160 "}>
                                <Row md={(!isStarter && teams.length > 0) ? 3 : 2} className={"h-full"}>
                                    <Col className={"flex justify-center items-center relative"}>
                                        <FullContrastBlueTextField disabled={isFree} data-planCB={'changeFrequency'} name={"id_frequency"} onChange={handleChange} value={selectedFrequency} fullWidth={true} size={"small"} select={true} label={_.upperFirst(t("admin.frequency"))}>
                                            {
                                                frequencies.map((item, index) => {
                                                    if(emptyFrequencies.includes(item.id)){
                                                        return(
                                                            <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                                <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.emptyFrequencyTitle"))} placement={"left"}>
                                                                    <p>{item.title}</p>
                                                                </EbloomTooltip>
                                                            </MenuItem>
                                                        )

                                                    }
                                                    return <MenuItem key={index} value={item.id} disabled={(playlists.find(el => el.id === autoplanner.id_playlist) && autoplanner.id_playlist !== 0  ? playlists.find(el => el.id === autoplanner.id_playlist).nbCB < week.reduce((previousValue, currentValue) => previousValue + item[currentValue],0) : false)}>{item.title}</MenuItem>

                                                })
                                            }
                                        </FullContrastBlueTextField>
                                        <div className={"absolute bottom-0 cursor-pointer"} onClick={isFree? () => {} :() => {handleOpenFrequency(selectedFrequency)}}>
                                            <span className={"text-contrast-blue underline"}>
                                                {_.upperFirst(t("admin.autoPlanner.viewFrequency"))}
                                                {
                                                    isFree &&
                                                    <LockOutlinedIcon fontSize={"small"} className={"text-contrast-blue"}/>
                                                }
                                            </span>
                                        </div>
                                    </Col>
                                    <Col className={"flex justify-center items-center"} >
                                        <FullContrastBlueTextField  disabled={isFree} data-planCB={'selectPlaylist'} onChange={handleChange} name={"id_playlist"}
                                                                   value={selectedPlaylist} fullWidth={true} size={"small"}
                                                                   select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                                            {
                                                usedPlaylistActive.includes(0) && autoplanner.id_playlist !== 0 ?
                                                    <MenuItem key={-1} value={0} disabled={true} className={"pointer-events-auto"}>
                                                        <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                                            <p>{_.upperFirst(t("core.playlist.library"))}</p>
                                                        </EbloomTooltip>
                                                    </MenuItem>
                                                    :
                                                    <MenuItem key={-1} value={0}
                                                              className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>

                                            }
                                            {
                                                playlists.map((item, index) => {
                                                    if(usedPlaylistActive.includes(item.id) && item.id !== autoPlannerData.id_playlist ){
                                                        return (
                                                            <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                                <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                                                    <p>{item.name}</p>
                                                                </EbloomTooltip>
                                                            </MenuItem>
                                                        )
                                                    }else if(item.id !== autoPlannerData.id_playlist && item.nbCB < week.reduce((previousValue, currentValue) => previousValue + autoplanner.frequency[currentValue], 0)){
                                                        return (
                                                            <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                                                <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledShort"))} placement={"left"}>
                                                                    <p>{item.name}</p>
                                                                </EbloomTooltip>
                                                            </MenuItem>
                                                        )
                                                    }
                                                    return (
                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </FullContrastBlueTextField>
                                    </Col>
                                    {
                                        (!isStarter && teams.length > 0 && !isFree) ?
                                            <Col className={"flex justify-center items-center "}>
                                                <FullContrastBlueTextField name={"teams"} onChange={handleChange}
                                                                           value={selectedTeams} size={"small"}
                                                                           select={true}
                                                                           label={_.upperFirst(t("admin.teams"))}
                                                                           SelectProps={{
                                                                               multiple: true,
                                                                               renderValue: (selected) => (
                                                                                   <div className={"truncate"}>
                                                                                       {
                                                                                           selected.length === 1 ?
                                                                                               selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                                                                   : teams.find(el => el.id === selected[0]).name
                                                                                               : selected.length + " " + _.lowerFirst(t("admin.teams"))

                                                                                       }
                                                                                   </div>
                                                                               )
                                                                           }}
                                                >
                                                    <MenuItem key={-1}
                                                              value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                                    <Divider key={-3}/>

                                                    {teams.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>
                                                            <div>{item.name}</div>
                                                        </MenuItem>

                                                    ))}
                                                </FullContrastBlueTextField>
                                                {
                                                    checkIfModified() &&
                                                    <div className={"absolute bottom-5 right-10 flex justify-center items-center gap-x-10"}>
                                                        <div onClick={() => handleCancelEdit()} className={"text-purple underline cursor-pointer"}>
                                                            <p>{_.upperFirst(t("core.cancel"))}</p>
                                                        </div>
                                                        <div className={""}>
                                                            <Button className={'ebloom-btn-purple'}
                                                                    onClick={() => handleOpenModalStartPlanner()}>{_.capitalize(t("core.save"))}</Button>
                                                        </div>
                                                    </div>

                                                }

                                            </Col> :
                                            checkIfModified() &&
                                            <div className={"absolute bottom-5 right-0"}>
                                                <div onClick={() => handleCancelEdit()} className={"text-purple underline cursor-pointer"}>
                                                    <p>{_.upperFirst(t("core.cancel"))}</p>
                                                </div>
                                                <div className={"absolute bottom-5 right-10"}>
                                                    <Button className={'ebloom-btn-purple'}
                                                            onClick={() => handleOpenModalStartPlanner()}>{_.capitalize(t("core.save"))}</Button>
                                                </div>
                                            </div>
                                    }
                                </Row>

                            </Card.Body>
                            :
                            <Card.Body className={"w-full pb-0  flex flex-col justify-between items-stretch"}>
                                <Row md={3} data-planCB={'changeFrequency'}>
                                    <Col md={6} className={"flex justify-start items-center"}>
                                        <span>{_.upperFirst(t("admin.frequency"))} :</span>
                                    </Col>
                                    <Col md={6} className={"text-center p-0"}>
                                        <span onClick={() => {handleOpenFrequency(autoplanner.id_frequency)}} className={"opacity-100 text-contrast-blue underline cursor-pointer"}>{autoPlannerData.frequency.title}</span>
                                    </Col>
                                </Row>
                                <Row md={3} data-planCB={'selectPlaylist'} >
                                    <Col md={6} className={"flex justify-start items-center"}>
                                        <span>{_.upperFirst(t("core.playlist.playlist"))} :</span>
                                    </Col>
                                    <Col md={6} className={"text-center p-0"}>
                                        <span>{autoPlannerData.playlist.name}</span>
                                    </Col>
                                </Row>
                                {
                                    (!isStarter && teams.length > 0) &&
                                    <Row md={3}>
                                        <Col md={6} className={"flex justify-start items-center"}>
                                            <span>{_.upperFirst(t("admin.autoPlanner.recipient"))} :</span>
                                        </Col>
                                        <Col md={6} className={"text-center p-0"}>
                                            {
                                                autoPlannerData.auto_planner_teams.length >= 0 && autoPlannerData.auto_planner_teams.length < 2 ?
                                                    <span
                                                        className={"text-contrast-blue font-semibold"}>{"#" + (autoPlannerData.auto_planner_teams.length > 0 ?autoPlannerData.auto_planner_teams[0].team.name : _.upperFirst(t('core.allBis')))}</span>
                                                    :
                                                    <EbloomTooltip text={autoPlannerData.auto_planner_teams.map(el => el.team.name).join('\n')}>
                                                <span
                                                    className={"text-contrast-blue font-semibold"}>{"#" + (autoPlannerData.auto_planner_teams.length + " " + _.lowerFirst(t("admin.teams")))}</span>
                                                    </EbloomTooltip>
                                            }
                                        </Col>
                                    </Row>
                                }
                                {
                                    !isSingle &&
                                    <Row className={"p-0 mt-5"}>
                                        <div className={" p-0 w-full flex justify-end items-center"}>
                                            {
                                                shouldShowDelete &&
                                                <EbloomTooltip placement={"top"} text={_.capitalize(t("core.delete"))}>
                                                    <IconButton className={"text-contrast-blue"} size="sm" onClick={() => setShowModalDeleteAutoPlanner(true)}><DeleteIcon/></IconButton>
                                                </EbloomTooltip>
                                            }
                                            <EbloomTooltip placement={"top"} text={_.upperFirst(t("core.edit"))}>
                                                <IconButton className={"text-contrast-blue"} onClick={toggleDrawer(true)}>
                                                    <EditIcon className={" text-contrast-blue size-20"}/>
                                                </IconButton>
                                            </EbloomTooltip>
                                        </div>
                                    </Row>
                                }
                            </Card.Body>
                    }
                </Card>
            </div>
            <SideForm
                toggleDrawer={toggleDrawer}
                sideFormOpen={sideFormOpen}
                buttonDisabled={selectedPlaylist === -1 || selectedFrequency === -1 ||selectedTeams.length === 0}
                showButtons={true}
                handleSave={() => handleOpenModalStartPlanner()}
                formTitle={"core.edit"}
            >
                <div className={" w-300  flex justify-center items-center flex-col gap-15"}>
                    <FullContrastPurpleTextField name={"id_frequency"} onChange={handleChange} value={selectedFrequency} fullWidth={true} size={"small"} select={true} label={_.upperFirst(t("admin.frequency"))}>
                        {
                            frequencies.map((item, index) => {
                                if(emptyFrequencies.includes(item.id)){
                                    return(
                                        <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                            <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.emptyFrequencyTitle"))} placement={"left"}>
                                                <p>{item.title}</p>
                                            </EbloomTooltip>
                                        </MenuItem>
                                    )

                                }
                                return <MenuItem key={index} value={item.id} disabled={(playlists.find(el => el.id === autoplanner.id_playlist) && autoplanner.id_playlist !== 0  ? playlists.find(el => el.id === autoplanner.id_playlist).nbCB < week.reduce((previousValue, currentValue) => previousValue + item[currentValue],0) : false)}>{item.title}</MenuItem>

                            })
                        }
                    </FullContrastPurpleTextField>

                    <FullContrastPurpleTextField onChange={handleChange} name={"id_playlist"}
                                                 value={selectedPlaylist} fullWidth={true} size={"small"}
                                                 select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                        <MenuItem key={-2} value={-1} disabled
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                        {
                            usedPlaylistActive.includes(0) && autoplanner.id_playlist !== 0 ?
                                <MenuItem key={-1} value={0} disabled={true} className={"pointer-events-auto"}>
                                    <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                        <p>{_.upperFirst(t("core.playlist.library"))}</p>
                                    </EbloomTooltip>
                                </MenuItem>
                                :
                                <MenuItem key={-1} value={0}
                                          className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>

                        }

                        {
                            playlists.map((item, index) => {
                                if(usedPlaylistActive.includes(item.id) && item.id !== autoPlannerData.id_playlist ){
                                    return (
                                        <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                            <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledActive"))} placement={"left"}>
                                                <p>{item.name}</p>
                                            </EbloomTooltip>
                                        </MenuItem>
                                    )
                                }else if(item.id !== autoPlannerData.id_playlist && item.nbCB < week.reduce((previousValue, currentValue) => previousValue + autoplanner.frequency[currentValue], 0)){
                                    return (
                                        <MenuItem key={index} value={item.id} disabled={true} className={"pointer-events-auto"}>
                                            <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.playlistDisabledShort"))} placement={"left"}>
                                                <p>{item.name}</p>
                                            </EbloomTooltip>
                                        </MenuItem>
                                    )
                                }
                                return (
                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                )
                            })
                        }
                    </FullContrastPurpleTextField>

                    {
                        (!isStarter && teams.length > 0) &&
                        <FormControl variant={"outlined"} fullWidth style={{
                            '& label.Mui-focused': {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: "white",
                                '&.Mui-focused fieldset': {
                                    borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                },
                                '&:hover fieldset': {
                                    borderColor:Colors.EBLOOM_CONTRAST_PURPLE,
                                },

                            },
                            '& .MuiFormHelperText-root':{
                                color:Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiFormLabel-root":{
                                color:Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiOutlinedInput-root fieldset":{
                                borderColor: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiInputBase-input":{
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiSelect-iconOutlined":{
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor:  Colors.EBLOOM_CONTRAST_PURPLE,
                            },
                        }}>
                            <InputLabel className={"text-contrast-purple bg-bg-white"} id={"3"}>{_.upperFirst(t("admin.teams"))}</InputLabel>
                            <Select
                                className={"text-contrast-purple border border-contrast-purple"}
                                size="small"
                                id={"3"}
                                multiple
                                name={"teams"}
                                value={selectedTeams}
                                onChange={(event) => handleChange(event)}
                                renderValue={(selected) => (
                                    <div className={""}>
                                        {
                                            selected.length === 1 ?
                                                selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                    : teams.find(el => el.id === selected[0]).name
                                                : selected.length +" "+ _.lowerFirst(t("admin.teams"))

                                        }
                                    </div>
                                )}
                            >
                                <MenuItem key={-1} value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                <Divider key={-3}/>

                                {teams.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        <div>{item.name}</div>
                                    </MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    }
                </div>
            </SideForm>
            <Modal show={showModalStartPlanner} onHide={handleCloseModal} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlannerTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlannerHelper"))}</p>
                    {
                        isFree &&
                        <div className={"text-line-break"}>
                            <p>{_.upperFirst(t("free.autoPlannerText"))}</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={handleCloseModal} disabled={loadingPlannerNow || loadingPlannerNext}>{_.capitalize(t("core.cancel"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={() => startAutoPlanner("now")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNow ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={() => startAutoPlanner("nextWeek")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNext ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalFrequencyEmpty} onHide={() => setModalFrequencyEmpty(false)} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.upperFirst(t("admin.autoPlanner.emptyFrequencyTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlanner.emptyFrequency"))}</p>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={() => setModalFrequencyEmpty(false)} >{_.capitalize(t("core.close"))}</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal showButtons={false} size={"lg"} show={showFrequency} handleClose={handleCloseFrequency} title={_.upperFirst(t("admin.frequency")+ (!!frequency ? " : "+ frequency.title : ""))}>
                {
                    frequency &&
                    <div>
                        <Row className={"p-0 mb-15"}>
                            <Col md={2}>
                                <span>{_.upperFirst(t("admin.frequencies.reccurency"))} :</span>
                            </Col>
                            <Col md={4} className={"text-start"}>
                                <span className={"text-contrast-blue font-semibold"}>
                                    {recurrencies.filter(el => el.value === frequency.recurrency).length > 0 ? _.upperFirst(t(recurrencies.filter(el => el.value === frequency.recurrency)[0].name)) : "Undefined"}
                                </span>
                            </Col>
                        </Row>
                        <Row md={5} style={{margin: "auto", marginBottom: 20}}>
                            {
                                week.map((day, index) => (
                                    <Col style={{padding: 2}} key={index}>
                                        <Card style={{minWidth: 120, borderColor: Colors.EBLOOM_CONTRAST_PURPLE}}>
                                            <Card.Header style={{textAlign: "center", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, color:"white"}} className={"ebloom-text ebloom-large-text"}>{_.capitalize(t("core.day." + day))}</Card.Header>
                                            <Card.Body>
                                                <ListGroup variant={"flush"}>
                                                    {
                                                        Array(frequency[day]).fill("Click&Bloom").map((value, index) => (
                                                            <ListGroup.Item key={index} className={"flex justify-center items-center"} style={{paddingLeft:0, paddingRight:0}}>
                                                                <div style={{textAlign:"center"}}>
                                                                    <span className={"text-center"} style={{float:"left"}}>{value}</span>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>

                }
                {
                    frequencies &&
                    <Row>
                        <div className={"w-full flex justify-center items-center"}>
                            <span className={"text-center text-contrast-blue underline cursor-pointer"} onClick={() => {navigate("/admin/frequency/"+autoplanner.id_frequency)}}>
                                {_.upperFirst(t("admin.autoPlanner.modifyFrequency"))}
                            </span>
                        </div>
                    </Row>
                }
            </ConfirmModal>
            <Modal show={openStartModal} onHide={handleCloseModalStart} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlannerTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlannerHelper"))}</p>
                    {
                        isFree &&
                        <div className={"text-line-break"}>
                            <p>{_.upperFirst(t("free.autoPlannerText"))}</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={handleCloseModalStart} disabled={loadingPlannerNow || loadingPlannerNext}>{_.capitalize(t("core.cancel"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={() => startAutoPlanner("now")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNow ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={() => startAutoPlanner("next")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNext ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openModifiedActivePlanner}  size={"lg"}  style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.upperFirst(t("admin.autoPlanner.modificationTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    {
                        autoplanner.active ?
                            <div>
                                <p>
                                    {_.upperFirst(t("admin.autoPlanner.enableInactiveAutoPlannerSubTextRestart"))}
                                </p>

                            </div>
                            :
                            <div>
                                <p>
                                    {_.upperFirst(t("admin.autoPlanner.enableInactiveAutoPlanner"))}
                                </p>
                                <p>
                                    {_.upperFirst(t("admin.autoPlanner.enableInactiveAutoPlannerSubTextStart"))}
                                </p>
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer className={"flex justify-end  flex-row"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                    {
                        autoplanner.active ?
                            <div className={"flex justify-end  flex-row gap-x-10"}>
                                <Button variant={"secondary"} className={"ebloom-normal-text"} disabled={loadingPlannerNow || loadingPlannerNext} onClick={() => handleModalModifiedPlanner("cancel",autoplanner.active)} >{_.capitalize(t("core.cancel"))}</Button>
                                <Button className={"ebloom-btn-purple ebloom-normal-text"} disabled={loadingPlannerNow || loadingPlannerNext} onClick={() =>handleModalModifiedPlanner("now",autoplanner.active)} >{loadingPlannerNow ? <LoadingView size={16}/> : _.upperFirst(t("core.confirm"))}</Button>
                            </div>
                            :
                            <div className={"flex justify-end  flex-row gap-x-10"}>
                                <Button variant={"secondary"} className={"ebloom-normal-text"} disabled={loadingPlannerNow || loadingPlannerNext} onClick={() => handleModalModifiedPlanner("cancel",autoplanner.active)} >{_.capitalize(t("admin.autoPlanner.notNow"))}</Button>
                                <Button className={"ebloom-btn-purple ebloom-normal-text"} disabled={loadingPlannerNow || loadingPlannerNext} onClick={() =>handleModalModifiedPlanner("now",autoplanner.active)} >{loadingPlannerNow ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                                <Button className={"ebloom-btn-purple ebloom-normal-text"} disabled={loadingPlannerNow || loadingPlannerNext} onClick={() =>handleModalModifiedPlanner("next",autoplanner.active)} >{loadingPlannerNext ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                            </div>
                    }
                </Modal.Footer>
            </Modal>
            <ConfirmModal handleClose={()=> setAutoPlannerAccessDenied(false)} showButtons={false} show={autoPlannerAccessDenied} title={_.upperFirst(t("core.forbiddenActivation"))}>
                        <div>
                            {_.upperFirst(t("admin.autoPlanner.limitReached"))}

                        </div>
            </ConfirmModal>
            <ConfirmModal buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))} handleChange={() => handleChangeActive(confirmDeleteQuestions)} title={_.capitalize(t("admin.autoPlanner.desactivateAutoPlanner"))}  handleClose={() => setShowModalDeleteQuestions(false)} show={showModalDeleteQuestions}>
                <div>
                    <p>{_.upperFirst(t("admin.autoPlanner.disableAutoPlanner"))}</p>
                    <br/>
                    {
                        lastDateCB &&
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={confirmDeleteQuestions}
                                        onChange={handleCheckBoxDelete}
                                        className={"checkbox-purple"}
                                        name="deleteQuestions"
                                    />
                                }
                                label={<div>
                                    <p>{_.upperFirst(t("admin.autoPlanner.deleteQuestions",{date : moment(lastDateCB).format("DD/MM/YYYY")}))}</p>
                                </div>}
                            />
                        </div>
                    }
                </div>
            </ConfirmModal>
            <Modal show={showModalUsedPlaylist} onHide={() => setShowModalUsedPlaylist(false)} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlanner.youCantActivateTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlanner.playlistIsUsed"))}</p>
                    <p>{_.upperFirst(t("admin.autoPlanner.playlistIsUsedSub"))}</p>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={() => setShowModalUsedPlaylist(false)} >{_.capitalize(t("core.close"))}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalDeleteAutoPlanner} onHide={() => setShowModalDeleteAutoPlanner(false)} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_CONTRAST_BLUE, color:"white"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("core.notification.warning")) + " - " + _.capitalize(t("core.confirmDelete"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"ebloom-normal-text"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                    <div>
                        <p>{_.capitalize(t("core.questionDelete"))}</p>

                        {
                            lastDateCB &&
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={confirmDeleteQuestions}
                                            onChange={handleCheckBoxDelete}
                                            className={"checkbox-purple"}
                                            name="deleteQuestions"
                                        />
                                    }
                                    label={<div>
                                        <p>{_.upperFirst(t("admin.autoPlanner.deleteQuestions",{date : moment(lastDateCB).format("DD/MM/YYYY")}))}</p>
                                    </div>}
                                />
                            </div>
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer className={"ebloom-normal-text"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant="secondary"
                            onClick={() => setShowModalDeleteAutoPlanner(false)}>{_.capitalize(t("core.cancel"))}</Button>
                    <Button className={"ebloom-btn-purple"}
                            onClick={() => handleDeleteAutoPlanner(autoplanner.id)}>{_.capitalize(t("core.confirm"))}</Button>
                </Modal.Footer>
            </Modal>
        </div>
        /* OLD VERSION
        <Card data-planCB={'autoPlanner'} className={"bg-white-blue border border-contrast-blue w-1/2 m-auto mb-20"} onClick={isFree ? handleOpenModalFreeTrial : () => {}}>
                            <Card.Header className={"text-center bg-contrast-blue text-white ebloom-very-large-text ebloom-text py-0"}><span>Auto-Planner</span>{(isFree) && <LockOutlinedIcon className={"ml-5"}/>}</Card.Header>
                            <Card.Body>
                                <div className={"flex justify-around"}>
                                    <FullContrastBlueSwitch data-planCB={'switchAutoPlanner'} disabled={selectedPlaylist === -1 || isFree} checked={activeAuto} name={"automActive"} onChange={handleChangeActive} />
                                    <FullContrastBlueTextField data-planCB={'selectPlaylist'} disabled={isFree} className={"ebloom-normal-text"} size={"small"} variant={"outlined"} name={"select-playlist"} defaultValue={-1} select value={selectedPlaylist} onChange={handleChangeAutoPlaylist}>
                                        <MenuItem key={-2} value={-1} disabled className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                                        <MenuItem key={-1} value={0} className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>
                                        {
                                            playlists.map((playlist, index) => (
                                                <MenuItem disabled={playlist.nbCB < totalFrequency} key={index} value={playlist.id} className={"ebloom-normal-text"}>{playlist.name}</MenuItem>
                                            ))
                                        }
                                    </FullContrastBlueTextField>
                                    <EbloomInfoTooltip text={_.upperFirst(t("admin.plannerHelper3"))}/>
                                </div>
                                <div data-planCB={'changeFrequency'} className={"text-center mt-10"}>
                                    <span className={"ebloom-link-blue"} onClick={isFree ? handleOpenModalFreeTrial : companyToEdit ? () => navigate("/backoffice/frequency") : () => navigate("/admin/frequency")}>{_.upperFirst(t("admin.plannerFrequencyHelper"))}</span>
                                    {
                                        isFree &&
                                        <LockOutlinedIcon fontSize={"small"} className={"text-contrast-blue"}/>
                                    }
                                </div>

                            </Card.Body>
                        </Card>
         */
    );
};

AutoPlanner.propTypes = {
    autoPlannerData: PropTypes.object.isRequired,
    companyToEdit: PropTypes.object,
    playlists: PropTypes.array.isRequired,
    frequencies: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
    selectedEntity: PropTypes.number,
    fetchAutoPlanners: PropTypes.func.isRequired,
    shouldShowDelete: PropTypes.bool,
    isStarter: PropTypes.bool.isRequired,
    isSingle: PropTypes.bool,
    handleToday: PropTypes.func.isRequired,
    fetchNumberFutureQuestions: PropTypes.func.isRequired,
    usedPlaylists: PropTypes.array.isRequired,
    otherAutoPlanners: PropTypes.array.isRequired,
    handleOpenModalFree: PropTypes.func
};

export default AutoPlanner;
