import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {ReactComponent as PersonIcon} from '../../../../../../Core/symbols/user-solo-white.svg';
import {useUserData} from "../../../../../../../hooks/userHook";
import moment from "moment";
import _ from "lodash";

const Identification = (props) => {

    //state

    //props
    const {question, handleChange} = props;
    //hooks
    const user = useUserData();

    //getters

    //posters

    //handlers
    const handle = () => {
        let q = _.cloneDeep(question);
        q.answer = user.name ? user.name + " (" + user.email + ")" : user.email;
        if(handleChange){
            handleChange(q);
        }
    }

    //useEffect

    useEffect(() => {
        handle()
    }, [])

    return (
        <>
            <div className={"my-10"}>
                <div className={"text-contrast-purple ebloom-text ebloom-very-small-text text-right"}>{moment().format("DD/MM/YYYY HH:mm")}</div>
                <div className={"flex w-full p-10 text-contrast-purple rounded-lg bg-contrast-purple/[0.1]"}>
                    <PersonIcon className={"h-25 w-25 contrast-purple-icon mr-10"}/>
                    <p className={"text-line-break"}>{user.name ? user.name + " (" + user.email + ")" : user.email}</p>
                </div>
            </div>
        </>
    )
}

Identification.propTypes = {
    answer:PropTypes.object
}

Identification.defaultProps = {
    answer:null,
}
export default Identification