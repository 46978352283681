import React, {useState, useEffect} from "react";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {useUserData} from "../../../hooks/userHook";
import HttpApi from "../../../httpApi";
import fileSaver from "file-saver/dist/FileSaver";
import {createErrorNotification, notifyError, notifySuccess} from "../../../Utils/Notification";
import readXlsxFile from "read-excel-file";
import CardDefault from "../../Core/Modules/Cards/CardDefault";
import InviteEditor from "../../Admin/Pages/Employee/InviteEditor";
import SideForm from "../../Core/Modules/Views-Tables-Lists/SideForm";
import {Col, Form, Row} from "react-bootstrap";

const BackofficeInviteUsers = (props) => {

    //state
    const [excelData, setExcelData] = useState([]);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [users, setUsers] = useState([]);
    const [sideFormOpen, setSideFormOpen] = useState(false);

    //props
    const {createdEmailsAndPhones, handleBack, handleSeePreviewAndReload} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData();

    //getters
    const downloadTemplate = () => {
        const url ="/companies/template/1";
        HttpApi.getWithResponseTypeV2(url).then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'ebloom-users-template.xlsx');
            notifySuccess("Template downloaded");
        })
    }

    //posters
    const handleSaveExcel = () => {
        if(!loadingExcel && userData.module !== "happy"){
            if(excelData && excelData.length > 1){
                setLoadingExcel(true);
                HttpApi.post("/user/checkMatchingBackoffice", {users:excelData}).then(response => {
                    if(response && response.data){
                        setExcelData([]);
                        setSideFormOpen(false);
                        setUsers(response.data.tableToInvite);
                    }
                }).catch(error => {
                    notifyError(error);
                }).finally(() => setLoadingExcel(false));
            }else{
                createErrorNotification("Error!", "Please provide a filled excel file.");
            }
        }
    }

    //handlers
    const toggleDrawer = (open, toEdit=null) => event =>{
        event.preventDefault();
        setSideFormOpen(open);
    }

    const handleExcel = (event) => {
        readXlsxFile(event.target.files[0]).then((rows) => {
            if(rows && rows.length > 1){
                setExcelData(rows);
            }else if(!rows || rows.length <= 1) {
                createErrorNotification("Error!", "Empty file, please provide a filled excel file");
                document.getElementById("input-invite-users-backoffice").value = null;
                setExcelData([]);
            }else{
                createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
                document.getElementById("input-invite-users-backoffice").value = null;
                setExcelData([]);
            }
        }).catch(() => {
            createErrorNotification("Error!", "Invalid file format. Please use the Excel template provided");
            document.getElementById("input-invite-users-backoffice").value = null;
            setExcelData([]);
        })
    }

    const handleBackToUsers = () => {
        handleBack();
    }

    const handleChangeUsers = (newUsers) => {
        setUsers(newUsers);
    }

    const handleButton = () => {
        const url = "/users/saveUsersBackoffice";
        HttpApi.postV2(url, {users:users}).then(() => {
            notifySuccess(_.upperFirst(t("admin.invite.success.userSaved")));
        }).catch(error => {
            notifyError(error);
        })
    }

    //useEffect

    useEffect(() => {
        return () => {
            setSideFormOpen(false)
        }
    }, [])

    return (
        <div>
            <CardDefault title={_.upperFirst(t("core.inviteUsers"))} showButton={users && users.length >0 && userData.module !== "happy"} rightButtonVariant={"link"} handleRightButton={toggleDrawer(true)} buttonText={_.upperFirst(t("admin.invite.inviteViaExcel"))} showLeftButton leftButtonVariant={"secondary"} leftButtonText={_.upperFirst(t("core.back"))} handleLeftButton={handleBackToUsers}>
                <InviteEditor createdEmailsAndPhones={createdEmailsAndPhones} usersTable={users} handleChangeUsers={handleChangeUsers} buttonText={_.upperFirst(t("core.saveAndInviteUsers")) + " (" + _.upperFirst(t("core.seePreview")) + ")"} handleButton={handleButton} handleSeePreviewAndReload={handleSeePreviewAndReload} isInvite isBackoffice/>
                {
                    (!users || users.length === 0) &&
                    <div className={"flex justify-end items-center mt-30"}>
                        <span data-tour={"excelBtn"} className={"ebloom-link-purple"} onClick={toggleDrawer(true)}>{_.upperFirst(t("admin.invite.inviteManyExcel"))}</span>
                    </div>
                }
            </CardDefault>
            <SideForm sideFormOpen={sideFormOpen} formTitle={_.capitalize(t("core.inviteUsers"))} toggleDrawer={toggleDrawer} handleSave={handleSaveExcel} loading={loadingExcel} buttonDisabled={excelData.length === 0}>
                <p className={"w-350 text-justify"}>{ _.upperFirst(t("admin.uploadInviteHelper"))}</p>
                <p className={"text-strong-red text-center"}>{_.upperFirst(t("admin.uploadHelper2"))}</p>
                <Row>
                    <span data-invite={'template'} className={"ebloom-link-purple ebloom-text ebloom-very-large-text text-center"} onClick={downloadTemplate}>{_.upperFirst(t("core.downloadParam", {param:"template"}))}</span>
                </Row>
                <Row className={"mt20 mb20"}>
                    <Col>
                        <Form.Control data-invite={'chooseFile'} type="file" id="input-invite-users-backoffice" accept=".xlsx, .xlsm, .xls" onChange={handleExcel} />
                    </Col>
                </Row>
            </SideForm>
        </div>
    )
}

BackofficeInviteUsers.propTypes = {}

BackofficeInviteUsers.defaultProps = {}
export default BackofficeInviteUsers