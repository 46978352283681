import React from 'react';
import DisplayManagerView from '../Layouts/DisplayManagerView'
import ManagerDashboard from "./ManagerDashboard";
import {Container} from "react-bootstrap";

const ManagerDashboardPage = (props) => {
    return (
      <DisplayManagerView {...props} showTeamSwitcher={true} showDatePicker={true} showFilterCommonSurveys={true}>
          {
              (teamToDisplay, startDate, endDate, timeFilterVersion, shouldDownloadReport, handleReportState, showModalReport, handleModalReport, handleReportDisabled ,commonSurveyActivated,validTeams,dashboardAccess) => dashboardAccess &&(
                  <Container fluid>
                      <ManagerDashboard {...props} teamToDisplay={teamToDisplay} startDate={startDate} endDate={endDate} timeFilterVersion={timeFilterVersion} shouldDownloadReport={shouldDownloadReport} handleReportState={handleReportState} showModalReport={showModalReport} handleModalReport={handleModalReport} handleReportDisabled={handleReportDisabled} commonSurveyActivated={commonSurveyActivated} validTeams={validTeams}/>
                  </Container>
              )
          }
      </DisplayManagerView>
    )

}

export default ManagerDashboardPage;
