import React from 'react';
import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {Colors} from "../../../../Utils/Colors";
import {getColorFromPercentage} from "../../../../Utils/Global";
import {useTranslation} from "react-multi-lang";
import _ from "lodash";
import PropTypes from "prop-types";

const EbloomLineChart = (props) => {
    const {data,color,dataKeyX,dataKeyY, disabled} = props
    const t = useTranslation()
    const renderTick = (props) => {
        const {x, y, payload} = props;
        const icon = payload.value > 80 ? "smiley_happy" : payload.value > 60 ? "smiley_glad" : payload.value > 40 ? "smiley_poker-face" : payload.value > 20 ? "smiley_sad" : "smiley_unhappy";
        return (
            <image x={x - 30} y={y - 10} href={"/img/" + icon + ".png"} width={25} height={25}/>
        )
    }

    const renderTickRight = (score) => {
        if (score === 0 || score === 50 || score === 100) {
            return score + "%";
        }
        return "";
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="ebloom-normal-text bg-white border-1 border-grey/70 p-10 ">
                    <p>{_.upperFirst(t("core.date"))}: {label}</p>
                    <p>{_.upperFirst(t("core.score"))} : <span className={"font-semibold"} style={{color: getColorFromPercentage(payload[0].value)}}>
                             {payload[0].value}
                        </span>
                        <span className={"text-xs font-semibold"}>
                            %
                        </span>
                    </p>
                </div>
            );
        }

        return null;
    };

    const listTicks = (data) => {
        if(data && data.length >= 2){
            if(data[0].date && data[Math.round((data.length-1)/2)].date && data[data.length-1].date){
                return [data[0].date, data[Math.round((data.length-1)/2)].date,data[data.length-1].date]
            }
        }
        return []
    }

    return disabled ?
        <ResponsiveContainer width="100%" height="100%" >
            <LineChart data={data} margin={{right: 20, bottom: 0, left: 20, top: 20}}>
                <ReferenceLine y={20}/>
                <ReferenceLine y={40}/>
                <ReferenceLine y={60}/>
                <ReferenceLine y={80}/>
                <ReferenceLine y={100}/>

                <XAxis padding={{left: 10,right:10}} angle={0} tickMargin={20} axisLine={{stroke: Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}} tick={false}/>
                <YAxis tickLine={false} tickCount={3} tick={renderTick} axisLine={{stroke: Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}}  type="number" dataKey={dataKeyY}  domain={[0, 100]} unit={"%"} />
                <YAxis yAxisId="right" type="number" dataKey="score" orientation="right" tickCount={3}
                       domain={[0, 100]} tick={{fill: Colors.EBLOOM_CONTRAST_PURPLE}}
                       tickFormatter={renderTickRight} axisLine={false} tickLine={false}/>
            </LineChart>
        </ResponsiveContainer>
        :
        <ResponsiveContainer width="100%" height="100%" >
            <LineChart data={data} margin={{right: 20, bottom: 20, left: 20, top: 20}}>
                <ReferenceLine y={20}/>
                <ReferenceLine y={40}/>
                <ReferenceLine y={60}/>
                <ReferenceLine y={80}/>
                <ReferenceLine y={100}/>

                <XAxis tickLine={{stroke: Colors.EBLOOM_CONTRAST_PURPLE}} tickCount={4} ticks={listTicks(data)}  tick={{fill:Colors.EBLOOM_CONTRAST_PURPLE}} padding={{left: 10,right:10}} angle={0} tickMargin={20} axisLine={{stroke: Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}} dataKey={dataKeyX} />
                <YAxis tickLine={false} tickCount={3} tick={renderTick} axisLine={{stroke: Colors.EBLOOM_CONTRAST_PURPLE, strokeWidth: 2}}  type="number" dataKey={dataKeyY}  domain={[0, 100]} unit={"%"} />
                <YAxis yAxisId="right" type="number" dataKey="score" orientation="right" tickCount={3}
                       domain={[0, 100]} tick={{fill: Colors.EBLOOM_CONTRAST_PURPLE}}
                       tickFormatter={renderTickRight} axisLine={false} tickLine={false}/>

                <Tooltip content={CustomTooltip}/>

                <Line type="monotone" dataKey={dataKeyY}  stroke={color}  />
            </LineChart>
        </ResponsiveContainer>
};

EbloomLineChart.propTypes = {
    disabled : PropTypes.bool,
    color : PropTypes.string,
    dataKeyX : PropTypes.string,
    dataKeyY : PropTypes.string,
    data : PropTypes.array.isRequired
}

EbloomLineChart.defaultProps = {
    disabled : false,
    color : Colors.EBLOOM_PURPLE,
    dataKeyX : "date",
    dataKeyY : "score"
}

export default EbloomLineChart;
