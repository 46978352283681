import React, {useState, useEffect} from "react";
import _ from "lodash";
import HttpApi from "../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {FullContrastPurpleTextField} from "../../../../../Utils/Global";
import {useTranslation} from "react-multi-lang/lib";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import NotificationToken from "../../../models/NotificationToken";

const MobileEditor = (props) => {

    //state
    const [mobileVersion, setMobileVersion] = useState(0);
    const [mobileTokens, setMobileTokens] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    //props

    //hooks
    const t = useTranslation();
    //getters

    //posters
    const update = () => {
        HttpApi.put("/settings/updateMobileVersion", {value:mobileVersion}).then(() => {
            notifySuccess("Mobile version successfully updated");
        })
    }

    const verifyToken = (notification_token) => event => {
        event.preventDefault();
        HttpApi.getV2("/integrations/notification/token/verify/" + notification_token.token).then((response) => {
           if(response && response.data){
               notifySuccess("Token valid");
           }else{
               createErrorNotification("Invalid token", "Token is not valid and should be deleted/replaced");
           }
        }).catch(error => {
            notifyError(error);
        })
    }

    //handlers
    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    //useEffect

    useEffect(() => {
        HttpApi.getV2("/settings/mobile/getVersion").then(response => {
            if(response && response.data){
                setMobileVersion(response.data.value);
            }
        })
        HttpApi.getV2("/integrations/notification/token/getAll").then(response => {
            if(response && response.data){
                console.log(response.data);
                setMobileTokens(response.data);
            }
        })
    }, [])

    return (
        <div className={"m-auto w-full"}>
            <div className={"w-1/2 m-auto"}>
                <CardDefault title={"Mobile version"} handleRightButton={update} buttonText={_.upperFirst(t("core.update"))}>
                    <FullContrastPurpleTextField fullWidth variant={"outlined"} value={mobileVersion} placeholder={"Mobile version"} onChange={(event) => {setMobileVersion(event.target.value)}}/>
                </CardDefault>
            </div>
            <CardDefault title={"Mobile tokens"} showButton={false}>
                <TableViewV2 model={NotificationToken} items={mobileTokens} tablePage={tablePage} handleTablePage={handleTablePage} showDetail handleView={verifyToken} handleObject showDelete={false} showEdit={false}/>
            </CardDefault>
        </div>
    )
}

MobileEditor.propTypes = {}

MobileEditor.defaultProps = {}
export default MobileEditor