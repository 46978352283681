import ScoreType from "./ScoreType";
import {getColorFromPercentage} from "../../../Utils/Global";
import PropTypes from "prop-types";
import _ from "lodash";
import React, { useRef} from "react";
import {useTranslation} from "react-multi-lang/lib";
import {useOutsideAlerter} from "../../../hooks/outsideAlerterHook";
import {Colors} from "../../../Utils/Colors";
import CloseIcon from "../../Core/Modules/Icons/CloseIcon";

const TagDetail = (props) => {
    const {details, setShowDetails,showDifference, difference,isModelCustomEnable,isEbloomModel,colors} = props
    const t = useTranslation()
    const wrapper = useRef();

    useOutsideAlerter(wrapper, () => {setShowDetails(null)})
    return(
    <div>
        {
            details &&
            <div className={`fixed inset-0 flex items-center justify-center bg-grey/60 backdrop-blur-[1px] z-50 `}>
                <div ref={wrapper}>
                    <div
                        style={{borderColor : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                        className=" w-full md:w-[600px] rounded-lg  border-2  bg-white z-70 flex flex-col justify-start items-center relative p-30 divide-purple divide-y">
                        <div className={"w-full flex justify-between flex-row items-center pb-10"}>
                            <div className={"w-full"}>
                                <ScoreType isModelCustomEnable={isModelCustomEnable} category={details.category} showDifference={showDifference} difference={difference} colors={colors} />
                                <div className={"w-full mt-10"}>{}</div>

                            </div>
                            <button className={" absolute w-30 h-30 right-10 top-10 bg-white rounded-full"}
                                    onClick={() => setShowDetails(null)}>
                                <CloseIcon colors={colors} width={30} height={30}/>
                            </button>
                        </div>

                        <div className={"w-full flex flex-col justify-center items-center pt-10"}>
                            {details.tags.sort((a,b) =>{
                                if (_.lowerCase(a.name).includes("general")) {
                                    return 1;
                                } else if (_.lowerCase(b.name).includes("general")) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }).map((tag,index) =>
                                <div key={index} className={"w-full grid grid-cols-12 mt-10"}>
                                    <div className={"col-start-1 col-end-6"}>
                                        {
                                            _.lowerCase(tag.name).includes("general") && isEbloomModel
                                                ?<h1><span className={"font-bold mr-10"}>#</span>{_.upperFirst(t("core.general"))}</h1>

                                                :<h1><span className={"font-bold mr-10"}>#</span>{t("core.tag."+tag.name)}</h1>

                                        }

                                    </div>
                                    <div className={"col-start-8 col-end-11 flex justify-center items-center"}>
                                        <div className={"w-full bg-[#D9D9D9] h-10 rounded-full relative"}>
                                            <div className={" h-full rounded-full float-left "}
                                                 style={{
                                                     width: tag.score && tag.score > 0 ? (tag.score + "%") : 0,
                                                     backgroundColor: getColorFromPercentage(tag.score)
                                                 }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-start-11 col-end-13 flex justify-center items-center"}>
                                        <h1 style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={"font-semibold text-center text-xl "}>{tag.total ? tag.score !== null && tag.score >= 0 ? tag.score + "%" : _.toUpper(t("manager.dashboard.anonymityRestrictionSmall")) : _.toUpper(t("webloom.NA"))}</h1>
                                    </div>

                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        }

    </div>
    )
}

TagDetail.propTypes = {
    details : PropTypes.object.isRequired,
    setShowDetails : PropTypes.func.isRequired,
    showDifference : PropTypes.bool,
    isModelCustomEnable : PropTypes.bool,
    isEbloomModel : PropTypes.bool,
    difference : PropTypes.object,
    colors : PropTypes.object
}

TagDetail.defaultProps = {
    showDifference : false,
    isModelCustomEnable : false,
    isEbloomModel : false,
    difference : null,
    colors : null
}

export default TagDetail