export const manageUserPackageStarterSteps = [
    {
        title: "admin.productTour.manageUsers.step1.title",
        content: "admin.productTour.manageUsers.step1.content",
        target: '[data-users="dots"]',
        placement: 'left',
        disableBeacon : true,
        disableNext : true,
        nextText : "admin.productTour.manageUsers.step1.nextText",
    },
    {
        title: "admin.productTour.manageUsers.step2.title",
        content: "admin.productTour.manageUsers.step2.content",
        target: '[data-users="roles"]',
        placement: 'left',
        disableBeacon : true,
        spotlightClicks : false,
        spotlightPadding : 7 ,
    },
    {
        title: "admin.productTour.manageUsers.step3.title",
        content: "admin.productTour.manageUsers.step3.content",
        target: '[data-users="reinviteUsers"]',
        placement: 'left',
        disableBeacon : true

    },
    {
        title: "admin.productTour.manageUsers.step4.title",
        content: "admin.productTour.manageUsers.step4.content",
        target: '[data-tour="buttonUsersView-0"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 1,
        disableScrolling : true
    },
    {
        title: "admin.productTour.manageUsers.step5.title",
        content: "admin.productTour.manageUsers.step5.content",
        target: '[data-tour="buttonUsersEdit-0"]',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 1,
        disableScrolling : true,
        styles: {
            options : {
                zIndex : 1500
            }
        },
        disableNext : true,
        nextText : "admin.productTour.manageUsers.step5.nextText",

    },
    {
        title: "admin.productTour.manageUsers.step6.title",
        content: "admin.productTour.manageUsers.step6.content",
        target: '#roles-user',
        placement: 'left',
        disableBeacon : true,
        spotlightPadding: 2,
        spotlightClicks: false,
        styles: {
            options : {
                zIndex: 1500
            },
        }
    },
]