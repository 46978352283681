import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {Colors} from "../../../../Utils/Colors";
import {useTranslation, getLanguage} from "react-multi-lang";
import _ from "lodash";
import {getTranslations} from "../../../../lang/translations";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {Checkbox} from "@mui/material";
import Arrow from "@mui/icons-material/ArrowForwardIos";
import {notifySuccess} from "../../../../Utils/Notification";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {useUserData} from "../../../../hooks/userHook";
import EbloomSelectLang from "../../../Core/Modules/Input/EbloomSelectLang";
import CommunicationTour from "../../../../Utils/ProductTour/Tours/Communication/CommunicationTour";
import {TourType} from "../../../../Utils/Global";

const CommunicationV2 = (props) => {

    const [inviteLang, setInviteLang] = useState(getLanguage());
    const [show, setShow] = useState(false);
    const [downloadQrCode, setDownloadQrCode] = useState(false);
    const [downloadPoster, setDownloadPoster] = useState(false);
    const [downloadPosterFr, setDownloadPosterFr] = useState(false);
    const [downloadPosterEn, setDownloadPosterEn] = useState(false);
    const [downloadPosterNl, setDownloadPosterNl] = useState(false);
    const [downloadSlide, setDownloadSlide] = useState(false);
    const [downloadSlideFr, setDownloadSlideFr] = useState(false);
    const [downloadSlideEn, setDownloadSlideEn] = useState(false);
    const [downloadSlideNl, setDownloadSlideNl] = useState(false);

    const t = useTranslation();

    const user = useUserData();

    const companyState = user.company;

    const handleChangeInviteLang = (event) => {
        setInviteLang(event.target.value);
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleChangeCheckbox = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        switch (name) {
            case "all":
                setDownloadPoster(checked);
                setDownloadPosterEn(checked);
                setDownloadPosterFr(checked);
                //setDownloadPosterNl(checked);
                setDownloadSlide(checked);
                setDownloadSlideEn(checked);
                setDownloadSlideFr(checked);
                //setDownloadSlideNl(checked);
                setDownloadQrCode(checked);
                break;
            case "qr_code":
                setDownloadQrCode(checked);
                break;
            case "poster":
                setDownloadPoster(checked);
                setDownloadPosterEn(checked);
                setDownloadPosterFr(checked);
                //setDownloadPosterNl(checked);
                break;
            case 'poster_en':
                setDownloadPosterEn(checked);
                break;
            case "poster_fr":
                setDownloadPosterFr(checked);
                break;
            case "poster_nl":
                setDownloadPosterNl(checked);
                break;
            case "slide":
                setDownloadSlide(checked);
                setDownloadSlideEn(checked);
                setDownloadSlideFr(checked);
                //setDownloadSlideNl(checked);
                break;
            case "slide_en":
                setDownloadSlideEn(checked);
                break;
            case "slide_fr":
                setDownloadSlideFr(checked);
                break;
            case "slide_nl":
                setDownloadSlideNl(checked);
                break;
            default:
                break;
        }
    }

    const company = props.companyToEdit ? props.companyToEdit.name : companyState;

    const handleDownloadSupport = () => {
        if(downloadQrCode && !downloadPoster && !downloadSlide){
            //QR code
            window.open("https://drive.google.com/u/1/uc?id=1H8SOfleKrcvWypYhNU7dlnqyvfGDfHpf&export=download", "_blank"); //qr_code
        }else if(downloadQrCode && downloadPoster && !downloadSlide){
            //QR code + poster
            window.open("https://drive.google.com/u/1/uc?id=18x20_dK4ISQbT5WIsuhlMiPF7rKh2EzC&export=download", "_blank");
        }else if(downloadQrCode && !downloadPoster && downloadSlide){
            //QR code + slide
            window.open("https://drive.google.com/u/1/uc?id=1Q-9uYhMjaB41VU0t3hlBgUbH8D3H_crV&export=download", "_blank");
        }else if(downloadQrCode && downloadPoster && downloadSlide){
            //QR code + poster + slide
            window.open("https://drive.google.com/u/1/uc?id=1QRrCz9ckpIm9Yg8iNt_JikOq6tVCTdll&export=download", "_blank");
        }else if(!downloadQrCode && downloadPoster && downloadSlide){
            //Poster + slide
            window.open("https://drive.google.com/u/1/uc?id=15qA_TS6HAPhHu0IfhzmXNqV6ZqVBGEHJ&export=download", "_blank");
        }else if(!downloadQrCode && !downloadPoster && downloadSlide){
            //Slide
            window.open("https://drive.google.com/u/1/uc?id=1NWNrAcFXt3UszUmbEalDGBS4czPGXGwi&export=download", "_blank");
        }else if(!downloadQrCode && downloadPoster && !downloadSlide){
            //Poster
            window.open("https://drive.google.com/u/1/uc?id=1CwC6ul_IQsyuZINT6hH7bXHaUOuEAXa3&export=download", "_blank");
        }
        setShow(false);

    }

    return(
        <>
            <CommunicationTour/>
            <CardDefault title={t("admin.introductionTitle")} showButton={false} tourTooltip={true} tourType={TourType.COMMUNICATION}>
                <div className={"pt-30 flex justify-between"}>
                    <div>
                        <div data-com={'visualSupport'}>
                        <span className={"ebloom-large-text ebloom-text font-semibold"}>{_.upperFirst(t("admin.visualSupportToLaunch"))}</span>
                        <p className={"ebloom-normal-text"}>{_.upperFirst(t("admin.whichSupport"))}</p>
                        <Checkbox className={"checkbox-purple"} checked={(downloadQrCode && downloadPoster && downloadSlide)} name={"all"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("core.selectAll"))}<br/>
                        <Checkbox className={"checkbox-purple"} checked={downloadQrCode} name={"qr_code"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("admin.qrCode"))}<br/>
                        <Checkbox className={"checkbox-purple"} checked={downloadPoster} name={"poster"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("admin.poster"))}
                        {/*
                            downloadPoster &&
                            <div className={"ml20 inline_icon"}>
                                <Arrow size={16} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}/>
                                <Checkbox className={"checkbox-purple"} checked={downloadPosterEn} name={"poster_en"} onChange={handleChangeCheckbox}/>En
                                <Checkbox className={"checkbox-purple"} checked={downloadPosterFr} name={"poster_fr"} onChange={handleChangeCheckbox}/>Fr
                            </div>
                        */}
                        <br/><Checkbox className={"checkbox-purple"} checked={downloadSlide} name={"slide"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("admin.slide"))}
                        {/*
                            downloadSlide &&
                            <div className={"ml20 inline_icon"}>
                                <Arrow size={16} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}/>
                                <Checkbox className={"checkbox-purple"} checked={downloadSlideEn} name={"slide_en"} onChange={handleChangeCheckbox}/>En
                                <Checkbox className={"checkbox-purple"} checked={downloadSlideFr} name={"slide_fr"} onChange={handleChangeCheckbox}/>Fr
                            </div>
                        */}
                        </div>
                        <div className={"mt-10"}>
                            <Button data-com={'visualSupportBtn'} size={"sm"} className={"ebloom-btn-purple"} disabled={!downloadQrCode && !downloadPosterFr && !downloadPosterEn && !downloadSlideEn && !downloadSlideFr} onClick={handleDownloadSupport}>{_.capitalize(t("core.downloadParam", {param:t("admin.visualSupport")}))}</Button>
                        </div>

                    </div>
                    <div className={"text-center"}>
                        <div data-com={'video'}>
                            <iframe  width="450" height="275" className={"m-auto"} src="https://www.youtube.com/embed/TpQmaDG425g"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>

                                <p>{_.upperFirst(t("admin.videoHelper"))}</p>
                        </div>
                        <div>
                            <Button data-com={'copyVideo'} className={"ebloom-btn-purple mt-20"} onClick={() => {navigator.clipboard.writeText("https://www.youtube.com/embed/TpQmaDG425g");notifySuccess(_.upperFirst(t("admin.copyNotification")));}}>{_.capitalize(t("admin.copyButton"))}</Button>
                        </div>

                    </div>
                </div>
                <div data-com={'introProposition'} className={"ebloom-text ebloom-large-text font-semibold"}>{_.upperFirst(t("admin.introProposition"))}
                    <p>{_.upperFirst(t("admin.introProposition2"))}</p>
                </div>
                <div data-com={'comMail'} >
                    <div className={"my-10"}>
                        <EbloomSelectLang handleChange={handleChangeInviteLang} value={inviteLang}/>
                    </div>
                    <CardDefault showHeader={false} border={"1px solid #C4C4C4"}>
                        <p className={"text-line-break"}>{getTranslations(inviteLang, "admin.com1", {company:company})}</p>
                    </CardDefault>
                </div>

            </CardDefault>
            {
                /*
                <Card style={{background: Colors.EBLOOM_CONTRAST_BLUE, color:"white", cursor:"pointer"}} onClick={handleOpenModal}>
                <Card.Body>
                    <Row>
                        <Col md={6} className={"center"}>
                            <div className={"ebloom-normal-title"}>{_.upperFirst(t("admin.mobileAppAvailable"))}</div>
                            <div className={"ebloom-normal-text"}>{_.upperFirst(t("admin.mobileAppAvailableHelper"))}</div>
                            <div className={"center mt10 mb10"}>
                                <img src={qr} alt={"QR code"}/>
                            </div>
                            <Button style={{maxWidth:230}} className={"ebloom-btn-purple"} >{_.upperFirst(t("admin.inviteToApp"))}</Button>
                        </Col>
                        <Col className={"center"}>
                            <img src={phone} alt={"phone_img"} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
                 */
            }

            <ConfirmModal handleClose={handleCloseModal} show={show} title={_.upperFirst(t("admin.promoteApp"))} showButtons={false} size={"lg"}>
                <div style={{textAlign:"left"}} className={"mb10"}>
                    <Button variant={"secondary"} onClick={handleCloseModal} size={"sm"}>{_.upperFirst(t("core.back"))}</Button>
                </div>
                <p className={"text-line-break"}>{_.upperFirst(t("admin.promoteAppHelper"))}</p>
                <CardDefault showHeader={false} border={"none"} title={"none"}>
                    <p className={"ebloom-normal-text"}>{_.upperFirst(t("admin.whichSupport"))}</p>
                    <Checkbox className={"checkbox-purple"} checked={(downloadQrCode && downloadPosterFr && downloadPosterEn && downloadSlideEn && downloadSlideFr)} name={"all"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("core.selectAll"))}<br/>
                    <Checkbox className={"checkbox-purple"} checked={downloadQrCode} name={"qr_code"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("core.downloadParam", {param:t("admin.qrCode")}))}<br/>
                    <Checkbox className={"checkbox-purple"} checked={downloadPoster} name={"poster"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("admin.orderPoster"))}
                    {
                        downloadPoster &&
                        <div className={"ml20 inline_icon"}>
                            <Arrow size={16} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}/>
                            <Checkbox className={"checkbox-purple"} checked={downloadPosterEn} name={"poster_en"} onChange={handleChangeCheckbox}/>En
                            <Checkbox className={"checkbox-purple"} checked={downloadPosterFr} name={"poster_fr"} onChange={handleChangeCheckbox}/>Fr
                        </div>
                    }
                    <br/><Checkbox className={"checkbox-purple"} checked={downloadSlide} name={"slide"} onChange={handleChangeCheckbox}/>{_.upperFirst(t("admin.orderSlide"))}
                    {
                        downloadSlide &&
                        <div className={"ml20 inline_icon"}>
                            <Arrow size={16} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}/>
                            <Checkbox className={"checkbox-purple"} checked={downloadSlideEn} name={"slide_en"} onChange={handleChangeCheckbox}/>En
                            <Checkbox className={"checkbox-purple"} checked={downloadSlideFr} name={"slide_fr"} onChange={handleChangeCheckbox}/>Fr
                        </div>
                    }
                    <Button className={"ebloom-btn-purple right"} disabled={!downloadQrCode && !downloadPosterFr && !downloadPosterEn && !downloadSlideEn && !downloadSlideFr} onClick={handleDownloadSupport}>{_.capitalize(t("core.downloadParam", {param:t("admin.visualSupport")}))}</Button>

                </CardDefault>
            </ConfirmModal>
        </>
    )
}

export default CommunicationV2;