import React, {useEffect, useState} from "react";
import DisplayAdminView from "../../../Layouts/DisplayAdminView";
import Library from "./Library";
import {Container} from "react-bootstrap";
import EntitiesSwitcher from "../../../../Core/Modules/Button/EntitiesSwitcher";
import {useUserData} from "../../../../../hooks/userHook";
import {useLocation, useParams} from "react-router-dom";



const LibraryPage = (props) => {
    const user = useUserData()
    const location = useLocation()
    const [selectedEntity, setSelectedEntity] = useState(null);
    const params = useParams();

    useEffect(() => {
        if(!!params.idEntity ){
            setSelectedEntity(parseInt(params.idEntity))
        }
    }, [params,location]);

    return(
        <DisplayAdminView {...props} menu={"questions"} pageName={"core.playlist.library"} selectedIndex={3}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true} entity={selectedEntity}/>
                            </div>
                        }
                        <Library selectedEntity={selectedEntity} {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default LibraryPage;