import React, {useState, useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PollFrame from "../../../../../Admin/Pages/WeBloom/Polls/Modules/PollFrame";
import {PollTypes, SurveyStatus, SurveyTypes} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import AnswerAlert from "./Modules/AnswerAlert";
import HttpApi from "../../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import WeBloomNotFound from "../../WeBloomNotFound";
import {useUserData} from "../../../../../../hooks/userHook";

const WeBloomAnswerAlert = (props) => {
    //context
    //state
    const [survey, setSurvey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [linkType, setLinkType] = useState("valid");

    //hooks
    const location = useLocation();
    const navigate = useNavigate();
    const t = useTranslation();
    const userData = useUserData();
    const {id} = useParams();

    //getters

    //posters
    const handleSave = (input) => {
        if (!loading) {
            validateSurvey(input).then(() => {
                setLoading(true);
                HttpApi.post("/collaborative/insertAnswer", {
                    questions: [{id:survey.question.id, type:PollTypes.OPEN_QUESTION, answer:input}],
                    idSurvey: survey.id,
                    type:SurveyTypes.ALERT,
                }).then(() => {
                    notifySuccess(_.upperFirst(t("webloom.notification.saveAnswersSuccess")));
                    setSurvey(prevState => {
                        let survey = {...prevState};
                        if(!survey.question.answers){
                            survey.question.answers = [{id:null, text:input, isRead:true, id_user:userData.id, created_at:new Date()}];
                        }else{
                            survey.question.answers.push({id:null, text:input, isRead:true, id_user:userData.id, created_at:new Date()})
                        }
                        return survey;
                    })
                }).catch(error => {
                    notifyError(error);
                }).finally(() => {
                    setLoading(false);
                })
            }).catch(error => {
                createErrorNotification(_.upperFirst(t("core.notification.error")), error);
            })

        }

    }

    //handlers
    const validateSurvey = (input) => {
        return new Promise((resolve, reject) => {
            if (input && input.trim().length > 0) {
                resolve(true);
            }
            reject(_.upperFirst(t("webloom.notification.ideaAnswerError")));
        });
    }

    //useEffect

    useEffect(() => {
        if (location && location.state) {
            console.log("ici")
            setSurvey(location.state);
        }else if(id){
            console.log("La")
            HttpApi.get("/collaborative/getForUser/" + id).then(response => {
                if(response && response.data && typeof response.data === "string"){
                    setLinkType(response.data);
                }else if(response && response.data){
                    setSurvey(response.data);
                }else{
                    navigate("/webloom/dashboard");
                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            navigate("/webloom/dashboard");
        }
        if((location && location.state) || id){
            console.log("par ici")
            const id_survey = (location && location.state ? parseInt(location.state.id) : parseInt(id));
            HttpApi.post("/collaborative/readAllConversation", {id_survey:id_survey}).catch(error => {
                notifyError(error);
            })
        }
    }, [])

    return (
        <>
            {
                (linkType === "alreadyAnswered" || linkType === "isExpired" || linkType === "isNotAvailable") ?
                    <WeBloomNotFound type={linkType}/>
                    :
                    <PollFrame status={SurveyStatus.RUNNING} type={SurveyTypes.ALERT}>
                        {
                            survey &&
                            <AnswerAlert survey={survey} handleSave={handleSave} loading={loading}/>
                        }
                    </PollFrame>
            }
        </>
    )
}

WeBloomAnswerAlert.propTypes = {}

WeBloomAnswerAlert.defaultProps = {}
export default WeBloomAnswerAlert