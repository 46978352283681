import React, {useState} from "react";
import PropTypes from "prop-types";
import {ListGroup, ListGroupItem, Col, Row, Badge, Button} from "react-bootstrap";
import CustomButton from "../Button/CustomButton";
import _ from "lodash";
import EbloomSearchBar from "../Input/EbloomSearchBar";
import {useTranslation} from "react-multi-lang/lib";
import TextFieldCustom from "../Input/TextFieldCustom";
import UserModel from "../../../../models/User";
import {getLanguages} from "../../../../lang/translations";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {ModelTypes} from "../../../../models/Types";
import {Checkbox} from "@mui/material";

const ListView = (props) => {
    const [searchValue, setSearchValue] = useState("");
    const [langValue, setLangValue] = useState("all");

    const {model, items, listVariant, showAction, handleAction, showSelectAll, handleSelectAll, showLangFilter, actionType,listTitle} = props;

    const t = useTranslation();

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleLangFilter = (event) => {
        setLangValue(event.target.value);

    }

    const filterBySearch = (array, search) => {
        if(!search){
            return array;
        }
        array = array.filter((el) => _.lowerCase(JSON.stringify(el)).includes(_.lowerCase(search)));

        return array;
    };

    const handleSelectAllItems = () => {
        const itemsToReturn = filterBySearch(items, searchValue).filter(el => (langValue === "all" || el.lang === langValue));
        handleSelectAll(itemsToReturn);
    }

    return(
        <>
            {
                !!listTitle &&
                <div className={"w-full text-contrast-purple ebloom-title ebloom-very-small-title mb-20"} >
                    <h1>{listTitle}</h1>
                </div>
            }
        <EbloomSearchBar searchValue={searchValue} handleChange={handleChange} size={"small"}/>
            {
                showLangFilter &&

                    <TextFieldCustom model={UserModel.lang} value={langValue} handleChange={handleLangFilter} fullWidth style={{marginTop:10, marginBottom:10}} size={"small"}>
                        <MenuItem key={-1} value={"all"}>{_.upperFirst(t("core.all"))}</MenuItem>
                        {
                            getLanguages().map((lang, index) => (
                                <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                            ))
                        }
                    </TextFieldCustom>

            }
            {
                showSelectAll &&
                    <div style={{textAlign:"right"}}>
                        <Button className={"ebloom-link-purple"} variant="link" onClick={handleSelectAllItems}>{actionType === "remove" ? _.upperFirst(t("core.removeParam", {param:t("core.allBis")})) : _.upperFirst(t("core.addParam", {param:t("core.allBis")}))}</Button>
                    </div>
            }

        <ListGroup variant={listVariant}>
            {
                filterBySearch(items, searchValue).filter(el => (langValue === "all" || el.lang === langValue)).map((item, index) => (
                    <ListGroupItem key={index}>
                        <Row>
                        {
                            Object.entries(model).map(([key, value], index) => {
                                if(value.list && value.list.show && item[key]){
                                    let val = item[key];
                                    if(value.type === ModelTypes.PRISMA_RELATION){
                                        val = value.relationLink(val);
                                    }
                                    if(key === "status"){
                                        if(item[key] === "invited"){
                                            return(
                                                <Col key={index} className={"right"}>
                                                    <Badge variant="secondary">{_.upperFirst(t("admin." + item[key].toString()))}</Badge>
                                                </Col>
                                            )
                                        }else{
                                            return(
                                                <Col key={index}/>
                                            )
                                        }
                                    }else{
                                        return(
                                            <Col key={index}>
                                                {val}
                                            </Col>
                                        )
                                    }

                                }
                            })
                        }
                            {
                                showAction &&
                                    <Col md={1} className={"text-right"}>
                                        <CustomButton type={actionType} handleClick={handleAction(item)}/>
                                    </Col>
                            }
                        </Row>
                    </ListGroupItem>
                ))
            }
        </ListGroup>
        </>
    )


}

ListView.defaultProps = {
    listVariant:"flush",
    showAction:true,
    handleAction: (item) => {},
    showSelectAll:false,
    showLangFilter:false,
    actionType:"add",
    listTitle : null
};

ListView.propTypes = {
    model:PropTypes.object.isRequired,
    items:PropTypes.array.isRequired,
    listVariant:PropTypes.string,
    showAction:PropTypes.bool,
    showSelectAll:PropTypes.bool,
    handleSelectAll:PropTypes.func,
    showLangFilter:PropTypes.bool,
    actionType:PropTypes.string,
    listTitle:PropTypes.string,
};

export default ListView;