import React, {useState, useEffect} from "react";
import _ from "lodash";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import TagModel from "../../../../../models/Tag";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {useTranslation, withTranslation} from "react-multi-lang/lib";
import {Row} from "react-bootstrap";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification, notifyError
} from "../../../../../Utils/Notification";
import {MenuItem} from "@mui/material";
import ModelForms from "../../../../Core/Modules/Input/ModelForms";


const TagEditor = (props) => {
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState({id:null, name:"", id_category:-1});
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [tablePage, setTablePage] = useState(0);

    const t = useTranslation();


    const fetchTags = () => {
        HttpApi.getV2("/tags/getAll").then(response => {
            if(response && response.data){
                setTags(response.data);
            }else{
                createInfoNotification("Info", "No tags");
                setTags([]);
            }

        }).catch(error => {
           notifyError(error);
        })
    };

    const handleDelete = () => {
        fetchTags();
    };

    const handleSave = () => {
        let url = "/tags/create";
        let data = {name:tag.name, id_category:tag.id_category};
        let notif1 = "created";
        let notif2 = "creating";

        if(tag.id){
            url="/tags/update";
            data.id = tag.id;
            notif1 = "updated";
            notif2 = "updating";
        }

        HttpApi.postV2(url, data).then(response => {
            if(response){
                createSuccessNotification("Success !", "Tag successfully " + notif1);
                fetchTags();
                setSideFormOpen(false);
            }else{
                createErrorNotification("Error !", "An error occurred while " + notif2 + " the tag");
            }

        }).catch(error => {
           notifyError(error);
        });
    };


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setTag(prevState => {
            let tag = {...prevState};
            tag[name] = value;
            return tag;
        })
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }


    const resetTagEditState = () => {
        setTag({id:null, name:"", id_category: -1})
    };

    useEffect(() => {
        fetchTags();
    }, []);


    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(toEdit){
            setTag(toEdit);
        }else{
            resetTagEditState();
        }

        setSideFormOpen(open);


    };

    const formTitle = tag.id ? _.capitalize(t("core.update")) + " " + _.capitalize(t("core.tag.tag")) : _.capitalize(t("core.create")) + " " + _.capitalize(t("core.tag.tag"));

    return(
        <>
            <CardDefault title={_.capitalize(t("core.tag.tags"))} handleRightButton={toggleDrawer(true)}>
                <TableViewV2 items={tags} model={TagModel} handleDelete={handleDelete} handleEdit={toggleDrawer} tablePage={tablePage} handleTablePage={handleTablePage}/>
            </CardDefault>
            <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <ModelForms model={TagModel} handleChange={handleChange} values={tag}/>
            </SideForm>
        </>
    )

}

export default withTranslation(TagEditor);