import React from 'react';
import PropTypes from 'prop-types';

const ArrowRightIcon = (props) => {
    const {colors,width,height} = props

    return (
        <svg style={{height : height, width: width}} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{fill: colors ? colors.secondary : "#B32DDD"}} d="M15 9L0 17.6603L0 0.339746L15 9Z"/>
        </svg>
    );
};

ArrowRightIcon.propTypes = {
    colors: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
};

ArrowRightIcon.defaultProps = {
    color: null,
    width: 20,
    height: 20,
}
export default ArrowRightIcon;
