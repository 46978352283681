import React, {useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
import {FullContrastPurpleTextField} from "../../../../Utils/Global";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton, InputAdornment, OutlinedInput} from "@mui/material";


const TextFieldCustom = (props) => {

    const t = useTranslation();
    const {error, helper, helperText, model, style, fullWidth, value, handleChange, readOnly, size, children, hasLabel, placeholder} = props;
    const [showPassword, setShowPassword] = useState(false);
    return(
        <FullContrastPurpleTextField
            className={"text-purple border border-purple relative"}
            error={error}
            helperText={helper ? (_.upperFirst(t(model.form.helperText)) || helperText) : ""}
            style={style}
            fullWidth={fullWidth}
            required={model.form.required}
            multiline={model.form.multiline}
            type={model.form.password ? showPassword ? "text" : "password" : "text"}
            rows={model.form.rows}
            select={model.form.select}
            id={model.form.id}
            label={hasLabel ? _.upperFirst(t(model.form.label)) : ""}
            placeholder={placeholder ? placeholder : model.form.placeholder ? _.upperFirst(t(model.form.placeholder)) : _.upperFirst(t(model.form.label))}
            variant="outlined"
            name={model.form.name}
            value={value}
            onChange={handleChange}
            InputProps={{
                readOnly:readOnly || model.form.readOnly,
                endAdornment : model.form.password && model.form.name !== "confirmPassword" && (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={()=> setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityOff className={"text-contrast-purple"}/> : <Visibility className={"text-contrast-purple"}/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            disabled={readOnly || model.form.readOnly}
            size={size}
        >
            {children}
        </FullContrastPurpleTextField>
    )

}

TextFieldCustom.defaultProps = {
    model:{
        id:"noId",
        label:"noLabel",
        name:"noName",
        required:true,
        multiline:false,
        select:false,
        readOnly:false,
    },
    value:"noValue",
    style:{marginBottom:10, marginTop:10, marginLeft:0, marginRight:0},
    error:false,
    helperText:"",
    helper:false,
    size:"medium",
    fullWidth:true,
    hasLabel:false
}

TextFieldCustom.propTypes = {
    model:PropTypes.object.isRequired,
    value:PropTypes.any.isRequired,
    style:PropTypes.object,
    error:PropTypes.bool,
    helperText:PropTypes.string,
    helper:PropTypes.bool,
    readOnly:PropTypes.bool,
    size:PropTypes.string,
    fullWidth: PropTypes.bool,
    hasLabel:PropTypes.bool,
    placeholder:PropTypes.any
}

export default TextFieldCustom;