import {useContext, useEffect, useState} from "react";
import React from "react";

import {useNavigate} from 'react-router-dom'
import {
    createErrorNotification,
    createSuccessNotification,
    notifyCustomError,
    notifyError,
    notifyInfo,
} from '../../Utils/Notification';

import {Card, Button, Container, Row, Col} from "react-bootstrap";

import HttpApi from "../../httpApi";
import Login from "../../login/login";
import TextFieldCustom from "./Modules/Input/TextFieldCustom";
import UserModel from "../../models/User";
import CompanyModel from "../../models/Company";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {getLanguages} from "../../lang/translations";
import _ from "lodash";
import {Colors} from "../../Utils/Colors";
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-multi-lang";
import {setLanguage} from "react-multi-lang/lib";
import LoadingView from "./Layouts/LoadingView";
import { useDispatch } from 'react-redux'
import {
    updateId,
    updateEmail,
    updateCompany,
    updateIdCompany,
    updateName,
    updateModule, updateCompanyType, updateCompanyGroup, updateIsSuperAdmin, updateRoles
} from "../../store/user";
import {ProductTourContext} from "../../Utils/Context/ProductTourContext";
import ProspectModal from "./Modules/Modal/ProspectModal";


const LoginPage = (props) => {
    const [activeForm, setActiveForm] = useState(0);
    const [userLogin, setUserLogin] = useState({email:"", password:""});
    const [userRegister, setUserRegister] = useState({password:"",
        confirmPassword:"",
        email:"",
        code:"",
        lang:"en",
        name:"",
    timezone:Intl.DateTimeFormat().resolvedOptions().timeZone});
    const [policyChecked, setPolicyChecked] = useState(false);
    const [emailPasswordRecovery, setEmailPasswordRecovery] = useState("");
    const [hasEmail, setHasEmail] = useState(true);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [loadingInvite, setLoadingInvite] = useState(false);
    const [userInvite, setUserInvite] = useState({id:null, email:"", name:"", token:"", lang:"en", password:"", confirmPassword:"", timezone:Intl.DateTimeFormat().resolvedOptions().timeZone});

    //Prospect Account states
    const [showModal, setShowModal] = useState(false);
    const [isFreeEnable, setIsFreeEnable] = useState(false)


    const t = useTranslation();
    const navigate = useNavigate();

    const {bp} = props;

    const dispatch = useDispatch();

    const {fetchData} = useContext(ProductTourContext)
    //HANDLER

    const handleChangeRegister = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setUserRegister(prevState => {
            let userRegister = {...prevState};
            if(name === "name"){
                userRegister[name] = value;
            }else{
                userRegister[name] = value.trim();
            }
            if(name === 'lang'){
                setLanguage(value);
            }
            return userRegister;
        })
    };

    const handleChangeInvite = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if(name === 'lang'){
            setLanguage(value);
        }

        setUserInvite(prevState => {
            let userInvite = {...prevState};
            userInvite[name] = value;
            return userInvite;
        })
    }

    const handleChangePolicy = (event) => {
        setPolicyChecked(event.target.checked);
    }

    const handleChangeLogin = (event) => {
       const name = event.target.name;
       const value = event.target.value;

       setUserLogin(prevState => {
           let userLogin = {...prevState};
           userLogin[name] = value.trim();
           return userLogin;
       })
    };

    const handleChangeActiveState = (value) => event => {
        setActiveForm(value);
    }

    const handleChangeRecovery = (event) => {
        const value = event.target.value;
        setEmailPasswordRecovery(value);
    };

    const handleChangeLang = (event) => {
        const value = event.target.value;
        setUserRegister(prevState => {
            let userRegister  = {...prevState};
            userRegister.lang = value;
            return userRegister;
        })
        setLanguage(value);
    }

    //LOGIN AND REGISTER FUNCTIONS

   const register = () => {
        if(!loadingRegister){
            setLoadingRegister(true);
            if(checkRegisterValidity()) {
                HttpApi.postV2("/auth/register", userRegister)
                    .then(async results => {
                        if (results && results.data) {
                            if(typeof results.data === "string"){
                                notifyCustomError(results.data);
                                return;
                            }
                            const user = results.data;
                            createSuccessNotification("Success !", "Your registration has correctly been saved");
                            await Login.connectApp(results);
                            dispatch(updateId(results.data.userId));
                            dispatch(updateEmail(results.data.email));
                            dispatch(updateName(results.data.name));
                            dispatch(updateCompany(results.data.company));
                            dispatch(updateIdCompany(results.data.id_company));
                            dispatch(updateRoles(results.data.roles));
                            dispatch(updateModule(results.data.module));
                            dispatch(updateCompanyType(results.data.company_type));
                            dispatch(updateCompanyGroup(user.company_group));

                            const welcomeText = _.upperFirst(t("employee.welcome.text1")) + " \n " + _.upperFirst(t("employee.welcome.text2"));

                            if (user.roles.includes("global_employee")) {
                                notifyInfo(welcomeText, true);
                                navigate("/employee/daily");
                            } else if (user.roles.includes("global_admin")) {
                                const adminWelcome = _.upperFirst(t("admin.welcome"));
                                notifyInfo(adminWelcome, true);
                                if (user.module === "prospect") {
                                    navigate("/manager/dashboard");
                                } else {
                                    navigate("/admin/account");
                                    fetchData();
                                }
                            } else {
                                notifyInfo(welcomeText, true);
                                navigate("/employee/daily");
                            }
                        } else {
                            notifyCustomError("Unable to save your registration")
                        }
                    })
                    .catch((error) => {
                        notifyError(error);
                    }).finally(() => {
                    resetPasswordFields("userRegister");
                    setLoadingRegister(false);
                });
            }else{
                createErrorNotification("Error !", "Invalid data for registration");
                resetPasswordFields("userRegister");
                setLoadingRegister(false);
            }
        }
    };

   const registerInvite = () => {
       if(!loadingInvite){
           setLoadingInvite(true);
           if(checkRegisterInviteValidity()){
               HttpApi.put("/auth/registerInvite", userInvite)
                   .then(async results => {
                       if (results && results.data) {
                           if(typeof results.data === "string"){
                               notifyCustomError(results.data);
                               return;
                           }
                           const user = results.data;
                           createSuccessNotification("Success !", "Your registration has correctly been saved");
                           await Login.connectApp(results);
                           dispatch(updateId(user.userId));
                           dispatch(updateEmail(user.email));
                           dispatch(updateName(user.name));
                           dispatch(updateCompany(user.company));
                           dispatch(updateIdCompany(user.id_company));
                           dispatch(updateRoles(user.roles));
                           dispatch(updateModule(user.module));
                           dispatch(updateCompanyType(user.company_type));
                           dispatch(updateCompanyGroup(user.company_group));
                           dispatch(updateIsSuperAdmin(user.is_super_admin));

                           const welcomeText = _.upperFirst(t("employee.welcome.text1")) + " \n " + _.upperFirst(t("employee.welcome.text2"));

                           if (user.roles.includes("global_employee")) {
                               notifyInfo(welcomeText, true);
                               navigate("/employee/daily");
                           } else if (user.roles.includes("global_admin")) {
                               const adminWelcome = _.upperFirst(t("admin.welcome"));
                               notifyInfo(adminWelcome, true);
                               if (user.module === "prospect") {
                                   navigate("/manager/dashboard");
                               } else {
                                   navigate("/admin/account");
                                   fetchData();
                               }
                           } else {
                               notifyInfo(welcomeText, true);
                               navigate("/employee/daily");
                           }
                       } else {
                           createErrorNotification("Error !", "Unable to save your registration");
                       }
                   })
                   .catch((error) => {
                       notifyError(error);
                   }).finally(() => {
                   setLoadingInvite(false);
               });
           }else{
               setLoadingInvite(false);
           }
       }
   }

   const login = () => {
        if(checkLoginValidity()) {
            HttpApi.authenticate(userLogin)
                .then(async user => {
                    if (user && typeof user.data === "boolean") {
                        const value = user.data
                        setIsFreeEnable(value)
                        setShowModal(true)
                    } else if (user && typeof user.data === "string") {
                        notifyCustomError(user.data);
                    } else {
                        if (user) {
                            await Login.connectApp(user);
                            dispatch(updateId(user.data.userId));
                            dispatch(updateEmail(user.data.email));
                            dispatch(updateName(user.data.name));
                            dispatch(updateCompany(user.data.company));
                            dispatch(updateIdCompany(user.data.id_company));
                            dispatch(updateRoles(user.data.roles));
                            dispatch(updateModule(user.data.module));
                            dispatch(updateCompanyType(user.data.company_type));
                            dispatch(updateCompanyGroup(user.company_group));

                            if (user.data.roles.includes("global_backoffice")) {
                                navigate("/backoffice/user");
                            } else if (user.data.roles.includes("datatopia_backoffice")) {
                                navigate("/backoffice/company");
                            } else if (user.data.roles.includes("global_admin")) {
                                if (user.data.module !== "prospect") {
                                    fetchData();
                                }
                                navigate("/manager/dashboard");
                            } else if (user.data.roles.includes("demo_backoffice")) {
                                navigate("/backoffice/company")
                            } else {
                                navigate('/employee/daily');
                            }
                        }
                    }
                }).finally(() => resetPasswordFields("userLogin"));
        }else{
            resetPasswordFields("userLogin");
            createErrorNotification("Error !", "Invalid credentials");
        }
    };

   const resetPassword = () => {
        if(emailPasswordRecovery){
            HttpApi.requestResetPassword({email:emailPasswordRecovery}).then((response) => {
                if(response){
                    createSuccessNotification("Success !", "Email successfully sent to " + emailPasswordRecovery);
                }
                setEmailPasswordRecovery("");
            }).catch(error => {
                notifyError(error)
            })
        }else{
            createErrorNotification("Error !", "You must enter your email");
        }
   };

    //GLOBAL LOGIN AND REGISTER CHECKS

    const checkLoginValidity = () => {
        return userLogin.email && userLogin.password;
    }

    const checkRegisterValidity = () => {
         if(userRegister.password && userRegister.confirmPassword && userRegister.email && userRegister.lang && userRegister.code){
             let valid = true;
             let message = "";
             if(UserModel.password.form.error(userRegister.password)){
                 message += _.upperFirst(t('core.login.passwordHelper'));
                 valid = false;
             }
             if(UserModel.confirmPassword.form.error(userRegister.password, userRegister.confirmPassword)){
                 message += " \n Password and Confirm Password doesn't match";
                 valid = false;
             }
             if(UserModel.email.form.error(userRegister.email) && hasEmail){
                 message += " \n Invalid email format";
                 valid = false;
             }

             if(UserModel.phone.form.error(userRegister.email) && !hasEmail){
                 message += " \n Invalid phone format";
                 valid = false;
             }

             if(UserModel.name.form.error(userRegister.name) && !hasEmail){
                 message += " \n Invalid name format";
                 valid = false;
             }

             if(!valid){
                 createErrorNotification("Error ! ", message);
             }

             return valid;
         }else{
             createErrorNotification("Error !", "Every field must be filled in");
             return false;
         }
    }

    const checkRegisterInviteValidity = () => {
        if(userInvite.password && userInvite.confirmPassword && userInvite.lang){
            let valid = true;
            let message = "";
            if(UserModel.password.form.error(userInvite.password)){
                message += _.upperFirst(t('core.login.passwordHelper'));
                valid = false;
            }
            if(UserModel.confirmPassword.form.error(userInvite.password, userInvite.confirmPassword)){
                message += " \n Password and Confirm Password doesn't match";
                valid = false;
            }

            if(!valid){
                createErrorNotification("Error ! ", message);
            }

            return valid;
        }else{
            createErrorNotification("Error !", "Every field must be filled in");
            return false;
        }
    }


    //FIELDS RESET

    const resetPasswordFields = (name) => {
        if(name === "userRegister"){
            setUserRegister(prevState => {
                let userRegister = {...prevState};
                userRegister.password = "";
                userRegister.confirmPassword = "";
                return userRegister;
            });
        }else{
            setUserLogin(prevState => {
                let userLogin = {...prevState};
                userLogin.password = "";
                return userLogin;
            })
        }
    }

    const showLogin = () => {
       resetPasswordFields("userRegister");
        setActiveForm(1);
    };

    const showPassword = () => {
        setActiveForm(3);
        setEmailPasswordRecovery("");
    };

    const handleChangeHasEmail = () => {
        setHasEmail(!hasEmail);
    }

    let CloneModel = _.cloneDeep(UserModel);
    let CloneModelRegister = _.cloneDeep(UserModel);
    CloneModel.email.form.label="core.login.emailPhoneNumber";
    CloneModelRegister.email.form.label = "core.login.email";

    const listener = (event) => {
        if(event.keyCode === 13){ //KeyCode for Enter
            if(activeForm === 3){
                resetPassword();
            }else if(userLogin.email.length > 0 && userLogin.password.length > 0){
                login();
            }
        }

    };

    useEffect(() => {
        let url = new URL(window.location.href);
        let tokenUrl = url.searchParams.get("token");
        let userIdUrl = url.searchParams.get("id");
        let emailUrl = url.searchParams.get("email");
        let langUrl = url.searchParams.get("lang");
        if(langUrl){
            setLanguage(langUrl);
        }
        if(tokenUrl && userIdUrl){
           setUserInvite(prevState => {
               let userInvite = {...prevState};
               userInvite.id = parseInt(userIdUrl);
               userInvite.token = tokenUrl;
               userInvite.email = emailUrl;
               userInvite.lang = langUrl;
               return userInvite;
           });
            setActiveForm(4);
        }
    }, [])

    const isMobile = bp.isMobileLandscapeOrLess();


    return (
        <div onKeyUp={listener} className={"bg-bg-blue min-h-screen"}>
                <div className={"pt-15 pb-10"} >
                    <div className="logo-ebloom h-60"/>
                </div>
                {
                    isMobile &&
                    <div className={"-mt-70 mr-20 text-right"}>

                            <TextFieldCustom size={"small"} model={UserModel.lang}
                                             style={{width:90, textAlign:"left"}}
                                             handleChange={handleChangeLang}
                                             value={userRegister.lang}>
                                {
                                    getLanguages().map(lang => (
                                        <MenuItem key={lang.value}
                                                  value={lang.value}>{lang.name}</MenuItem>
                                    ))
                                }
                            </TextFieldCustom>

                    </div>
                }

            <Container>
                {activeForm !== 3 &&
                <Row>
                    {
                        (activeForm === 0 && isMobile) &&
                            <Col className={"text-center mt-200"}>
                                <Button size={"lg"} block className={"ebloom-btn-purple mb-40"} onClick={handleChangeActiveState(1)}>{_.upperCase(t("core.login.connection"))}</Button>
                                <Button size={"lg"} block className={"ebloom-btn-purple-outline mt-40"} onClick={handleChangeActiveState(2)}>{_.upperCase(t("core.login.createNewAccount"))}</Button>
                            </Col>
                    }
                    {((activeForm === 1 && isMobile) || (activeForm !== 3 && activeForm !== 4 && !isMobile)) &&
                        <Col className={isMobile ? "mr-0" : "mr-40"}>
                            <Card className={"bg-transparent border-transparent"}>
                                <Card.Body>
                                    <div className={"text-center mb-20"}>
                                        <Card.Title style={{
                                            color: Colors.EBLOOM_CONTRAST_PURPLE,
                                            fontSize: "2em",
                                            fontWeight: "normal",
                                            marginBottom: 20
                                        }}>{_.upperFirst(t("core.login.connection"))}</Card.Title>
                                    </div>
                                    <Row>
                                        <TextFieldCustom model={CloneModel.email}
                                                         value={userLogin.email}
                                                         handleChange={handleChangeLogin}/>
                                    </Row>
                                    <Row>
                                        <TextFieldCustom id={"password-login"} model={UserModel.password}
                                                         value={userLogin.password}
                                                         handleChange={handleChangeLogin}/>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className={"w-full text-center"}>
                                                <Button id="login-button" block={isMobile} className={"ebloom-btn-purple"}
                                                        onClick={login}>
                                                    {_.upperCase(t("core.login.logIn"))}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className={"w-full text-center"}>
                                            <Button variant="link" className={"ebloom-link-purple"}
                                                    onClick={showPassword}>{_.upperFirst(t("core.login.passwordForgotten"))}</Button>
                                        </div>
                                    </Row>
                                    {
                                        isMobile &&
                                        <Row className={"mt-50"}>
                                            <Col>
                                                <p className={"font-bold"}>{_.upperFirst(t("core.login.iMANewUser"))}. <Button variant={"link"} className={"ebloom-link-purple p-0 pb-2 font-bold"} onClick={handleChangeActiveState(2)}>{_.upperFirst(t("core.login.createNewAccount"))}</Button></p>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    {((activeForm === 2 && isMobile) || (activeForm !== 3 && activeForm !== 4 && !isMobile)) &&
                        <Col className={isMobile ? "ml-0" : "ml-40"}>
                            <Card
                                style={{backgroundColor: isMobile ? "transparent" : "#EEF7FA", borderColor: "transparent", borderRadius: 25}}>
                                <Card.Body>
                                    <div style={{textAlign: "center", marginBottom: 20}}>
                                        <Card.Title style={{
                                            color: Colors.EBLOOM_CONTRAST_PURPLE,
                                            fontSize: "2em",
                                            fontWeight: "normal",
                                            marginBottom: 20
                                        }}>{_.upperFirst(t("core.login.createAccount"))}</Card.Title>
                                    </div>
                                    <Row style={{marginTop: 10}}>
                                        <Col style={{paddingLeft:0}}>
                                            <Checkbox className={"checkbox-purple"} checked={!hasEmail}
                                                      onChange={handleChangeHasEmail}/>{_.capitalize(t("core.login.hasEmailQuestion"))}
                                        </Col>
                                    </Row>
                                    {
                                        hasEmail ?
                                            <Row>
                                                <Col>
                                                    <TextFieldCustom size={isMobile ? "normal" : "small"} model={CloneModelRegister.email}
                                                                     error={UserModel.email.form.error(userRegister.email)}
                                                                     value={userRegister.email}
                                                                     handleChange={handleChangeRegister}/>
                                                </Col>

                                            </Row>
                                            :
                                            <Row>
                                                <Col style={{paddingLeft: 0, paddingRight: isMobile ? 0 : 10}}>
                                                    <TextFieldCustom size={isMobile ? "normal" : "small"} model={CloneModel.phone} error={UserModel.phone.form.error(userRegister.email)} value={userRegister.email} handleChange={handleChangeRegister} helper/>
                                                </Col>
                                                <Col style={{paddingRight: 20, paddingLeft:isMobile ? 5 : 15}}>
                                                    <TextFieldCustom size={isMobile ? "normal" : "small"} model={CloneModel.name} error={UserModel.name.form.error(userRegister.name)} value={userRegister.name} handleChange={handleChangeRegister}/>
                                                </Col>
                                            </Row>
                                    }


                                    <Row>
                                        <Col style={{paddingLeft: 12, paddingRight: isMobile ? 0 : 10}}>
                                            <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.password}
                                                             error={UserModel.password.form.error(userRegister.password)}
                                                             handleChange={handleChangeRegister}
                                                             value={userRegister.password} margin={0} helper/>
                                        </Col>
                                        <Col style={{paddingRight: 12, paddingLeft:isMobile ? 5 : 15}}>
                                            <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.confirmPassword}
                                                             error={UserModel.confirmPassword.form.error(userRegister.password, userRegister.confirmPassword)}
                                                             value={userRegister.confirmPassword}
                                                             handleChange={handleChangeRegister} margin={0}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.lang}
                                                             handleChange={handleChangeRegister}
                                                             value={userRegister.lang}>
                                                {
                                                    getLanguages().map(lang => (
                                                        <MenuItem key={lang.value}
                                                                  value={lang.value}>{lang.name}</MenuItem>
                                                    ))
                                                }
                                            </TextFieldCustom>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextFieldCustom size={"small"} model={CompanyModel.code}
                                                             value={userRegister.code}
                                                             handleChange={handleChangeRegister} helper/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 10}}>
                                        <Col>
                                            <Checkbox className={"checkbox-purple"} checked={policyChecked}
                                                      onChange={handleChangePolicy}/><span><a
                                            href="/documents/Privacy_Policy_eBloom.html" className={"ebloom-link-purple"} target="_blank">{_.upperFirst(t("core.login.iHaveAgree", {param:t("core.login.privacyPolicy")}))}</a>*</span>
                                        </Col>
                                    </Row>
                                     <Row>
                                         <Col>
                                             <div style={{textAlign: "center", marginTop: 30}}>
                                                 <Button className={"ebloom-btn-purple"} block={isMobile} onClick={register}
                                                         disabled={!policyChecked || loadingRegister}>
                                                     {loadingRegister ? <LoadingView size={16}/> : _.upperFirst(t("core.login.signUp"))}
                                                 </Button>
                                             </div>
                                         </Col>
                                     </Row>
                                    {
                                        isMobile &&
                                        <Row style={{marginTop:50}}>
                                            <Col>
                                                <p style={{fontWeight:"bold"}}>{_.upperFirst(t("core.login.alreadyAMember"))}. <Button variant={"link"} style={{color:Colors.EBLOOM_PURPLE, padding:0, paddingBottom:2, fontWeight:"bold"}} onClick={handleChangeActiveState(1)}>{_.upperFirst(t("core.login.connection"))}</Button></p>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                </Row>
                }
                {
                activeForm === 3 &&
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card style={{backgroundColor:"transparent", borderColor:"transparent"}}>
                            <Card.Body>
                                <div style={{textAlign:"center"}}>
                                    <Card.Title style={{color:Colors.EBLOOM_CONTRAST_PURPLE, fontSize:"2em", fontWeight:"normal"}}>Password Recovery</Card.Title>
                                    <Card.Subtitle style={{fontWeight:"normal"}}>Enter your email address or phone number used to register and we will send you an email/SMS to reset your password</Card.Subtitle>
                                </div>
                                <Row>
                                    <TextFieldCustom  model={CloneModel.email} helper={false} value={emailPasswordRecovery} handleChange={handleChangeRecovery}/>
                                </Row>
                                <Row>
                                    <div style={{width:"100%",textAlign:"center"}}>
                                        <Button className={"ebloom-btn-purple"} onClick={resetPassword}>
                                            Send
                                        </Button>
                                        <Button className={"ebloom-btn-purple-outline"} style={{marginLeft:10}} variant="outline-primary" onClick={showLogin}>{_.upperFirst(t("core.back"))}</Button>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               }
                {
                    activeForm === 4 && //Invited user simplified form
                    <Col style={{marginLeft: isMobile ? 0 : 40}}>
                        <Card
                            style={{backgroundColor: isMobile ? "transparent" : "#EEF7FA", borderColor: "transparent", borderRadius: 25}}>
                            <Card.Body>
                                <div style={{textAlign: "center", marginBottom: 20}}>
                                    <Card.Title style={{
                                        color: Colors.EBLOOM_CONTRAST_PURPLE,
                                        fontSize: "2em",
                                        fontWeight: "normal",
                                        marginBottom: 20
                                    }}>{_.upperFirst(t("core.login.createAccount"))}</Card.Title>
                                </div>

                                <Row>
                                    <Col>
                                        <span style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}>{userInvite.email}</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={{paddingLeft: 0, paddingRight: isMobile ? 0 : 10}}>
                                        <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.password}
                                                         error={UserModel.password.form.error(userInvite.password)}
                                                         handleChange={handleChangeInvite}
                                                         value={userInvite.password} margin={0} helper/>
                                    </Col>
                                    <Col style={{paddingRight: 20, paddingLeft:isMobile ? 5 : 15}}>
                                        <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.confirmPassword}
                                                         error={UserModel.confirmPassword.form.error(userInvite.password, userInvite.confirmPassword)}
                                                         value={userInvite.confirmPassword}
                                                         handleChange={handleChangeInvite} margin={0}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <TextFieldCustom size={isMobile ? "normal" : "small"} model={UserModel.lang}
                                                     handleChange={handleChangeInvite}
                                                     value={userInvite.lang}>
                                        {
                                            getLanguages().map(lang => (
                                                <MenuItem key={lang.value}
                                                          value={lang.value}>{lang.name}</MenuItem>
                                            ))
                                        }
                                    </TextFieldCustom>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                    <Col>
                                        <Checkbox className={"checkbox-purple"} checked={policyChecked}
                                                  onChange={handleChangePolicy}/><span><a
                                        href="/documents/Privacy_Policy_eBloom.html" target="_blank" className={"ebloom-link-purple"}>{_.upperFirst(t("core.login.iHaveAgree", {param:t("core.login.privacyPolicy")}))}</a>*</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{textAlign: "center", marginTop: 30}}>
                                            <Button className={"ebloom-btn-purple"} block={isMobile} onClick={registerInvite}
                                                    disabled={!policyChecked || loadingInvite}>
                                                {loadingRegister ? <LoadingView size={16}/> : _.upperFirst(t("core.login.signUp"))}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    isMobile &&
                                    <Row style={{marginTop:50}}>
                                        <Col>
                                            <p style={{fontWeight:"bold"}}>{_.upperFirst(t("core.login.alreadyAMember"))}. <Button variant={"link"} style={{color:Colors.EBLOOM_PURPLE, padding:0, paddingBottom:2, fontWeight:"bold"}} onClick={handleChangeActiveState(1)}>{_.upperFirst(t("core.login.connection"))}</Button></p>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Container>
            <Row style={{marginTop:20}}/>
            <ProspectModal show={showModal} handleClose={() => setShowModal(false)} isLogin={true} showFree={isFreeEnable}/>
        </div>
    );
}

export default LoginPage;