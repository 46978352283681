import React from "react"
import PropTypes from "prop-types";
import {FullBlueTextField, FullContrastPurpleTextField, FullPurpleTextField, QuestionSectors} from "../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";


const CategoryList = (props) => {
  const {handleChange, value, color, name, size, label} = props;

  const t = useTranslation();

  const CustomTextField = color === "purple-contrast" ? FullContrastPurpleTextField : color === "blue" ? FullBlueTextField : FullPurpleTextField;

  return(

      <CustomTextField select variant={"outlined"} fullWidth size={size} label={label} name={name} defaultValue={0} onChange={handleChange} value={value}>
        <MenuItem key={0} value={'all'}>
          <span>{_.upperFirst(t("core.allBis"))}</span>
        </MenuItem>
        {
          Object.values(QuestionSectors).map((sector, index) => (
            <MenuItem key={index} value={sector}>
              <span>{_.upperFirst(t(`core.${sector}`))}</span>
            </MenuItem>
          ))

        }
      </CustomTextField>

  )
}

CategoryList.propTypes ={
  categories:PropTypes.array,
  id_category:PropTypes.number,
  handleChange:PropTypes.func.isRequired,
  value:PropTypes.number.isRequired,
  color:PropTypes.string,
  name:PropTypes.string,
  size:PropTypes.string,
  label:PropTypes.string.isRequired,
  id_action: PropTypes.number,
  isModelCustomEnable: PropTypes.bool,
}

CategoryList.defaultProps = {
  color:"purple-contrast",
  name:"category-filter",
  size:"small",
  isModelCustomEnable : false
}

export default CategoryList;