import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import React from "react";
import Report from "../../../../Manager/Pages/Report/Report";
import {getLanguage} from "react-multi-lang/lib";

const BackofficeReport = (props) => {
    return (
        <DisplayBackofficeView menu={"noMenu"} {...props}>
            {companyToEdit => (
                <Container>
                        <Report lang={getLanguage()} companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayBackofficeView>

    )
}

export default BackofficeReport;
