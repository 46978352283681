import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {communicationSteps} from "./communicationSteps";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";

const CommunicationTour = (props) => {

    const {
        updateStep,
        stepTour,
        stepCommunication, setStepCommunication,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        validateOutcome,
        icon,
        updateHandler
    } = useContext(ProductTourContext)

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
                updateStep(TourType.COMMUNICATION,next)
            }
        }
    }
    const handleTour = () => {
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)

        const tour = {
            id : stepTour[TourType.COMMUNICATION].id,
            current_step : stepTour[TourType.COMMUNICATION].step >= communicationSteps.length - 1 ? 0 : stepTour[TourType.COMMUNICATION].step,
            type: TourType.COMMUNICATION,
            finished : stepTour[TourType.COMMUNICATION].outcome
        }
        if(stepCommunication >= communicationSteps.length - 1){
            validateOutcome(TourType.COMMUNICATION)
            setStepCommunication(0)
            icon.current.classList.remove('ebloom-ring')
        }else {
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})
        }
    }

    const iconRinging = () => {
        if(icon.current && stepTour[TourType.COMMUNICATION]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.COMMUNICATION].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepCommunication]);


    useEffect(() => {
        fetchData();
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        updateHandler(TourType.COMMUNICATION,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={communicationSteps}
                continuous
                run={tourStarted}
                stepIndex={stepCommunication}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                tooltipComponent={EbloomTourTip}
                styles={styleEbloom}
            />

        </>
    )
}
export default CommunicationTour