import React from "react";
import {Container} from "react-bootstrap";
import FiltersList from "./FiltersList";
import DisplayAdminView from "../../Layouts/DisplayAdminView";



const FiltersListPage = (props) =>{

    return (
        <DisplayAdminView selectedIndex={31} menu={"settings"} {...props}>
            {() => (
                <Container>
                    <FiltersList {...props}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default FiltersListPage;


