
export const customLibrarySteps = [
    {
        title: "admin.productTour.customLibrary.step1.title",
        content: "admin.productTour.customLibrary.step1.content",
        target: '[data-customLib="selectLang"]',
        placement: 'top',
        disableBeacon : true,
        disableNext : true,
        nextText : "admin.productTour.customLibrary.step1.nextText"
    },
    {
        title: "admin.productTour.customLibrary.step2.title",
        content: "admin.productTour.customLibrary.step2.content",
        target: '[data-customLib="steps"]',
        placement: 'top',
        disableBeacon : true,
        spotlightClicks: false,
        disableBack : true
    },
    {
        title: "admin.productTour.customLibrary.step3.title",
        content: "admin.productTour.customLibrary.step3.content",
        target: '[data-customLib="step7"]',
        placement: 'top',
        disableBeacon : true,
        spotlightClicks: false,
        nextText: "admin.productTour.customLibrary.step3.nextText"
    },
    {
        title: "admin.productTour.customLibrary.step3.title",
        content: "admin.productTour.customLibrary.step3.content",
        target: '[data-customLib="step7"]',
        placement: 'top',
        disableBeacon : false,
        nextText: "admin.productTour.customLibrary.step3.nextText",
        disableSkip: true,
    },
    {
        title: "admin.productTour.customLibrary.step5.title",
        content: "admin.productTour.customLibrary.step5.content",
        target: '[data-customLib="validation"]',
        placement: 'left',
        disableBeacon : true,
        toolTipWidth : '200px' ,
    },
    {
        title: "admin.productTour.customLibrary.step6.title",
        content: "admin.productTour.customLibrary.step6.content",
        target: '[data-customLib="saveBtn"]',
        placement: 'top',
        disableBeacon : true,

    },
]