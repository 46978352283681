import React from "react";
import {Navigate} from "react-router-dom";
import Login from "../login/login";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return(
        Login.isLogin() && restricted ?
            <Navigate to='/'/>
            : <Component {...rest}/>
    )
}

export default PublicRoute;