import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton/IconButton";
import Login from "../../../../login/login";
import {useTranslation} from "react-multi-lang/lib";
import ProfileIcon from "@mui/icons-material/PersonOutline";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import _ from "lodash";
import Button from "@mui/material/Button/Button";
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import TeamsIcon from '@mui/icons-material/PeopleOutline';
import UsersIcon from '@mui/icons-material/RecentActorsOutlined';
import FrequencyIcon from '@mui/icons-material/TuneOutlined';
import MyBloomIcon from '@mui/icons-material/BarChartOutlined';
import MenuSettings from "./MenuSettings";
import PlaylistIcon from '@mui/icons-material/SubscriptionsOutlined';
import LibraryIcon from '@mui/icons-material/ListOutlined';
import {ReactComponent as SmileyIcon} from "../../../Core/symbols/myBloom/icons/smileyHappyWhite.svg";
import {ReactComponent as NotificationsIcon} from "../../../Core/symbols/webloom/white/alert-white.svg"
import {ReactComponent as EntitiesIcon} from "../../symbols/icons/EntitiesIcon.svg";
import {ReactComponent as GroupIcon} from "../../symbols/icons/GroupIcon.svg";

import FAQIcon from '@mui/icons-material/ContactSupport';
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import SubmitCustomIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import EbloomNotification from "../Notifications/EbloomNotification";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FreeTrialModal from "../Modal/FreeTrialModal";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import EbloomNotificationBadge from "../Notifications/EbloomNotificationBadge";
import {useNavigationTo} from "../../../../hooks/navigationHook";
import {useUserData} from "../../../../hooks/userHook";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import {CompanyTypes, Roles} from "../../../../Utils/Global";
import ProspectModal from "../Modal/ProspectModal";
import {Colors} from "../../../../Utils/Colors";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CategoryIcon from "@mui/icons-material/CategoryRounded";
import {ListItemButton} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";


const checkUrl = () => {
    const url = window.location.pathname;

    if (url === "/admin/faq") {
        return 21;
    }
    if (url === "/admin/account") {
        return 20;
    }
    if (url === "/admin/identity") {
        return Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") ? 26 : 20;
    }
    if (url === "/admin/customCategories") {
        return Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") ? 30 : 20;
    }
    if (url === "/admin/communication") {
        return 20;
    }
    const webloomUrls = [
        "/admin/webloom",
        "/admin/webloom/poll/create",
        "/admin/webloom/poll/result",
        "/admin/webloom/ideabox/create",
        "/admin/webloom/ideabox/result",
        "/admin/webloom/message/create",
        "/admin/webloom/message/result",
        "/admin/collaborative/create",
        "/webloom",
        "/webloom/dashboard",
        "/webloom/poll/answer"
    ];
    if (webloomUrls.includes(url)) {
        return 18;
    }
    if (url === "/suggestion") {
        return 17;
    }
    if (url === "/admin/questions/submit") {
        return 16;
    }
    if (url === "/admin/tuto") {
        return 15;
    }
    if (url === "/manager/dashboard") {
        return 14;
    }
    if (url === "/group/dashboard") {
        return 22;
    }
    if (url === "/group/entities/dashboard") {
        return 23;
    }
    if (url === "/admin/area") {
        return 13;
    }
    if (url === "/employee/daily") {
        return 12;
    }
    if (url === "/admin/questions/planner") {
        return 11;
    }
    if (url === "/admin/questions/playlist") {
        return 10;
    }
    if (url === "/admin/questions/library") {
        return 9;
    }
    if (url === "/employee/dashboard") {
        return 6;
    }
    if (url === "/profile") {
        return 5;
    }
    if (url === "/admin/integration" || url === "/admin/faq") {
        return 20
    }
    const groupWebloomRegex = /^\/group\/webloom\/(message|poll|ideabox|alert)\/(create|result)/;
    if (groupWebloomRegex.test(url) || url === "/group/webloom/dashboard") {
        return 24;
    }
    if(url === "/group/entities/webloom/dashboard"){
        return 25;
    }
    if(url === "/admin/filters"){
        return 31;
    }

    return 1
}

const EbloomMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorProfile, setAnchorProfile] = React.useState(null);
    const [anchorDashboard, setAnchorDashboard] = useState(null);
    const [anchorClickAndBloom, setAnchorClickAndBloom] = React.useState(null);
    const [anchorWeBloom, setAnchorWeBloom] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(checkUrl);
    const [hasNewCB, setHasNewCB] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [showProspect, setShowProspect] = React.useState(false);
    const [customCategoriesActivated, setCustomCategoriesActivated] = useState(false);

    const navigation = useNavigationTo();
    const t = useTranslation();
    const {
        bp,
        hasNewSBox,
        showSBox,
        checkForCollabSurvey,
        checkIfCollabAccess,
        nbActiveSurvey,
        nbAnswersSurvey,
        isFree,
        collabAccess,
        nbSurvey,
        nbNotifGroup
    } = props;
    const user = useUserData();
    const isProspect = user.companyType === CompanyTypes.PROSPECT
    const handleCloseModal = () => {
        isFree ? setShow(false) : setShowProspect(false)
    }

    const handleOpenModal = () => {
        isFree ? setShow(true) : setShowProspect(true)
        if (anchorEl) {
            setAnchorEl(null)
        } else if (anchorProfile) {
            setAnchorProfile(null)
        } else if (anchorClickAndBloom) {
            setAnchorClickAndBloom(null);
        }else if (anchorDashboard) {
            setAnchorDashboard(null)
        }
    }

    const fetchCustomCategories = () => {
        if(user.isSuperAdmin && user.companyGroup !== null ){
            const idGroup = user.companyGroup.id
            HttpApi.get("/setting/group/customCategories/getForCompany/"+idGroup).then(response => {
                if(response && response.data && response.data.value > 0){
                    setCustomCategoriesActivated(true)
                }else{
                    setCustomCategoriesActivated(false)
                }
            }).catch(error =>{
                notifyError(error)
            })
        }

    }

    const checkIfCB = () => {
        if(Login.hasOneOfRole(["global_super_admin", "global_admin"])){
            if (isProspect){
                setHasNewCB(false);
            }else{
                HttpApi.getV2(`/questions/getQuestionOfTheDay`)
                    .then((response) => {
                        if (response && response.data && response.data.length > 0) {
                            HttpApi.getV2("/feedbacks/getForUser/" + response.data[0].id_survey_question).then((response) => {
                                if (response && response.data === true) {
                                    setHasNewCB(false);
                                } else {
                                    setHasNewCB(true);
                                }
                            }).catch((error) => {
                                notifyError(error);
                                setHasNewCB(false)
                            })
                        } else {
                            setHasNewCB(false);
                        }
                    })
                    .catch((error) => {
                        notifyError(error);
                        setHasNewCB(false);
                    });
            }

        }
    }

    const handleClickDashboard = event => {
        setAnchorDashboard(event.currentTarget)
    }

    const handleClickWeBloom = (event) => {
      setAnchorWeBloom(event.currentTarget)
    }

    const handleClickSettings = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const handleClickProfile = event => {
        setAnchorProfile(event.currentTarget);
    };

    const handleCloseProfile = () => {
        setAnchorProfile(null);
    };

    const handleClickAndBloom = event => {
        setAnchorClickAndBloom(event.currentTarget);
    };

    const handleCloseDashboard = () => {
        setAnchorDashboard(null)
    }

    const handleCloseWeBloom = () => {
      setAnchorWeBloom(null)
    }

    const handleCloseClickAndBloom = () => {
        setAnchorClickAndBloom(null);
    };

    const handleCollab = () => {
        if (Login.hasRole("global_admin") || collabAccess) {
            navigation.to("/admin/webloom");
        } else {
            navigation.to("/webloom/dashboard");
        }
    }

    const handleListItemClick = (index, url) => event => {
        event.preventDefault();
        setSelectedIndex(index);
        navigation.to(url);
    };


    const createStyle = (arrayOfindex) => {
        let style = {color: "white", fontWeight: "normal", fontSize: 14};
        if (arrayOfindex.includes(selectedIndex)) {
            style = {color: "white", fontWeight: "bold", fontSize: 14, borderBottom: "2px solid white"}
        }
        return style;

    };

    const createIconStyle = (arrayOfIndex) => {
        let style = {color: "white", fontWeight: "normal"};
        if (arrayOfIndex.includes(selectedIndex)) {
            style = {color: "white", fontWeight: "bold", paddingBottom: 1, borderBottom: "2px solid white"}
        }
        return style;
    }

    const dividerStyle = {backgroundColor: "white", height: 25, marginTop: 20, opacity: 0.6}

    useEffect(() => {
        checkIfCB();
        fetchCustomCategories()
        if (checkForCollabSurvey) {
            checkForCollabSurvey();
        }
        if (checkIfCollabAccess) {
            checkIfCollabAccess();
        }
    }, []);
    return (
        <div>
            {
                bp.pageSize > bp.breakpoints.tabletLandscape ?
                    <div className={"flex"}>
                        {
                            (Login.hasOneOfRole(["global_super_admin", "global_admin"]) && hasNewCB) &&
                            <IconButton style={{marginLeft: 5, marginRight: 5, marginBottom: 5}} size={"small"}
                                        color="inherit" onClick={handleListItemClick(12, "/employee/daily",)}
                                        className={"icon_button"}>
                                <EbloomNotificationBadge number={1} showNumber>
                                    <span><SmileyIcon className={"w-24 h-24"}/></span>
                                </EbloomNotificationBadge>
                            </IconButton>
                        }
                        {
                            (Login.hasOneOfRole(["global_super_admin", "global_admin"]) && hasNewCB) &&
                            <Divider orientation="vertical" style={dividerStyle}/>
                        }
                        {
                            Login.hasOneOfRole(["global_manager", "global_admin","global_super_admin"]) ?
                                user.isSuperAdmin && user.companyGroup !== null ?
                                    <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                            onClick={handleClickDashboard}>
                                        <span style={createStyle([14,22,23])}>
                                            {t("manager.dashboard.dashboard")}
                                        </span>
                                    </Button>
                                    :
                                <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                        onClick={handleListItemClick(14, "/manager/dashboard")}><span
                                    style={createStyle([14,22,23])}>{t("manager.dashboard.dashboard")}</span></Button>
                                :
                                <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                        onClick={handleListItemClick(6, "/employee/dashboard")}><span
                                    style={createStyle([6])}>MyBloom</span></Button>

                        }
                        <Divider orientation="vertical" style={dividerStyle}/>
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <Button
                                className={"icon_button ebloom-text"}
                                style={{margin: 10}}
                                onClick={isProspect ? handleOpenModal : handleListItemClick(11, "/admin/questions/planner")}>
                                <span className={isProspect ? "text-disabled-grey ": ""} style={createStyle([11])}>
                                    {t("core.playlist.planner")}
                                </span>
                            </Button>
                        }
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <Divider orientation="vertical" style={dividerStyle}/>
                        }
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                    aria-controls='click_and_bloom_menu' aria-haspopup="true"
                                    onClick={isProspect ? handleOpenModal : handleClickAndBloom}>
                                <span className={isProspect ? "text-disabled-grey": ""} style={createStyle([9, 10, 16])}>{t("core.questions")}</span>
                            </Button>

                        }
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <Divider orientation="vertical" style={dividerStyle}/>
                        }
                        {
                            (Login.hasOneOfRole(["global_super_admin", "global_admin"]) || nbSurvey > 0 || collabAccess) ?
                            user.isSuperAdmin && user.companyGroup !== null ?
                                <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                        onClick={handleClickWeBloom}>
                                        <span style={createStyle([17, 18,24,25])}>
                                            {_.upperFirst("WeBloom")}
                                        </span>
                                </Button>
                                :
                            <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                    aria-controls="collab-menu" aria-haspopup="true" onClick={handleCollab}>
                                <EbloomNotificationBadge number={nbActiveSurvey + nbAnswersSurvey} showNumber>
                                    <span style={createStyle([17, 18,24,25])}>{_.upperFirst("WeBloom")}</span>
                                </EbloomNotificationBadge>
                            </Button>
                                :
                                <Button className={"icon_button ebloom-text"} style={{margin: 10}}
                                        aria-controls="collab-menu" aria-haspopup="true" onClick={handleCollab}>
                                    <EbloomNotificationBadge number={nbActiveSurvey + nbAnswersSurvey} showNumber>
                                        <span style={createStyle([17, 18,24,25])}>{_.upperFirst("WeBloom")}</span>
                                    </EbloomNotificationBadge>
                                </Button>
                        }
                        <Divider orientation="vertical" style={dividerStyle}/>
                        <IconButton className={"icon_button ebloom-text"}
                                    style={{marginLeft: 5, marginRight: 5, marginBottom: 5}} color="inherit"
                                    aria-controls='profile-menu' aria-haspopup="true" onClick={handleClickProfile}><span
                            style={createIconStyle(Login.hasRole("global_employee") ? [5, 7] : [5, 6, 7])}><AccountCircleIcon/></span></IconButton>
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <Divider orientation="vertical" style={dividerStyle}/>
                        }
                        {
                            Login.hasOneOfRole(["global_super_admin", "global_admin"]) &&
                            <IconButton className={"icon_button ebloom-text"}
                                        style={{marginLeft: 5, marginRight: 5, marginBottom: 5}} color="inherit"
                                        aria-controls='settings_menu' aria-haspopup="true"
                                        onClick={isProspect ? handleOpenModal : handleClickSettings}><span
                                style={createIconStyle([1, 2, 3, 4, 15, 19, 20,26,31])}><SettingsIcon className={ isProspect && "text-disabled-grey opacity-75"}/></span></IconButton>
                        }
                        <Divider orientation="vertical" style={dividerStyle}/>
                        {
                            (!Login.hasRole("global_backoffice")) &&
                            <IconButton style={{marginLeft: 5, marginRight: 5, marginBottom: 5}} color="inherit"
                                        className={"icon_button"}
                                        onClick={isProspect ? handleOpenModal : () => {}}
                            >
                                {
                                    isProspect ?
                                        <NotificationsIcon style={{fill: Colors.EBLOOM_DISABLED_GREY}} className={"grey-icon opacity-75 w-23 h-23"}/>
                                        :
                                        <EbloomNotification/>
                                }
                            </IconButton>
                        }

                        <Menu id="settings_menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseSettings}
                        >
                            <List>
                                <MenuItem key={0} onClick={handleListItemClick(20,"/admin/account")} selected={selectedIndex === 20}>
                                    <ListItemIcon><MenuBookOutlinedIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("admin.account.account"))}/>
                                </MenuItem>
                                <MenuItem key={1}
                                          onClick={isFree ? handleOpenModal : handleListItemClick(1, "/admin/frequency")}
                                          selected={selectedIndex === 1}>
                                    <ListItemIcon><FrequencyIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("admin.frequency"))}/>
                                    {
                                        isFree &&
                                        <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                    }
                                </MenuItem>
                                {
                                    Login.hasRole("global_admin") &&
                                    <MenuItem key={3} onClick={handleListItemClick(3, "/admin/users")}
                                              selected={selectedIndex === 3}>
                                        <ListItemIcon><UsersIcon/></ListItemIcon>
                                        <ListItemText primary={_.capitalize(t("core.user.users"))}/>
                                    </MenuItem>
                                }

                                {
                                    ((Login.hasModule("very_happy") || Login.hasModule("super_happy") || Login.hasModule("free")) && Login.hasRole("global_admin") ) &&
                                    <MenuItem key={2} onClick={handleListItemClick(2, "/admin/teams")}
                                              selected={selectedIndex === 2}>
                                        <ListItemIcon><TeamsIcon/></ListItemIcon>
                                        <ListItemText primary={_.capitalize(t("admin.teams"))}/>
                                    </MenuItem>
                                }
                                {
                                    ((Login.hasModule("very_happy") || Login.hasModule("super_happy") || Login.hasModule("free")) && Login.hasRole("global_admin") ) &&
                                    <MenuItem key={31} onClick={ isFree ? handleOpenModal :handleListItemClick(31, "/admin/filters")} selected={selectedIndex === 31}  >
                                        <ListItemIcon><FilterListOutlinedIcon/></ListItemIcon>
                                        <ListItemText primary={_.capitalize(t("core.filters"))}/>
                                        {
                                            isFree &&
                                            <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                        }
                                    </MenuItem>
                                }
                                {
                                    Login.hasRole("global_admin") &&
                                    <MenuItem key={5} onClick={handleListItemClick(19, "/admin/communication")}
                                              selected={selectedIndex === 19}>
                                        <ListItemIcon><BuildOutlinedIcon/></ListItemIcon>
                                        <ListItemText primary={_.capitalize(t("core.general"))}/>
                                    </MenuItem>
                                }
                                {
                                    Login.hasRole("global_admin") &&
                                    <MenuItem key={21} onClick={handleListItemClick(21, "/admin/faq")}
                                              selected={selectedIndex === 21}>
                                        <ListItemIcon><FAQIcon/></ListItemIcon>
                                        <ListItemText primary={_.upperCase(t("admin.faq.title"))}/>
                                    </MenuItem>
                                }
                                {
                                    Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") &&
                                    <MenuItem key={26} onClick={handleListItemClick(26, "/admin/identity")}
                                              selected={selectedIndex === 26}>
                                        <ListItemIcon><CreateOutlinedIcon/></ListItemIcon>
                                        <ListItemText primary={_.upperFirst(t("admin.identity.titleSm"))}/>
                                    </MenuItem>
                                }
                                {
                                    Login.hasRole("global_super_admin") && !Login.hasRole("global_admin") && customCategoriesActivated &&
                                    <MenuItem key={-3} onClick={handleListItemClick(30,"/admin/customCategories")}
                                              selected={selectedIndex === 30}>
                                        <ListItemIcon><CategoryIcon/></ListItemIcon>
                                        <ListItemText primary={_.upperFirst(t("admin.customCategories.title"))} />
                                    </MenuItem>
                                }
                            </List>
                        </Menu>
                        <Menu id={"profile_menu"} anchorEl={anchorProfile} keepMounted open={Boolean(anchorProfile)}
                              onClose={handleCloseProfile}>
                            <List>
                                <MenuItem key={5} onClick={handleListItemClick(5, "/profile")}
                                          selected={selectedIndex === 5}>
                                    <ListItemIcon><ProfileIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.profile.profile"))}/>
                                </MenuItem>
                                <MenuItem key={6} onClick={handleListItemClick(6, "/employee/dashboard")}
                                          selected={selectedIndex === 6}>
                                    <ListItemIcon><MyBloomIcon/></ListItemIcon>
                                    <ListItemText primary={"MyBloom"}/>
                                </MenuItem>
                                <MenuItem key={8} onClick={Login.logout}>
                                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.login.logout"))}/>
                                </MenuItem>
                            </List>
                        </Menu>
                        <Menu id={"click_and_bloom_menu"} anchorEl={anchorClickAndBloom} keepMounted
                              open={Boolean(anchorClickAndBloom)} onClose={handleCloseClickAndBloom}>
                            <List>
                                <MenuItem key={9}
                                          onClick={isFree ? handleOpenModal : handleListItemClick(9, "/admin/questions/library")}
                                          selected={selectedIndex === 9}>
                                    <ListItemIcon><LibraryIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.playlist.library"))}/>
                                    {
                                        isFree &&
                                        <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                    }
                                </MenuItem>
                                <MenuItem key={10} onClick={handleListItemClick(10, "/admin/questions/playlist")}
                                          selected={selectedIndex === 10}>
                                    <ListItemIcon><PlaylistIcon/></ListItemIcon>
                                    <ListItemText primary={_.capitalize(t("core.playlist.playlists"))}/>
                                </MenuItem>
                                <MenuItem key={16}
                                          onClick={isFree ? handleOpenModal : handleListItemClick(16, "/admin/questions/submit")}
                                          selected={selectedIndex === 16}>
                                    <ListItemIcon><SubmitCustomIcon/></ListItemIcon>
                                    <ListItemText primary={_.upperFirst(t("admin.submitCustomTitle"))}/>
                                    {
                                        isFree &&
                                        <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                                    }
                                </MenuItem>
                            </List>
                        </Menu>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <Menu
                                id={"webloom_menu"}
                                keepMounted
                                anchorEl={anchorWeBloom}
                                open={Boolean(anchorWeBloom)}
                                onClose={handleCloseWeBloom}
                            >
                                <List>
                                    <MenuItem key={24} onClick={handleListItemClick(24,"/group/webloom/dashboard")} selected={selectedIndex === 24}>
                                        <ListItemIcon><GroupIcon/></ListItemIcon>
                                        <EbloomNotificationBadge number={nbNotifGroup} showNumber>
                                            <ListItemText>{user.companyGroup.name}</ListItemText>
                                        </EbloomNotificationBadge>

                                    </MenuItem>
                                    <MenuItem key={25} onClick={handleListItemClick(25,"/group/entities/webloom/dashboard")} selected={selectedIndex === 25}>
                                        <ListItemIcon><EntitiesIcon/></ListItemIcon>
                                        <ListItemText>{ _.upperFirst(t("core.allEntities"))}</ListItemText>
                                    </MenuItem>
                                    {
                                        Login.hasRole("global_admin") &&
                                        <Divider orientation={"horizontal"}/>
                                    }
                                    {
                                        Login.hasRole("global_admin") &&
                                        <MenuItem key={18} onClick={handleListItemClick(18,"/admin/webloom")} selected={selectedIndex === 18} >
                                            <EbloomNotificationBadge number={nbActiveSurvey + nbAnswersSurvey} showNumber>
                                                <ListItemText>
                                                    {_.upperFirst(user.company)}
                                                </ListItemText>
                                            </EbloomNotificationBadge>
                                        </MenuItem>
                                    }

                                </List>
                            </Menu>
                        }
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <Menu
                                id="dashboard_menu"
                                anchorEl={anchorDashboard}
                                keepMounted
                                open={Boolean(anchorDashboard)}
                                onClose={handleCloseDashboard}>
                                <List>
                                    <MenuItem key={22} selected={selectedIndex === 22}
                                              onClick={handleListItemClick(22, "/group/dashboard")}>
                                        <ListItemIcon><GroupIcon/></ListItemIcon>
                                        <ListItemText>{user.companyGroup.name}</ListItemText>
                                    </MenuItem>
                                    <MenuItem key={23} selected={selectedIndex === 23}
                                              onClick={handleListItemClick(23, "/group/entities/dashboard")}>
                                        <ListItemIcon><EntitiesIcon/></ListItemIcon>
                                        <ListItemText>{ _.upperFirst(t("core.allEntities"))}</ListItemText>

                                    </MenuItem>
                                    {
                                        Login.hasOneOfRole(["global_manager", "global_admin"]) &&
                                        <Divider/>
                                    }
                                    {
                                        Login.hasOneOfRole(["global_manager", "global_admin"]) &&
                                        <MenuItem key={14} selected={selectedIndex === 14}
                                                  onClick={handleListItemClick(14, "/manager/dashboard")}>
                                            {_.upperFirst(user.company)}
                                        </MenuItem>
                                    }
                                </List>
                            </Menu>
                        }
                    </div>
                    :
                    <MenuSettings {...props} hasNewCB={hasNewCB} hasNewSBox={hasNewSBox} showSBox={showSBox}/>
            }
            <ProspectModal show={showProspect} handleClose={handleCloseModal} />
            <FreeTrialModal show={show} handleClose={handleCloseModal}/>
        </div>
    );
};

EbloomMenu.defaultProps = {
    nbActiveSurvey: 0
}

export default EbloomMenu;