import React from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import CommunicationV2 from "./CommunicationV2";

const CommunicationPage = (props) => {

    return(
        <DisplayAdminView pageName={"admin.communication"} selectedIndex={12} menu={"settings"} {...props}>
            {
                () => (
                    <Container>
                        <CommunicationV2 {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )
}

export default CommunicationPage;