import React, {useState} from 'react';
import DisplayManagerView from "../../../Manager/Layouts/DisplayManagerView";
import MyBloom from "./MyBloom";
import {Container} from "react-bootstrap";
import {Colors} from "../../../../Utils/Colors";

const EmployeeDashboardPage = (props) => {

    const [bgColor, setBgColor] = useState(Colors.EBLOOM_WHITE_BLUE);

    return (
        <DisplayManagerView {...props} pageName={"MyBloom"} selectedIndex={6} showDatePicker bgColor={bgColor}>
            {
                (teamToDisplay, startDate, endDate, timeFilterVersion) => (
                    <Container fluid>
                        <MyBloom {...props} teamToDisplay={teamToDisplay} startDate={startDate} endDate={endDate}
                                 timeFilterVersion={timeFilterVersion} handleBgColor={setBgColor}/>
                    </Container>
                )
            }

        </DisplayManagerView>
    )

}

export default EmployeeDashboardPage;