import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";
import {PollTypes} from "../../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import _ from "lodash";
import {FullContrastPurpleTextField, replaceUrlsWithLinks} from "../../../../../../../Utils/Global";
import {useTranslation} from "react-multi-lang/lib";
import {getTranslations} from "../../../../../../../lang/translations";

const Choice = (props) => {
    const [optionsState, setOptionsState] = useState([]);

    const {question, handleChange, lang} = props;

    const t = useTranslation();

    //handlers
    const handleCheck = (event, id) => {
        if(handleChange) { //when preview mode, handle change is not defined because answers must not be saved
           let q = _.cloneDeep(question);
            let options = q.options;
            const name = event.target.name;
            if(question.type === PollTypes.MULTIPLE_CHOICES){
                let option = options.find(el => el.id === id);
                option.selected = !option.selected || option.selected === 0 || name === "define_other" ? 1 : 0;
            }else{
                options.forEach(option => {
                    if(option.id !== id){
                        option.selected = 0;
                    }else{
                        option.selected = !option.selected || option.selected === 0 || name === "define_other" ? 1 : 0;
                    }
                })
            }
            handleChange(q);
        }else{
            const name = event.target.name;
            setOptionsState(prevState => {
                let options = [...prevState];
                if(question.type === PollTypes.MULTIPLE_CHOICES) {
                    let option = options[id];
                    if(option){
                        option.selected = !option.selected || option.selected === 0 || name === "define_other" ? 1 : 0;
                    }else{
                        if(name === "radio-check-"+ question.id){
                            let currentCheckedStatus = document.getElementById("radio-check-"+ question.id).checked !== true;
                            document.getElementById("radio-check-"+ question.id).checked = !currentCheckedStatus;
                        }else{
                            document.getElementById("radio-check-"+ question.id).checked = true;
                        }
                    }
                }else{
                    options.forEach(option => {
                        if(option !== options[id]){
                            option.selected = 0;
                        }else{
                            option.selected = !option.selected || option.selected === 0 || name === "define_other" ? 1 : 0;
                        }
                    })
                    if(id === options.length){
                        document.getElementById("radio-check-"+ question.id).checked = true;
                    }else if(question.show_other){
                        document.getElementById("radio-check-"+ question.id).checked = false;
                    }
                }
                return options;
            });
        }

    }

    const handleChangeOther = (event) => {
        if(handleChange){ //when preview mode, handle change is not defined because answers must not be saved
            let q = _.cloneDeep(question);
            let option = q.options.find(el => el.id === 0);
            option.text = event.target.value;
            handleChange(q);
        }
    }

    const hasMaxNbItems = question.type === PollTypes.MULTIPLE_CHOICES && optionsState.filter(el => el.selected && el.selected === 1).length === parseInt(question.nb_items);


        useEffect(() => {
        setOptionsState(_.cloneDeep(question.options));
    }, [question]);


    return(
        <div>
            {
                optionsState && optionsState.map((option, index) => (
                    <div className={"flex items-center"} key={index}>
                        <Form.Check disabled={hasMaxNbItems && !option.selected} onChange={(event) => {handleChange ? handleCheck(event, option.id) : handleCheck(event, index)}} name={"radio-check-" + question.id} type={question.type === PollTypes.MULTIPLE_CHOICES ? "checkbox" : "radio"} className={"mt10 mb10 pointer"} key={index} checked={option.selected} value={option.selected}/>
                        {
                            option.other && option.define ?
                                <FullContrastPurpleTextField disabled={hasMaxNbItems && !option.selected} size={"small"} variant={"outlined"} placeholder={_.upperFirst(getTranslations(lang, "webloom.other")) + "..."} className={"ml-5"} onChange={handleChangeOther} value={option.text} name={"define_other"} onClick={(event) => {handleCheck(event, option.id)}}/>
                                :
                                <span className={"ml-10 " + (hasMaxNbItems && !option.selected ? " text-disabled-grey" : "")}>{option.text ? option.text === "Other" ? _.upperFirst(getTranslations(lang,"webloom.other")) : replaceUrlsWithLinks(option.text) : (_.upperFirst(t("webloom.option")) + (index+1))}</span>
                        }

                    </div>
                ))
            }
            {
                (!handleChange && (true === Boolean(question.show_other))) &&
                <div className={"flex items-center"}>
                    <Form.Check id={"radio-check-" + question.id} name={"radio-check-" + question.id} type={question.type === PollTypes.MULTIPLE_CHOICES ? "checkbox" : "radio"} onChange={(event) => {handleCheck(event, optionsState.length)}} className={"mt10 mb10 pointer"}/>
                    {
                        (true === Boolean(question.define_other)) ?
                            <FullContrastPurpleTextField size={"small"} variant={"outlined"} placeholder={_.upperFirst(getTranslations(lang, "webloom.other")) + "..."} className={"ml-5"} name={"define_other"} onClick={(event) => {handleCheck(event, optionsState.length)}}/>
                            :
                            <span className={"ml-10 "}>{question.type === PollTypes.YES_NO ? _.upperFirst(getTranslations(lang,"webloom.NA")) : _.upperFirst(getTranslations(lang, "webloom.other"))}</span>

                    }
                </div>
            }
        </div>
    )
}

Choice.propTypes = {
    question:PropTypes.object.isRequired,
    handleChange:PropTypes.func
}

export default Choice;