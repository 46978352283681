import React from "react";
import {Navigate} from "react-router-dom";
import Login from "../login/login";

const PrivateRoute = ({component: Component, roles, modules, ...rest}) => {

        //Show the component only if the user is logged in
    return Login.isLogin() ?
            Login.hasOneOfModules(modules) ?
                Login.hasOneOfRole(roles) ?
                    <Component {...rest} />
                :
                    <Navigate to="/"/>
                :
                <Navigate to="/"/>
                :
            <Navigate to="/login"/>
};

export default PrivateRoute;