import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Button} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    createErrorNotification,
    createSuccessfullyDeletedNotification, notifyError
} from "../../../../Utils/Notification";
import HttpApi from "../../../../httpApi";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {Colors} from "../../../../Utils/Colors";
import IconButton from "@mui/material/IconButton/IconButton";


const DeleteModal = (props) => {
    const [show, setShow] = useState(false);
    const [textConfirmation, setTextConfirmation] = useState(null);

   const t = useTranslation();

   const {noRequest, id, url, handleDelete, method,getTextConfirmation,color} = props;

    const handleClose = () => {
        setShow(false);
        setTextConfirmation(null);
    }

    const handleOpen = () => {
       setShow(true);
       const text = getTextConfirmation(id)
       setTextConfirmation(text)
    };

    const handleChange = () => {
        if(noRequest){
            handleDelete();
            handleClose();
        }else{
            if(!id || !url){
                createErrorNotification("Error !", "Invalid params");
                handleClose();
            }else{
              const HttpApiCall = method === 'DELETE' ? HttpApi.delete : method === 'PUT' ? HttpApi.put : HttpApi.postV2;
                HttpApiCall(url, {id:id}).then(() => {
                    handleClose();
                    createSuccessfullyDeletedNotification("item");
                    handleDelete();
                }).catch(error => {
                    handleClose();
                    notifyError(error);
                })
            }
        }
    };

    return(
        <>
         <OverlayTrigger overlay={
            <Tooltip>
                {_.capitalize(t("core.delete"))}
            </Tooltip>
        }>

             <IconButton className={color} size="sm" onClick={handleOpen}><DeleteIcon/></IconButton>
        </OverlayTrigger>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_CONTRAST_BLUE, color:"white"}}>
                        <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("core.notification.warning")) + " - " + _.capitalize(t("core.confirmDelete"))}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"ebloom-normal-text"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                        <div className={"whitespace-pre-wrap"}>
                            {textConfirmation}
                        </div>
                        {_.capitalize(t("core.questionDelete"))}
                    </Modal.Body>
                    <Modal.Footer className={"ebloom-normal-text"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                        <Button variant="secondary" onClick={handleClose}>{_.capitalize(t("core.cancel"))}</Button>
                        <Button className={"ebloom-btn-purple"} onClick={handleChange}>{_.capitalize(t("core.confirm"))}</Button>
                    </Modal.Footer>
                </Modal>
            </>
    )

}

DeleteModal.propTypes = {
  noRequest:PropTypes.bool,
  id:PropTypes.number,
  url:PropTypes.string,
  handleDelete:PropTypes.func,
  method:PropTypes.string,
  color:PropTypes.string,
  getTextConfirmation:PropTypes.func
}

DeleteModal.defaultProps = {
  noRequest:false,
  method: 'POST',
  color:"text-purple",
  getTextConfirmation : () => {return null}
}

export default DeleteModal;