import React from "react";
import PropTypes from "prop-types"
import {getLanguages} from "../../../../lang/translations";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {FullContrastBlueTextField, FullContrastPurpleTextField} from "../../../../Utils/Global";

const EbloomSelectLang = (props) => {

    //state

    //props
    const {name, value, handleChange, color, size, fullWidth} = props;

    const ColorTextField = color === "contrast-purple" ? FullContrastPurpleTextField : FullContrastBlueTextField;

    //hooks

    //getters

    //posters

    //handlers

    //useEffect

    return (
        <ColorTextField size={size} value={value} variant={"outlined"} onChange={handleChange} name={name} fullWidth={fullWidth} select>
            {
                getLanguages().map((lang, index) => (
                    <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                ))
            }
        </ColorTextField>


    )
}

EbloomSelectLang.propTypes = {
    name:PropTypes.string,
    value:PropTypes.string.isRequired,
    handleChange:PropTypes.func.isRequired,
    color:PropTypes.string,
    size:PropTypes.string,
    fullWidth:PropTypes.bool
}

EbloomSelectLang.defaultProps = {
    name:"lang",
    color:"contrast-purple",
    size:"small",
    fullWidth:false
}
export default EbloomSelectLang