import React from "react";
import PropTypes from "prop-types";
import {getColorFromPercentage} from "../../../Utils/Global";
import {ReactComponent as WLIcon} from "../../Core/symbols/categories/work_lifestyle_bubble_icon.svg";
import {ReactComponent as TIIcon} from "../../Core/symbols/categories/team_interaction_bubble_icon.svg";
import {ReactComponent as MLIcon} from "../../Core/symbols/categories/management_leadership_bubble_icon.svg";
import {ReactComponent as PSIcon} from "../../Core/symbols/categories/policy_support_bubble_icon.svg";
import {ReactComponent as ACIcon} from "../../Core/symbols/categories/adhesion_culture_bubble_icon.svg";
import {ReactComponent as ArrowGreen} from "../../Core/symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../Core/symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../Core/symbols/general/ArrowNeutral.svg";


import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import EbloomTooltip from "../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import moment from "moment";
import {selectBubbleIcon, selectIcon} from "../../Admin/Pages/Categories/Assets/CategoriesAssets";
import {Colors} from "../../../Utils/Colors";

const ScoreType = (props) => {

    const {category, showDifference, difference,isModelCustomEnable,colors} = props
    const t = useTranslation()
    const categoriesIcon = {
        5: <WLIcon/>,
        6: <TIIcon/>,
        7: <MLIcon/>,
        8: <PSIcon/>,
        9: <ACIcon/>,

    }

    const displayDifference = (difference,score) =>{
        if(difference !== null && difference.difference !==null){
            const diff = difference.difference
            if(diff === score ){
                return null
            }else if(Math.round(diff) > 0){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                    <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                        <div className={"scale-75"}>
                            <ArrowGreen className={""}/>
                        </div>
                        <p className={"text-strong-green text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>

            }else if (Math.round(diff) < 0 ){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                    <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                        <div className={"scale-75"}>
                            <ArrowRed/>
                        </div>
                        <p className={"text-strong-red text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>

            }else if (Math.round(diff) === 0){
                return <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                    <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center ml-10"}>
                        <div className={"scale-75"}>
                            <ArrowNeutral/>
                        </div>
                        <p className={"text-grey text-base"}>
                            {Math.abs(Math.round(diff))}
                            <span className={"font-normal"}>%</span>
                        </p>
                    </div>
                </EbloomTooltip>
            }
            return null
        }
        return null
    }


    return <div className={"grid grid-cols-12 mr-10 gap-y-15"}>
        <div className={"col-start-1 col-end-3"}>
            <div className={"flex justify-center items-center relative"}>
                <div>
                    {selectBubbleIcon(category.icon,category.color)}
                </div>

            </div>
        </div>
        <div className={"col-start-3 col-end-10 "}>
            <div className={" flex flex-col justify-start items-start"}>
                <h1 style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={"text-left font-sans "}>{isModelCustomEnable ? category.name : _.upperFirst(t("core.category."+category.name))}</h1>
                <div className={"w-3/4 bg-[#D9D9D9] h-10 rounded-full relative"}>
                    <div className={" h-full rounded-full float-left "}
                         style={{
                             width: category.score ? (category.score + "%") : 0,
                             backgroundColor: getColorFromPercentage(category.score)
                         }}>
                    </div>
                </div>
            </div>
        </div>
        <div className={"col-start-10 col-end-12 flex justify-start items-center"}>
            <h1 style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={" font-semibold text-center text-2xl "}>{category.total ? category.score !== null && category.score >= 0 ? category.score + "%" : _.toUpper(t("manager.dashboard.anonymityRestrictionSmall")) : _.toUpper(t("webloom.NA"))}</h1>
            {
                showDifference &&
                <div>
                    {displayDifference(difference,category.score)}
                </div>

            }
        </div>
    </div>
}

ScoreType.propTypes={
    category : PropTypes.object.isRequired,
    showArrow : PropTypes.bool,
    showDifference : PropTypes.bool,
    isModelCustomEnable : PropTypes.bool,
    difference : PropTypes.object,
    colors : PropTypes.object
}

ScoreType.defaultProps = {
    showArrow: true,
    showDifference : false,
    isModelCustomEnable : false,
    difference : null,
    colors : null
}

export default ScoreType
