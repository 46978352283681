export const PollTypes = {
    TITLE:"title",
    UNIQUE_CHOICE:"uniqueChoice",
    MULTIPLE_CHOICES:"multipleChoices",
    YES_NO:"yesNo",
    STAR_RATING:"starRating",
    OPEN_QUESTION:"openQuestion",
    NAME:"name",
    NUMBER:"number",
    IDENTIFICATION:"identification",
}

export const SurveyTypes = {
    POLL:"poll",
    IDEABOX:"ideabox",
    MESSAGE:"message",
    ALERT:"alert",
}

export const SurveyStatus = {
    RUNNING:1,
    DRAFT:-1,
    SCHEDULED:-2,
    DONE:2,
    ARCHIVED:0
}

export const SurveyUsersStatus = {
    UNREAD:0,
    READ:1,
    ARCHIVED:-1,
    ANSWERED : 2
}