import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Frequency from "../../../../Admin/Pages/Frequency/Frequency";
import FrequenciesList from "../../../../Admin/Pages/Frequency/FrequenciesList";



const BackofficeFrequencyPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <FrequenciesList companyToEdit={companyToEdit} {...props}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default BackofficeFrequencyPage


