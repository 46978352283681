import ScoreType from "./ScoreType";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import PropTypes from "prop-types";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";


const MyScores = (props) => {
    const {loadingCategories, categoriesScores, setDetails, setShowDetails,isModelCustomEnable} = props

    const t = useTranslation()
    const handleDetails = (category) => {
        setDetails(category)
        setShowDetails(true)
    }

    return <div className={"w-full h-300 flex flex-col items-stretch overflow-y-auto"}>
        {
            loadingCategories ?
                <LoadingView/>
                :
                categoriesScores.sort((a,b) => a.category.position - b.category.position).map((value, index) =>
                    <div key={index}>
                        <div onClick={()=>handleDetails(value)}  className={"mb-10 cursor-pointer h-50"}>
                            <ScoreType isModelCustomEnable={isModelCustomEnable} setDetails={setDetails} setShowDetails={setShowDetails}
                                       category={value} showArrow={true}/>
                        </div>
                    </div>
                )
        }
    </div>
}
MyScores.propTypes = {
    loadingCategories : PropTypes.bool.isRequired,
    isModelCustomEnable : PropTypes.bool,
    categoriesScores : PropTypes.array.isRequired,
    setDetails : PropTypes.func.isRequired,
    setShowDetails : PropTypes.func.isRequired
}

MyScores.defaultProps = {
    isModelCustomEnable : false
}
export default MyScores