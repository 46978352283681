import React, {useEffect, useState} from "react";
import {Container, Row, Col, Card, ListGroup, ListGroupItem, Badge, Accordion} from "react-bootstrap";
import HttpApi, {Sources} from "../../../../../httpApi";
import Login from "../../../../../login/login"
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import moment from "moment";
import _ from "lodash";
import {Colors} from "../../../../../Utils/Colors";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import History from "../../../../Manager/Modules/History";
import {useTranslation} from "react-multi-lang/lib";
import DashboardCard from "../../../../Core/Modules/Cards/DashboardCard";
import * as fileSaver from "file-saver";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import UserModel from "../../../../../models/User";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {getLanguage} from "react-multi-lang";
import ExportIcon from '@mui/icons-material/Launch';
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import EbloomSmileyPercentageV2 from "../../../../Core/Modules/Chart/EbloomSmileyPercentageV2";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EbloomAllSmileyProgressBar from "../../../../Core/Modules/Chart/EbloomAllSmileyProgressBar";
import CategoryTag from "../../../../Core/Modules/CategoryDisplay/CategoryTag";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {getLanguages} from "../../../../../lang/translations";
import {useUserData} from "../../../../../hooks/userHook";
import EbloomSpeedometerV2 from "../../../../Core/Modules/Chart/EbloomSpeedometerV2";

const GlobalDashboard = (props) => {
    const [loadingParticipation, setLoadingParticipation] = useState(true);
    const [loadingNbSubs, setLoadingNbSubs] = useState(true);
    const [loadingAvg, setLoadingAvg] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingFromStart, setLoadingFromStart] = useState(true);
    const [loadingExportData, setLoadingExportData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dataExportLang, setDataExportLang] = useState(getLanguage());
    const [categoriesScores, setCategoriesScores] = useState([]);
    const [activeKeyCategory, setActiveKeyCategory] = useState(0);
    const [statFromStart, setStatFromStart] = useState({tableOfResults:[], nbResponse:0});
    const [nbSubs, setNbSubs] = useState(0);
    const [participationRate, setParticipationRate] = useState("no value");
    const [statOfAvg, setStatAvg] = useState({total:0, avg:0, limit:true});

    const langs = getLanguages();
    const t = useTranslation();


    const anonymousRestriction = _.upperFirst(t("manager.dashboard.anonymousRestriction"));
    const noData = _.upperFirst(t("core.noData"));

    const {isMobile, companyToEdit, teamToDisplay, startDate, endDate, timeFilterVersion} = props;

    const user = useUserData();

    const companyStateName = user.company;

    const handleCloseModal = (event) => {
        setShowModal(false);
    }

    const handleOpenModal = (event) => {
        setShowModal(true);
    }

    const handleChangeLangDataExport = (event) => {
        setDataExportLang(event.target.value);
    }


    const handleActiveKeyCategory = (event) => {
        if(activeKeyCategory === 0){
            setActiveKeyCategory(1);
        }else{
            setActiveKeyCategory(0);
        }
    }

    const fetchScoresCategories = () => {
        setLoadingCategories(true);
        const url = "/feedbacks/global/getCategoriesScores/" + startDate + "/" + endDate;
        HttpApi.getV2(url, Sources.CATEGORIES).then(response => {
            if(response && response.data){
                setCategoriesScores(response.data);
            }else{
                setCategoriesScores([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingCategories(false));
    }

    const fetchGlobalStat = () => {
        setLoadingAvg(true);
        const url =  "/feedbacks/global/getGlobalStats/" + startDate + "/" + endDate;
        HttpApi.getV2(url, Sources.GLOBAL_STAT).then(response => {
            if(response && response.data){
                setStatAvg({total:response.data.nbFeedback, avg:response.data.avgFeedback, limit:response.data.nbFeedback < 5})
            }else{
                setStatAvg({total:0, avg:0, limit:true});
            }
        }).catch(error => {
            notifyError(error)
        }).finally(() => setLoadingAvg(false));
    }

    const fetchScoreFromStart = () => {
        setLoadingFromStart(true);
        let url = "/feedbacks/global/getSmileyScores/" + startDate + "/" + endDate;
        HttpApi.getV2(url, Sources.SCORE_FROM_START).then(response => {
            if(response && response.data){
                setStatFromStart(response.data);
            }else{
                setStatFromStart({tableOfResults:[], nbResponse:0});
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingFromStart(false);
        })
    };

    const fetchNbSubs = () => {
        let url = "/users/global/getCountEmployees/" + endDate;
        setLoadingNbSubs(true);
        HttpApi.getV2(url, Sources.NB_SUBS).then(response => {
            if(response && response.data){
                setNbSubs(response.data.nbEmployees);
            }else{
                setNbSubs(0);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingNbSubs(false));
    };

    const fetchParticipationRate = () => {
        let url = "/feedbacks/global/getParticipationRate/" + startDate + "/" + endDate;
        setLoadingParticipation(true);
        HttpApi.getV2(url, Sources.PARTICIPATION_RATE).then(response => {
            if(response && response.data){
                setParticipationRate(response.data);
            }else{
                setParticipationRate(t("core.noData"));
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingParticipation(false));
    };
    const exportData = () => {
        setLoadingExportData(true);
        HttpApi.getWithResponseType("/dashboard/export/" + startDate + "/" + endDate + "/" + dataExportLang + "/" + (teamToDisplay ? teamToDisplay.id : 0) + "/" + (teamToDisplay ? teamToDisplay.name : null)).then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'ebloom_' + startDate + '_' + endDate + '.xlsx');
            notifySuccess("Data exported to excel file");
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingExportData(false));
    }

    useEffect(() => {
        if(timeFilterVersion !== null){
            fetchScoreFromStart();
            fetchNbSubs();
            fetchParticipationRate();
            fetchGlobalStat();
            fetchScoresCategories();
        }
    }, [companyToEdit, teamToDisplay, timeFilterVersion])


    return (
        <div>
            <Row style={{marginBottom:20}}>
                <Col md={8} sm={12} style={{marginBottom:20}}>
                    <Container fluid style={{paddingRight:0, paddingLeft:0}}>
                        <Card style={{backgroundColor:Colors.EBLOOM_BG_BLUE, borderColor:Colors.EBLOOM_CONTRAST_PURPLE, height:isMobile ? "auto": 284}}>
                            <Row>
                                <Col md={isMobile ? 12 : 5}>
                                    <Card style={{border:"none", borderRight:isMobile ? "none" : "1px solid " + Colors.EBLOOM_CONTRAST_PURPLE, borderRadius:0, backgroundColor:"transparent", maxHeight:283}}>
                                        <Card.Body style={{paddingBottom:0}}>
                                            <div style={{float:"right"}}>
                                                <EbloomTooltip text={_.upperFirst(t("manager.dashboard.bloomHelper"))}>
                                                    <div className={"info_icon very_small_smile"}/>
                                                </EbloomTooltip>
                                            </div>
                                            <Card.Title className={"ebloom-normal-title"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, textAlign:"center"}}>{_.upperFirst(t("manager.dashboard.bloomIndex"))}</Card.Title>
                                            {
                                                loadingAvg ?
                                                    <LoadingView />
                                                    :
                                                    !statOfAvg.total || statOfAvg.total <= 0 ?
                                                        <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{noData}</p>
                                                        :
                                                        statOfAvg.limit ?
                                                            <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                            :
                                                            <Row style={{marginTop:25}}>
                                                                <div style={{margin:"auto"}}>
                                                                    <EbloomSpeedometerV2 score={statOfAvg.avg}/>
                                                                </div>
                                                            </Row>
                                            }

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={isMobile ? 12 : 7}>
                                    <Card style={{backgroundColor:"transparent", border:"none"}}>
                                        <Card.Body>
                                            <div style={{float:"right"}}>
                                                <EbloomTooltip text={_.upperFirst(t("manager.dashboard.smileyHelper"))}>
                                                    <div className={"info_icon very_small_smile"}/>
                                                </EbloomTooltip>
                                            </div>
                                            <Card.Title className={"ebloom-normal-title"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, textAlign:"center"}}>{"Smiley Score"}</Card.Title>
                                            {
                                                loadingAvg || loadingFromStart ?
                                                    <LoadingView/>
                                                    :
                                                    !statOfAvg.total || statOfAvg.total <= 0 ?
                                                        <p style={{color:"black", textAlign:"center", fontSize:"1.5em", marginTop:20}}>{noData}</p>
                                                        :
                                                        statOfAvg.limit ?
                                                            <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                            :
                                                            <Row style={{marginTop:35, marginLeft:10}}>
                                                                <div style={{width:"100%", margin:"auto"}}>
                                                                    {
                                                                        loadingFromStart ?
                                                                            <LoadingView />
                                                                            :
                                                                            <EbloomSmileyPercentageV2 value={statFromStart} isMobile={isMobile}/>
                                                                    }
                                                                </div>
                                                            </Row>
                                            }

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Col>
                <Col md={4} sm={12}>
                    <DashboardCard title={_.upperFirst(t("manager.dashboard.participation"))} showInfo={false} maxHeight={isMobile ? "auto" : 284} minHeight={isMobile ? "auto" : 284}>
                        {
                            (loadingParticipation || loadingNbSubs || loadingAvg) ?
                                <LoadingView/>
                                :
                                <div>
                                    <ListGroup variant="flush">
                                        <ListGroupItem style={{padding:15, paddingLeft:15, paddingRight:15, border:"none"}} key={1}>
                                            <Row>
                                                <Col md={2} sm={2} xs={2}>
                                                    <div className={"hands_icon very_small_smile"} style={{marginBottom:-27}}/>
                                                </Col>
                                                <Col>
                                                    <span className={"ebloom-normal-text"}>{_.upperFirst(t("manager.dashboard.participationRate")) + " (%)"}</span>
                                                </Col>
                                                <Col md={1} sm={1} xs={2}>
                                                    <Badge className={"ebloom-normal-text"} style={{color:"white", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, float:"right"}}>{participationRate}</Badge>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem style={{padding:15, paddingLeft:15, paddingRight:15, border:"none"}} key={2}>
                                            <Row>
                                                <Col md={2} xs={2}>
                                                    <div className={"users_group_icon very_small_smile"} style={{marginBottom:-27}}/>
                                                </Col>
                                                <Col>
                                                    <span className={"ebloom-normal-text"}>{_.upperFirst(t("manager.dashboard.numberSubscription"))}</span>
                                                </Col>
                                                <Col md={1} xs={2}>
                                                    <Badge className={"ebloom-normal-text"} style={{color:"white", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, float:"right"}}>{nbSubs}</Badge>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem style={{padding:15, paddingLeft:15, paddingRight:15, border:"none"}} key={3}>
                                            <Row>
                                                <Col md={2} sm={2} xs={2}>
                                                    <div className={"nbCB_icon very_small_smile"} style={{marginBottom:-27}}/>
                                                </Col>
                                                <Col md={8} sm={8} xs={8}>
                                                    <span className={"ebloom-normal-text"} style={{width:195}}>{_.upperFirst(t("manager.dashboard.nbAnswers"))}</span>
                                                </Col>
                                                <Col md={2} sm={2} xs={2}>
                                                    <Badge className={"ebloom-normal-text"} style={{color:"white", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, float:"right"}}>{statOfAvg.total}</Badge>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>
                                </div>
                        }
                    </DashboardCard>
                </Col>
            </Row>
            <Row style={{marginBottom:20}}>
                <Col md={12} sm={12}>
                    <DashboardCard paddingLeft={15} paddingRight={15} title={_.upperFirst(t("core.category.categories"))} info={_.upperFirst(t("manager.dashboard.categoryHelper"))}>
                        {
                            (loadingAvg || loadingCategories) ?
                                <LoadingView/>
                                :
                                !statOfAvg.total || statOfAvg.total <= 0 ?
                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{noData}</p>
                                    :
                                    statOfAvg.limit ?
                                        <p>{anonymousRestriction}</p>
                                        :

                                        <Accordion activeKey={activeKeyCategory} style={{marginTop:25}}>
                                            <Row md={5} xs={1}>
                                                {
                                                    categoriesScores.map((category, index) => (
                                                        <Col key={index} style={{textAlign:"center"}}>
                                                            <div style={{margin:"auto", display:"inline-block"}}>
                                                                <CategoryTag category={category.category} width={200}/>
                                                            </div>
                                                            <div className={"ebloom-normal-text center mt10"} style={{height:90}}>{category.name}</div>
                                                            <div style={{marginTop:isMobile ? 15 : 60, opacity:(category.score === null || category.score === -1) ? 0.4 : 1}}>
                                                                <EbloomSpeedometerV2 score={category.score} width={200}/>
                                                            </div>
                                                            {
                                                                (category.score === null || category.score === -1) ?
                                                                    <div className={"ebloom-text ebloom-very-large-text center"} style={{position:"relative", bottom:category.score === -1 ? 150 :100}}>{category.score === -1 ? _.upperFirst(t("manager.dashboard.anonymousRestriction")) : _.upperFirst(t("core.noData"))}</div>
                                                                    :
                                                                    <Accordion.Collapse eventKey={1}>
                                                                        <div style={{marginLeft:30, marginBottom:isMobile ? 30 : 0}}>
                                                                            <EbloomAllSmileyProgressBar nbResponse1={category.nbResponse1} nbResponse2={category.nbResponse2} nbResponse3={category.nbResponse3} nbResponse4={category.nbResponse4} nbResponse5={category.nbResponse5} total={category.total} barWidth={isMobile ? 150 : 75} showNA={false}/>
                                                                        </div>
                                                                    </Accordion.Collapse>

                                                            }
                                                            {
                                                                index === categoriesScores.length-1 &&
                                                                <Accordion.Button bsPrefix={"accordion"} eventKey={1} as={"div"} style={{float:"right", cursor:"pointer", position:"relative", bottom:30, left:25}}>
                                                                    {
                                                                        activeKeyCategory === 1 ?
                                                                            <ArrowDropUpIcon style={{color:Colors.EBLOOM_PURPLE, fontSize:32}} onClick={handleActiveKeyCategory}/>
                                                                            :
                                                                            <ArrowDropDownIcon style={{color:Colors.EBLOOM_PURPLE, fontSize:32}} onClick={handleActiveKeyCategory}/>

                                                                    }
                                                                </Accordion.Button>
                                                            }

                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Accordion>


                        }
                    </DashboardCard>
                </Col>
            </Row>
            <ConfirmModal handleClose={handleCloseModal} show={showModal} title={_.capitalize(t("core.confirm"))+" - " + _.capitalize(t("admin.dataExport"))} showButtons buttonLeftText={_.capitalize(t("core.cancel"))} buttonRightText={_.capitalize(t("core.confirm"))} handleChange={exportData}>
                <p className={"text-line-break"}>{teamToDisplay && teamToDisplay.id > 0 ? _.upperFirst(t("admin.dataExportHelperTeam", {company:teamToDisplay.name, period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")})) : _.upperFirst(t("admin.dataExportHelperDashboard", {company:companyToEdit ? companyToEdit.name : companyStateName, period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")}))}</p>
                <Row>
                    <TextFieldCustom model={UserModel.lang} value={dataExportLang} handleChange={handleChangeLangDataExport}>
                        {
                            langs.map(lang => (
                                <MenuItem key={lang.value} value={lang.value}>{lang.value}</MenuItem>
                            ))
                        }
                    </TextFieldCustom>
                </Row>

            </ConfirmModal>
        </div>
    )

}

GlobalDashboard.defaultProps = {
    companyToEdit: null,
    teamToDisplay:null,
    startDate:"1995-12-25",
    endDate:moment().format("YYYY-MM-DD")
};

export default GlobalDashboard;