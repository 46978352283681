import {ModelTypes} from "./Types";

const Category = {
    crud:{
        create:"/categories/create",
        update:"/categories/update",
        delete:"/categories/delete"
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },

    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            label:"core.name",
            show:true,
            id:"name-category",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false,
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },
};


export default Category;