import React from "react"
import DisplayAdminView from "../../../../../Admin/Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import WeBloomAnswerAlert from "./WeBloomAnswerAlert";

const WeBloomAnswerAlertPage = (props) => {

    return (
        <DisplayAdminView pageName={"webloom.alert"} menu={"noMenu"} selectedIndex={10} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomAnswerAlert {...props}/>
                    </Container>

                )
            }
        </DisplayAdminView>
    )

}

export default WeBloomAnswerAlertPage;