import {memo} from "react";
import {useDrop} from "react-dnd";


export const DropWrapperV2 = memo(function DropWrapperV2({children}) {
    const [, drop] = useDrop(() => ({ accept: "existingQuestion" }))

    return (
        <div ref={drop} className={"mt-20 mb-20 w-full"}>
            {children}
        </div>
    )
})