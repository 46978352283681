import {ReactComponent as TIIcon} from "../../../../Core/symbols/customCategoriesIcons/team_interaction_icon.svg";
import {ReactComponent as WLIcon} from "../../../../Core/symbols/customCategoriesIcons/work_lifestyle_icon.svg";
import {ReactComponent as MLIcon} from "../../../../Core/symbols/customCategoriesIcons/management_leadership_icon.svg";
import {ReactComponent as PSIcon} from "../../../../Core/symbols/customCategoriesIcons/policy_support_icon.svg";
import {ReactComponent as ACIcon} from "../../../../Core/symbols/customCategoriesIcons/adhesion_culture_icon.svg";
import {ReactComponent as Icon6} from "../../../../Core/symbols/customCategoriesIcons/Icon6.svg";
import {ReactComponent as Icon7} from "../../../../Core/symbols/customCategoriesIcons/Icon7.svg";
import {ReactComponent as Icon8} from "../../../../Core/symbols/customCategoriesIcons/Icon8.svg";
import {ReactComponent as Icon9} from "../../../../Core/symbols/customCategoriesIcons/Icon9.svg";
import {ReactComponent as Icon10} from "../../../../Core/symbols/customCategoriesIcons/Icon10.svg";
import {ReactComponent as Icon11} from "../../../../Core/symbols/customCategoriesIcons/Icon11.svg";
import {ReactComponent as Icon12} from "../../../../Core/symbols/customCategoriesIcons/Icon12.svg";
import {ReactComponent as Icon13} from "../../../../Core/symbols/customCategoriesIcons/Icon13.svg";
import {ReactComponent as Icon14} from "../../../../Core/symbols/customCategoriesIcons/Icon14.svg";
import {ReactComponent as Icon15} from "../../../../Core/symbols/customCategoriesIcons/Icon15.svg";
import {ReactComponent as Icon16} from "../../../../Core/symbols/customCategoriesIcons/Icon16.svg";
import {ReactComponent as Icon17} from "../../../../Core/symbols/customCategoriesIcons/Icon17.svg";
import {ReactComponent as Icon18} from "../../../../Core/symbols/customCategoriesIcons/Icon18.svg";
import {ReactComponent as Icon19} from "../../../../Core/symbols/customCategoriesIcons/Icon19.svg";
import {ReactComponent as Icon20} from "../../../../Core/symbols/customCategoriesIcons/Icon20.svg";
import {ReactComponent as Icon21} from "../../../../Core/symbols/customCategoriesIcons/Icon21.svg";

import {ReactComponent as Bubble1} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble1.svg";
import {ReactComponent as Bubble2} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble2.svg";
import {ReactComponent as Bubble3} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble3.svg";
import {ReactComponent as Bubble4} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble4.svg";
import {ReactComponent as Bubble5} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble5.svg";
import {ReactComponent as Bubble6} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble6.svg";
import {ReactComponent as Bubble7} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble7.svg";
import {ReactComponent as Bubble8} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble8.svg";
import {ReactComponent as Bubble9} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble9.svg";
import {ReactComponent as Bubble10} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble10.svg";
import {ReactComponent as Bubble11} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble11.svg";
import {ReactComponent as Bubble12} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble12.svg";
import {ReactComponent as Bubble13} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble13.svg";
import {ReactComponent as Bubble14} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble14.svg";
import {ReactComponent as Bubble15} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble15.svg";
import {ReactComponent as Bubble16} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble16.svg";
import {ReactComponent as Bubble17} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble17.svg";
import {ReactComponent as Bubble18} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble18.svg";
import {ReactComponent as Bubble19} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble19.svg";
import {ReactComponent as Bubble20} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble20.svg";
import {ReactComponent as Bubble21} from "../../../../Core/symbols/customCategoriesIcons/Bubbles/Bubble21.svg";
import {useTranslation} from "react-multi-lang";


export const listIcon = [
    <WLIcon className={"w-full h-full"}/>,
    <TIIcon className={"w-full h-full"}/>,
    <MLIcon className={"w-full h-full"}/>,
    <PSIcon className={"w-full h-full"}/>,
    <ACIcon className={"w-full h-full"}/>,
    <Icon6 className={"w-full h-full"}/>,
    <Icon7 className={"w-full h-full"}/>,
    <Icon8 className={"w-full h-full"}/>,
    <Icon9 className={"w-full h-full"}/>,
    <Icon10 className={"w-full h-full"}/>,
    <Icon11 className={"w-full h-full"}/>,
    <Icon12 className={"w-full h-full"}/>,
    <Icon13 className={"w-full h-full"}/>,
    <Icon14 className={"w-full h-full"}/>,
    <Icon15 className={"w-full h-full"}/>,
    <Icon16 className={"w-full h-full"}/>,
    <Icon17 className={"w-full h-full"}/>,
    <Icon18 className={"w-full h-full"}/>,
    <Icon19 className={"w-full h-full"}/>,
    <Icon20 className={"w-full h-full"}/>,
    <Icon21 className={"w-full h-full"}/>,
]

export const listBubbleIcon = (color) =>  [
    <Bubble1 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble2 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble3 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble4 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble5 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble6 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble7 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble8 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble9 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble10 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble11 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble12 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble13 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble14 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble15 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble16 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble17 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble18 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble19 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble20 style={{fill: color}} className={"w-full h-full"}/>,
    <Bubble21 style={{fill: color}} className={"w-full h-full"}/>,
]

export const randomColor = () => {
    return defaultColors[Math.floor(Math.random()*defaultColors.length)]
}

export const selectIcon = (icon) => {
  if (icon !== null){
      return listIcon[parseInt(icon)-1]
  }else{
      return String(Math.floor(Math.random()*listIcon.length+1))
  }
}

export const selectBubbleIcon = (icon,color) => {
    if (icon !== null){
        return listBubbleIcon(color)[parseInt(icon)-1]
    }else{
        return null
    }
}

export const defaultColors = [
    '#F7E4ED','#E4E2F2','#F3DFD1','#FFE5C6','#DAF0E6','#D4F4FB',"#FDF2D1","#E1E1ED","#F5E1FD","#D4FBF2","#C4E4FF"
]