import React, {useEffect, useRef, useState} from "react";
import HttpApi from "../../../../httpApi";
import * as PropTypes from "prop-types";
import {notifyError, notifyInfo} from "../../../../Utils/Notification";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Login from "../../../../login/login";
import {useNavigate} from "react-router-dom";
import {useUserData} from "../../../../hooks/userHook";
import {FormControl, OutlinedInput} from "@mui/material";
import Select from "@mui/material/Select";
import _ from "lodash";
import {useOutsideAlerter} from "../../../../hooks/outsideAlerterHook";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import {CompanyTypes} from "../../../../Utils/Global";
import {Colors} from "../../../../Utils/Colors";
import {alpha} from "@mui/material/styles";

const TeamSwitcher = (props) => {
    const [teams, setTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();

    const {bp, handleSwitch} = props;

    const user = useUserData();

    const t = useTranslation();
    const isProspect = user.companyType === CompanyTypes.PROSPECT

    const handleChangeMultiple = (event) => {
        const value = event.target.value
        const companySelected = value.filter(el => el.id === 0).length > 0 && selectedTeams.filter(el => el.id === 0).length < 1
        if(value.length > 0){
            const indexCompanyRemove = value.findIndex(el => el.id === 0)
            if(companySelected){
                setSelectedTeams([teams[0]])
                setShowMenu(false)
                handleSwitch([teams[0]])
            }else if(indexCompanyRemove !== -1){
                value.splice(indexCompanyRemove,1)
                setSelectedTeams(value)
                handleSwitch(value)
            }else{
                setSelectedTeams(value)
                handleSwitch(value)
            }
        }else if((value.length === 1 && value[0].id === 0) || (value.length === 0 && selectedTeams.length === 1 && selectedTeams[0].id === 0)){
            setShowMenu(false)
        }
    }



    const fetchValidTeams = () => {
        let url = Login.hasRole("global_admin") ? "/teams/getValid" : "/teams/getUserAdvancedCBTeamsWithUsers";

        HttpApi.getV2(url).then(response => {
            let company = !isProspect ? user.company : _.upperFirst(t("core.company.company"));
            if(Login.hasRole("global_admin")){
                setTeams([{id:0, name:company}])
            }
            if(response && response.data){
                let listTeam = response.data

                if(Login.hasRole("global_admin")){
                    listTeam.splice(0,0, {id:0, name:company});
                }
                if(Login.hasRole("global_manager") && listTeam[0].id === 0){
                    listTeam[0].name = company
                }
                if(isProspect){
                    listTeam = listTeam.map((el,index) => {
                        if(el.id !== 0 ){
                            el.name = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index-1 + 'A'.charCodeAt(0))
                            return el
                        }
                        return el
                    })
                }
                handleSwitch([listTeam[0]]);
                setSelectedTeams([listTeam[0]])
                setTeams(listTeam);
            }else{
                if(Login.hasRole("global_admin")){
                    const companyName = company || _.upperFirst(t("core.global"));
                    handleSwitch([{id: 0, name: companyName}]);
                }else{
                    navigate("/employee/dashboard");
                    notifyInfo("No valid dashboard to display - Redirecting you to your personal dashboard", true);
                }

            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const redirectToMyBloom = () => {
        navigate("/employee/dashboard");
    }

    useEffect(() => {
        fetchValidTeams();
    }, [user]);



        return(
            <div className={"w-auto max-w-170"}>
                <FormControl sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border:"none"
                    },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border:"none"
                    },
                    "&& .MuiSelect-icon":{
                        color: Colors.EBLOOM_PURPLE
                    }
                }}  fullWidth={true} className={"text-ellipsis w-auto max-w-170 min-w-140 overflow-hidden flex-nowrap"} size={"small"}>
                    <Select
                        className={"text-contrast-purple h-35 mr-5 bg-white border border-contrast-purple text-ellipsis overflow-hidden flex-nowrap"}
                        multiple={true}
                        value={selectedTeams}
                        name={"selectTeams"}
                        onChange={handleChangeMultiple}
                        onClose={()=> setShowMenu(false)}
                        onOpen={()=> setShowMenu(true)}
                        open={showMenu}
                        MenuProps={{
                            sx : {
                                "&& .Mui-selected": {
                                    backgroundColor : alpha(Colors.EBLOOM_PURPLE,0.35)
                                },
                            }
                        }}
                        renderValue={(selected) => {
                                if(selected.length === 1){
                                    return <div><p
                                        className={"text-ellipsis overflow-hidden"}>{selected[0].name}</p>
                                    </div>
                                }else{
                                    return <div>
                                        <p className={"text-ellipsis overflow-hidden"}>{selected.length +" "+ _.lowerFirst(t("admin.teams"))}</p>
                                    </div>
                                }
                            }
                        }
                    >
                        {
                            teams.map((option, index) => {
                                const isCompany = option.id === 0
                                let name = isCompany ?  !isProspect ? user.company : _.upperFirst(t("core.company.company")) : option.name;
                                return(
                                    <MenuItem key={index} divider={isCompany || teams.length === index +1} value={option} >{name}</MenuItem>
                                )
                            })
                        }
                        <MenuItem key={-1} value={-1}  onClick={redirectToMyBloom}>MyBloom</MenuItem>
                    </Select>
                </FormControl>
            </div>
        )
}

TeamSwitcher.propTypes = {
   handleSwitch:PropTypes.func

};

export default TeamSwitcher;