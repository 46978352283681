import React, {useEffect} from "react"
import DisplayPublicView from "../../../Core/Layouts/DisplayPublicView";
import DailySurvey from "./DailySurvey";
import {useBreakpoints} from "../../../../hooks/breakpointsHook";
import {useNavigate} from "react-router-dom";

const PublicDailySurveyPage = (props) => {

    const bp = useBreakpoints();
    const navigate = useNavigate();

    useEffect(() => {
        let url = new URL(window.location.href);
        let tokenUrl = url.searchParams.get("key");

        if(!tokenUrl){
            navigate("/login")
        }
    }, []);

    return (
        <DisplayPublicView>
            {
               <DailySurvey bp={bp}/>
            }
        </DisplayPublicView>
    )

}

export default PublicDailySurveyPage;