import {createSlice} from "@reduxjs/toolkit";

export const navigation = createSlice({
    name: 'navigation',
    initialState: {
        value: false,
        modal: false,
        blockedRoute: {url:"", options:{}},
    },
    reducers: {
        updateNavigation: (state, action) => {
            state.value = action.payload
        },
        updateModal: (state, action) => {
            state.modal = action.payload
        },
        updateBlockedRoute: (state, action) => {
            state.blockedRoute = action.payload
        }
    },
})

export const {updateNavigation, updateModal, updateBlockedRoute} = navigation.actions;

export default navigation.reducer;