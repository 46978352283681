import {ModelTypes} from "./Types";

const Company = {
    crud:{
        create:"/companies/create",
        update:"/companies/update",
        delete:{
            url:'/companies/delete',
            method:'PUT'
        }
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },


    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.name",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"company-name",
            label:"core.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
            helperText:""
        }
    },

    code:{
        id:"code",
        type:ModelTypes.TEXT,
        label:"core.login.companyCode",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false,
            id:"company-code",
            label:"core.login.companyCode",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"code",
            helperText:"core.login.companyCodeHelper"
        }
    },

   code_admin:{
        id:"code_admin",
        type:ModelTypes.TEXT,
        label:"core.login.companyCodeAdmin",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false,
            id:"company-codeAdmin",
            label:"core.login.companyCodeAdmin",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"code_admin",
            helperText:""
        }
    },

    type:{
        id:"type",
        type:ModelTypes.TEXT,
        label:"core.type",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:true,
            id:"company-type",
            label:"core.type",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"type",
            helperText:""
        }
    },

    id_module:{
        id:"id_module",
        type:ModelTypes.NUMBER,
        label:"core.module",
        table:{
            show:false
        },
        list:{

        },

        form:{
            show:true,
            id:"company-module",
            label:"core.module",
            select:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_module",
            helperText:""
        }
    },

    module:{
        id:"module",
        type:ModelTypes.PRISMA_RELATION,
        relationLink:(value) => {return value.name },
        label:"core.module",
        table:{
            show:true
        },
        list:{

        },
        form:{
            show:false
        }
    },

    id_airtable:{
        id:"id_airtable",
        type:ModelTypes.TEXT,
        label:"id_airtable",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATETIME,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATETIME,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};

export default Company;