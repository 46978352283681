import React from "react";
import PropTypes from "prop-types"
import {ReactComponent as ImgIcon} from "../../symbols/webloom/white/img-white.svg";
import {ReactComponent as FileIcon} from "../../symbols/webloom/white/file-white.svg";
import {Form} from "react-bootstrap";
import {notifyCustomError} from "../../../../Utils/Notification";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";

const FileInput = (props) => {

    //state
    const id = Math.random().toString() + "-file-input"

    //props
    const {handleChange, type, showIcon, showText, iconColor} = props;
    const t = useTranslation();

    const acceptedTypes = type === "image" ? ["image/jpeg", "image/png"] : [
        "text/plain",
        "application/pdf",
        "image/jpeg",
        "image/png",
    ]

    const accept = type === "image" ? ".jpeg, .jpg, .png" : ".pdf, .jpeg, .jpg, .png, .txt"

    //hooks

    //getters

    //posters

    //handlers
    const validateType = (event) => {
        const file = event.target.files[0];
        if(file && file.type){
            if(acceptedTypes.includes(file.type)){
                let size = ((file.size/1024)/1024).toFixed(4); //MB Size
                if(size <= 100){//max 100MB
                    handleChange(event);
                    document.getElementById(id).value = null;
                }else{
                    notifyCustomError(_.upperFirst(t("webloom.notification.fileSizeError")));
                    document.getElementById(id).value = null;
                }
            }else{
                notifyCustomError(_.upperFirst(t("webloom.notification.fileTypeError", {fileExtensions:accept})));
                document.getElementById(id).value = null;
            }

        }

    }

    //useEffect

    return (
        <div className={props.className}>
            <label htmlFor={id}>
                {
                    showIcon === true &&
                        type === "image" ?
                        <EbloomTooltip text={_.upperFirst(t("webloom.image"))}>
                            <ImgIcon className={"pointer inline w-25 h-25 " + iconColor + "-icon"}/>
                        </EbloomTooltip>
                        : showIcon === true &&
                        <EbloomTooltip text={_.upperFirst(t("webloom.attachment"))}>
                            <FileIcon className={"pointer inline w-25 h-25 " + iconColor + "-icon"}/>
                        </EbloomTooltip>
                }
                {
                    showText &&
                    <span className={"pointer ml-5 underline text-" + iconColor}>{_.upperFirst(t("core.selectParam", {param:t(type === "image" ? "webloom.image" : "webloom.file")}))}</span>
                }
                <Form.Control className={(showIcon ? "hidden" : "")} type="file" id={id} accept={accept} onChange={validateType} />
            </label>
        </div>
    )
}

FileInput.propTypes = {
    handleChange:PropTypes.func.isRequired,
    showIcon:PropTypes.bool,
    iconColor:PropTypes.string,
    type:PropTypes.string,
    showText:PropTypes.bool,
}

FileInput.defaultProps = {
    showIcon:true,
    type:"file",
    iconColor:"purple",
    showText:false,
}
export default FileInput