import {useState} from "react";
import {useNavigationTo} from "./navigationHook";

export function useSavePage(initialState=true){
    const navigation = useNavigationTo();
    const updateSavedPageState = (state) => {
        setPage(prevState => {
            let savedPage = {...prevState};
            savedPage.isSaved = state;
            return savedPage;
        })
        if(false === state){
            navigation.blockRouter();
        }else{
            navigation.unblockRouter();
        }
    }

    const save = () => {
        updateSavedPageState(true);
    }

    const unSave = () => {
        updateSavedPageState(false);
    }

    const [page, setPage] = useState({isSaved:initialState, save:save, unSave:unSave});



    return page;
}