import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as PollIcon} from "../../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as IdeaboxIcon} from "../../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as SpeakerIcon} from "../../../../../Core/symbols/webloom/white/message-white.svg";
import pollTemplateImg from "../../../../../Core/symbols/webloom/poll-template.png";
import ideaboxTemplateImg from "../../../../../Core/symbols/webloom/idea-template.png";
import messageTemplateImg from "../../../../../Core/symbols/webloom/message-template.png";
import _ from "lodash";
import {SurveyTypes} from "../../Polls/Modules/Types";
import HttpApi from "../../../../../../httpApi";
import {getLanguage} from "react-multi-lang";
import EbloomSelectLang from "../../../../../Core/Modules/Input/EbloomSelectLang";
import {useUserData} from "../../../../../../hooks/userHook";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FileDisplay from '../../../../../Core/Modules/Views-Tables-Lists/FileDisplay';

const WeBloomCreateMenu = (props) => {

    //state
    const [templates, setTemplates] = useState([]);
    const [lang, setLang] = useState(getLanguage());

    const {showBorder,isGroup} = props;

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();
    const userData = useUserData();
    const idCompanyGroup = isGroup ? userData.companyGroup.id : null
    const isProspect = userData.module === "prospect"
    //getters

    //posters

    //handlers

    //useEffect
    useEffect(() => {
        HttpApi.getV2("/webloom/getTemplates").then(response => {
            if(response && response.data){
                setTemplates(response.data);
            }
        })
    }, [])

    return (
        <div className={"bg-bg-white" + (showBorder ? " border border-contrast-purple rounded-lg p-10" : "")}>
            <div className={"text-contrast-purple ebloom-title ebloom-very-small-title font-semibold  text-center mb-30"}><p>{_.upperFirst(t("webloom.createFromScratch"))}</p></div>
            <div className={"flex items-center justify-between mb-30"}>
                <div className={"flex flex-col w-1/3 pointer"} onClick={() => {
                    const url = isGroup && idCompanyGroup  ? "/group/webloom/poll/create/"+idCompanyGroup : "/admin/webloom/poll/create"
                    navigate(url)
                }}>
                    <PollIcon className={"purple-icon w-60 h-60 m-auto"}/>
                    <div className={"ebloom-normal-text text-contrast-purple text-center"}>{_.upperFirst(t("core.create"))}</div>
                    <div className={"ebloom-text ebloom-large-text font-semibold text-center text-contrast-purple pointer"}>{_.upperFirst(t("webloom.poll"))}</div>
                </div>
                <div className={"flex flex-col w-1/3 pointer"} onClick={() => {
                    const url = isGroup && idCompanyGroup  ? "/group/webloom/ideabox/create/"+idCompanyGroup : "/admin/webloom/ideabox/create"
                    navigate(url)
                }}>
                    <IdeaboxIcon className={"purple-icon w-60 h-60 m-auto"}/>
                    <div className={"ebloom-normal-text text-contrast-purple text-center"}>{_.upperFirst(t("core.create"))}</div>
                    <div className={"ebloom-text ebloom-large-text font-semibold text-center text-contrast-purple pointer"}>{_.upperFirst(t("webloom.ideabox"))}</div>
                </div>
                <div className={"flex flex-col w-1/3 pointer"} onClick={() => {
                    const url = isGroup && idCompanyGroup ? "/group/webloom/message/create/"+idCompanyGroup : "/admin/webloom/message/create"
                    navigate(url)
                }}>
                    <SpeakerIcon className={"purple-icon w-60 h-60 m-auto"}/>
                    <div className={"ebloom-normal-text text-contrast-purple text-center"}>{_.upperFirst(t("core.create"))}</div>
                    <div className={"ebloom-text ebloom-large-text font-semibold text-center text-contrast-purple pointer"}>{_.upperFirst(t("webloom.message"))}</div>
                </div>
            </div>
            <div>
                <div className={"grid grid-cols-3 mb-20 items-center"}>
                    <div>
                        {
                          templates.some(el => el.logo_url !== null) &&
                          <a className={'ebloom-link-purple'}
                             href={'https://drive.google.com/drive/folders/1AXJ4JBPoK9l9DR-fRwfOoxQxda1a9Odd?usp=sharing'}
                             target={'_blank'}>{_.upperFirst(t('webloom.partnersBrochure'))}</a>
                        }
                    </div>
                    <div className={'col-start-2 justify-self-center'}>
                        <div
                          className={'text-contrast-purple ebloom-title ebloom-very-small-title font-semibold w-fit mr-5'}>{_.upperFirst(t("webloom.templates"))} {isProspect && <spa><LockOutlinedIcon/></spa>}</div>
                    </div>
                    <div className={"justify-self-end"}>
                        <EbloomSelectLang handleChange={(event) => setLang(event.target.value)} value={lang}/>
                    </div>
                </div>
                <div className={"grid grid-cols-3"}>
                    {
                        templates.filter(el => el.lang === lang).map((template, index) => (
                            <div className={"m-5 " + (!isProspect ? 'pointer' : 'cursor-not-allowed')} key={index} onClick={isProspect ? () => {} : () => {navigate("/admin/webloom/"+template.type+"/create?duplicate=true", {state:template})}}>
                                <div className={"h-200 p-5 w-full border-2 border-contrast-purple rounded-md bg-white-blue"}>
                                    <div className={'grid grid-rows-1 grid-cols-3'}>
                                        <div className={"col-start-2"}>
                                            {

                                                template.type === SurveyTypes.POLL ? <PollIcon className={"w-35 h-35 contrast-purple-icon m-auto my-5"}/> : template.type === SurveyTypes.IDEABOX ? <IdeaboxIcon className={"w-35 h-35 contrast-purple-icon m-auto my-5"}/> : <SpeakerIcon className={"w-35 h-35 contrast-purple-icon m-auto my-5"}/>
                                            }
                                        </div>
                                        {
                                            template.logo_url &&
                                          <FileDisplay className={"justify-self-end"} urlParams={template.logo_url} imgHeight={30} displayImg/>
                                        }
                                    </div>

                                    <img className={"m-auto"} src={template.type === SurveyTypes.POLL ? pollTemplateImg : template.type === SurveyTypes.IDEABOX ? ideaboxTemplateImg : messageTemplateImg} alt={"template-img"}/>
                                </div>
                                <div className={"w-full text-center font-semibold " + (isProspect ? "text-disabled-grey": "text-purple")}>{template.title}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

WeBloomCreateMenu.propTypes = {
    showBorder:PropTypes.bool,
    isGroup:PropTypes.bool,
}

WeBloomCreateMenu.defaultProps = {
    showBorder:false,
    isGroup : false
}
export default WeBloomCreateMenu