import React from "react";
import DisplayAdminView from "../../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import WeBloomResultPoll from "./WeBloomResultPoll";

const WeBloomResultPollPage = (props) => {

    return(
        <DisplayAdminView pageName={"WeBloom"} menu={"noMenu"} selectedIndex={10} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomResultPoll {...props}/>
                    </Container>

                )
            }
        </DisplayAdminView>
    )
}

export default WeBloomResultPollPage;