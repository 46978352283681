import {ReactComponent as HandIcon} from "../../../../Core/symbols/myBloom/icons/Hands.svg";
import {ReactComponent as AnswerIcon} from "../../../../Core/symbols/myBloom/icons/AnswerIcon.svg";
import {ReactComponent as ArrowGreen} from "../../../../Core/symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../../../Core/symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../../../Core/symbols/general/ArrowNeutral.svg";
import PropTypes from "prop-types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {getLanguage} from "react-multi-lang";
import React, {useEffect, useState} from "react";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import moment from "moment";

const MyParticipation = (props) => {

    const {participation, answered, totalCB,history,previousDate} = props

    const [participationPoint, setParticipationPoint] = useState(2);
    const t = useTranslation()
    const descriptor = {
        2: "observer",
        3: "curious",
        4: "enthusiastic",
        5: "involved",
        6: "proactive",
        7: "expert",
        8: "pillar",
        9: "legendary"
    }
    const calculatePoint = () => {
        let point = 2
        const partRate = participation.actual
        if(partRate != null && partRate> 0){
            if (partRate <= 20){
                point = point+1
            }else if (partRate <= 40){
                point = point+2
            }else if (partRate <= 60){
                point = point+3
            }else if (partRate <= 70){
                point = point+4
            }else if (partRate <= 80){
                point = point+5
            }else if (partRate <= 90){
                point = point+6
            }else if (partRate >= 91){
                point = point+7
            }
        }
        setParticipationPoint(point)
    }

    const progressBar = () => {
        const list = []
        let leftPercentage = 107
        let rightPercentage = 100
        const increment = 9
        for (let i = 1; i <= 9; i++) {
            leftPercentage -= increment
            rightPercentage -= increment
            if (i === 10) {
                list.push(
                    <div key={i} className={`w-15 h-full ${i<= participationPoint ? "bg-contrast-purple" : "bg-disabled-grey" }`}
                         style={{
                             clipPath: `polygon(0 ${leftPercentage}%, 100% 0, 100% 100%, 0 100%)`
                         }}>
                    </div>)
            } else {
                list.push(
                    <div key={i} className={`w-15 h-full ${i<= participationPoint ? "bg-contrast-purple" : "bg-disabled-grey" } mr-3`}
                         style={{
                             clipPath: `polygon(0 ${leftPercentage}%, 100% ${rightPercentage}%, 100% 100%, 0 100%)`
                         }}>
                    </div>)
            }
        }
        return list
    }

    const displayPreviousExercice = () => {
        const score = participation.actual
        const difference = participation.previous
        if(difference !== null){
            if(score === difference ){
                return null
            }else if(Math.round(difference) > 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowGreen className={""}/>
                            </div>
                            <p className={"text-strong-green text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>

            }else if (Math.round(difference) < 0 ){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowRed/>
                            </div>
                            <p className={"text-strong-red text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }else if (Math.round(difference) === 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(previousDate.start).format("DD/MM/YY"), end: moment(previousDate.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center ml-5"}>
                            <div className={"scale-75"}>
                                <ArrowNeutral/>
                            </div>
                            <p className={"text-grey text-sm"}>
                                {Math.abs(Math.round(difference))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }
            return null
        }
        return null
    }

    useEffect(() => {
        calculatePoint()
    }, [participation]);

    return <div className={"flex flex-col items-stretch justify-center mb-10"}>
        <h1 className={"text-center ebloom-normal-title text-contrast-purple font-bold "+ ((participationPoint !==2 || getLanguage() !== "fr") ? "text-5xl" : "text-4xl" ) }>
            {_.upperFirst(t("employee.dashboard.descriptor."+descriptor[participationPoint]))}
        </h1>
        <div className={"w-full my-10 h-50 flex flex-row justify-center items-end"}>
            {progressBar()}
        </div>
        <div className={"flex flex-col justify-center items-center"}>
            <div className={"flex flex-row justify-start items-end mb-20"}>
                <HandIcon className={"w-40 h-40 contrast-purple-icon"}/>
                <p className={"mx-10 text-contrast-purple ebloom-normal-text "}>
                    {_.upperFirst(t("employee.dashboard.participationRate"))}
                </p>
                <p className={"text-xl ebloom-title text-contrast-purple font-semibold"}>{participation.actual != null ? (participation.actual + "%") : _.toUpper(t("webloom.NA"))}</p>
                <p>{displayPreviousExercice()}</p>
            </div>
            <div className={"flex flex-row justify-start items-end mb-10"}>
                <AnswerIcon className={"w-30 h-30 contrast-purple-icon"}/>
                <p className={"mx-10 text-contrast-purple  ebloom-normal-text "}>
                    {_.upperFirst(t("employee.dashboard.nbAnswers"))}
                </p>
                <p className={"text-contrast-purple font-semibold"}>
                    <span
                        className={"text-xl ebloom-title text-contrast-purple font-semibold"}>{totalCB > 0 ? answered : _.toUpper(t("webloom.NA"))}
                    </span>
                    {totalCB > 0 && "/" + totalCB}
                </p>
            </div>
        </div>
    </div>
}
MyParticipation.propTypes={
    participation : PropTypes.object,
    answered : PropTypes.number.isRequired,
    totalCB : PropTypes.number.isRequired,
    history : PropTypes.array.isRequired,
    previousDate : PropTypes.object.isRequired
}

MyParticipation.defaultProps={
    participation : {actual : null, previous: null}
}

export default MyParticipation