import {ReactComponent as SmileyUnhappyIcon} from "../../../../Core/symbols/smileys/smileyUnhappy.svg";
import {ReactComponent as SmileySadIcon} from "../../../../Core/symbols/smileys/smileySad.svg";
import {ReactComponent as SmileyNeutralIcon} from "../../../../Core/symbols/smileys/smileyNeutral.svg";
import {ReactComponent as SmileyGladIcon} from "../../../../Core/symbols/smileys/smileyGlad.svg";
import {ReactComponent as SmileyHappyIcon} from "../../../../Core/symbols/smileys/smileyHappy.svg"
import LoadingView from "../../../../Core/Layouts/LoadingView";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";


const MyVotes = (props) => {

    const {loadingFromStart, statFromStart} = props
    const t = useTranslation()
    const smileys = [<SmileyUnhappyIcon/>, <SmileySadIcon/>, <SmileyNeutralIcon/>, <SmileyGladIcon/>,
        <SmileyHappyIcon/>]

    const percentForScore = (score) => {
        if (statFromStart.nbResponse <= 0) {
            return 0;
        }
        return Math.round(((statFromStart.tableOfResults[score].nbResponse / statFromStart.nbResponse) * 100))
    };

    const generateSmiley = () => {
        const list = []
        for (let i = 0; i < smileys.length; i++) {
            if (statFromStart.nbResponse > 0) {
                list.push(
                    <div key={i} className={"w-90 h-full flex-col flex"}>

                        <div className={"w-full h-70 flex justify-center items-end mb-20"}>
                            <div style={{width: (20 + (percentForScore(i) / 2)) + "px"}}>
                                {smileys[i]}
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <p className={"text-contrast-purple text-2xl font-semibold text-center"}>{percentForScore(i)}%</p>
                        </div>
                    </div>
                )
            } else {
                list.push(
                    <div key={i} className={"w-90 h-full flex-col flex"}>

                        <div className={"w-full h-70 flex justify-center items-end mb-20"}>
                            <div style={{width: (20 + (50 / 2)) + "px"}}>
                                {smileys[i]}
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <p className={"text-contrast-purple text-2xl font-semibold text-center"}>{_.toUpper(t("webloom.NA"))}</p>
                        </div>
                    </div>
                )
            }

        }
        return list
    }

    return <div className={"w-full mb-20"}>
        <div className={"w-full flex flex-row justify-center items-end"}>
            {
                loadingFromStart ?
                    <LoadingView/>
                    :
                    generateSmiley()
            }
        </div>

    </div>
}

export default MyVotes