import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as CopyImgIcon} from "../../../../../../Core/symbols/webloom/copy_img.svg";
import {ReactComponent as DownloadImgIcon} from "../../../../../../Core/symbols/webloom/download_img.svg";
import {ReactComponent as PieIcon} from "../../../../../../Core/symbols/webloom/pie.svg";
import {ReactComponent as SortDescIcon} from "../../../../../../Core/symbols/webloom/sort-desc.svg";
import IconButton from "@mui/material/IconButton/IconButton";
import {PollTypes} from "../../Modules/Types";
import EbloomTooltip from "../../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../../../../lang/translations";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {replaceUrlsWithLinks} from "../../../../../../../Utils/Global";

const ResultFrame = (props) => {

    const [isHover, setIsHover] = useState(false);

    const {question, children, handleShowGraph, showGraph, handleCopy, handleDownload, handleSort, anonymityRestriction, lang,setShowModalProspect,isProspect} = props;

    const t = useTranslation();

    return(
        <div className={"w-full"} onMouseLeave={() => setIsHover(false)} >
            <div className={"flex items-center w-11/12"} onMouseOver={() => {
                setIsHover(true)
            }} >
                <div
                    className={isHover && question.type !== PollTypes.TITLE ? "w-full border border-dashed border-contrast-purple rounded-lg" : "w-full border border-bg-white border-dashed rounded-lg"}>
                    <div id={"result-frame-" + question.id}
                         className={"p-15 " + (((question.type !== PollTypes.OPEN_QUESTION && question.type !== PollTypes.NAME) || props.isIndividualView === true) ? "report-webloom" : "")}>
                        <div className={"flex"}>
                            <div
                                className={"ebloom-title ebloom-very-small-title font-semibold text-contrast-purple w-[550px] "}>{question.title ? replaceUrlsWithLinks(question.title) : question.title}</div>
                            {question.is_required > 0 &&
                                <span className={"ebloom-text ebloom-very-large-text"}>*</span>}</div>
                        <div
                            className={"ebloom-text ebloom-small-text text-contrast-purple mb-20 text-line-break "}>{question.description ? replaceUrlsWithLinks(question.description) : question.description}</div>
                        {
                            question.type === PollTypes.MULTIPLE_CHOICES &&
                            <div
                                className={"ebloom-text ebloom-very-small-text text-grey mb-20"}>{_.upperFirst(getTranslations(lang, "webloom.selectMaxParamAnswer", {param: question.nb_items}))}</div>
                        }
                        <div className={"mb-10"}>
                            {
                                question.image_url &&
                                <FileDisplay className={"w-fit"} urlParams={question.image_url} imgHeight={300}
                                             displayImg/>
                            }
                            {
                                question.attachment_url &&
                                <div className={"mt-10 flex justify-start items-center"}>
                                    <FileDisplay urlParams={question.attachment_url}/>
                                </div>
                            }
                        </div>

                        {
                            children
                        }
                    </div>
                </div>
                {
                    (isHover && !anonymityRestriction && question.type !== PollTypes.TITLE) ?
                        <div className={"flex flex-col justify-between ml-20 w-26"}>
                            <EbloomTooltip text={_.upperFirst(t("webloom.copyClipboard"))} placement={"right"}>
                                <IconButton size={"small"}
                                            onClick={isProspect ? () => setShowModalProspect(true) : handleCopy}>
                                    <CopyImgIcon className={isProspect ? "grey-icon" : "purple-icon"}/>
                                </IconButton>
                            </EbloomTooltip>
                            <EbloomTooltip text={_.upperFirst(t("webloom.downloadPng"))} placement={"right"}>
                                <IconButton size={"small"}
                                            onClick={isProspect ? () => setShowModalProspect(true) : handleDownload}>
                                    <DownloadImgIcon className={isProspect ? "grey-icon" : "purple-icon"}/>
                                </IconButton>
                            </EbloomTooltip>
                            {
                                (question.type !== PollTypes.OPEN_QUESTION && question.type !== PollTypes.NAME && question.type !== PollTypes.IDENTIFICATION) &&
                                <EbloomTooltip text={_.upperFirst(t("webloom.switchView"))} placement={"right"}>
                                    <IconButton size={"small"} onClick={handleShowGraph}>
                                        <PieIcon
                                            className={"w-20 h-20" + (showGraph ? " purple-icon" : " border-purple-icon")}/>
                                    </IconButton>
                                </EbloomTooltip>
                            }
                            {
                                !showGraph && question.type !== PollTypes.OPEN_QUESTION && question.type !== PollTypes.NAME && question.type !== PollTypes.IDENTIFICATION && question.type !== PollTypes.STAR_RATING &&
                                <EbloomTooltip text={_.upperFirst(t("webloom.sortByMost"))} placement={"right"}>
                                    <IconButton size={"small"} onClick={handleSort}>
                                        <SortDescIcon/>
                                    </IconButton>
                                </EbloomTooltip>

                            }
                        </div>
                        :
                        <div className={"flex flex-col justify-between w-50"}/>
                }

            </div>

        </div>
    )
}

ResultFrame.propTypes = {
    question: PropTypes.object.isRequired,
    handleShowGraph: PropTypes.func,
    showGraph: PropTypes.bool,
    handleCopy: PropTypes.func,
    handleDownload: PropTypes.func,
    handleSort: PropTypes.func,
    anonymityRestriction: PropTypes.bool,
    isIndividualView: PropTypes.bool,
    isProspect: PropTypes.bool,
    setShowModalProspect: PropTypes.func
}

ResultFrame.defaultProps = {
    showGraph: false,
    isIndividualView: false,
}

export default ResultFrame;