import React from 'react';
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import ColorsEditor from "./ColorsEditor";

const ColorsEditorPage = (props) => {
    //const {} = props

    return (
        <DisplayAdminView {...props}>
            {() => (
                <Container>
                    <ColorsEditor {...props}/>
                </Container>
            )
            }
        </DisplayAdminView>
    );
};

ColorsEditorPage.propTypes = {

};

export default ColorsEditorPage;
