import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import EbloomPopoverV2 from "../../../../../Core/Modules/Popovers-Tooltips/EbloomPopoverV2";
import {Col, ListGroup, Row} from "react-bootstrap";
import moment from "moment/moment";
import {ReactComponent as NotificationsIcon} from "../../../../../Core/symbols/webloom/white/alert-white.svg"
import EbloomNotificationBadge from "../../../../../Core/Modules/Notifications/EbloomNotificationBadge";
import _ from "lodash";

const WeBloomNotifications = (props) => {
    //state
    const [showPopover, setShowPopover] = useState(false);
    const [targetPopover, setTargetPopover] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [badgeNotif, setBadgeNotif] = useState(0);

    const {surveys, handleSeeDetail} = props;

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers
    const handleClosePopover = () => {
        setShowPopover(false);
    }

    const handleOpenPopover = (event) => {
        if(notifications.length > 0){
            if(showPopover){
                setShowPopover(false);
            }else{
                setShowPopover(true);
                setTargetPopover(event.target);
            }
        }
    }

    //useEffect
    useEffect(() => {
        if(surveys){
            let array = surveys.filter(el => el.nbResponses>el.lastNbAnswers).sort((a, b) => {return a.lastAnswer-b.lastAnswer});
            setNotifications(array);
            setBadgeNotif(surveys.filter(el => el.nbResponses>el.lastNbAnswers).length);
        }else{
            setNotifications([]);
        }
    }, [surveys])

    return (
       <EbloomNotificationBadge number={badgeNotif} showNumber>
            <EbloomPopoverV2 trigger={<span style={{color:"white", fontWeight:"normal"}} onClick={handleOpenPopover}><NotificationsIcon className={"w-31 h-31 contrast-purple-icon"}/></span>} placement="bottom" show={showPopover} target={targetPopover} handleClose={handleClosePopover} width={350}>
                <ListGroup defaultActiveKey={0} variant="flush" className={"max-h-454 overflow-y-auto border border-contrast-purple"}>
                    {
                        notifications && notifications.length > 0 && notifications.map((notification, index) => (
                            <ListGroup.Item key={index} className={"list_notification_clickable list_notification"} onClick={() => {handleSeeDetail(notification)}}>
                                <Row>
                                    <Col>
                                        <p className={"ebloom-normal-text text-contrast-purple"}>{_.upperFirst(t("webloom.notification.newAnswers",{param:(notification.nbResponses-notification.lastNbAnswers).toString(), title:notification.title || notification.question.text || ""}))}</p>
                                    </Col>
                                    <Col md={3}>
                                        <span className={"right ebloom-small-text text-grey"}>{moment(notification.lastAnswer).format("DD/MM/YYYY")}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </EbloomPopoverV2>
       </EbloomNotificationBadge>
    )
}

WeBloomNotifications.propTypes = {
    surveys:PropTypes.array,
    handleSeeDetail:PropTypes.func
}

WeBloomNotifications.defaultProps = {}
export default WeBloomNotifications