import {ReactComponent as EbloomLogo} from "./symbols/logo/ebloom.svg";
import {useEffect, useState} from "react";
import axios from "axios";

function Counter() {
    const [totalFeedbacks, setTotalFeedbacks] = useState(190000);
    const [weekFeedbacks, setWeekFeedbacks] = useState(0);
    const [monthFeedbacks, setMonthFeedbacks] = useState(0);
    const [newData, setNewData] = useState(null);

    const updateStateWithTransition = (newNumber, oldNumber, setState) => {
        for(let i=oldNumber; i<=newNumber;i++){
            setTimeout(() => {
                setState(i);
            }, 10000)
        }
    }

    /**
     * DO NOT TOUCH
     * Recover data from eBloom backend
     */
    const fetchCountFeedbacks = () => {
        axios.request({
            method: "GET",
            url: "https://www.ebloom-clicknbloom.com/api/dashboard/getCountFeedbacks",
            headers: {"Content-Type": "application/json"},
        }).then((response) => {
            if (response && response.data) {
                setNewData(response.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    /**
     * DO NOT TOUCH
     * Start the CRON Job on component mount
     * Stop the CRON Job on component unmount
     */
    useEffect(() => {
        fetchCountFeedbacks();
        let myInterval = setInterval(fetchCountFeedbacks, 30000)
        return () => {
            clearInterval(myInterval);
        }
    }, [])

    useEffect(() => {
        if(newData && newData[0].nbFeedbacks !== weekFeedbacks){
            updateStateWithTransition(newData[0].nbFeedbacks, weekFeedbacks, setWeekFeedbacks);
            updateStateWithTransition(newData[1].nbFeedbacks, monthFeedbacks, setMonthFeedbacks);
            updateStateWithTransition(newData[2].nbFeedbacks, totalFeedbacks, setTotalFeedbacks);
        }
    }, [newData])


    return (
        <div className={"h-screen bg-bg-blue w-full flex justify-center"}>
            <div className={"w-full grid grid-rows-3"}>
                <EbloomLogo className={"w-250 h-250 m-auto"}/>
                <div className={"grid grid-cols-3 mt-50"}>
                    <div>
                        <h1 className={"text-3xl font-semibold text-center text-contrast-purple mb-3"}>{"Week"}</h1>
                        <div className={"flex items-center justify-center"}>
                            {
                                weekFeedbacks.toString().split("").map((i, index) => (
                                    <div key={index}
                                         className={"bg-bg-white rounded-xl p-10 text-8xl text-center mx-5"}>{i}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <h1 className={"text-3xl font-semibold text-center text-contrast-purple mb-3"}>{"Total"}</h1>
                        <div className={"flex items-center justify-center"}>
                            {
                                totalFeedbacks.toString().split("").map((i, index) => (
                                    <div key={index}
                                         className={"bg-bg-white rounded-xl p-10 text-9xl text-center mx-5"}>{i}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <h1 className={"text-3xl font-semibold text-center text-contrast-purple mb-3"}>{"Month"}</h1>
                        <div className={"flex items-center justify-center"}>
                            {
                                monthFeedbacks.toString().split("").map((i, index) => (
                                    <div key={index}
                                         className={"bg-bg-white rounded-xl p-10 text-8xl text-center mx-5"}>{i}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Counter;