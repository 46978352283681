import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";
import {ReactComponent as DeleteIcon} from "../../../../../../Core/symbols/webloom/white/delete-white.svg";
import IconButton from "@mui/material/IconButton/IconButton";
import {FullContrastBlueTextField} from "../../../../../../../Utils/Global";
import {PollTypes} from "../../Modules/Types";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {getTranslations} from "../../../../../../../lang/translations";

const Choice = (props) => {
    const {propQuestion, isEditing, handleChange, lang, handleCanDrag} = props;

    const t = useTranslation();

    const handleChangeOption = (event) => {
        const value = event.target.value;
        const index = parseInt(event.target.name);
        const question = _.cloneDeep(propQuestion);
        let options = question.options;
        options[index].text = value;
        const e = {target:{value:options, name:"options"}};
        handleChange(e);

    }

    const handleAddOption = () => {
        const question = _.cloneDeep(propQuestion);
        let options = question.options;
        options.push({name:"", value:options.length});
        const e = {target:{value:options, name:"options"}};
        handleChange(e);
        if(propQuestion.type === PollTypes.MULTIPLE_CHOICES){
            handleChange({target:{value:options.length, name:"nb_items_adapted"}})
        }
    }

    const handleDeleteOption = (index) => {
        const question = _.cloneDeep(propQuestion);
        let options = question.options;
        options.splice(index, 1);
        const e = {target:{value:options, name:"options"}};
        handleChange(e);
        if(propQuestion.type === PollTypes.MULTIPLE_CHOICES){
            handleChange({target:{value:options.length, name:"nb_items"}})
        }
    }

    useEffect(() => {
        if(lang && propQuestion.type === PollTypes.YES_NO){
            let q = _.cloneDeep(propQuestion);
            q.options = [{text:_.upperFirst(getTranslations(lang,"webloom." + (propQuestion.is_heart ? "true" : "yes"))), value:0}, {text:_.upperFirst(getTranslations(lang, "webloom." + (propQuestion.is_heart ? "false" : "no"))), value:1}];
            const e = {target:{value:q.options, name:"options"}};
            handleChange(e);
        }
    }, [lang])

    return(
        <div>
                {
                    propQuestion.options && propQuestion.options.map((option, index) => (
                        <div className={"flex items-center"} key={index}>
                            <Form.Check name={"radio-check-" + propQuestion.id} type={propQuestion.type === PollTypes.MULTIPLE_CHOICES ? "checkbox" : "radio"} className={"mt10 mb10"} key={index}/>
                            {
                                (isEditing && (propQuestion.type !== PollTypes.YES_NO)) ?
                                <FullContrastBlueTextField value={option.text} placeholder={_.upperFirst(t("webloom.option")) + " " + (index+1)} size={"small"} onChange={handleChangeOption} variant={"outlined"} name={index.toString()} className={"ml-10"} onMouseEnter={() => {handleCanDrag(false)}} onMouseLeave={() => {handleCanDrag(true)}}/>
                                    :
                                <span className={"ml-10"}>{option.text || (_.upperFirst(t("webloom.option")) + " " + (index+1))}</span>
                            }
                            {
                                (isEditing && propQuestion.type !== PollTypes.YES_NO && propQuestion.options.length > 2) &&
                                <IconButton size={"small"} onClick={() => {handleDeleteOption(index)}}><DeleteIcon className={"contrast-blue-icon w-15 h-15 ml-10"}/></IconButton>
                            }
                        </div>
                    ))
                }
                {
                    (isEditing && (propQuestion.type === PollTypes.UNIQUE_CHOICE || propQuestion.type === PollTypes.MULTIPLE_CHOICES)) &&
                    <div className={"flex items-center mt-10"}><Form.Check disabled type={propQuestion.type === PollTypes.MULTIPLE_CHOICES ? "checkbox" : "radio"}/><span className={"ml-10 text-disabled-grey pointer"} onClick={handleAddOption}>{_.upperFirst(t("core.addParam", {param:t("webloom.option")}))}</span></div>
                }
                {
                    propQuestion.show_other &&
                    <div className={"flex items-center"}>
                        <Form.Check name={"radio-check-" + propQuestion.id} type={propQuestion.type === PollTypes.MULTIPLE_CHOICES ? "checkbox" : "radio"} className={"mt10 mb10"}/>
                        {
                            propQuestion.define_other ?
                                <div className={"ml-10 flex w-200 h-40 rounded-md bg-contrast-blue/[0.1] text-grey items-center"}><div className={"ml-5"}>{_.upperFirst(getTranslations(lang,"webloom.defineOther"))}</div></div>
                                :
                                <span className={"ml-10"}>{propQuestion.type === PollTypes.YES_NO ? _.upperFirst(getTranslations(lang,"webloom.NA")) : _.upperFirst(getTranslations(lang,"webloom.other"))}</span>
                        }
                    </div>
                }
            </div>
    )
}

Choice.propTypes = {
    propQuestion:PropTypes.object.isRequired,
    isEditing:PropTypes.bool,
    handleChange:PropTypes.func,
    lang:PropTypes.string
}

Choice.defaultProps = {
    lang:"en"
}

export default Choice;