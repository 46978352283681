import React, {useState, useEffect} from "react";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import {ReactComponent as LikeIcon} from "../../../../../Core/symbols/webloom/white/like-white-nofill.svg";
import HttpApi from "../../../../../../httpApi";
import {notifyError} from "../../../../../../Utils/Notification";
import {ReactComponent as BubbleIcon} from "../../../../../Core/symbols/webloom/bubble-coma.svg";
import {ReactComponent as MessageIcon} from "../../../../../Core/symbols/webloom/white/message-white.svg";
import {ReactComponent as ArchiveIcon} from "../../../../../Core/symbols/webloom/white/archive-white.svg";
import {SurveyStatus, SurveyUsersStatus} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import moment from "moment";
import _ from "lodash";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {replaceUrlsWithLinks} from "../../../../../../Utils/Global";
import EbloomTooltip from "../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import PropTypes from "prop-types";
import {useUserData} from "../../../../../../hooks/userHook";
import {Badge} from "react-bootstrap";
import {Colors} from "../../../../../../Utils/Colors";

const MessageCard = (props) => {

    //state
    const [isLiked, setIsLiked] = useState(false);
    const {survey,fetchSurveys,isProspect,setShowModal} = props

    //hooks
    const t = useTranslation();
    const user = useUserData()
    //getters

    //posters
    const handleLike = () => {
        const score = isLiked ? 0 : 1;
        HttpApi.post("/collaborative/likeMessage", {id_collab_question:survey.question.id, score:score}).then(() => {
            setIsLiked(!isLiked);
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleArchive = () => {
      HttpApi.post("/collaborative/archiveMessage", {id: survey.id}).then(() => {
        fetchSurveys()
      }).catch(error => {
          notifyError(error);
      })
    }

    //handlers
    const isRunning = survey && survey.userSurveyStatus !== SurveyUsersStatus.ARCHIVED && survey.status === SurveyStatus.RUNNING;

    const displayDate = (survey) => {
        return new Date(survey.start_date);
    }
    //useEffect

    useEffect(() => {
        setIsLiked(survey.question.score === 1);
    }, [survey])

    return (
        <>
            <div className={isRunning ? "w-full p-10 text-contrast-purple rounded-lg " + (survey.userSurveyStatus !== SurveyUsersStatus.UNREAD ? "bg-contrast-blue/[0.1]" : "bg-contrast-blue/[0.3]") : "w-full p-10 text-grey rounded-lg bg-bg-grey/[0.7]"}>
                <div className={"flex justify-between items-center mb-20"}>
                    <div
                        className={"flex items-center " + (isRunning ? "text-contrast-purple" : "text-grey")}>
                        <MessageIcon
                            className={isRunning ? "contrast-purple-icon h-30 w-30 mr-5" : "grey-icon h-30 w-30 mr-5"}/>
                        <span className={" ebloom-title ebloom-very-large-text font-semibold"}>{_.upperFirst(t("webloom." + survey.type)) + " " + moment(displayDate(survey)).locale(getLanguage()).fromNow()}</span>
                        <div className={"mx-10 ebloom-normal-text"}>
                            {
                                !!user.companyGroup && !!survey.id_company && survey.id_company !== user.companyId &&
                                <Badge style={{backgroundColor: user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill className={"text-white "}>{user.companyGroup?.name}</Badge>
                            }
                        </div>
                    </div>
                    {
                        survey.userSurveyStatus !== SurveyUsersStatus.ARCHIVED && isRunning &&
                        <EbloomTooltip text={_.upperFirst(t("core.collaborative.archive"))}>
                            <button onClick={isProspect ? () => setShowModal(true) : handleArchive} className={"flex justify-center items-center w-22 h-22"}>
                                <ArchiveIcon className={"contrast-purple-icon"}/>
                            </button>
                        </EbloomTooltip>
                    }
                </div>

                {
                    survey.image_url &&
                    <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400} className={"my-10"}/>
                }
                {
                  survey.title &&
                  <span className={'font-semibold text-contrast-purple ebloom-large-text ebloom-text'}>{survey.title}</span>
                }
                <p className={'break-words text-line-break'}>
                    {replaceUrlsWithLinks(survey.question.text)}
                </p>
                <div className={"w-full flex justify-between mt-10"}>
                    <div>
                        {
                            survey.attachment_url &&
                            <FileDisplay urlParams={survey.attachment_url}/>
                        }
                    </div>
                   <div>
                       {
                           isRunning &&
                           <LikeIcon className={isLiked ? "purple-icon-fill-stroke h-25 w-25 pointer mt-10" : "purple-icon-stroke h-25 w-25 pointer mt-10"} onClick={isProspect ? () => setShowModal(true) :handleLike}/>
                       }
                   </div>
                </div>
            </div>
            <BubbleIcon className={isRunning ? survey.userSurveyStatus !== SurveyUsersStatus.UNREAD ? "ml-20 contrast-blue-icon low-opacity-icon" : "ml-20 contrast-blue-icon opacity-icon" : "ml-20 grey-icon low-opacity-icon"}/>
        </>
    )
}

MessageCard.propTypes = {
    setShowModal : PropTypes.func.isRequired,
    isProspect : PropTypes.bool.isRequired
}

MessageCard.defaultProps = {}
export default MessageCard