import React from "react";
import DailySurvey from "./DailySurvey";
import DisplayAdminView from "../../../Admin/Layouts/DisplayAdminView";

const DailySurveyPage = (props) => {

    return(
        <DisplayAdminView {...props} menu={"noMenu"}>
            {
                () => (
                    <DailySurvey {...props}/>
                )
            }
        </DisplayAdminView>
    )
}

export default DailySurveyPage;