import React from "react";
import {ReactComponent as EvolutionIcon} from "../../symbols/general/Evolution.svg";
import {ReactComponent as SmileyIcon} from "../../symbols/smileys/smileyHappy.svg";
import {ReactComponent as SmileyGrey} from "../../symbols/smileys/smileyHappyGrey.svg";
import {ReactComponent as SmileyGladWhite} from "../../symbols/smileys/smileyGladWhite.svg";
import {ReactComponent as SmileyGlad} from "../../symbols/smileys/smileyGlad.svg";
import {ReactComponent as SmileyHappy} from "../../symbols/smileys/smileyHappy.svg";
import {ReactComponent as SmileyHappyWhite} from "../../symbols/smileys/smileyHappyWhite.svg";
import {ReactComponent as SmileyNeutral} from "../../symbols/smileys/smileyNeutral.svg";
import {ReactComponent as SmileyNeutralWhite} from "../../symbols/smileys/smileyNeutralWhite.svg";
import {ReactComponent as SmileyUnhappy} from "../../symbols/smileys/smileyUnhappy.svg";
import {ReactComponent as SmileyUnhappyWhite} from "../../symbols/smileys/smileyUnhappyWhite.svg";
import {ReactComponent as SmileySad} from "../../symbols/smileys/smileySad.svg";
import {ReactComponent as SmileySadWhite} from "../../symbols/smileys/smileySadWhite.svg";

import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import {useTranslation} from "react-multi-lang";
import _ from "lodash";
import PropTypes from "prop-types";


const EbloomSwitchHistory = (props) => {
    const {handleChange, selected,score} = props;
    const t = useTranslation()

    const determineSmiley = () => {
        if (score <= 19) {
            if(!selected){
                return <SmileyUnhappy className={" w-25 h-25"} />
            }else{
                return <SmileyUnhappyWhite className={" w-25 h-25"} />
            }

        } else if (score <= 39) {
            if(!selected){
                return <SmileySad className={" w-25 h-25"} />
            }else{
                return <SmileySadWhite className={" w-25 h-25"} />
            }
        } else if (score <= 59) {
            if(!selected){
                return <SmileyNeutral className={" w-25 h-25"} />
            }else{
                return <SmileyNeutralWhite className={" w-25 h-25"} />
            }
        } else if (score <= 79) {
            if(!selected){
                return <SmileyGlad className={" w-25 h-25"} />
            }else{
                return <SmileyGladWhite className={" w-25 h-25"} />
            }
        } else {
            if(!selected){
                return <SmileyHappy className={" w-25 h-25"} />
            }else{
                return <SmileyHappyWhite className={" w-25 h-25"} />
            }
        }
    }


    return (
        <>
            <div className={"h-35 w-85 bg-bg-blue rounded-3xl border-2 border-bg-white flex justify-between items-center p-5 pointer relative"} onClick={handleChange}>
                <div className={`absolute bg-bg-white transition-all duration-100 ${
                    selected ? 'translate-x-41' : 'left-0'
                } h-35 w-35 border-2 border-purple rounded-3xl`}/>
                <div className={"z-10"}>
                    <EbloomTooltip text={_.upperFirst(t("core.details"))}>
                        <div className={"w-25 h-25 z-10"}>
                            {
                                determineSmiley()
                            }
                        </div>
                    </EbloomTooltip>


                </div>
                <div className={"z-10"}>
                    <EbloomTooltip text={_.upperFirst(t("core.evolution"))}>
                        <EvolutionIcon className={"w-25 h-25 z-10 " + (selected ? "purple-icon" : "white-icon")}/>
                    </EbloomTooltip>
                </div>


            </div>
        </>
    )
}

EbloomSwitchHistory.propTypes = {
    score: PropTypes.number.isRequired,
    selected : PropTypes.bool.isRequired,
    handleChange : PropTypes.func.isRequired
}

export default EbloomSwitchHistory