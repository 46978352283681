import React, {useEffect, useState} from "react";
import HttpApi, {Sources} from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";
import {Col, ListGroup, Row, Accordion, Button, Badge} from "react-bootstrap";
import _ from "lodash";
import {Colors} from "../../../Utils/Colors";
import moment from "moment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {useTranslation} from "react-multi-lang/lib";
import * as PropTypes from "prop-types";
import EbloomSearchBar from "../../Core/Modules/Input/EbloomSearchBar";
import TablePagination from "@mui/material/TablePagination/TablePagination";
import EbloomTooltip from "../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import EbloomSmileyProgressBar from "../../Core/Modules/Chart/EbloomSmileyProgressBar";
import EbloomAllSmileyProgressBar from "../../Core/Modules/Chart/EbloomAllSmileyProgressBar";
import CommentTooltip from "../../Core/Modules/Popovers-Tooltips/CommentTooltip";
import CategoryTag from "../../Core/Modules/CategoryDisplay/CategoryTag";
import Login from "../../../login/login";
import LoadingView from "../../Core/Layouts/LoadingView";
import TagList from "../../Core/Modules/Input/TagList";
import CategoryList from "../../Core/Modules/Input/CategoryList";
import {CompanyTypes, getEndDateAccordingToWindow, SurveyQuestionRecipients} from "../../../Utils/Global";
import EbloomLineChart from "../../Core/Modules/Chart/EbloomLineChart";
import EbloomSpeedometerV2 from "../../Core/Modules/Chart/EbloomSpeedometerV2";
import EbloomSwitchHistory from "../../Core/Modules/Button/EbloomSwitchHistory";
import {useBreakpoints} from "../../../hooks/breakpointsHook";
import {ReactComponent as ArrowRight} from "../../Core/symbols/general/ArrowRight.svg";
import {ReactComponent as EvolutionIcon} from "../../Core/symbols/general/Evolution.svg";
import {ReactComponent as FlagGrey} from "../../Core/symbols/flags/flag-grey.svg";
import {useUserData} from "../../../hooks/userHook";
import FlagIcon from "../../Core/Modules/Icons/FlagIcon";
import HandsIcon from "../../Core/Modules/Icons/HandsIcon";
import IconButton from "@mui/material/IconButton/IconButton";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";

const History = (props) => {
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [questionsByCategory, setQuestionByCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [selectedTag, setSelectedTag] = useState(-1);
    const [questions, setQuestions] = useState([]);
    const [feedback, setFeedback] = useState({});
    const [eventKey, setEventKey] = useState(0);
    const [activeKey, setActiveKey] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("date");
    const [displayGraph, setDisplayGraph] = useState(false);
    const [entitiesGroup, setEntitiesGroup] = useState([]);

    const {startDate, endDate, isDashboardManager,teamsSelected, isMobile, isTablet, isLandscapeTablet,companyToEdit,isModelCustomEnable,isTranslationEnable,showTranslation,isSuperAdmin,isAllEntities,isTeamSelected,teamsEntityToDisplay,getGroupSelectedEntities,entitiesDashgroup,colors,commonSurveyActivated,handleIdQuestionModal} = props;

    const t = useTranslation();

    const bp = useBreakpoints()

    const anonymousRestriction = _.upperFirst(t("manager.dashboard.anonymousRestriction"));

    const user = useUserData()

    const isProspect = user.companyType === CompanyTypes.PROSPECT
    const isHappy = Login.hasModule("happy");
    const filterByCategory = (event) => {
        const id = event.target.value;
        const name = event.target.name;
        if(name === "category-filter"){
            setSelectedCategory(id);
            setSelectedTag(-1);
        }else if(name === "tag-filter"){
            setSelectedTag(id);
        }

        if((id < 0 && name === "category-filter" && selectedTag < 0) || (id<0 && name === "tag-filter" && selectedCategory < 0) ){
            setQuestionByCategory(questions);
        }else{
            let array = questions;
            if(name === "category-filter" && id>=0){
                array = array.filter((el) => el.id_category === id);
            }else if(name === "tag-filter"){
                if(selectedCategory >= 0 && id >= 0){
                    array = array.filter((el) => el.id_category === selectedCategory && el.id_tag === id);
                }else if(selectedCategory >= 0 && id < 0){
                    array = array.filter((el) => el.id_category === selectedCategory);
                }else{
                    array = array.filter((el) => el.id_tag === id);
                }
            }
            setQuestionByCategory(array);
            setPage(0);
        }

    };

    const linkQuestions = (list) => {
        if(list && list.length > 0){
            list.forEach(q => {
                q.otherQuestions = list.filter(el =>( el.id === q.id)).sort((a,b) => { return new Date(b.date) -  new Date(a.date)})
            })
            return list
        }else{
            return []
        }
    }

    const fetchQuestions = () => {
        setLoading(true);
        let url = "/questions/getHistory/0/"+startDate+"/"+endDate+"/"+commonSurveyActivated
        if(isDashboardManager){
            let params = props.teamToDisplay ? props.teamToDisplay.map(el => String(el.id)).join('-') : '0'
            params = params.length > 0 ? params : '0'
            url = "/questions/getHistory/"+params+"/"+startDate+"/"+endDate+"/"+commonSurveyActivated
            if(isSuperAdmin){
                url = "/questions/group/getHistory/"+ (companyToEdit ? companyToEdit.id : user.companyGroup.id) + "/"+getGroupSelectedEntities()+"/"+startDate+"/"+endDate
            }
            if(isAllEntities){
                url = "/questions/entities/getHistory/" +(companyToEdit ? companyToEdit.id : user.companyGroup.id)+ "/" +props.teamToDisplay[0].id +"/0/"+startDate+"/"+endDate+"/"+commonSurveyActivated
                if(isTeamSelected){
                    const teamsId = teamsEntityToDisplay.map(el => String(el.id)).join('-')
                    url = "/questions/entities/getHistory/"+(companyToEdit ? companyToEdit.id : user.companyGroup.id)+ "/" + props.teamToDisplay[0].id + "/"+ teamsId +"/"+startDate+"/"+endDate+"/"+commonSurveyActivated
                }
            }
        }
        HttpApi.getV2(url, Sources.HISTORY).then(response => {
            if(response && response.data){
                const list = linkQuestions(response.data)
                setQuestions(list);
                setQuestionByCategory(list);
                setSelectedTag(-1)
                setSelectedCategory(-1)
                filterBySearch(list, searchValue);
            }else{
                setQuestions([]);
                setQuestionByCategory([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoading(false));
    };

    const fetchMainCategories = () => {
        let url ="/categories/getMainCategories"
        if(isSuperAdmin){
           url = "/categories/getMainCategories/"+user.companyGroup.id
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                response.data.splice(0,0, {id:-1, name:_.capitalize(t("core.all"))});
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error)
        })
    };

    const fetchTags = () => {
        let url = "/tags/getAll"
        if(isSuperAdmin){
            url = "/tags/getAll/"+user.companyGroup.id
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                response.data.splice(0,0, {id:-1, name:_.capitalize(t("core.allBis")),id_category:-1});
                setTags(response.data);
            }else{
                setTags([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchEntities = () => {
        if(companyToEdit){
            const url = "/companies/findAllEntitiesFromGroup/"+ companyToEdit.id
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setEntitiesGroup(response.data)
                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            if(user.companyGroup && user.companyGroup.id){
                setEntitiesGroup(user.companyGroup.companies_entities.map(el => {return {id: el.id, name : el.name}}))
            }else {
                setEntitiesGroup([])
            }

        }
    }


    const handleChart = () => {
        setDisplayGraph(!displayGraph)
    }

    const handleChange = event => {
        setSearchValue(event.target.value);
        if(page > 0){
            setPage(0);
        }
        filterBySearch(questionsByCategory, event.target.value);
    };


    const handleAccordionToggle = (id) => event => {
        if(id !== eventKey){
            setDisplayGraph(false)
            setEventKey(id);
            setActiveKey(id);
            let url = "/feedbacks/getScoreForQuestion/" + id+"/0";
            if(isDashboardManager){
                const params = props.teamToDisplay ? props.teamToDisplay.map(el => String(el.id)).join('-') : '0'
                if(isSuperAdmin || isAllEntities){
                    if(isAllEntities && isTeamSelected){
                        const teamsId = teamsEntityToDisplay.map(el => String(el.id)).join('-')
                        url = "/feedbacks/getScoreForQuestion/"+ id + "/"+teamsId
                    }else {
                        url = "/feedbacks/group/getScoreForQuestion/" + id + "/"+getGroupSelectedEntities()
                    }
                }else{
                    url = "/feedbacks/getScoreForQuestion/" + id+"/"+params;
                }

            }
            HttpApi.getV2(url).then(response => {
                if(response && response.data ){
                    setFeedback(response.data);
                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            setDisplayGraph(false)
            setEventKey(0);
            setActiveKey(0);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const collectDataGraph = (list) => {
        const result = []
        const data = {dataGraph : [], avgScore : 0}
        let avgScore = 0
        let total = 0
        if(list && list.length > 0){
            list.sort((a,b) => { return new Date(a.date) -  new Date(b.date)}).forEach(q => {
                if(q.nbFeedback >= 5){
                    const score = q.score > 0 ? Math.round(q.score * 25) : q.score;
                    avgScore += score
                    total ++
                    const value = {date: moment(q.date).format("DD/MM/YY") ,score : score}
                    result.push(value)
                }
            })
            avgScore = Math.round((avgScore/total) )
            data.avgScore = avgScore
            data.dataGraph = result
        }
        return data
    }

    const filterBySearch = (array, value) => {
        if(orderBy){
            array.sort(getComparator(order, orderBy))
        }

        if(!value){
            return questionsByCategory;
        }else if(value.includes("::")){
            const label = _.lowerCase(value.split("::")[0]);
            const data = _.lowerCase(value.split("::")[1]);

            array = array.filter((el) => (_.lowerCase(el[label]).includes(data)));

            return array;

        }else{
            const val = _.lowerCase(value);
            array = array.filter((el) => (_.lowerCase(el.text).includes(val) || _.lowerCase(el.category).includes(val) || _.lowerCase(moment(el.date).format("YYYY/MM/DD")).includes(val) || _.lowerCase(Math.round(el.score*25).toString()).includes(val)));
            return array;
        }
    };

    const handleChangeOrderBy = (type) => event => {
        event.preventDefault();
        if(orderBy === type){
            setOrder(order === "desc" ? "asc" : "desc");
        }else{
            setOrderBy(type);
            setOrder("desc");
        }
        if(page > 0){
            setPage(0);
        }
    }

    const descendingComparator = (a, b, orderBy) => {
        if(orderBy === "score"){
            if(b[orderBy] === a[orderBy]){
                return 0;
            }
            if(b[orderBy] === -1){
                return -1;
            }
        }
        if(orderBy === "comments"){
            if(b[orderBy] === a[orderBy]){
                return 0;
            }
            if(b[orderBy] === null){
                return -1;
            }
            if(a[orderBy] === null){
                return 1;
            }
            if(b[orderBy] === -1){
                return -1;
            }
            if (b[orderBy].length < a[orderBy].length) {
                return -1;
            }
            if (b[orderBy].length > a[orderBy].length) {
                return 1;
            }
        }
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const ascendingComparator = (a, b, orderBy) => {
        if(orderBy === "score"){
            if(b[orderBy] === a[orderBy]){
                return 0;
            }
            if(b[orderBy] === -1){
                return -1;
            }
            if(a[orderBy] === -1){
                return 1;
            }
        }
        if(orderBy === "comments"){
            if(b[orderBy] === a[orderBy]){
                return 0;
            }
            if(b[orderBy] === null){
                return 1;
            }
            if(a[orderBy] === null){
                return -1;
            }
            if(b[orderBy] === -1){
                return -1;
            }
            if(a[orderBy] === -1){
                return 1;
            }
            if (b[orderBy].length < a[orderBy].length) {
                return 1;
            }
            if (b[orderBy].length > a[orderBy].length) {
                return -1;
            }
        }
        if(orderBy === "nbFlags"){
            if(b[orderBy] === a[orderBy]){
                return 0;
            }
            if(b[orderBy] === -1){
                return -1;
            }
            if(a[orderBy] === -1){
                return 1;
            }
            if (b[orderBy] < a[orderBy]) {
                return 1;
            }
            if (b[orderBy] > a[orderBy]) {
                return -1;
            }
        }
        if (b[orderBy] < a[orderBy]) {
            return 1;
        }
        if (b[orderBy] > a[orderBy]) {
            return -1;
        }

        return 0;
    }


    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => ascendingComparator(a, b, orderBy);
    }

    const displayDate = (date) => {
        return moment(date, "DD/MM/YY").format("DD/MM/YY");
    }

    useEffect(() => {
        fetchMainCategories();
        fetchTags();
    }, []);

    useEffect(() => {
        setDisplayGraph(false)
        setEventKey(0);
        setActiveKey(0);
        if(Login.hasRole("global_backoffice") && props.companyToEdit !== null  && props.timeFilterVersion !== null){
            fetchQuestions();
        }else if(props.teamToDisplay !== null && props.teamToDisplay && props.teamToDisplay.length > 0 && props.timeFilterVersion !== null){
            fetchQuestions();
        }else if((!isDashboardManager || Login.hasModule("happy")) && props.timeFilterVersion !== null){
            fetchQuestions();
        }
        if(Login.hasOneOfRole(["global_backoffice","global_super_admin"])){
            fetchEntities()
        }
    }, [props.companyToEdit, props.teamToDisplay, props.timeFilterVersion,teamsEntityToDisplay,entitiesDashgroup,commonSurveyActivated])



    return(
        <div style={{marginBottom:20, marginTop:30}}>
            <Row className={"grid grid-cols-5 px-16 gap-y-20 mb-30"}>
                <div className={ bp.isTabletOrLess() ? "col-span-full" : "col-start-2"}>
                    <CategoryList isModelCustomEnable={isModelCustomEnable} categories={categories} handleChange={filterByCategory} label={_.capitalize(t("core.category.categories"))} value={selectedCategory} color={"blue"}/>
                </div>
                <div className={ bp.isTabletOrLess() ? "col-span-full" : "col-start-3"}>
                    <TagList isModelCustomEnable={isModelCustomEnable} tags={tags} value={selectedTag} id_category={selectedCategory} handleChange={filterByCategory} color={"purple"}/>
                </div>
                <div className={ bp.isTabletOrLess() ? "col-span-full" : "col-start-4"}>
                    <EbloomSearchBar handleChange={handleChange} searchValue={searchValue} size={"small"} color={"blue"}/>
                </div>
            </Row>


            {
                questionsByCategory.length > 0 &&
                <div>
                    {
                        (!bp.isTabletOrLess()) &&
                        <Row className={"mt10 font-bold ml-20 mr-10"}>
                            <Col md={1} style={{textAlign:"left", cursor:"pointer", paddingLeft:0, color: colors ? colors.primary :Colors.EBLOOM_CONTRAST_PURPLE}} onClick={handleChangeOrderBy("date")}>
                                {_.upperFirst(t("core.date"))}
                                {
                                    orderBy !== "date" ?
                                        <div style={{display:"inline-block"}}><ArrowDropDownIcon style={{fontSize:32, marginTop:-7, color:colors ? colors.secondary :Colors.EBLOOM_PURPLE}}/><ArrowDropUpIcon style={{fontSize:32, marginLeft:-20, marginRight:-40, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/></div>
                                        :
                                        orderBy === "date" && order === "desc" ?
                                            <ArrowDropDownIcon style={{fontSize:32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                            :
                                            <ArrowDropUpIcon style={{fontSize: 32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                }</Col>
                            <Col md={{span:2, offset:7}} className={"text-left pl-23"} style={{cursor:"pointer", color: colors ? colors.primary :Colors.EBLOOM_CONTRAST_PURPLE}} onClick={handleChangeOrderBy("score")}>
                                Score
                                {
                                    orderBy !== "score" ?
                                        <div style={{display:"inline-block"}}><ArrowDropDownIcon style={{fontSize:32, marginTop:-7, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/><ArrowDropUpIcon style={{fontSize:32, marginLeft:-20, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/></div>
                                        :
                                        orderBy === "score" && order === "desc" ?
                                            <ArrowDropDownIcon style={{fontSize:32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                            :
                                            <ArrowDropUpIcon style={{fontSize: 32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                }
                            </Col>
                            <Col md={1} className={"text-center"} style={{cursor:"pointer", color:colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE, paddingRight:1, paddingLeft:0}} onClick={handleChangeOrderBy("comments")}>
                                Com.
                                {
                                    orderBy !== "comments" ?
                                        <div style={{display:"inline-block"}}><ArrowDropDownIcon style={{fontSize:32, marginTop:-7, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/><ArrowDropUpIcon style={{fontSize:32, marginLeft:-20, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/></div>
                                        :
                                        orderBy === "comments" && order === "desc" ?
                                            <ArrowDropDownIcon style={{fontSize:32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                            :
                                            <ArrowDropUpIcon style={{fontSize: 32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                }
                            </Col>
                            <Col md={1} style={{cursor:"pointer", color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE, paddingRight:1, paddingLeft:0}} onClick={handleChangeOrderBy(isDashboardManager ? "nbFlags" : "id_flag")}>
                                <EbloomTooltip text={_.upperFirst(t("manager.dashboard.flagHelper"))} placement={"top"}>
                                    <div >
                                        Flags
                                        {
                                            orderBy !== "id_flag" && orderBy !== "nbFlags" ?
                                                <div style={{display:"inline-block"}}><ArrowDropDownIcon style={{fontSize:32, marginTop:-7, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/><ArrowDropUpIcon style={{fontSize:32, marginLeft:-20, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/></div>
                                                :
                                                (orderBy === "id_flag" || orderBy === "nbFlags") && order === "desc" ?
                                                    <ArrowDropDownIcon style={{fontSize:32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                                    :
                                                    <ArrowDropUpIcon style={{fontSize: 32, color: colors ? colors.secondary : Colors.EBLOOM_PURPLE}}/>
                                        }
                                    </div>

                                </EbloomTooltip>

                            </Col>


                        </Row>
                    }

                    {loading ?
                        <LoadingView/>
                        :
                        <Accordion activeKey={activeKey} style={{marginTop: isMobile ? 20 : 0}}>
                            <ListGroup variant={"flush"}>
                                {
                                    filterBySearch(questionsByCategory, searchValue).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((question, index) => {
                                        const date = moment(question.date).format("DD/MM/YY");
                                        const score = question.score > 0 ? Math.round(question.score * 25) : question.score;
                                        const anonymityRestriction = isDashboardManager && question.nbFeedback < 5;
                                        const results = collectDataGraph(question.otherQuestions)
                                        const data = results.dataGraph
                                        const avgScore = results.avgScore
                                        const accessEvolution = question.nbFeedback < 5 || data.length <= 0 ? false : question.otherQuestions.filter(q =>  q.nbFeedback > 4 && q.id_survey_question !== question.id_survey_question).length >= 1 && data.length > 0
                                        return (
                                            <ListGroup.Item key={index} style={{
                                                border: activeKey === question.id_survey_question ? "1px dashed " + (colors ? colors.quinary : Colors.EBLOOM_LOGO_BLUE) : "none",
                                                backgroundColor : activeKey === question.id_survey_question ? (colors ? colors.background : Colors.EBLOOM_WHITE_BLUE) : "white",
                                                borderRadius: 6
                                            }}>
                                                <Accordion.Button bsPrefix={"accordion"} action="true" as={"div"}
                                                                  style={{cursor: !anonymityRestriction && isDashboardManager ? "pointer" : "default"}}
                                                                  eventKey={question.id_survey_question}>
                                                    <Row>
                                                        <Col md={1} xs={4} style={{paddingRight: 2, paddingLeft:(isTablet || isLandscapeTablet) ? 0 : 15}}
                                                             onClick={!anonymityRestriction && isDashboardManager ? handleAccordionToggle(question.id_survey_question) : () => {
                                                             }}>
                                                                <span className={"ebloom-normal-text"} style={{
                                                                    fontWeight: "bold",
                                                                    color: colors ? colors.quinary : Colors.EBLOOM_LOGO_BLUE
                                                                }}>
                                                                    {
                                                                        (moment(date, "DD/MM/YY").isAfter(moment("08/05/2022", "DD/MM/YYYY"))) ?
                                                                            <EbloomTooltip text={_.upperFirst(t("core.notification.openFromTo", {param1:displayDate(date), param2:displayDate(getEndDateAccordingToWindow(question.date, question.opening_time))}))}>
                                                                                <span>{date}</span>
                                                                            </EbloomTooltip>
                                                                            :
                                                                            <span>{date}</span>
                                                                    }
                                                                </span>
                                                        </Col>
                                                        {
                                                            isMobile &&
                                                            <Col xs={4}>
                                                                <CategoryTag modelCustomEnable={isModelCustomEnable} category={question}
                                                                             width={150}/>
                                                            </Col>
                                                        }
                                                        <Col md={(isSuperAdmin && (!isAllEntities && getGroupSelectedEntities() === "0")) || (!isProspect && questionsByCategory.filter(el => el.recipients === SurveyQuestionRecipients.TEAMS).length > 0 && !commonSurveyActivated && teamsSelected ) ? 4 : 5} style={{
                                                            textAlign: "left",
                                                            marginTop: isMobile ? 10 : 0,
                                                            marginBottom: isMobile ? 10 : 0
                                                        }}
                                                             >
                                                            <div>
                                                                <span onClick={!anonymityRestriction && isDashboardManager ? handleAccordionToggle(question.id_survey_question) : () => {
                                                                }}>{question.text}</span>
                                                                {
                                                                    !isHappy && question?.enableIdentityVersion === true &&
                                                                    <IconButton className={"text-purple ml-5"} onClick={() => handleIdQuestionModal(question.id_survey_question,true)} >
                                                                        <TypeSpecimenIcon/>
                                                                    </IconButton>

                                                                }
                                                            </div>

                                                        </Col>
                                                        {
                                                            !isProspect && questionsByCategory.filter(el => el.recipients === SurveyQuestionRecipients.TEAMS).length > 0 && !commonSurveyActivated && teamsSelected  &&
                                                            <Col md={1}>
                                                                <div >
                                                                {
                                                                    question.survey_teams?.length > 0 &&
                                                                        <div className={"cursor-default"}>
                                                                            <EbloomTooltip text={question.survey_teams.map(el => el.team.name).join('\n')}>
                                                                                <Badge style={{backgroundColor : user.companyGroup && user.companyGroup.colors ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill
                                                                                       className={"text-white"}>
                                                                                    {question.survey_teams.length + " " +  (question.survey_teams.length > 1 ? _.lowerFirst(t("admin.teams")) :  _.lowerFirst(t("admin.team")))}
                                                                                </Badge>
                                                                            </EbloomTooltip>
                                                                        </div>
                                                                }
                                                                </div>
                                                            </Col>
                                                        }

                                                        {
                                                            isSuperAdmin && (!isAllEntities && getGroupSelectedEntities() === "0") &&
                                                            <Col md={1}>
                                                                {
                                                                    isAllEntities ?
                                                                        <Badge pill className={"text-white bg-purple"}>{question.company_name}</Badge>
                                                                        :
                                                                        question.survey_entities.length === 1 ?
                                                                            <div className={"mb-5 cursor-default"}>
                                                                                <Badge style={{backgroundColor : user.companyGroup && user.companyGroup.colors ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill
                                                                                       className={"text-white"}>
                                                                                    {question.survey_entities[0].company.name}
                                                                                </Badge>
                                                                            </div>
                                                                            :
                                                                            <div
                                                                                className={"mb-5 cursor-default"}>
                                                                                <EbloomTooltip
                                                                                    text={question.survey_entities.map(el => el.company.name).join('\n')}>
                                                                                    <Badge style={{backgroundColor : user.companyGroup && user.companyGroup.colors ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill
                                                                                           className={"text-white"}>
                                                                                        {question.survey_entities.length + " " +  _.lowerFirst(t("core.entities"))}
                                                                                    </Badge>
                                                                                </EbloomTooltip>
                                                                            </div>
                                                                }
                                                            </Col>
                                                        }
                                                        {
                                                            !isMobile &&
                                                            <Col md={2} style={{textAlign: "center"}}
                                                                 onClick={!anonymityRestriction && isDashboardManager ? handleAccordionToggle(question.id_survey_question) : () => {
                                                                 }}>
                                                                <CategoryTag modelCustomEnable={isModelCustomEnable} category={question}
                                                                             width={isTablet ? 90 : 150}/>
                                                            </Col>
                                                        }

                                                        <Col md={2} xs={6}  style={{textAlign: "center",padding:0}}
                                                             onClick={!anonymityRestriction && isDashboardManager ? handleAccordionToggle(question.id_survey_question) : () => {
                                                             }}>
                                                            {
                                                                (isDashboardManager && question.nbFeedback < 5) ?
                                                                    <Row
                                                                        style={{marginLeft: isMobile ? 10 : 0}}>
                                                                        <EbloomSmileyProgressBar avg={-1}
                                                                                                 staticSmiley={false}
                                                                                                 barWidth={isMobile ? 100 : isTablet ? 120 : 150}
                                                                                                 showSmiley={false}
                                                                                                 showLegendPercent={false}
                                                                                                 legendFontSize={16}
                                                                                                 legendFontFamily={"Poppins"}/>
                                                                    </Row>
                                                                    :
                                                                    <Row
                                                                        style={{marginLeft: isMobile ? 10 : 0}}>
                                                                        <EbloomSmileyProgressBar avg={score}
                                                                                                 staticSmiley={false}
                                                                                                 barWidth={isMobile ? 100 : isTablet ? 120 : 150}
                                                                                                 showSmiley={false}
                                                                                                 showLegendPercent={false}
                                                                                                 legendFontSize={16}
                                                                                                 legendFontFamily={"Poppins"}/>
                                                                    </Row>
                                                            }
                                                        </Col>
                                                        <Col md={1} xs={3} style={{textAlign: "center"}}>
                                                            {
                                                                (isDashboardManager && question.nbFeedback < 5) ?
                                                                    <EbloomTooltip text={anonymousRestriction}>
                                                                        <div
                                                                            className={"dialog_icon_grey micro_icon inline_icon mr-15"}/>
                                                                    </EbloomTooltip>
                                                                    :
                                                                    <CommentTooltip
                                                                        comments={isDashboardManager ? question.comments : question.comment ? [question.comment] : null} isDashboardManager={isDashboardManager} isTranslationEnable={isTranslationEnable} showTranslation={showTranslation} colors={colors}/>
                                                            }

                                                        </Col>
                                                        {
                                                            !isDashboardManager ?
                                                                <Col md={1} xs={3}
                                                                     style={{textAlign: "center"}}>
                                                                    {(question.id_flag) &&
                                                                        <div
                                                                            className={"flag_contrast micro_icon inline_icon"}/>
                                                                    }
                                                                </Col>
                                                                :
                                                                <Col md={1} xs={3}
                                                                     onClick={!anonymityRestriction && isDashboardManager ? handleAccordionToggle(question.id_survey_question) : () => {
                                                                     }} style={{paddingRight: 0,paddingLeft:8}}>
                                                                    {!anonymityRestriction &&
                                                                        <div className={"mt-4"} style={{
                                                                            display: "inline-block",

                                                                        }}>
                                                                            <FlagIcon
                                                                                colors={colors}
                                                                                width={21} height={21}/>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !anonymityRestriction ?
                                                                            <div className={"ebloom-normal-text"}
                                                                                 style={{
                                                                                     display: "inline-block",
                                                                                     position: "relative",
                                                                                     bottom: 7,
                                                                                     left: 5,
                                                                                     color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
                                                                                     fontWeight: "bold"
                                                                                 }}>{question.nbFlags}<span className={"text-xs"}>%</span></div>
                                                                            : <EbloomTooltip text={anonymousRestriction}>
                                                                                <FlagGrey className={"w-23 h-23"}/>
                                                                            </EbloomTooltip>
                                                                    }
                                                                    {!anonymityRestriction &&
                                                                    activeKey === question.id_survey_question ?
                                                                        <ArrowDropUpIcon style={{
                                                                            color:  colors ? colors.secondary : Colors.EBLOOM_PURPLE,
                                                                            fontSize: 32,
                                                                            float: "right"
                                                                        }}/>
                                                                        : !anonymityRestriction &&
                                                                        <ArrowDropDownIcon style={{
                                                                            color:  colors ? colors.secondary : Colors.EBLOOM_PURPLE,
                                                                            fontSize: 32,
                                                                            float: "right"
                                                                        }}/>
                                                                    }
                                                                </Col>
                                                        }
                                                    </Row>
                                                </Accordion.Button>
                                                <Accordion.Collapse eventKey={question.id_survey_question}>
                                                    <div>
                                                        {
                                                            accessEvolution &&
                                                            <div className={"grid grid-cols-12"}>
                                                                <div className={"col-start-9 col-span-2 flex justify-center items-center"}>
                                                                    <EbloomSwitchHistory score={score} handleChange={handleChart} selected={displayGraph} />
                                                                </div>
                                                            </div>
                                                        }


                                                        {
                                                            displayGraph ?
                                                                <div className={"mt-20"}>
                                                                    <Row>

                                                                        <Col className={"flex justify-center items-center flex-col"} md={5}>

                                                                            <div className={"w-full h-200 "}>
                                                                                <EbloomLineChart data={data} color={ colors ? colors.primary : Colors.EBLOOM_PURPLE} dataKeyX={"date"} dataKeyY={"score"} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col className={"flex justify-center items-center flex-col relative " + (!bp.isTabletOrLess() && "border-contrast-purple border-r-[1px] ") } md={{span:2, offset:0}}>
                                                                            {
                                                                                !bp.isTabletOrLess() &&
                                                                                <div className={"absolute top-1/2 right-0"}>
                                                                                    <div className={"rotate-180"}>
                                                                                        <ArrowRight className={"contrast-purple-icon"} />
                                                                                    </div>

                                                                                </div>

                                                                            }

                                                                            <h1 className={"ebloom-large-text font-semibold text-contrast-purple"}>{_.upperFirst(t("core.avgScore"))}</h1>
                                                                            <EbloomSpeedometerV2 score={avgScore} width={170} showLegend={false} />
                                                                        </Col>
                                                                        <Col md={{offset:0}} >
                                                                            {
                                                                                question.otherQuestions.sort((a,b) => { return new Date(b.date) -  new Date(a.date)}).map((q,index) => {
                                                                                    const date = moment(q.date).format("DD/MM/YY");
                                                                                    const score = q.score > 0 ? Math.round(q.score * 25) : q.score;
                                                                                    return <Row className={"flex items-center"}>
                                                                                        <Col md={3} xs={3} className={"text-center cursor-default"}>
                                                                                            {(moment(date, "DD/MM/YY").isAfter(moment("08/05/2022", "DD/MM/YYYY"))) ?
                                                                                                <EbloomTooltip text={_.upperFirst(t("core.notification.openFromTo", {param1:displayDate(date), param2:displayDate(getEndDateAccordingToWindow(q.date, q.opening_time))}))}>
                                                                                                    <span className={"ebloom-normal-text text-logo-blue " + (q.id_survey_question === question.id_survey_question && "font-bold")}>{date}</span>
                                                                                                </EbloomTooltip>
                                                                                                :
                                                                                                <span className={"ebloom-normal-text text-logo-blue"}>{date}</span>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col md={5} xs={4} className={"pl-0"}>
                                                                                            <div className={"flex justify-start items-center text-sm"}>
                                                                                                <EbloomSmileyProgressBar avg={score}
                                                                                                                         staticSmiley={false}
                                                                                                                         barWidth={100}
                                                                                                                         showSmiley={false}
                                                                                                                         showLegendPercent={true}
                                                                                                                         legendFontSize={12}
                                                                                                                         barHeight={8}
                                                                                                                         legendFontFamily={"Poppins"}/>
                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col md={2} xs={2} className={"flex items-center justify-center"} >
                                                                                            {
                                                                                                (isDashboardManager && q.nbFeedback < 5) ?
                                                                                                    <EbloomTooltip text={anonymousRestriction}>
                                                                                                        <div className={"dialog_icon_grey micro_icon inline_icon mr-15 my-auto"}/>
                                                                                                    </EbloomTooltip>
                                                                                                    :
                                                                                                    <CommentTooltip
                                                                                                        comments={isDashboardManager ? q.comments : question.comment ? [question.comment] : null} isDashboardManager={isDashboardManager} isTranslationEnable={isTranslationEnable} showTranslation={showTranslation} colors={colors}/>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col md={2} xs={3} className={"flex items-center justify-start"}>

                                                                                            {
                                                                                                ((!(q.nbFeedback < 5) || q.nbFlags >= 0)) ?
                                                                                                    <div
                                                                                                        className={"flex items-center justify-center"}>
                                                                                                        <div
                                                                                                            className={"mt-4"}
                                                                                                            style={{
                                                                                                                display: "inline-block",

                                                                                                            }}>
                                                                                                            <FlagIcon
                                                                                                                colors={colors}
                                                                                                                width={21}
                                                                                                                height={21}/>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={"ebloom-normal-text"}
                                                                                                            style={{
                                                                                                                display: "inline-block",
                                                                                                                position: "relative",
                                                                                                                left: 5,
                                                                                                                color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
                                                                                                                fontWeight: "bold"
                                                                                                            }}>{q.nbFlags}<span
                                                                                                            className={"text-xs"}>%</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    : <EbloomTooltip
                                                                                                        text={anonymousRestriction}>
                                                                                                        <FlagGrey
                                                                                                            className={"w-24 h-24 ml-1"}/>
                                                                                                    </EbloomTooltip>

                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                                : !bp.isTabletOrLess() ?
                                                                    <Row className={"mt-20"}>
                                                                        <Col className={"flex justify-center items-center"} md={5}>
                                                                            {accessEvolution &&
                                                                                <div className={"w-full h-200 relative"}>
                                                                                    <Button onClick={handleChart} className={"ebloom-btn-purple w-180 h-38 flex justify-around items-center absolute left-0 right-0 mx-auto my-auto top-0 bottom-0 z-20"}>
                                                                                        <p>{_.capitalize(t("manager.dashboard.seeEvolution"))}</p>
                                                                                        <EvolutionIcon/>
                                                                                    </Button>
                                                                                    <div className={"w-full h-full opacity-40"}>
                                                                                        <EbloomLineChart data={[{value: ''}]}  disabled={true} />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </Col>

                                                                        <Col className={"flex justify-center items-center border-r-[1px] border-contrast-purple"} md={{span: 1,offset:2}}>
                                                                            <div className={"flex justify-around items-end w-full"}>
                                                                                <HandsIcon
                                                                                    colors={ colors }
                                                                                    width={25} height={25}/>
                                                                                <div style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE }} className={"ebloom-normal-text "}>{feedback.nbResponse}</div>
                                                                            </div>


                                                                        </Col>
                                                                        <Col md={{span: 3}} className={"flex items-center justify-start"}>
                                                                            {
                                                                                (isDashboardManager && feedback.nbResponse < 5) ?
                                                                                    <p>{anonymousRestriction}</p>
                                                                                    :
                                                                                    <div>
                                                                                        <EbloomAllSmileyProgressBar
                                                                                            nbResponse0={feedback.nbResponse0}
                                                                                            nbResponse1={feedback.nbResponse1}
                                                                                            nbResponse2={feedback.nbResponse2}
                                                                                            nbResponse3={feedback.nbResponse3}
                                                                                            nbResponse4={feedback.nbResponse4}
                                                                                            nbResponse5={feedback.nbResponse5}
                                                                                            total={feedback.nbResponse}
                                                                                            showRepartition barWidth={100}
                                                                                            barHeight={8}/>
                                                                                    </div>

                                                                            }
                                                                        </Col>

                                                                    </Row>
                                                                    : <Row className={"mt-20"}>
                                                                        <Col className={"flex justify-center items-center"} xs={12} >
                                                                            {accessEvolution &&
                                                                                <Button onClick={handleChart} className={"ebloom-btn-purple w-180 flex justify-around items-center"}>
                                                                                    <p>{_.capitalize(t("manager.dashboard.seeEvolution"))}</p>
                                                                                    <EvolutionIcon/>
                                                                                </Button>
                                                                            }
                                                                        </Col>
                                                                        <Col className={"flex justify-center items-center divide-x-[1px] divide-contrast-purple "} xs={12}>
                                                                            <div className={"flex justify-around items-end w-full"}>
                                                                                <div
                                                                                    className={"hands_icon very_small_smile inline_icon"}
                                                                                />
                                                                                <div className={"ebloom-normal-text text-contrast-purple inline_icon"}>{feedback.nbResponse}</div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    (isDashboardManager && feedback.nbResponse < 5) ?
                                                                                        <p>{anonymousRestriction}</p>
                                                                                        :
                                                                                        <EbloomAllSmileyProgressBar
                                                                                            nbResponse0={feedback.nbResponse0}
                                                                                            nbResponse1={feedback.nbResponse1}
                                                                                            nbResponse2={feedback.nbResponse2}
                                                                                            nbResponse3={feedback.nbResponse3}
                                                                                            nbResponse4={feedback.nbResponse4}
                                                                                            nbResponse5={feedback.nbResponse5}
                                                                                            total={feedback.nbResponse}
                                                                                            showRepartition barWidth={100}
                                                                                            barHeight={8}/>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                        }
                                                    </div>
                                                </Accordion.Collapse>
                                            </ListGroup.Item>

                                        )
                                    })
                                }
                            </ListGroup>
                        </Accordion>
                    }
                    <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                     component="div"
                                     labelRowsPerPage={_.capitalize(t("core.rowsPerPage"))}
                                     labelDisplayedRows={({ from, to, count }) => { return from +"-" + to + " " + t("core.of") + " " + count}}
                                     count={!searchValue ? questionsByCategory.length : questionsByCategory.filter((el) => (_.lowerCase(el.text).includes(searchValue) || _.lowerCase(el.category).includes(searchValue) || _.lowerCase(moment(el.date).format("YYYY/MM/DD")).includes(searchValue) || _.lowerCase(Math.round(el.score*25).toString()).includes(searchValue))).length
                                     }
                                     page={page}
                                     onPageChange={handleChangePage}
                                     rowsPerPage={rowsPerPage}
                                     onRowsPerPageChange={handleChangeRowsPerPage}/>
                </div>
            }
        </div>
    )


}

History.propTypes = {
    isDashboardManager: PropTypes.bool,
    isMobile:PropTypes.bool,
    isModelCustomEnable:PropTypes.bool,
    isTranslationEnable: PropTypes.bool,
    showTranslation: PropTypes.bool,
    isSuperAdmin:PropTypes.bool,
    isAllEntities:PropTypes.bool,
    isTeamSelected:PropTypes.bool,
    teamsEntityToDisplay:PropTypes.array,
    entitiesDashgroup:PropTypes.array,
    getGroupSelectedEntities:PropTypes.func,
    colors : PropTypes.object,
    commonSurveyActivated : PropTypes.bool.isRequired,
    teamsSelected : PropTypes.bool,
};

History.defaultProps = {
    isDashboardManager:false,
    companyToEdit:null,
    startDate:moment("1996-05-15").format("YYYY-MM-DD"),
    endDate:moment().format("YYYY-MM-DD"),
    isMobile:false,
    isModelCustomEnable:false,
    isTranslationEnable:false,
    showTranslation:false,
    isSuperAdmin : false,
    isAllEntities : false,
    isTeamSelected:false,
    teamsEntityToDisplay:[],
    entitiesDashgroup:[],
    colors: null,
    teamsSelected : false,
    getGroupSelectedEntities : () => {return 0}
};

export default History;