import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Team from "../../../../Admin/Pages/Team/Team";



const TeamView = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <Team companyToEdit={companyToEdit} {...props}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default TeamView;


