import React, {useContext} from "react";
import {Card, Badge, Button} from "react-bootstrap"
import _ from "lodash";
import PropTypes from "prop-types";
import {Colors} from "../../../../Utils/Colors";
import {useTranslation} from "react-multi-lang";
import EbloomInfoTooltip from "../Popovers-Tooltips/EbloomInfoTooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import {TourType} from "../../../../Utils/Global";


const CardDefault = (props) => {

    const {showHeader, showBadge, showButton, showExtraRightButton, showLeftButton, subtitle, title, titleColor, titlePosition, backgroundColor, badgeContent, badgeVariant, border, buttonText, extraRightButtonText, extraRightButtonVariant, handleExtraRightButton, handleLeftButton, handleRightButton, leftButtonText, leftButtonVariant, rightButtonVariant, showSecondExtraRightButton, secondExtraRightButtonVariant, secondExtraRightButtonText, handleSecondExtraRightButton, tourDataset,tourTooltip,tourType} = props;

    const t = useTranslation();

    const{icon,tourActivated, handlersTour,stepTour} = useContext(ProductTourContext)


    const toolTipProductTour = () => {
        if(tourActivated && tourTooltip){
            if(!stepTour[TourType.INVITE].outcome && [TourType.MANAGE_USERS,TourType.MANAGE_TEAMS,TourType.DEFINE_ROLES].includes(tourType)){
                if(subtitle){
                    return (<div className={"ml-5"}>
                        <EbloomInfoTooltip text={subtitle+ "\n\n" + _.upperFirst(t('admin.productTour.finishInvite'))}/>
                    </div>)
                }else{
                    return (<div className={"ml-5"}>
                        <EbloomInfoTooltip text={_.upperFirst(t('admin.productTour.finishInvite'))}/>
                    </div>)
                }
            }else{
                if(tourTooltip && subtitle){
                    return (
                        <div className={"ml-5"}>
                            <IconButton onClick={handlersTour[tourType]} className={'cursor-pointer'} ref={icon}>
                                <EbloomInfoTooltip text={subtitle+ "\n\n" + _.upperFirst(t('admin.productTour.launch'))}/>
                            </IconButton>
                        </div>
                    )
                }else if (!subtitle && tourTooltip){
                    return (<div className={"ml-5"}>
                        <IconButton onClick={handlersTour[tourType]} className={'cursor-pointer'} ref={icon}>
                            <EbloomInfoTooltip text={_.upperFirst(t('admin.productTour.launch'))}/>
                        </IconButton>
                    </div>)
                }
            }

        }else{
            if(subtitle){
                return (<div className={"ml-5"}>
                    <EbloomInfoTooltip text={subtitle}/>
                </div>)
            }

        }
    }


    return(
        <Card data-tour={tourDataset.card} className={"mb-20"} style={{backgroundColor:backgroundColor, border:border}}>
            <Card.Body>
                {
                    showHeader &&
                    <div className={"mb-20 w-full grid grid-cols-6 grid-rows-1 gap-5 items-baseline"}>
                        {showLeftButton &&
                            <div className={"mr-5 col-span-1"}>
                                <Button data-tour={tourDataset.leftBtn} variant={leftButtonVariant} size="sm" className={leftButtonVariant === "link" ? "ebloom-normal-text text-purple" : leftButtonVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text" : "ebloom-normal-text"}
                                        onClick={handleLeftButton}>{typeof buttonText === "string" ? _.capitalize(t(leftButtonText)) : leftButtonText}</Button>
                            </div>
                        }
                        <div className={"col-span-4 col-start-2 text-" + titlePosition}>
                            <h1 data-tour={tourDataset.title} className={"ebloom-normal-title mb-0 text-" + titleColor}>{title}</h1>
                            {
                                showBadge &&
                                <Badge pill bg={badgeVariant} className={"center text-white"}>{badgeContent}</Badge>
                            }
                        </div>
                        <div className={"col-span-1"}>
                            <div className={"col-span-1 flex items-center justify-end"}>
                                {
                                    showButton &&
                                    <div className={"flex items-center justify-between"}>
                                        {
                                            showSecondExtraRightButton &&
                                            <Button variant={secondExtraRightButtonVariant} size={"sm"} style={{color:secondExtraRightButtonVariant === "link" ? Colors.EBLOOM_PURPLE : ""}}
                                                    onClick={handleSecondExtraRightButton} className={secondExtraRightButtonVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text inline-icon" : secondExtraRightButtonVariant === "purple-outline" ? "ebloom-btn-purple-outline ebloom-normal-text inline_icon" : secondExtraRightButtonVariant === "purple-outline-noBorder" ? "ebloom-btn-purple-outline noBorder ebloom-normal-text inline_icon" : " ebloom-normal-text inline-icon"}>{typeof secondExtraRightButtonText === "string" ? _.upperFirst(t(secondExtraRightButtonText)) : secondExtraRightButtonText}</Button>
                                        }
                                        {
                                            showExtraRightButton &&
                                            <Button variant={extraRightButtonVariant} size={"sm"} style={{color:extraRightButtonVariant === "link" ? Colors.EBLOOM_PURPLE : ""}}
                                                    onClick={handleExtraRightButton} className={extraRightButtonVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text inline-icon" : extraRightButtonVariant === "purple-outline" ? "ebloom-btn-purple-outline ebloom-normal-text inline_icon" : extraRightButtonVariant === "purple-outline-noBorder" ? "ebloom-btn-purple-outline noBorder ebloom-normal-text inline_icon" : " ebloom-normal-text inline-icon"}>{typeof extraRightButtonText === "string" ? _.upperFirst(t(extraRightButtonText)) : extraRightButtonText}</Button>

                                        }
                                        <Button data-tour={tourDataset.btn} variant={rightButtonVariant}
                                                className={rightButtonVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text inline-icon" : rightButtonVariant === "purple-outline" ? "ebloom-btn-purple-outline ebloom-normal-text inline_icon" : rightButtonVariant === "purple-outline-noBorder" ? "ebloom-btn-purple-outline noBorder ebloom-normal-text inline_icon" : " ebloom-normal-text inline-icon"} size="sm"
                                                style={{color:rightButtonVariant === "link" ? Colors.EBLOOM_PURPLE : "", marginLeft:5}}
                                                onClick={handleRightButton}>{typeof buttonText === "string" ? _.upperFirst(t(buttonText)) : buttonText}</Button>
                                    </div>

                                }
                                {
                                    (subtitle || tourTooltip) && toolTipProductTour()
                                }
                            </div>
                        </div>
                    </div>
                }
                {props.children}
            </Card.Body>
        </Card>
    )
}

CardDefault.defaultProps = {
    showHeader:true,
    showButton:true,
    showLeftButton:false,
    buttonText: "core.create",
    titlePosition:"center",
    leftButtonText:"core.previous",
    rightButtonVariant:"purple",
    rightButtonPurple: true,
    leftButtonVariant:"success",
    handleLeftButton: () => {},
    handleRightButton: () => {},
    backgroundColor:"white",
    showExtraRightButton:false,
    extraRightButtonText:"link",
    extraRightButtonVariant:"link",
    handleExtraRightButton: () => {},
    border:"1px solid " + Colors.EBLOOM_CONTRAST_PURPLE,
    titleColor:"contrast-purple",
    showBadge:false,
    badgeContent:"",
    badgeVariant:"primary",
    buttonMarginBugFix:0,
    isMobile:false,
    showSecondExtraRightButton: false,
    secondExtraRightButtonVariant: "link",
    secondExtraRightButtonText: "link",
    handleSecondExtraRightButton: () => {},
    tourDataset : {
        card : 'none',
        leftBtn : 'none',
        btn : 'none',
        title : 'none',
    },
    tourTooltip : false,
    tourType : "none"
};

CardDefault.propTypes = {
    showHeader: PropTypes.bool,
    showButton: PropTypes.bool,
    showLeftButton: PropTypes.bool,
    buttonText: PropTypes.any,
    titlePosition: PropTypes.string,
    leftButtonText: PropTypes.string,
    rightButtonVariant: PropTypes.string,
    rightButtonPurple: PropTypes.bool,
    leftButtonVariant: PropTypes.string,
    handleLeftButton: PropTypes.func,
    handleRightButton: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundColor: PropTypes.string,
    showExtraRightButton: PropTypes.bool,
    extraRightButtonText: PropTypes.any,
    extraRightButtonVariant: PropTypes.string,
    handleExtraRightButton: PropTypes.func,
    border:PropTypes.string,
    titleColor:PropTypes.string,
    showBadge:PropTypes.bool,
    badgeVariant: PropTypes.string,
    badgeContent: PropTypes.string,
    buttonMarginBugFix: PropTypes.number,
    isMobile:PropTypes.bool,
    showSecondExtraRightButton: PropTypes.bool,
    secondExtraRightButtonVariant: PropTypes.string,
    secondExtraRightButtonText:PropTypes.any,
    handleSecondExtraRightButton:PropTypes.func,
    tourDataset: PropTypes.object,
    tourTooltip : PropTypes.bool,
    tourType : PropTypes.string
};

export default CardDefault