import jsPDF from "jspdf";
import {Button, Card, Col, Row} from "react-bootstrap";
import CategoryTag from "../../../../Core/Modules/CategoryDisplay/CategoryTag";
import EbloomPieChart from "../../../../Core/Modules/Chart/EbloomPieChart";
import Divider from "@mui/material/Divider";
import {Colors} from "../../../../../Utils/Colors";
import EbloomSmileyPercentageV2 from "../../../../Core/Modules/Chart/EbloomSmileyPercentageV2";
import _ from "lodash";
import DashboardCard from "../../../../Core/Modules/Cards/DashboardCard";
import {categoryIcon, categoryName, processImageForPdf} from "../../../../../Utils/Global";
import SimpleBubbleChart from "../../../../Core/Modules/Chart/SimpleBubbleChart";
import React, {useEffect, useState} from "react";
import HttpApi, {Sources} from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import moment from "moment";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import Login from "../../../../../login/login";
import {getTranslations} from "../../../../../lang/translations";
import {PropTypes} from "prop-types";
import {useUserData} from "../../../../../hooks/userHook";
import {toCanvas, toPng} from "html-to-image";
import EbloomSpeedometerV2 from "../../../../Core/Modules/Chart/EbloomSpeedometerV2";
import {selectBubbleIcon} from "../../../../Admin/Pages/Categories/Assets/CategoriesAssets";
import {useTranslation} from "react-multi-lang";

const ReportGroup = (props) => {

    const [loading, setLoading] = useState(false);
    const [loadingBubble, setLoadingBubble] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingAvg, setLoadingAvg] = useState(true);
    const [loadingSmileyScore, setLoadingSmileyScore] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [mostFlagged, setMostFlagged] = useState([]);
    const [mostImportant, setMostImportant] = useState([]);
    const [mostSatisfied, setMostSatisfied] = useState([]);
    const [lessSatisfied, setLessSatisfied] = useState([]);
    const [mostImportantNumber, setMostImportantNumber] = useState(20);
    const [lessImportantNumber, setLessImportantNumber] = useState(20);
    const [statOfBubble, setStatOfBubble] = useState([]);
    const [categoriesScores, setCategoriesScores] = useState([]);
    const [statAvg, setStatAvg] = useState({total:0, avg:0, limit:true});
    const [smileyScore, setSmileyScore] = useState({tableOfResults:[], nbResponse:0});
    const [bestCB, setBestCB] = useState([]);
    const [worstCB, setWorstCB] = useState([]);
    const [nbCBAsked, setNbCBAsked] = useState({categories:[{total:0}, {total: 0}, {total: 0}, {total: 0}, {total: 0}], total:0})

    const {companyToEdit, startDate, endDate, shouldDownloadReport, handleReportState, lang, teamToDisplay,isModelCustomEnable,isGroup, isTeamSelected,teams,getEntitiesFromGroup,commonSurveyActivated} = props;

    const user = useUserData();
    const id_company_group = companyToEdit ? companyToEdit.id :  user.companyGroup.id
    const t = useTranslation()

    const company = user.company;

    const fetchImportanceAndSatisfaction = () => {
        setLoadingBubble(true);
        let url = "/feedbacks/group/getImportanceAndSatisfaction/"+id_company_group+"/"+getEntitiesFromGroup()+"/"+startDate+"/"+endDate
        if(!isGroup){
            const entities = teamToDisplay.map(el => String(el.id)).join('-')
            const teamsId = isTeamSelected ? teams.map(el => String(el.id)).join('-') : '0'
            url = "/feedbacks/entities/getImportanceAndSatisfaction/"+ id_company_group+ "/"+ entities +"/"+ teamsId +"/" +startDate+"/"+endDate + "/"+commonSurveyActivated
        }
        HttpApi.getV2(url, Sources.IMPORTANCE_SATISFACTION_REPORT).then(response => {
            if(response && response.data){
                setStatOfBubble(response.data);
                let maxImportant = [];
                let maxSatisfaction = [];
                let lessSatisfaction = [];
                let maxSatisfiedNumber = -1;
                let lessSatisfiedNumber = 101;
                Object.values(response.data).forEach((value) => {
                    if(!!value.satisfactionTooltip && value.satisfactionTooltip >= 0){
                        if(maxSatisfaction.length === 0 && maxImportant.length === 0 && lessSatisfaction.length === 0){
                            if(!!value.satisfactionTooltip ){
                                maxSatisfaction = [value]
                                lessSatisfaction = [value]
                            }
                            if(value.importanceTooltip !== null && value.importanceTooltip !== undefined){
                                maxImportant = [value]
                            }
                            if(value.satisfactionTooltip > maxSatisfiedNumber) {
                                maxSatisfiedNumber = value.satisfactionTooltip
                            }
                            if(value.satisfactionTooltip < lessSatisfiedNumber){
                                lessSatisfiedNumber = value.satisfactionTooltip
                            }
                        }else{
                            if(value.satisfactionTooltip > maxSatisfiedNumber){
                                maxSatisfiedNumber = value.satisfactionTooltip;
                            }
                            if(value.satisfactionTooltip < lessSatisfiedNumber){
                                lessSatisfiedNumber = value.satisfactionTooltip
                            }

                            if(value.importanceTooltip > maxImportant[0].importanceTooltip){
                                maxImportant = [value];
                            }else if(value.importanceTooltip === maxImportant[0].importanceTooltip){
                                maxImportant.push(value);
                            }

                            if(value.satisfactionTooltip > maxSatisfaction[0].satisfactionTooltip){
                                maxSatisfaction = [value];
                            }else if(value.satisfactionTooltip === maxSatisfaction[0].satisfactionTooltip){
                                maxSatisfaction.push(value);
                            }

                            if(value.satisfactionTooltip < lessSatisfaction[0].satisfactionTooltip){
                                lessSatisfaction = [value];
                            }else if(value.satisfactionTooltip === lessSatisfaction[0].satisfactionTooltip){
                                lessSatisfaction.push(value);
                            }
                        }
                    }
                })
                setMostImportantNumber(30);
                setLessImportantNumber(0);
                if(maxSatisfiedNumber === -1){
                    maxSatisfaction = ["Anonymity"];
                }
                if(lessSatisfiedNumber === 101){
                    lessSatisfaction = ["Anonymity"];
                }
                if(maxImportant.length === 0 && maxSatisfiedNumber === -1){
                    maxImportant = ["Anonymity"];
                }
                setLessSatisfied(lessSatisfaction)
                setMostImportant(maxImportant);
                setMostSatisfied(maxSatisfaction);
            }else{
                setStatOfBubble([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingBubble(false));
    };

    const fetchScoresCategories = () => {
        setLoadingCategories(true);
        let url = "/feedbacks/group/getCategoriesScores/"+id_company_group+"/"+getEntitiesFromGroup()+"/"+startDate+"/"+endDate
        if(!isGroup){
            const entities = teamToDisplay.map(el => String(el.id)).join('-')
            const teamsId = isTeamSelected? teams.map(el => String(el.id)).join('-') : '0'
            url = "/feedbacks/entities/getCategoriesScores/"+ id_company_group+ "/"+ entities +"/"+ teamsId +"/" +startDate+"/"+endDate + "/"+commonSurveyActivated
        }
        HttpApi.getV2(url, Sources.CATEGORIES_REPORT).then(response => {
            if(response && response.data){
                setCategoriesScores(response.data);
            }else{
                setCategoriesScores([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingCategories(false));
    }

    const fetchGlobalStat = () => {
        setLoadingAvg(true);
        let url = "/feedbacks/group/getGlobalStats/"+id_company_group+"/"+getEntitiesFromGroup()+"/"+startDate+"/"+endDate
        if(!isGroup){
            const entities = isTeamSelected ? teamToDisplay[0].id : teamToDisplay.map(el => String(el.id)).join('-')
            const teamsId = isTeamSelected ? teams.map(el => String(el.id)).join('-'): '0'
            url = "/feedbacks/entities/getGlobalStats/"+ entities +"/"+ teamsId +"/" +startDate+"/"+endDate + "/"+commonSurveyActivated
        }
        HttpApi.getV2(url, Sources.GLOBAL_STAT_REPORT).then(response => {
            if(response && response.data){
                setStatAvg({total:response.data.nbFeedback, avg:response.data.avgFeedback, limit:response.data.nbFeedback < 5})
            }else{
                setStatAvg({total:0, avg:0, limit:true});
            }
        }).catch(error => {
            notifyError(error)
        }).finally(() => setLoadingAvg(false));
    }

    const fetchScoreFromStart = () => {
        setLoadingSmileyScore(true);
        let url = "/feedbacks/group/getSmileyScores/"+id_company_group+"/"+getEntitiesFromGroup()+"/"+startDate+"/"+endDate
        if(!isGroup){
            const entities = isTeamSelected ? teamToDisplay[0].id : teamToDisplay.map(el => String(el.id)).join('-')
            const teamsId = isTeamSelected ? teams.map(el => String(el.id)).join('-'): '0'
            url = "/feedbacks/entities/getSmileyScores/"+ entities +"/"+ teamsId +"/"+startDate+"/"+endDate+ "/"+commonSurveyActivated

        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setSmileyScore(response.data);
            }else{
                setSmileyScore({tableOfResults:[], nbResponse:0});
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingSmileyScore(false);
        })
    };

    const fetcHistoryQuestions = () => {
        setLoadingHistory(true);
        let url = "/questions/group/getHistory/"+  id_company_group +"/"+getEntitiesFromGroup()+"/"+startDate+"/"+endDate

        if(!isGroup){
            const teamsId = isTeamSelected ? teams.map(el => String(el.id)).join('-'): '0'
            url = "/questions/entities/getHistory/" + id_company_group +"/"+ teamToDisplay[0].id +"/"+teamsId+"/"+startDate+"/"+endDate+"/"+commonSurveyActivated
        }
        HttpApi.getV2(url, Sources.HISTORY_REPORT).then(response => {
            const urlCat = "/categories/getMainCategories/"+user.companyGroup.id;
            HttpApi.getV2(urlCat).then(categoriesResponse => {
                if(response && response.data && categoriesResponse && categoriesResponse.data){
                    let questions = response.data;

                    let best = questions.filter(el => el.score !== -1).sort((a, b) => {
                        if(a.score>b.score){
                            return -1;
                        }else if(a.score<b.score){
                            return 1;
                        }
                        return 0;
                    });

                    let worst = questions.filter(el => el.score !== -1).sort((a,b) => {
                        if(a.score>b.score){
                            return 1;
                        }else if(a.score<b.score){
                            return -1;
                        }
                        return 0;
                    });

                    let keys = [];

                    questions.filter(el => el.score !== -1 && el.nbFlags > 0).sort((a,b) => {
                        if(a.date>b.date){
                            return -1;
                        }else if(a.date<b.date){
                            return 1;
                        }
                        return 0;
                    }).forEach((item) => {
                        const existing = keys.findIndex((el) => el.id === item.id)
                        if(existing === -1){
                            keys.push(item)
                        }else if(item.nbFlags > keys[existing].nbFlags){
                            keys[existing] = item
                        }
                    })

                    keys.sort((a,b) => {
                        return b.nbFlags-a.nbFlags;
                    })


                    let c = categoriesResponse.data.map(cat => {
                        const total = questions.filter(el => el.id_category === cat.id).length
                        return  {
                            category : cat,
                            total: total
                        }
                    })
                    setMostFlagged(keys);
                    setBestCB(best);
                    setWorstCB(worst);
                    setNbCBAsked({categories: c,total:questions.length})
                }else{
                    setMostFlagged([]);
                    setBestCB([]);
                    setWorstCB([]);
                    setNbCBAsked({categories:[], total:0})
                }

            }).catch(error => {
                notifyError(error);
            }).finally(() => setLoadingHistory(false));
        }).catch(error => {
            notifyError(error);
        })
    };

    const cutName = (name,limit) => {
        if(name){
            let words = name.split(' ');
            let result = []
            for (let i = 0; i < words.length; i += (limit === 1 && words.length > 3) ? 2 : 1) {
                if (words[i].length > limit) {
                    result.push(_.upperFirst(words[i].substring(0, limit) + '.'))
                }else{
                    result.push(_.upperFirst(words[i]))
                }
            }
            return result.join(' ')
        }

    }

    const disableClicks = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const generatePdf = async () => {
        if(!loading){
            document.addEventListener("click", disableClicks, true);
            document.getElementById("report").style.display = "block"
            setLoading(true);
            let pdf = new jsPDF({orientation:"landscape",compress:true});
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            const report1 = require("../../../../Core/symbols/reports/report_start_landscape_" + lang + ".png");
            pdf.addImage(report1, "PNG", 0, 0, width, height,null,"SLOW");
            let nodes = document.querySelectorAll(".report-dashboard");
            let canvaWidth = 0;
            let canvaHeight = 0;
            for(let i=0;i<nodes.length;i++){
                let canva = await toCanvas(nodes[i]);
                canvaWidth = canva.width;
                canvaHeight = canva.height;
                await toPng(nodes[i],{style:{backgroundColor:"#EFF9FE"}}).then(async dataUrl => {
                    await processImageForPdf(pdf, dataUrl, canvaWidth, canvaHeight, "landscape").then(newPdf => {
                        pdf = newPdf;
                        if(i === nodes.length-1){
                            let name = getTranslations(lang, "manager.dashboard.report.report") + "_";
                            if(companyToEdit){
                                name += companyToEdit.name;
                            }else if(teamToDisplay && teamToDisplay.length > 0){
                                name += isTeamSelected ? teams.map(el => String(el.name)).join(', ') : teamToDisplay.map(el => String(el.name)).join('_')
                            }else{
                                name += company;
                            }
                            if(startDate){
                                name += "_" + moment(startDate).format("YYYY-MM-DD") + "_" + moment(endDate).format("YYYY-MM-DD");
                            }
                            name += ".pdf";
                            pdf.addPage("a4", "landscape")
                            const report10 = require("../../../../Core/symbols/reports/report_end_landscape_" + lang + ".png");
                            pdf.addImage(report10, 0, 0, width, height,null,"SLOW");
                            pdf.save(name)
                            document.removeEventListener("click", disableClicks, true);
                            document.getElementById("report").style.display = "none";
                            setLoading(false);
                            if(!companyToEdit){
                                handleReportState(false);
                            }
                        }
                    })
                })
            }
        }
    }

    useEffect(() => {
        if((Login.hasRole("global_backoffice") && companyToEdit !== null) || shouldDownloadReport){
            fetchGlobalStat();
            fetchImportanceAndSatisfaction();
            fetchScoresCategories();
            fetchScoreFromStart();
            fetcHistoryQuestions();
        }
    }, [companyToEdit, shouldDownloadReport])

    useEffect(() => {
        if(!loadingCategories && !loadingBubble && !loadingAvg && !loadingSmileyScore && !loadingHistory && !companyToEdit){
            generatePdf();
        }
    }, [loadingBubble, loadingAvg, loadingSmileyScore, loadingCategories, loadingHistory])

    return(
        <div>
            {
                companyToEdit &&
                <Button className={"right ebloom-btn-purple mb20"} disabled={loading} onClick={generatePdf}>{loading ? <LoadingView size={16}/> : <span>Generate</span>}</Button>
            }

            <div id={"report"} className={"ml20"} style={{display:companyToEdit ? "block" : "none", width:1110, height:5200}}>
                {
                    !loadingHistory && !loadingCategories && !loadingAvg &&
                    <div className={"report-dashboard"} style={{height:742, width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20}}>
                        <div className={"center ebloom-normal-title contrast-purple font-weight-bold"} style={{marginBottom:20}}>
                            {startDate ? getTranslations(lang, "manager.dashboard.report.nbAnswersFromTo", {param:statAvg.total, start:moment(startDate).format("DD/MM/YYYY"), end:moment(endDate).format("DD/MM/YYYY")}) :getTranslations(lang, "manager.dashboard.report.nbAnswersSince", {param:statAvg.total})}
                        </div>
                        <Row className={"ml10"}>
                            <Col md={7} style={{paddingLeft:50}}>
                                <div className={"center"}>
                                    <div className={"ebloom-text ebloom-xtra-large-text contrast-purple mt20 mb40"}>
                                        <span><span className={"font-weight-bold"}>{nbCBAsked.total}</span>{getTranslations(lang, "manager.dashboard.report.nbCBAsked", {param:" "})}</span>
                                    </div>
                                    <div style={{width:390, margin:"auto", marginTop: 10}}>
                                        {
                                            !loadingHistory && !loadingCategories && categoriesScores.sort((a,b)=> a.category.position - b.category.position).map((category, index) => {
                                                return <Row className={"mb30"}>
                                                    <Col md={6}><CategoryTag modelCustomEnable={isModelCustomEnable} category={category.category} inline={true} lang={lang}/></Col><Col md={6} className={"ebloom-normal-text contrast-purple"}>{nbCBAsked.categories.filter(c => c.category.id === category.category.id)[0].total} Click&Bloom</Col>
                                                </Row>
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                {
                                    !loadingHistory &&
                                    <EbloomPieChart categories={nbCBAsked.categories}/>
                                }
                                <Row className={"ml10 mt50"}>
                                    <p className={"ebloom-normal-text contrast-purple"}>{getTranslations(lang, "manager.dashboard.report.cbHelper")}</p>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                }
                <Divider className={"mt10 mb10"}/>
                {
                    !loadingAvg && !loadingSmileyScore &&
                    <div className={"report-dashboard"} style={{height:742, width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20}}>
                        <div className={"center ebloom-normal-title contrast-purple font-weight-bold mt20"} style={{marginBottom:110}}>
                            {getTranslations(lang, "manager.dashboard.report.avgResults")}
                        </div>
                        <Card style={{backgroundColor:Colors.EBLOOM_BG_BLUE, borderColor:Colors.EBLOOM_CONTRAST_PURPLE}}>
                            <Row>
                                <Col>
                                    <Card style={{backgroundColor:"transparent", border:"none", borderRight:"1px solid " + Colors.EBLOOM_CONTRAST_PURPLE, borderRadius:0}} className={"center"}>
                                        <div className={"ebloom-normal-title contrast-purple mb20 mt20 font-weight-bold"}>Bloom Index</div>
                                        {
                                            !loadingAvg && (!statAvg.total || statAvg.total <= 0) ?
                                                <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{_.upperFirst(getTranslations(lang,"core.noData"))}</p>
                                                :
                                                !loadingAvg && statAvg.limit ?
                                                    <p style={{color:"black"}}>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</p>
                                                    :
                                                    !loadingAvg ?
                                                        <EbloomSpeedometerV2 score={statAvg.avg}/>
                                                        :
                                                        <LoadingView/>
                                        }

                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{backgroundColor:"transparent", border:"none"}} className={"center"}>
                                        <div className={"ebloom-normal-title contrast-purple mb20 mt20 font-weight-bold"}>Smiley Score</div>
                                        <div>
                                            {
                                                !loadingSmileyScore && !loadingAvg && (!statAvg.total || statAvg.total <= 0) ?
                                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{_.upperFirst(getTranslations(lang,"core.noData"))}</p>
                                                    :
                                                    !loadingSmileyScore && !loadingAvg && statAvg.limit ?
                                                        <p style={{color:"black"}}>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</p>
                                                        :
                                                        !loadingSmileyScore ?
                                                            <EbloomSmileyPercentageV2 value={smileyScore} isMobile={false}/>
                                                            :
                                                            <LoadingView/>

                                            }
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        <Row className={"mt50"}>
                            <Col>
                                <p className={"center contrast-purple ebloom-normal-text"}>{_.upperFirst(getTranslations(lang, "manager.dashboard.bloomHelper"))}</p>
                            </Col>
                            <Col>
                                <p className={"center contrast-purple ebloom-normal-text"}>{_.upperFirst(getTranslations(lang, "manager.dashboard.smileyHelper"))}</p>
                            </Col>
                        </Row>

                    </div>
                }

                <Divider className={"mt10 mb10"}/>
                {
                    !loadingCategories &&
                    <div className={"report-dashboard"} style={{height:742, width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20}}>
                        <div className={"center ebloom-normal-title contrast-purple font-weight-bold mt20"} style={{marginBottom:categoriesScores.length > 5 ? 20 : 100}}>
                            {getTranslations(lang, "manager.dashboard.report.avgCategories")}
                        </div>
                        <div className={"mt10 mb40 ebloom-text ebloom-xtra-large-text contrast-purple"}>
                            {_.upperFirst(getTranslations(lang, "manager.dashboard.categoryHelper"))}
                        </div>
                        <Row md={5} xs={1} className={"mt10 w-full h-4/6"}>
                            {
                                !loadingAvg && (!statAvg.total || statAvg.total <= 0) ?
                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{_.upperFirst(getTranslations(lang, "core.noData"))}</p>
                                    : !loadingAvg && statAvg.limit ?
                                        <p>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</p>
                                        :
                                        !loadingCategories ? categoriesScores.length > 5 ?
                                                <div className={"w-full"}>
                                                    {[0, 1].map(index => (
                                                        <div className={"w-full flex flex-row justify-around items-start"}>
                                                            {
                                                                categoriesScores.sort((a, b) => a.category.position - b.category.position).slice(index === 0 ? 0 : Math.floor(categoriesScores.length / 2), index === 0 ? Math.floor(categoriesScores.length / 2) : categoriesScores.length).map((category, index) => (
                                                                    <div key={index}
                                                                         className={"mb-20 flex flex-col justify-center items-center w-min mx-10" }>
                                                                        <div style={{margin: "auto", display: "inline-block"}}>
                                                                            <CategoryTag modelCustomEnable={isModelCustomEnable}
                                                                                         category={category.category} width={200} lang={lang}/>
                                                                        </div>
                                                                        <div style={{
                                                                            marginTop: 30,
                                                                            opacity: category.score === null || category.score === -1 ? 0.4 : 1
                                                                        }}>
                                                                            <EbloomSpeedometerV2 score={category.score} width={200}/>
                                                                        </div>
                                                                        {
                                                                            (category.score === null || category.score === -1) &&
                                                                            <div className={"ebloom-text ebloom-very-large-text center"}
                                                                                 style={{
                                                                                     position: "relative",
                                                                                     bottom: 150
                                                                                 }}>{category.score === -1 ? _.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction")) : _.upperFirst(getTranslations(lang, "core.noData"))}</div>
                                                                        }
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <div className={"w-full h-full flex flex-row justify-around items-start"}>
                                                    {categoriesScores.map((category, index) => (
                                                        <div key={index} className={"w-1/5"} style={{textAlign: "center"}}>
                                                            <div style={{margin: "auto", display: "inline-block"}}>
                                                                <CategoryTag modelCustomEnable={isModelCustomEnable}
                                                                             category={category.category} width={200} lang={lang}/>
                                                            </div>
                                                            <div style={{
                                                                marginTop: 30,
                                                                opacity: category.score === null || category.score === -1 ? 0.4 : 1
                                                            }}>
                                                                <EbloomSpeedometerV2 score={category.score} width={200}/>
                                                            </div>
                                                            {
                                                                (category.score === null || category.score === -1) &&
                                                                <div className={"ebloom-text ebloom-very-large-text center"}
                                                                     style={{
                                                                         position: "relative",
                                                                         bottom: 150
                                                                     }}>{category.score === -1 ? _.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction")) : _.upperFirst(getTranslations(lang, "core.noData"))}</div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            : null
                            }
                        </Row>
                    </div>
                }
                <Divider className={"mt10 mb10"}/>
                {
                    !loadingCategories && !loadingBubble &&
                    <div className={"report-dashboard"} style={{height:"auto", width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20}}>
                        <div className={"center ebloom-normal-title font-weight-bold contrast-purple mt20 mb50"}>
                            {getTranslations(lang, "manager.dashboard.report.whatMatters", {company:companyToEdit ? companyToEdit.name : (teamToDisplay && teamToDisplay.length > 0) ? isTeamSelected ? teams.map(el => String(el.name)).join(', ') : teamToDisplay.map(el => String(el.name)).join(', ') : company})}
                        </div>
                        <Row>
                            <Col md={9}>
                                <DashboardCard minHeight={450} title={"Focus Matrix"} showInfo={false}>
                                    {
                                        <div>
                                            <Row md={5} xs={3} style={{marginBottom:10}}>
                                                {
                                                    !loadingCategories && categoriesScores.map((category, index) => (
                                                        <Col key={index} style={{textAlign:"center", paddingRight:index === 2 ? 10 : 15, marginBottom:10}}>
                                                            <div style={{margin:"auto"}} className={"flex justify-center"}>
                                                                <div className={"w-50 h-50"}>
                                                                    {selectBubbleIcon(category.category.icon,category.category.color)}
                                                                </div>

                                                            </div>
                                                            <span className={"ebloom-normal-text"}>{ _.upperFirst(getTranslations(lang,"core.category."+category.category.name))}</span>
                                                        </Col>

                                                    ))
                                                }
                                            </Row>
                                            {
                                                !loadingBubble &&
                                                <SimpleBubbleChart data={statOfBubble} isReport min={lessImportantNumber-1} max={mostImportantNumber+1} lang={lang}/>
                                            }

                                        </div>
                                    }
                                </DashboardCard>
                            </Col>
                            <Col md={3}>
                                <div
                                    className={"ebloom-xtra-large-text ebloom-text contrast-purple mt40 mb40"}>{getTranslations(lang, mostImportant.length > 1 ? "manager.dashboard.report.mostImportantCategories" : "manager.dashboard.report.mostImportantCategory")}</div>
                                <div>
                                    {
                                        mostImportant[0] === "Anonymity" ? _.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))
                                            : mostImportant.map((important, index) => (
                                                <div className={"mb20"}><CategoryTag modelCustomEnable={isModelCustomEnable}
                                                                                     category={important.category} lang={lang}
                                                                                     key={index}/></div>
                                            ))
                                    }
                                </div>
                                <div
                                    className={"ebloom-xtra-large-text ebloom-text contrast-purple mt40 mb40"}>{getTranslations(lang, mostSatisfied.length > 1 ? "manager.dashboard.report.mostSatisfiedCategories" : "manager.dashboard.report.mostSatisfiedCategory")}</div>
                                <div>{mostSatisfied[0] === "Anonymity" ? _.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))
                                    : mostSatisfied.map((satisfied, index) => (
                                        <div className={"mb20"}><CategoryTag modelCustomEnable={isModelCustomEnable}
                                                                             category={satisfied.category} lang={lang}
                                                                             key={index}/></div>
                                    ))}
                                </div>
                                <div
                                    className={"ebloom-xtra-large-text ebloom-text contrast-purple mt40 mb40"}>{getTranslations(lang, lessSatisfied.length > 1 ? "manager.dashboard.report.lessSatisfiedCategories" : "manager.dashboard.report.lessSatisfiedCategory")}</div>
                                <div>
                                    {
                                        lessSatisfied[0] === "Anonymity"
                                            ? _.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))
                                            : lessSatisfied.map((satisfied, index) => (
                                                <div className={"mb20"}>
                                                    <CategoryTag modelCustomEnable={isModelCustomEnable}
                                                                 category={satisfied.category} lang={lang}
                                                                 key={index}
                                                    />
                                                </div>
                                            ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                <Divider className={"mb10 mt10"}/>
                {
                    !loadingHistory &&
                    <div className={"report-dashboard"} style={{
                        height: 742,
                        width: "100%",
                        margin: "auto",
                        backgroundColor: "rgb(239, 249, 254)",
                        padding: 20
                    }}>
                        <div className={"ebloom-normal-title contrast-purple font-weight-bold mt20 mb50 center"}>
                            {getTranslations(lang, "manager.dashboard.report.keyTopics", {company: companyToEdit ? companyToEdit.name : (teamToDisplay && teamToDisplay.length > 0) ? isTeamSelected ? teams.map(el => String(el.name)).join(', ') :  teamToDisplay.map(el => String(el.name)).join(', ') : company})}
                        </div>
                        <div className={"ebloom-xtra-large-text ebloom-text contrast-purple mb30"}>
                            {getTranslations(lang, "manager.dashboard.report.keyTopicsText")}
                        </div>
                        {
                            !loadingHistory && mostFlagged.length > 0 ? mostFlagged.slice(0,3).map((cb, index) => (
                                    <div key={index} style={{backgroundColor:"white", height:(mostFlagged.length > 3 && mostFlagged[3].nbFlags === mostFlagged[2].nbFlags) ? 120 : 150}} className={"mt20 mb20"}>
                                        <Row style={{minHeight:"100%"}}>
                                            <Col md={2}>
                                                <div className={"flag_contrast very_small_smile inline_icon ml20 mr10"} style={{marginTop:60, width:25, height:26}}/><div className={"inline_icon ebloom-very-large-text contrast-purple font-weight-bold b10"}>{cb.nbFlags} %</div>
                                            </Col>
                                            <Col>
                                                <p className={"ebloom-very-large-text ebloom-text contrast-purple"} style={{marginTop:60}}>{cb.text}</p>
                                            </Col>
                                            <Col md={3}>
                                                <EbloomSpeedometerV2 score={cb.score*25} width={(mostFlagged.length > 3 && mostFlagged[3].nbFlags === mostFlagged[2].nbFlags) ? 130 : 180} />
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                                :
                                <div className={"center ebloom-text ebloom-xtra-large-text contrast-purple"} style={{marginTop:200, marginBottom:291}}>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</div>
                        }
                        {
                            (mostFlagged.length > 3 && mostFlagged[3].nbFlags === mostFlagged[2].nbFlags) &&
                            <div style={{backgroundColor:"white", height:80, padding:10}} className={"mt20 mb20"}>
                                <p className={"ebloom-normal-text contrast-purple"}>
                                    {getTranslations(lang, mostFlagged[2].nbFlags === 1 ? "manager.dashboard.report.keyTopicsDuplicateSingle" : "manager.dashboard.report.keyTopicsDuplicate", {nbFlags:mostFlagged[2].nbFlags})}
                                    {
                                        mostFlagged.slice(3, mostFlagged.length).filter(el => el.nbFlags === mostFlagged[2].nbFlags).map((cb, index) => (
                                            <span className={"font-italic"}>{"\"" + cb.text + "\"" + (index === mostFlagged.slice(3, mostFlagged.length).filter(el => el.nbFlags === mostFlagged[2].nbFlags).length-1 ? "." : ", ")}</span>
                                        ))
                                    }
                                </p>
                            </div>
                        }
                        <p className={"ebloom-normal-text ebloom-text contrast-purple mt20"}>{getTranslations(lang, "manager.dashboard.report.keyTopicsHelper")}</p>
                    </div>
                }

                <Divider className={"mb10 mt10"}/>
                {
                    !loadingHistory &&
                    <div className={"report-dashboard"} style={{height:742, width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20}}>
                        <div className={"ebloom-normal-title contrast-purple font-weight-bold mt20 mb50 center"}>
                            {getTranslations(lang, "manager.dashboard.report.bestTopics")}
                        </div>
                        <div className={"ebloom-xtra-large-text ebloom-text contrast-purple mb30"}>
                            {getTranslations(lang, "manager.dashboard.report.bestTopicsText")}
                        </div>
                        {
                            bestCB.length > 0 ? bestCB.slice(0,3).map((bestScore, index) => (
                                    <div key={index} style={{backgroundColor:"white", height:(bestCB.length > 3 && bestCB[3].score === bestCB[2].score) ? 130 : 150}} className={"mt20 mb20"}>
                                        <Row style={{minHeight:"100%"}}>
                                            <Col md={2} className={"flex justify-center items-center flex-row "}>
                                                <div className={"ml10 mr10 w-35 h-35"} style={{marginTop:10}}>
                                                    {selectBubbleIcon(bestScore.icon,bestScore.color)}
                                                </div>
                                                <div className={"contrast-purple ebloom-very-large-text inline_icon "}>
                                                    {moment(bestScore.date).format("DD/MM/YY")}
                                                </div>

                                            </Col>
                                            <Col>
                                                <p className={"ebloom-very-large-text ebloom-text contrast-purple"} style={{marginTop:55}}>{bestScore.text}</p>
                                            </Col>
                                            <Col md={3}>
                                                <EbloomSpeedometerV2 score={bestScore.score*25} width={(bestCB.length > 3 && bestCB[3].score === bestCB[2].score) ? 150 : 180}/>
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                                :
                                <div className={"center ebloom-text ebloom-xtra-large-text contrast-purple"} style={{marginTop:200}}>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</div>

                        }
                        {
                            (bestCB.length > 3 && bestCB[3].score === bestCB[2].score) &&
                            <div style={{backgroundColor:"white", height:80, padding:10}} className={"mt20 mb20"}>
                                <p className={"ebloom-normal-text contrast-purple"}>
                                    {getTranslations(lang, "manager.dashboard.report.bestTopicsDuplicate", {score:bestCB[2].score*25})}
                                    {
                                        bestCB.slice(3, bestCB.length).filter(el => el.score === bestCB[2].score).map((cb, index) => (
                                            <span className={"font-italic"}>{"\"" + cb.text + "\" (" + moment(cb.date).format("DD/MM/YY") + ")" + (index === bestCB.slice(3, bestCB.length).filter(el => el.score === bestCB[2].score).length-1 ? "." : ", ")}</span>
                                        ))
                                    }
                                </p>
                            </div>
                        }
                    </div>
                }
                <Divider className={"mb10 mt10"}/>
                {
                    !loadingHistory &&
                    <div className={"report-dashboard"} style={{width:"100%", margin:"auto", backgroundColor:"rgb(239, 249, 254)", padding:20, height:742}}>
                        <div className={"ebloom-normal-title contrast-purple font-weight-bold mt20 mb50 center"}>
                            {getTranslations(lang, "manager.dashboard.report.worstTopics")}
                        </div>
                        <div className={"ebloom-xtra-large-text ebloom-text contrast-purple mb30"}>
                            {getTranslations(lang, "manager.dashboard.report.worstTopicsText")}
                        </div>
                        {
                            worstCB.length > 0 ? worstCB.slice(0,3).map((worstScore, index) => (
                                    <div key={index} style={{backgroundColor:"white", height:150}} className={"mt20 mb20"}>
                                        <Row style={{minHeight:"100%"}}>
                                            <Col md={2} className={"flex justify-center items-center flex-row "}>
                                                <div className={"ml10 mr10 w-35 h-35"} style={{marginTop:10}}>
                                                    {selectBubbleIcon(worstScore.icon,worstScore.color)}
                                                </div>
                                                <div className={"contrast-purple ebloom-very-large-text inline_icon "}>
                                                    {moment(worstScore.date).format("DD/MM/YY")}
                                                </div>

                                            </Col>
                                            <Col>
                                                <p className={"ebloom-very-large-text ebloom-text contrast-purple"} style={{marginTop:55}}>{worstScore.text}</p>
                                            </Col>
                                            <Col md={3}>
                                                <EbloomSpeedometerV2 score={worstScore.score*25} width={180}/>
                                            </Col>
                                        </Row>
                                    </div>
                                )) :
                                <div className={"center ebloom-text ebloom-xtra-large-text contrast-purple"} style={{marginTop:200}}>{_.upperFirst(getTranslations(lang, "manager.dashboard.anonymousRestriction"))}</div>

                        }
                        {
                            (worstCB.length > 3 && worstCB[3].score === worstCB[2].score) &&
                            <div style={{backgroundColor:"white", height:80, padding:10}} className={"mt20 mb20"}>
                                <p className={"ebloom-normal-text contrast-purple"}>
                                    {getTranslations(lang, "manager.dashboard.report.worstTopicsDuplicate", {score: worstCB[2].score*25})}
                                    {
                                        worstCB.slice(3, worstCB.length).filter(el => el.score === worstCB[2].score).map((cb, index) => (
                                            <span className={"font-italic"}>{"\"" + cb.text + "\" (" + moment(cb.date).format("DD/MM/YY") + ")" + (index === worstCB.slice(3, worstCB.length).filter(el => el.score === worstCB[2].score).length-1 ? "." : ", ")}</span>
                                        ))
                                    }
                                </p>
                            </div>
                        }
                    </div>
                }

            </div>
        </div>


    )
};

ReportGroup.propTypes = {
    companyToEdit: PropTypes.object,
    startDate:PropTypes.string,
    endDate:PropTypes.string,
    lang:PropTypes.string.isRequired,
    teamToDisplay: PropTypes.object,
    isModelCustomEnable: PropTypes.bool,
    isGroup: PropTypes.bool.isRequired,
    isTeamSelected: PropTypes.bool.isRequired,
    commonSurveyActivated: PropTypes.bool.isRequired,
    teams : PropTypes.array,
    getEntitiesFromGroup : PropTypes.func
}

ReportGroup.defaultProps = {
    companyToEdit:null,
    startDate:"1995-12-25",
    endDate:moment().format("YYYY-MM-DD"),
    teamToDisplay:null,
    isModelCustomEnable:false,
    isTeamSelected: false,
    teams : null,
    getEntitiesFromGroup : () => {return 0}
}

export default ReportGroup;