import React, {useEffect, useState} from "react";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-multi-lang/lib";
import {notifyError, notifySuccess} from "../../../../Utils/Notification";
import HttpApi from "../../../../httpApi";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import LoadingView from "../../../Core/Layouts/LoadingView";
import InvitePreview from "./InvitePreview";
import InviteEditor from "./InviteEditor";
import UserModel from "../../../../models/User";

const UserComparison = (props) => {

    const [show, setShow] = useState(false);
    const [shouldShowModalFilters, setShouldShowModalFilters] = useState(false);
    const [showModalFilters, setShowModalFilters] = useState(false);
    const [showModalDeleteFilters, setShowModalDeleteFilters] = useState(false);
    const [seePreview, setSeePreview] = useState(false);
    const [reloadFilters, setReloadFilters] = useState([]);

    const [loading, setLoading] = useState(false);
    const [stepper, setStepper] = useState([{title:"", table:[], tableConfirm:[], buttonText:""}, {title:"", table:[], tableConfirm: [], buttonText:""}, {title:"", table:[], tableConfirm:[], buttonText:""}]);
    const [activeStep, setActiveStep] = useState(0);

    const {usersMatched, handleShowComparison, handleSeePreviewAndReloadBis, createdEmailsAndPhones} = props;
    const t = useTranslation();

    const handleOpen = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleSeePreview = () => {
        setSeePreview(!seePreview);
        handleSeePreviewAndReloadBis()
    }

    const handleSave = () => {
        if(!loading){
            setLoading(true);
            HttpApi.postV2("/users/saveUsers", {users:stepper[2].table}).then(() => {
                notifySuccess(_.upperFirst(t("admin.invite.success.userSaved")));
                setSeePreview(!seePreview);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleInvite = () => {
        setSeePreview(!seePreview);
        setStepper(prevState => {
            let stepper = [...prevState];
            stepper[2].table = [];
            return stepper;
        })
        if(stepper[0].table.length > 0){
            setActiveStep(0);
        }else if(stepper[1].table.length > 0){
            setActiveStep(1)
        }else{
            handleSeePreviewAndReloadBis();
        }
    }

    const selectAll = () => {
        if(stepper[0].tableConfirm.length === stepper[0].table.length){
            setStepper(prevState => {
                let stepper = [...prevState];
                stepper[0].tableConfirm = [];
                return stepper;
            })
        }else{
            setStepper(prevState => {
                let stepper = [...prevState];
                stepper[0].tableConfirm = _.cloneDeep(stepper[0].table);
                return stepper;
            })
        }
    }

    const handleCheck = (user) => event => {
        event.preventDefault();
        setStepper(prevState => {
            let stepper = [...prevState];
            const index = stepper[0].tableConfirm.indexOf(user);
            if(index > -1){
                stepper[0].tableConfirm.splice(index, 1);
            }else{
                stepper[0].tableConfirm.push(user);
            }
            return stepper;
        })
    }

    const handleRecoverMissingFilters = () => {
        HttpApi.put("/users/attachMissingFilters", {users:usersMatched.tableToUpdate}).then(response => {
            if(response && response.data){
                setStepper(prevState => {
                    let stepper = [...prevState];
                    stepper[1].table = response.data;
                    return stepper;
                })
                setShouldShowModalFilters(false);
            }
        })
    }

    const handleDeleteFilters = () => {
        HttpApi.post("/filter/delete", {filters:usersMatched.filtersToDelete}).then(() => {
            UserModel.initializeOptions();
            setShouldShowModalFilters(false);
            setReloadFilters(usersMatched.filtersToDelete);
            notifySuccess("Filters successfully deleted");
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleUpdate = () => {
        let users = stepper[activeStep].table;
        if(!loading){
            setLoading(true);
            HttpApi.put("/users/updateEmployees", {users:users}).then(() => {
                setStepper(prevState => {
                    let stepper = [...prevState];
                    stepper[1].tableConfirm = [];
                    stepper[1].table = [];
                    return stepper;
                })
                UserModel.initializeOptions();
                notifySuccess(_.upperFirst(t("admin.invite.success.userUpdated")));
                if(stepper[2].table.length > 0){
                    setActiveStep(2);
                }else if(stepper[0].table.length > 0){
                    setActiveStep(0);
                }else{
                    handleSeePreviewAndReloadBis();
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const handleDelete = () => {
        if(!loading){
            setLoading(true);
            let mailsToDelete = stepper[0].tableConfirm.map(el => el.email);
            HttpApi.postV2("/users/deleteEmployees", {mails:mailsToDelete}).then(() => {
                setStepper(prevState => {
                    let stepper = [...prevState];
                    stepper[0].table =  stepper[0].table.filter(el => !mailsToDelete.includes(el.email));
                    stepper[0].tableConfirm = [];
                    return stepper;
                })
                if(stepper[0].table.length === 0){
                    if(stepper[1].table.length > 0){
                        setActiveStep(1);
                    }else if(stepper[2].table.length > 0){
                        setActiveStep(2);
                    }else{
                        handleSeePreviewAndReloadBis()
                    }
                }
                notifySuccess(_.upperFirst(t("admin.invite.success.userDeleted")));
            }).finally(() => setLoading(false));
        }

    }

    const handleChangeUsers = (newUsers) => {
        setStepper(prevState => {
            let stepper = [...prevState];
            stepper[activeStep].table = newUsers;
            return stepper;
        })
    }

    const getStepClassName = (index) => {
        let className = "p-5";
        if(stepper[index].table.length === 0){
            className += " text-grey bg-disabled-grey/[0.5] border border-contrast-purple";
        }else if(index === activeStep){
            className += " text-white bg-contrast-purple pointer border border-contrast-purple";
        }else{
            className += " text-contrast-purple bg-bg-white border border-contrast-purple pointer";
        }
        if(index === 0){
            className += " rounded-l-lg border-r-0";
        }else if(index === stepper.length-1){
            className += " rounded-r-lg border-l-0";
        }else{
            className += " border-l-0 border-r-0"
        }

        return className;
    }

    const checked = (email) => {
        return stepper[0].tableConfirm.map(el => el.email).includes(email);
    }

    useEffect(() => {
        if(usersMatched){
            setStepper([{title:"admin.toDelete", table:usersMatched.tableToDelete, tableConfirm:[], buttonText:_.upperFirst(t("core.deleteParam", {param:t("core.user.users")}))}, {title:"core.update", table:usersMatched.tableToUpdate, tableConfirm: [], buttonText:_.upperFirst(t("core.updateParam", {param:t("core.user.users")}))}, {title:"core.invite", table:usersMatched.tableToInvite, tableConfirm:[], buttonText:_.upperFirst(t("core.saveAndInviteUsers")) + " (" + _.upperFirst(t("core.seePreview")) + ")"}]);
            if(usersMatched.tableToDelete.length > 0){
                setActiveStep(0);
            }else if(usersMatched.tableToUpdate.length > 0){
                setActiveStep(1);
            }else if(usersMatched.tableToInvite.length > 0){
                setActiveStep(2);
            }else{
                handleShowComparison();
            }
            if(usersMatched && usersMatched.filtersToDelete && usersMatched.filtersToDelete.length > 0){
                setShouldShowModalFilters(true);
            }
        }
    }, [usersMatched])

    useEffect(() => {
        if(activeStep === 1 && shouldShowModalFilters){ //update
            if(usersMatched && usersMatched.filtersToDelete && usersMatched.filtersToDelete.length > 0){
                setShowModalFilters(true);
            }
        }
    }, [activeStep])

    return(
        <>
            {
                seePreview ?
                    <InvitePreview toInviteConfirm={stepper[2].table} handleSeePreview={handleSeePreview} handleSeePreviewAndReload={handleInvite} {...props}/>
                    :
                    <div>
                        <CardDefault showButton={false} showLeftButton handleLeftButton={handleShowComparison} leftButtonText={_.upperFirst(t("core.back"))} leftButtonVariant={"secondary"} title={_.upperFirst(t("core.updateParam", {param:t("core.user.users")}))}>
                            <div className={"text-center flex justify-center items-center mb-10"}>
                                {
                                    stepper.map(el => el.title).map((step, index) => (
                                        <div id={'stepUpdateUser'+index} key={index} className={getStepClassName(index)} onClick={stepper[index].table.length === 0 ? () => {} : () => {setActiveStep(index)}}>{_.upperFirst(t(step)) + " ("+ stepper[index].table.length +")"}</div>
                                    ))
                                }
                            </div>
                            {
                                activeStep === 0 &&
                                <div className={"text-right"}>
                                    <Button variant={"link"} className={"text-purple"} onClick={selectAll}>{(stepper[activeStep].tableConfirm.length === stepper[activeStep].table.length) ? _.upperFirst(t("core.deselectAll")) :_.upperFirst(t("core.selectAll"))}</Button>
                                </div>
                            }
                            {
                                activeStep === 0 ?
                                <div>
                                    <ListGroup variant={"flush max-h-500 overflow-y-auto"}>
                                        {
                                            stepper[activeStep].table.map((user, index) => (
                                                <ListGroup.Item className={"py-0"} key={index}>
                                                    <Row className={"items-center cursor-default"}>
                                                        <Col>{user.name || user.email}</Col>
                                                        {
                                                            user.name &&
                                                            <Col className={"text-center"}>{user.email}</Col>
                                                        }
                                                        <Col><Checkbox className={"checkbox-purple right"} checked={checked(user.email)} onChange={handleCheck(user)}/></Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))
                                        }
                                    </ListGroup>
                                    <Button className={"right ebloom-btn-purple mt10"} size="sm" disabled={stepper[activeStep].tableConfirm.length === 0 || loading} onClick={activeStep === 1 ? handleUpdate : activeStep === 0 ? handleOpen : handleSeePreview}>
                                        {
                                            loading ?
                                                <LoadingView size={16}/>
                                                :
                                                <span>{stepper[activeStep].buttonText}</span>
                                        }
                                    </Button>
                                </div>
                                    :
                                    <InviteEditor usersTable={stepper[activeStep].table} handleChangeUsers={handleChangeUsers} buttonText={stepper[activeStep].buttonText} handleButton={activeStep === 1 ? handleUpdate : handleSave} createdEmailsAndPhones={activeStep === 2 ? createdEmailsAndPhones : []} reloadFilters={reloadFilters} handleSeePreviewAndReload={handleSeePreviewAndReloadBis} isInvite={activeStep === 2}/>
                            }

                        </CardDefault>
                        <ConfirmModal handleClose={handleClose} show={show} title={_.upperFirst(t("core.confirmation"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={handleDelete}>
                            <p>{_.upperFirst(t("admin.confirmDeleteUser", {param:stepper[activeStep].tableConfirm.length.toString()}))}</p>
                        </ConfirmModal>
                        <ConfirmModal handleClose={() => {setShowModalFilters(false)}} show={showModalFilters} title={_.upperFirst(t("admin.invite.modalDeleteFiltersTitle"))} handleChange={handleRecoverMissingFilters} handleLeftButton={() => {setShowModalDeleteFilters(true)}} buttonLeftText={_.upperFirst(t("core.deleteParam", {param:t("core.filters")}))} buttonRightText={_.upperFirst(t("core.recoverParam", {param:t("core.filters")}))} buttonLeftVariant={"danger"}>
                            <p>{_.upperFirst(t("admin.invite.modalDeleteFiltersText1"))}</p>
                            {
                                usersMatched.filtersToDelete.map((f, i) => (
                                    <li key={i}>{f.name}</li>
                                ))
                            }
                            <p>{_.upperFirst(t("admin.invite.modalDeleteFiltersText2"))}</p>
                        </ConfirmModal>
                        <ConfirmModal handleClose={() => setShowModalDeleteFilters(false)} show={showModalDeleteFilters} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("core.deleteParam", {param:t("core.filters")}))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleLeftButton={() => {setShowModalFilters(true)}} handleChange={handleDeleteFilters} buttonRightText={_.upperFirst(t("core.deleteParam", {param:t("core.filters")}))} buttonRightVariant={"danger"}>
                            <p>{_.upperFirst(t("admin.invite.modalConfirmDeleteFiltersText1"))}</p>
                            {
                                usersMatched.filtersToDelete.map((f, i) => (
                                    <li key={i}>{f.name}</li>
                                ))
                            }
                            <p>{_.upperFirst(t("admin.invite.modalConfirmDeleteFiltersText2"))}</p>
                        </ConfirmModal>
                    </div>
            }
        </>
    )
}

export default UserComparison;