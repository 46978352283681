import {useContext, useEffect, useState} from "react";
import {ProductTourContext} from "../../../Utils/Context/ProductTourContext";
import {ReactComponent as ValidateIcon} from "../../Core/symbols/productTour/step-done.svg";
import {ReactComponent as StartedIcon} from "../../Core/symbols/productTour/step-doing.svg";
import {ReactComponent as NotStartedIcon} from "../../Core/symbols/productTour/step-todo.svg";
import {ReactComponent as NextIcon} from "../../Core/symbols/productTour/done-next-icon.svg";
import {TourTypePackages, TourUrl} from "../../../Utils/Global";
import {Button} from "react-bootstrap";
import {useNavigationProductTour} from "../../../hooks/navigationProductTourHook";
import {useTranslation} from "react-multi-lang";
import {useUserData} from "../../../hooks/userHook";

const MiniProductTourMap = (props) => {
    const {stepTour} = useContext(ProductTourContext);

    const [tourList, setTourList] = useState([]);
    const [tourResumeIndex, setTourResumeIndex] = useState(-1);
    const [tourResumeType, setTourResumeType] = useState(null);

    const navigateProductTour = useNavigationProductTour();

    const user = useUserData()

    const t = useTranslation("admin.productTour")

    const order = {communication: 1,invite: 2,manageUsers: 3,defineRoles:4,manageTeams: 5,customLibrary: 6,browseLibrary:7,createPlaylist: 8,createClickAndBloom: 9,planClickAndBloom: 10,launchWeBloom: 11}

    const {setAllTourDone} = props

    const orderTour = () => {
        const final = Object.entries(stepTour).filter(tour => TourTypePackages[user.module].includes(tour[0])).sort((a,b) => {
            const indexA = order[a[0]]
            const indexB = order[b[0]]
            if(indexA<indexB){
                return-1;
            }else if(indexA>indexB){
                return 1;
            }
            return 0;
        })
        let checkFirstNotFinished = false
        final.forEach((tour,index) => {
            if (tour[1].step >=0 && !checkFirstNotFinished && !tour[1].outcome){
                setTourResumeIndex(index)
                setTourResumeType(tour[0])
                checkFirstNotFinished = true
                setAllTourDone(false)
            }
        })
        if(!checkFirstNotFinished){
            setAllTourDone(true)
        }
        setTourList(final)
    }

    const defineIcon = (data,progress=false,index=null) => {
        if(progress){
            if (tourList.slice(0,index).find(el => el[1].step >= 0 && el[1].outcome === 0)){
                return 'mini-tour-between-step mini-tour-not-started'
            }else{
                return 'mini-tour-between-step ' + (data.outcome === 1 ? 'mini-tour-finished' : index=== tourResumeIndex ? 'mini-tour-not-finished' : 'mini-tour-not-started')
            }
        }else{
            if (data.outcome === 1){
                return <ValidateIcon className={'mini-tour-svg'} />
            }else if (data.step >= 0){
               return index === tourResumeIndex ? <StartedIcon className={'mini-tour-svg'}/> :  <NotStartedIcon className={'mini-tour-svg'}/>
            }
        }
    }

    useEffect(() => {
        orderTour()
    }, [stepTour]);

    return (
        <div className={'flex flex-col items-center mb-20'}>
                <div onClick={()=> navigateProductTour.to(TourUrl[tourResumeType],tourResumeType)} className={'flex flex-row justify-between w-full px-20 cursor-pointer'}>
                    {
                        tourList.map((tour,index) =>{
                            const data = tour[1]
                            if(index === 0){
                                return (
                                    <div key={index} className={'flex justify-center items-center relative'}>
                                        <div className={'absolute z-10 -top-6 -right-15 bottom-0 flex flex-col items-center'}>
                                            <div> {defineIcon(data,false,index)} </div>
                                        </div>
                                    </div>
                                )
                            }else{
                                return (
                                    <div key={index} className={'flex justify-center items-center w-full relative'}>
                                        <div className={defineIcon(data,true,index)}></div>
                                        <div className={'absolute z-10 -top-6 -right-15 bottom-0 flex flex-col items-center'}>
                                            <div> {defineIcon(data,false,index)} </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            <div className={'text-center mt-10'}>
                <Button onClick={()=> navigateProductTour.to(TourUrl[tourResumeType],tourResumeType)} size={"sm"} className={"mt-12 text-center ebloom-btn-purple ebloom-small-text flex flex-row items-center justify-center"}>
                    <NextIcon className={'tour-next-icon mt-3 mr-3'}/>
                    {t(tourResumeType+'.title')}
                </Button>
            </div>
        </div>
    )
}
export default MiniProductTourMap