
export const createPlaylistSteps = [
    {
        title: "admin.productTour.createPlaylist.step1.title",
        content: "admin.productTour.createPlaylist.step1.content",
        target: '[data-tour="eBloomPlaylist"]',
        placement: 'top',
        spotlightPadding: 5,
        spotlightClicks: false,
        disableBeacon : true
    },
    {
        title: "admin.productTour.createPlaylist.step2.title",
        content: "admin.productTour.createPlaylist.step2.content",
        target: '[data-tour="createPlaylist"]',
        placement: 'left',
        spotlightPadding: 5,
        spotlightClicks: true,
        disableBeacon : true,
        disableNext : true,
        nextText : "admin.productTour.createPlaylist.step2.nextText"
    },
    {
        title: "admin.productTour.createPlaylist.step3.title",
        content: "admin.productTour.createPlaylist.step3.content",
        target: '[data-tour="createSideFormPlaylist"]',
        placement: 'left',
        spotlightPadding: 2,
        disableBeacon: false,
        spotlightClicks: false,
        placementBeacon : 'left',
        styles : {
            options : {
                zIndex : 1500
            }
        },
        nextText : "admin.productTour.createPlaylist.step3.nextText"
    },
    {
        title: "admin.productTour.createPlaylist.step4.title",
        content: "admin.productTour.createPlaylist.step4.content",
        target: '[data-tour="row"]',
        placement: 'top',
        spotlightPadding: 5,
        spotlightClicks: false,
        disableBeacon : true,
    }
    ]