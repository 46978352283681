import React, {useEffect} from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {ModelTypes} from "../../../../models/Types";
import moment from "moment/moment";
import EbloomModal from "../Modal/EbloomModal";
import {Row, Col} from "react-bootstrap";

const DetailView = (props) => {

    //state

    //props
    const {model, item, show, handleClose, isModal} = props;

    //hooks
    const t = useTranslation();

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {

    }, [])

    return (
        <>
            {
                isModal ?
                    <EbloomModal show={show} handleClose={handleClose} size={"lg"}>
                        <Row md={3}>
                            {
                                Object.entries(model).filter(([key, value]) => value.detail && value.detail.show && (!value.detail.showCondition || value.detail.showCondition(item) === true)).map(([key, value], index) => (
                                    <Col className={"flex items-center text-contrast-purple my-10"} key={index}>
                                        <span className={"mr-5"}>{_.upperFirst(t(value.detail.label || value.label)) + ":"}</span>
                                        <div className={"font-semibold truncate"}>
                                            {
                                                value.type === ModelTypes.DATETIME || value.type === ModelTypes.DATE  ?
                                                    <span>{moment(item[key]).format(ModelTypes.DATE ? "DD/MM/YYYY" : "DD/MM/YY HH:mm")}</span>
                                                    :
                                                    value.type === ModelTypes.ARRAY ?
                                                        <div className={"flex flex-wrap max-h-150 overflow-x-auto overflow-y-auto"} style={{maxWidth:150}}>
                                                            {
                                                                item[key] && item[key].map((i, index) => (
                                                                    <div className={"m-5 px-10 rounded-xl bg-purple/[0.2]"} key={index}>{i[value.detail.key]}</div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        value.type === ModelTypes.TEXT && value.detail.translation ?
                                                            <span>{_.upperFirst(t(value.detail.translation + item[key]))}</span>
                                                            :
                                                            value.type === ModelTypes.PRISMA_RELATION ?
                                                                <span>{value.relationLink(item[key])}</span>
                                                                :
                                                                value.type === ModelTypes.PRISMA_RELATIONS ?
                                                                    <div
                                                                        className={"flex flex-wrap  items-center max-h-150 overflow-x-auto overflow-y-auto"}
                                                                        style={{maxWidth: 150}}>
                                                                        {
                                                                            item[key] && item[key].map((i, index) => (
                                                                                <div
                                                                                    className={"m-5 px-10 rounded-xl bg-purple/[0.2]"}
                                                                                    key={index}>{value.relationLink(i)}</div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <span>{item[key]}</span>
                                            }
                                        </div>

                                    </Col>
                                ))
                            }
                        </Row>
                    </EbloomModal>
                    :
                    <Row md={3}>
                        {
                            Object.entries(model).filter(([key, value]) => value.detail && value.detail.show && (!value.detail.showCondition || value.detail.showCondition(item) === true)).map(([key, value], index) => (
                                <Col className={"flex text-contrast-purple my-10"} key={index}>
                                    <span className={"mr-5"}>{_.upperFirst(t(value.detail.label || value.label)) + ":"}</span>
                                    <div className={"font-semibold truncate"}>
                                        {
                                            value.type === ModelTypes.DATETIME || value.type === ModelTypes.DATE  ?
                                                <span>{moment(item[key]).format(ModelTypes.DATE ? "DD/MM/YYYY" : "DD/MM/YY HH:mm")}</span>
                                                :
                                                value.type === ModelTypes.ARRAY ?
                                                    <div className={"flex flex-wrap max-h-150 overflow-x-auto overflow-y-auto"} style={{maxWidth:150}}>
                                                        {
                                                            item[key] && item[key].map((i, index) => (
                                                                <div className={"m-5 px-10 rounded-xl bg-purple/[0.2]"} key={index}>{i[value.detail.key]}</div>
                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    value.type === ModelTypes.TEXT && value.detail.translation ?
                                                        <span>{_.upperFirst(t(value.detail.translation + item[key]))}</span>
                                                        :
                                                        <span>{item[key]}</span>
                                        }
                                    </div>

                                </Col>
                            ))
                        }
                    </Row>
            }

        </>
    )
}

DetailView.propTypes = {
    model:PropTypes.object.isRequired,
    item:PropTypes.object.isRequired,
    show:PropTypes.bool,
    handleClose:PropTypes.func,
    isModal:PropTypes.bool,
}

DetailView.defaultProps = {
    isModal:true
}
export default DetailView