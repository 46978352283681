import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {PollTypes} from "../../Modules/Types";
import Choice from "./Choice";
import StarRatings from "./StarRatings";
import {OpenQuestion} from "./OpenQuestion";
import {useOutsideAlerter} from "../../../../../../../hooks/outsideAlerterHook";
import {ReactComponent as MultipleArrows} from "../../../../../../Core/symbols/webloom/multiple-arrows.svg";
import {Badge, Col, Row} from "react-bootstrap";
import {FullContrastBlueSwitch, FullContrastBlueTextField, uploadFile} from "../../../../../../../Utils/Global";
import PollCardMenu from "./PollCardMenu";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {getTranslations} from "../../../../../../../lang/translations";
import FileInput from "../../../../../../Core/Modules/Input/FileInput";
import LoadingView from "../../../../../../Core/Layouts/LoadingView";
import FileDisplay from "../../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {notifyError, notifySuccess} from "../../../../../../../Utils/Notification";

export const PollQuestion = (props) => {
    const {propQuestion, handleSaveQuestion, handleDuplicateQuestion, handleRemoveQuestion, lang, handleCanDrag} = props;
    const [question, setQuestion] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const wrappedRef = useRef(null);

    const t = useTranslation();

    const handleChangeEditState = () => {
        if(isEditing){
            handleSaveQuestion(question)
            setIsEditing(false);
        }
    }

    const handleDuplicate = (question) => {
        setIsEditing(false);
        handleDuplicateQuestion(question)
    }

    const handleRemove = () => {
        setIsEditing(false);
        handleRemoveQuestion(question);
    }

    const handleAttachment = (event) => {
        const file = event.target.files[0];
        if(file && !loadingAttachment){
            setLoadingAttachment(true);
            uploadFile(file).then((url) => {
                setQuestion(prevState => {
                    let question = {...prevState};
                    question.attachment_url = url;
                    return question;
                })
                setLoadingAttachment(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setQuestion(prevState => {
                    let question = {...prevState};
                    question.attachment_url = null;
                    return question;
                })
                notifyError(error);
            })
        }else{
            setQuestion(prevState => {
                let question = {...prevState};
                question.attachment_url = null;
                return question;
            })
        }
    }

    const handleImage = (event) => {
        const file = event.target.files[0];
        if(file && !loadingImage){
            setLoadingImage(true);
            uploadFile(file).then((url) => {
                setQuestion(prevState => {
                    let question = {...prevState};
                    question.image_url = url;
                    return question;
                })
                setLoadingImage(false);
                notifySuccess("Image uploaded");
            }).catch(error => {
                setQuestion(prevState => {
                    let question = {...prevState};
                    question.image_url = null;
                    return question;
                })
                notifyError(error);
            })
        }else{
            setQuestion(prevState => {
                let question = {...prevState};
                question.image_url = null;
                return question;
            })
        }
    }

    const handleDeleteImage = () => {
        setQuestion(prevState => {
            let question = {...prevState};
            question.image_url = null;
            return question;
        })
    }

    const handleDeleteAttachment = () => {
        setQuestion(prevState => {
            let question = {...prevState};
            question.attachment_url = null;
            return question;
        })
    }

    useOutsideAlerter(wrappedRef, handleChangeEditState);

    const handleChange = (event) => {
        let name = event.target.name;
        let value = name === "is_required" || name === "is_heart" || name === "show_other" || name === "define_other" ? event.target.checked : event.target.value;
        if(name === "nb_items" && question.type === PollTypes.STAR_RATING && (value > 10 || value < 3)){
            value = question.nb_items;
        }else if(name === "nb_items" && question.type === PollTypes.MULTIPLE_CHOICES){
            const l = question.show_other ? question.options.length + 1 : question.options.length;
            if(value < 2 || value > l){
                value= question.nb_items;
            }
        }else if(name === "nb_items_adapted"){//force change nb_items when add or delete option
            name = "nb_items";
        }
        setQuestion(prevState => {
            let question = {...prevState};
            question[name] = value;
            if(name === "show_other" && question.define_other && !value){
                question.define_other = false;
            }
            if(question.type === PollTypes.MULTIPLE_CHOICES && name === "show_other"){
                if(value){
                    question.nb_items++;
                }else{
                    question.nb_items--;
                }
            }
            if(question.type === PollTypes.YES_NO && name === "is_heart"){
                question.options = [{text:_.upperFirst(getTranslations(lang,true === value ? "webloom.true" : "webloom.yes")), value:0}, {text:_.upperFirst(getTranslations(lang, true === value ? "webloom.false" : "webloom.no")), value:1}]
            }
            if(question.nb_items && typeof question.nb_items === "string"){
                question.nb_items = parseInt(question.nb_items);
            }
            if(typeof question.is_required !== "boolean"){
                question.is_required = Boolean(question.is_required);
            }
            if(typeof question.is_heart!== "boolean"){
                question.is_heart = Boolean(question.is_heart);
            }
            if(typeof question.show_other!== "boolean"){
                question.show_other = Boolean(question.show_other);
            }
            if(typeof question.define_other!== "boolean"){
                question.define_other = Boolean(question.define_other);
            }
            return question;
        })
    }

    useEffect(() => {
        setQuestion(prevState => {
            let question = {...prevState};
            question.id = propQuestion.id;
            question.title = propQuestion.title;
            question.description = propQuestion.description;
            question.type = propQuestion.type;
            question.is_required = Boolean(propQuestion.is_required);
            question.new = propQuestion.new || false;
            question.attachment_url = propQuestion.attachment_url || null;
            question.image_url = propQuestion.image_url || null;
            switch (question.type){
                case PollTypes.UNIQUE_CHOICE :
                    question.options = propQuestion.options || [{text:"", value:0}, {text:"", value:1}, {text:"", value:2}, {text:"", value:3}];
                    question.show_other = propQuestion.show_other || false;
                    question.define_other = propQuestion.define_other || false;
                    question.nb_items = propQuestion.nb_items || 1;
                    break;
                case PollTypes.MULTIPLE_CHOICES :
                    question.options = propQuestion.options || [{text:"", value:0}, {text:"", value:1}, {text:"", value:2}, {text:"", value:3}];
                    question.show_other = propQuestion.show_other || false;
                    question.define_other = propQuestion.define_other || false;
                    question.nb_items = propQuestion.nb_items || question.options.length;
                    break;
                case PollTypes.YES_NO :
                    question.options = propQuestion.options || [{text:_.upperFirst(getTranslations(lang,"webloom.yes")), value:0}, {text:_.upperFirst(getTranslations(lang, "webloom.no")), value:1}];
                    question.show_other = propQuestion.show_other || false;
                    question.nb_items = propQuestion.nb_items || 1;
                    question.is_heart = propQuestion.is_heart || false;
                    question.define_other = false;
                    break;
                case PollTypes.STAR_RATING :
                    question.is_heart = propQuestion.is_heart || false;
                    question.nb_items = propQuestion.nb_items || 5;
                    question.show_other = false;
                    question.define_other = false;
                    break;
                case PollTypes.OPEN_QUESTION :
                    question.show_other = false;
                    question.define_other = false;
                    break;
                case PollTypes.NAME :
                    question.title = _.upperFirst(getTranslations(lang, "webloom.name"));
                    question.show_other = false;
                    question.define_other = false;
                    break;
                case PollTypes.TITLE :
                    question.show_other = false;
                    question.define_other = false;
                    question.nb_items = 0;
                    question.is_required = false;
                    break;
                case PollTypes.IDENTIFICATION:
                    question.title = _.upperFirst(getTranslations(lang, "webloom.identification"));
                    question.show_other = false;
                    question.define_other = false;
                    question.nb_items = 0;
                    question.is_required = true;
                    break;
            }
            return question;
        })
    }, [propQuestion]);

    useEffect(() => {
        if(lang && propQuestion && (propQuestion.type === PollTypes.NAME || propQuestion === PollTypes.IDENTIFICATION)){
            let q = _.cloneDeep(propQuestion);
            q.title = propQuestion.type === PollTypes.IDENTIFICATION ? _.upperFirst(getTranslations(lang, "webloom.identification")) : _.upperFirst(getTranslations(lang, "webloom.name"));
            setQuestion(prevState => {
                let question = {...prevState};
                question.title = propQuestion.type === PollTypes.IDENTIFICATION ? _.upperFirst(getTranslations(lang, "webloom.identification")) : _.upperFirst(getTranslations(lang, "webloom.name"));
                return question;
            })
            handleSaveQuestion(q);
        }
    }, [lang])

    useEffect(() => { //save the question the first time it is added to fix the id
        if(typeof question.id === "string") {
            handleSaveQuestion(question);
        }
    },[question])

    return(
        <div ref={wrappedRef} className={"flex items-center m-10"} onMouseOver={propQuestion.type !== PollTypes.IDENTIFICATION ? () => {setIsHover(true)} : () => {}} onMouseLeave={propQuestion.type !== PollTypes.IDENTIFICATION ? () => setIsHover(false) : () => {}} onClick={propQuestion.type !== PollTypes.IDENTIFICATION ? () => {if(!isEditing){setIsEditing(true)}} : () => {}}>
            {
                (isEditing || isHover) &&
                <MultipleArrows className={"w-22 h-22 mr-6 cursor-grab"} />
            }
            <div className={(isEditing || isHover) ? "border-1 border-contrast-blue border-dashed rounded-lg p-10 w-full " + (isHover && !isEditing ? "pointer" : "cursorDefault") : "ml-27 w-full p-10 border border-bg-white rounded-lg"}>
                {question.is_required && isEditing &&
                    <div className={"inline float-right mb-5"}>
                        <Badge bg={""} className={"bg-contrast-blue text-white"}>{_.upperFirst(t("webloom.mandatory"))}</Badge>
                    </div>
                }
                <div className={"ebloom-text font-semibold " + (propQuestion.type === PollTypes.TITLE ? "ebloom-xtra-large-text" : "ebloom-large-text")}><div className={"inline w-full"}>{(isEditing && question.type !== PollTypes.NAME) ? <FullContrastBlueTextField fullWidth value={question.title} variant={"outlined"} size={"small"} name={"title"} onChange={handleChange} placeholder={_.upperFirst(t("core.typeTitle"))} onMouseEnter={() => {handleCanDrag(false)}} onMouseLeave={() => {handleCanDrag(true)}}/> : question.title || _.upperFirst(t("core.title"))}</div>{(question.is_required && !isEditing) && <span className={"ebloom-text ebloom-very-large-text"}>*</span>}</div>
                <div className={"ebloom-text ebloom-small-text mt5 mb-20 w-full"}>{isEditing ? <FullContrastBlueTextField fullWidth value={question.description} variant={"outlined"} size={"small"} name={"description"} onChange={handleChange} placeholder={_.upperFirst(t("core.typeText"))} onMouseEnter={() => {handleCanDrag(false)}} onMouseLeave={() => {handleCanDrag(true)}}/> : question.description || null}</div>
                {
                    (question.type === PollTypes.MULTIPLE_CHOICES || question.type === PollTypes.UNIQUE_CHOICE || question.type === PollTypes.YES_NO) &&
                    <div className={"ebloom-text ebloom-very-small-text text-grey -mt-20 mb-10 italic"}>{_.upperFirst(getTranslations(lang, "webloom.selectMaxParamAnswer", {param:question.nb_items}))}</div>
                }
                <div className={"flex mb-10 items-center"}>
                    {
                        !isEditing ?
                            <FileDisplay className={"mr-5"} urlParams={question.image_url} showIcon={question.image_url !== null}/>
                            :
                            <div className={"flex items-center mr-5"}>
                                <FileInput className={"mr-5"} handleChange={handleImage} showText={!question.image_url && !loadingImage} iconColor={"contrast-blue"} type={"image"}/>
                                {
                                    loadingImage ?
                                        <LoadingView size={16}/>
                                        :
                                        question.image_url &&
                                        <FileDisplay urlParams={question.image_url} handleDelete={handleDeleteImage} showIcon={false}/>
                                }
                            </div>
                    }
                    {
                        !isEditing ?
                            <FileDisplay urlParams={question.attachment_url} showIcon={question.attachment_url !== null}/>
                            :
                            <div className={"flex items-center"}>
                                <FileInput className={"mr-5"} handleChange={handleAttachment} showText={!question.attachment_url && !loadingAttachment} iconColor={"contrast-blue"} iconType={"file"}/>
                                {
                                    loadingAttachment ?
                                        <LoadingView size={16}/>
                                        :
                                        question.attachment_url &&
                                        <FileDisplay urlParams={question.attachment_url} handleDelete={handleDeleteAttachment} showIcon={false}/>
                                }
                            </div>
                    }
                </div>
                {
                    propQuestion.type === PollTypes.UNIQUE_CHOICE || propQuestion.type === PollTypes.MULTIPLE_CHOICES || propQuestion.type === PollTypes.YES_NO ?
                        <Choice isEditing={isEditing} isHover={isHover} propQuestion={question} handleChange={handleChange} lang={lang} handleCanDrag={handleCanDrag}/> :
                        propQuestion.type === PollTypes.STAR_RATING ?
                            <StarRatings isEditing={isEditing} isHover={isHover} propQuestion={question}/> :
                            (propQuestion.type === PollTypes.OPEN_QUESTION || propQuestion.type === PollTypes.NAME || propQuestion.type === PollTypes.IDENTIFICATION) ?
                                <OpenQuestion isEditing={isEditing} isHover={isHover} propQuestion={question}/>
                                :
                                <div className={"ml-20"}/>
                }
            </div>
            {
                (isEditing && (propQuestion.type !== PollTypes.IDENTIFICATION)) &&
                <PollCardMenu question={question} handleDuplicateQuestion={handleDuplicate} handleRemoveQuestion={handleRemove}>
                    <Row><Col>{_.upperFirst(t("webloom.mandatory"))}</Col><Col md={3}><FullContrastBlueSwitch name={"is_required"} onChange={handleChange} value={question.is_required} checked={question.is_required}/></Col></Row>
                    {
                        propQuestion.type === PollTypes.MULTIPLE_CHOICES &&
                        <Row><Col>{_.upperFirst(t("webloom.maxSelect"))}</Col><Col md={4}><FullContrastBlueTextField value={question.nb_items} variant={"outlined"} size={"small"} onChange={handleChange} name={"nb_items"} placeholder={question.options.length} type={"number"} fullWidth/></Col></Row>
                    }
                    {
                        (propQuestion.type === PollTypes.STAR_RATING || propQuestion.type === PollTypes.YES_NO) &&
                        <Row><Col>{_.upperFirst(t(propQuestion.type === PollTypes.YES_NO ? "webloom.trueFalse" : "webloom.hearts"))}</Col><Col md={3}><FullContrastBlueSwitch name={"is_heart"} value={question.is_heart} checked={question.is_heart} onChange={handleChange}/></Col></Row>
                    }
                    {
                        propQuestion.type === PollTypes.STAR_RATING &&
                        <Row><Col>{_.upperFirst(t("webloom.amountItem"))}</Col><Col md={4}><FullContrastBlueTextField value={question.nb_items} name={"nb_items"} variant={"outlined"} size={"small"} onChange={handleChange} type={"number"}/></Col></Row>
                    }
                    {
                        (propQuestion.type === PollTypes.MULTIPLE_CHOICES || propQuestion.type === PollTypes.UNIQUE_CHOICE || propQuestion.type === PollTypes.YES_NO) &&
                        <Row><Col>{propQuestion.type === PollTypes.YES_NO ? _.upperFirst(t("webloom.addNA")) : _.upperFirst(t("core.addParam", {param:t("webloom.otherOption")}))}</Col><Col md={3}><FullContrastBlueSwitch name={"show_other"} onChange={handleChange} value={question.show_other} checked={question.show_other}/></Col></Row>
                    }
                    {
                        (propQuestion.type !== PollTypes.YES_NO && question.show_other) &&
                        <Row><Col>{_.upperFirst(t("webloom.defineOther"))}</Col><Col md={3}><FullContrastBlueSwitch name={"define_other"} onChange={handleChange} value={question.define_other} checked={question.define_other} /></Col></Row>
                    }
                </PollCardMenu>
            }
        </div>
    )
}

PollQuestion.propTypes = {
    propQuestion:PropTypes.object.isRequired,
    handleSaveQuestion: PropTypes.func.isRequired,
    handleRemoveQuestion: PropTypes.func.isRequired,
    handleDuplicateQuestion: PropTypes.func.isRequired,
}