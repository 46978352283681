import React, {useEffect, useRef, useState} from "react";
import {Col, ListGroup, ListGroupItem, Overlay, Popover, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useOutsideAlerter} from "../../../../hooks/outsideAlerterHook";
import {PurpleSwitch} from "../../../../Utils/Global";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import LoadingView from "../../Layouts/LoadingView";
import EbloomTooltip from "./EbloomTooltip";
import _ from "lodash";
import CommentIcon from "../Icons/CommentIcon";
import {Colors} from "../../../../Utils/Colors";

const CommentTooltip = (props) => {
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [translate, setTranslate] = useState(false);
    const [translatedComments, setTranslatedComments] = useState([]);
    const [loadingComment, setLoadingComment] = useState(false)

    const lang = getLanguage()
    const t = useTranslation()
    const handleClick = (event) => {
        setShow(!show);
    };

    const resetAndClose = () => {
        if(show){
            setShow(false);
        }
    }

    const {comments, isDashboardManager,isTranslationEnable,showTranslation,colors} = props;

    const smiley = (value) => {
        switch (value) {
            case 1:
                return "smileyUnhappy very_small_smile";
            case 2:
                return "smileySad very_small_smile";
            case 3:
                return "smileyPokerface very_small_smile";
            case 4:
                return "smileyGlad very_small_smile";
            case 5:
                return "smileyHappy very_small_smile";
            default:
                return "smileyPokerface very_small_smile";
        }
    };

    const fetchTranslation = () => {
        if(translate || isTranslationEnable){
            setLoadingComment(true)
            const data = {
                text : comments.map(el => el.comment),
                lang : lang === "en" ? "en-GB" : lang
            }
            if(translatedComments.length === 0){
                HttpApi.post("/integration/translate",data).then(response => {
                    if(response && response.data){
                        const listTranslated = response.data.map((el,index) => {
                            let nameLang = new Intl.DisplayNames([lang],{type:"language"})
                            return {
                                comment: el.text,
                                score: comments[index].score,
                                detectedLang: el.detectedSourceLang,
                                nameDetectedLang: nameLang.of(el.detectedSourceLang)
                            }
                        })
                        setTranslatedComments(listTranslated)
                    }
                }).catch(e =>{
                    notifyError(e)
                }).finally(()=> setLoadingComment(false))
            }else{
                setTimeout(()=>{
                    setLoadingComment(false)
                },300)

            }


        }

    }

    const processFrenchText = (lang) => {
        const vowels = ["a","e","i","o","u","y","h"]
        if(vowels.includes(lang[0])){
            return "de l'"+ lang
        }else{
            return "du "+ lang
        }

    }

    useEffect(() => {
        if(comments && comments.length > 0 && show){
            if(isTranslationEnable && !showTranslation){
                fetchTranslation()
                setTranslate(true)
            }else if (isTranslationEnable && showTranslation){
                fetchTranslation()
                setTranslate(true)
            }
        }
    }, [comments,show]);


    useOutsideAlerter(ref, () => {resetAndClose()});

    return(
        <div ref={ref} className={"cursor-pointer"}>
            <div className={"inline-block mr-5 -mb-8"} onClick={(comments && comments.length > 0) ? handleClick : () => {}}>
                <CommentIcon width={22} height={22} colors={colors}/>
            </div>
            <span style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}} className={"ebloom-normal-text  " +  ((comments && comments.length > 0) ? "underline" : "") } onClick={(comments && comments.length > 0) ? handleClick : () => {}}>
                {(comments && comments.length > 0) ? comments.length : 0}
            </span>
            <Overlay
                show={show}
                target={ref.current}
                placement="bottom-start"
                container={ref.current}
                containerPadding={20}
            >
                <Popover style={{
                    borderColor : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
                    backgroundColor : colors ? colors.background : Colors.EBLOOM_TRANSPARENT_BLUE,
                    '--border-color':  colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE
                }} className={"ebloom-comment-arrow border-1 p-2 w-500 max-w-500 z-50"} id="popover-ebloom">
                    <Popover.Body style={{
                        backgroundColor : colors ? colors.background : Colors.EBLOOM_TRANSPARENT_BLUE
                    }} className={" p-0"}>
                        {
                            showTranslation && isTranslationEnable &&
                            <div className={"flex flex-row justify-end items-center"}>
                                <p>{_.upperFirst(t("admin.translationComment"))}</p>
                                <PurpleSwitch checked={translate} onClick={() => {
                                    setTranslate(!translate)
                                }}/>
                            </div>
                        }

                        <ListGroup variant="flush" className={"ebloom-divider overflow-y-auto overflow-y-auto-custom max-h-350"} style={{'--background-color': colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,'--border-color': colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}>
                            {
                                loadingComment ?
                                    <div className={"h-100 flex justify-center items-center"}>
                                        <LoadingView/>
                                    </div>

                                    :
                                translate && translatedComments.length > 0 && isTranslationEnable ?
                                    translatedComments && translatedComments.map((comment, index) => (
                                        <ListGroupItem style={{
                                            backgroundColor : colors ? colors.background : Colors.EBLOOM_TRANSPARENT_BLUE
                                        }} className={" relative h-full"} key={index}>
                                            <Row className={"flex flex-row  items-center"}>
                                                {
                                                    isDashboardManager &&
                                                    <Col lg={1} md={1} sm={1} className={"pr-0"}>
                                                        <div className={"float-left"}>
                                                            <div className={smiley(comment.score)}/>
                                                        </div>
                                                    </Col>
                                                }
                                                <Col lg={10} md={10} sm={10}>
                                                    <div>{isDashboardManager ? comment.comment : comment}</div>
                                                </Col>
                                                {
                                                    comment.detectedLang !== lang && showTranslation &&
                                                    <Col className={"h-full"} lg={1} md={1} sm={1}>
                                                        <div className={"h-full absolute top-10"}>
                                                            <EbloomTooltip text={_.upperFirst(t("admin.translationInfoTooltip", {source : lang === "fr" ? processFrenchText(comment.nameDetectedLang) : comment.nameDetectedLang, lang:( lang==="fr" ? "le " :"") +  new Intl.DisplayNames([lang],{type:"language"}).of(lang)}))} placement={"top"}>
                                                                <div className={"w-20 h-20 bg-white-blue rounded-3xl flex justify-center items-center"}>
                                                                    <div className={" mb-1"}>
                                                                        <span style={{
                                                                            color : colors ? colors.tertiary : Colors.EBLOOM_CONTRAST_BLUE
                                                                        }} className={" h-full w-full"}>t</span>
                                                                    </div>

                                                                </div>
                                                            </EbloomTooltip>
                                                        </div>

                                                    </Col>
                                                }
                                            </Row>
                                        </ListGroupItem>
                                    ))
                                    :
                                    comments && !translate && comments.map((comment, index) => (
                                        <ListGroupItem style={{
                                            backgroundColor : colors ? colors.background : Colors.EBLOOM_TRANSPARENT_BLUE
                                        }} className={" relative h-full"} key={index}>
                                            <Row className={"flex flex-row  items-center"}>
                                                {
                                                    isDashboardManager &&
                                                    <Col lg={1} md={1} sm={1} className={"pr-0"}>
                                                        <div className={"float-left"}>
                                                            <div className={smiley(comment.score)}/>
                                                        </div>
                                                    </Col>
                                                }
                                                <Col lg={10} md={10} sm={10}>
                                                    <div>{isDashboardManager ? comment.comment : comment}</div>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    ))
                            }
                        </ListGroup>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    )
}

CommentTooltip.propTypes = {
    comments: PropTypes.array,
    isDashboardManager: PropTypes.bool,
    isTranslationEnable: PropTypes.bool,
    showTranslation: PropTypes.bool,
    colors : PropTypes.object
}

CommentTooltip.defaultProps = {
    isDashboardManager: false,
    isTranslationEnable:false,
    showTranslation:false,
    showOrigin:false,
    colors : null
}

export default CommentTooltip;