import React, {useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import CreateIcon from "@mui/icons-material/Add";
import {ListGroup, Badge, Row, Col} from "react-bootstrap";
import {notifyError} from "../../../../Utils/Notification";
import {PropTypes} from "prop-types";
import _ from "lodash";
import ActionButtons from "../Button/ActionButtons";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import EbloomSearchBar from "../Input/EbloomSearchBar";
import CustomButton from "../Button/CustomButton";
import moment from "moment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CategoryTag from "../CategoryDisplay/CategoryTag";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import TablePagination from "@mui/material/TablePagination/TablePagination";
import TagList from "../Input/TagList";
import {useNavigate} from "react-router-dom";
import CategoryList from "../Input/CategoryList";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {Modules, SettingTypes, QuestionSectors} from "../../../../Utils/Global";
import {useUserData} from "../../../../hooks/userHook";
import FreeTrialModal from "../Modal/FreeTrialModal";
import SectorList from '../Input/SectorList';

const QuestionList = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("text");
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(-1);
    const [showCustom, setShowCustom] = useState(false);
    const [showSector, setShowSector] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [anchor, setAnchor] = useState(null);
    const [isModelCustomEnable, setIsModelCustomEnable] = useState(false);
    const [showFreeModal, setShowFreeModal] = useState(false);
    const [selectedSector, setSelectedSector] = useState('all');

    const {questions, showPagination, tablePage, handleTablePage, showActionButtons, showAction, buttonVariant, buttonType, buttonText, buttonIcon, handleAction, handleEdit, handleDelete, showLastUsed, isBackoffice, returnObject, handleBackofficeButton, showTag, tagLink, showDiagnostic, companyToEdit,entityId} = props;


    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()
    const isSuperAdmin = user.roles.includes("global_super_admin")

    const isFree = Modules.FREE.includes(user.module)

    const fetchModelCustomSetting = () => {
        let url = "/settings/getForCompany/"+ SettingTypes.CUSTOM_CATEGORIES;
        if(!!entityId && isSuperAdmin){
            url = "/settings/group/getForCompany/"+ + SettingTypes.CUSTOM_CATEGORIES + "/" + entityId
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setIsModelCustomEnable(Boolean(response.data.value))
            }else{
                setIsModelCustomEnable(false)
            }
        }).catch(error => {
            notifyError(error);
        })
    }


    const fetchCategories = () => {
        let url = "/categories/getMainCategories"
        if(!!entityId && isSuperAdmin){
            url = "/categories/getMainCategories/"+ entityId
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                response.data.splice(0,0,{id:-1, name:_.capitalize(t("core.all"))})
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const fetchTags = () => {
        let url = "/tags/getAll/"
        if(!!entityId && isSuperAdmin){
            url = "/tags/getAll/"+ entityId
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                response.data.splice(0,0,{id:-1, name:_.capitalize(t("core.allBis")), id_category:-1})
                setTags(response.data);
            }else{
                setTags([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const filterByCategory = (event) => {
        const id = event.target.value;
        const name = event.target.name;
        if(name === "category-filter"){
            setSelectedCategory(id);
            setSelectedTag(-1);
        }else if(name === "tag-filter"){
            setSelectedTag(id);
        }
        if(tablePage > 0){
            handleTablePage(null, 0);
        }
    };

    const sortQuestions = (a, b) => {
        if(a[orderBy] === b[orderBy]){
            if(orderBy === "nbAskedCompany"){
                if(a.nbAskedTag<b.nbAskedTag){
                    return order === "desc" ? 1 : -1;
                }else if(a.nbAskedTag>b.nbAskedTag) {
                    return order === "desc" ? -1 : 1;
                }
            }
            return 0;
        }
        if(a[orderBy] === null){
            return order === "desc" ? 1 : -1;
        }
        if(b[orderBy] === null){
            return order === "desc" ? -1 : 1;
        }
        if(a[orderBy]<b[orderBy]){
            return order === "desc" ? 1 : -1;
        }
        if(a[orderBy]>b[orderBy]){
            return order === "desc" ? -1 : 1;
        }
        return 0;
    };

    const handleChange = event => {
        if(tablePage > 0){
            handleTablePage(null, 0);
        }
        setSearchValue(event.target.value);
    };

    const handleChangeOrderBy = (type) => {
        if(order === "asc" && type !== "nbAskedCompany"){
            setOrder("desc");
        }else if(order === "desc" && type === "nbAskedCompany"){
            setOrder("asc");
        }
        if(type === "text" || type === "sector"){
            setOrder("asc");
        }
        if(type !== orderBy){
            setOrderBy(type);
        }
        if(tablePage > 0){
            handleTablePage(null, 0);
        }
        setAnchor(null);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    const handleClose = () => {
        setAnchor(null);
    }

    const handleOpen = (event) => {
        setAnchor(event.target);
    }

    const displayDate = (date) => {
        if(!date){
            return _.capitalize(t("core.neverUsed"));
        }

        let localeMoment = moment(date);

        localeMoment.locale(getLanguage());

        return _.capitalize(localeMoment.fromNow());

    };

    const displayOrder = () => {
        switch (orderBy){
            case "nbAskedAll":
                return _.upperFirst(t("core.mostTrending"));
            case "nbAskedCompany":
                return _.upperFirst(t("core.lessExplored"));
            case "lastUsed":
                return _.capitalize(t("core.lastUsed"));
            case "company":
                return _.capitalize(t("core.customized"));
            case "text":
                return _.upperFirst(t("core.alphabetic"));
            case "sector":
                return _.upperFirst(t("core.sector"));
            default:
                return _.upperFirst(t("core.mostTrending"));
        }
    }

    useEffect(() => {
        fetchCategories();
        fetchTags();
        fetchModelCustomSetting()
    }, [entityId]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let id_tag = selectedTag;
        if(urlParams.get("tag")){
            id_tag = parseInt(urlParams.get("tag"));
            urlParams.delete("tag");
            setSelectedTag(id_tag);
        }

        if(!showPagination){
            setRowsPerPage(10000);
        }
        if(questions.filter(el => (el.company !== null && el.company !== "Global")).length > 0){
            setShowCustom(true);
        }else{
            setShowCustom(false);
        }
        if(questions.filter(el => el.sector !== QuestionSectors.GLOBAL).length > 0){
            setShowSector(true);
        }

    }, [questions]);

    return(
        <>
            <Row className={'mb-20 flex justify-center items-center'} md={(isBackoffice || showSector) ? 4 : 3}>
                {
                    (isBackoffice || showSector) &&
                  <Col>
                        <SectorList value={selectedSector} handleChange={(event) => setSelectedSector(event.target.value)} color="purple" label={_.upperFirst(t("core.sector"))}/>
                  </Col>
                }
                <Col data-browseLibrary={'categories'}>
                    <CategoryList isModelCustomEnable={isModelCustomEnable} handleChange={filterByCategory} value={selectedCategory} categories={categories} color={"purple"} label={_.capitalize(t("core.category.categories"))}/>
                </Col>
                <Col data-browseLibrary={'tags'}>
                    <TagList isModelCustomEnable={isModelCustomEnable} tags={tags} value={selectedTag} id_category={selectedCategory} handleChange={filterByCategory} color={"purple"}/>
                </Col>
                <Col data-browseLibrary={'search'}>
                    <EbloomSearchBar className={"w-full"} searchValue={searchValue} handleChange={handleChange} size="small" color="blue"/>
                </Col>
            </Row>

            {
                questions.length > 0 &&
                    <div>
                        <Row>
                            <Col data-browseLibrary={'order'} md={3} className={"ml20 font-weight-bold purple pointer"} onClick={handleOpen}>
                                <span>{displayOrder()}</span>
                                <ArrowDropDownIcon style={{fontSize:32}}/>
                            </Col>
                        </Row>
                        <Menu id={"sortMenu"} anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={handleClose}>
                            <EbloomTooltip text={_.upperFirst(t("core.mostTrendingHelper"))} placement={"right"}>
                                <MenuItem key={1} onClick={() => {handleChangeOrderBy("nbAskedAll")}}>
                                    {_.upperFirst(t("core.mostTrending"))}
                                </MenuItem>
                            </EbloomTooltip>
                            {
                                !isBackoffice &&
                                <EbloomTooltip text={_.upperFirst(t("core.lessExploredHelper"))} placement={"right"}>
                                    <MenuItem key={2} onClick={() => {handleChangeOrderBy("nbAskedCompany")}}>
                                        {_.upperFirst(t("core.lessExplored"))}
                                    </MenuItem>
                                </EbloomTooltip>
                            }
                            {
                                showCustom &&
                                <MenuItem key={3} onClick={() => {handleChangeOrderBy("company")}}>
                                    {_.upperFirst(t("core.customized"))}
                                </MenuItem>
                            }
                            {
                                (isBackoffice || showSector) &&
                              <MenuItem key={4} onClick={() => {handleChangeOrderBy("sector")}}>
                                  {_.upperFirst(t("core.sector"))}
                              </MenuItem>
                            }
                            {
                                showLastUsed &&
                                <EbloomTooltip text={_.upperFirst(t("core.lastUsedHelper"))} placement={"right"}>
                                    <MenuItem key={5} onClick={() => {handleChangeOrderBy("lastUsed")}}>
                                        {_.upperFirst(t("core.lastUsed"))}
                                    </MenuItem>
                                </EbloomTooltip>
                            }
                            <MenuItem key={6} onClick={() => {handleChangeOrderBy("text")}}>
                                {_.upperFirst(t("core.alphabetic"))}
                            </MenuItem>
                        </Menu>
                        <ListGroup variant={"flush"}>
                            {
                                questions?.filter(el => (el.id_category === selectedCategory || selectedCategory === -1) && (el.id_tag === selectedTag || selectedTag === -1) && (el.sector === selectedSector || selectedSector === 'all')).filter(el => _.lowerCase(el.text).includes(_.lowerCase(searchValue))).sort(sortQuestions).slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage).map((question, index) => {
                                    const category = {
                                        id : question.id_category,
                                        color : question.color,
                                        name : question.name,
                                        icon : question.icon
                                    }
                                    return(

                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={isBackoffice ? ((question.company && question.company !== "Global") || question.sector !== QuestionSectors.GLOBAL) ? 5 : 7 : showLastUsed ? ((question.company && question.company !== "Global") || question.sector !== QuestionSectors.GLOBAL) ? 4 : 6 : showTag ? ((question.company && question.company !== "Global") || question.sector !== QuestionSectors.GLOBAL) ? 5 : 7 : ((question.company && question.company !== "Global") || question.sector !== QuestionSectors.GLOBAL) ? 7 : 9}>
                                                    {
                                                        showDiagnostic && question.isDiagnostic ?
                                                            <EbloomTooltip text={_.upperFirst(t("core.tag.diagnostic", {tag:t("core.tag." + question.tag)}))}>
                                                                <span style={{cursor:"default", fontWeight:620}}>{question.text}</span>
                                                            </EbloomTooltip>
                                                            :
                                                            <span>{question.text}</span>
                                                    }
                                                </Col>
                                                {
                                                    ((question.company && question.company !== "Global") || question.sector !== QuestionSectors.GLOBAL) &&
                                                    <Col md={2} className={"text-center"}>
                                                        {
                                                          (question.company && question.company !== "Global") &&
                                                          <Badge bg={""} className={"bg-contrast-blue text-white"}>{isBackoffice ? question.company : _.capitalize(t("core.customized"))}</Badge>
                                                        }
                                                        {
                                                          (question.sector && question.sector !== QuestionSectors.GLOBAL) &&
                                                          <Badge className={"bg-contrast-purple text-white"}>{_.upperFirst(t("core."+question.sector))}</Badge>
                                                        }
                                                    </Col>
                                                }
                                                <Col md={showTag ? 1 : 2} className={"text-center"}>
                                                    <CategoryTag modelCustomEnable={isModelCustomEnable} category={category} width={showTag ? 75 : 120}/>
                                                </Col>
                                                {
                                                    showTag &&
                                                    <Col md={2} className={"center"}>
                                                        <span className={tagLink ? "ebloom-link-purple" : "cursorDefault"} onClick={
                                                            tagLink && !isFree ?
                                                                () => {
                                                                    companyToEdit ?
                                                                        navigate("/backoffice/library?tag=" + question.id_tag)
                                                                        :
                                                                        !!entityId ?
                                                                            navigate("/admin/questions/library/"+entityId+"?tag=" + question.id_tag)
                                                                            :
                                                                            navigate("/admin/questions/library?tag=" + question.id_tag)}
                                                                :
                                                                isFree ?
                                                                    () => {setShowFreeModal(true)}
                                                                    :
                                                                    () => {}
                                                        }>
                                                            {t("core.tag." + question.tag)}
                                                        </span>
                                                    </Col>
                                                }
                                                {
                                                    showLastUsed &&
                                                        <Col md={2} className={"text-center"}>
                                                            {displayDate(question.lastUsed)}
                                                        </Col>
                                                }
                                                {
                                                    showAction &&
                                                    <Col md={1} className={"text-center"}>
                                                        <CustomButton type={buttonType} variant={buttonVariant} style={{float:"right"}} handleClick={handleAction(returnObject ? question : question.id)} icon={buttonIcon} text={buttonText}/>
                                                    </Col>
                                                }
                                                {
                                                    showActionButtons &&
                                                        <Col md={2} style={{paddingLeft:0, paddingRight:0}}>
                                                            <ActionButtons handleDelete={handleDelete} handleEdit={handleEdit(true, question)} id={question.id} url="/questions/delete" />
                                                        </Col>
                                                }

                                            </Row>
                                        </ListGroup.Item>
                                    )})
                            }
                        </ListGroup>
                        {
                            showPagination &&
                            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                             component="div"
                                             labelRowsPerPage={_.capitalize(t("core.rowsPerPage"))}
                                             labelDisplayedRows={({ from, to, count }) => { return from +"-" + to + " " + t("core.of") + " " + count}}
                                             count={questions.filter(el => (el.id_category === selectedCategory || selectedCategory === -1) && (el.id_tag === selectedTag || selectedTag === -1) && (el.sector === selectedSector || selectedSector === 'all')).filter(el => _.lowerCase(el.text).includes(_.lowerCase(searchValue))).length}
                                             page={tablePage}
                                             onPageChange={handleTablePage}
                                             rowsPerPage={rowsPerPage}
                                             onRowsPerPageChange={handleChangeRowsPerPage}/>
                        }
                        <FreeTrialModal show={showFreeModal} handleClose={()=> setShowFreeModal(false)}/>

                    </div>
            }
        </>
    )
}

QuestionList.propTypes = {
    questions: PropTypes.array.isRequired,
    handleAction: PropTypes.func,
    showAction: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    buttonVariant:PropTypes.string,
    buttonType:PropTypes.string,
    buttonText:PropTypes.string,
    buttonIcon:PropTypes.object,
    handleEdit:PropTypes.func,
    handleDelete:PropTypes.func,
    showLastUsed:PropTypes.bool,
    isBackoffice:PropTypes.bool,
    returnObject:PropTypes.bool,
    handleBackofficeButton:PropTypes.func,
    showPagination:PropTypes.bool,
    tablePage:PropTypes.number,
    handleTablePage:PropTypes.func,
    showTag:PropTypes.bool,
    tagLink:PropTypes.bool,
    showDiagnostic: PropTypes.bool,
    isEbloomSelection: PropTypes.bool,
    entityId : PropTypes.number,
};

QuestionList.defaultProps = {
    handleAction: (id) => {console.log("No function defined : " + id)},
    showAction:true,
    showActionButtons:false,
    buttonVariant:"outline-success",
    buttonIcon: <CreateIcon/>,
    buttonType: "other",
    handleEdit: (open, question) => {},
    handleDelete: () => {},
    showLastUsed:false,
    isBackoffice:false,
    returnObject:false,
    showPagination:false,
    tablePage:0,
    showTag:false,
    tagLink:false,
    showDiagnostic:false,
    isEbloomSelection:false,
    entityId : null,
};

export default QuestionList;