import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import moment from "moment";
import ActionButtons from "../Button/ActionButtons";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {Badge} from "react-bootstrap";
import EbloomSearchBar from "../Input/EbloomSearchBar";
import CustomButton from "../Button/CustomButton";
import {ReactComponent as CheckedIcon} from "../../symbols/checked.svg";
import {ModelTypes} from "../../../../models/Types";
import {UserStatus} from "../../../../Utils/Global";
import {useUserData} from "../../../../hooks/userHook";
import LoadingView from "../../Layouts/LoadingView";
import {Checkbox} from "@mui/material";

function descendingComparator(a, b, orderBy) {
    let aOrderBy = typeof a[orderBy] === "string" ? _.lowerCase(a[orderBy]) : a[orderBy];
    let bOrderBy = typeof b[orderBy] === "string" ? _.lowerCase(b[orderBy]) : b[orderBy];
    if (bOrderBy < aOrderBy) {
        return -1;
    }
    if (bOrderBy > aOrderBy) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function filterWithSearch(array, searchValue){
    return array.filter((el) => _.lowerCase(JSON.stringify(el)).includes(_.lowerCase(searchValue)));
}

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort, model, items, showActions, showCounter} = props;
    const t = useTranslation();
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {
                items[0] &&

                <>
                    {showCounter &&
                    <TableCell
                        key={"counter-id"}
                        align={'center'}
                        padding={'normal'}
                    >
                        {"#"}
                    </TableCell>
                    }


                    {Object.entries(model).map(([key,modelValue]) => (
                        (items[0].hasOwnProperty(key) !== undefined && modelValue.table && modelValue.table.show) &&
                        <TableCell
                            key={modelValue.id}
                            align={'center'}
                            padding={'normal'}
                            sortDirection={orderBy === modelValue.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === modelValue.id}
                                direction={orderBy === modelValue.id ? order : 'asc'}
                                onClick={createSortHandler(modelValue.id)}
                            >
                                {_.capitalize(t(modelValue.label))}
                                {orderBy === modelValue.id ? (
                                    <span className={"hidden"}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>



                    ))}
                    {
                        showActions &&
                        <TableCell
                            key={"actions-id"}
                            align={'center'}
                            padding={'normal'}>
                            {_.upperFirst(t("core.actions"))}
                        </TableCell>

                    }


                </>

                }

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    showActions: PropTypes.bool.isRequired,
    showCounter: PropTypes.bool.isRequired,
};


const EnhancedTableToolbar = (props) => {
    const {items, handleFilter, tablePage, handleTablePage} = props;
    const [value, setValue] = React.useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
        let filteredArray = filterWithSearch(items, event.target.value);
        handleFilter(filteredArray);
        if(tablePage > 0){
            handleTablePage(null, 0);
        }
    };

    useEffect(() => {
        let filteredArray = filterWithSearch(items, value);
        handleFilter(filteredArray);
    }, [items]);

    return (
        <Toolbar
            className={clsx({paddingLeft:5, paddingRight:5})}
        >

        <EbloomSearchBar className={"w-full"} searchValue={value} handleChange={handleChange}/>


    </Toolbar>
    );
};

export default function TableViewV2(props) {
    const t = useTranslation();
    const {tablePage, handleTablePage, loading, items, model, showActions, showCounter, showDelete, showEdit, showDetail, handleDelete, handleEdit, handleView, iconDetail, variantDetail, typeDetail, textDetail, handleRowClick, rowCursor, min, handleObject, defaultOrderBy, defaultOrder,handleDuplicate, showCheckbox, handleCheckbox, checkedItems,getTextConfirmation} = props;
    const [order, setOrder] = React.useState(defaultOrder);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [clonedItems, setClonedItems] = React.useState(_.cloneDeep(items));
    const [filteredItems, setFilteredItems] = React.useState(_.cloneDeep(items));
    const user = useUserData()
    const isNotFree = user.module !== "free"
    useEffect(() => {
        setClonedItems(_.cloneDeep(items));
        setFilteredItems(_.cloneDeep(items));
        setOrderBy(defaultOrderBy);
    }, [items]);


    const handleFilterItems = (newValue) => {
        setFilteredItems(newValue);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        handleTablePage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredItems.length - tablePage * rowsPerPage);

    return (
        <div className={"w-full"}>
            <Paper className={"w-full mb-5"}>
                <EnhancedTableToolbar items={clonedItems} handleFilter={handleFilterItems} handleTablePage={handleTablePage} tablePage={tablePage}/>
                <TableContainer>
                    <Table
                        stickyHeader
                        className={"min-w-750"}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            model={model}
                            items={clonedItems}
                            showCounter={showCounter}
                            showActions={showActions}
                        />
                        <TableBody>
                            {loading ? <LoadingView/> : stableSort(filteredItems, getComparator(order, orderBy))
                                .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                                .map((item, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                        style={{cursor:rowCursor}}
                                        data-tour={item.name === "eBloom Selection" ? 'eBloomPlaylist' : 'row'}
                                    >
                                        {
                                            showCounter &&
                                            <TableCell align={"center"} onClick={handleRowClick(item)}>{((index+1) + (rowsPerPage*tablePage))}</TableCell>
                                        }
                                        {
                                            Object.entries(model).map(([key,modelValue], indexItem) => {
                                                let value = item.hasOwnProperty(key) ? item[key] : null;

                                                if(value && modelValue.table && modelValue.table.show){
                                                    if(modelValue.type === ModelTypes.RELATIONS){
                                                        value = value[0][modelValue.key];
                                                    }else if(modelValue.type === ModelTypes.RELATION){
                                                        value = value.key;
                                                    }else if(modelValue.type === ModelTypes.BOOLEAN){
                                                        value = true === value ? <CheckedIcon className={"w-35 h-35 m-auto contrast-purple-icon"}/> : "";
                                                    }else if(modelValue.type === ModelTypes.PRISMA_RELATION){
                                                        value = modelValue.relationLink(value);
                                                    }else if(modelValue.type === ModelTypes.PRISMA_RELATIONS){
                                                        if(modelValue.filterValueRelation) {
                                                            value = modelValue.filterValueRelation(value)
                                                        }
                                                        value = value.map(el => modelValue.relationLink(el)).join(", ")
                                                    }
                                                    if(modelValue.table.formatValue){
                                                        value = modelValue.table.formatValue(value);
                                                    }
                                                }
                                                return(
                                                (modelValue.table && modelValue.table.show) &&
                                                    <TableCell key={indexItem} align="center" onClick={handleRowClick(item)}>
                                                        {(modelValue.type === "DATE" || modelValue.type === "DATETIME") ?
                                                            moment(value).format(model[key].type === "DATE" ? "DD/MM/YYYY" : "DD/MM/YY HH:mm").toString() : (key === "isValid" || key === "status")  ?
                                                                <Badge data-tour={'badge-'+key} pill={key === "isValid"} className={(value === UserStatus.SAVED ? "text-grey " : "text-white ") + ((value === "valid" || value === "registered") ? "bg-strong-green" : value === "invited" ? "bg-grey" : value === UserStatus.SAVED ? "bg-transparent" : "bg-strong-red")}>{value.toString() === "rule1" ? _.upperFirst(t("admin.rule1", {min:min})) :_.upperFirst(t("admin." + value.toString()))}</Badge> : (!value && modelValue.table.alternative) ? <div onClick={modelValue.table.alternativeAction(true, item)}>{modelValue.table.alternative}</div>
                                                                    :
                                                                    modelValue.table.badge ?
                                                                        <Badge className={"text-white bg-contrast-purple"}>{value}</Badge>
                                                                        :
                                                                        modelValue.table.format && modelValue.table.format === "length" ?
                                                                            value.length
                                                                            :
                                                                        value
                                                        }
                                                    </TableCell>


                                            )})}
                                        {showActions &&
                                        <TableCell align={"center"} className={"flex items-center justify-center"}>
                                                {
                                                    showDetail &&
                                                    <div data-tour={model.productTour ? model.productTour.view + "-" + index : ''}>
                                                        <CustomButton  type={typeDetail} variant={variantDetail} handleClick={handleView(handleObject ? item : item.id)} icon={iconDetail} text={textDetail}/>
                                                    </div>

                                                }
                                                {
                                                    item.name !== "eBloom Selection" ?
                                                        <ActionButtons dataTour={model.productTour ? model.productTour.edit + "-" + index : ''} showEdit={showEdit} showDelete={showDelete} url={model.crud.delete?.url || model.crud.delete} method={model.crud.delete?.method || 'POST'} id={item.id} handleDelete={handleDelete} handleEdit={handleEdit(true, item)} getTextConfirmation={getTextConfirmation}/>
                                                        : isNotFree ?
                                                        <ActionButtons showEdit={false} showDelete={false} showDuplicate={true} url={model.crud.create} id={item.id} handleDuplicate={() =>handleDuplicate(item)} />
                                                        : null
                                                }
                                                {
                                                   showCheckbox &&
                                                    <Checkbox className={"checkbox-purple"} checked={checkedItems.find(el => el.id === item.id) !== undefined} onChange={() => handleCheckbox(item)}/>
                                                }
                                        </TableCell>
                                        }

                                    </TableRow>
                                            ))
                                        }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    labelRowsPerPage={_.capitalize(t("core.rowsPerPage"))}
                    labelDisplayedRows={({ from, to, count }) => { return from +"-" +to + " " + t("core.of") + " " + count}}
                    component="div"
                    count={filteredItems.length}
                    rowsPerPage={rowsPerPage}
                    page={tablePage}
                    onPageChange={handleTablePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

TableViewV2.propTypes = {
    items: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    showCounter: PropTypes.bool,
    showActions: PropTypes.bool,
    showEdit: PropTypes.bool,
    showDelete: PropTypes.bool,
    showDetail: PropTypes.bool,
    iconDetail: PropTypes.object,
    variantDetail: PropTypes.string,
    typeDetail: PropTypes.string,
    textDetail: PropTypes.string,
    handleEdit: PropTypes.func,
    handleRowClick: PropTypes.func,
    handleDuplicate: PropTypes.func,
    rowCursor:PropTypes.string,
    tablePage:PropTypes.number,
    handleTablePage:PropTypes.func,
    min:PropTypes.number,
    handleObject:PropTypes.bool,
    defaultOrderBy:PropTypes.string,
    defaultOrder:PropTypes.string,
    getTextConfirmation:PropTypes.func,
    loading:PropTypes.bool,
    showCheckbox:PropTypes.bool,
    handleCheckbox:PropTypes.func,
    checkedItems:PropTypes.array
};

TableViewV2.defaultProps = {
    showCounter: true,
    showActions: true,
    showEdit: true,
    showDelete:true,
    showDetail:false,
    iconDetail:<VisibilityOutlinedIcon/>,
    variantDetail: "outline-info",
    handleEdit: () => {},
    handleDuplicate: () => {},
    handleRowClick: (item) => event => {},
    rowCursor:"normal",
    tablePage:0,
    min:8,
    handleObject:false,
    defaultOrderBy:"",
    getTextConfirmation:() => {return null},
    defaultOrder:"asc",
    loading:false,
    showCheckbox:false,
    handleCheckbox:() => {},
    checkedItems:[]
};
