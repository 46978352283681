import React, {useRef} from 'react';
import {useDrop} from "react-dnd";

const DropZoneCategory = (props) => {
    const {category,changeTagCategory,children} = props

    const ref = useRef(null);
    const [{canDrop, isOver}, drop] = useDrop({
        accept: "tag",

        drop: (item ) => changeTagCategory(item, category),
        canDrop : (item, monitor) => {
            if(category.tags.filter(el => el.name === "General").length > 0 && item.name === "General"){
                return false
            }
            return category.tags.filter(el => el.id_tag === item.id).length === 0
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    drop(ref);
    return <div ref={ref}  className={"mt-5 h-30 w-full " + (canDrop ? " rounded-md border-2 border-contrast-blue border-dashed" : "")}> {children}</div>;
};

export default DropZoneCategory;
