import DisplayAdminView from "../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import React, {useState} from "react";
import CustomCategories from "./CustomCategories";
import {useUserData} from "../../../../hooks/userHook";
import EntitiesSwitcher from "../../../Core/Modules/Button/EntitiesSwitcher";

const CustomCategoriesPage = (props) => {
    const user = useUserData()
    const [selectedEntity, setSelectedEntity] = useState(null);
    return(
        <DisplayAdminView selectedIndex={26} menu={"settings"} {...props}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher entity={selectedEntity} handleSwitch={(value) => {setSelectedEntity(value)}} alternative={true}/>
                            </div>
                        }
                        <CustomCategories selectedEntity={selectedEntity} {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )
}

export default CustomCategoriesPage;