import React, {useContext, useEffect, useState} from "react";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import { Button, Accordion, ListGroup} from "react-bootstrap";
import {getLanguages} from "../../../../lang/translations";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import {
    IdentityTypes,
    isNullOrUndefined, Modules,
    TourType
} from "../../../../Utils/Global";
import HttpApi from "../../../../httpApi";
import {FormControlLabel, MenuItem} from "@mui/material";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {createSuccessfullyDeletedNotification} from "../../../../Utils/Notification";
import LoadingView from "../../../Core/Layouts/LoadingView";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton/IconButton";
import {useSavePage} from "../../../../hooks/savePageHook";
import {useUserData} from "../../../../hooks/userHook";
import CustomLibraryTour from "../../../../Utils/ProductTour/Tours/CustomLibrary/CustomLibraryTour";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import IdentityGlobal from "./IdentityItems/IdentityGlobal";
import IdentityCustom from "./IdentityItems/IdentityCustom";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";

const Identity = (props) => {

    const [loading, setLoading] = useState(false);
    const [langs, setLangs] = useState([]);
    const [step, setStep] = useState(0);
    const [listIdentities, setListIdentities] = useState([]);
    const [selectedLang, setSelectedLang] = useState("fr");
    const [anchor, setAnchor] = useState(null);
    const [expandedKey, setExpandedKey] = useState("0");
    const [showModalLang, setShowModalLang] = useState(false);
    const [teams, setTeams] = useState([]);
    const [sideFormTeams, setSideFormTeams] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [isGroupSelected, setIsGroupSelected] = useState(false);
    const [placeholders, setPlaceholders] = useState([]);

    const {companyToEdit, selectedEntity} = props;

    const {updateStep, setContinuous, setTourStarted} = useContext(ProductTourContext);

    const user = useUserData();

    const isSuperAdmin = user.roles.includes("global_super_admin");
    const isStarter = user.module === Modules.HAPPY[0];


    const page = useSavePage(true);


    const t = useTranslation();

    const handleLangs = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;
        setLangs(prevState => {
            let langs = [...prevState];
            if (checked) {
                langs.push(value);
            } else {
                langs.splice(langs.indexOf(value), 1);
            }
            return langs;
        })
        if (page.isSaved) {
            page.unSave();
        }
    }

    const toggleDrawerTeams = (open, toEdit) => event => {
        event.preventDefault();
        if(!open){
            setSelectedTeams([])
        }
        setSideFormTeams(open);
    }


    const handleUpdateLang = () => {
        if(langs.length === 0){
            setStep(8);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleCloseModalLang = () => {
        setShowModalLang(false);
    }

    const handleOpenModalLang = () => {
        setAnchor(null);
        setShowModalLang(true);
    }


    const handleAccordion = (key) => {
        if (expandedKey === key) {
            setExpandedKey("-1");
        } else {
            setExpandedKey(key);
        }
    }

    const handleFetch = () => {
        fetchIdentities()
    }

    const handleCheckboxTeams = (team) => {
        if(selectedTeams.includes(team)){
            setSelectedTeams(selectedTeams.filter(el => el.team.id !== team.team.id))
        }else{
            setSelectedTeams([...selectedTeams,team])
        }
    }

    const handleStep = (number) => {
        if (step === 0) {
            setSelectedLang([langs[0]]);
            updateStep(TourType.CUSTOM_LIBRARY, 1)
        } else {
            setContinuous(false)
            updateStep(TourType.CUSTOM_LIBRARY, 3)
        }
        setStep(number);
    }

    const deleteListIdentity = (index) => {
        const newList = [...listIdentities];
        const list = newList[index]
        if(list.id){
            HttpApi.postV2("/identities/delete",{id : list.id}).then(response => {
                createSuccessfullyDeletedNotification("identity");
                fetchIdentities();
            })
        }else{
            newList.splice(index, 1);
            setListIdentities(newList);
            createSuccessfullyDeletedNotification("identity");
        }
    }

    const handleAddCustomIdentity = () => {
        setListIdentities(prevState => {
            const newList = [...prevState];
            const newCustom = {
                id : null,
                title: _.upperFirst(t("admin.identity.customTitle")) + " #"+ newList.length,
                type: IdentityTypes.TEAMS,
                identities: [],
                identity_teams: selectedTeams
            }
            newList.push(newCustom);
            setExpandedKey(String(newList.length-1));
            return newList;
        })
        setSideFormTeams(false)
        setSelectedTeams([])
    }

    const fetchTeams = () => {
        HttpApi.getV2("/teams/getAll").then(response => {
            if(response && response.data){
                const teams = response.data.map(el => ({team : el}))
                setTeams(teams);
            }else{
                setTeams([]);
            }
        })
    }

    const fetchIdentities = () => {
        setLoading(true)
        let url = ""
        if (isSuperAdmin && !isNullOrUndefined(selectedEntity)) {
            url = "/identities/getForCompany/" + selectedEntity
        } else if (!isSuperAdmin) {
            url = "/identities/getForCompany";
        }
        if ((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin) {
            HttpApi.getV2(url).then(response => {
                fetchTeams()
                if (response && response.data && response.data.length > 0) {
                    const list = response.data
                    setListIdentities(list);
                    const globalList = list.filter(el => el.type === IdentityTypes.GLOBAL)[0];
                    if (!!globalList) {
                        const global = globalList.identities
                        if (global.filter(el => el.lang === getLanguage()).length > 0) {
                            setSelectedLang(getLanguage());
                        } else {
                            setSelectedLang(global[0].lang);
                        }
                        setLangs(prevState => {
                            let langs = [...prevState];
                            global.forEach(i => {
                                if (!langs.includes(i.lang)) {
                                    langs.push(i.lang);
                                }
                            })
                            return langs;
                        })
                    }
                } else {
                    setListIdentities([{id : null,title:"General Customization", type: IdentityTypes.GLOBAL, identities: [],identity_teams:[]}])
                    setLangs([])
                    setSelectedLang(getLanguage())
                }
            }).finally(() => {setLoading(false)})
        }

    }

    useEffect(() => {
        if(!!selectedEntity){
            if(user.companyGroup !== null && user.companyGroup.id === selectedEntity){
                setIsGroupSelected(true)
            }else{
                setIsGroupSelected(false)
            }
        }
        fetchIdentities();
    }, [companyToEdit, selectedEntity])

    useEffect(() => {
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        if (productTour && productTour === "identity") {
            setTourStarted(true);
        }
        const globalList = listIdentities.find(el => el.type === IdentityTypes.GLOBAL);
        const listKeywords = []
        if(!!globalList && langs.length > 0){
            const keywordKeys = ["my manager","mon/ma manager","mijn manager","my team","mon équipe","mijn team","my colleagues","de mes collègues","mes collègues","mijn collega's",]
            globalList.identities.forEach(identity => {
                if(keywordKeys.includes(identity.key_value)){
                    const value= {
                        text:identity.text,
                        lang:identity.lang,
                        key_value:identity.key_value,
                    }
                    listKeywords.push(value)
                }
            })
        }
        setPlaceholders(listKeywords)
    }, [listIdentities]);


    return (
        <>
            <CustomLibraryTour handleStep={handleStep} stepCustomization={step}/>
            <CardDefault showButton={false} title={_.upperFirst(t("admin.identity.title"))}
                         subtitle={_.upperFirst(t("admin.identity.description"))} tourTooltip={true}
                         tourType={TourType.CUSTOM_LIBRARY}>
                {langs.length > 0 && step !==0 &&

                    <div style={{textAlign: "right", position: "relative", bottom: 65, right: 40}}>
                        <IconButton onClick={(event) => {
                            setAnchor(event.target)
                        }}>
                            <MoreHorizIcon className={"purple"}/>
                        </IconButton>
                        <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={() => {
                            setAnchor(null)
                        }}>
                            <MenuItem
                                onClick={handleOpenModalLang}>{_.upperFirst(t("admin.identity.addDeleteLang"))}</MenuItem>
                        </Menu>
                    </div>
                }
                <Accordion className={"ebloom-accordion"} activeKey={expandedKey} defaultActiveKey={expandedKey}>
                    {
                        loading ?
                            <LoadingView /> :
                            listIdentities.map((el,index) => (
                                <div className={"my-20"}>
                                    {
                                        el.type === IdentityTypes.GLOBAL?
                                            <IdentityGlobal expandedKey={expandedKey}  handleAccordion={handleAccordion} keyAccordion={index.toString()}
                                                            companyToEdit={companyToEdit} selectedEntity={selectedEntity} listIdentitiesItem={el}
                                                            langs={langs} handleLangs={handleLangs} step={step} setStep={setStep} selectedLang={selectedLang} setSelectedLang={setSelectedLang} handleFetch={() => handleFetch()} isGroupSelected={isGroupSelected}/>
                                            :
                                            <IdentityCustom expandedKey={expandedKey}  handleAccordion={handleAccordion} keyAccordion={index.toString()}
                                                            companyToEdit={companyToEdit} selectedEntity={selectedEntity} listIdentitiesItem={el}
                                                            langs={langs} handleLangs={handleLangs} selectedLang={selectedLang} setSelectedLang={setSelectedLang} handleFetch={() => handleFetch()} teams={teams}
                                                            handleDelete={()=> deleteListIdentity(index)} placeholders={placeholders}
                                            />

                                    }
                                </div>
                            ))

                    }

                </Accordion>
                <div className={"right mt-10"}>
                    {
                        langs.length > 0 && listIdentities.every(el => el.id !== null) && teams.length > 0 && teams.some(el => el.team.identity_teams.length === 0) && !isGroupSelected && !isStarter &&
                        <Button className={"ebloom-btn-purple"} size={"sm"} onClick={() => setSideFormTeams(true)}>
                            <div>{_.upperFirst(t("admin.identity.addCustom"))}</div>
                        </Button>
                    }
                </div>
            </CardDefault>
            <SideForm formTitle={_.upperFirst(t("admin.identity.customTitleTeams"))} buttonDisabled={selectedTeams.length === 0} toggleDrawer={toggleDrawerTeams} sideFormOpen={sideFormTeams} handleSave={handleAddCustomIdentity} >
                <ListGroup variant={"flush"}>
                    {
                        teams?.map((item, index) => {
                            return (
                                <ListGroup.Item >
                                    <FormControlLabel
                                        key={item.team.id}
                                        control={
                                            <Checkbox
                                                className={item.team.identity_teams.length > 0 ? "checkbox-disabled" : "checkbox-purple"}
                                                checked={selectedTeams.includes(item)}
                                                onChange={() => handleCheckboxTeams(item)}
                                                disabled={item.team.identity_teams.length > 0}
                                            />
                                        }
                                        label={<div className={"w-full flex flex-row items-center"}>
                                            {item.team.name}
                                            {
                                                item.team.identity_teams.length > 0 &&
                                                <div className={'ml-10'}>
                                                    ({_.upperFirst(t("admin.identity.teamAlreadyUsed"))})
                                                </div>
                                            }
                                        </div>}
                                        className={"w-full"}
                                    />
                                </ListGroup.Item>

                            )
                        })
                    }
                </ListGroup>
            </SideForm>
            <ConfirmModal handleClose={handleCloseModalLang} show={showModalLang}
                          title={_.upperFirst(t("admin.identity.addDeleteModalTitle"))}
                          buttonRightText={_.upperFirst(t("core.confirm"))}
                          buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={handleUpdateLang}>
                <p className={"ebloom-text ebloom-large-text purple"}>{_.upperFirst(t("admin.identity.addDeleteModalText"))}</p>
                {
                    getLanguages().map((lang, index) => (
                        <div><Checkbox className={"checkbox-purple"} checked={langs.includes(lang.value)}
                                       value={lang.value} onChange={handleLangs}/>{lang.name}</div>
                    ))
                }
            </ConfirmModal>
        </>
    )
}

export default Identity;