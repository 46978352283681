import React, {useEffect} from "react";
import DisplayAdminView from "../../Admin/Layouts/DisplayAdminView";
import HttpApi from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";
import {Button} from "react-bootstrap";
import CommonSurveyButton from "../Modules/Button/CommonSurveyButton";

const TestPage = (props) => {

    const call = () => {
        HttpApi.getV2("/questions/getQuestionIdentities/3641").then(response => {
            console.log(response.data);
        }).catch(error => {
            notifyError(error);
        })
    }

    const call2 = () => {
        HttpApi.getV2("/questions/getQuestionIdentities/3642").then(response => {
            console.log(response.data);
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {

    }, [])

    return(
        <DisplayAdminView {...props} menu={"noMenu"} pageName={"test"} selectedIndex={-1}>
            {
                () => (
                    <div className={"ml-20"}>
                        <div>
                            <CommonSurveyButton locked={false} handleSwitch={() => {}} isActive={false}/>
                        </div>
                    </div>
                )
            }

        </DisplayAdminView>
    )


};

export default TestPage