import React, {useState} from "react";
import {ReactComponent as PollIcon} from "../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as IdeaboxIcon} from "../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as MessageIcon} from "../../../../Core/symbols/webloom/white/message-white.svg";
import IconButton from "@mui/material/IconButton/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Col, Row} from "react-bootstrap";
import _ from "lodash";
import {SurveyStatus, SurveyTypes} from "../Polls/Modules/Types";
import moment from "moment";
import {useTranslation} from "react-multi-lang/lib";
import WeBloomDashboardItemMenu from "./Modules/WeBloomDashboardItemMenu";
import WeBloomDashboardItemProgressBar from "./Modules/WeBloomDashboardItemProgressBar";
import {getLanguage} from "react-multi-lang";
import CategoryIcon from "../../../../Core/Modules/CategoryDisplay/CategoryIcon";
import EbloomNotificationBadge from "../../../../Core/Modules/Notifications/EbloomNotificationBadge";

const WeBloomDashboardListItem = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const {survey, handleSeeDetail, handleEdit,isProspect,isAllEntities,isGroup} = props;

    //hooks
    const t = useTranslation();

    //handlers
    const handleOpenMenu = (event) => {
        setAnchorEl(event.target);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const displayColor = () => {
        return (survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? "contrast-blue" : survey.status === SurveyStatus.RUNNING ? "purple" : survey.status === SurveyStatus.DONE ? "contrast-purple" : "grey";
    }
    const displayStatus = () => {
        return survey.status === SurveyStatus.DRAFT ? t("webloom.draft") : survey.status === SurveyStatus.SCHEDULED ? t("webloom.scheduled") : survey.status === SurveyStatus.RUNNING ? t("webloom.running") : survey.status === SurveyStatus.DONE ? t("webloom.done") : t("webloom.archived")
    }

    const displaySurveyRecipient = () => {
        switch (survey.recipients){
            case "allCurrentAndFutureUsers":
                return _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"));
            case "allCurrentUsers":
                return _.upperFirst(t("core.collaborative.allCurrentUsers"));
            case "customized":
                return _.upperFirst(t("core.customized"));
            default:
                return survey.recipients
        }
    }


    return(
        <Row className={"pointer"}>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}}><EbloomNotificationBadge number={survey.nbResponses-survey.lastNbAnswers}>{survey.type === SurveyTypes.POLL ? <PollIcon className={displayColor() + "-icon h-31 w-31"}/> : survey.type === SurveyTypes.IDEABOX ? <IdeaboxIcon className={displayColor() + "-icon h-31 w-31"}/> : <MessageIcon className={displayColor() + "-icon h-31 w-31"}/>}</EbloomNotificationBadge></Col>
            <Col md={2} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}} className={"truncate ebloom-text ebloom-small-text"}>{(survey.type === SurveyTypes.IDEABOX && survey.question && survey.question.category) ? <div className={"flex items-center justify-start"}><div><CategoryIcon name={survey.question.category} className={"w-25 h-25 mr-5"}/></div><div>{survey.question.text}</div></div> : ((survey.type === SurveyTypes.IDEABOX || survey.type === SurveyTypes.MESSAGE) && survey.question) ? survey.question.text :survey.title}</Col>
            <Col md={3} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}}><WeBloomDashboardItemProgressBar survey={survey}/></Col>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}} className={"truncate"}>{"# " + displaySurveyRecipient()}</Col>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}} className={"text-center"}>{survey.type === SurveyTypes.MESSAGE ? "-" : (Math.round((survey.nbResponses/survey.users.length)*100) || "0") + "%"}</Col>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}} className={"text-center"}>{survey.type === SurveyTypes.MESSAGE ? "-" :(survey.nbResponses || "0") + "/" + (survey.users ? survey.users.length : "0")}</Col>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}} className={"text-center"}>{survey.type === SurveyTypes.MESSAGE ? "-" : survey.lastAnswer ? moment(survey.lastAnswer).locale(getLanguage()).fromNow() : "-"}</Col>
            <Col md={1} onClick={(survey.status === SurveyStatus.DRAFT || survey.status === SurveyStatus.SCHEDULED) ? () => {handleEdit(survey)} : () => {handleSeeDetail(survey)}}><div className={"ebloom-text ebloom-very-small-text font-semibold p-1 text-center text-white rounded-3xl bg-" + displayColor()}>{_.toUpper(displayStatus())}</div></Col>
            {
                (!isProspect && !isAllEntities) &&
                <Col md={1} className={"inline"}><IconButton size={"small"} onClick={handleOpenMenu}><MoreHorizIcon className={"text-" + displayColor()}/></IconButton></Col>
            }
            <WeBloomDashboardItemMenu survey={survey} color={displayColor()} anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} isGroup={isGroup} {...props}/>
        </Row>
    )
}

export default WeBloomDashboardListItem;