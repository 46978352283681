import React, {useState, useEffect} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import LDAPModel from "../../../models/LDAP";
import SSOModel from "../../../models/SSO";
import ModelForms from "../../../../Core/Modules/Input/ModelForms";
import {IntegrationType} from "../../../../../Utils/Global";
import _ from "lodash";
import HttpApi from "../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../Utils/Notification";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import DetailView from "../../../../Core/Modules/Views-Tables-Lists/DetailView";
import ActionButtons from "../../../../Core/Modules/Button/ActionButtons";
import LoadingView from "../../../../Core/Layouts/LoadingView";

const IntegrationEditor = (props) => {

    //state
    const [loading, setLoading] = useState(false);
    const [ldap, setLdap] = useState({url:"", bindDN:"", bindCredentials:"", scope:2, filter:"", attributes:""})

    const [ssoCredentials, setSsoCredentials] = useState({id:null, type:"", domain:"", client_id:"", client_secret:"", scope:"", filter:"", attribute:"", email_mapper:"", name_mapper:"", lang_mapper:"", team_mapper:"", filter_mapper:""})

    const [view, setView] = useState("form");

    //props
    const {companyToEdit} = props;

    //hooks
    const t = useTranslation();

    //getters
    const fetchIntegrations = () => {
        HttpApi.getV2("/integrations/sso/fetch").then(response => {
            if(response && response.data){
                setSsoCredentials(response.data);
                setView("detail");
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    //posters
    const handleSave = () => {
        if(!loading){
            setLoading(true);
            HttpApi.postV2("/integrations/sso/save/" + companyToEdit.id, ssoCredentials).then(() => {
                setView("detail");
                notifySuccess(ssoCredentials.type + " integration successfully saved");
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoading(false);
            })
        }

    }

    //handlers
    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setLdap(prevState => {
            let ldap = {...prevState};
            ldap[name] = value;
            return ldap;
        })
    }

    const handleChangeSSO = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setSsoCredentials(prevState => {
            let ssoCredentials = {...prevState};
            ssoCredentials[name] = value;
            return ssoCredentials;
        })
    }

    const handleDelete = () => {
        setSsoCredentials({id:null, type:"", domain:"", client_id:"", client_secret:"", scope:"", filter:"", attribute:"", email_mapper:"", name_mapper:"", lang_mapper:"", team_mapper:"", filter_mapper:""})
        setView("form");

    }

    const handleType = (value) => {
        setSsoCredentials({id:null, type:value, domain:"", client_id:"", client_secret:"", scope:"", filter:"", attribute:"", email_mapper:"", name_mapper:"", lang_mapper:"", team_mapper:"", filter_mapper:""})
    }

    const getLogo = (value) => {
        const logo = require("../../../../Core/symbols/integrations/"+value+".svg")
        return <img src={logo} alt={"Logo " + value} width={35} height={35}/>
    }

    //useEffect

    useEffect(() => {
        if(companyToEdit){
            fetchIntegrations();
        }
    }, [companyToEdit])

    return (
        <div>
            <CardDefault title={"Integration"} showButton={false}>
                {
                    ssoCredentials.id === null ?
                    <div className={"grid grid-cols-4 items-center"}>
                        {
                            Object.values(IntegrationType).map((value, index) => (
                                <div
                                    className={"border border-black rounded-md flex items-center p-5 m-5 pointer " + (ssoCredentials.type === value && "bg-contrast-purple/[.08]")}
                                    key={index} onClick={() => handleType(value)}>
                                    <div className={"mx-5"}>
                                        {getLogo(value)}
                                    </div>
                                    <div>
                                        {_.upperFirst(value)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                        :
                        <div className={"flex items-center"}>
                            <div className={"mr-5"}>
                                {getLogo(ssoCredentials.type)}
                            </div>
                            <div>
                                {_.upperFirst(ssoCredentials.type)}
                            </div>
                            <ActionButtons id={ssoCredentials.id} handleDelete={handleDelete} url="/integrations/sso/delete" handleEdit={() => setView("form")}/>

                        </div>
                }

                {
                    (ssoCredentials.type !== "" && ssoCredentials.type !== IntegrationType.LDAP) &&
                    <div>
                        {
                            view === "form" ?
                                <ModelForms model={SSOModel} handleChange={handleChangeSSO} values={ssoCredentials}/>
                                :
                                <DetailView model={SSOModel} item={ssoCredentials} isModal={false}/>
                        }
                    </div>
                }
                {
                    (ssoCredentials.type !== "" && ssoCredentials.type === IntegrationType.LDAP) &&
                    <div>
                        <ModelForms model={LDAPModel} handleChange={handleChange} values={ldap}/>
                    </div>
                }
                {
                    (ssoCredentials.type !== "" && view === "form") &&
                    <Button className={"ebloom-btn-purple float-right"} onClick={handleSave} disabled={loading}>{loading ? <LoadingView size={16}/> : _.upperFirst(t("core.save"))}</Button>
                }
            </CardDefault>
        </div>
    )
}

IntegrationEditor.propTypes = {}

IntegrationEditor.defaultProps = {}
export default IntegrationEditor