import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {Colors} from "../../../../Utils/Colors";

const ConfirmModal = (props) => {
    const {title, children, size, buttonLeftText, buttonLeftVariant, buttonRightText, buttonRightVariant, handleChange, showButtons, show, handleClose, light, buttonDisabled, bgColor, handleLeftButton,colors} = props;


    const handleClick = () => {
        handleChange();
        handleClose();
    }

    const handleClickLeft = () => {
        handleLeftButton();
        handleClose();
    }

    return(
        <Modal show={show} onHide={handleClose} size={size} style={{borderColor:colors ? colors.tertiary :light ? Colors.EBLOOM_WHITE_BLUE : Colors.EBLOOM_CONTRAST_BLUE}}>
            <div data-tour={'modal'}>
                <Modal.Header closeButton style={{backgroundColor:colors ? colors.tertiary :light ? Colors.EBLOOM_WHITE_BLUE : bgColor, color:"white"}}>
                    <Modal.Title className={light ? "ebloom-normal-title" : "ebloom-xtra-large-text ebloom-text"} style={{textAlign:"center", color:light ? Colors.EBLOOM_CONTRAST_PURPLE : "white"}}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"ebloom-normal-text " + (!showButtons ? "rounded-b-md" : "")} style={{backgroundColor:colors ? colors.background :light ? "white" : Colors.EBLOOM_WHITE_BLUE}}>
                    {children}
                </Modal.Body>
                {
                    showButtons &&
                    <Modal.Footer style={{backgroundColor: colors ? colors.background : Colors.EBLOOM_WHITE_BLUE}}>
                        <Button variant={buttonLeftVariant} onClick={handleLeftButton ? handleClickLeft : handleClose}>{buttonLeftText}</Button>
                        <Button style={{backgroundColor: colors ? colors.secondary : null}} data-tour={'confirmModal'} variant={buttonRightVariant} className={colors ? "text-white" : buttonRightVariant === "purple" ? "ebloom-btn-purple" : buttonRightVariant === "link" ? "ebloom-link-purple" : ""} onClick={handleClick} disabled={buttonDisabled}>{buttonRightText}</Button>
                    </Modal.Footer>
                }

            </div>

        </Modal>
    )
}

ConfirmModal.propTypes = {
    title:PropTypes.string.isRequired,
    buttonLeftText:PropTypes.string,
    buttonLeftVariant:PropTypes.string,
    buttonRightText:PropTypes.string,
    buttonRightVariant:PropTypes.string,
    handleChange:PropTypes.func,
    handleLeftButton:PropTypes.func,
    showButtons:PropTypes.bool,
    handleClose:PropTypes.func.isRequired,
    show:PropTypes.bool.isRequired,
    size:PropTypes.string,
    light:PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    bgColor:PropTypes.string,
    colors: PropTypes.object
}

ConfirmModal.defaultProps = {
    buttonLeftVariant: "secondary",
    buttonRightVariant:"purple",
    showButtons:true,
    size:"md",
    light:false,
    buttonDisabled:false,
    bgColor:Colors.EBLOOM_CONTRAST_BLUE,
    colors : null
}

export default ConfirmModal;