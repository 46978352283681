import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import {Provider} from "react-redux";
import store from "./store/store";
import {getFromStorage} from "./Utils/Storage";


Sentry.init({
    dsn: "https://532d79a4522b4f4c9b2eeea58a166485@o4504887026057216.ingest.sentry.io/4504887027499008",
    integrations: [
      Sentry.browserTracingIntegration()
      , Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.50,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag("email", getFromStorage("email"));
Sentry.setTag("company", getFromStorage("company"));


const root=createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename="/">
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

serviceWorker.unregister();
