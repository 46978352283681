import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Row, Col} from "react-bootstrap";
import WeBloomDashboardCard from "./WeBloomDashboardCard";
import {ReactComponent as CreateBubbleIcon} from "../../../../Core/symbols/webloom/white/create-white.svg";
import {ReactComponent as ListIcon} from "../../../../Core/symbols/webloom/list.svg";
import {ReactComponent as CardIcon} from "../../../../Core/symbols/webloom/cards.svg";
import {ReactComponent as AnswerIcon} from "../../../../Core/symbols/webloom/white/answer-white.svg";
import {CompanyTypes, isNullOrUndefined, Modules, PurpleSwitch, TourType} from "../../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import EbloomSearchBar from "../../../../Core/Modules/Input/EbloomSearchBar";
import WeBloomDashboardListItem from "./WeBloomDashboardListItem";
import EbloomModal from "../../../../Core/Modules/Modal/EbloomModal";
import _ from "lodash";
import EbloomInfoTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import EbloomSelectV2 from "../../../../Core/Modules/Input/EbloomSelectV2";
import {SurveyStatus, SurveyTypes} from "../Polls/Modules/Types";
import HttpApi from "../../../../../httpApi";
import {notifyCustomError, notifyError, notifySuccess} from "../../../../../Utils/Notification";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import moment from "moment";
import WeBloomNotifications from "./Modules/WeBloomNotifications";
import IconButton from "@mui/material/IconButton/IconButton";
import EbloomNotificationBadge from "../../../../Core/Modules/Notifications/EbloomNotificationBadge";
import WeBloomCreateMenu from "./Modules/WeBloomCreateMenu";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import WeBloomTour from "../../../../../Utils/ProductTour/Tours/LaunchWeBloom/WeBloomTour";
import {ProductTourContext} from "../../../../../Utils/Context/ProductTourContext";
import {useUserData} from "../../../../../hooks/userHook";
import FreeTrialModal from "../../../../Core/Modules/Modal/FreeTrialModal";
import EntitiesSwitcher from "../../../../Core/Modules/Button/EntitiesSwitcher";

const WeBloomDashboard = (props) => {

    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState("card");
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [typeFilter, setTypeFilter] = useState("All types");
    const [teamFilter, setTeamFilter] = useState("All recipients");
    const [teams, setTeams] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalStop, setShowModalStop] = useState(false);
    const [showModalPublish, setShowModalPublish] = useState(false);
    const [showModalReopen, setShowModalReopen] = useState(false);
    const [showModalBoost, setShowModalBoost] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const [hasEndedFreeTrial, setHasEndedFreeTrial] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [showWhistleBlower, setShowWhistleBlower] = useState(false);

    const [entitySelected, setEntitySelected] = useState(null);




    const [surveys, setSurveys] = useState([]);

    const [surveyToUpdate, setSurveyToUpdate] = useState({id:null, title:"", type:"", question:{text:""}});

    const {nbActiveSurvey, companyToEdit, isTemplate,isSuperAdmin, isAllEntities,displayEntitySwitcher} = props;

    const {icon,tourActivated,handlersTour, setTourStarted} = useContext(ProductTourContext)

    const t = useTranslation();
    const navigate = useNavigate();
    const userData = useUserData();
    const isProspect = userData.companyType === CompanyTypes.PROSPECT

    //getters
    const fetchSurveys = (getArchived=false) => {
        let url = isTemplate ? "/webloom/getTemplates" : "/webloom/getAll/"+ getArchived;
        if (isSuperAdmin ){
            if (isAllEntities && !isNullOrUndefined(entitySelected)){
                url = "/collaborative/group/getAll/"+(entitySelected ? entitySelected : userData.companyId)
            }else if (!isAllEntities){
                url = "/collaborative/group/getAll/"+(userData.companyGroup ? userData.companyGroup.id : userData.companyId)
            }
        }

        if((isSuperAdmin && isAllEntities && !isNullOrUndefined(entitySelected)) || !isSuperAdmin || isSuperAdmin ){
            setLoading(true);
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    if(isAllEntities){
                        const list = response.data.filter(el => el.status !== SurveyStatus.DRAFT && el.status !== SurveyStatus.SCHEDULED )
                        let value = list.filter(el => el.status !== SurveyStatus.ARCHIVED).length < 1
                        value = list.length > 0 ? value : false
                        setShowArchived(value)
                        setSurveys(list)
                    }else{
                        setSurveys(response.data);
                    }
                }else{
                    setSurveys([]);
                }
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const fetchValidTeams = () => {
        if(userData.module !== Modules.HAPPY[0] && !isTemplate){
            let url = ""
            if(isAllEntities && entitySelected){
                url = "/teams/getValid/" + entitySelected
            }else if ( !isSuperAdmin){
                url = "/teams/getValid" ;
            }
            if((isSuperAdmin && !isNullOrUndefined(entitySelected)) || !isSuperAdmin){
                HttpApi.getV2(url).then(response => {
                    if(response && response.data){
                        let listTeams = response.data
                        setTeams(listTeams);
                    }else{
                        setTeams([]);
                    }
                })
            }

        }

    }

    const fetchEndDate = () => {
        if(Modules.FREE.includes(userData.module)){
            HttpApi.getV2("/companies/getEndDate").then(response => {
                if(response && response.data){
                    setHasEndedFreeTrial(moment(response.data.end_date).isSameOrBefore(moment()));
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    //posters
    const deleteSurvey = () => {
        HttpApi.post("/collaborative/delete", {id:surveyToUpdate.id}).then(() => {
            setSurveys(prevState => {
                let surveys = [...prevState];
                const index = surveys.indexOf(surveyToUpdate);
                surveys.splice(index, 1);
                return surveys;
            })
            notifySuccess(_.upperFirst(t("webloom.notification.surveyDeleted", {survey:t("webloom." + surveyToUpdate.type)})));
        }).catch(error => {
            notifyError(error);
        })
    }

    const updateSurveyStatus = (status) => {
        let url = "/webloom/updateActiveState"
        if (isSuperAdmin && !isAllEntities){
            url += '/' + userData.companyGroup.id
        }
        const isReminder = (status === surveyToUpdate.status && status === SurveyStatus.RUNNING)
        const isReopen = status === SurveyStatus.RUNNING && surveyToUpdate.status === SurveyStatus.DONE;
        let data = {id:surveyToUpdate.id, status:status, is_reminder:isReminder, is_reopen:isReopen}
        if(isSuperAdmin && !isAllEntities){
            data.id_company = userData.companyGroup.id
        }
        const isPublishing = (status === SurveyStatus.RUNNING && (surveyToUpdate.status === SurveyStatus.SCHEDULED || surveyToUpdate.status === SurveyStatus.DRAFT))
        if(isPublishing && ((surveyToUpdate.type === SurveyTypes.POLL && !surveyToUpdate.is_anonymous && surveyToUpdate.users.length < 5) || ((surveyToUpdate.type === SurveyTypes.ALERT || surveyToUpdate.type === SurveyTypes.IDEABOX) && surveyToUpdate.users.length < 5))){
            notifyCustomError(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom." + surveyToUpdate.type)})))
        }else{
            HttpApi.put(url, data).then(() => {
                setSurveys(prevState => {
                    let surveys = [...prevState];
                    const index = surveys.indexOf(surveyToUpdate);
                    surveys[index].status = status;
                    if(status === SurveyStatus.DONE) {//close
                        surveys[index].end_date = new Date();
                    }else if(isReopen){//reopen
                        surveys[index].end_date = null;
                    }else if(status === SurveyStatus.RUNNING && !isReminder){//publish
                        surveys[index].start_date = new Date();
                    }else if(isReminder){//boost
                        surveys[index].last_boost = moment().format("YYYY-MM-DD");
                    }
                    return surveys;
                })

                    let notif = isReopen ? "webloom.notification.surveyReopened" :  isReminder ? "webloom.notification.surveyBoosted" : status === SurveyStatus.RUNNING ? "webloom.notification.surveyPublished" : status === SurveyStatus.DONE ? "webloom.notification.surveyStopped" : "webloom.notification.surveyArchived";
                    notifySuccess(_.upperFirst(t(notif, {survey:t("webloom." + surveyToUpdate.type)})));
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    //handlers
    const handleOpenModalDelete = (survey) => {
        setShowModalDelete(true);
        if(survey.type === SurveyTypes.ALERT){
            survey.question.text = _.upperFirst(t("webloom.alertText"));
        }
        setSurveyToUpdate(survey);
    }

    const handleOpenModalStop = (survey) => {
        setShowModalStop(true);
        if(survey.type === SurveyTypes.ALERT){
            survey.question.text = _.upperFirst(t("webloom.alertText"));
        }
        setSurveyToUpdate(survey);
    }

    const handleOpenModalPublish = (survey) => {
        if(hasEndedFreeTrial){
            setShowFreeTrialModal(true);
        }else{
            setShowModalPublish(true);
            if(survey.type === SurveyTypes.ALERT){
                survey.question.text = _.upperFirst(t("webloom.alertText"));
            }
            setSurveyToUpdate(survey);
        }
    }

    const handleOpenModalReopen = (survey) => {
        if(hasEndedFreeTrial){
            setShowFreeTrialModal(true);
        }else{
            setShowModalReopen(true);
            if(survey.type === SurveyTypes.ALERT){
                survey.question.text = _.upperFirst(t("webloom.alertText"));
            }
            setSurveyToUpdate(survey);
        }
    }

    const handleOpenModalBoost = (survey) => {
        setShowModalBoost(true);
        setSurveyToUpdate(survey);
    }

    const handleOpenModalArchive = (survey) => {
        setShowModalArchive(true);
        if(survey.type === SurveyTypes.ALERT){
            survey.question.text = _.upperFirst(t("webloom.alertText"));
        }
        setSurveyToUpdate(survey);
    }

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleCloseModalStop = () => {
        setShowModalStop(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleCloseModalPublish = () => {
        setShowModalPublish(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleCloseModalReopen = () => {
        setShowModalReopen(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleCloseModalBoost = () => {
        setShowModalBoost(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleCloseModalArchive = () => {
        setShowModalArchive(false);
        setSurveyToUpdate({id:null, title:"", type:"", question:{text:""}});
    }

    const handleDisplay = () => {
        setDisplay(display === "card" ? 'list' : "card");
    }

    const handleModal = () => {
        setShowCreateModal(!showCreateModal);
    }

    const handleSearchValue = (event) => {
        setSearchValue(event.target.value);
    }

    const handleTypeFilter = (event) => {
        setTypeFilter(event.target.value);
    }

    const handleTeamFilter = (event) => {
        setTeamFilter(event.target.value);
    }

    const handleShowArchived = (event) => {
        fetchSurveys(event.target.checked);
        setShowArchived(event.target.checked);
    }

    const handleEdit = (survey) => {
        if(isSuperAdmin && !isAllEntities ){
            navigate("/group/webloom/"+survey.type+"/create/"+userData.companyGroup.id, {state:survey});
        }else{
            navigate("/admin/webloom/"+survey.type+"/create" + (isSuperAdmin && !isAllEntities ? "/"+userData.companyGroup.id : "" ), {state:survey});
        }

    }

    const handleSeeDetail = (survey) => {
        if(survey.nbResponses > survey.lastNbAnswers && !isAllEntities){
            HttpApi.post("/collaborative/updateLastNotif", {id_collab_survey:survey.id, nbNotif:survey.nbResponses}).then(() => {
                if (isSuperAdmin) {
                    navigate("/group/webloom/"+survey.type+"/result/"+userData.companyGroup.id, {state:survey})
                }else{
                    navigate("/admin/webloom/"+survey.type+"/result", {state:survey});
                }
            }).catch(error => notifyError(error));
        }else{
            if(isSuperAdmin && isAllEntities){
                navigate("/group/webloom/"+survey.type+"/result/"+entitySelected, {state:survey})
            }else if (isSuperAdmin) {
                navigate("/group/webloom/"+survey.type+"/result/"+userData.companyGroup.id, {state:survey})
            }else{
                navigate("/admin/webloom/"+survey.type+"/result", {state:survey})
            }

        }
    }

    useEffect(() => {
        fetchSurveys();
        fetchValidTeams();
        fetchEndDate();
        setSearchValue("")
        setTypeFilter("All types")
        setTeamFilter("All recipients")
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        if(productTour && productTour === "webloom"){
            setTourStarted(true);
        }
        if(!isSuperAdmin){
            HttpApi.getV2("/settings/getForCompany/alert").then(response => {
                if(response && response.data){
                    setShowWhistleBlower(Boolean(response.data));
                }else{
                    setShowWhistleBlower(false)
                }
            }).catch(error => {
                notifyError(error);
            })
        }


    }, [companyToEdit, entitySelected]);


    return (
        <div className={"h-full"}>
            <WeBloomTour/>
            <div className={"mb-30 flex justify-between items-center"}>
                {
                    (!isSuperAdmin || !isAllEntities) &&
                    <IconButton data-webloom={"create"} size="small" onClick={handleModal}><CreateBubbleIcon className={"w-45 h-45 purple-icon"}/></IconButton>

                }
                {
                    isSuperAdmin && isAllEntities &&
                    <EntitiesSwitcher constrastPurple={true} entity={entitySelected} allOption={false} isWeBloom={true} handleSwitch={(value) => setEntitySelected(value) } />
                }
                <EbloomSelectV2 value={typeFilter} onChange={handleTypeFilter}>
                    <MenuItem key={1} value={"All types"}>{_.upperFirst(t("core.allBisParam", {param:t("webloom.types")}))}</MenuItem>
                    <MenuItem key={2} value={SurveyTypes.POLL}>{_.upperFirst(t("webloom.poll"))}</MenuItem>
                    <MenuItem key={3} value={SurveyTypes.IDEABOX}>{_.upperFirst(t("webloom.ideabox"))}</MenuItem>
                    <MenuItem key={4} value={SurveyTypes.MESSAGE}>{_.upperFirst(t("webloom.message"))}</MenuItem>
                    {
                        showWhistleBlower &&
                        <MenuItem key={5} value={SurveyTypes.ALERT}>{_.upperFirst(t("webloom.alert"))}</MenuItem>
                    }
                </EbloomSelectV2>
                <EbloomSelectV2 onChange={handleTeamFilter} value={teamFilter}>
                    <MenuItem key={-3} value={"All recipients"}>{_.upperFirst(t("core.allBisParam", {param:t("core.collaborative.recipients")}))}</MenuItem>
                    <MenuItem key={-2} value={"allCurrentAndFutureUsers"}>{_.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"))}</MenuItem>
                    <MenuItem key={-1} value={"allCurrentUsers"}>{_.upperFirst(t("core.collaborative.allCurrentUsers"))}</MenuItem>
                    {
                        teams.map((team, index) => {
                            let name = team.name;
                            if(isProspect){
                                name = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index + 'A'.charCodeAt(0))
                            }
                            return <MenuItem key={index} value={team.name}>{name}</MenuItem>
                        })
                    }
                    {
                        !isProspect &&
                        <MenuItem key={-1} value={"customized"}>{_.upperFirst(t("core.customized"))}</MenuItem>
                    }
                </EbloomSelectV2>
                <EbloomSearchBar className={"inline"} handleChange={handleSearchValue} searchValue={searchValue} size={"small"} color={"purple-contrast"}/>
                <div><PurpleSwitch value={showArchived} checked={showArchived} onChange={handleShowArchived}/><span className={"cursorDefault text-contrast-purple"}>{_.upperFirst(t("core.showParam", {param:t("webloom.archived")}))}</span></div>
                <div><IconButton data-webloom={"notif"} size="small"><WeBloomNotifications surveys={surveys} handleSeeDetail={handleSeeDetail}/></IconButton></div>
                {
                    !isSuperAdmin &&
                    <div>
                        <IconButton data-webloom={"seeWeBloom"} size={"small"}>
                            <EbloomNotificationBadge number={nbActiveSurvey} showNumber>
                                <AnswerIcon className={"pointer w-31 h-31 purple-icon"} onClick={() => {navigate("/webloom/dashboard")}}/>
                            </EbloomNotificationBadge>
                        </IconButton>
                    </div>
                }
                <div><IconButton size="small" onClick={handleDisplay}>{display === "card" ? <ListIcon /> : <CardIcon/>}</IconButton></div>
                <div>
                    {
                        tourActivated ?
                            <IconButton onClick={handlersTour[TourType.LAUNCH_WEBLOOM]} className={'cursor-pointer'} ref={icon}>
                                <EbloomInfoTooltip text={_.upperFirst(t("webloom.info"))} placement={"bottom"}/>
                            </IconButton>
                            :
                            <EbloomInfoTooltip text={_.upperFirst(t("webloom.info"))} placement={"bottom"}/>
                    }

                </div>
            </div>
            {
                loading ?
                    <LoadingView/>
                    :
                !surveys || surveys.length === 0 || (surveys.filter(el => el.status !== SurveyStatus.ARCHIVED).length === 0 && !showArchived) ?
                    isSuperAdmin && isAllEntities ?
                        <div className={"text-center  text-xl ebloom-text h-full"}>
                            <p>This Entity has no WeBloom</p>
                        </div>
                        :
                    <WeBloomCreateMenu showBorder={true} hasEndedFreeTrial={hasEndedFreeTrial} setShowFreeTrialModal={setShowFreeTrialModal} isGroup={isSuperAdmin && !isAllEntities}/>
                    :
                    <Row md={display === "card" ? 3 : 1} className={display === "list" ? "bg-bg-white border-1 border-contrast-purple rounded-xl p-11" : ""}>
                        {display === "list" &&
                            <Col className={"mb-10 mt-10"}>
                                <Row className={"ebloom-text ebloom-small-text"}>
                                    <Col md={1} className={"text-center"}/>
                                    <Col md={2} className={"text-center"}>{_.upperFirst(t("core.title"))}</Col>
                                    <Col md={3} className={"text-center"}>{_.upperFirst(t("core.dates"))}</Col>
                                    <Col md={1} className={"text-center"}>{_.upperFirst(t("core.collaborative.recipients"))}</Col>
                                    <Col md={1} className={"text-center"}>{_.upperFirst(t("webloom.participation"))}</Col>
                                    <Col md={1} className={"text-center"}>{_.upperFirst(t("webloom.participants"))}</Col>
                                    <Col md={1} className={"text-center"}>{_.upperFirst(t("webloom.lastAnswer"))}</Col>
                                    <Col md={1} className={"text-center"}>{_.upperFirst(t("webloom.status"))}</Col>
                                    <Col md={1}/>
                                </Row>
                            </Col>
                        }
                        {
                            surveys?.filter(el => (el.type === _.lowerCase(typeFilter) || typeFilter === "All types")).filter(el => (el.recipients === teamFilter || teamFilter === "All recipients")).filter(el => showArchived ? el.status !== null : el.status !== SurveyStatus.ARCHIVED).filter(el => (el.title && _.toLower(el.title).includes(_.toLower(searchValue))) || (el.question && _.toLower(el.question.text).includes(_.toLower(searchValue)))).map((survey, index) => (
                                <Col className={"mb-10 mt-10"} key={index}>
                                    {
                                        display === "card" ? <WeBloomDashboardCard isProspect={isProspect} survey={survey} handleEdit={handleEdit} handleDelete={handleOpenModalDelete} handleStop={handleOpenModalStop} handlePublish={handleOpenModalPublish} handleReopen={handleOpenModalReopen} handleBoost={handleOpenModalBoost} handleArchive={handleOpenModalArchive} handleSeeDetail={handleSeeDetail} isAllEntities={isAllEntities} isGroup={isSuperAdmin && !isAllEntities}/> : <WeBloomDashboardListItem isProspect={isProspect} survey={survey} handleEdit={handleEdit} handleDelete={handleOpenModalDelete} handleStop={handleOpenModalStop} handlePublish={handleOpenModalPublish} handleReopen={handleOpenModalReopen} handleBoost={handleOpenModalBoost} handleArchive={handleOpenModalArchive} handleSeeDetail={handleSeeDetail} isAllEntities={isAllEntities} isGroup={isSuperAdmin && !isAllEntities}/>
                                    }

                                </Col>
                            ))
                        }
                    </Row>
            }
            <EbloomModal handleClose={handleModal} show={showCreateModal} size={"lg"}>
                <WeBloomCreateMenu hasEndedFreeTrial={hasEndedFreeTrial} setShowFreeTrialModal={setShowFreeTrialModal} isGroup={isSuperAdmin && !isAllEntities}/>
            </EbloomModal>
            <ConfirmModal handleClose={handleCloseModalDelete} show={showModalDelete} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("core.deleteParam",{param:t("webloom."+ (surveyToUpdate ? surveyToUpdate.type : "poll"))}))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={deleteSurvey}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t("webloom.deleteModal", {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalPublish} show={showModalPublish} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.publish"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={() => {updateSurveyStatus(SurveyStatus.RUNNING)}}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t((surveyToUpdate.type === SurveyTypes.ALERT ? "webloom.publishAlertModal" : "webloom.publishModal"), {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalReopen} show={showModalReopen} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.reopen"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={() => {updateSurveyStatus(SurveyStatus.RUNNING)}}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t((surveyToUpdate.type === SurveyTypes.ALERT ? "webloom.reopenAlertModal" :"webloom.reopenModal"), {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalBoost} show={showModalBoost} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.boost"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={() => {updateSurveyStatus(SurveyStatus.RUNNING)}}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t("webloom.boostModal", {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalStop} show={showModalStop} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.stop"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={() => {updateSurveyStatus(SurveyStatus.DONE)}}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t("webloom.stopModal", {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalArchive} show={showModalArchive} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("core.collaborative.archive"))} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))} handleChange={() => {updateSurveyStatus(SurveyStatus.ARCHIVED)}}>
                <p className={"text-line-break break-words"}>{_.upperFirst(t("webloom.archiveModal", {param:(surveyToUpdate ? surveyToUpdate.type === SurveyTypes.POLL ? surveyToUpdate.title : surveyToUpdate.question.text : ""), survey:t("webloom.the" + _.upperFirst(t(surveyToUpdate.type)))}))}</p>
            </ConfirmModal>
            <FreeTrialModal handleClose={() => {setShowFreeTrialModal(false)}} show={showFreeTrialModal} text={""}/>
        </div>
    )
}

WeBloomDashboard.propTypes = {
    companyToEdit:PropTypes.object,
    isTemplate:PropTypes.bool,
    isSuperAdmin:PropTypes.bool,
    isAllEntities:PropTypes.bool,
    displayEntitySwitcher:PropTypes.bool,
    entitiesToDisplay:PropTypes.number
}

WeBloomDashboard.defaultProps = {
    companyToEdit:null,
    isTemplate:false,
    isSuperAdmin:false,
    isAllEntities:false,
    displayEntitySwitcher:false
}

export default WeBloomDashboard;