import React, {useRef, useState} from "react";
import {Overlay, Popover, Button} from "react-bootstrap";
import * as PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Colors} from "../../../../Utils/Colors";
import {useOutsideAlerter} from "../../../../hooks/outsideAlerterHook";


const EbloomPopover = (props) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [overrideClosePopover, setOverrideClosePopover] = useState(false);
    const ref = useRef(null);

    const {triggerVariant, triggerText, title, children, placement, showHeader, width, size, isCollab, customTrigger,colors}= props;


    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };


    const resetAndClose = () => {
        if(!overrideClosePopover){
            setShow(false);
        }

    };

    const handleOverrideClose = (state) => {
        setOverrideClosePopover(state);
    }

    useOutsideAlerter(ref, () => {resetAndClose()});

    return(
        <div ref={ref}>
            {
                customTrigger ?
                    <div onClick={handleClick}>{customTrigger}</div>
                    :
                isCollab ?
                    <span style={{fontSize:18}} className={"pointer text-line-break"} onClick={handleClick}>#{triggerText}</span>
                    :
                    <Button size={size} variant={triggerVariant} className={triggerVariant === "purple" ? "ebloom-btn-purple ebloom-normal-text" : triggerVariant === "white" ? "ebloom-btn-white ebloom-normal-text" : "ebloom-normal-text"} style={{zIndex:10}} onClick={handleClick}>
                        {triggerText} <ArrowDropDownIcon style={{color:triggerVariant === "white" ? colors !== null ? colors.secondary : Colors.EBLOOM_PURPLE : "white"}}/>
                    </Button>

            }

                <Overlay
                    show={show}
                    target={target}
                    placement={placement}
                    container={ref.current}
                    containerPadding={20}
                >
                    <Popover id="popover-ebloom" style={{width:width, maxWidth:width}} >
                        {
                            showHeader &&
                            <Popover.Header as="h3">{title}</Popover.Header>
                        }

                        <Popover.Body>
                            {children(handleOverrideClose)}
                        </Popover.Body>
                    </Popover>
                </Overlay>
        </div>
    )
}

EbloomPopover.propTypes = {
    title: PropTypes.string,
    showHeader:PropTypes.bool,
    triggerText: PropTypes.any,
    triggerVariant:PropTypes.string,
    placement:PropTypes.string.isRequired,
    width:PropTypes.number,
    overrideClose: PropTypes.bool,
    size:PropTypes.string,
    isCollab:PropTypes.bool,
    customTrigger:PropTypes.node,
    colors: PropTypes.object
};

EbloomPopover.defaultProps = {
    showHeader:false,
    width:300,
    overrideClose:false,
    size:"md",
    isCollab:false,
    customTrigger:null,
    colors:null
}

export default EbloomPopover;