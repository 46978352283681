import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {weBloomSteps} from "./weBloomSteps";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";

const WeBloomTour = (props) => {
    const {
        updateStep,
        stepTour,
        stepLaunchWebloom, setStepLaunchWebloom,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        icon,
        updateHandler
    } = useContext(ProductTourContext)


    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
                updateStep(TourType.LAUNCH_WEBLOOM,next)
            }
        }
    }

    const handleTour = () => {
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)

        const tour = {
            id : stepTour[TourType.LAUNCH_WEBLOOM].id,
            current_step : stepTour[TourType.LAUNCH_WEBLOOM].step >= weBloomSteps.length - 1 ? 0 : stepTour[TourType.LAUNCH_WEBLOOM].step,
            type: TourType.LAUNCH_WEBLOOM,
            finished : stepTour[TourType.LAUNCH_WEBLOOM].outcome
        }
        if(stepLaunchWebloom >= weBloomSteps.length - 1){
            setStepLaunchWebloom(0)
        }
        HttpApi.post('/productTour/updateTour',tour).then(()=>{})

    }

    const iconRinging = () => {
        if(icon && icon.current && stepTour[TourType.LAUNCH_WEBLOOM]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.LAUNCH_WEBLOOM].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepLaunchWebloom]);


    useEffect(() => {
        fetchData();

        if(tourActivated && icon && icon.current){
            iconRinging()
        }
        updateHandler(TourType.LAUNCH_WEBLOOM,handleTour)
        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={weBloomSteps}
                continuous
                run={tourStarted}
                stepIndex={stepLaunchWebloom}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={false}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                styles={styleEbloom}
                tooltipComponent={EbloomTourTip}
            />

        </>
    )
}

export default WeBloomTour