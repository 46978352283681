import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import EmployeeEditor from "../../../../Admin/Pages/Employee/EmployeeEditor";



const BackofficeEmployeeEditorPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <EmployeeEditor companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default BackofficeEmployeeEditorPage;


