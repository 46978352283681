import React, {useState, useEffect, useContext} from "react";
import _ from "lodash";
import {Button, Container} from "react-bootstrap";
import {FullContrastPurpleTextField, isEmail, isPhone, OnboardingStep} from "../../Utils/Global";
import EbloomSelectLang from "./Modules/Input/EbloomSelectLang";
import {getLanguages, getTranslations} from "../../lang/translations";
import HttpApi from "../../httpApi";
import {createErrorNotification, createSuccessNotification, notifyError, notifyInfo} from "../../Utils/Notification";
import {setLanguage, useTranslation} from "react-multi-lang";
import {useNavigate} from "react-router-dom";
import EbloomSelectV2 from "./Modules/Input/EbloomSelectV2";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {ReactComponent as GestionparticipativeIcon} from "../Core/symbols/webloom/white/participation-white.svg";
import {ReactComponent as IdeaboxIcon} from "../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as BoostIcon} from "../Core/symbols/webloom/white/boost-white.svg";
import {ReactComponent as RouteIcon} from "../Core/symbols/createFreeAccount/route.svg";
import {ReactComponent as PuzzleIcon} from "../Core/symbols/createFreeAccount/puzzle.svg";
import Checkbox from "@mui/material/Checkbox";
import LoadingView from "./Layouts/LoadingView";
import Login from "../../login/login";
import { useDispatch } from 'react-redux';
import {
    updateCompany, updateCompanyType,
    updateEmail,
    updateId,
    updateIdCompany,
    updateModule,
    updateName,
    updateRoles
} from "../../store/user";
import {ProductTourContext} from "../../Utils/Context/ProductTourContext";

const CreateAccountPage = (props) => {

    //state
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [lang, setLang] = useState("en");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [role, setRole] = useState("role");
    const [companySize, setCompanySize] = useState("size");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("country");
    const [sector, setSector] = useState("sector");
    const [useCase, setUseCase] = useState([]);
    const [step, setStep] = useState(OnboardingStep.VERIFICATION);
    const [mailStatus, setMailStatus] = useState("");
    const [policyChecked, setPolicyChecked] = useState(false);

    const useCases = [
        {value:"teampulse", image:BoostIcon},
        {value:"ideabox", image:IdeaboxIcon},
        {value:"journey", image:RouteIcon},
        {value:"management", image:GestionparticipativeIcon},
        {value:"other", image:PuzzleIcon}
    ]

    const sectors = [
        'technology',
        'health',
        'finance',
        'energy',
        'manufacturing',
        'commerce',
        'education',
        'professionalServices',
        'agriFood',
        'realEstate',
        'transportationAndLogistics',
        'entertainmentAndMedia',
        'tourismAndHospitality',
        'telecommunications',
        'publicServices',
        'other'
    ];


    //props

    //hooks
    const navigate = useNavigate();
    const t = useTranslation()

    const dispatch = useDispatch();

    const {fetchData} = useContext(ProductTourContext)

    //getters

    //posters
    const verifyEmail = () => {
        if(!loading){
            setLoading(true);
            HttpApi.post('/auth/verifyEmail', {email:email, lang:lang}).then(response => {
                if(response && response.data === true){
                    setMailStatus("valid");
                }else if(response && response.data === "disposable"){
                    setMailStatus("disposable");
                }else{
                    //already an account
                    setMailStatus("alreadyMember");
                }
                setLoading(false);
            }).catch(error => {
                notifyError(error);
                setLoading(false);
            })
        }

    }

    const save = () => {
        if(!loading){
            setLoading(true);
            if(!email || !isEmail(email) || !name || !companyName || !companySize || companySize === "size" || !role || role === "role" || !useCase || useCase.length === 0 || !policyChecked){
                createErrorNotification(_.upperFirst(t("core.notification.error")), _.upperFirst(t("core.createAccount.notification.errorPayload")));
                setLoading(false);
            }else{
                HttpApi.postV2("/companies/createFreeAccount", {
                    name:companyName,
                    size:companySize,
                    password:password,
                    admin_mail:email,
                    admin_name:name,
                    admin_phone:phone,
                    role:_.upperFirst(t("core.createAccount.role."+ role)),
                    useCase:useCase,
                    timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
                    admin_lang:lang,
                    country:country,
                    sector:sector
                }).then(async (response) => {
                    if (response && response.data) {
                        await Login.connectApp(response);
                        dispatch(updateId(response.data.id));
                        dispatch(updateEmail(response.data.email));
                        dispatch(updateName(response.data.name));
                        dispatch(updateCompany(response.data.company));
                        dispatch(updateIdCompany(response.data.id_company));
                        dispatch(updateRoles(response.data.roles));
                        dispatch(updateModule(response.data.module));
                        dispatch(updateCompanyType(response.data.company_type));
                        createSuccessNotification("Success !", "Your registration has correctly been saved");
                        notifyInfo(_.upperFirst(t("admin.welcome")), true);
                        fetchData();
                        navigate("/admin/account");
                    }
                }).catch(error => {
                    setLoading(false);
                    notifyError(error);
                });
            }
        }

    }

    //handlers
    const handleNextStep = () => {
        if(step === OnboardingStep.VERIFICATION){
            verifyEmail();
        }else if(step === OnboardingStep.USER){
            setStep(step+1);
        }else if(step === OnboardingStep.COMPANY){
            //validate + create account + create airtable
            save();
        }
    }

    const disableButton = () => {
        if(loading){
            return true;
        }
        if(step === OnboardingStep.VERIFICATION){
            return !isEmail(email);
        }else if(step === OnboardingStep.USER){
            return (!password || password !== confirmPassword || !role || role === "role" || !name || !phone || !isPhone(phone));
        }else if(step === OnboardingStep.COMPANY){
            return (!policyChecked || !companyName || !companySize || companySize === "size" || !useCase || useCase.length === 0 || !country ||country === "country" || !sector || sector === "sector");
        }
    }

    const handleUseCase = (value) => {
        if(useCase.includes(value)){
            setUseCase(prevState => {
                let useCase = [...prevState];
                useCase.splice(useCase.indexOf(value), 1);
                return useCase;
            })
        }else{
            setUseCase(prevState => {
                let useCase = [...prevState];
                useCase.push(value);
                return useCase;
            })
        }
    }

    //useEffect

    useEffect(() => {
        let url = new URL(window.location.href);
        let tokenUrl = url.searchParams.get("token");
        let emailUrl = url.searchParams.get("email");
        let langUrl = url.searchParams.get("lang");
        if(langUrl){
            langUrl = getLanguages().map(el => el.value).includes(langUrl) ? langUrl : "en";
            setLanguage(langUrl);
            setLang(langUrl);
        }
        if(emailUrl){
            setEmail(emailUrl);
        }
        if(emailUrl && tokenUrl){
            HttpApi.post("/auth/verifyMailToken", {email:emailUrl, token:tokenUrl}).then(response => {
                if(response && true === response.data){
                    setStep(OnboardingStep.USER);
                    setEmail(emailUrl);
                    fetch('https://restcountries.com/v3.1/all')
                        .then(response => response.json())
                        .then(data => {
                            let tempArray = [];
                            data.forEach(country => {
                                tempArray.push({value: country.name.common.toLowerCase(),
                                    nl: country.translations.nld.common,
                                    fr: country.translations.fra.common,
                                    en: country.name.common})
                            });
                            setCountries(tempArray);

                        })
                        .catch(error => console.error('Erreur lors de la récupération des données :', error));
                }else{
                    setStep(OnboardingStep.VERIFICATION);
                    setMailStatus("invalidLink");
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }, [])

    return (
        <div className={"bg-bg-blue min-h-screen"}>
            <div className={"pt-15 pb-10"} >
                <div className="logo-ebloom h-60"/>
            </div>
            <Container>
                <div className={"w-1/2 mt-50 m-auto p-30 bg-[#EEF7FA] rounded-2xl"}>
                    <h1 className={"text-center ebloom-title text-contrast-purple text-3xl"}>{_.upperFirst(t( "core.createAccount.title"))}</h1>
                    <div className={"mt-30 w-full m-auto"}>
                        {
                            (step === OnboardingStep.USER || step === OnboardingStep.COMPANY) &&
                            <h2 className={"ebloom-text text-2xl text-contrast-purple mb-10"}>{step === OnboardingStep.USER ? _.upperFirst(t("core.createAccount.userTitle")) : _.upperFirst(t("core.createAccount.companyTitle"))}</h2>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <p className={"ebloom-text text-contrast-purple mb-10"}>{_.upperFirst(t("core.createAccount.companyHelper"))}</p>
                        }
                        {
                            step === OnboardingStep.VERIFICATION &&
                            <div className={"mb-20"}>
                                <FullContrastPurpleTextField variant={"outlined"} size={"small"} fullWidth value={email} name={"email"} onChange={(event) => {setEmail(event.target.value)}} placeholder={_.upperFirst(t("core.login.email"))} error={mailStatus && mailStatus !== "valid" && mailStatus !== "invalidLink"} helperText={mailStatus === "disposable" ? _.upperFirst(t("core.createAccount.disposableHelper")) : mailStatus === "alreadyMember" ? _.upperFirst(t("core.createAccount.alreadyMemberHelper")) : _.upperFirst(t("core.createAccount.mailHelper"))}/>
                            </div>
                        }
                        {
                            step === OnboardingStep.USER &&
                            <div className={"mb-20"}>
                                <span className={"ebloom-text text-xl"}>{_.upperFirst(t("core.login.email"))}</span>
                                <span className={"ebloom-text text-xl text-contrast-purple ml-5"}>{email}</span>
                            </div>
                        }
                        {
                            step === OnboardingStep.USER &&
                            <div className={"mb-20"}>
                                <FullContrastPurpleTextField fullWidth size={"small"} variant={"outlined"} placeholder={_.upperFirst(t("core.login.name"))} value={name} onChange={(event) => {setName(event.target.value)}} helperText={_.upperFirst(t("core.createAccount.nameHelper"))}/>
                            </div>
                        }
                        {
                            step === OnboardingStep.USER &&
                            <div className={"mb-20"}>
                                <FullContrastPurpleTextField fullWidth size={"small"} variant={"outlined"} placeholder={_.upperFirst(t("core.login.phone"))} value={phone} onChange={(event) => {setPhone(event.target.value)}} helperText={_.upperFirst(t("core.login.phoneHelper2"))} error={phone && !isPhone(phone)}/>
                            </div>
                        }
                        {
                            step === OnboardingStep.USER &&
                            <div className={"mb-20"}>
                                <EbloomSelectV2 className={"w-full"} onChange={(event) => setRole(event.target.value)} value={role}>
                                    <MenuItem value={"role"} disabled>{_.upperFirst(t("core.user.role"))}</MenuItem>
                                    <MenuItem value={"manager"}>{_.upperFirst(t("core.createAccount.role.manager"))}</MenuItem>
                                    <MenuItem value={"hr"}>{_.upperFirst(t("core.createAccount.role.hr"))}</MenuItem>
                                    <MenuItem value={"boss"}>{_.upperFirst(t("core.createAccount.role.boss"))}</MenuItem>
                                    <MenuItem value={"freelance"}>{_.upperFirst(t("core.createAccount.role.freelance"))}</MenuItem>
                                    <MenuItem value={"employee"}>{_.upperFirst(t("core.createAccount.role.employee"))}</MenuItem>
                                    <MenuItem value={"other"}>{_.upperFirst(t("core.createAccount.role.other"))}</MenuItem>
                                </EbloomSelectV2>
                            </div>
                        }
                        {
                            (step === OnboardingStep.VERIFICATION) &&
                            <div className={"mb-20"}>
                                <EbloomSelectLang fullWidth={true} handleChange={(event) => {setLang(event.target.value); setLanguage(event.target.value)}} value={lang}/>
                            </div>
                        }
                        {
                            step === OnboardingStep.VERIFICATION && mailStatus && mailStatus === "valid" &&
                            <p className={"ebloom-text text-lg text-strong-green"}>{_.upperFirst(t("core.createAccount.validHelper"))}</p>
                        }
                        {
                            step === OnboardingStep.VERIFICATION && mailStatus && mailStatus === "invalidLink" &&
                            <p className={"ebloom-text text-lg text-strong-red"}>{_.upperFirst(t("core.createAccount.invalidLinkHelper"))}</p>
                        }
                        {
                            step === OnboardingStep.USER &&
                            <div className={"flex justify-between mb-20"}>
                                <div className={"w-full mr-5"}>
                                    <FullContrastPurpleTextField fullWidth type={"password"} variant={"outlined"} size={"small"} value={password} placeholder={_.upperFirst(t("core.login.password"))} onChange={(event) => {setPassword(event.target.value)}} helperText={_.upperFirst(t("core.login.passwordHelper"))} error={password.length > 0 && password.length < 8}/>
                                </div>
                                <div className={"w-full ml-5"}>
                                    <FullContrastPurpleTextField fullWidth type={"password"} variant={"outlined"} size={"small"} value={confirmPassword} placeholder={_.upperFirst(t("core.login.confirmPassword"))} onChange={(event) => {setConfirmPassword(event.target.value)}} helperText={(password.length > 0 && confirmPassword.length > 0 && confirmPassword !== password ? _.upperFirst(t("core.login.confirmPasswordHelper")) : "")} error={password.length > 0 && confirmPassword.length > 0 && confirmPassword !== password}/>
                                </div>
                            </div>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <div className={"mb-20"}>
                                <FullContrastPurpleTextField fullWidth variant={"outlined"} size={"small"} value={companyName} placeholder={_.upperFirst(t("core.createAccount.companyName"))} helperText={_.upperFirst(t("core.createAccount.companyNameHelper"))} onChange={(event) => {setCompanyName(event.target.value)}}/>
                            </div>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <div className={"mb-20"}>
                                <EbloomSelectV2 className={"w-full"} onChange={(event) => {setCompanySize(event.target.value)}} value={companySize}>
                                    <MenuItem disabled value={"size"}>{_.upperFirst(t("core.createAccount.companySize"))}</MenuItem>
                                    <MenuItem value={"<10 ETP"}>{"<10 ETP"}</MenuItem>
                                    <MenuItem value={"<50 ETP"}>{"<50 ETP"}</MenuItem>
                                    <MenuItem value={"<100 ETP"}>{"<100 ETP"}</MenuItem>
                                    <MenuItem value={"<200 ETP"}>{"<200 ETP"}</MenuItem>
                                    <MenuItem value={"<300 ETP"}>{"<300 ETP"}</MenuItem>
                                    <MenuItem value={">300 ETP"}>{">300 ETP"}</MenuItem>
                                </EbloomSelectV2>
                            </div>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <div className={"mb-20"}>
                                <EbloomSelectV2 className={"w-full overflow-y-auto"} onChange={(event) => {setCountry(event.target.value)}} value={country}>
                                    <MenuItem disabled value={"country"}>{_.upperFirst(t("core.createAccount.country"))}</MenuItem>
                                    {
                                        countries?.sort((a,b) => {return a[lang]<b[lang] ? -1 : a[lang]>b[lang] ? 1 : 0}).map((c, index) => (
                                            <MenuItem key={index} value={c.value}>{c[lang]}</MenuItem>
                                        ))
                                    }
                                </EbloomSelectV2>
                            </div>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <div className={"mb-20"}>
                                <EbloomSelectV2 className={"w-full overflow-y-auto"} onChange={(event) => {setSector(event.target.value)}} value={sector}>
                                    <MenuItem disabled value={"sector"}>{_.upperFirst(t("core.createAccount.sector"))}</MenuItem>
                                    {
                                        sectors?.map((s, index) => (
                                            <MenuItem key={index} value={s}>{_.upperFirst(t("core.createAccount.sectors." + s))}</MenuItem>
                                        ))
                                    }
                                </EbloomSelectV2>
                            </div>
                        }
                        {
                            step === OnboardingStep.COMPANY &&
                            <div className={"mb-20"}>
                                <div className={"ebloom-text text-contrast-purple mb-5"}>{_.upperFirst(t("core.createAccount.useCaseHelper"))}</div>
                                <div className={"grid grid-cols-3"}>
                                    {
                                        useCases.map((uc, index) => (
                                            <div key={index} className={useCase.includes(uc.value) ? "bg-contrast-purple border border-white rounded-2xl m-5 flex items-center p-5 cursor-pointer" : "border border-purple rounded-2xl m-5 flex items-center p-5 cursor-pointer"} onClick={() => {handleUseCase(uc.value)}}>
                                                <uc.image className={(uc.value === "management" ? "w-50 h-50 p-5 " : "w-40 h-40 p-5 ") + (useCase.includes(uc.value) ? "white-icon" : "contrast-purple-icon")}/>
                                                <span className={useCase.includes(uc.value) ? "text-white" : "text-contrast-purple"}>{_.upperFirst(t("core.createAccount.useCase." + uc.value))}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {
                            OnboardingStep.COMPANY === step &&
                            <div className={"mt-10"}>
                                <Checkbox className={"checkbox-purple"} checked={policyChecked}
                                          onChange={() => {setPolicyChecked(!policyChecked)}}/><span><a
                                href="/documents/Privacy_Policy_eBloom.html" className={"ebloom-link-purple"} target="_blank">{_.upperFirst(t("core.login.iHaveAgree", {param:t("core.login.privacyPolicy")}))}</a>*</span>
                            </div>
                        }
                    </div>
                    <div className={"mt-50 w-full"}>
                        {
                            step >= OnboardingStep.COMPANY &&
                            <div className={"ebloom-link-purple text-center mb-5"} onClick={() => {setStep(step-1)}}>{_.upperFirst(t("core.previous"))}</div>
                        }
                        <Button disabled={disableButton()} className={"ebloom-btn-purple w-full"} onClick={handleNextStep}>{loading ? <LoadingView size={16} color={"white"}/> : _.upperFirst(getTranslations(lang, OnboardingStep.COMPANY === step ? "core.createAccount.title" : "core.createAccount.continue"))}</Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

CreateAccountPage.propTypes = {}

CreateAccountPage.defaultProps = {}
export default CreateAccountPage