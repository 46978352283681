import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification, notifyError
} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import CategoryModel from "../../../../../models/Category";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import ModelForms from "../../../../Core/Modules/Input/ModelForms";

const CategoryEditor = (props) => {
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({id:null, name:""});
    const [tablePage, setTablePage] = useState(0);
    const [sideFormOpen, setSideFormOpen] = useState(false);

    const t = useTranslation();

    const handleDelete = () => {
        fetchCategories();
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCategory(prevState => {
            let category = {...prevState};
            category[name] = value;
            return category;
        })
    }

    const handleSave = () => {
        let url = CategoryModel.crud.update;
        let data = category;
        let notif1 = "updated";
        let notif2 = "updating"

        if(!category.id){
            url = CategoryModel.crud.create;
            delete data.id;
            notif1 = "created";
            notif2 = "creating"
        }

        HttpApi.postV2(url, data).then(response => {
            if(response){
                createSuccessNotification("Success !", "Category successfully " + notif1);
                fetchCategories();
                setSideFormOpen(false);
            }else{
                createErrorNotification("Error !", "An error occurred while " + notif2 + " the category");
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const fetchCategories = () => {
        HttpApi.getV2("/categories/getAll").then(response => {
            if(response && response.data){
                setCategories(response.data);
            }else{
                createInfoNotification("Info !", "No categories found");
                setCategories([]);
            }
        }).catch(error => {
           notifyError(error);
        })
    };

    const resetCategoryEditState = () => {
        setCategory({id:null, name:""});
    };

    useEffect(() => {
        fetchCategories();
    }, []);


    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(toEdit){
            setCategory(toEdit);
        }else{
            resetCategoryEditState();
        }

        setSideFormOpen(open);
    };

    const formTitle = category.id ? _.capitalize(t("core.update")) + " " + _.capitalize(t("core.category.category")) : _.capitalize(t("core.create")) + " " + _.capitalize(t("core.category.category"));

    return(
        <>
            <CardDefault title={_.capitalize(t("core.category.categories"))} handleRightButton={toggleDrawer(true)}>
                <TableViewV2 items={categories} model={CategoryModel} handleDelete={handleDelete} handleEdit={toggleDrawer} tablePage={tablePage} handleTablePage={handleTablePage}/>
            </CardDefault>

            <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <ModelForms model={CategoryModel} handleChange={handleChange} values={category}/>
            </SideForm>
        </>
    )

}

export default CategoryEditor;