import React, {useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {useNavigate, useParams} from "react-router-dom";
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createInfoNotification, createSuccessfullyUpdatedNotification, createSuccessNotification, notifyError
} from "../../../../../Utils/Notification";
import _ from "lodash";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import {Button, Row} from "react-bootstrap";
import PlaylistModel from "../../../../../models/Playlist";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import {useTranslation} from "react-multi-lang/lib";
import EbloomJumbotron from "../../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";
import QuestionList from "../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import {getUrl} from "../../../../../Utils/Global";
import {useUserData} from "../../../../../hooks/userHook";
import LoadingView from "../../../../Core/Layouts/LoadingView";


const Playlist = (props) => {
    const [playlist, setPlaylist] = useState({id:null, name:""});
    const [title, setTitle] = useState("");
    const [questions, setQuestions] = useState([]);
    const [questionsNotInPlaylist, setQuestionsNotInPlaylist] = useState([]);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [tablePage, setTablePage] = useState(0);
    const [sideFormQuestion, setSideFormQuestion] = useState(false);


    const params = useParams();
    const navigate = useNavigate()
    const t = useTranslation()
    const user = useUserData()

    const {companyToEdit,isFree} = props;

    const fetchPlaylist = () => {
        if(params.id){
            let url = "/playlists/getById/"+params.id
            if(params.idEntity){
                url += "/" + params.idEntity;
            }
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setPlaylist(response.data);
                    setTitle(response.data.name);
                }else{
                    createInfoNotification("Info !", "Playlist with ID " + params.id + " not found");
                    navigate("/admin/questions/playlist");

                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification("Error !", "Invalid playlist ID");
        }

    };

    const fetchQuestionsNotInPlaylist = () => {
        let url = "/questions/getNotInPlaylist/" + params.id
        if(params.idEntity){
            url = "/questions/getNotInPlaylist/" + params.id + "/" + params.idEntity;
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setQuestionsNotInPlaylist(response.data);
            }else{
                setQuestionsNotInPlaylist([]);
            }
        }).catch(error => {
            notifyError(error);
        });
    }


    const fetchQuestions = () => {
        if(params.id){
            let url =  "/questions/getForPlaylist/"+ params.id;
            if(params.idEntity){
                url = "/questions/getForPlaylist/"+ params.id + "/" + params.idEntity;
            }
            HttpApi.getV2(url).then(results => {
                if(results && results.data){
                    setQuestions(results.data);
                }else{
                    setQuestions([]);

                }
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification("Error !", "Invalid playlist ID");
        }

    };

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setPlaylist(prevState => {
            let playlist = {...prevState};
            playlist[name] = value;
            return playlist;
        })
    };

    const handleSave = () => {
        if(!!playlist.id && playlist.name){
            let playlistData = {
                id: parseInt(playlist.id),
                name: playlist.name,
            }
            let url = "/playlists/update"
            if(params.idEntity){
                url += "/"+ params.idEntity
            }
            HttpApi.postV2(url, playlistData).then(() => {
                createSuccessfullyUpdatedNotification("playlist");
                setSideFormOpen(false);
                fetchPlaylist();
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const handleAdd = (id_question) => event => {
        event.preventDefault();
        if(id_question && params.id){
            let url = "/playlists/addQuestion"
            let data = {id_playlist:parseInt(params.id), id_question:id_question}
            if(params.idEntity){
                url += "/"+params.idEntity
            }
            HttpApi.postV2(url, data).then(() => {
                createSuccessNotification("Success !", "Question successfully added to your playlist");
                fetchQuestions();
                fetchQuestionsNotInPlaylist()
            }).catch(error => {
                notifyError(error);
            })
        }
    };


    const handleRemove = (id_question) => event => {
        const id_playlist = params.id;
        if(id_question && id_playlist){
            let url = "/playlists/removeQuestion"
            let data = {id_playlist:id_playlist, id_question:id_question}
            if(params.idEntity){
                url += "/"+params.idEntity;
            }
            HttpApi.postV2(url,data).then(() => {
                createSuccessNotification("Success !", "Click & Bloom successfully removed from your playlist");
                fetchQuestions();
                fetchQuestionsNotInPlaylist()
            }).catch(error => {
               notifyError(error);
            })
        }

    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    useEffect(() => {
        fetchPlaylist();
        fetchQuestions();
        fetchQuestionsNotInPlaylist()
    }, []);

    useEffect(() => {
        fetchPlaylist();
        fetchQuestions();
        fetchQuestionsNotInPlaylist()
    }, [params.id]);


    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSideFormOpen(open);
    };

    const toggleDrawerQuestions = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSideFormQuestion(open);
    };

    const handleBack = () => {
        let url = companyToEdit ? "/backoffice/playlists" : "/admin/questions/playlist";
        if(params.idEntity){
            url = "/admin/questions/playlist?entity=" + params.idEntity
        }
        navigate(url);
    }

    const formTitle = _.capitalize(t("core.playlist.rename"));

    return(
        <div>
            {
                playlist &&
                <CardDefault showLeftButton={true} leftButtonVariant={"secondary"} leftButtonText={_.capitalize(t("core.back"))} handleLeftButton={handleBack} border={"none"} rightButtonPurple={true} title={title} buttonText={"core.rename"} showButton={playlist.name !== "eBloom Selection" && !props.companyToEdit} handleRightButton={toggleDrawer(true)}>
                    {
                        (questions && questions.length > 0) ?
                            <div>
                                {
                                    (playlist.name !== "eBloom Selection" && !props.companyToEdit) &&
                                    <div style={{textAlign:"right", marginBottom:20}}>
                                        <Button data-playlist={'addClickAndBloom'} className={"ebloom-btn-purple"} onClick={toggleDrawerQuestions(true)}>{_.upperFirst(t("core.addParam", {param:"Click&Bloom"}))}</Button>
                                    </div>
                                }
                                <QuestionList questions={questions} showAction={playlist.name !== "eBloom Selection"} buttonType={"remove"} handleAction={handleRemove} showPagination tablePage={tablePage} handleTablePage={handleTablePage} showTag tagLink showLastUsed companyToEdit={companyToEdit} isEbloomSelection={playlist.name !== "eBloom Selection"} entityId={parseInt(params.idEntity)}/>

                            </div>
                            :
                            <EbloomJumbotron title={_.upperFirst(t("core.playlist.playlistGetStarted", {name:playlist.name}))} handleAction={toggleDrawerQuestions(true)}/>
                    }

                </CardDefault>
            }

            <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={handleSave}>
                <Row>
                    <TextFieldCustom model={PlaylistModel.name} value={playlist.name} handleChange={handleChange}/>
                </Row>
            </SideForm>
            <SideForm toggleDrawer={toggleDrawerQuestions} sideFormOpen={sideFormQuestion} showButtons={false} formTitle={"core.add"}>
                <QuestionList questions={questionsNotInPlaylist}  buttonType="add" handleAction={handleAdd} showDiagnostic showLastUsed showTag entityId={parseInt(params.idEntity)}/>
            </SideForm>
        </div>
    )

}

export default Playlist;