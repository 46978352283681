import axios from 'axios';
import Login from "./login/login";
import {setLanguage} from "react-multi-lang/lib";
import {CompanyTypes, isPhone} from "./Utils/Global";
import {getFromStorage, getStorage} from "./Utils/Storage";
import { Crisp } from "crisp-sdk-web";
import { createErrorNotification, notifyCustomError, notifyError } from './Utils/Notification';

export const Sources = {
    GLOBAL_STAT:"globalStatSource",
    GLOBAL_STAT_COMPANY:"globalStatCompanySource",
    MOST_FLAGGED:"mostFlaggedSource",
    IMPORTANCE_SATISFACTION:"importanceAndSatisfactionSource",
    CATEGORIES:"categoriesSource",
    MOST_RECENT:"mostRecentSource",
    SCORE_FROM_START:"scoreFromStartSource",
    NB_SUBS:"nbSubsSource",
    PARTICIPATION_RATE:"participationRateSource",
    RADAR_DATA:"radarDataSource",
    HEAT_MAP_DATA:"heatMapDataSource",
    HISTORY:"historySource",
    GLOBAL_STAT_REPORT:"globalStatReportSource",
    MOST_FLAGGED_REPORT:"mostFlaggedReportSource",
    IMPORTANCE_SATISFACTION_REPORT:"importanceAndSatisfactionReportSource",
    CATEGORIES_REPORT:"categoriesReportSource",
    SCORE_FROM_START_REPORT:"scoreFromStartSource",
    HISTORY_REPORT:"historyReportSource",
    ENTITIES_WEBLOOM:"entitiesWebloomSource",
    COMPANY_DATA_SCORES : "companyDataScoresSource"
}

class HttpApi {
    constructor(){
        this.baseUrl = null;
        this.apiInstance = null;
        this.globalStatSource = null;
        this.mostFlaggedSource = null;
        this.importanceAndSatisfactionSource = null;
        this.categoriesSource = null;
        this.mostRecentSource = null;
        this.scoreFromStartSource = null;
        this.nbSubsSource = null;
        this.participationRateSource = null;
        this.radarDataSource = null;
        this.heatMapDataSource = null;
        this.historySource = null;
        this.companyDataScoresSource = null;
    }

    setBaseUrl(url) {
        this.baseUrl = url;
    }

    getBaseUrl(){
        return this.baseUrl;
    }

    setBackofficeToken(token) {
        this.apiInstance = axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getFromStorage("token") + " " + token,
            }
        });
    }


    async connectApp(dataCredentials) {
        if (dataCredentials === null || dataCredentials === undefined) {
            if (getFromStorage("token")) {
                if (localStorage.getItem("ebloom-companyToEdit")) {
                    const data = JSON.parse(localStorage.getItem("ebloom-companyToEdit"))
                    const response = await axios.request({
                        method: "GET",
                        url: this.baseUrl + "/v2/auth/companyToEdit/"+data.id,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + getFromStorage("token"),
                        },
                    })
                    const backOfficeToken = response.data;
                    this.setBackofficeToken(backOfficeToken)
                } else {
                    this.apiInstance = axios.create({
                        baseURL: this.baseUrl,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + getFromStorage("token"),
                        }
                    });
                }
                let store = getStorage("ebloom-credentials") || {};
                let email = store.email;
                let name = store.name;
                let company = store.company;

                //initialize companyType to client for old companies with no value
                if (!store.company_type) {
                    store.company_type = CompanyTypes.CLIENT;
                    localStorage.setItem("ebloom-credentials", JSON.stringify(store));
                }

                if (isPhone(email)) {
                    Crisp.user.setPhone(email)
                } else {
                    Crisp.user.setEmail(email);
                }
                Crisp.user.setCompany(company, {});
                if (name) {
                    Crisp.user.setNickname(name);
                }

                this.getV2("/auth/verifyCredentials").then(result => {
                    if (result && result.data === true) {
                        Login.initiateRole();
                        this.getAppLanguage();
                    } else {
                        Login.logout();
                    }
                }).catch(error => {
                    console.log("Credentials error : " + error);
                    Login.logout();
                });


            } else {
                this.apiInstance = axios.create({
                    baseURL: this.baseUrl,
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
            }

            return;
        }
        if (localStorage.getItem("ebloom-companyToEdit")) {
            const data = JSON.parse(localStorage.getItem("ebloom-companyToEdit"))
            const response = await axios.request({
                method: "GET",
                url: this.baseUrl + "/v2/auth/companyToEdit/"+data.id,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + getFromStorage("token"),
                },
            })
            const backOfficeToken = response.data;
            this.setBackofficeToken(backOfficeToken)
        } else {
            this.apiInstance = axios.create({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + dataCredentials.token,
                }
            });
        }


        let email = dataCredentials.email;
        let company = dataCredentials.company;
        let name = dataCredentials.name;

        if (isPhone(email)) {
            Crisp.user.setPhone(email);
        } else {
            Crisp.user.setEmail(email);
        }
        Crisp.user.setCompany(company, {});
        if (name) {
            Crisp.user.setNickname(name);
        }

        setLanguage(dataCredentials.lang);
    }

    connectPublicApp(token){
        this.apiInstance = axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            }
        });
    }

    getPublicCredentials(token){
        return new Promise((resolve, reject) => {
            axios.request({
                method:"GET",
                url: this.baseUrl + "/v2/auth/verifyPublicCredentials",
                headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
            }).then((response) => {
                if(response && response.data !== null){
                    //valid token
                    this.getAppLanguage();
                    resolve(response.data);
                }else{
                    resolve(null);
                }
            }).catch(error => {
                console.log("Credentials error : " + error);
                reject(error);
            })
        })
    }

    getSource(name){
        if(this[name]){
            this[name].cancel("axios request canceled")
        }
        const source = axios.CancelToken.source();
        this[name] = source;
        return source;
    }

    callWithMethod (method, config) {
        config.method = method;
        return new Promise((resolve, reject) => {
            this.apiInstance.request(config).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    }

    post = (url, data) => {
        return this.callWithMethod("post", {url:url, data:data});
    };

    postV2 = (url, data) => {
        return new Promise((resolve, reject) => {
            this.post("/v2" + url, data).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                   resolve(null)
                }
                resolve(response)
            }).catch(error => {
                notifyError(error);
            });
        })

    }

    postFile = (url, data) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("file", data.file);
            formData.append("fileName", data.fileName);
            axios.request({
                method:"POST",
                url: this.baseUrl + "/v2/files/upload",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + getFromStorage("token"),
                },
                data:formData
            }).then((response) => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    }

    put = (url, data) => {
        return new Promise((resolve, reject) => {
            this.callWithMethod("put", {url: '/v2'+url, data:data}).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                    resolve(null);
                }
                resolve(response)
            }).catch(error => {
                notifyError(error);
            });
        })

    }

    delete = (url) => {
        return new Promise((resolve, reject) => {
            this.callWithMethod("delete", {url: '/v2'+url}).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                    resolve(null);
                }
                resolve(response);
            }).catch(error => {
                if(url === "/auth/logout" && error && error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)){
                    createErrorNotification("Error !", "Access denied");
                }else{
                    notifyError(error);
                }
            });
        })
    }

    get = (url, sourceName=null) => {
        let token = sourceName ? this.getSource(sourceName).token : null
        return this.callWithMethod("get", {url:url, cancelToken:token});
    };

    getV2 = (url, sourceName=null) => {
        return new Promise((resolve, reject) => {
            this.get("/v2" + url, sourceName).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                    resolve(null);
                }
                resolve(response);
            }).catch(error => {
                notifyError(error);
                reject(error);
            });
        })
    }

    getWithResponseType = (url, responseType="arraybuffer") => {
        return this.callWithMethod("get", {url:url, responseType:responseType});
    }

    getWithResponseTypeV2 = (url, responseType="arraybuffer") => {
        return this.getWithResponseType('/v2' + url, responseType).then(response => {
            if(response && response.data && response.data.error && response.data.error.msg){
                notifyCustomError(response.data.error.msg)
                return null;
            }
            return response;
        }).catch(error => {
            notifyError(error);
        });
    }

    authenticate = (data) => {
        return new Promise((resolve, reject) => {
            axios.request({
                method:"POST",
                url: this.baseUrl + "/v2/auth/login",
                headers: {"Content-Type": "application/json"},
                data: data
            }).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                    resolve(null);
                }
                resolve(response);
            }).catch(error => {
                notifyError(error);
                reject(error);
            });
        })

    };

    requestResetPassword = (data) => {
        return new Promise((resolve, reject) => {
            axios.request({
                method:"POST",
                url:this.baseUrl + "/v2/auth/requestResetPassword",
                headers:{"Content-Type": "application/json"},
                data:data
            }).then(response => {
                if(response && response.data && response.data.error && response.data.error.msg){
                    notifyCustomError(response.data.error.msg)
                    resolve(null);
                }
                resolve(response);
            }).catch(error => {
                notifyError(error);
                reject(error);
            });
        })
    };

    getAppLanguage = () => {
        this.getV2('/users/getLang').then(response => {
            if(response && response.data){
                setLanguage(response.data.lang);
            }else{
                console.log("Error while loading language")
            }

        }).catch(error => console.log(error));
    }
}

let instance = new HttpApi();

export default instance;

