import React, {useEffect, useState} from "react";
import {Card, Row, Col, Button} from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import {Colors} from "../../Utils/Colors";
import TextFieldCustom from "./Modules/Input/TextFieldCustom";
import UserModel from "../../models/User";
import {createErrorNotification, createSuccessNotification, notifyError} from "../../Utils/Notification";
import HttpApi from "../../httpApi";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-multi-lang/lib';
import _ from "lodash";


const ResetPassword = (props) => {
    const [user, setUser] = useState({password:"", confirmPassword:""});
    const [token, setToken] = useState("");
    const [userId, setUserId] = useState("");

    const navigate = useNavigate();
    const t = useTranslation();

    const handleSave = () => {
        if(UserModel.password.form.error(user.password) || UserModel.confirmPassword.form.error(user.password, user.confirmPassword)){
            createErrorNotification("Error !", _.upperFirst(t("core.login.passwordHelper")));
        }else{
            HttpApi.put("/auth/resetPassword", {id_user: parseInt(userId), token: token, password: user.password, confirmPassword:user.confirmPassword}).then(() => {
                createSuccessNotification("Success !", "Password successfully updated");
                navigate("/login");
            }).catch(error => {
                notifyError(error);
                setUser({password:"", confirmPassword: ""});
            })
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setUser(prevState => {
            let user = {...prevState};
            user[name] = value;
            return user;
        })
    };

    const checkForUrlParams = () => {
        let url = new URL(window.location.href);
        let tokenUrl = url.searchParams.get("token");
        let userIdUrl = url.searchParams.get("id");
        if(tokenUrl && userIdUrl){
            setToken(tokenUrl);
            setUserId(userIdUrl);
        }else{
            let host = window.location.host;
            let protocol = window.location.protocol;
            window.location.replace( protocol + "//" + host + "/login");
        }
    };

    useEffect(() => {
        checkForUrlParams();
    }, []);


    return(
        <div style={{backgroundColor:Colors.EBLOOM_BG_BLUE, minHeight:"100vh"}}>
            <div style={{padding: 60}} >
                <div className="logo-ebloom" style={{height: 90}}/>
            </div>
            <Container>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card style={{backgroundColor:"transparent", borderColor:"transparent"}}>
                            <Card.Body>
                                <div style={{textAlign:"center"}}>
                                    <Card.Title style={{color:Colors.EBLOOM_CONTRAST_PURPLE, fontSize:"2em", fontWeight:"normal"}}>Reset Password</Card.Title>
                                </div>
                                <Row>
                                    <TextFieldCustom  model={UserModel.password} error={UserModel.password.form.error(user.password)} helper value={user.password} handleChange={handleChange}/>
                                </Row>
                                <Row>
                                    <TextFieldCustom  model={UserModel.confirmPassword} error={UserModel.confirmPassword.form.error(user.password, user.confirmPassword)} value={user.confirmPassword} handleChange={handleChange}/>
                                </Row>
                                <Row>
                                    <div style={{width:"100%",textAlign:"center"}}>
                                        <Button className={"ebloom-btn-purple"} onClick={handleSave}>
                                            Reset
                                        </Button>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default ResetPassword;