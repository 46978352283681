import React from "react"
import DisplayBackofficeView from "../../../Layouts/DisplayBackofficeView";
import WeBloomDashboard from "../../../../Admin/Pages/WeBloom/Dashboard/WeBloomDashboard";
import {Container} from "react-bootstrap";

const BackofficeWeBloomPage = (props) => {

    return (
        <DisplayBackofficeView {...props}>
            {
                (companyToEdit) => (
                    <Container>
                        <WeBloomDashboard companyToEdit={companyToEdit} {...props}/>
                    </Container>
                )
            }
        </DisplayBackofficeView>
    )

}

export default BackofficeWeBloomPage;