import React, {useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import * as PropTypes from "prop-types";
import {notifyError} from "../../../../Utils/Notification";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {useUserData} from "../../../../hooks/userHook";
import {FormControl} from "@mui/material";
import Select from "@mui/material/Select";
import _ from "lodash";
import { useTranslation} from "react-multi-lang/lib";
import {Colors} from "../../../../Utils/Colors";
import {alpha} from "@mui/material/styles";


const TeamSwitcherEntities = (props) => {
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [teams, setTeams] = useState([]);

    const {handleSwitch,entity} = props;

    const user = useUserData();

    const t = useTranslation();

    const handleChangeMultiple = (event) => {
        const value = event.target.value
        const companySelected = value.filter(el => el.id === 0).length > 0 && selectedTeam.filter(el => el.id === 0).length < 1
        if(value.length > 0){
            const indexCompanyRemove = value.findIndex(el => el.id === 0)
            if(companySelected){
                setSelectedTeam([teams[0]])
                setShowMenu(false)
                handleSwitch([teams[0]])
            }else if(indexCompanyRemove !== -1){
                value.splice(indexCompanyRemove,1)
                setSelectedTeam(value)
                handleSwitch(value)
            }else{
                setSelectedTeam(value)
                handleSwitch(value)
            }
        }else if((value.length === 1 && value[0].id === 0) || (value.length === 0 && selectedTeam.length === 1 && selectedTeam[0].id === 0)){
            setShowMenu(false)
        }
    }

    const fetchValidTeams = () => {
        let url = "/teams/getValid/"+entity

        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                const listTeams = response.data;
                listTeams.splice(0,0,{id : 0, name: _.upperFirst(t("core.global"))})
                setTeams(listTeams)
                setSelectedTeam([listTeams[0]])
                handleSwitch([listTeams[0]])
            }else{
                const listTeams = [{id : 0, name: _.upperFirst(t("core.global"))}]
                setTeams(listTeams)
                setSelectedTeam([listTeams[0]])
                handleSwitch([listTeams[0]])
            }
        }).catch(error => {
            notifyError(error);
        })
    };



    useEffect(() => {
        fetchValidTeams();
    }, [entity]);


    return (
        <div className={"w-auto max-w-125"}>

            <FormControl sx={{
                minWidth: 125,
                maxWidth: 125,
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:"none"
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border:"none"
                },
                "&& .MuiSelect-icon":{
                    color: Colors.EBLOOM_PURPLE
                }
            }}  fullWidth={true} className={"text-ellipsis w-auto max-w-125 min-w-100 overflow-hidden flex-nowrap"} size={"small"}>
                <Select
                    className={"text-contrast-purple h-35 mr-5 bg-white border border-contrast-purple text-ellipsis overflow-hidden flex-nowrap"}
                    multiple={true}
                    value={selectedTeam}
                    name={"selectTeams"}
                    onChange={handleChangeMultiple}
                    onClose={()=> setShowMenu(false)}
                    onOpen={()=> setShowMenu(true)}
                    open={showMenu}
                    MenuProps={{
                        sx : {
                            "&& .Mui-selected": {
                                backgroundColor : alpha(Colors.EBLOOM_PURPLE,0.35)
                            },
                        }
                    }}
                    renderValue={(selected) => {
                        if(selected.length === 1){
                            return <div><p
                                className={"text-ellipsis overflow-hidden"}>{selected[0].name}</p>
                            </div>
                        }else{
                            return <div>
                                <p className={"text-ellipsis overflow-hidden"}>{selected.length +" "+ _.lowerFirst(t("admin.teams"))}</p>
                            </div>
                        }
                    }
                    }
                >
                    {
                        teams.map((option, index) => {
                            return(
                                <MenuItem key={index} value={option} >{option.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>
    );
}

TeamSwitcherEntities.propTypes = {
    handleSwitch:PropTypes.func.isRequired,
    entity:PropTypes.number.isRequired

};

export default TeamSwitcherEntities;