import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import LoadingView from "../../../../Core/Layouts/LoadingView";
import moment from "moment";
import {ReactComponent as HandIcon} from "../../../../Core/symbols/myBloom/icons/Hands.svg";
import {ReactComponent as DoneIcon} from "../../../../Core/symbols/myBloom/icons/Done.svg";
import {ReactComponent as SmileyUnhappyIcon} from "../../../../Core/symbols/smileys/smileyUnhappy.svg";
import {ReactComponent as SmileySadIcon} from "../../../../Core/symbols/smileys/smileySad.svg";
import {ReactComponent as SmileyNeutralIcon} from "../../../../Core/symbols/smileys/smileyNeutral.svg";
import {ReactComponent as SmileyGladIcon} from "../../../../Core/symbols/smileys/smileyGlad.svg";
import {ReactComponent as SmileyHappyIcon} from "../../../../Core/symbols/smileys/smileyHappy.svg";
import {ReactComponent as SmileyAnonymousIcon} from "../../../../Core/symbols/smileys/smileyAnonymous.svg";
import {ReactComponent as FlagIcon} from "../../../../Core/symbols/flags/flag-purple-contrast.svg";
import {ReactComponent as AnswerIcon} from "../../../../Core/symbols/myBloom/icons/AnswerIcon.svg";
import {ReactComponent as IdeaBoxIcon} from "../../../../Core/symbols/webloom/white/ideabox-white.svg";
import {ReactComponent as PollsIcon} from "../../../../Core/symbols/webloom/white/poll-white.svg";
import {ReactComponent as ArrowDown} from "../../../../Core/symbols/myBloom/icons/ArrowDown.svg";
import {ReactComponent as ArrowUp} from "../../../../Core/symbols/myBloom/icons/ArrowUp.svg";
import {ReactComponent as FilterIcon} from "../../../../Core/symbols/myBloom/icons/FilterIcon.svg";
import {ReactComponent as CloseIcon} from "../../../../Core/symbols/myBloom/icons/Close.svg";

import _ from "lodash";
import {useBreakpoints} from "../../../../../hooks/breakpointsHook";
import CategoryList from "../../../../Core/Modules/Input/CategoryList";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import TagList from "../../../../Core/Modules/Input/TagList";
import EbloomSearchBar from "../../../../Core/Modules/Input/EbloomSearchBar";
import ActionList from "./ActionList";
import CategoryTag from "../../../../Core/Modules/CategoryDisplay/CategoryTag";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import TablePagination from "@mui/material/TablePagination/TablePagination";
import {useUserData} from "../../../../../hooks/userHook";
import {Badge} from "react-bootstrap";
import {Colors} from "../../../../../Utils/Colors";


const MyActivity = (props) => {

    const {history,loading,isModelCustomEnable} = props

    const bp = useBreakpoints()
    const t = useTranslation()
    const user = useUserData()

    const isProspect = user.module === 'prospect'


    const [openFilters, setOpenFilters] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [selectedTag, setSelectedTag] = useState(-1);
    const [selectedAction, setSelectedAction] = useState(-1);
    const [searchValue, setSearchValue] = useState(null);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("date");
    const [historyByCategory, setHistoryByCategory] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rowsPerPageMobile, setRowsPerPageMobile] = useState(10);



    const generateSortIcon = (type) => {
        if(orderBy === type){
            if(order === "desc"){
                return <div className={"w-20 h-20 flex flex-row justify-center items-center ml-5"}>
                    <ArrowDown/>
                </div>
            }else{
                return <div className={"w-15 h-15 flex flex-row justify-center items-center ml-5"}>
                    <ArrowUp/>
                </div>
            }
        }else{
            return <div className={"w-20 h-20 flex flex-row justify-center items-center ml-5"}>
                <ArrowUp/>
                <ArrowDown/>
            </div>
        }
    }

    const generateIcon = (value) => {
      if(value.survey_type){
        if (value.survey_type === "poll"){
            return <PollsIcon className={"w-35 h-35 contrast-purple-icon"}/>
        }else{
            return <IdeaBoxIcon className={"w-35 h-35 contrast-purple-icon"}/>
        }
      }else{
          return <HandIcon className={"w-35 h-35 contrast-purple-icon"}/>
      }
    }

    const selectSmiley = (score) => {
        switch (score) {
            case -1 :
                return <SmileyAnonymousIcon className={"w-35 h-35"}/>
            case 1 :
                return <SmileyUnhappyIcon className={"w-35 h-35"}/>
            case 2 :
                return <SmileySadIcon className={"w-35 h-35"}/>
            case 3 :
                return <SmileyNeutralIcon className={"w-35 h-35"}/>
            case 4 :
                return <SmileyGladIcon className={"w-35 h-35"}/>
            case 5 :
                return <SmileyHappyIcon className={"w-35 h-35"}/>
            default:
                break;
        }
    }

    const generateLogos = (value) => {
        if(bp.isTabletLandscapeOrLess()){
            if(value.score){
                return [
                    <div key={"score"} className={"col-start-10 col-end-11 w-full text-center flex justify-center items-center"}>
                        {selectSmiley(value.score)}
                    </div>,
                    <div key={"comment"} className={"col-start-11 col-end-12 w-full text-center flex justify-center items-center"}>
                        {value.comment ?
                            <EbloomTooltip text={value.comment}>
                                <AnswerIcon className={"w-30 h-30 purple-icon"}/>
                            </EbloomTooltip>
                            : <AnswerIcon className={"w-30 h-30 grey-icon"}/>}
                    </div>,
                    <div key={"flag"} className={"col-start-12 col-end-13 w-full text-center flex justify-center items-center"}>
                        {Boolean(value.flag) ? <FlagIcon className={"w-30 h-30 contrast-purple-icon"}/> : <FlagIcon className={"w-30 h-30 grey-icon"}/>}
                    </div>
                ]
            }else{
                return <div className={"col-start-10 col-end-11 w-full text-center flex justify-center items-center"}>
                    <DoneIcon className={"w-25 h-25"}/>
                </div>
            }
        }else{
            if(value.score){
                return [
                    <div key={"score"} className={"col-start-10 col-end-11 w-full text-center flex justify-center items-center"}>
                        {selectSmiley(value.score)}
                    </div>,
                    <div key={"comment"} className={"col-start-11 col-end-12 w-full text-center flex justify-center items-center"}>
                        {value.comment ?
                            <EbloomTooltip text={value.comment}>
                                <AnswerIcon className={"w-30 h-30 purple-icon"}/>
                            </EbloomTooltip>
                            :
                            <AnswerIcon className={"w-30 h-30 grey-icon"}/>}
                    </div>,
                    <div key={"flag"} className={"col-start-12 col-end-13 w-full text-center flex justify-center items-center"}>
                        {Boolean(value.flag) ? <FlagIcon className={"w-30 h-30 contrast-purple-icon"}/> : <FlagIcon className={"w-30 h-30 grey-icon"}/>}
                    </div>
                ]
            }else{
                return <div className={"col-start-10 col-end-11 w-full text-center flex justify-center items-center"}>
                    <DoneIcon className={"w-25 h-25"}/>
                </div>
            }
        }

    }

    const handleChangeOrderBy = (type) => event => {
        event.preventDefault();
        if(orderBy === type){
            setOrder(order === "desc" ? "asc" : "desc");
        }else{
            setOrderBy(type);
            setOrder("desc");
        }
        if(page > 0){
            setPage(0);
        }
    }

    const filterBySearch = (array, value) => {
        if(value){
            if(value.includes("::")){
                const label = _.lowerCase(value.split("::")[0]);
                const data = _.lowerCase(value.split("::")[1]);
                array = array.filter((el) => (_.lowerCase(el[label]).includes(data)));
                return array;
            }else{
                const val = _.lowerCase(value);
                array = array.filter((el) => (_.lowerCase(el.text).includes(val)  || _.lowerCase(el.survey_title).includes(val) || (el.category && _.lowerCase(el.category.name).includes(val)) || _.lowerCase(moment(el.date).format("YYYY/MM/DD")).includes(val) || _.lowerCase(el.score).includes(val)));
                if(orderBy){
                    return array.sort(getComparator(order, orderBy))
                }
                return array;
            }
        }

        if(orderBy){
            return array.sort(getComparator(order, orderBy))
        }

        if(!value){
            return null;
        }
    };
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => ascendingComparator(a, b, orderBy);
    }

    const descendingComparator = (a, b, orderBy) => {

        if(orderBy === "score"){
            if(b.score === a.score){
                return 0
            }else if (!b.score){
                return -1
            }else if (!a.score){
                return 1
            }else{
                return b.score - a.score
            }
        }
        if(orderBy === "com") {
            const aHasComment = "comment" in a ;
            const bHasComment = "comment" in b ;

            if (aHasComment && !bHasComment) {
                return -1;
            } else if (!aHasComment && bHasComment) {
                return 1;
            }
            if (aHasComment && bHasComment) {
                if (a.comment === null && b.comment !== null) {
                    return 1;
                } else if (a.comment !== null && b.comment === null) {
                    return -1;
                }
            }else{
                return 0
            }
        }
        if(orderBy === "flags"){
            const aHasFlag = "flag" in a ;
            const bHasFlag = "flag" in b ;

            if (aHasFlag && !bHasFlag) {
                return -1;
            } else if (!aHasFlag && bHasFlag) {
                return 1;
            }if (aHasFlag && bHasFlag) {
                return (Boolean(a.flag) === Boolean(b.flag))? 0 : Boolean(a.flag) ? -1 : 1
            } else{
                return 0
            }
        }
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;

    }

    const ascendingComparator = (a, b, orderBy) => {
        if(orderBy === "score"){
            if(b.score === a.score){
                return 0
            }else if (!b.score){
                return -1
            }else if (!a.score){
                return 1
            }else{
                return a.score - b.score
            }
        }
        if(orderBy === "com") {
            const aHasComment = "comment" in a ;
            const bHasComment = "comment" in b ;

            if (aHasComment && !bHasComment) {
                return -1;
            } else if (!aHasComment && bHasComment) {
                return 1;
            }
            if (aHasComment && bHasComment) {
                if (a.comment === null && b.comment !== null) {
                    return -1;
                } else if (a.comment !== null && b.comment === null) {
                    return 1;
                }
            }else{
                return 0
            }
        }
        if(orderBy === "flags"){
            const aHasFlag = "flag" in a ;
            const bHasFlag = "flag" in b ;

            if (aHasFlag && !bHasFlag) {
                return -1;
            } else if (!aHasFlag && bHasFlag) {
                return 1;
            }
            if (aHasFlag && bHasFlag) {
                return (Boolean(a.flag) === Boolean(b.flag))? 0 : Boolean(a.flag) ? 1 : -1
            }else{
                return 0
            }
        }
        if (b[orderBy] < a[orderBy]) {
            return 1;
        }
        if (b[orderBy] > a[orderBy]) {
            return -1;
        }
        return 0;
    }

    const handleOpenFilters = () => {
        setOpenFilters(!openFilters)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setRowsPerPageMobile(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChange = event => {
        setSearchValue(event.target.value);
        if(page > 0){
            setPage(0);
        }
        filterBySearch(history, event.target.value);
    };
    const filterByCategory = (event) => {
        const id = event.target.value;
        const name = event.target.name;
        if(name === "category-filter"){
            setSelectedCategory(id);
            setSelectedTag(-1);
        }else if(name === "tag-filter"){
            setSelectedTag(id);
        }else if (name === "action-filter"){
            setSelectedAction(id)
        }
        if((id < 0 && name === "category-filter" && selectedAction <0) || (id<0 && name === "tag-filter" && selectedCategory < 0 && selectedAction <0) || (id<0 && name === "action-filter" && selectedCategory < 0 && selectedTag <0) ){
            setHistoryByCategory(history);
        }else{
            let array = history;
            if(name === "category-filter"){
                if(selectedAction >=0 && id>= 0){
                    if (selectedAction === 2){
                        setSelectedAction(-1)
                        array = array.filter((el) =>el.category && el.category.id === id);
                    }else{
                        array = array.filter((el) =>el.category &&  el.category.id === id && el.id_action === selectedAction);
                    }

                }else if(selectedAction >=0 && id< 0) {
                    array = array.filter((el) => el.id_action === selectedAction);
                }else{
                    array = array.filter((el) => el.category && el.category.id === id);
                }

            }else if(name === "tag-filter"){
                if((selectedAction >=0 || selectedCategory >= 0) && id>= 0){
                    if(selectedAction >= 0 && selectedCategory < 0){
                        if (selectedAction === 2){
                            setSelectedAction(-1)
                            array = array.filter((el) =>  el.id_tag === id);
                        }else{
                            array = array.filter((el) =>  el.id_tag === id && el.id_action === selectedAction);
                        }
                    }else if (selectedCategory >= 0 && selectedAction < 0){
                        array = array.filter((el) => el.category &&  el.id_tag === id && el.category.id === selectedCategory);
                    }else if (selectedAction >=0 && selectedCategory >= 0){
                        array = array.filter((el) =>el.category &&  el.id_tag === id && el.id_action === selectedAction &&  el.category.id === selectedCategory);
                    }
                }else if((selectedAction >=0 || selectedCategory >= 0) && id < 0){
                    if(selectedAction >= 0 && selectedCategory < 0){
                        array = array.filter((el) => el.id_action === selectedAction);
                    }else if (selectedCategory >= 0 && selectedAction < 0){
                        array = array.filter((el) =>  el.category && el.category.id === selectedCategory);
                    }else if (selectedAction >=0 && selectedCategory >= 0){
                        array = array.filter((el) => el.category && el.id_action === selectedAction &&  el.category.id === selectedCategory);
                    }
                }else{
                    array = array.filter((el) => el.id_tag === id);
                }
            }else if (name === "action-filter"){
                if((selectedTag >=0 || selectedCategory >= 0) && id>= 0){
                    if(selectedTag >= 0 && selectedCategory < 0){
                        array = array.filter((el) =>  el.id_action === id && el.id_tag === selectedTag);
                    }else if (selectedCategory >= 0 && selectedTag < 0){
                        array = array.filter((el) =>el.category &&  el.id_action === id && el.category.id === selectedCategory);
                    }else if (selectedTag >=0 && selectedCategory >= 0){
                        array = array.filter((el) => el.category && el.id_action === id && el.id_tag === selectedTag &&  el.category.id === selectedCategory);
                    }
                }else if((selectedTag >=0 || selectedCategory >= 0) && id < 0){
                    if(selectedTag >= 0 && selectedCategory < 0){
                        array = array.filter((el) => el.id_tag === selectedTag);
                    }else if (selectedCategory >= 0 && selectedTag < 0){
                        array = array.filter((el) => el.category && el.category.id === selectedCategory);
                    }else if (selectedTag >=0 && selectedCategory >= 0){
                        array = array.filter((el) =>el.category && el.id_tag === selectedTag &&  el.category.id === selectedCategory);
                    }
                }else{
                    array = array.filter((el) => el.id_action === id);
                }
            }
            setHistoryByCategory(array);
            setPage(0);
        }

    };
    const fetchMainCategories = () => {
        HttpApi.getV2("/categories/getMainCategories").then(response => {
            if(response && response.data){
                response.data.splice(0,0, {id:-1, name:_.capitalize(t("core.all"))});
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error)
        })
    };

    const fetchTags = () => {
        HttpApi.getV2("/tags/getAll").then(response => {
            if(response && response.data){
                response.data.splice(0,0, {id:-1, name:_.capitalize(t("core.allBis")),id_category : -1});
                setTags(response.data);
            }else{
                setTags([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {
        setHistoryByCategory(history)
        setSelectedAction(-1)
        setSelectedCategory(-1)
        setSelectedTag(-1)
    }, [history]);


    useEffect(() => {
        setHistoryByCategory(history)
        fetchMainCategories()
        fetchTags()
    }, []);


    return loading ?
        <LoadingView/>
        :
        bp.isTabletLandscapeOrLess() ?
            history && history.length>0
                ?
                <div className={"w-full"}>

                    <div className={"flex flex-row justify-center items-top px-30"}>
                        <div className={"flex flex-col w-full"}>
                            <EbloomSearchBar handleChange={handleChange} searchValue={searchValue} size={"small"}  color={"blue"}/>
                            {
                                openFilters && <div>
                                    <div className={"my-15"}>
                                        <ActionList handleChange={filterByCategory} label={"Actions"} value={selectedAction} color={"purple"} id_cat={selectedCategory} id_tag={selectedTag}/>
                                    </div>
                                    <div className={"mb-15"}>
                                        <CategoryList isModelCustomEnable={isModelCustomEnable} handleChange={filterByCategory} categories={categories} value={selectedCategory} label={_.capitalize(t("core.category.categories"))} id_action={selectedAction}/>
                                    </div>
                                    <div className={""}>
                                        <TagList isModelCustomEnable={isModelCustomEnable} tags={tags} value={selectedTag} id_category={selectedCategory} handleChange={filterByCategory} color={"blue"} id_action={selectedAction}/>
                                    </div>
                                </div>
                            }
                        </div>

                        <div onClick={handleOpenFilters}  className={"mx-10 w-32 h-32 cursor-pointer mt-5"}>
                            { openFilters ? <CloseIcon /> : <FilterIcon />}
                        </div>
                    </div>

                    <div className={"px-10 pb-30 divide-y divide-purple"}>
                        {
                            filterBySearch(historyByCategory,searchValue).slice(page * rowsPerPageMobile, page * rowsPerPageMobile + rowsPerPageMobile).map((value, index)=>{
                                return (
                                    <div key={index} className={"w-full mt-20"} >
                                        <div className={"w-full flex flex-col justify-center items-center pt-20"}>
                                            <div className={" w-full flex flex-row justify-between items-center"}>
                                                <div className={"flex flex-col justify-center items-center"}>
                                                    <div className={"w-full flex flex-row justify-start items-center"} >
                                                        {generateIcon(value)}
                                                        <p className={"text-logo-blue font-bold text-left ml-10"}>{moment(value.date).format("DD/MM/YY")}</p>
                                                        <div className={"ml-10"}>
                                                            {
                                                                !isProspect && value.id_company !== user.companyId &&
                                                                <Badge style={{backgroundColor: user.companyGroup.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill className={"text-white"}>{user.companyGroup.name}</Badge>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className={"my-5"}>
                                                        {value.category &&
                                                            <CategoryTag modelCustomEnable={isModelCustomEnable} category={value.category} width={bp.isTabletLandscapeOrLess() ? 180 : 200}/>
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row w-1/2"}>
                                                    {generateLogos(value)}
                                                </div>
                                            </div>
                                            <div className={"w-full"}>
                                                <p className={"w-full text-left"}>{value.survey_type === "poll" ? value.survey_title : value.text }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <TablePagination className={"mt-15"}
                                         rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                         component="div"
                                         labelRowsPerPage={_.capitalize(t("core.rowsPerPage"))}
                                         labelDisplayedRows={({ from, to, count }) => { return from +"-" + to + " " + t("core.of") + " " + count}}
                                         count={!searchValue ? historyByCategory.length : historyByCategory.filter((el) => (_.lowerCase(el.text).includes(searchValue) || _.lowerCase(el.survey_title).includes(searchValue) || _.lowerCase(el.category).includes(searchValue) || _.lowerCase(moment(el.date).format("YYYY/MM/DD")).includes(searchValue))).length
                                         }
                                         page={page}
                                         onPageChange={handleChangePage}
                                         rowsPerPage={rowsPerPageMobile}
                                         onRowsPerPageChange={handleChangeRowsPerPage}/>
                    </div>
                </div>
                :
                <div className={"w-full text-center mb-30"}>
                    <h1 className={"text-4xl"}>{_.upperFirst(t("employee.dashboard.noData"))}</h1>
                </div>

            :
        <div>
            {
                history && history.length > 0
                    ?
                    <div className={"w-full px-10 py-30 divide-y divide-contrast-purple"}>
                        <div className={"grid grid-cols-4 gap-10 mb-30 px-50"}>
                            <div className={""}>
                                <ActionList handleChange={filterByCategory} label={"Actions"} value={selectedAction} color={"purple"} id_cat={selectedCategory} id_tag={selectedTag}/>
                            </div>
                            <div className={""}>
                                <CategoryList isModelCustomEnable={isModelCustomEnable} handleChange={filterByCategory} categories={categories} value={selectedCategory} label={_.capitalize(t("core.category.categories"))} id_action={selectedAction}/>
                            </div>
                            <div className={" "}>
                                <TagList isModelCustomEnable={isModelCustomEnable} tags={tags} value={selectedTag} id_category={selectedCategory} handleChange={filterByCategory} color={"blue"} id_action={selectedAction}/>
                            </div>
                            <div className={""}>
                                <EbloomSearchBar handleChange={handleChange} searchValue={searchValue} size={"small"} className={"w-full"} color={"blue"}/>
                            </div>
                        </div>
                        <div>
                            <div className={"grid grid-cols-12 gap-10 mt-20"}>
                                <div onClick={handleChangeOrderBy("date")} className={"col-start-2 col-end-3 w-full flex justify-center items-center flex-row cursor-pointer"}>
                                    <span className={"text-contrast-purple font-semibold"}>Date</span>
                                    {generateSortIcon("date")}
                                </div>
                                <div onClick={handleChangeOrderBy("score")} className={"col-start-10 col-end-11 w-full text-center flex justify-center items-center flex-row cursor-pointer"}>
                                    <p className={"text-contrast-purple font-semibold"}>Score</p>
                                    {generateSortIcon("score")}
                                </div>
                                <div onClick={handleChangeOrderBy("com")} className={"col-start-11 col-end-12 w-full text-center flex justify-center items-center flex-row cursor-pointer"}>
                                    <p className={"text-contrast-purple font-semibold"}>Com.</p>
                                    {generateSortIcon("com")}
                                </div>
                                <div onClick={handleChangeOrderBy("flags")} className={"col-start-12 col-end-13 w-full text-center flex justify-center items-center flex-row cursor-pointer"}>
                                    <p className={"text-contrast-purple font-semibold"}>Flags</p>
                                    {generateSortIcon("flags")}
                                </div>
                            </div>
                            {
                                filterBySearch(historyByCategory,searchValue).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index)=>{
                                    return (
                                        <div key={index} className={"grid grid-cols-12 gap-10 mt-20 ebloom-normal-text"} >
                                            <div className={"col-start-1 col-end-2 w-full flex justify-center items-center"}>
                                                {generateIcon(value)}
                                            </div>
                                            <div className={"col-start-2 col-end-3 w-full flex justify-center items-center"}>
                                                <p className={"text-logo-blue font-bold text-left"}>{moment(value.date).format("DD/MM/YY")}</p>
                                            </div>
                                            <div className={"col-start-3 col-end-6 w-full flex justify-start items-center"}>
                                                <EbloomTooltip text={value.survey_type === "poll" ? value.survey_title : value.text }>
                                                    <p className={"truncate text-left"}>{value.survey_type === "poll" ? value.survey_title : value.text }</p>
                                                </EbloomTooltip>

                                            </div>
                                            <div className={"col-start-6 col-end-7 w-full flex justify-center items-center"}>
                                                {
                                                    !isProspect && value.id_company !== user.companyId &&
                                                    <Badge style={{backgroundColor: user.companyGroup?.colors?.primary ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill className={"text-white"}>{user.companyGroup.name}</Badge>
                                                }
                                            </div>
                                            <div
                                                className={"col-start-7 col-end-10 w-full flex justify-center items-center"}>
                                                {value.category &&
                                                    <CategoryTag modelCustomEnable={isModelCustomEnable} category={value.category} width={bp.isTabletLandscapeOrLess() ? 180 : 200}/>
                                                }
                                            </div>
                                            {generateLogos(value)}
                                        </div>
                                    )
                                })
                            }
                            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                             component="div"
                                             labelRowsPerPage={_.capitalize(t("core.rowsPerPage"))}
                                             labelDisplayedRows={({ from, to, count }) => { return from +"-" + to + " " + t("core.of") + " " + count}}
                                             count={!searchValue ? historyByCategory.length : historyByCategory.filter((el) => (_.lowerCase(el.text).includes(searchValue) || _.lowerCase(el.survey_title).includes(searchValue) || _.lowerCase(el.category).includes(searchValue) || _.lowerCase(moment(el.date).format("YYYY/MM/DD")).includes(searchValue))).length
                                             }
                                             page={page}
                                             onPageChange={handleChangePage}
                                             rowsPerPage={rowsPerPage}
                                             onRowsPerPageChange={handleChangeRowsPerPage}/>
                        </div>

                    </div>
                    :
                    <div className={"w-full text-center mb-30"}>
                        <h1 className={"text-4xl"}>{_.upperFirst(t("employee.dashboard.noData"))}</h1>
                    </div>
            }
        </div>


};

MyActivity.propTypes = {
    history : PropTypes.array.isRequired,
    loading : PropTypes.bool.isRequired,
    isModelCustomEnable : PropTypes.bool,
};

MyActivity.defaultProps = {
    isModelCustomEnable : false
}

export default MyActivity;
