import React, { useEffect, useState } from 'react';
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import LogModel from "../../../models/Log";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import EbloomSelectV2 from '../../../../Core/Modules/Input/EbloomSelectV2';
import { MenuItem } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang/lib';
import EbloomModal from '../../../../Core/Modules/Modal/EbloomModal';


const Log = (props) => {
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [log, setLog] = useState(null);
    const [tablePage, setTablePage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [status, setStatus] = useState('all');
    const [showModal, setShowModal] = useState(false);

    const t = useTranslation();

    const fetchLogs = (limitedAt) => {
        setLoading(true);
        let url = LogModel.crud.getAll;
        if(typeof limitedAt === 'number'){
            url += "/" + limitedAt;
        }else{
            url += "/10000";
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setLogs(response.data);
            }else{
                setLogs([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoading(false));
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleChangeLimit = () => {
        fetchLogs(limit === 100 ? "all" : 100);
        setLimit(limit === 100 ? "all" : 100);
    }

    const handleShowModal = (item) => {
        setLog(item);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setLog(null);
    }

    useEffect(() => {
        fetchLogs(100);
    }, []);

    return(
      <>
          <CardDefault title={"Logs"} buttonText={limit === 100 ? "Get All" : "Get 100"} handleRightButton={handleChangeLimit} >
              {loading ?
                <LoadingView/>
                :
                <div>
                    <div className={"w-full flex justify-end items-center"}>
                        <div className={"mx-5"}>Status code: </div>
                        <EbloomSelectV2 onChange={(event) => {setStatus(event.target.value)}} value={status}>
                            <MenuItem value='all'>{_.upperFirst(t("core.allBis"))}</MenuItem>
                            <MenuItem value={200}>200 - Success</MenuItem>
                            <MenuItem value={300}>300</MenuItem>
                            <MenuItem value={400}>400 - Bad Request, Forbidden, Unauthorized</MenuItem>
                            <MenuItem value={500}>500 - Internal Server Error</MenuItem>
                        </EbloomSelectV2>
                    </div>
                    <TableViewV2 items={logs.filter(el => status === 'all' || (el.code >= status && el.code < status+100))} model={LogModel} showActions={false} tablePage={tablePage} handleTablePage={handleTablePage} handleRowClick={(item) => event => {handleShowModal(item)}}/>
                </div>
              }
          </CardDefault>
          <EbloomModal handleClose={handleCloseModal} show={showModal} size='lg'>
              <p className={"text-contrast-purple font-semibold"}>{log?.message}</p>
              <p className={"text-contrast-purple"}>{log?.error}</p>
          </EbloomModal>
      </>

    )
}

export default Log;