import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as StarIcon} from "../../symbols/webloom/white/star-white.svg";
import {ReactComponent as HeartIcon} from "../../symbols/webloom/white/heart-3-parts.svg";

const EbloomStarRatingBarChart = (props) => {

    const [totalWidth, setTotalWidth] = useState(1);

    const {avgAnswers, answers, array, isHeart} = props;

    const ref = useRef(null);

    const getBarHeight = (index) => {
        let item = answers.filter(el => el.score === index).length;
        const percentAnswer = answers.length > 0 ? item/answers.length : 0;
        return 250*percentAnswer;
    }

    const getOffSet = () => {
        let elementWidth = array.length > 0 ? ((totalWidth-(array.length*10))/array.length) : totalWidth;
        return (avgAnswers*elementWidth) + ((Math.floor(avgAnswers)-1)*10) - (elementWidth/2) - 5;
    }

    const getOpacity = (index) => {
        const part = array.length > 0 ? 1/array.length : 0;
        return ((index+1) * part);
    }

    useEffect(() => {
        if(ref && ref.current){
            setTotalWidth(ref.current.offsetWidth)
        }
    }, [ref])

    return(
        <div className={"flex items-baseline"}>
            <div className={"w-2/3"}>
                <div className={"flex justify-between"}>
                    {
                        array.map((i, index) => (
                            <div ref={ref} key={index} className={"relative h-250 w-full ml-5 mr-5"}>
                                <div className={"absolute w-full"} style={{bottom:0}}>
                                    <div className={"ease-linear duration-500 bg-contrast-blue"} style={{height:getBarHeight(index+1), opacity:getOpacity(index+1)}}/>
                                    <div className={"w-full text-center text-contrast-purple absolute"} style={{bottom:getBarHeight(index+1)}}>{answers.filter(el => el.score === index+1).length}</div>
                                </div>

                                <div className={"absolute w-full"} style={{bottom:-25}}>
                                    <div className={"text-center text-contrast-purple font-semibold"}>{index+1}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={"w-full mt-40"}>
                    <div ref={ref} className={"w-full h-10 bg-grey/[0.7] rounded-lg"}>
                        <div className={"h-20 w-20 rounded-xl bg-contrast-purple relative bottom-5 ease-linear duration-500"} style={{left:getOffSet()}}/>
                    </div>
                    <div className={"relative top-10 ease-linear duration-500"} style={{left:getOffSet()-7}}>
                        {
                            isHeart ?
                                <HeartIcon className={"h-35 w-35 contrast-purple-icon"}/>
                                :
                                <StarIcon className={"h-35 w-35 contrast-purple-icon"}/>
                        }
                    </div>
                </div>
            </div>
            <div className={"w-1/3 ml-50"}>
                <div className={"ebloom-xtra-large-text text-contrast-purple font-semibold"}>{avgAnswers + "/" + array.length}</div>
            </div>
        </div>
    )
}

EbloomStarRatingBarChart.propTypes = {
    array:PropTypes.array.isRequired,
    answers:PropTypes.array.isRequired,
    avgAnswers:PropTypes.number.isRequired
}
export default EbloomStarRatingBarChart;