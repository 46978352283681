import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import NotificationEditor from "./NotificationEditor";



const NotificationEditorPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <React.Fragment>
                        <NotificationEditor companyToEdit={companyToEdit}/>
                    </React.Fragment>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default NotificationEditorPage;