import React, {useContext, useEffect} from "react";
import {ProductTourContext} from "../../../Context/ProductTourContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TourType} from "../../../Global";
import HttpApi from "../../../../httpApi";
import {createClickAndBloomStep} from "./createClickAndBloomStep";
import EbloomTourTip from "../../CustomToolTip/EbloomTourTip";

const CreateClickAndBloomTour = (props) => {
    const {
        updateStep,
        stepTour,
        stepCreateClickAndBloom, setStepCreateClickAndBloom,
        tourStarted,
        setTourStarted,
        tourActivated,
        styleEbloom,
        fetchData,
        icon,
        updateHandler,
        validateOutcome
    } = useContext(ProductTourContext)

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || index >= createClickAndBloomStep.length) {
            updateTourStatus()
        }else if([EVENTS.STEP_AFTER,EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const next = index + (action === ACTIONS.PREV ? -1 : 1);
            if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action)) {
                updateStep(TourType.CREATE_CLICK_AND_BLOOM,next)
            }
        }
    }

    const handleTour = () => {
        setTourStarted(true)
    }
    const updateTourStatus = () => {
        setTourStarted(false)

        const tour = {
            id : stepTour[TourType.CREATE_CLICK_AND_BLOOM].id,
            current_step : stepTour[TourType.CREATE_CLICK_AND_BLOOM].step >= createClickAndBloomStep.length - 1 ? 0 : stepTour[TourType.CREATE_CLICK_AND_BLOOM].step,
            type: TourType.CREATE_CLICK_AND_BLOOM,
            finished : stepTour[TourType.CREATE_CLICK_AND_BLOOM].outcome
        }
        if(stepCreateClickAndBloom >= createClickAndBloomStep.length){
            validateOutcome(TourType.CREATE_CLICK_AND_BLOOM)
            setStepCreateClickAndBloom(0)
        }else{
            HttpApi.post('/productTour/updateTour',tour).then(()=>{})
        }
    }

    const iconRinging = () => {
        if(icon.current && stepTour[TourType.CREATE_CLICK_AND_BLOOM]){
            if(icon.current.classList.contains('ebloom-ring')){
                icon.current.classList.remove('ebloom-ring')
            }
            if (!stepTour[TourType.CREATE_CLICK_AND_BLOOM].outcome){
                icon.current.classList.add('ebloom-ring')
            }
        }
    }

    useEffect(() => {
        iconRinging()
    }, [stepCreateClickAndBloom]);


    useEffect(() => {
        fetchData();
        if(tourActivated){
            if(icon && icon.current){
                iconRinging()
            }
        }
        updateHandler(TourType.CREATE_CLICK_AND_BLOOM,handleTour)

        return () => {
            setTourStarted(false)
        }
    }, []);



    return(
        <>
            <ReactJoyride
                steps={createClickAndBloomStep}
                continuous
                run={tourStarted}
                stepIndex={stepCreateClickAndBloom}
                callback={handleJoyrideCallback}
                showProgress
                spotlightClicks={true}
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={false}
                disableScrollParentFix={false}
                hideCloseButton={true}
                debug={false}
                styles={styleEbloom}
                tooltipComponent={EbloomTourTip}
            />

        </>
    )
}

export default CreateClickAndBloomTour