import React, {useEffect, useState} from "react";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import HttpApi from "../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../Utils/Notification";
import {useNavigate, useParams} from "react-router-dom";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import _, {upperFirst} from "lodash";
import TableViewV2 from "../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import UserModel from "../../../../models/User";
import ListView from "../../../Core/Modules/Views-Tables-Lists/ListView";
import LoadingView from "../../../Core/Layouts/LoadingView";
import EbloomJumbotron from "../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {useTranslation} from "react-multi-lang/lib";

const Team = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingChange, setLoadingChange] = useState(false);
    const [team, setTeam] = useState({id:null, name:""});
    const [title, setTitle] = useState("");
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersForTeam, setUsersForTeam] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [userToRemove, setUserToRemove] = useState({id:null, email:"", name:null});
    const [min, setMin] = useState(8);
    const [tablePage, setTablePage] = useState(0);

    const navigate = useNavigate();
    const {id} = useParams();
    const t = useTranslation();

    const fetchTeam = () => {
        const url = "/teams/getById/" + id;
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setTeam(response.data);
                setTitle(response.data.name);
            }else{
                setTeam({id:null, name:""});
                setTitle("");
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const fetchUsers = () => {
        const url = "/users/getNotInAnyTeam";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setUsers(response.data);
            }else{
                setUsers([]);
            }
        })
    };

    const fetchUserForTeam = () => {
        setLoading(true);
        const url = "/users/getForTeam/" + id;
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setUsersForTeam(response.data);
            }else{
                setUsersForTeam([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoading(false));
    };

    const fetchMinTeamMember = () => {
        const url = props.companyToEdit ? "/setting/backoffice/minTeamMember/getForCompany/" + props.companyToEdit.id : "/setting/minTeamMember/getForCompany";
        HttpApi.get(url).then(response => {
            if(response && response.data){
                setMin(response.data.value);
            }else{
                setMin(5);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleAdd = (item) => event => {
        if(!id || !item.id){
            createErrorNotification("Error !", "Invalid payload request")
        }else{
            if(!loadingChange){
                setLoadingChange(true);
                const data = {id_team:parseInt(id), id_user: parseInt(item.id)};
                HttpApi.postV2("/teams/insertUser", data).then(() => {
                    setUsers(prevState => {
                        let users =  [...prevState];
                        users.splice(users.indexOf(users.find(el => el.id === item.id)), 1);
                        return users;

                    })
                    setUsersForTeam(prevState => {
                        let usersForTeam = [...prevState];
                        usersForTeam.push(item);
                        return usersForTeam;
                    })
                    fetchTeam();
                    notifySuccess(item.email + " successfully added to team " + team.name, 1000);
                    setLoadingChange(false);
                }).catch(error => {
                    setLoadingChange(false);
                    notifyError(error);
                })
            }

        }
    };

    const handleRemove = () => {
        if(!userToRemove || !userToRemove.id){
            createErrorNotification("Error !", "Invalid payload request");
        }else{
            if(!loadingChange){
                setLoadingChange(true);
                const data = {id_team:parseInt(id), id_user:parseInt(userToRemove.id)};
                HttpApi.postV2("/teams/removeUser", data).then(() => {
                    setUsers(prevState => {
                        let users = [...prevState];
                        users.push(userToRemove)
                        return users;
                    })
                    setUsersForTeam(prevState => {
                        let usersForTeam = [...prevState];
                        usersForTeam.splice(usersForTeam.indexOf(usersForTeam.find(el => el.id === userToRemove.id)), 1);
                        return usersForTeam;
                    })
                    setUserToRemove({id:null, email:"", name:null});
                    setShowModal(false);
                    fetchTeam();
                    notifySuccess("User successfully removed from team " + team.name, 1000);
                    setLoadingChange(false);
                }).catch(error => {
                    setLoadingChange(false);
                    notifyError(error);
                })
            }

        }
    };

    const handleClose = () => {
        setShowModal(false);
        setUserToRemove({id:null, email:"", name:null});
    };

    const handleOpen = (user) => event => {
        setShowModal(true);
        setUserToRemove(user);
    };

    const handleLeftButton = () => {
        const url = props.companyToEdit ? "/backoffice/teams" : "/admin/teams";
        navigate(url);
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    useEffect(() => {
        fetchTeam();
        fetchUsers();
        fetchUserForTeam();
        fetchMinTeamMember();
    }, [props]);


    const toggleDrawer = (open, toEdit) => event => {
        event.preventDefault();
        setSideFormOpen(open);
    };


    const EmployeeModel = _.cloneDeep(UserModel);
    EmployeeModel.lang.table.show = false;
    EmployeeModel.company_name.table.show = false;
    EmployeeModel.created_at.table.show = false;
    EmployeeModel.roles.table.show = false;
    EmployeeModel.crud.delete = "/users/deleteEmployee";

    return(
        <>
            <CardDefault showButton={!props.companyToEdit} showBadge={true} badgeContent={team.isValid === "rule1" ? _.upperFirst(t("admin.rule1", {min:min.toString()})) : _.upperFirst(t("admin." + team.isValid))} badgeVariant={team.isValid === "valid" ? "strong-green" : "strong-red"} handleLeftButton={handleLeftButton} showLeftButton={true} leftButtonText={_.capitalize(t("core.back"))} leftButtonVariant={"secondary"} title={title} titlePosition={"center"} buttonText={"core.add"} handleRightButton={toggleDrawer(true)}>
                {
                    loading ?
                        <LoadingView/>
                        :

                        (usersForTeam && usersForTeam.length > 0) ?

                            <TableViewV2 items={usersForTeam} model={EmployeeModel} showActions={!props.companyToEdit} showEdit={false} showDelete={false} showDetail={true} handleView={handleOpen} handleObject typeDetail={"remove"} tablePage={tablePage} handleTablePage={handleTablePage}/>

                            :
                            <EbloomJumbotron title={_.upperFirst(t("admin.teamGetStarted", {name:team.name}))} text={_.capitalize(t("admin.teamHelper"))} handleAction={toggleDrawer(true)}/>


                }

            </CardDefault>
            <SideForm formTitle={_.capitalize(t("core.add"))} sideFormOpen={sideFormOpen} toggleDrawer={toggleDrawer} showButtons={false}>
                <ListView model={UserModel} items={users} handleAction={handleAdd}/>
            </SideForm>
            <ConfirmModal handleClose={handleClose} show={showModal} title={upperFirst(t("core.removeParam", {param:userToRemove.name || userToRemove.email}))} handleChange={handleRemove} buttonRightText={_.capitalize(t("core.confirm"))} buttonLeftText={_.capitalize(t("core.cancel"))}>
                <p>{_.upperFirst(t("admin.teamRemoveHelper", {param:userToRemove.name || userToRemove.email, param2:title}))}</p>
            </ConfirmModal>

        </>
    )
}

export default Team;