import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import {ReactComponent as AlertIcon} from "../../../../../Core/symbols/webloom/white/finguer-up-white.svg";
import {ReactComponent as ValidateIcon} from "../../../../../Core/symbols/webloom/white/validate-white.svg";
import {ReactComponent as ForbiddenIcon} from "../../../../../Core/symbols/webloom/white/forbidden-white.svg";
import {Badge, Button} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import {SurveyStatus, SurveyUsersStatus} from "../../../../../Admin/Pages/WeBloom/Polls/Modules/Types";
import {getLanguage} from "react-multi-lang";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import EbloomNotificationBadge from "../../../../../Core/Modules/Notifications/EbloomNotificationBadge";
import {useUserData} from "../../../../../../hooks/userHook";

const AlertCard = (props) => {

    //state
    const {survey,isProspect,setShowModal} = props;

    const isRunning = survey.status && survey.status === SurveyStatus.RUNNING && survey.lastAnswer === null;

    const hasAnswered = survey.status && survey.status === SurveyStatus.RUNNING && survey.lastAnswer !== null;

    const [nbUnreadAnswers, setNbUnreadAnswers] = useState(0);

    //hooks
    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()
    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {
        let unreadAnswers = survey.question.answers?.map(el => {return el.conversations?.filter(el => el.isRead === false).length})
        if(unreadAnswers){
            const sum = unreadAnswers.reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            },0);
            setNbUnreadAnswers(sum);
        }
    }, [survey])

    return (
        <div className={(isRunning || hasAnswered) ? survey.userSurveyStatus === SurveyUsersStatus.UNREAD ? "bg-contrast-blue/[0.3] rounded-lg px-15 py-11 pointer" : "bg-contrast-blue/[0.1] rounded-lg px-15 py-11 pointer" : "bg-bg-grey/[0.7] rounded-lg px-15 py-11"} onClick={isProspect ? ()=> setShowModal(true) : (isRunning || hasAnswered) ? () => {navigate("/webloom/alert/answer/" + survey.id, {state:survey})} : () => {}}>
            <div className={"flex justify-between items-center mb-20"}>
                <div className={"flex justify-start"}><AlertIcon
                    className={(isRunning || hasAnswered) ? "w-30 h-30 contrast-purple-icon mr-5" : "w-30 h-30 grey-icon mr-5"}/>
                    <div
                        className={(isRunning || hasAnswered) ? "ebloom-title font-semibold text-contrast-purple ebloom-very-large-text" : "ebloom-title font-semibold text-grey ebloom-very-large-text"}>{_.upperFirst(t("webloom.alert"))}</div>
                </div>
                {
                    (survey.end_date || survey.lastAnswer) &&
                    <div className={"flex items-center"}>
                        {
                            nbUnreadAnswers > 0 &&
                            <div className={"flex items-center"}>
                                <EbloomNotificationBadge number={nbUnreadAnswers} showNumber={false} showSonar={true}>
                                    <div
                                        className={"text-strong-red"}>{_.upperFirst(t("webloom.nbUnreadAnswers", {param: nbUnreadAnswers.toString()}))}</div>
                                </EbloomNotificationBadge>
                            </div>
                        }
                    </div>
                }

            </div>

            <div
                className={(isRunning || hasAnswered) ? "text-purple ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10" : "text-grey ebloom-text ebloom-very-large-text font-semibold mb-10 mt-10"}>
                {_.upperFirst(t("webloom.alertText"))}
            </div>
            {
                survey.image_url &&
                <FileDisplay displayImg urlParams={survey.image_url} imgHeight={300}/>
            }
            {
                (isRunning || hasAnswered) &&
                <div className={"flex justify-end items-baseline"}>
                    {
                        survey.lastAnswer !== null &&
                        <div className={"ebloom-link-purple mr-5"} onClick={() => {}}>{_.upperFirst(t("webloom.seeAlert"))}</div>
                    }
                    <Button className={"ebloom-btn-purple ml-5"} size={"sm"} onClick={isProspect ? ()=> setShowModal(true) : () => {navigate("/webloom/alert/answer/" + survey.id, {state:survey})}}>{_.toUpper(t("webloom.signalAlert"))}</Button>
                </div>
            }

        </div>
    )
}

AlertCard.propTypes = {
    survey:PropTypes.object.isRequired,
    setShowModal : PropTypes.func.isRequired,
    isProspect : PropTypes.bool.isRequired
}

AlertCard.defaultProps = {}
export default AlertCard;