import React from "react"
import DisplayAdminView from "../../../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import WeBloomResultAlert from "./WeBloomResultAlert";

const WeBloomResultAlertPage = (props) => {

    return (
        <DisplayAdminView pageName={"Webloom"} menu={"noMenu"} selectedIndex={10} {...props}>
            {
                () => (
                    <Container>
                        <WeBloomResultAlert {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )

}

export default WeBloomResultAlertPage;