import React, {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../../httpApi";
import fileSaver from "file-saver/dist/FileSaver";
import {notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import {SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import PollFrame from "../../Polls/Modules/PollFrame";
import {Col, ListGroup, Row} from "react-bootstrap";
import _ from "lodash";
import moment from "moment/moment";
import BubbleChatList from "../../Polls/Results/Modules/BubbleChatList";
import {Limit, replaceUrlsWithLinks} from "../../../../../../Utils/Global";
import EbloomPopover from "../../../../../Core/Modules/Popovers-Tooltips/EbloomPopover";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {selectBubbleIcon} from "../../../Categories/Assets/CategoriesAssets";
import {useUserData} from "../../../../../../hooks/userHook";

const WeBloomResultIdeabox = () => {

    //state
    const [survey, setSurvey] = useState(null)
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [teamToDisplay, setTeamToDisplay] = useState(0);
    const [entity, setEntity] = useState(null);
    const [isGroup, setIsGroup] = useState(false);

    //hooks
    const t = useTranslation();
    const location = useLocation();
    const userData = useUserData();
    const params = useParams()

    const isProspect = userData.module === "prospect"
    //getters

    //posters
    const exportSurvey = () => {
        setLoadingExcel(true);
        let url =  "/webloom/export/" + survey.id + "/" + teamToDisplay
        if(entity){
            if (isGroup){
                url = "/webloom/group/export/" + survey.id + "/" + entity + "/"+ teamToDisplay
            }else{
                url = "/webloom/entities/export/" + survey.id + "/" + entity + "/"+ teamToDisplay
            }
        }
        HttpApi.getWithResponseTypeV2(url).then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'webloom-survey.xlsx');
            notifySuccess(_.upperFirst(t("webloom.notification.ideaboxExported")));
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingExcel(false);
        });
    }

    //handlers

    const displayDate = () => {
        let start = survey.start_date ? moment(survey.start_date).format("DD/MM/YY HH:mm") : survey.created_at ? moment(survey.created_at).format("DD/MM/YY HH:mm") : moment().format("DD/MM/YY HH:mm");
        let end = survey.end_date ? moment(survey.end_date).format("DD/MM/YY HH:mm") : _.upperFirst(t("webloom.noEndDefined"));
        return start + " - " + end;
    }

    const handleMarkAsRead = (id_answer) => {
        setSurvey(prevState => {
            let survey = {...prevState};
            let answer  = survey.questions[0].answers.find(el => el.id === id_answer);
            answer.isRead = true;
            return survey;
        })
    }

    const anonymityRestriction = !survey || !survey.questions || !survey.questions[0] || !survey.questions[0].answers || (survey.is_anonymous && survey.questions[0].answers.length<Limit.ANONYMITY);

    const noData = !survey || !survey.questions || !survey.questions[0] || !survey.questions[0].answers || survey.questions[0].answers.length<1;

    const handleTeamToDisplay = (event) => {
        setTeamToDisplay(event.target.value)
    }

    //useEffect

    useEffect(() => {
        if(location && location.state){
            setSurvey(location.state);
        }
    }, [])

    useEffect(() => {

        let idEntity = null
        let isGroupValue = false

        if(params.idGroup){
            idEntity = parseInt(params.idGroup);
            isGroupValue = idEntity === userData.companyGroup.id
            setIsGroup(isGroupValue)
            setEntity(idEntity)
        }
        if((survey && survey.id)){
            let url = "/webloom/getSurveyTeamResults/" + survey.id + "/" + teamToDisplay
            if(idEntity !== null){
                if (isGroupValue){
                    url = "/webloom/group/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/"+ teamToDisplay
                }else{
                    url = "/webloom/entities/getSurveyTeamResults/"+ survey.id + "/"+idEntity + "/"+ teamToDisplay
                }
            }
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setSurvey(response.data);
                }
            }).catch(error => notifyError(error));
        }
    }, [teamToDisplay]);

    return (
        <>
            <PollFrame status={SurveyStatus.DONE} anonymityRestriction={anonymityRestriction} noData={noData} handleExportExcel={exportSurvey} loadingExcel={loadingExcel} type={SurveyTypes.IDEABOX} teamToDisplay={teamToDisplay} handleTeamToDisplay={handleTeamToDisplay} entity={entity ? entity : -1} isGroup={isGroup}>
                {
                    survey &&
                    <div className={"cursorDefault"}>
                        <Row className={"mb-20"}>
                            <Col>
                                <div className={"grid grid-rows-1 grid-cols-3 items-center text-contrast-purple mb-20"}>
                                    <span>{displayDate()}</span>
                                    <div className={"justify-self-center"}>
                                        {
                                            isProspect ?
                                                <div>
                                                    <span style={{fontSize:18}} className={"text-line-break"}>#{survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) :isProspect ?  _.upperFirst(t("core.customized")) : survey.recipients}</span>
                                                </div>
                                                :
                                                <EbloomPopover  isCollab={true} triggerText={survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) : survey.recipients} placement={"bottom"} triggerVariant={"link"}>
                                                    {
                                                        () => (
                                                            <ListGroup variant={"flush"}
                                                                       style={{maxHeight: 250, overflowY: "auto", overflowX: "hidden"}}>
                                                                {
                                                                    survey.users.map((user, index) => (
                                                                        <ListGroup.Item key={index} style={{
                                                                            padding: 0,
                                                                            border: "none"
                                                                        }}>{user.email}</ListGroup.Item>
                                                                    ))
                                                                }
                                                            </ListGroup>
                                                        )
                                                    }
                                                </EbloomPopover>
                                        }
                                    </div>
                                    <div className={"justify-self-end"}><span>{survey.nbResponses}</span>{" " + t("webloom.participants") + " (max " + survey.users.length +")"}</div>
                                </div>
                                {
                                    survey.question.category &&
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col className={"text-center"}>
                                            <p className={"text-contrast-purple ebloom-text ebloom-large-text"}>
                                                {_.upperFirst(t("core.sb.sbHelper"))}
                                            </p>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col className={"text-center"}>
                                        <p className={"ebloom-text ebloom-very-large-text font-semibold text-purple"}>{survey.question.category ? <div className={"flex justify-center items-center"}><div className={"w-40 h-40 mr-5"}>{selectBubbleIcon(survey.question.icon,survey.question.color)}</div>{survey.question.text}</div> : replaceUrlsWithLinks(survey.question.text)}</p>
                                    </Col>
                                </Row>
                                {
                                    survey.image_url &&
                                    <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400}/>
                                }
                                {
                                    survey.attachment_url &&
                                    <FileDisplay className={"w-fit m-auto my-10"} urlParams={survey.attachment_url}/>
                                }
                            </Col>
                        </Row>
                        <hr className={"border-contrast-purple"}/>
                        <div className={"w-1/2 m-auto"}>
                            {
                                (anonymityRestriction || noData) ?
                                    <p className={"text-contrast-purple text-center my-10 font-semibold"}>{_.upperFirst(t(noData ? "core.collaborative.noAnswer" : "manager.dashboard.anonymousRestriction"))}</p>
                                    :
                                    <BubbleChatList answers={survey.questions[0].answers} handleMarkAsRead={((isProspect || entity !== null) && !isGroup) ? ()=> {} :handleMarkAsRead} consultOnly={((isProspect || entity !== null) && !isGroup)}/>

                            }
                        </div>
                    </div>
                }
            </PollFrame>
        </>
    )
}

WeBloomResultIdeabox.propTypes = {}

WeBloomResultIdeabox.defaultProps = {}
export default WeBloomResultIdeabox