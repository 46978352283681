import React, {useContext, useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import _ from "lodash";
import SideForm from "../../../Core/Modules/Views-Tables-Lists/SideForm";
import TextFieldCustom from "../../../Core/Modules/Input/TextFieldCustom";
import TeamModel from "../../../../models/Team";
import {Row} from "react-bootstrap";
import TableViewV2 from "../../../Core/Modules/Views-Tables-Lists/TableViewV2";

import {
    createErrorNotification,
    createSuccessfullyCreatedNotification,
    createSuccessfullyUpdatedNotification,
    notifyError, notifySuccess
} from "../../../../Utils/Notification";
import {useNavigate} from "react-router-dom";
import EbloomJumbotron from "../../../Core/Modules/Views-Tables-Lists/EbloomJumbotron";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import {useTranslation} from "react-multi-lang/lib";
import {Colors} from "../../../../Utils/Colors";
import * as fileSaver from "file-saver";
import LoadingView from "../../../Core/Layouts/LoadingView";
import ExportIcon from '@mui/icons-material/Launch';
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import {Divider, IconButton} from "@mui/material";
import ManageTeamsTour from "../../../../Utils/ProductTour/Tours/ManageTeams/ManageTeamsTour";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import {getUrl, TourType} from "../../../../Utils/Global";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ListView from "../../../Core/Modules/Views-Tables-Lists/ListView";
import UserModel from "../../../../models/User";
import moment from "moment";



const Teams = (props) => {
    const [loadingExport, setLoadingExport] = useState(false);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [sideFormUsersTeam, setSideFormUsersTeam] = useState(false);
    const [teams, setTeams] = useState([]);
    const [team, setTeam] = useState({id:null, name:"",users:[]});
    const [showModal, setShowModal] = useState(false);
    const [anchorTeam, setAnchorTeam] = useState(null);
    const [tablePage, setTablePage] = useState(0);
    const [min, setMin] = useState(8);
    const [userNotInTeam, setUserNotInTeam] = useState([]);


    const {companyToEdit} = props;

    const navigate = useNavigate();
    const t = useTranslation();

    const {updateStep,tourStarted, setContinuous,tourActivated,validateOutcome, setTourStarted} = useContext(ProductTourContext)


    const handleSave = () => {
        const url = team.id ? TeamModel.crud.update : TeamModel.crud.create;
        HttpApi.postV2(url, team).then((response) => {
            if(response && response.data && response.data.id){
                team.id ? createSuccessfullyUpdatedNotification("team " + team.name) : createSuccessfullyCreatedNotification("team " + team.name);
                if(tourStarted){
                    fetchTeams();
                    setContinuous(true)
                    setTimeout(()=>{
                        updateStep(TourType.MANAGE_TEAMS, 4)
                    },200)

                }else{
                    if(tourActivated){
                        validateOutcome(TourType.MANAGE_TEAMS)
                    }
                    navigate("/admin/team/" + response.data.id);
                }
            }else if(response && team.id){
                createSuccessfullyUpdatedNotification("team " + team.name);
                fetchTeams();
            }
            setSideFormOpen(false);
            setTeam({id:null, name:"",users:[]});
        }).catch(error => {
            notifyError(error);
        })
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setTeam(prevState => {
            let team = {...prevState};
            team[name] = value;
            return team;
        })
    };

    const handleClose = (event) => {
        setShowModal(false);
    };

    const handleOpen = (event) => {
        event.preventDefault();
        setShowModal(true);
    };

    const handleOpenMenu = (open, item) => event => {
        setAnchorTeam(event.currentTarget);
        setTeam(item);
    }

    const handleCloseMenu = () => {
        setAnchorTeam(null);
    }

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    const handleAdd = (item) => event => {
        event.preventDefault();
        const data = {id_team:team.id, id_user:item.id};
        if(team.users.filter(el => el.id === item.id).length === 0){
            HttpApi.postV2("/teams/insertUser", data).then(() => {
                setTeam(prevState => {
                    let team = {...prevState};
                    team.users.push(item);
                    return team;
                })
                setUserNotInTeam(prevState => {
                    let users =  [...prevState];
                    users.splice(users.indexOf(users.find(el => el.id === item.id)), 1);
                    return users;
                })
                fetchTeams()
                notifySuccess(item.displayName + " successfully added to team " + team.name, 1000);
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification("Error!" ,"User already added",1000);
        }

    };

    const handleRemove =  (item) => event => {
        event.preventDefault();
        const data = {id_team:team.id, id_user:item.id};
        if(team.users.filter(el => el.id === item.id).length > 0){
            HttpApi.postV2("/teams/removeUser", data).then(() => {
                setTeam(prevState => {
                    let team = {...prevState};
                    team.users = team.users.filter(el => el.id!== item.id);
                    return team;
                })
                setUserNotInTeam(prevState => {
                    let users = [...prevState];
                    users.push(item)
                    return users;
                })
                fetchTeams()
                notifySuccess(item.displayName + " successfully removed from team " + team.name, 1000);
            }).catch(error => {
                notifyError(error);
            })
        }else{
            createErrorNotification("Error!" ,"User already removed",1000);
        }
    };


    const fetchTeams = () => {
        const url = "/teams/getAll";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setTeams(response.data);
            }else{
                setTeams([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const fetchMinTemMember = () => {
        const url = getUrl("/setting/minTeamMember/getForCompany", companyToEdit);
        HttpApi.get(url).then(response => {
            if(response && response.data){
                setMin(response.data.value);
            }else{
                setMin(8);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchUserNotInTeam = () => {
        HttpApi.getV2("/users/getNotInAnyTeam").then(response => {
            if(response && response.data){
                setUserNotInTeam(response.data)
            }
        })
    }


    const handleRowClick = (item) => event => {
        const url = companyToEdit ? "/backoffice/team/" + item.id : "/admin/team/" + item.id;
        navigate(url);
    };

    const formTitle = team.id ? _.capitalize(t("core.renameParam", {param:t("admin.team")})) : _.capitalize(t("core.createParam", {param:t("admin.team")}));

    const toggleDrawer = (open, toEdit) => event => {
        event.preventDefault();

        if(toEdit){
            setTeam(toEdit);
            setAnchorTeam(null);
            setSideFormOpen(open);
        }else{
            if(open){
                setTimeout(() => {
                    updateStep(TourType.MANAGE_TEAMS, 3)
                }, 360)
            }else{
                setContinuous(true)
                document.querySelectorAll("[data-tour=row]").length >0 && tourStarted? updateStep(TourType.MANAGE_TEAMS, 4) : updateStep(TourType.MANAGE_TEAMS, 2);
            }
            setTeam({id:null, name:"",users: []});
            setSideFormOpen(open);
        }

    };

    const toggleDrawerUsers = (toEdit) => event => {
        event.preventDefault();
        if(toEdit && toEdit.id && !sideFormUsersTeam){
            fetchUserNotInTeam()
            setTeam(toEdit);

        }else if(team && sideFormUsersTeam){
            setTeam({id:null, name:"",users: []});
        }
        setSideFormUsersTeam(!sideFormUsersTeam);
    }

    const checkIfTeamHasCB = (idTeam) => {
        const team = teams.find(el => el.id === idTeam);
        if(!!team){
            let text = ''
            if(team.survey_teams.length > 0){
                const now = moment();
                const survey_teams = team.survey_teams;
                const survey_teams_future = survey_teams.filter(el => moment(el.survey_question.date).isAfter(now))
                if(survey_teams_future.length > 0){
                    for (let i = 0; i < survey_teams_future.length; i++) {
                        const survey = survey_teams_future[i].survey_question;
                        if(survey.survey_teams.length === 1){
                            text += _.upperFirst(t("admin.modalTextDeleteTeamPlanned"))+"\n"
                            break
                        }
                    }
                }
            }
            text += _.upperFirst(t("admin.modalTextDeleteTeamPast"))+"\n"
            return text
        }else{
            return null
        }

    }


    useEffect(() => {
        fetchTeams();
        fetchMinTemMember();
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        if(productTour && productTour === "teams"){
            setTourStarted(true);
        }
    }, [props.companyToEdit]);

    return(
       <>
           <ManageTeamsTour setSideForm={setSideFormOpen}/>
           <CardDefault title={_.capitalize(t("admin.teams"))} subtitle={_.upperFirst(t("admin.teamHelper"))} showButton={!props.companyToEdit} handleRightButton={toggleDrawer(true)} buttonText={_.capitalize(t("core.create"))} showLeftButton leftButtonText={_.capitalize(t("core.anonymityRules"))} leftButtonVariant={"link"} handleLeftButton={handleOpen}  tourDataset={{card : '',leftBtn: 'anonymityRulesTeams', btn : 'teamsCreate', title : 'teamsTitle',}} tourTooltip={true} tourType={TourType.MANAGE_TEAMS}>
               {
                   (teams && teams.length > 0) ?
                       <TableViewV2 items={teams} model={TeamModel} handleEdit={handleOpenMenu} handleDelete={fetchTeams} handleView={toggleDrawerUsers} typeDetail={"custom"} iconDetail={<PersonAddAltOutlinedIcon className={"text-purple"} />} showDetail handleObject={true} textDetail={_.upperFirst(t("core.addUsers"))} rowCursor="pointer" showActions={!props.companyToEdit} handleRowClick={handleRowClick} tablePage={tablePage} handleTablePage={handleTablePage} min={min} getTextConfirmation={checkIfTeamHasCB} />
                       :
                       <EbloomJumbotron title={_.capitalize(t("admin.teamsGetStarted"))} text={_.upperFirst(t("admin.teamsGetStartedHelper"))} handleAction={toggleDrawer(true)} buttonText={"core.create"}/>

               }
           </CardDefault>
           <SideForm sideFormOpen={sideFormOpen} toggleDrawer={toggleDrawer} handleSave={handleSave} formTitle={formTitle} tourDataset={{card :'saveTeam', btn : 'btnSaveTeam'}}>
               <Row>
                    <TextFieldCustom data-teams={'inputName'} model={TeamModel.name} value={team.name} handleChange={handleChange} />
               </Row>
           </SideForm>
           <SideForm formTitle={"core.edit"} sideFormOpen={sideFormUsersTeam} toggleDrawer={toggleDrawerUsers} showButtons={false}>
               <div className={"flex"}>
                   <div className={"p-5 m-5"}>
                       <ListView model={UserModel} items={team.users} handleAction={handleRemove} actionType={"remove"} listTitle={_.upperFirst(t("core.usersWithThisParam", {param :_.upperFirst(team.name)}))}/>
                   </div>
                   <Divider orientation="vertical" flexItem/>
                   <div className={"p-5 m-5"}>
                       <ListView model={UserModel} items={userNotInTeam} handleAction={handleAdd} listTitle={_.upperFirst(t("core.usersAvailableAdd"))}/>
                   </div>
               </div>
           </SideForm>
           <Menu id="teams_menu"
                 anchorEl={anchorTeam}
                 keepMounted
                 open={Boolean(anchorTeam)}
                 onClose={handleCloseMenu}
           >
               <List subheader={<ListSubheader>{_.upperFirst(t("core.editParam", {param:team.name}))}</ListSubheader>}>
                   <MenuItem key={1} onClick={toggleDrawer(true, team)}>
                       <ListItemText primary={_.capitalize(t('core.rename'))}/>
                   </MenuItem>
                   <MenuItem key={2} onClick={handleRowClick(team)}>
                       <ListItemText primary={_.capitalize(t("core.update"))}/>
                   </MenuItem>
               </List>
           </Menu>
           <ConfirmModal handleClose={handleClose} show={showModal} title={_.capitalize(t("core.anonymityRules"))} size="lg" showButtons={false}>
               <p className={"ebloom-text ebloom-large-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, fontWeight:500}}>{_.upperFirst(t("admin.teamsTipsTitle"))}</p>
               <div className={"ebloom-normal-text"}>
                   <li>{_.upperFirst(t("admin.teamsTips1", {min:min.toString()}))}</li>
                   <li>{_.upperFirst(t("admin.teamsTips2"))}</li>
                   <li>{_.upperFirst(t("admin.teamsTips3"))}</li>
               </div>
           </ConfirmModal>
       </>
    )

}

export default Teams;