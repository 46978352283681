import React from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import EmployeeEditor from "./EmployeeEditor";
import {Container} from "react-bootstrap";



const EmployeeEditorPage = (props) => {
    return(
        <DisplayAdminView {...props} menu={"settings"} pageName={"core.user.users"} selectedIndex={1}>
            {
                () => (
                    <Container>
                        <EmployeeEditor {...props}/>
                    </Container>
                )
            }
        </DisplayAdminView>
    )
};

export default EmployeeEditorPage;