export const invitePackageStarterSteps = [
    {
        title: "admin.productTour.invite.step1.title",
        content: "admin.productTour.invite.step1.content",
        target: '[data-invite="addButton"]',
        placement: 'center',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        toolTipWidth : 'auto' ,
        styles: {
            options : {
                zIndex : 1500
            }
        },
    },{
        title: "admin.productTour.invite.step2.starterTitle",
        content: "admin.productTour.invite.step2.starterContent",
        target: '[data-invite="addButton"]',
        placement: 'top',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        toolTipWidth : 'auto' ,
        styles: {
            options : {
                zIndex : 1500
            }
        },
        disableNext: true,
        nextText: 'admin.productTour.invite.step2.nextText'
    },
    {
        title: "admin.productTour.invite.step6.title",
        content: "admin.productTour.invite.step6.content",
        target: '[data-invite="resultExcel"]',
        placement: 'top',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        disableBack : true,
        spotlightClicks: false,
        toolTipWidth : 'auto' ,
        styles: {
            options : {
                zIndex : 1500
            }
        }
    },

    {
        title: "admin.productTour.invite.step7.title",
        content: "admin.productTour.invite.step7.content",
        target: '[data-invite="seePreview"]',
        placement: 'left',
        placementBeacon: 'top',
        disableBeacon : false,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        spotlightPadding: 3,
        nextText : "admin.productTour.invite.step7.nextText",


    },
    {
        title: "admin.productTour.invite.step8.title",
        content: "admin.productTour.invite.step8.content",
        target: '[data-invite="editText"]',
        placement: 'left',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,
    },
    {
        title: "admin.productTour.invite.step9.title",
        content: "admin.productTour.invite.step9.content",
        target: '[data-invite="editLang"]',
        placement: 'right',
        disableBeacon : true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: false,

    },
    {
        title: "admin.productTour.invite.step10.title",
        content: "admin.productTour.invite.step10.content",
        target: '[data-invite="finalInvite"]',
        placement: 'top',
        disableBeacon : false,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        disableNext : true,
        nextEmpty : true,
        enableLastSkip : true

    },
]