import React, {useEffect, useState} from "react";
import HttpApi  from "../../../../httpApi";
import {
    createSuccessfullyUpdatedNotification,
    createSuccessNotification
} from "../../../../Utils/Notification";
import {Card, Row, Col, Button} from "react-bootstrap";
import _ from "lodash";
import CheckIcon from '@mui/icons-material/Check';
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {useTranslation} from "react-multi-lang/lib";

const Integration = (props) => {
    const [slackActivated, setSlackActivated] = useState(false);
    const [mailActivated, setMailActivated] = useState(false);
    const [slackActiveState, setSlackActiveState] = useState(false);
    const [teamsActiveState, setTeamsActiveState] = useState(false);
    const [teamsActivated, setTeamsActivated] = useState(false);

    const t = useTranslation();

    const decodeCode = () => {
        let url = new URL(window.location.href);
        let code = url.searchParams.get("code");
        if(code){
            HttpApi.postV2("/integrations/slack/decode", {code:code}).then(() => {
                createSuccessNotification("Success", "eBloom successfully integrated with Slack !");
                setSlackActivated(true);
                window.history.replaceState({}, document.title, "/admin/integration");
            })
        }
    };

    const getAll = () => {
        HttpApi.getV2('/integrations/getAll').then(response => {
            if(response && response.data){
                setSlackActivated(response.data.some(el => el.type === "slack"));
                setSlackActiveState(response.data.some(el => el.type === "slack" && el.active));
                setMailActivated(response.data.some(el => el.type === "mail" && el.active));
                setTeamsActivated(response.data.some(el => el.type === "teams"));
                setTeamsActiveState(response.data.some(el => el.type === "teams" && el.active));
            }else{
                setSlackActivated(false);
                setMailActivated(false);
                setTeamsActivated(false);
                setTeamsActiveState(false);
            }
        })
    }

    const switchState = (type) => {
        let active = type === "slackActiveState" ? !slackActiveState : type === "teamsActiveState" ? !teamsActiveState : !mailActivated;
        let changeType = type === "slackActiveState" ? "slack" : type === "teamsActiveState" ? "teams" : "mail";
        HttpApi.put("/integrations/switchActiveState", {type:changeType, active:active}).then(() => {
            createSuccessfullyUpdatedNotification("active state");
            getAll()
        })
    };

    const downloadTeamsTutorial = () => {
        window.open("/documents/teams_integration.zip");
    }

    useEffect(() => {
        decodeCode();
        getAll()
    }, [])

    return(
        <>
            <Row>
                <Col className={'px-25'}>
                    <CardDefault showButton={false} title={_.upperFirst(t("core.integration"))}>
                        <p>{_.upperFirst(t("admin.integrationHelper"))}</p>
                    </CardDefault>
                </Col>
            </Row>
            <Row className={"my-20"}>
                <Col md={4} sm={12}>
                    <Card style={{width:300,float:"right", opacity:(!slackActiveState && (mailActivated || teamsActiveState)) ? 0.6 : 1, minHeight:379}}>
                        <Card.Body className={'my-50'}>
                            <div className={'text-center'}>
                                <div className={"logo_slack_large m-auto"}/>
                                    {
                                        slackActivated ?
                                            slackActiveState ?
                                                <Button className={"ebloom-btn-green mt-40"} size={"lg"} onClick={() => switchState("slackActiveState")}><CheckIcon/> {_.upperFirst(t("core.activated"))}</Button>
                                                :
                                                <Button className={"ebloom-btn-blue mt-40"} size={"lg"} onClick={() => switchState("slackActiveState")}><Row style={{marginLeft:1, marginRight:1}}>{_.upperFirst(t("admin.activateSlack"))}</Row></Button>
                                            :
                                            <a className={'mt-40'} href="https://slack.com/oauth/v2/authorize?client_id=774829323251.1130043035907&scope=incoming-webhook"><img
                                                alt="Add to Slack" height="40" width="139"
                                                src="https://platform.slack-edge.com/img/add_to_slack.png"
                                                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" style={{marginTop:40}}/></a>
                                    }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card style={{width:300, margin:"auto", opacity:!mailActivated && (slackActiveState || teamsActiveState) ? 0.6 : 1}}>
                        <Card.Body style={{marginTop:50, marginBottom:50}}>
                            <div style={{textAlign:"center"}}>
                                <Row><div className={"logo_mail_large"} style={{margin:"auto", fontSize:"15em"}}/></Row>
                                {
                                    mailActivated ?
                                        <Button  style={{marginTop:40}} size={"lg"} className={"ebloom-btn-green"} onClick={() => switchState("mailActivated")}><CheckIcon/> {_.upperFirst(t("core.activated"))}</Button>
                                        :
                                        <Button className={"ebloom-btn-blue"}  style={{marginTop:40}} size={"lg"} onClick={() => switchState("mailActivated")}> {_.upperFirst(t("admin.activateMail"))}</Button>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} sm={12}>
                    <Card style={{width:300, float:"left", opacity:!teamsActiveState && (slackActiveState || mailActivated) ? 0.6 : 1}}>
                        <Card.Body style={{marginTop:50, marginBottom:50}}>
                            <div style={{textAlign:"center"}}>
                                <Row><div className={"logo_teams_large"} style={{margin:"auto", fontSize:"15em"}}/></Row>
                                {
                                    teamsActivated ?
                                        teamsActiveState ?
                                            <Button  style={{marginTop:40}} size={"lg"} className={"ebloom-btn-green"} onClick={() => switchState("teamsActiveState")}><CheckIcon/> {_.upperFirst(t("core.activated"))}</Button>
                                            :
                                            <Button className={"ebloom-btn-blue"}  style={{marginTop:40}} size={"lg"} onClick={() => switchState("teamsActiveState")}> {_.upperFirst(t("admin.activateTeams"))}</Button>
                                        :
                                        <Button style={{marginTop:40}} size={"lg"} className={"ebloom-btn-purple"} onClick={downloadTeamsTutorial}>{_.upperFirst(t("admin.integrateTeams"))}</Button>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className={"mt-20"}/>
        </>
    )

}

export default Integration;