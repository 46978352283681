import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Accordion, Badge, Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";
import CustomButton from "../../../Core/Modules/Button/CustomButton";
import {Colors} from "../../../../Utils/Colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {FullContrastPurpleTextField} from "../../../../Utils/Global";
import {Label} from "recharts";
import {MenuItem} from "@mui/material";
import DeleteModal from "../../../Core/Modules/Modal/DeleteModal";
import HttpApi from "../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../Utils/Notification";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton/IconButton";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const FrequencyItem = (props) => {
    const {frequency,selectedEntity,handleAccordion,keyAccordion,expandedKey,handleRemove,showRemove,refreshFrequencies,maxCB,setFrequenciesUnsaved,frequenciesUnsaved,listFrequencies} = props;
    const checkName = (number) => {
        const name = "Frequency #"+number;
        if(listFrequencies.filter(el => el.title!==null && el.title.trim() === name.trim()).length > 0){
            return checkName(number+1)
        }
        return name;

    }
    const [frequencyToEdit, setFrequencyToEdit] = useState({
        id:null,
        title:checkName(listFrequencies.length),
        recurrency:1,
        monday:0,
        tuesday:0,
        wednesday:0,
        thursday:0,
        friday:0,
        auto_planners : []
    });
    const [nbCB, setNbCB] = useState(0);

    const t = useTranslation();

    const displayMaxCB = maxCB === 1000 ? t("core.unlimited") : maxCB;
    const recurrencies = [{value:1, name:"core.everyWeek"}, {value:2, name:"core.everyTwoWeeks"}, {value:3, name:"core.everyThreeWeeks"}, {value:4, name:"core.everyFourWeeks"}];
    const week = ["monday","tuesday","wednesday","thursday","friday"];

    const updateNbCB = (frequencyData ) => {
        const data = frequencyData
        const nb = week.reduce((previousValue, currentValue) => previousValue + data[currentValue],0)
        setNbCB(nb)
    }

    const removeFrequency = (day) => event => {
        setFrequencyToEdit(prevState => {
            let week = {...prevState};
            week[day] = week[day] - 1;
            return week;
        })
        setNbCB(prevState => prevState -1)
    }

    const addFrequency = (day) => event => {
        setFrequencyToEdit(prevState => {
            let week = {...prevState};
            week[day] = week[day] + 1;
            return week;
        })
        setNbCB(prevState => prevState +1)
    }

    const isFrequencyChanged = () => {
        const isChanged= frequencyToEdit.id === null || frequencyToEdit.title !== frequency.title ||
            frequencyToEdit.recurrency !== frequency.recurrency ||
            frequencyToEdit.monday !== frequency.monday ||
            frequencyToEdit.tuesday !== frequency.tuesday ||
            frequencyToEdit.wednesday !== frequency.wednesday ||
            frequencyToEdit.thursday !== frequency.thursday ||
            frequencyToEdit.friday !== frequency.friday;
        if(isChanged){
            setFrequenciesUnsaved(previous => {
                if(previous.includes(frequency.id)){
                    return previous;
                }else{
                    return [...previous, frequency.id];
                }
            });
        }else{
            setFrequenciesUnsaved(previous => {
                return previous.filter(el => el !== frequency.id);
            });
        }
    }


    const handleChangeTitle= (event) => {
        setFrequencyToEdit(prevState => {
            let frequency = {...prevState};
            frequency.title = event.target.value;
            return frequency;
        })
    }

    const handleChangeRecurrency = (event) => {
        setFrequencyToEdit(prevState => {
            let frequency = {...prevState};
            frequency.recurrency = event.target.value;
            return frequency;
        })
    }

    const checkIfRelatedToAutoPlanner = (id) => {
        if(id !== null && frequency.auto_planners &&  frequencyToEdit.auto_planners.length > 0){
            return _.upperFirst(t("admin.frequencies.deleteHelper"))
        }else{
            return null
        }
    }

    const statusToDisplay = () => {
        if(frequency.auto_planners && frequency.auto_planners.length > 0){
            if(frequency.auto_planners.some(el => el.active)){
                return _.upperFirst(t("core.active"))
            }else{
                return _.upperFirst(t("core.inactive"))
            }
        }else{
            return _.upperFirst(t("core.inactive"))
        }
    }

    const classNameToDisplay = () => {
        if (frequency.auto_planners && frequency.auto_planners.length > 0) {
            if (frequency.auto_planners.some(el => el.active)) {
                return "bg-strong-green"
            } else {
                return "bg-grey"
            }
        } else {
            return "bg-grey"
        }
    }


    const handleSave = () => {
        if( frequencyToEdit.title === null  ||frequencyToEdit.title.trim() === "" || listFrequencies.filter(el => frequency.id !== el.id).filter(el =>el.title.trim() === frequencyToEdit.title.trim() ).length > 0){
            createErrorNotification(_.upperFirst(t("admin.frequencies.invalidFrequency")),_.upperFirst(t("admin.frequencies.enterAValidTitle")))
            return
        }
        if(frequencyToEdit.id === -10){
            setFrequencyToEdit(prevState => {
                let frequency = {...prevState};
                frequency.id = null;
                return frequency;
            })
        }
        let HttpCall = frequencyToEdit.id === null ? HttpApi.postV2 : HttpApi.put;
        let url = frequencyToEdit.id === null ? '/frequencies/create' : '/frequencies/update';
        if(!!selectedEntity){
            url += '/' + selectedEntity;
        }
        HttpCall(url, frequencyToEdit).then(response => {
            let msg = frequencyToEdit.id === null ? _.upperFirst(t("admin.frequencies.createSuccess")) : _.upperFirst(t("admin.frequencies.updateSuccess"))
            notifySuccess(msg)
            setFrequenciesUnsaved(previous => {
                return previous.filter(el => el !== frequency.id);
            });
            refreshFrequencies();

        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {
        isFrequencyChanged();
    }, [frequencyToEdit]);

    useEffect(() => {
        if (!frequenciesUnsaved.includes(frequency.id)) {
            setFrequencyToEdit(frequency)
        }else if (frequency.id === -10){
            setFrequencyToEdit(prevState => {
                let frequencyState = {...prevState};
                frequencyState.title = frequency.title;
                return frequencyState;
            })
        }
        updateNbCB(frequency)
    }, [frequency]);

    return (
        <Accordion.Item as={"div"} className={"w-full my-10"} eventKey={keyAccordion}>
            <Accordion.Button bsPrefix={"accordion"} action="true" as={"div"} onClick={() => handleAccordion(keyAccordion) } className={"cursor-pointer ebloom-accordion border-[1px] border-contrast-purple rounded-md  h-45 w-full pl-15 "}>
                <Row className={"h-full w-full m-0"} >
                    <Col className={"flex justify-start items-center"}>
                        <h1 className={"text-contrast-purple ebloom-text ebloom-large-text"}>{frequencyToEdit.title}</h1>
                        <div className={"ml-10 flex justify-center items-center"}>
                            {
                                frequenciesUnsaved.includes(frequency.id) || frequencyToEdit.id === null ?
                                    <Badge pill className={"text-white bg-strong-red "}>{_.upperFirst(t("core.unsaved"))}</Badge>
                                    :
                                    <Badge pill className={"text-white "+ classNameToDisplay()}>{statusToDisplay()}</Badge>

                            }
                        </div>

                    </Col>
                    <Col md={{span: "2", order: "last"}}>
                        <div className={"w-full h-full flex  justify-end items-center"}>

                                {
                                    frequency.auto_planners && frequency.auto_planners.some(el => el.active) ?
                                        <EbloomTooltip text={_.upperFirst(t("admin.frequencies.linkedToActiveAutoPlanner"))} placement={"top"}>
                                            <div className={"cursor-default"}>
                                                <IconButton className={"text-grey"} disabled={true}  size="sm" onClick={() => {}}><DeleteIcon/></IconButton>
                                            </div>
                                        </EbloomTooltip>


                                        :
                                        showRemove &&
                                        <DeleteModal handleDelete={handleRemove} getTextConfirmation={checkIfRelatedToAutoPlanner}  noRequest={true} />
                                }

                            <div className={"w-30 h-30  transition duration-300 " + (expandedKey === keyAccordion ? "rotate-180" : "rotate-0")}>
                                <ArrowDropDownIcon className={"w-full h-full text-contrast-purple"}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Accordion.Button>
            <Accordion.Body style={{padding : 0}}>
                <Card style={{border:"none"}}>
                    <Card.Body>
                        <Row md={3} className={"flex justify-between items-center w-full mb-20"}>
                            <Col>
                                <div className={"flex justify-center items-center max-w-350"}>
                                    <h1 className={"mr-5 ebloom-text ebloom-normal-text "}>{_.upperFirst(t("core.name"))}:</h1>
                                    <FullContrastPurpleTextField variant={"outlined"} size="small"
                                                                 value={frequencyToEdit.title} name={"title"}
                                                                 onChange={handleChangeTitle}/>
                                </div>
                            </Col>
                            <Col>
                                <div className={"flex justify-center items-center "}>
                                    <h1 className={"mr-5 ebloom-text ebloom-normal-text "}>{_.upperFirst(t("admin.applyFrequency"))}:</h1>
                                    <FullContrastPurpleTextField style={{float: "left", width: 200}}
                                                                 variant={"outlined"}
                                                                 size={"small"} select onChange={handleChangeRecurrency}
                                                                 value={frequencyToEdit.recurrency}>
                                        {
                                            recurrencies.map((option, index) => (
                                                <MenuItem key={index} value={option.value}
                                                          className={"ebloom-normal-text"}>{t(option.name)}</MenuItem>
                                            ))
                                        }
                                    </FullContrastPurpleTextField>
                                </div>
                            </Col>
                            <Col>
                                <div className={"ebloom-normal-text text-contrast-purple w-full text-center"}>
                                    <span>
                                        {_.upperFirst(t("admin.cbUsed"))}
                                        <span className={"text-contrast-blue font-bold "}>
                                            {nbCB}
                                        </span>
                                        /{displayMaxCB}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row md={5} style={{margin: "auto", marginBottom: 20}}>
                            {
                                week.map((day, index) => (
                                    <Col style={{padding: 2}} key={index}>
                                        <Card style={{minWidth: 170, borderColor: Colors.EBLOOM_CONTRAST_PURPLE}}>
                                            <Card.Header style={{textAlign: "center", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, color:"white"}} className={"ebloom-text ebloom-large-text"}>{_.capitalize(t("core.day." + day))}</Card.Header>
                                            <Card.Body>
                                                <ListGroup variant={"flush"}>
                                                    {
                                                        Array(frequencyToEdit[day]).fill("Click&Bloom").map((value, index) => (
                                                            <ListGroup.Item key={index} style={{paddingLeft:0, paddingRight:0}}>
                                                                <div style={{textAlign:"center"}}>
                                                                    <span style={{float:"left"}}>{value}</span>
                                                                    <CustomButton type={"remove"} border style={{float:"right"}} handleClick={removeFrequency(day)}/>
                                                                </div>
                                                            </ListGroup.Item>

                                                        ))
                                                    }
                                                    {
                                                        nbCB < maxCB &&
                                                        <ListGroup.Item className={"center"} style={{paddingTop:frequencyToEdit[day] === 0 ? 0 : 15, paddingBottom:0}}>
                                                            <AddCircleOutlineIcon className={"ebloom-add-btn-purple"} onClick={addFrequency(day)}/>
                                                        </ListGroup.Item>
                                                    }

                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                        <Row className={"w-full"}>
                            {
                                frequenciesUnsaved.includes(frequency.id) &&
                                <div className={"w-full flex justify-end items-center mt-15"}>
                                    {
                                        nbCB > 0 ?
                                            <Button className={"ebloom-btn-purple w-auto"} size={"sm"}
                                                    onClick={() => handleSave()}>
                                                <div>{_.upperFirst(t("core.save"))}</div>
                                            </Button>

                                            :
                                            <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.emptyFrequencyTitle"))} placement={"top"}>
                                                <div>
                                                    <Button disabled={true} className={"ebloom-btn-purple w-full"} size={"sm"}
                                                            onClick={() => handleSave()}>
                                                        <div>{_.upperFirst(t("core.save"))}</div>
                                                    </Button>
                                                </div>

                                            </EbloomTooltip>

                                    }

                                </div>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Accordion.Body>
        </Accordion.Item>
    );
};

FrequencyItem.propTypes = {
    frequency: PropTypes.object.isRequired,
    selectedEntity: PropTypes.number,
    handleAccordion: PropTypes.func.isRequired,
    keyAccordion: PropTypes.string.isRequired,
    expandedKey: PropTypes.string.isRequired,
    handleRemove: PropTypes.func.isRequired,
    showRemove: PropTypes.bool.isRequired,
    refreshFrequencies: PropTypes.func.isRequired,
    maxCB : PropTypes.number.isRequired,
    setFrequenciesUnsaved : PropTypes.func.isRequired,
    frequenciesUnsaved : PropTypes.array.isRequired,
    listFrequencies : PropTypes.array.isRequired
};

FrequencyItem.defaultProps = {
    selectedEntity: null
}

export default FrequencyItem;
