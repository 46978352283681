import React, {useState, useEffect} from "react";
import HttpApi from "../../../../httpApi";
import {ReactComponent as NotificationsIcon} from "../../symbols/webloom/white/alert-white.svg"
import {Button as ButtonBootstrap, Col, ListGroup, Row} from "react-bootstrap";
import {Colors} from "../../../../Utils/Colors";
import _ from "lodash";
import moment from "moment";
import EbloomPopoverV2 from "../Popovers-Tooltips/EbloomPopoverV2";
import {useTranslation} from "react-multi-lang";
import {notifyError} from "../../../../Utils/Notification";
import {useNavigate, useNavigation} from "react-router-dom";
import EbloomNotificationBadge from "./EbloomNotificationBadge";
import {useUserData} from "../../../../hooks/userHook";


const EbloomNotification = (props) => {
    const [showPopover, setShowPopover] = useState(false);
    const [targetPopover, setTargetPopover] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [badgeNotif, setBadgeNotif] = useState(0);
    const [nbNewNotif, setNbNewNotif] = useState(0);
    const [limitNotif, setLimitNotif] = useState(5);
    const [totalNotif, setTotalNotif] = useState(0);

    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData()

    const handleClosePopover = () => {
        setShowPopover(false);
    }

    const handleOpenPopover = (event) => {
        if(showPopover){
            setShowPopover(false);
        }else{
            setShowPopover(true);
            setTargetPopover(event.target);
            if(badgeNotif > 0){
                HttpApi.post("/notification/updateUserLastNotif").then(() => {
                    setBadgeNotif(0);
                }).catch(error => {
                    notifyError(error);
                })
            }
        }
    }

    const checkIfNotif = (limit=5) => {
        HttpApi.get("/notification/getForUser/" + limit).then(response => {
            if(response && response.data){
                setNotifications(_.orderBy(response.data[0],['created_at'],['desc']));
                setBadgeNotif(response.data[1]);
                setNbNewNotif(response.data[1]);
                setTotalNotif(response.data[2]);
            }else{
                setNotifications([]);
                setBadgeNotif(0);
                setNbNewNotif(0);
                setTotalNotif(0);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleClickSeeMore = () => {
        const newLimit = limitNotif + 5;
        setLimitNotif(newLimit);
        checkIfNotif(newLimit);
    }

    const redirectTo = (url) => event => {
        event.preventDefault();
        navigate(url);
    }

    useEffect(() => {
        checkIfNotif();
    }, []);

    return(
        <EbloomNotificationBadge number={badgeNotif} showNumber>
            <EbloomPopoverV2 trigger={<span style={{color:"white", fontWeight:"normal"}} onClick={handleOpenPopover}><NotificationsIcon className={"w-23 h-23"}/></span>} placement="bottom" show={showPopover} target={targetPopover} handleClose={handleClosePopover} width={350}>
                <ListGroup defaultActiveKey={0} variant="flush" style={{maxHeight:454, overflow:"auto", overflowX:"hidden"}}>
                    {
                        notifications && notifications.length > 0 && notifications.map((notification, index) => (
                            <ListGroup.Item key={index} className={index<nbNewNotif ? notification.url ? "list_notification_unread list_notification_unread_clickable" : "list_notification_unread" : notification.url ? "list_notification_clickable list_notification" : "list_notification"} onClick={notification.url ? redirectTo(notification.url,Boolean(notification.isUserNotification)) : () => {}}>
                                <Row>
                                    <Col>
                                        <span style={{fontSize:18, fontFamily:"Lato", fontWeight:index < nbNewNotif ? "bold" : "normal", color:index < nbNewNotif ? Colors.EBLOOM_CONTRAST_PURPLE : "black"}}>{_.upperFirst(t("core.notification." +notification.isUserNotification ? "info" : notification.type))}</span>
                                        <p className={"ebloom-normal-text"} style={{color:index < nbNewNotif ? "black" : "grey"}}>{notification.isUserNotification ? _.upperFirst(t("core.notification."+notification.type, {group:user.companyGroup.name})) :notification.notifications_tr[0].text}</p>
                                    </Col>
                                    <Col md={3}>
                                        <span className={"right ebloom-small-text"} style={{color:index < nbNewNotif ? "black" : "grey"}}>{moment(notification.created_at).format("DD/MM/YYYY")}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
                {
                    totalNotif > notifications.length &&
                    <div className={"center"}>
                        <ButtonBootstrap variant={"link"} style={{color:Colors.EBLOOM_PURPLE}} onClick={handleClickSeeMore}>{_.upperFirst(t("core.seeMore"))}</ButtonBootstrap>
                    </div>
                }
            </EbloomPopoverV2>
        </EbloomNotificationBadge>
    )
}

export default EbloomNotification;