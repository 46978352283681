import React from 'react';
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import Filter from "./Filter";

const FilterPage = (props) => {
    //const {} = props

    return (
        <DisplayAdminView selectedIndex={31} menu={"settings"} {...props}>
            {() => (
                <Container>
                    <Filter {...props}/>
                </Container>
            )
            }
        </DisplayAdminView>
    );
};

FilterPage.propTypes = {

};

export default FilterPage;
