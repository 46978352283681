import React, {useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {Colors} from "../../../../Utils/Colors";
import {Button, Modal} from "react-bootstrap";
import Confetti from "react-confetti";
import HttpApi from "../../../../httpApi";
import {avatarCodes} from "../../../Employee/Pages/MyBloom/Avatar/AvatarAssets";
import {notifyError} from "../../../../Utils/Notification";
import Avatar from "../../../Employee/Pages/MyBloom/Avatar/Avatar";
import {GlobalContext} from "../../../../Utils/Context/GlobalContext";
import {useTranslation} from "react-multi-lang/lib";

const ReviewModal = (props) => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [loopConfetto, setLoopConfetto] = useState(true)
    const [show, setShow] = useState(false)
    const [participationPoint, setParticipationPoint] = useState(2)
    const [avatar, setAvatar] = useState({
        form: '01',
        hat: '16',
        emote: '06',
        color: '01'
    });

    const {showModalGoogle, setShowModalGoogle,googleReviewData} = useContext(GlobalContext)

    const t = useTranslation();

    const div = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const colors = [
        Colors.EBLOOM_CONTRAST_BLUE,
        Colors.EBLOOM_CONTRAST_PURPLE,
        Colors.EBLOOM_PURPLE,
        Colors.EBLOOM_LOGO_BLUE,
        Colors.EBLOOM_BG_BLUE
    ]

    const handleClose= () => {

        setShow(false)
        setShowModalGoogle(false);
    }

    const handleRemindReviews = (reviewed= false) => {
        HttpApi.postV2("/users/updateReminderReview", {reviewed : reviewed}).then(()=> {
            handleClose()
        }).catch(error => {
            notifyError(error);
        })
    }


    const fetchAvatar = () => {
        HttpApi.get("/mybloom/getAvatar").then(result => {
            const data = avatarCodes(result.data)
            data.emote = "11" //Happy
            setAvatar(data)
        }).catch(error => {
            notifyError(error);
        })
    }
    const descriptor = {
        2: "observer",
        3: "curious",
        4: "enthusiastic",
        5: "involved",
        6: "proactive",
        7: "expert",
        8: "pillar",
        9: "legendary"
    }
    const calculatePoint = () => {
        let point = 2
        const partRate = googleReviewData.participation
        if(partRate != null && partRate> 0){
            if (partRate <= 20){
                point = point+1
            }else if (partRate <= 40){
                point = point+2
            }else if (partRate <= 60){
                point = point+3
            }else if (partRate <= 70){
                point = point+4
            }else if (partRate <= 80){
                point = point+5
            }else if (partRate <= 90){
                point = point+6
            }else if (partRate >= 91){
                point = point+7
            }
        }
        setParticipationPoint(point)
    }
    useEffect(() => {
        fetchAvatar()
        calculatePoint()
    }, []);

    useEffect(() => {
        if (showModalGoogle) {
            setTimeout(() => {
                setShow(true)
            }, 1000)
            setTimeout(() => {
                setLoopConfetto(false)
            }, 3000)
        } else {
            setShow(false)
            setLoopConfetto(true)
        }
    }, [showModalGoogle]);

    return (
        <Modal show={show} size={"lg"} style={{borderColor: Colors.EBLOOM_CONTRAST_BLUE}}>
            <div ref={div} data-tour={'modal'}>
                <Modal.Header closeButton style={{backgroundColor: Colors.EBLOOM_CONTRAST_BLUE, color: "white"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}
                                 style={{textAlign: "center", color: "white"}}>{_.upperFirst(t("employee.googleReviewTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"ebloom-normal-text"} style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                    <div className={"mb-15"}>
                       <p>{_.upperFirst(t("employee.googleReviewText",{"numberCB" : googleReviewData.cb, 'participation' : _.upperFirst(t("employee.dashboard.descriptor."+descriptor[participationPoint]))}))}</p>
                    </div>
                    <Podium userAvatar={avatar}/>
                </Modal.Body>
                <Modal.Footer className={"flex justify-center items-center w-full relative"}
                              style={{backgroundColor: Colors.EBLOOM_WHITE_BLUE}}>
                    <div className={"absolute left-10 cursor-pointer text-grey"} onClick={()=>handleRemindReviews(false)}>{_.upperFirst(t("employee.googleReviewReminder"))}</div>
                    <Button variant={"purple"} className={"ebloom-btn-purple"} target="_blank" href={"https://g.page/r/CWUtMz6pmgdMEB0/review"} onClick={()=>handleRemindReviews(true)} >{_.upperFirst(t("employee.googleReviewButton"))}</Button>
                </Modal.Footer>
                <Confetti colors={colors} numberOfPieces={500} recycle={loopConfetto} width={width} height={height} />
            </div>
        </Modal>

    );
};
const Podium = (props) => {
    const {userAvatar} = props
    const fakeSecond = {hat: '16', emote: '08', color: '03', form: '05'}
    const fakeThird = {hat: '16', emote: '06', color: '06', form: '01'}
    return <div className={"flex justify-center items-center"}>
        <div className={"flex-row flex justify-center items-end"}>
            <div className={"mr-20"}>
                <div className={"w-100"}>
                    <Avatar avatar={fakeSecond}/>
                </div>
                <div className={"w-100 h-80 bg-contrast-purple flex rounded-t-lg justify-center items-center p-10 "}>
                    <div
                        className={"bg-[#C0C0C0] border-4 border-[#868686] p-8 rounded-lg shadow-lg text-center"}>
                        <p>2nd</p>
                    </div>
                </div>
            </div>

            <div>
                <div className={"w-100"}>
                    <Avatar avatar={userAvatar}/>
                </div>
                <div
                    className={"w-100 h-100 bg-contrast-purple rounded-t-lg flex justify-center items-center p-10"}>
                    <div
                        className={"bg-[#FFD700] border-4 border-[#B29600] p-8 rounded-lg shadow-lg text-center"}>
                        <p className={""}>Top Bloomer</p>
                    </div>
                </div>
            </div>
            <div className={"ml-20"}>
                <div className={"w-100"}>
                    <Avatar avatar={fakeThird}/>
                </div>
                <div className={"w-100 h-60 bg-contrast-purple flex rounded-t-lg justify-center items-center p-10"}>
                    <div
                        className={"bg-[#b87333] border-4 border-[#935c28] p-8 rounded-lg shadow-lg text-center"}>
                        <p>3rd</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
Podium.propTypes = {
    userAvatar: PropTypes.object.isRequired,
};
export default ReviewModal;

