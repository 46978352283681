import React, {useContext, useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {Badge, Button, Card, Col, ListGroup, Modal, Row} from "react-bootstrap";
import moment from "moment";
import {Colors} from "../../../../../Utils/Colors";
import _ from "lodash";
import {useTranslation} from "react-multi-lang/lib";
import {
    createErrorNotification,
    createSuccessfullyCreatedNotification,
    createSuccessfullyDeletedNotification,
    notifyError,
    notifySuccess
} from "../../../../../Utils/Notification";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import PlaylistIcon from '@mui/icons-material/SubscriptionsOutlined';
import LibraryIcon from '@mui/icons-material/ListOutlined';
import QuestionList from "../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import {
    getEndDateAccordingToWindow,
    isNullOrUndefined,
    Modules,
    publicHolidays,
    Roles,
    SettingTypes, SurveyQuestionRecipients,
    TourType
} from "../../../../../Utils/Global";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import {useParams} from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FreeTrialModal from "../../../../Core/Modules/Modal/FreeTrialModal";
import CategoryTag from "../../../../Core/Modules/CategoryDisplay/CategoryTag";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {useUserData} from "../../../../../hooks/userHook";
import PlanClickAndBloomTour from "../../../../../Utils/ProductTour/Tours/PlanClickAndBloom/PlanClickAndBloomTour";
import {ProductTourContext} from "../../../../../Utils/Context/ProductTourContext";
import {getLanguage} from "react-multi-lang";
import {Checkbox, Divider, FormControlLabel} from "@mui/material";
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import IdentityQuestionModal from "../../../../Core/Modules/Modal/IdentityQuestionModal";
import IconButton from "@mui/material/IconButton/IconButton";
import {ReactComponent as AlertIcon} from "../../../../Core/symbols/webloom/white/danger-white.svg";
import AutoPlannersList from "./AutoPlanner/AutoPlannersList";


const Planner = (props) => {
    const [anchorPlanner, setAnchorPlanner] = useState(null);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [playlists, setPlaylists] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [days, setDays] = useState({monday:moment().startOf("week").add(1, "day"), tuesday:moment().startOf("week").add(2, "day"), wednesday:moment().startOf("week").add(3, "day"), thursday:moment().startOf("week").add(4, "day"), friday:moment().startOf("week").add(5, "day")})
    const [selectedDay, setSelectedDay] = useState({});
    const [week, setWeek] = useState({monday:[], tuesday:[], wednesday:[], thursday:[], friday:[]});
    const [maxCB, setMaxCB] = useState(5);
    const [nbCB, setNbCB] = useState(0);
    const [playlistToReload, setPlaylistToReload] = useState(0);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [idSurveyQuestionToDelete, setIdSurveyQuestionToDelete] = useState(-1);
    const [holidays, setHolidays] = useState([]);
    const [showModalFreeTrial, setShowModalFreeTrial] = useState(false);
    const [startDateFreeAdmin, setStartDateFreeAdmin] = useState(moment().format());
    const [endDateFreeAdmin, setEndDateFreeAdmin] = useState(moment().add(4, "weeks").format())
    const [showClearModal, setShowClearModal] = useState(false);
    const [nbFutureQuestions, setNbFutureQuestions] = useState({future:0, futureNextWeek:0});
    const [isModelCustomEnable, setIsModelCustomEnable] = useState(false);
    const [groupSelected, setGroupSelected] = useState(false);
    const [entities, setEntities] = useState([]);
    const [entitiesAvailable, setEntitiesAvailable] = useState([]);
    const [entitiesSelected, setEntitiesSelected] = useState([]);
    const [selectedAllEntities, setSelectedAllEntities] = useState(false);
    const [sideFormEntities, setSideFormEntities] = useState(false);
    const [idQuestionForGroup, setIdQuestionForGroup] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [teams, setTeams] = useState([]);
    const [teamsAvailable, setTeamsAvailable] = useState([]);
    const [sideFormTeams, setSideFormTeams] = useState(false);
    const [idQuestionTeams, setIdQuestionTeams] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([-1]);
    const [showModalQuestion, setShowModalQuestion] = useState(false);
    const [idSurveyModal, setIdSurveyModal] = useState(null);

    //Auto planners states
    const [autoPlanners, setAutoPlanners] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [usedPlaylists, setUsedPlaylists] = useState([]);
    const [loading, setLoading] = useState(false);

    const t = useTranslation();
    const user = useUserData();
    const params = useParams();
    const {companyToEdit,selectedEntity,isDate} = props;

    const {validateOutcome,tourActivated, setTourStarted} = useContext(ProductTourContext)

    const isFree = user.module === Modules.FREE[0];
    const isStarter = (!!companyToEdit ? companyToEdit.module : user.module) === Modules.HAPPY[0];
    const isSuperAdmin = user.roles.includes("global_super_admin");
    const today = moment().format("DD/MM");

    const fetchQuestionsForWeek = (start, end,isDate = false) => {
        let start_date = start.format("YYYY-MM-DD");
        let end_date = end.format("YYYY-MM-DD");
        if(isDate){
            const date = params.date
            const dayOfWeek = moment(date).isoWeekday();
            start_date = moment(date).subtract(dayOfWeek-1, 'days').format('YYYY-MM-DD');
            if(dayOfWeek < 5){
                end_date = moment(date).add(5-dayOfWeek, 'days').format('YYYY-MM-DD');
            }else{
                end_date = moment(date).subtract(dayOfWeek-5, 'days').format('YYYY-MM-DD');
            }
            setDays({monday:moment(start_date), tuesday:moment(start_date).add(1, "day"), wednesday:moment(start_date).add(2, "day"), thursday:moment(start_date).add(3, "day"), friday:moment(end_date)})
        }
        let url = "/questions/getForWeekAndCompany/" + start_date + "/" + end_date
        if((isSuperAdmin && !isNullOrUndefined(selectedEntity) && selectedEntity === user.companyGroup?.id ) || (!!companyToEdit && companyToEdit.type === "group")){
            url = "/questions/getForWeekAndCompany/" + (!!companyToEdit ? companyToEdit.id : selectedEntity) + "/" + start_date + "/" + end_date
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity) || !isNullOrUndefined(companyToEdit))) || !isSuperAdmin || !isNullOrUndefined(companyToEdit) ){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    if(!!companyToEdit){
                        setNbCB(response.data.filter(el => el.id_company === companyToEdit.id).length)
                    }else if (!!selectedEntity){
                        setNbCB(response.data.filter(el => el.id_company === selectedEntity).length)
                    }else{
                        setNbCB(response.data.filter(el => el.id_company === user.companyId).length);
                    }
                    let newWeek = {monday:[], tuesday:[], wednesday:[], thursday:[], friday:[]};
                    response.data.forEach(question => {
                        let date =_.lowerCase(moment(question.date).format("dddd"))
                        newWeek[date].push(question);
                    })
                    setWeek(newWeek);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const fetchQuestions = () => {
        const date = days[selectedDay].format("YYYY-MM-DD");
        let url = ""
        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url = "/questions/getAllExceptDayWithLastUsed/"+date+"/"+ selectedEntity;
        }else if (!isSuperAdmin){
            url = "/questions/getAllExceptDayWithLastUsed/" + date+ "/"
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setQuestions(response.data);
                    if(!sideFormOpen ){
                        setSideFormOpen(true);
                    }else{
                        setSideFormEntities(false)
                    }
                }else{
                    setSideFormOpen(false)
                }
            }).catch(error => {
                notifyError(error);
            });
        }


    };

    const fetchForPlaylist = (id_playlist) => {
        const date = days[selectedDay].format("YYYY-MM-DD");
        let url = "/questions/getForPlaylistExceptDay/"+ id_playlist + "/" + date
        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url = "/questions/getForPlaylistExceptDay/"+ id_playlist + "/" + date+ "/" + selectedEntity
        }else if (!isSuperAdmin){
            url = "/questions/getForPlaylistExceptDay/" + id_playlist + "/" + date;
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin) {
            HttpApi.getV2(url).then(response => {
                if (response && response.data) {
                    setQuestions(response.data);
                    if (!sideFormOpen) {
                        setSideFormOpen(true);
                    }
                } else {
                    setSideFormOpen(false)
                }
            }).catch(error => {
                notifyError(error);
            });
        }
    };

    const fetchTeams = () => {
        HttpApi.getV2("/teams/getAll").then(response => {
            if(response && response.data){
                setTeams(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }



    const fetchPlaylists = () => {
        let url = "/playlists/getValidForCompany"
        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url += "/"+selectedEntity
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin) {
            HttpApi.getV2(url).then(response => {
                if (response && response.data) {
                    setPlaylists(response.data);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const fetchEndDateFree = () => {
        HttpApi.getV2("/companies/getEndDate").then(response => {
            if(response && response.data){
                setStartDateFreeAdmin(moment(response.data.created_at).format());
                setEndDateFreeAdmin(moment(response.data.end_date).format());
            }else{
                setStartDateFreeAdmin(moment().format())
                setEndDateFreeAdmin(moment().add(4, "weeks").format());
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchNumberFutureQuestions = (monday=null) => {
        const mondayDate = monday || days.monday;
        let url = ""
        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url = "/questions/countFutureQuestions/"+ moment(mondayDate).format("YYYY-MM-DD") + "/" +selectedEntity
        }else if (!isSuperAdmin){
            url = "/questions/countFutureQuestions/" + moment(mondayDate).format("YYYY-MM-DD")
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin){
            HttpApi.getV2(url).then(results => {
                if(results && results.data){
                    setNbFutureQuestions(results.data);
                }else{
                    setNbFutureQuestions({future:0, futureNextWeek:0});
                }
            }).catch(error => {
                notifyError(error);
            });
        }
    }

    const fetchModelCustomSetting = () => {
        let url = ""
        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url = "/settings/group/getForCompany/"+SettingTypes.CUSTOM_CATEGORIES+"/"+selectedEntity
        }else if (!isSuperAdmin){
            url = "/settings/getForCompany/"+ SettingTypes.CUSTOM_CATEGORIES
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setIsModelCustomEnable(Boolean(response.data.value))
                }else{
                    setIsModelCustomEnable(false)
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }


    const handleAddGroup = () => {
        const date = days[selectedDay].format("YYYY-MM-DD");
        const url = "/questions/insertAndCreate/"+companyInfo?.id
        if(idQuestionForGroup !== null){
            const data = {id_question:idQuestionForGroup, date:date, listEntities : entitiesSelected, teams :[],id_playlist: playlistToReload};
            HttpApi.postV2(url, data).then(() => {
                createSuccessfullyCreatedNotification("Click&Bloom", 1000);
                setIdQuestionForGroup(null)
                setEntitiesSelected([])
                setSelectedAllEntities(false)
                fetchQuestions();
                fetchQuestionsForWeek(days.monday, days.friday);
                fetchNumberFutureQuestions()
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const handleAddDrawers = (id_question) => event => {
        if(groupSelected){
            setSideFormOpen(false)
            setSideFormEntities(true)
            setIdQuestionForGroup(id_question)
        }else{
            setIdQuestionTeams(id_question)
            if(isStarter || teams.length === 0){
                handleAdd(id_question)
            }else{
                setSideFormOpen(false)
                setSideFormTeams(true)
            }

        }
    };

    const handleAdd = (id_question=null) =>  {
        const idQuestion = id_question || idQuestionTeams
        const date = days[selectedDay].format("YYYY-MM-DD");
        const url = "/questions/insertAndCreate"
        const teamsToSend= selectedTeams.length === 1 && selectedTeams[0] === -1 ? [] : selectedTeams.length >= 1 && !selectedTeams.includes(-1) ? selectedTeams :  []
        const data = {id_question:idQuestion, date:date,teams:teamsToSend,id_playlist: playlistToReload};
        HttpApi.postV2(url, data).then(() => {
            createSuccessfullyCreatedNotification("Click&Bloom", 1000);
            setIdQuestionTeams(null)
            if(!isStarter){
                setSideFormOpen(false)
                setSideFormTeams(false)
            }
            if(playlistToReload > 0){
                fetchForPlaylist(playlistToReload);
            }else{
                fetchQuestions();
            }

            fetchQuestionsForWeek(days.monday, days.friday);
            fetchNumberFutureQuestions()
            if(tourActivated){
                validateOutcome(TourType.PLAN_CLICK_AND_BLOOM)
            }

        }).catch(error => {
            notifyError(error);
        })
    }


    const handleRemove = (id_survey_question) => event => {
        HttpApi.postV2("/questions/deleteQuestion",{id:id_survey_question}).then((response) => {
            if(response.data === false && Roles.BACKOFFICE_DEMO_BACKOFFICE.some(el =>user.roles.includes(el))){
                setIdSurveyQuestionToDelete(id_survey_question);
                setShowConfirmDelete(true);
            }else if(response.data === true){
                createSuccessfullyDeletedNotification("Click&Bloom", 1000);
                fetchQuestionsForWeek(days.monday, days.friday);
                fetchNumberFutureQuestions()
            }else{
                createErrorNotification("Error !", "You only can delete future Click & Blooms");
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const handleCloseModalFreeTrial = () => {
        setShowModalFreeTrial(false);
    }

    const handleOpenModalFreeTrial = () => {
        setShowModalFreeTrial(true);
        setAnchorPlanner(null);
    }

    const handleOpenClearModal = () => {
        setShowClearModal(true);
    }

    const handleCloseClearModal = () => {
        setShowClearModal(false);
    }

    const handleClearPlanner = () => {
        let url = "/questions/deleteAllFutureQuestions"
        if(selectedEntity){
            url = "/questions/deleteAllFutureQuestions/"+selectedEntity
        }
        HttpApi.postV2(url).then(() => {
            notifySuccess("Your planner has successfully been cleared");
            fetchQuestionsForWeek(days.monday, days.friday);
            fetchNumberFutureQuestions();
            fetchAutoPlanners()
        }).catch(error => {
            notifyError(error);
        })
    }

    const handleConfirmDelete = () => {
        if(Roles.BACKOFFICE_DEMO_BACKOFFICE.some(el => user.roles.includes(el))){
            HttpApi.postV2("/questions/confirmDeleteQuestion", {id:idSurveyQuestionToDelete}).then(() => {
                createSuccessfullyDeletedNotification("Click&Bloom", 1000);
                fetchQuestionsForWeek(days.monday, days.friday);
                setShowConfirmDelete(false);
                setIdSurveyQuestionToDelete(-1);
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const handleClick = (day) => event => {
        setAnchorPlanner(event.currentTarget);
        if(day !== selectedDay){
            if(teamsAvailable.length === teams.length){
                setSelectedTeams([-1])
            }else{
                setSelectedTeams([])
            }
        }
        if(teamsAvailable.length !== teams.length){
            setSelectedTeams([])
        }
        setSelectedDay(day);
    };

    const handleClose = () => {
        setAnchorPlanner(null);
    };

    const handleNext = (fromToday) => {
        if(fromToday){
            const newMonday = moment().startOf("week").add(1, "day").add(1,"week");
            const newTuesday =  moment().startOf("week").add(2, "day").add(1,"week");
            const newWednesday =  moment().startOf("week").add(3, "day").add(1,"week");
            const newThursday = moment().startOf("week").add(4, "day").add(1,"week");
            const newFriday = moment().startOf("week").add(5, "day").add(1,"week");
            handlePublicHolidays(newMonday, newFriday);

            setDays({monday:newMonday, tuesday: newTuesday, wednesday: newWednesday, thursday: newThursday, friday: newFriday});

            fetchQuestionsForWeek(newMonday, newFriday);
            fetchNumberFutureQuestions()
        }else{
            const newMonday = days.monday.add(1, "week");
            const newTuesday = days.tuesday.add(1, "week");
            const newWednesday = days.wednesday.add(1, "week");
            const newThursday = days.thursday.add(1, "week");
            const newFriday = days.friday.add(1, "week");

            handlePublicHolidays(newMonday, newFriday);

            setDays({monday:newMonday, tuesday: newTuesday, wednesday: newWednesday, thursday: newThursday, friday: newFriday});

            fetchQuestionsForWeek(newMonday, newFriday);
            fetchNumberFutureQuestions()
        }
    };

    const handlePrevious = () => {
        const newMonday = days.monday.subtract(1, "week");
        const newTuesday = days.tuesday.subtract(1, "week");
        const newWednesday = days.wednesday.subtract(1, "week");
        const newThursday = days.thursday.subtract(1, "week");
        const newFriday = days.friday.subtract(1, "week");

        handlePublicHolidays(newMonday, newFriday);

        setDays({monday: newMonday, tuesday: newTuesday, wednesday: newWednesday, thursday: newThursday, friday: newFriday});

        fetchQuestionsForWeek(newMonday, newFriday);
        fetchNumberFutureQuestions()
    };

    const handlePublicHolidays = (monday, friday) => {
        const year = moment(monday).get("year");
        const otherYear = moment(friday).get("year");

        if(year === otherYear){
            setHolidays(publicHolidays(year));
        }else{
            const ph= publicHolidays(year).concat(publicHolidays(otherYear));
            setHolidays(ph);
        }
    }

    const handleToday = () => {
        const newMonday = moment().startOf("week").add(1, "day");
        const newTuesday =  moment().startOf("week").add(2, "day");
        const newWednesday =  moment().startOf("week").add(3, "day");
        const newThursday = moment().startOf("week").add(4, "day");
        const newFriday = moment().startOf("week").add(5, "day");

        handlePublicHolidays(newMonday, newFriday);

        setDays({monday: newMonday, tuesday: newTuesday, wednesday: newWednesday, thursday: newThursday, friday: newFriday});

        fetchQuestionsForWeek(newMonday, newFriday);
        fetchNumberFutureQuestions(newMonday);

    };

    const handleCheckboxTeams = (idTeam) => {
        if(idTeam === -1){
            setSelectedTeams([-1])
        }else{
            if(selectedTeams.includes(idTeam)){
                setSelectedTeams(selectedTeams.filter(el => el !== idTeam && el !== -1))
            }else{
                setSelectedTeams([...selectedTeams.filter(el => el !== -1),idTeam])
            }
        }

    }

    const handleCheckbox = (idEntity) => {
        if(entitiesSelected.includes(idEntity)){
            setEntitiesSelected(entitiesSelected.filter(el => el !== idEntity))
        }else{
            setEntitiesSelected([...entitiesSelected,idEntity])
        }
    }

    const handleSelectAllEntities = () => {
        if(selectedAllEntities){
            setEntitiesSelected([])
        }else{
            setEntitiesSelected(entities.map(el => el.id))
        }
        setSelectedAllEntities(!selectedAllEntities)
    }

    const handleIdQuestionModal = (idSurvey,open) => {
        setIdSurveyModal(idSurvey);
        setShowModalQuestion(open)
    }

    const fetchEntities = () => {
        const url = "/companies/findAllEntitiesFromGroup/"+ companyInfo.id
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setEntities(response.data)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchNbCB = () => {

        let url ;

        if(isSuperAdmin && (!isNullOrUndefined(selectedEntity))){
            url = "/settings/group/getForCompany/"+SettingTypes.NB_CB+"/"+selectedEntity
        }else{
            url = "/settings/getForCompany/"+SettingTypes.NB_CB;
        }
        if((isSuperAdmin && (!isNullOrUndefined(selectedEntity))) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setMaxCB(response.data.value);
                }else{
                    setMaxCB(10);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }
    const fetchAutoPlanners = () => {
        setLoading(true)
        let url = "/frequencies/autoPlanner/getAll"
        if(!!selectedEntity){
            url += "/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data && response.data.length > 0){
                let usedPlaylistsId = []
                for (let i = 0; i < response.data.length; i++) {
                    if(response.data[i].id_playlist !== -1){
                        if(!usedPlaylistsId.includes(response.data[i].id_playlist)){
                            usedPlaylistsId.push(response.data[i].id_playlist)
                        }
                    }
                }
                fetchFrequencies()
                handleToday()
                fetchNumberFutureQuestions()
                setUsedPlaylists(usedPlaylistsId)
                setAutoPlanners(response.data);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchFrequencies = () => {
        let url = "/frequencies/getAll"
        if(!!selectedEntity){
            url += "/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setFrequencies(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }
    const handleCloseModalConfirmDelete = () => {
        setShowConfirmDelete(false);
    }

    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(true === open){
            handleClose();
            if(toEdit > 0){
                setPlaylistToReload(toEdit);
                fetchForPlaylist(toEdit);
            }else{
                setPlaylistToReload(0);
                fetchQuestions();
            }
        }else {
            setSideFormOpen(open);
        }
    };


    const toggleDrawerTeams = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setIdQuestionTeams(null)
        }
        if(teamsAvailable.length === teams.length){
            setSelectedTeams([-1])
        }else{
            setSelectedTeams([])
        }

        setSideFormTeams(open);
    };

    const toggleDrawerEntities = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSideFormEntities(open);
    };

    const determineSourceOfQuestion = (question) => {
        if(isStarter){
            return null
        }
        if(companyInfo){ //Super Admin
            if(question.id_company === companyInfo.id && groupSelected){
                return (
                    <span className={"font-semibold text-contrast-blue"}>
                    {"#" + question.survey_entities.map(el => el.name).join(", ")}
                </span>
                )
            }
            if((question.id_company === (!!companyToEdit ? companyToEdit.id : user.companyId))&& !groupSelected) {
                if (question.survey_teams.length > 0) {
                    return (
                        <span className={"font-semibold text-contrast-blue"}>
                    {"#" + question.survey_teams.map(el => el.name).join(", ")}
                </span>
                    )
                } else {
                    return (
                        <span className={"font-semibold text-contrast-blue"}>
                    {"#"+_.upperFirst(t('core.allBis'))}
                </span>
                    )
                }
            }else {
                return (
                    <span className={"text-black font-normal"}>
                        {_.upperFirst(t("webloom.from")) + " "}
                        <span className={"font-semibold text-contrast-purple"}>{question.company_name}</span>
                    </span>
                )
            }
        } else { // Company
            if(question.id_company !== (!!companyToEdit ? companyToEdit.id : user.companyId)){
                return (
                    <span className={"text-black font-normal"}>
                        {_.upperFirst(t("webloom.from")) + " "}
                        <span style={
                            {color: user.companyGroup ? user.companyGroup.colors.primary : Colors.EBLOOM_CONTRAST_PURPLE
                            }} className={"font-semibold"}>{!!companyToEdit ?companyToEdit.company_group?.name : user.companyGroup.name}</span>
                    </span>
                )
            }
            if (question.survey_teams.length > 0) {
                return (
                    <div>
                        <span className={"font-semibold text-contrast-blue"}>
                    #{question.survey_teams.map((el,index) => (
                                <span
                                    className={"font-semibold " + (!!el.deleted_at ? "text-disabled-grey" : "text-contrast-blue")}>{el.name + (index === question.survey_teams.length-1 ?"" :", ")}</span>
                        ))}
                </span>
                    </div>

                )
            } else if (question.recipients === "all") {
                return (
                    <span className={"font-semibold text-contrast-blue"}>
                    {"#" + _.upperFirst(t('core.allBis'))}
                </span>
                )
            } else return null

        }

    }

    const checkCompanyToEdit = () => {
        if (!!companyToEdit) {
            if (companyToEdit.type !== "group") {
                setCompanyInfo(null)
                setGroupSelected(false)
            } else {
                if (!!companyToEdit.company_group) {
                    const data = {
                        id : companyToEdit.id,
                        name : companyToEdit.name
                    }
                    setCompanyInfo(data)
                    setGroupSelected(false)
                }else if(companyToEdit.companies_entities.length > 0){
                    const data = {
                        id : companyToEdit.id,
                        name : companyToEdit.name
                    }
                    setCompanyInfo(data)
                    setGroupSelected(true)
                }
            }

        }else{
            if(user.isSuperAdmin && user.companyGroup !== null && selectedEntity !== null) {
                const data = {
                    id : user.companyGroup.id,
                    name : user.companyGroup.name
                }
                setCompanyInfo(data)
                setGroupSelected(selectedEntity === data.id)
            }
        }
    }

    const checkIfLimitExceeded = (day) => {
        //Check if it's not free or if it's free and the date is between the free period
        if(!isFree || (days[day].isSameOrAfter(startDateFreeAdmin) && days[day].isBefore(endDateFreeAdmin))){
            //Check if the number of CB is not exceeded or if it's unlimited
            if((user.roles.some(el => [...Roles.SUPER_ADMIN].includes(el)) && !!selectedEntity && selectedEntity === user.companyGroup?.id) || (!!companyToEdit && companyToEdit.type === "group")){
                let plannedCB = []
                Object.entries(week).forEach(([day, questions]) => {
                    plannedCB = plannedCB.concat(questions)
                })
                //Check if all entities has planned CB length < maxCB if true add it to list
                let entitiesLimitNotExceeded = []
                const entitiesData = !!companyToEdit ? companyToEdit.companies_entities : entities
                entitiesData.forEach(entity => {
                    let entityCB = plannedCB.filter(el => el.survey_entities.some(el2 => el2.id_company === entity.id)).length;
                    if(entityCB < maxCB){
                        entitiesLimitNotExceeded.push(entity)
                    }
                })
                if(entitiesLimitNotExceeded.length > 0 && user.roles.some(el => Roles.BACKOFFICE_DEMO_BACKOFFICE.includes(el))){
                    return true
                }
                if(entitiesLimitNotExceeded.length > 0 && days[day].isAfter(moment().format())){
                    return true
                }

            }else{
                let plannedCB = []
                Object.entries(week).forEach(([day, questions]) => {
                    plannedCB = plannedCB.concat(questions)
                })
                const cbToAll = plannedCB.filter(el => el.recipients === SurveyQuestionRecipients.ALL).length;
                if(cbToAll < maxCB){
                    if(user.roles.some(el => Roles.BACKOFFICE_DEMO_BACKOFFICE.includes(el))){
                        return true
                    }
                    if(days[day].isAfter(moment().format())){
                        //Check if the user is an admin or super admin
                        if(user.roles.some(el => [...Roles.ADMIN].includes(el))){
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    const updateTeamsAvailableForPlanner = () => {
        // Gather all questions planned for the week into a single array
        let questionsPlanned = Object.values(week).flat();

        if (questionsPlanned.length === 0) {
            // If no questions are planned, all teams are available
            setTeamsAvailable(teams);
        } else {
            let teamsAvailable = [];
            let teamsNotAvailable = [];

            teams.forEach(team => {
                // Count the number of CBs for this team specifically
                const teamCB = questionsPlanned.filter(
                    el => el.survey_teams?.some(el2 => el2.id_team === team.id)
                ).length;

                // Count the number of CBs where recipients are set to ALL
                const teamCBAll = questionsPlanned.filter(
                    el => el.recipients === SurveyQuestionRecipients.ALL
                ).length;

                // Check if the total CBs for the team are below the maximum allowed (maxCB)
                if ((teamCB + teamCBAll) < maxCB) {
                    teamsAvailable.push(team);
                } else {
                    teamsNotAvailable.push(team);
                }
            });

            // Update selected teams, filtering out teams that are not available
            if (teamsNotAvailable.length > 0) {
                setSelectedTeams(prevState =>
                    prevState.filter(el =>
                        !teamsNotAvailable.some(team => team.id === el)
                    )
                );
            }

            // Update the available teams state
            setTeamsAvailable(teamsAvailable);
        }
    };

    const updateEntitiesAvailableForPlanner = () => {
        // Gather all questions planned for "ENTITIES" recipients throughout the week
        let questionsPlanned = Object.values(week)
            .flat()
            .filter(el => el.recipients === SurveyQuestionRecipients.ENTITIES);

        if (questionsPlanned.length === 0) {
            // If no "ENTITIES" questions are planned, all entity IDs are available
            setEntitiesAvailable(entities.map(el => el.id));
        } else {
            let entitiesAvailable = [];
            entities.forEach(entity => {
                // Count the number of CBs specifically for this entity
                const entityCB = questionsPlanned.filter(
                    el => el.survey_entities?.some(el2 => el2.id_company === entity.id)
                ).length;
                // Add entity to available list if CB count is below maxCB
                if (entityCB < maxCB) {
                    entitiesAvailable.push(entity.id);
                }
            });

            // Update the available entities state
            setEntitiesAvailable(entitiesAvailable);
        }
    };




    //const displayCBLeft =  maxCB === "unlimited" || maxCB === 1000 ? t("core.unlimited") : (maxCB);

    useEffect(() => {
        checkCompanyToEdit()
        if(user.roles.some(el => [...Roles.ADMIN_BACKOFFICE,Roles.DEMO_BACKOFFICE].includes(el))){
            fetchTeams()
        }
        fetchPlaylists();
        fetchQuestionsForWeek(days.monday, days.friday,Boolean(isDate));
        fetchNbCB();
        fetchNumberFutureQuestions()
        fetchModelCustomSetting()
        if(isFree){
            fetchEndDateFree()
        }
        let url = new URL(window.location.href);
        let productTour = url.searchParams.get("productTour");
        if(productTour && productTour === "planner"){
              setTourStarted(true);
        }
    }, [companyToEdit,selectedEntity])

    useEffect(() => {
        if(companyInfo && companyInfo.id){
            fetchEntities()
        }
    }, [companyInfo]);

    useEffect(() => {
        if((isSuperAdmin && !!selectedEntity && selectedEntity === user.companyGroup?.id )|| (!!companyToEdit && companyToEdit.type === "group")){
            updateEntitiesAvailableForPlanner()
        }else{
            updateTeamsAvailableForPlanner()
        }

    }, [week,teams]);

    return(
        <>
            <PlanClickAndBloomTour handleNextWeek={handleNext} handleToday={handleToday}/>
            <CardDefault showButton={false} subtitle={isFree || isStarter ? _.upperFirst(t("admin.plannerHelperFree")) : _.upperFirst(t("admin.plannerHelper"))} title={_.upperFirst(t("core.playlist.planner"))} tourTooltip={true} tourType={TourType.PLAN_CLICK_AND_BLOOM} >
                <Row className={"mb-10 flex justify-center items-center"}>
                    <div className={"w-full text-center"}>
                        <AutoPlannersList selectedEntity={selectedEntity} playlists={playlists} teams={teams}
                                          handleToday={handleToday}
                                          fetchNumberFutureQuestions={fetchNumberFutureQuestions}
                                          isFree={isFree}
                                          handleOpenModalFree={() => handleOpenModalFreeTrial()}
                                          isStarter={isStarter}
                                          fetchAutoPlanners={fetchAutoPlanners}
                                          autoPlanners={autoPlanners}
                                          frequencies={frequencies}
                                          usedPlaylists={usedPlaylists}
                                          loading={loading}
                        />
                    </div>
                </Row>
                <Row>
                    <div className={"w-full flex justify-between items-center"}>
                        <div data-planCB={'navigate'} className={"ml-20"}>
                            {
                                (!isFree || moment(days.monday).subtract(2, "days").isAfter(startDateFreeAdmin)) &&
                                <KeyboardArrowLeftIcon className={"pointer text-purple"} onClick={handlePrevious}/>
                            }
                            <Button variant={"link"} className={"ebloom-link-purple"}
                                    style={{marginLeft: (!isFree || moment(days.monday).subtract(2, "days").isAfter(startDateFreeAdmin)) ? 0 : 24}}
                                    onClick={handleToday}>{_.capitalize(t("core.day.today"))}</Button>
                            {
                                (!isFree || moment(days.friday).add(2, 'days').isBefore(endDateFreeAdmin)) &&
                                <EbloomTooltip
                                    text={_.upperFirst(t("admin.nbCBScheduled", {param: nbFutureQuestions.futureNextWeek.toString()}))}
                                    placement="top">
                                    <KeyboardArrowRightIcon className={"pointer text-purple"}
                                                            onClick={() => handleNext(false)}/>
                                </EbloomTooltip>
                            }
                        </div>
                        <div data-planCB={'clearPlanner'} className={"right mr10"}><span
                            className={"ebloom-link-purple"}
                            onClick={handleOpenClearModal}>{_.upperFirst(t("admin.emptyPlanner"))}</span></div>
                    </div>
                </Row>
                <Row md={5} className={"w-full m-auto"}>
                    {
                        Object.entries(week).map(([day, questions], index) => {
                            const isEnabled = (!isFree || (days[day].isSameOrAfter(startDateFreeAdmin) && days[day].isBefore(endDateFreeAdmin)));
                            const isAfterFree = (isFree && days[day].isSameOrAfter(endDateFreeAdmin));
                            return (
                                <Col className={"p-2"} key={index}>
                                    <Card
                                        style={{borderColor: isEnabled ? Colors.EBLOOM_CONTRAST_PURPLE : Colors.EBLOOM_DISABLED_GREY}}>
                                        <EbloomTooltip text={_.upperFirst(t("admin.publicHolidayTooltip"))}
                                                       placement={"top"}
                                                       show={holidays.includes(days[day].format("YYYY-MM-DD"))}>
                                            <Card.Header style={{
                                                backgroundColor: isEnabled ? Colors.EBLOOM_CONTRAST_PURPLE : Colors.EBLOOM_DISABLED_GREY,
                                                color: isEnabled ? "white" : "grey",
                                                textAlign: "center",
                                                fontFamily: "Lato",
                                                fontWeight: days[day].format("DD/MM") === today ? "bold" : "normal",
                                                fontSize: days[day].format("DD/MM") === today ? 15 : 14,
                                                opacity:  holidays.includes(days[day].format("YYYY-MM-DD")) ? 0.70 : 1
                                            }}>
                                                <span
                                                    className={"cursorDefault"}
                                                    style={{opacity: days[day].format("DD/MM") === today ? 1 : 0.75}}>{_.capitalize(t("core.day." + day))}</span>
                                                <span className={"cursorDefault ml5"} style={{
                                                    opacity: 1,
                                                    fontSize: days[day].format("DD/MM") === today ? 17 : 16
                                                }}>{days[day].format("DD/MM")}</span>
                                            </Card.Header>
                                        </EbloomTooltip>
                                        <Card.Body style={{minHeight: 66.67}}>
                                            <ListGroup variant={"flush"} style={{textAlign: "center"}}>
                                                {
                                                    questions.map((question, index) => (
                                                        <EbloomTooltip text={_.upperFirst(t("core.availableUntil", {date:moment(getEndDateAccordingToWindow(days[day].format("YYYY-MM-DD"), question.opening_time)).locale(getLanguage()).format("dddd DD/MM")}) + (groupSelected || !!companyInfo ?  "\n " + _.upperFirst(t("core.plannedBy", {company : question.company_name})) : ""))} show={(questions && questions.length > 0)} placement={"top"}>
                                                            <ListGroup.Item key={index}>
                                                                <div
                                                                    className={"text-center flex flex-col justify-center items-center"}>
                                                                    <div className={"flex justify-center"}>
                                                                        <CategoryTag
                                                                            modelCustomEnable={isModelCustomEnable}
                                                                            category={question} width={150}/>
                                                                    </div>
                                                                    <span className={"mt-5"}>{question.text}</span>

                                                                    <div className={"mt-10 text-center flex justify-center flex-col items-center"}>
                                                                        {determineSourceOfQuestion(question)}

                                                                        {
                                                                            !isStarter && question?.enableIdentityVersion === true &&
                                                                            <IconButton className={"text-purple ml-5"} onClick={() => handleIdQuestionModal(question.id_survey_question,true)} >
                                                                                <TypeSpecimenIcon/>
                                                                            </IconButton>
                                                                        }

                                                                    </div>

                                                                    {
                                                                        ((moment(question.date).isAfter(moment().format()) && ((groupSelected && question.id_company === companyInfo?.id) || (!groupSelected && question.id_company === user.companyId))) || (Roles.BACKOFFICE_DEMO_BACKOFFICE.some(el => user.roles.includes(el)))) &&
                                                                        <div className={"flex justify-center"}>
                                                                            <RemoveCircleOutlineIcon
                                                                                style={{marginTop: 20}}
                                                                                className={"ebloom-add-btn-purple"}
                                                                                onClick={handleRemove(question.id_survey_question)}/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </ListGroup.Item>
                                                        </EbloomTooltip>
                                                    ))
                                                }
                                                {
                                                    checkIfLimitExceeded(day,questions) &&
                                                    <ListGroup.Item style={{
                                                        paddingTop: questions.length === 0 ? 0 : 15,
                                                        paddingBottom: 0
                                                    }}>
                                                        <AddCircleOutlineIcon
                                                            data-planCB={'plus'}
                                                            className={"ebloom-add-btn-purple"}
                                                            aria-controls='planner-menu'
                                                            aria-haspopup="true"
                                                            onClick={handleClick(day)}/>
                                                    </ListGroup.Item>
                                                }
                                                {
                                                    isAfterFree &&
                                                    <div style={{fontSize:12}}>
                                                        <p>{_.upperFirst(t("free.outOfPlannerText"))}</p>
                                                        <Button className={"ebloom-btn-purple"} size={"sm"} onClick={handleOpenModalFreeTrial}>{_.upperFirst(t("free.subscribe"))}</Button>
                                                    </div>
                                                }
                                            </ListGroup>
                                        </Card.Body>

                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </CardDefault>

            <SideForm toggleDrawer={toggleDrawerEntities} buttonDisabled={entitiesSelected.length === 0} sideFormOpen={sideFormEntities} handleSave={()=> handleAddGroup()} formTitle={_.upperFirst(t("admin.plannerGroupTitle"))}>
                <div className={"flex flex-col justify-center items-start"}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={entities.length !== entitiesAvailable.length}
                                className={entities.length !== entitiesAvailable.length ? "checkbox-disabled" :"checkbox-purple"}
                                checked={entities.length !== entitiesAvailable.length ? false : selectedAllEntities}
                                onChange={entities.length !== entitiesAvailable.length ? () => {} : handleSelectAllEntities}
                            />
                        }
                     label={ _.upperFirst(t("core.allEntities"))}
                    />
                    {
                        entities?.map((item, index) => {
                            return (
                                <FormControlLabel
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            disabled={!entitiesAvailable.includes(item.id)}
                                            className={!entitiesAvailable.includes(item.id) ? "checkbox-disabled" :"checkbox-purple"}
                                            checked={!entitiesAvailable.includes(item.id) ? false : entitiesSelected.includes(item.id)}
                                            onChange={!entitiesAvailable.includes(item.id) ? () => {} : () => handleCheckbox(item.id)}
                                        />
                                    }
                                    label={item.name}
                                />
                            )
                        })
                    }
                </div>
            </SideForm>
            <SideForm toggleDrawer={toggleDrawerTeams} sideFormOpen={sideFormTeams} showButtons={true} handleSave={() => handleAdd()} formTitle={_.upperFirst(t("core.whichTeams"))} buttonDisabled={selectedTeams.length === 0} >
                {
                    <div className={"w-full flex justify-center items-center flex-col"}>
                        <div className={"flex flex-col justify-center items-start"}>
                            {
                                teams.length !== teamsAvailable.length ?
                                    <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.allCollaboratorsDisabled"))}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={teams.length !== teamsAvailable.length}
                                                    className={teams.length !== teamsAvailable.length? "checkbox-disabled" : "checkbox-purple"}
                                                    checked={teams.length === teamsAvailable.length && selectedTeams.includes(-1)}
                                                    onChange={teams.length !== teamsAvailable.length ? () => {} : () => handleCheckboxTeams(-1)}
                                                />
                                            }
                                            label={_.upperFirst(t("core.allCollaborators"))}
                                        />
                                    </EbloomTooltip>
                                    :
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={teams.length !== teamsAvailable.length}
                                                className={teams.length !== teamsAvailable.length? "checkbox-disabled" : "checkbox-purple"}
                                                checked={teams.length === teamsAvailable.length && selectedTeams.includes(-1)}
                                                onChange={teams.length !== teamsAvailable.length ? () => {} : () => handleCheckboxTeams(-1)}
                                            />
                                        }
                                        label={_.upperFirst(t("core.allCollaborators"))}
                                    />
                            }
                        </div>
                        <Divider flexItem className={" px-10 my-20 "}>{_.upperCase(t("prospect.or"))}</Divider>
                        <div className={"flex flex-col justify-center items-start"}>
                            {
                                teams?.map((item, index) => {
                                    if(!teamsAvailable.map(el => el.id).includes(item.id)){
                                        return (
                                            <EbloomTooltip text={_.upperFirst(t("admin.autoPlanner.teamsDisabled"))}>
                                                <FormControlLabel
                                                    key={item.id}
                                                    control={
                                                        <Checkbox
                                                            disabled={!teamsAvailable.map(el => el.id).includes(item.id)}
                                                            className={!teamsAvailable.map(el => el.id).includes(item.id) ? "checkbox-disabled" :"checkbox-purple"}
                                                            checked={selectedTeams.includes(item.id)}
                                                            onChange={() => handleCheckboxTeams(item.id)}
                                                        />
                                                    }
                                                    label={<div className={"flex flex-row items-center"}>
                                                        <div className={"mr-10"}>
                                                            {item.name}
                                                        </div>
                                                        {
                                                            item.isValid !== "valid" &&
                                                            <div>
                                                                <EbloomTooltip text={_.upperFirst(t("core.invalidTeam"))}>
                                                                    <AlertIcon className={"h-20 w-20 red-icon"}/>
                                                                </EbloomTooltip>
                                                            </div>
                                                        }

                                                    </div>}
                                                    className={""}
                                                />
                                            </EbloomTooltip>
                                        )
                                    }
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            control={
                                                <Checkbox
                                                    disabled={!teamsAvailable.map(el => el.id).includes(item.id)}
                                                    className={!teamsAvailable.map(el => el.id).includes(item.id) ? "checkbox-disabled" :"checkbox-purple"}
                                                    checked={selectedTeams.includes(item.id)}
                                                    onChange={() => handleCheckboxTeams(item.id)}
                                                />
                                            }
                                            label={<div className={"flex flex-row items-center"}>
                                                <div className={"mr-10"}>
                                                    {item.name}
                                                </div>
                                                {
                                                    item.isValid !== "valid" &&
                                                    <div>
                                                        <EbloomTooltip text={_.upperFirst(t("core.invalidTeam"))}>
                                                            <AlertIcon className={"h-20 w-20 red-icon"}/>
                                                        </EbloomTooltip>
                                                    </div>
                                                }

                                            </div>}
                                            className={""}
                                        />
                                    )
                                })
                            }
                        </div>

                    </div>
                }

            </SideForm>
            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} showButtons={false}
                      formTitle={"core.add"}>
                <QuestionList companyToEdit={companyToEdit} questions={questions} handleAction={handleAddDrawers}
                              buttonType={"add"} showLastUsed entityId={selectedEntity}/>
            </SideForm>
            <Menu id="planner_menu"
                  anchorEl={anchorPlanner}
                  keepMounted
                  open={Boolean(anchorPlanner)}
                  onClose={handleClose}
            >
                <List subheader={
                    <ListSubheader>{_.upperFirst(t("core.addParam", {param: "Click&Bloom"}))}</ListSubheader>}>
                    <MenuItem key={1} onClick={isFree ? handleOpenModalFreeTrial : toggleDrawer(true, 0)}>
                        <ListItemIcon><LibraryIcon/></ListItemIcon>
                        <ListItemText primary={_.capitalize(t('core.playlist.library'))}/>
                        {
                            isFree &&
                            <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                        }
                    </MenuItem>
                    {
                        playlists.map((playlist, index) => (
                            <MenuItem key={index + 1} onClick={toggleDrawer(true, playlist.id)}>
                                <ListItemIcon><PlaylistIcon/></ListItemIcon>
                                <ListItemText primary={playlist.name}/>
                            </MenuItem>
                        ))
                    }
                </List>
            </Menu>

            <ConfirmModal handleClose={handleCloseModalConfirmDelete} show={showConfirmDelete} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("core.delete"))} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperCase(t("core.confirm"))} handleChange={handleConfirmDelete}>
                <p>Attention ! Il y a plusieurs feedback associés à ce Click&Bloom. Le supprimer entrainera également leur suppression.</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseClearModal} show={showClearModal} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("admin.emptyPlanner"))} handleChange={handleClearPlanner} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>{_.upperFirst(t("admin.emptyPlannerConfirm", {param:nbFutureQuestions.future.toString()}))}</p>
            </ConfirmModal>
            <FreeTrialModal show={showModalFreeTrial} handleClose={handleCloseModalFreeTrial} text={""}/>
            <IdentityQuestionModal show={showModalQuestion} handleClose={() => handleIdQuestionModal(null,false)} id_survey={idSurveyModal}/>
        </>
    )
}

export default Planner;