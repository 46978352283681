import React from "react";
import PropTypes from "prop-types"
import {ReactComponent as PeopleIcon} from "../../symbols/webloom/white/people-white.svg";
import {ReactComponent as PersonIcon} from "../../symbols/user-solo-white.svg";

const EbloomSwitch = (props) => {

    //state

    //props
    const {handleChange, selected} = props;

    //hooks

    //getters

    //posters

    //handlers

    //useEffect

    return (
        <>
            <div className={"h-35 w-85 bg-bg-white/[0.8] rounded-3xl flex justify-between items-center p-5 pointer relative"} onClick={handleChange}>
                <div className={`absolute bg-bg-white transition-all duration-100 ${
                    selected ? 'translate-x-40' : 'left-0'
                } h-35 w-42 border border-purple rounded-3xl`}/>
                <PeopleIcon className={"w-25 h-25 z-10 " + (selected ? "contrast-purple-icon" : "purple-icon")}/>
                <PersonIcon className={"w-25 h-25 z-10 " + (selected ? "purple-icon" : "contrast-purple-icon")}/>
            </div>
        </>
    )
}

EbloomSwitch.propTypes = {
    selected:PropTypes.bool.isRequired,
    handleChange:PropTypes.func.isRequired
}

EbloomSwitch.defaultProps = {}
export default EbloomSwitch