import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {createSuccessfullyUpdatedNotification, notifySuccess} from "../../../../../Utils/Notification";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {ListGroup, Col, Row, Button} from "react-bootstrap";
import _ from "lodash";
import {TextField} from "@mui/material";
import {useTranslation} from "react-multi-lang/lib";
import { PurpleSwitch } from "../../../../../Utils/Global";

const NotificationEditor = (props) => {
    const [integrations, setIntegrations] = useState([]);
    const [webhookTeams, setWebhookTeams] = useState("");

    const t = useTranslation();

    const handleChange = (event) => {
        const value = event.target.checked;
        const name = event.target.name;
        HttpApi.put("/integrations/switchActiveState",{type:name, active:value}).then(() => {
            createSuccessfullyUpdatedNotification("active state");
            fetchIntegrationState();
        })
    };

    const handleChangeWebhook = (event) => {
        setWebhookTeams(event.target.value);
    }


    const fetchIntegrationState = () => {
        HttpApi.getV2("/integrations/getAll").then(response => {
            if(response && response.data){
                setIntegrations(response.data);
                setWebhookTeams(response.data.find(el => el.type === 'teams')?.webhook_url || "");
            }else{
                setIntegrations([]);
                setWebhookTeams("");
            }
        })
    };

    const saveTeamsCredentials = () => {
        const url = integrations.some(el => el.type === 'teams') ? "/integrations/updateTeamsCredentials" : "/integrations/saveTeamsCredentials";
        const data = integrations.some(el => el.type === 'teams') ? {id:integrations.find(el => el.type === 'teams')?.id, webhook_url:webhookTeams} : {webhook_url: webhookTeams};
        const HttpCall = integrations.some(el => el.type === 'teams') ? HttpApi.put : HttpApi.postV2
       HttpCall(url, data).then(() => {
            fetchIntegrationState();
            notifySuccess("Credentials successfully updated");
        })
    }

    useEffect(() => {
        fetchIntegrationState();
    },[props.companyToEdit]);

    return(
        <div>
            <CardDefault title={"Notifications"} showButton={false}>
                <ListGroup variant={"flush"}>
                    {
                        integrations?.map((integration, index) => (
                            <ListGroup.Item>
                                <Row>
                                    <Col>
                                        {_.capitalize(integration.type)}
                                    </Col>
                                    <Col>
                                        <PurpleSwitch checked={integration.active} onChange={handleChange} name={integration.type}/>
                                    </Col>
                                </Row>

                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </CardDefault>
            <CardDefault title={"Microsft Teams"} showButton={false}>
                <TextField fullWidth label="Webhook" variant="outlined" value={webhookTeams} onChange={handleChangeWebhook} />
                <Button className={"ebloom-btn-purple mt-10 right"} onClick={saveTeamsCredentials}>{_.upperFirst(t("core.save"))}</Button>
            </CardDefault>
        </div>

    )

}

export default NotificationEditor