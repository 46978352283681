import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useUserData} from "../../../../hooks/userHook";
import {CompanyTypes} from "../../../../Utils/Global";
import {useTranslation} from "react-multi-lang";
import moment from "moment/moment";
import _ from "lodash";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {Colors} from "../../../../Utils/Colors";
import EbloomPopover from "../Popovers-Tooltips/EbloomPopover";
import {Col, Row} from "react-bootstrap";
import ButtonUI from "@mui/material/Button";
import EbloomDatePicker from "./EbloomDatePicker";

const EbloomTimeFilter = (props) => {
    const user = useUserData();

    const {startDate, endDate, handleSwitchStartDate, handleSwitchEndDate,colorsGroup} = props;
    const [selectedPreset, setSelectedPreset] = useState((user.companyType === CompanyTypes.DEMO ? 6 : 3));
    const [month, setMonth] = useState(new Date());

    const t = useTranslation();

    const {bp,isProspect,isDashboardSuperAdmin,isAllEntities,entitiesToDisplay,entitiesDashGroup} = props;

    const handlePresetDate = (number) => event => {
        setSelectedPreset(number);
        switch (number){
            case 1:
                handleSwitchStartDate(new Date(moment().subtract(1, "month").format()));
                handleSwitchEndDate(new Date(moment().format()));
                setMonth(new Date());
                break;
            case 3:
                handleSwitchStartDate(new Date(moment().subtract(3, "month").format()));
                handleSwitchEndDate(new Date(moment().format()));
                setMonth(new Date());
                break;
            case 6:
                handleSwitchStartDate(new Date(moment().subtract(6, "month").format()));
                handleSwitchEndDate(new Date(moment().format()));
                setMonth(new Date());
                break;
            case 12:
                handleSwitchStartDate(new Date(moment().subtract(1, "year").format()));
                handleSwitchEndDate(new Date(moment().format()));
                setMonth(new Date());
                break;
            case 0:
                const startDate = new Date(moment("1996-05-15").format("YYYY-MM-DD"))
                const endDate = new Date(moment().format("YYYY-MM-DD"));
                handleSwitchStartDate(startDate);
                handleSwitchEndDate(endDate, true);
                setMonth(new Date());
                break;
            default:
                handleSwitchStartDate(new Date(moment().subtract(3, "month").format()));
                handleSwitchEndDate(new Date(moment().format()));
                setMonth(new Date());
                break;
        }
    }


    const textPreset = selectedPreset === 1 ? _.capitalize(t("core.lastMonth")) : selectedPreset === 3 ? _.capitalize(t("core.lastThreeMonth")) : selectedPreset === 6 ? _.capitalize(t("core.lastSixMonth")) : selectedPreset === 12 ? _.capitalize(t("core.lastYear")) : _.capitalize(t("core.fromStart"));

    const triggerText = bp.pageSize < bp.breakpoints.tablet || (isDashboardSuperAdmin && isAllEntities && entitiesToDisplay && entitiesToDisplay.length === 1 && entitiesToDisplay[0].id !== user.companyGroup.id ) || (isDashboardSuperAdmin && !isAllEntities && entitiesDashGroup )  ?
        <DateRangeIcon style={{fill : colorsGroup ? colorsGroup.primary : Colors.EBLOOM_CONTRAST_PURPLE}}/> :
        selectedPreset !== -1 ? textPreset : (startDate && endDate) ? (startDate === endDate) ? moment(startDate).format("DD/MM/YY") : moment(startDate).format("DD/MM/YY") + " - " + moment(endDate).format("DD/MM/YY") : (startDate && !endDate) ? moment(startDate).format("DD/MM/YY") : _.capitalize(t("core.fromStart"));


    const createStyle = (arrayOfindex) => {
        let style = {color: colorsGroup !== null ? colorsGroup.secondary : Colors.EBLOOM_PURPLE, fontWeight:"normal", fontSize:14};
        if(arrayOfindex.includes(selectedPreset)) {
            style = {color:colorsGroup !== null ? colorsGroup.secondary : Colors.EBLOOM_PURPLE, fontWeight: "bold", fontSize:14, borderBottom:"2px solid " + (colorsGroup !== null ? colorsGroup.secondary :Colors.EBLOOM_PURPLE)}
        }
        return style;

    };

    return(
        <EbloomPopover triggerText={triggerText} triggerVariant={"white"} placement={"bottom"} title={"Time Filter"} {...props} width={bp.isTabletOrLess() ? 300 : 490} colors={colorsGroup}>
            {
                () => (
                    <>
                        <Row>
                            <Col md={5} style={{paddingRight:0, paddingLeft:bp.pageSize < bp.breakpoints.tablet ? 15 : 0}}>
                                <div>
                                    <ButtonUI className={"icon_button ebloom-text"} style={{margin:10}} onClick={handlePresetDate(1)}><span style={createStyle([1])}>{t("core.lastMonth")}</span></ButtonUI>
                                    <ButtonUI className={"icon_button ebloom-text"} style={{margin:10}} onClick={handlePresetDate(3)}><span style={createStyle([3])}>{t("core.lastThreeMonth")}</span></ButtonUI>
                                    {
                                        !isProspect &&
                                        <div>
                                            <ButtonUI className={"icon_button ebloom-text"} style={{margin:10}} onClick={handlePresetDate(6)}><span style={createStyle([6])}>{t("core.lastSixMonth")}</span></ButtonUI>
                                            <ButtonUI className={"icon_button ebloom-text"} style={{margin:10}} onClick={handlePresetDate(12)}><span style={createStyle([12])}>{t("core.lastYear")}</span></ButtonUI>
                                            <ButtonUI className={"icon_button ebloom-text"} style={{margin:10}} onClick={handlePresetDate(0)}><span style={createStyle([0])}>{t("core.fromStart")}</span></ButtonUI>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col md={7} className={"px-0"}>
                                <EbloomDatePicker
                                    futureDates={false}
                                    mode={"range"}
                                    startDate={startDate} endDate={endDate}
                                    handleStart={handleSwitchStartDate} handleEnd={handleSwitchEndDate}
                                    changePreset={setSelectedPreset} preset={selectedPreset}
                                    month={month}
                                    handleMonthChange={setMonth}
                                />
                            </Col>
                        </Row>
                    </>
                )
            }


        </EbloomPopover>
    )
}

EbloomTimeFilter.propTypes ={
    startDate:PropTypes.any,
    endDate:PropTypes.any,
    isProspect : PropTypes.bool,
    handleSwitchStartDate:PropTypes.func,
    handleSwitchEndDate:PropTypes.func,
    isDashboardSuperAdmin: PropTypes.bool,
    isAllEntities: PropTypes.bool,
    colorsGroup : PropTypes.object
}
export default EbloomTimeFilter;
