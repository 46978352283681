import React, {useState} from "react";
import HeaderBackoffice from './HeaderBackoffice'
import NavDrawerBackoffice from "./NavDrawerBackoffice";
import {Row} from "react-bootstrap";
import {Colors} from "../../../Utils/Colors";
import PropTypes from "prop-types";

const DisplayBackofficeView = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [companyToEdit, setCompanyToEdit] = useState(localStorage.getItem("ebloom-companyToEdit") ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")) : null);

    const switchCompany = (company) => {
        setCompanyToEdit(company)
    }

    const {bp, menu} = props;

    const drawMargin = menu !== "noMenu" && bp.pageSize > bp.breakpoints.tablet ? 240 : 0;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return(
        <div style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE, minHeight:"100vh"}}>
            <Row className={"mr-0"} style={{marginLeft:drawMargin}}>
                {
                    menu !== "noMenu" &&
                    <NavDrawerBackoffice companyToEdit={companyToEdit} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} {...props}/>
                }
                <HeaderBackoffice switchCompany={switchCompany} handleDrawerToggle={handleDrawerToggle} {...props}/>
            </Row>
            <Row className={"pt-134"} style={{width:`calc(100% - ${drawMargin}px)`,marginLeft:drawMargin}}>
                {props.children(companyToEdit)}
            </Row>
        </div>
    )

}

DisplayBackofficeView.defaultProps = {
    menu:"backoffice",
    isGlobalDatatopia:false
}

DisplayBackofficeView.propTypes = {
    menu:PropTypes.string,
    isGlobalDatatopia:PropTypes.bool,
}

export default DisplayBackofficeView;
