
const Log = {
    crud:{
        create:"/logs/create",
        getAll:"/logs/getAll"
    },

    id:{
        id:"id",
        type:"NUMBER",
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    code:{
        id:"code",
        type:"NUMBER",
        label:"core.code",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

   message:{
        id:"message",
        type:"TEXT",
        label:"core.message",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    path:{
        id:"path",
        type:"TEXT",
        label:"path",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false,
        }
    },

    controller:{
        id:"controller",
        type:"TEXT",
        label:"controller",
        table:{
            show:true,
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

    method:{
        id:"method",
        type:"TEXT",
        label:"method",
        table:{
            show:true,
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

    user:{
        id:"user",
        type:"TEXT",
        label:"user",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

    error:{
        id:"error",
        type:"TEXT",
        label:"error",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:false
        }
    },

    created_at:{
        id:"created_at",
        type:"DATETIME",
        label:"core.created_at",
        table:{
            show:true
        },
        list:{

        },
        form:{

        }
    },

    updated_at:{
        id:"updated_at",
        type:"DATETIME",
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },

    deleted_at:{
        id:"deleted_at",
        type:"DATETIME",
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{

        },
        form:{

        }
    },
};


export default Log;