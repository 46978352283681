import React from "react";
import PropTypes from "prop-types";
import EbloomSmileyProgressBar from "./EbloomSmileyProgressBar";
import {Colors} from "../../../../Utils/Colors";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import _ from "lodash";
import {useTranslation} from "react-multi-lang";

const EbloomAllSmileyProgressBar = (props) => {

    const {nbResponse0, nbResponse1, nbResponse2, nbResponse3, nbResponse4, nbResponse5, total, showNA} = props;

    const t = useTranslation();

    return(
        <>
            <EbloomSmileyProgressBar nbResponse={nbResponse5} total={total} staticSmiley customSmiley="smileyHappy" customColor={Colors.EBLOOM_STRONG_GREEN} {...props}/>
            <EbloomSmileyProgressBar nbResponse={nbResponse4} total={total} staticSmiley customSmiley="smileyGlad" customColor={Colors.EBLOOM_LIGHT_GREEN} {...props}/>
            <EbloomSmileyProgressBar nbResponse={nbResponse3} total={total} staticSmiley customSmiley="smileyPokerface" customColor={Colors.EBLOOM_NEUTRAL} {...props}/>
            <EbloomSmileyProgressBar nbResponse={nbResponse2} total={total} staticSmiley customSmiley="smileySad" customColor={Colors.EBLOOM_LIGHT_RED} {...props}/>
            <EbloomSmileyProgressBar nbResponse={nbResponse1} total={total} staticSmiley customSmiley="smileyUnhappy" customColor={Colors.EBLOOM_STRONG_RED} {...props}/>
            {
                showNA &&
                <EbloomTooltip text={_.upperFirst(t("core.notApplicable"))} placement={"left"}>
                    <div>
                        <EbloomSmileyProgressBar nbResponse={nbResponse0} total={total} staticSmiley customSmiley="smileyAnonymous" customColor={"#636e72"} {...props}/>
                    </div>
                </EbloomTooltip>
            }

        </>
    )
}

EbloomAllSmileyProgressBar.propTypes = {
    nbResponse1:PropTypes.number.isRequired,
    nbResponse2:PropTypes.number.isRequired,
    nbResponse3:PropTypes.number.isRequired,
    nbResponse4:PropTypes.number.isRequired,
    nbResponse5:PropTypes.number.isRequired,
    total:PropTypes.number.isRequired,
}

EbloomAllSmileyProgressBar.defaultProps = {
    showNA:true
}

export default EbloomAllSmileyProgressBar;