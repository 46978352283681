import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import {Colors} from "../../../../Utils/Colors";
import PropTypes from "prop-types";


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index,}) => {
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        percent> 0 ?
            <text
                x={x}
                y={y}
                fill={Colors.EBLOOM_CONTRAST_PURPLE}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
            :
            <span/>

    );
}

const EbloomPieChart = (props) => {

    const {categories} = props;

    return (
        <ResponsiveContainer width={"100%"} height={500}>
            <PieChart width={400} height={400}>
                <Pie
                    data={categories}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={renderCustomizedLabel}
                    outerRadius={130}
                    fill="#8884d8"
                    dataKey="total"
                    isAnimationActive={false}
                >
                    {categories && categories.map((entry, index) => {
                        let color = entry.category ? entry.category.color : Colors.EBLOOM_CONTRAST_PURPLE;
                        return(
                            <Cell key={`cell-${index}`} fill={color} />
                        )
                    })}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );

}

EbloomPieChart.propsType = {
    categories:PropTypes.array.isRequired
}

export default EbloomPieChart;
